import { useMemo } from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@/components/common/ui-components/menu/types';
import { RoutePaths, NavbarTab } from '@/enums';
import { Event, LivenotesClickOrigin } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';
import { getHelpCenterUrl } from '@/utils/help-center-utils';
import { MODJO_EXTENSION_URL, useOpenModjoExtension } from '@/utils/modjo-extension-utils';

export const useExtensionMenuItems = (): Record<string, MenuItem> => {
  const openModjoExtension = useOpenModjoExtension();
  const { t } = useTranslation();
  return useMemo(
    () => ({
      MY_NOTES: {
        label: t('navbar.modjoExtension.myNotes'),
        link: RoutePaths.LIVENOTES_NOTES_LIST,
        dataTestId: DataTestId.LIVENOTES_MENU_MY_NOTES_BUTTON_ID,
        onClick: () => {
          mixpanel.track(Event.OPEN_MY_NOTES_PAGE_IN_WEBAPP, {
            clickOrigin: LivenotesClickOrigin.LIVENOTES_NAVBAR,
          });
          mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.MY_NOTES, hasLicense: true });
        },
      },
      LIVENOTES_TEMPLATES: {
        label: t('navbar.modjoExtension.notesTemplates'),
        link: RoutePaths.LIVENOTES_TEMPLATE_LIST,
        dataTestId: DataTestId.LIVENOTES_MENU_TEMPLATES_BUTTON_ID,
        onClick: () =>
          mixpanel.track(Event.CLICK_ON_NAVBAR, {
            navbarTab: NavbarTab.LIVENOTES_TEMPLATES,
            hasLicense: true,
          }),
      },
      DISCOVER_LIVENOTES: {
        label: t('navbar.modjoExtension.discoverNotes'),
        link: RoutePaths.MODJO_EXTENSION_SETTINGS,
        dataTestId: DataTestId.LIVENOTES_MENU_DISCOVER_LIVENOTES_BUTTON_ID,
        onClick: () =>
          mixpanel.track(Event.CLICK_ON_NAVBAR, {
            navbarTab: NavbarTab.DISCOVER_LIVENOTES,
            hasLicense: false,
          }),
      },
      OPEN_LIVENOTES: {
        label: t('navbar.modjoExtension.openModjoExtension'),
        onClick: () => {
          mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.OPEN_LIVENOTES, hasLicense: true });
          mixpanel.track(Event.OPEN_LIVENOTES, { clickOrigin: LivenotesClickOrigin.LIVENOTES_NAVBAR });
          openModjoExtension();
        },
        dataTestId: DataTestId.LIVENOTES_MENU_OPEN_APP_BUTTON_ID,
      },
      DOWNLOAD_LIVENOTES: {
        label: t('navbar.modjoExtension.installModjoExtension'),
        onClick: () => {
          mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.DOWNLOAD_LIVENOTES, hasLicense: true });
          mixpanel.track(Event.DOWNLOAD_LIVENOTES, { clickOrigin: LivenotesClickOrigin.LIVENOTES_NAVBAR });
          window.location.href = MODJO_EXTENSION_URL;
          window.open(getHelpCenterUrl(), '_blank');
        },
        dataTestId: DataTestId.LIVENOTES_MENU_DOWNLOAD_APP_BUTTON_ID,
      },
    }),
    [openModjoExtension, t]
  );
};
