import React from 'react';

import { useTranslation } from 'react-i18next';

import { MIN_LENTH_FOR_SEARCH } from '@/components/MainSidebar/Search/SearchDialog/SearchContent/search-hook';
import { useSearchAccountsQuery } from '@/entities/account/account.queries';
import { Account } from '@/entities/account/account.types';
import { useSearchContactQuery } from '@/entities/contact/contact.queries';
import { Contact } from '@/entities/contact/contact.types';
import { useSearchContactsJobTitlesQuery } from '@/entities/contacts/contacts.queries';

import { FiltersCategories, OtherOption, filtersCategoriesTranslationKeys } from './Common';
import { MultiSelectContent } from './MultiSelectContent';
import { SearchableObjectOptionType, SearchableObject, OtherInfoOptionType } from './search-components.types';

type MultiSelectContentProps = {
  selectedFilterType: FiltersCategories;
  selectedItems: SearchableObject[];
  setSelectedItems: (values: SearchableObject[]) => void;
  initialInputValue?: string;
};

export const MultiSelectContentApiSearch = ({
  selectedFilterType,
  selectedItems,
  setSelectedItems,
  initialInputValue,
}: MultiSelectContentProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState<string>('');
  const searchAccountsQuery = useSearchAccountsQuery(inputValue, MIN_LENTH_FOR_SEARCH, {
    queryOptions: { enabled: selectedFilterType === SearchableObjectOptionType.ACCOUNT },
  });
  const searchContactsQuery = useSearchContactQuery(inputValue, MIN_LENTH_FOR_SEARCH, {
    queryOptions: { enabled: selectedFilterType === SearchableObjectOptionType.CONTACT },
  });
  const searchContactsJobTitlesQuery = useSearchContactsJobTitlesQuery(inputValue, MIN_LENTH_FOR_SEARCH, {
    queryOptions: { enabled: selectedFilterType === OtherInfoOptionType.JOB_TITLE },
  });

  let values: Account[] | Contact[] | OtherOption[] | undefined;
  let loading: boolean = false;

  switch (selectedFilterType) {
    case SearchableObjectOptionType.ACCOUNT: {
      values = searchAccountsQuery.data?.map(
        (account) => new OtherOption(account.id, account.name, SearchableObjectOptionType.ACCOUNT)
      );
      loading = searchAccountsQuery.isLoading;
      break;
    }
    case SearchableObjectOptionType.CONTACT: {
      values = searchContactsQuery.data?.map(
        (contact) => new OtherOption(contact.id, contact.name, SearchableObjectOptionType.CONTACT)
      );
      loading = searchContactsQuery.isLoading;
      break;
    }
    case SearchableObjectOptionType.JOB_TITLE: {
      values = searchContactsJobTitlesQuery.data?.map(
        ({ jobTitle }, index) => new OtherOption(index.toString(), jobTitle, OtherInfoOptionType.JOB_TITLE)
      );
      loading = searchContactsJobTitlesQuery.isLoading;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <MultiSelectContent
      placeholder={`${t('filters.search.noInput')} ${t(filtersCategoriesTranslationKeys[selectedFilterType], { count: 1 })} ...`}
      options={values ?? []}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      initialInputValue={initialInputValue}
      onSearchValueChange={(value) => setInputValue(value)}
      loading={loading}
      minSearchChars={MIN_LENTH_FOR_SEARCH}
    />
  );
};
