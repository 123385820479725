import React, { ElementType, PropsWithChildren, useContext, useId, useMemo } from 'react';

import { UseFloatingReturn, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import {
  Combobox as ComboboxPrimitive,
  ComboboxInput as ComboboxInputPrimitive,
  ComboboxButton as ComboboxButtonPrimitive,
  ComboboxOption as ComboboxOptionPrimitive,
  ComboboxOptions as ComboboxOptionsPrimitive,
  ComboboxProps,
  ComboboxInputProps,
  ComboboxOptionProps,
  ComboboxOptionsProps,
  Label,
  LabelProps,
} from '@headlessui/react';
import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';

import { UICheckbox } from '@/components/common/ui-components/inputs/checkbox';
import { scrollbarDefaultOptions } from '@/enums/constants';
import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import { cn } from '@/utils/utils';

import { IconButtonGhost } from '../../buttons/UIButtonGhost';
import { DROPDOWN_ZINDEX } from '../../styles';

type ComboboxContextData = {
  refs: UseFloatingReturn['refs'];
  floatingStyles: UseFloatingReturn['floatingStyles'];
  labelId: string;
};

const ComboboxContext = React.createContext<ComboboxContextData | undefined>(undefined);

const useComboboxContext = () => {
  return useContext(ComboboxContext);
};

const ComboboxLabel = <TTag extends ElementType = 'label'>({ children }: LabelProps<TTag>) => {
  const { labelId } = useComboboxContext()!;

  return (
    <Label htmlFor={labelId} className="mb-1.5 font-medium">
      {children}
    </Label>
  );
};

const ComboboxInputWithChips = <TTag extends ElementType = 'input', TType = string>({
  children,
  open,
  className,
  placeholder,
  error,
  hideIcon,
  disabled,
  ...props
}: PropsWithChildren<
  ComboboxInputProps<TTag, TType> & {
    open: boolean;
    className?: string;
    placeholder?: string;
    error?: boolean;
    disabled?: boolean;
    hideIcon?: boolean;
  }
>) => {
  const { refs, labelId } = useComboboxContext()!;

  const { t } = useTranslation('common');

  return (
    <div
      ref={refs.setReference}
      className={cn(
        'relative flex flex-wrap items-center justify-between rounded-md p-2 ring-1 ring-inset focus:outline-none',
        {
          'ring-blue': open && !error,
          'ring-transparent-basic-16': !open && !error,
          'ring-red': error,
        },
        className
      )}
    >
      <div className="relative flex flex-1 flex-wrap items-center gap-1 overflow-hidden">
        {children}
        <ComboboxInputPrimitive
          id={labelId}
          placeholder={placeholder ?? t('combobox.typeAndSelect')}
          className="ml-1 w-full min-w-2 bg-grey-white outline-none transition-transform"
          displayValue={(value: ComboboxInputProps<TTag, TType>['defaultValue']) =>
            props?.displayValue ? props.displayValue(value as TType) : ''
          }
          disabled={disabled}
          autoComplete="off"
          {...props}
        />
      </div>
      {!hideIcon && (
        <ComboboxButtonPrimitive
          as={IconButtonGhost}
          icon={<ArrowDownChevronIcon className={clsx('size-4 fill-grey', open && 'rotate-180')} />}
          disabled={disabled}
          className="p-0.5"
          size="tiny"
        />
      )}
    </div>
  );
};

const ComboboxOptions = <TTag extends ElementType>({
  children,
  className,
  portal,
  ...props
}: Omit<ComboboxOptionsProps<TTag>, 'ref' | 'unmount'> & {
  className?: string;
  portal?: boolean;
}) => {
  const { refs, floatingStyles } = useComboboxContext()!;

  return (
    <ComboboxOptionsPrimitive {...props} modal={false} portal={portal}>
      {({ open }) => (
        <AnimatePresence>
          {open && (
            <m.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1, translateX: 0, translateY: 0 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.15 }}
              className="relative"
              style={{ zIndex: DROPDOWN_ZINDEX }}
            >
              <div
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  width: refs.reference.current?.getBoundingClientRect().width,
                }}
                className={cn('rounded-lg border border-grey-100 bg-grey-white py-1 shadow-list-item', className)}
              >
                <OverlayScrollbarsComponent options={scrollbarDefaultOptions}>
                  <ul className="flex max-h-[40vh] flex-col gap-y-1">{children}</ul>
                </OverlayScrollbarsComponent>
              </div>
            </m.div>
          )}
        </AnimatePresence>
      )}
    </ComboboxOptionsPrimitive>
  );
};

const ComboboxOption = <TTag extends ElementType, TType>({
  children,
  className,
  ...props
}: ComboboxOptionProps<TTag, TType> & {
  className?: string;
}) => {
  return (
    <ComboboxOptionPrimitive
      className={cn(
        'flex items-center gap-x-2 px-2 py-1 outline-none rounded mx-1 data-[active]:cursor-pointer data-[active]:bg-grey-100',
        className
      )}
      {...props}
    >
      {({ selected }) => (
        <>
          <UICheckbox checked={selected} readOnly className="border-grey-600" />
          {children}
        </>
      )}
    </ComboboxOptionPrimitive>
  );
};

const ComboboxProvider = <TValue extends { id: string }>({
  ...props
}: Pick<ComboboxProps<TValue, true>, 'value' | 'children' | 'onChange' | 'onClose'>) => {
  const { refs, floatingStyles } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), shift(), flip()],
  });
  const labelId = useId();

  const contextValue = useMemo(() => ({ refs, floatingStyles, labelId }), [refs, floatingStyles, labelId]);

  return (
    <ComboboxContext.Provider value={contextValue}>
      <ComboboxPrimitive multiple immediate {...props} />
    </ComboboxContext.Provider>
  );
};

export const MultiComboboxWithChips = Object.assign(ComboboxProvider, {
  Label: ComboboxLabel,
  InputWithChips: ComboboxInputWithChips,
  Options: ComboboxOptions,
  Option: ComboboxOption,
});
