export const LivenotesDataTestId = {
  LIVENOTES_MENU_BUTTON_ID: 'livenotesMenuBtn',
  LIVENOTES_MENU_CONTAINER_ID: 'livenotesMenuContainer',
  LIVENOTES_MENU_MY_NOTES_BUTTON_ID: 'livenotesMenuMyNotesBtn',
  LIVENOTES_MENU_DISCOVER_LIVENOTES_BUTTON_ID: 'livenotesMenuDiscoverLivenotesBtn',
  LIVENOTES_MENU_TEMPLATES_BUTTON_ID: 'livenotesMenuTemplatesBtn',
  LIVENOTES_MENU_OPEN_APP_BUTTON_ID: 'livenotesMenuOpenAppBtn',
  LIVENOTES_MENU_DOWNLOAD_APP_BUTTON_ID: 'livenotesMenuDownloadAppBtn',
  MODJO_EXTENSION_HEADER_TITLE_LABEL_ID: 'ModjoExtensionHeaderTitleLbl',
};
