import React from 'react';

import { cn } from '@/utils/utils';

type LoadingProps = {
  className?: string;
  dotColor: 'grey' | 'white' | 'black';
  size: 'tiny' | 'small' | 'medium' | 'large';
};

const CLASSES = {
  size: {
    tiny: 'size-0.5',
    small: 'size-1',
    medium: 'size-2',
    large: 'size-4',
  },
  dotColor: {
    grey: 'bg-grey-700',
    white: 'bg-grey-white',
    black: 'bg-grey-900',
  },
};

export const Loading = ({ dotColor, className, size }: LoadingProps) => (
  <div className={cn('flex items-center justify-center space-x-0.5 bg-grey-white', className)}>
    <div
      className={cn(
        'animate-[pulse_1s_infinite] rounded-full [animation-delay:-0.3s]',
        CLASSES.dotColor[dotColor],
        CLASSES.size[size]
      )}
    />
    <div
      className={cn(
        'animate-[pulse_1s_infinite] rounded-full [animation-delay:-0.1s]',
        CLASSES.dotColor[dotColor],
        CLASSES.size[size]
      )}
    />
    <div className={cn('animate-[pulse_1s_infinite] rounded-full', CLASSES.dotColor[dotColor], CLASSES.size[size])} />
  </div>
);
