import { getAllTemplatesOption } from '@/components/explore/filters-header/filters-components/AiCallScoringSearch';

import { useAiScoringTemplatesQuery } from './aiScoringTemplates.queries';
import { getAiCallScoringTemplatesMap, toAiCallScoringTemplateOptions } from './aiScoringTemplates.selects';

export const useAiScoringTemplateOptions = (options?: { withAllTemplates: boolean }) => {
  const allTemplatesOption = getAllTemplatesOption();
  const { withAllTemplates = true } = options || {};
  const templateOptions = useAiScoringTemplatesQuery({ queryOptions: { select: toAiCallScoringTemplateOptions } });
  if (templateOptions.data) {
    return [...(withAllTemplates ? [allTemplatesOption] : []), ...templateOptions.data];
  }
  return [...(withAllTemplates ? [allTemplatesOption] : [])];
};

export const useAiScoringTemplatesMapQuery = () => {
  return useAiScoringTemplatesQuery({
    queryOptions: { select: getAiCallScoringTemplatesMap },
  });
};
