import React, { KeyboardEvent, useMemo, useState } from 'react';

import { Composite, CompositeItem } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { Key } from 'ts-key-enum';

import { SEARCH_CALL_DEBOUNCE_TIME_IN_MS } from '@/enums/constants';
import { cn, isEmpty } from '@/utils/utils';

import { itemToJSX } from './Common';
import { ListMenuContainer, SearchOptionsInputContainer, SearchOptionsInputDescription } from './CommonStyles';
import { NoResultSearch } from './NoResultSearch';
import { SearchableObject } from './search-components.types';
import { useShortcutsContext } from '../../MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import { ProgressPlaceholder } from '../ProgressPlaceholder';
import { UICheckbox } from '../ui-components/inputs/checkbox';
import { SimpleInput } from '../ui-components/inputs/text/SimpleInput';

type MultiSelectContentProps = {
  options: SearchableObject[];
  placeholder: string;
  selectedItems: SearchableObject[];
  setSelectedItems: (values: SearchableObject[]) => void;
  initialInputValue?: string;
  loading?: boolean;
  onSearchValueChange?: (value: string) => void;
  minSearchChars?: number;
};

export const MultiSelectContent = ({
  options,
  placeholder,
  selectedItems,
  initialInputValue = '',
  loading = false,
  setSelectedItems,
  onSearchValueChange,
  minSearchChars = -1,
}: MultiSelectContentProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [inputDebouncedValue, setInputDebouncedValue] = useState<string>(initialInputValue);
  useDebounce(
    () => {
      onSearchValueChange?.(inputValue);
      setInputDebouncedValue(inputValue);
    },
    SEARCH_CALL_DEBOUNCE_TIME_IN_MS,
    [inputValue]
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const { handleTriggers } = useShortcutsContext();

  const filteredOptions = useMemo(
    () => options.filter((option) => option.name.toLowerCase().includes(inputDebouncedValue.toLowerCase())),
    [options, inputDebouncedValue]
  );

  const toggleSelectedItems = (item: SearchableObject) => {
    if (selectedItems.some(({ id }) => id === item.id)) {
      setSelectedItems(selectedItems.filter(({ id }) => id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const internalSelectedItems = new Map(selectedItems.map((item) => [item.id, item]));

  return (
    <div
      role="none"
      onKeyDown={(e) => {
        handleTriggers(e);
      }}
    >
      <Composite
        className={cn('flex flex-col', {
          'gap-2': !isEmpty(filteredOptions) || loading,
        })}
        activeIndex={activeIndex}
        onNavigate={(e) => {
          setActiveIndex(e);
        }}
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
          handleTriggers(e);
        }}
      >
        <SearchOptionsInputContainer>
          <SimpleInput
            autoFocus
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
          />
          {inputDebouncedValue.length < minSearchChars && (
            <SearchOptionsInputDescription>{t('filters.search.3charMin')}</SearchOptionsInputDescription>
          )}
        </SearchOptionsInputContainer>
        <ListMenuContainer>
          <NoResultSearch hasValues={!isEmpty(filteredOptions)} inputValue={inputDebouncedValue} />
          {loading && <ProgressPlaceholder />}
          {!loading &&
            filteredOptions?.map((item, index) => {
              return (
                <CompositeItem
                  key={item.id}
                  className={cn('outline-none p-1.5 flex gap-2 cursor-pointer', {
                    'rounded-md bg-grey-100': activeIndex === index,
                  })}
                  onMouseEnter={() => {
                    setActiveIndex(index);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Key.Enter && !e.ctrlKey && !e.shiftKey && !e.altKey) {
                      toggleSelectedItems(item);
                    }
                  }}
                  onClick={() => {
                    toggleSelectedItems(item);
                  }}
                >
                  <UICheckbox checked={internalSelectedItems.has(item.id)} onChange={() => toggleSelectedItems(item)} />
                  {itemToJSX(item, { inputValue: inputDebouncedValue })}
                </CompositeItem>
              );
            })}
        </ListMenuContainer>
      </Composite>
    </div>
  );
};
