import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { AltKeySymbol, CmdKeySymbol, CtrlKeySymbol } from '@/components/common/KeysSymbols';
import {
  FiltersCategories,
  useMapOptionsFromCategory,
  getOptionType,
  SINGLE_SELECT_CATEGORIES,
  enterIcon,
} from '@/components/common/search-components/Common';
import { FilterCategoryList } from '@/components/common/search-components/FilterCategoryList';
import {
  SearchableObject,
  RangeCategory,
  SearchableObjectOptionType,
  OptionTypeCategory,
  RangeOptionType,
} from '@/components/common/search-components/search-components.types';
import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { IconButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { ButtonOutlined } from '@/components/common/ui-components/buttons/UIButtonOutlined';
import { useShortcutKey } from '@/hooks/useShortcutKey';
import ReturnIcon from '@/static/icons/arrows/chevron-left-Line.svg?react';
import { Event, EventOrigin, EventType, SelectMode } from '@/tracking/events';
import { CRMProvider } from '@/types/providers';
import { isEmpty } from '@/utils/utils';

import { FilterSelectContent } from './FilterSelectContent';
import { useShortcutsContext } from './ShortcutsProvider';
import { useSearchContext } from '../SearchProvider';

const mainListVariants = {
  enter: { opacity: 0 },
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 40 },
  exit: { opacity: 0, x: -40 },
};

type FiltersContentPopoverProps = {
  options: SearchableObject[];
  selectedCategory?: FiltersCategories;
  onChangeSelectedCategory: (value?: FiltersCategories) => void;
  onValidateSearch: (values?: SearchableObject[]) => void;
  initialInputValue?: string;
  setInitialInputValue?: (value: string) => void;
  withSearchedValues?: boolean;
  onReturn: () => void;
  previousCategory?: FiltersCategories;
  crm?: CRMProvider | null;
};

const FiltersContentPopover = ({
  options,
  selectedCategory: category,
  onChangeSelectedCategory,
  onValidateSearch,
  initialInputValue,
  setInitialInputValue,
  withSearchedValues: isSearchedVals,
  onReturn,
  previousCategory,
  crm,
}: FiltersContentPopoverProps) => {
  const { t } = useTranslation();
  const selectedOptions = useMapOptionsFromCategory('navbar', category, crm);

  const { selectedFilters, searchedFilters, updateContextFilters, deleteContextFilters } = useSearchContext();
  const { addFilter, search, handleTriggers } = useShortcutsContext();

  const filters = isSearchedVals ? searchedFilters : selectedFilters;

  const [selectedItems, setSelectedItems] = useState<SearchableObject[]>(
    filters.filter((f) => getOptionType(f) === category)
  );

  useEffect(() => {
    if (category) {
      setSelectedItems(filters.filter((f) => getOptionType(f) === category));
    }
  }, [category, filters, setSelectedItems]);

  const handleUndoFilterSelection = () => {
    setSelectedItems([]);
    onReturn();
  };

  const handleValidateFilterSelection = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isEmpty(selectedItems)) {
      deleteContextFilters(category, undefined, isSearchedVals);
    } else {
      mixpanel.track(Event.ADD_MORE_FILTER, {
        actionType: event ? EventType.CLICK : EventType.SHORTCUT,
      });
      updateContextFilters(selectedItems, isSearchedVals);
    }
    onChangeSelectedCategory();
  };

  /**
   * @todo Better handling of current category filters due to async updateSelectedFilters
   */
  const handleSearch = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    mixpanel.track(Event.APPLY_FILTER, {
      filterOrigin: EventOrigin.SEARCHBAR,
      filterName: Object.values(RangeCategory).includes(category as RangeCategory) ? previousCategory : category,
      selectType: selectedItems.length > 1 ? SelectMode.MULTIPLE : SelectMode.SINGLE,
      actionType: event ? EventType.CLICK : EventType.SHORTCUT,
    });
    if (!isEmpty(selectedItems)) {
      updateContextFilters(selectedItems, isSearchedVals);
    }
    onValidateSearch(selectedItems);
  };

  useShortcutKey({ key: Key.Enter, altKey: true, callback: handleSearch });
  useShortcutKey({ key: 'i', ctrlKey: true, callback: handleValidateFilterSelection });

  useEffect(() => {
    if (search && !isEmpty(selectedItems)) {
      handleSearch();
    } else if (addFilter && (!isEmpty(selectedItems) || filters.some((f) => getOptionType(f) === category))) {
      handleValidateFilterSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFilter, search]);

  const isMulti = category && !SINGLE_SELECT_CATEGORIES.has(category as SearchableObjectOptionType);

  const isSubMenu =
    Object.values(OptionTypeCategory).includes(category as OptionTypeCategory) ||
    Object.values(RangeOptionType).includes(category as RangeOptionType);

  return (
    <div className="flex flex-col p-2 font-normal text-grey-900">
      <AnimatePresence mode="wait">
        <m.div
          key={category ?? null}
          initial="enter"
          animate="visible"
          exit="exit"
          variants={mainListVariants}
          transition={{ duration: 0.15 }}
        >
          {category && (
            <div className="absolute left-0.5 top-12 z-10">
              <IconButtonGhost
                icon={<ReturnIcon />}
                onClick={handleUndoFilterSelection}
                size="tiny"
                className="!p-0.5"
              />
            </div>
          )}
          {(!category || isSubMenu) && (
            <div
              className={clsx(
                (Object.values(OptionTypeCategory).includes(category as OptionTypeCategory) ||
                  Object.values(RangeOptionType).includes(category as RangeOptionType)) &&
                  'pl-4'
              )}
              aria-hidden
              onKeyDown={(event) => handleTriggers(event, false)}
            >
              <FilterCategoryList
                categories={selectedOptions}
                setInitialInputValue={setInitialInputValue}
                onChangeCategory={onChangeSelectedCategory}
              />
            </div>
          )}
          {filters.length > 0 && !category && !isSearchedVals && (
            <div className="mx-3 my-1">
              <ButtonFilled color="third" size="tiny" fullWidth onClick={handleSearch} className="!block">
                <div className="-mt-4 flex items-center justify-end opacity-50">
                  <CmdKeySymbol />
                  {enterIcon(true, true)}
                </div>
                <span>{t('filters.search.noInput')}</span>
              </ButtonFilled>
            </div>
          )}
          {category && !isSubMenu && (
            <>
              <FilterSelectContent
                options={options}
                category={category}
                previousCategory={previousCategory}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                initialInputValue={initialInputValue}
              />
              <div className="mx-4 my-1.5 flex flex-col gap-y-2">
                <ButtonFilled
                  color="third"
                  size="tiny"
                  fullWidth
                  disabled={isEmpty(selectedItems)}
                  onClick={handleSearch}
                  className="!block"
                >
                  <span>{isSearchedVals ? t('filters.apply') : t('filters.search.noInput')}</span>
                  <div className="-mt-4 flex items-center justify-end opacity-50">
                    <AltKeySymbol />
                    {enterIcon(true, true)}
                  </div>
                </ButtonFilled>

                {(isMulti ||
                  [
                    SearchableObjectOptionType.DEAL_STATUS,
                    SearchableObjectOptionType.DEAL_STAGE,
                    SearchableObjectOptionType.LEAD_STATUS,
                  ].includes(category as SearchableObjectOptionType)) &&
                  !isSearchedVals && (
                    <ButtonOutlined
                      size="tiny"
                      fullWidth
                      disabled={isEmpty(selectedItems) && !filters.some((f) => getOptionType(f) === category)}
                      onClick={handleValidateFilterSelection}
                      className="!block"
                    >
                      <span>
                        {isEmpty(selectedItems) && filters.some((f) => getOptionType(f) === category)
                          ? t('filters.search.clearFilter')
                          : t('filters.search.addFilter')}
                      </span>
                      <div className="-mt-4 flex items-center justify-end gap-x-1.5 opacity-30">
                        <CtrlKeySymbol />
                        <span className="font-semibold">I</span>
                      </div>
                    </ButtonOutlined>
                  )}
              </div>
            </>
          )}
        </m.div>
      </AnimatePresence>
    </div>
  );
};

export { FiltersContentPopover };
