export const WebSocketMessageTypes = {
  ACTIVITY: 'activity',
} as const;

export const socketEventHandlers = {
  connect: 'connect',
  disconnect: 'disconnect',
  connectError: 'connect_error',
  reconnectError: 'reconnect_error',
  reconnectFailed: 'reconnect_failed',
  error: 'error',
} as const;
