import { RightPanelState } from '@/components/common/ui-components/layout/right-panel/right-panel.slice';
import { createStoreAndMethods } from '@/utils/zustand-utils';

type AskAnythingStore = {
  suggestionsCurrentPage: number;
  callProcessedOpen: boolean;
  dealId: number;
} & RightPanelState;

const initialState: AskAnythingStore = {
  suggestionsCurrentPage: 0,
  callProcessedOpen: false,
  isRightPanelOpen: false,
  dealId: 0,
};

const [, askAnythingStore] = createStoreAndMethods(initialState);

export { askAnythingStore };
