import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FiltersCategories,
  SINGLE_SELECT_CATEGORIES,
  isRangeOptionType,
  RANGE_NUMBER_CATEGORIES,
  getRangeCategoryId,
  CALL_REVIEW_MAX_RATING,
  filterOptionsByCategory,
  filtersCategoriesTranslationKeys,
} from '@/components/common/search-components/Common';
import { DateSelect } from '@/components/common/search-components/DateSelect';
import { MultiChipsSelectContent } from '@/components/common/search-components/MultiChipsSelectContent';
import { MultiSelectContentApiSearch } from '@/components/common/search-components/MultiSelectApiSearchContent';
import { MultiSelectContent } from '@/components/common/search-components/MultiSelectContent';
import { RangeNumber } from '@/components/common/search-components/RangeNumber';
import {
  SearchableObjectOptionType,
  SearchableObject,
  OtherInfoOptionType,
  RangeCategory,
  RangeOptionType,
  ReviewsOptionType,
} from '@/components/common/search-components/search-components.types';
import { SimpleSelectContent } from '@/components/common/search-components/SimpleSelectContent';
import { SingleSelectWithApiCallTitleSearch } from '@/components/common/search-components/SingleSelectWithApiCallTitleSearch';
import { SingleSelectWithApiDealSearch } from '@/components/common/search-components/SingleSelectWithApiDealSearch';
import { AiCallScoringSearch } from '@/components/explore/filters-header/filters-components/AiCallScoringSearch';
import { useUrlFilters } from '@/components/explore/useUrlFilters';

type FilterSelectContentProps = {
  options: SearchableObject[];
  category: FiltersCategories;
  previousCategory?: FiltersCategories;
  selectedItems: SearchableObject[];
  setSelectedItems: React.Dispatch<React.SetStateAction<SearchableObject[]>>;
  initialInputValue?: string;
};

const FilterSelectContent = ({
  options,
  category,
  previousCategory,
  selectedItems,
  setSelectedItems,
  initialInputValue,
}: FilterSelectContentProps) => {
  const { t } = useTranslation();
  const { startCloseDate, endCloseDate } = useUrlFilters();
  const starDate = useMemo(() => (startCloseDate ? new Date(startCloseDate) : undefined), [startCloseDate]);
  const endDate = useMemo(() => (endCloseDate ? new Date(endCloseDate) : undefined), [endCloseDate]);

  const canSelectMultipleOptions = category && !SINGLE_SELECT_CATEGORIES.has(category as SearchableObjectOptionType);
  if (canSelectMultipleOptions) {
    if (
      [SearchableObjectOptionType.TRANSCRIPT, OtherInfoOptionType.TRANSCRIPT].includes(
        category as SearchableObjectOptionType
      )
    ) {
      return <MultiChipsSelectContent selectedItems={selectedItems} setSelectedItems={setSelectedItems} />;
    }

    if (
      [
        SearchableObjectOptionType.ACCOUNT,
        SearchableObjectOptionType.CONTACT,
        SearchableObjectOptionType.JOB_TITLE,
      ].includes(category as SearchableObjectOptionType)
    ) {
      return (
        <MultiSelectContentApiSearch
          selectedFilterType={category}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          initialInputValue={initialInputValue}
        />
      );
    }

    if (ReviewsOptionType.AI_CALL_SCORING === category) {
      return (
        <AiCallScoringSearch selectedItems={selectedItems} setSelectedItems={setSelectedItems} category={category} />
      );
    }

    if (previousCategory) {
      if (previousCategory === RangeOptionType.DEAL_CLOSE_DATE) {
        return (
          <DateSelect
            selectedFilterType={category as RangeCategory}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            startCloseDate={starDate}
            endCloseDate={endDate}
          />
        );
      }

      if (isRangeOptionType(previousCategory) && RANGE_NUMBER_CATEGORIES.has(previousCategory)) {
        return (
          <RangeNumber
            category={previousCategory}
            id={getRangeCategoryId(previousCategory)}
            selectedFilterType={category as RangeCategory}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            maxValue={previousCategory === RangeOptionType.REVIEW_RATING ? CALL_REVIEW_MAX_RATING : undefined}
          />
        );
      }
    }
    return (
      <MultiSelectContent
        options={filterOptionsByCategory(options, category)}
        placeholder={`${t('filters.search.noInput')} ${t(filtersCategoriesTranslationKeys[category], { count: 1 })} ...`}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        initialInputValue={initialInputValue}
      />
    );
  }

  if (category === SearchableObjectOptionType.CALL_TITLE) {
    return <SingleSelectWithApiCallTitleSearch selectedFilterType={category} setSelectedItems={setSelectedItems} />;
  }

  if (category === SearchableObjectOptionType.DEAL) {
    return <SingleSelectWithApiDealSearch selectedFilterType={category} setSelectedItems={setSelectedItems} />;
  }

  if (
    [
      SearchableObjectOptionType.DEAL_STATUS,
      SearchableObjectOptionType.DEAL_STAGE,
      SearchableObjectOptionType.LEAD_STATUS,
      SearchableObjectOptionType.DEAL,
    ].includes(category as SearchableObjectOptionType)
  ) {
    return (
      <SimpleSelectContent
        options={filterOptionsByCategory(options, category)}
        placeholder={`${t('filters.search.noInput')} ${t(filtersCategoriesTranslationKeys[category], { count: 1 })} ...`}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        initialInputValue={initialInputValue}
      />
    );
  }
  return null;
};

export { FilterSelectContent };
