import React, { createContext, useContext, useMemo, useState } from 'react';

type CappedErrorsContextProps = { nbErrors: number; increaseNbErrors: () => void; decreaseNbErrors: () => void };

/*
 * Context used to make sure we do not display too many errors from the same page.
 * We do not want to insist on the number of errors and offer a great minimum added value.
 * We would rather display an error page and ask them to contact us
 * @see CappedErrorPage
 */
const CappedErrorsContext = createContext<CappedErrorsContextProps>({
  nbErrors: 0,
  increaseNbErrors: () => null,
  decreaseNbErrors: () => null,
});
CappedErrorsContext.displayName = 'CappedErrorsContext';

export const NB_CAPPED_ERRORS = 2;

export const CappedErrorsProvider = ({ children }: { children: React.ReactNode }) => {
  const [nbErrors, setNbErrors] = useState<number>(0);

  const value: CappedErrorsContextProps = useMemo(
    () => ({
      nbErrors,
      increaseNbErrors: () => setNbErrors((previous) => previous + 1),
      decreaseNbErrors: () => setNbErrors((previous) => (previous < NB_CAPPED_ERRORS ? previous - 1 : previous)),
    }),
    [nbErrors]
  );

  return <CappedErrorsContext.Provider value={value}>{children}</CappedErrorsContext.Provider>;
};

export function useCappedErrorsContext() {
  const cappedErrors = useContext(CappedErrorsContext);
  if (cappedErrors === null) {
    throw new Error('useCappedErrorsContext must be used within a CappedErrorsProvider');
  }
  return cappedErrors;
}
