import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { FiltersCategories, filtersCategoriesTranslationKeys } from '@/components/common/search-components/Common';
import {
  OptionTypeCategory,
  RangeCategory,
  RangeOptionType,
} from '@/components/common/search-components/search-components.types';
import { Chip } from '@/components/common/ui-components/data-display/chips';
import { Tooltip } from '@/components/common/ui-components/data-display/tooltip';

import { makeFiltersWithCategory } from './helpers';
import { useSearchContext } from './SearchProvider';

const CHIP_MAX_WIDTH = 200;
const MAX_VISIBLE_FILTER = 1;

type EllipsisTextProps = {
  category?: FiltersCategories;
  previousCategory?: FiltersCategories;
  input?: string;
  multi?: boolean;
  light?: boolean;
};

const EllipsisText = ({ category, previousCategory, input, multi, light }: EllipsisTextProps) => {
  const { t } = useTranslation();
  const isOptionTypeCategory = Object.values(OptionTypeCategory).includes(category as OptionTypeCategory);
  const isRangeOptionType = Object.values(RangeOptionType).includes(category as RangeOptionType);
  const isRangeCategory = Object.values(RangeCategory).includes(category as RangeCategory);

  if (!category) {
    return null;
  }

  return (
    <div
      className={clsx('flex items-center truncate text-sm', light ? 'text-grey-white' : 'text-grey-900')}
      style={{
        maxWidth: CHIP_MAX_WIDTH,
      }}
    >
      {isOptionTypeCategory && <p>{t('filters.search.in')}&nbsp;</p>}
      <p className="font-semibold capitalize">
        {isRangeCategory && previousCategory
          ? t(filtersCategoriesTranslationKeys[previousCategory], { count: 1 })
          : t(filtersCategoriesTranslationKeys[category], { count: 1 })}
      </p>
      {!isOptionTypeCategory && !isRangeOptionType && (
        <>
          <p className="font-normal">&nbsp;{t('filters.categories.is', { count: multi ? 2 : 1 })}&nbsp;</p>
          <p className="truncate">{input ?? '...'}</p>
        </>
      )}
    </div>
  );
};

type InputChipsProps = {
  category?: FiltersCategories;
  previousCategory?: FiltersCategories;
};

const InputChips = ({ category, previousCategory }: InputChipsProps) => {
  const { t } = useTranslation();
  const { selectedFilters } = useSearchContext();

  if (!selectedFilters || (selectedFilters.length === 0 && !category)) {
    return null;
  }

  const filtersWithType = makeFiltersWithCategory(selectedFilters);
  const filtersCount = filtersWithType.length;
  const lastElementIndex = filtersCount - 1;

  return (
    <>
      {filtersCount === MAX_VISIBLE_FILTER && (
        <Chip
          className="mx-0.5 h-5"
          key={`${filtersWithType[lastElementIndex].option.id ?? filtersWithType[lastElementIndex].option.name}`}
          label={
            <EllipsisText
              category={filtersWithType[lastElementIndex].type}
              previousCategory={previousCategory}
              input={filtersWithType[lastElementIndex].option.name}
            />
          }
        />
      )}
      {filtersCount > MAX_VISIBLE_FILTER && (
        <Tooltip
          content={
            <ul>
              {filtersWithType.slice(0, lastElementIndex).map((filter) => (
                <li key={filter.option.id}>
                  <EllipsisText
                    category={filter.type}
                    previousCategory={previousCategory}
                    input={filter.option.name}
                    light
                  />
                </li>
              ))}
            </ul>
          }
        >
          <Chip className="mx-0.5 h-5" key="more-filters" label={<p>{t('plus', { value: lastElementIndex })}</p>} />
        </Tooltip>
      )}
      {category && (
        <Chip
          className="mx-0.5 h-5"
          key={category}
          label={<EllipsisText category={category} previousCategory={previousCategory} />}
        />
      )}
    </>
  );
};

export { InputChips };
