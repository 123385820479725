import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useCreatePortalSessionUrlQuery } from '@/entities/billing/billing.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { UserRole } from '@/enums';
import { hasRole } from '@/utils/user-utils';

import { VISIBLE_FOR_ADMINS_AFTER, VISIBLE_FOR_ALL_USERS_AFTER } from './constants';
import { ExternalLink } from '../../common/ui-components/navigation/ExternalLink';

type PaymentOverdueContentProps = {
  daysSincePaymentOverdue: number;
};

const PaymentOverdueContent = ({ daysSincePaymentOverdue }: PaymentOverdueContentProps) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const createPortalSessionUrlQuery = useCreatePortalSessionUrlQuery({
    queryOptions: { enabled: hasRole(currentUser?.role ?? UserRole.USER, UserRole.ADMIN) },
  });

  const dayOverdue =
    daysSincePaymentOverdue < VISIBLE_FOR_ALL_USERS_AFTER
      ? VISIBLE_FOR_ADMINS_AFTER.toString()
      : VISIBLE_FOR_ALL_USERS_AFTER.toString();

  const showLink = hasRole(currentUser?.role ?? UserRole.USER, UserRole.ADMIN) && createPortalSessionUrlQuery.data;
  return (
    <div className="flex-center flex-col gap-x-2.5 text-center">
      {t('freetrial.overdueInvoice', {
        dayOverdue,
      })}
      <br />
      <span>
        <Trans
          i18nKey="freetrial.contactAccounting"
          components={{
            span: showLink ? <ExternalLink href={createPortalSessionUrlQuery.data} /> : <span />,
          }}
        />
      </span>
    </div>
  );
};

export { PaymentOverdueContent };
