import React from 'react';

import CrossIcon from '@/static/icons/basic/cross-Line.svg?react';

import { IconButtonGhost } from '../../buttons/UIButtonGhost';

type CrossAdornmentProps = React.HTMLAttributes<HTMLButtonElement>;

const CrossAdormnemt = (props: CrossAdornmentProps) => (
  <IconButtonGhost size="tiny" {...props} icon={<CrossIcon className="icon-small" />} isTransparent />
);

CrossAdormnemt.displayName = 'CrossAdormnemt';

export { CrossAdormnemt };
