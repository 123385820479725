import { io } from 'socket.io-client';

import { Environment } from '@/enums/constants';

import { defaultSocketOptions, logger } from './socket.utils';
import { socketEventHandlers } from './websocket.constant';
import { ApiUrl } from '../api-utils';

const socket = io(ApiUrl, defaultSocketOptions);

socket.on(socketEventHandlers.connect, () => {
  logger('Socket is now connected to server');
});

socket.on(socketEventHandlers.disconnect, () => {
  logger('Socket is now disconnected from the server');
});

socket.on(socketEventHandlers.connectError, (error) => {
  logger(`Websocket connect_error : ${error.message}`);
});

socket.on(socketEventHandlers.reconnectError, (error) => {
  logger(`Websocket reconnect_error : ${error.message}`);
});

socket.on(socketEventHandlers.reconnectFailed, (error) => {
  logger(`Websocket reconnect_failed : ${error.message}`);
});

socket.on(socketEventHandlers.error, (error) => {
  logger(`Websocket error : ${error.message}`);
});

const connectWS = (token: string) => {
  if (token.length === 0) {
    return;
  }

  if (import.meta.env.VITE_ENV === Environment.DEV) {
    return;
  }

  socket.io.opts.query = {
    Authorization: token,
  };

  if (!socket.connected) {
    socket.connect();
  }
};

const disconnectWS = () => {
  socket.io.opts.query = {
    Authorization: '',
  };
  socket.disconnect();
};

export { socket, connectWS, disconnectWS };
