import { CallFilterDto } from '@/types/call-filters';
import { ObjectValues } from '@/types/common';

export const AvailableMetrics = {
  TOTAL_COUNT: 'totalCount',
  TOTAL_DURATION: 'totalDuration',
  AVG_DURATION: 'avgDuration',
  AVG_TALK_RATIO: 'avgTalkRatio',
  AVG_LONGEST_USER_MONOLOGUE: 'avgLongestUserMonologue',
  AVG_LONGEST_CONTACT_MONOLOGUE: 'avgLongestContactMonologue',
  AVG_INTERACTION_SPEED: 'avgInteractionSpeed',
  CALLS_LISTENED: 'callsListened',
  COMMENTS_POSTED: 'commentsPosted',
  COMMENTS_RECEIVED: 'commentsReceived',
  LIVE_NOTES_POSTED: 'liveNotesPosted',
  REVIEWS_RECEIVED: 'reviewsReceived',
  REVIEWS_POSTED: 'reviewsPosted',
  AI_SCORING: 'aiScoring',
} as const;

export type AvailableMetric = (typeof AvailableMetrics)[keyof typeof AvailableMetrics];

export const isAvailableMetric = (value?: string): value is AvailableMetric => {
  return Object.values(AvailableMetrics).includes(value as AvailableMetric);
};

const CALL_METRICS = [
  AvailableMetrics.TOTAL_COUNT,
  AvailableMetrics.AVG_DURATION,
  AvailableMetrics.TOTAL_DURATION,
] as const;

type CallMetric = (typeof CALL_METRICS)[number];

export const isCallMetric = (value: unknown): value is CallMetric => {
  return CALL_METRICS.includes(value as CallMetric);
};

const InteractionsMetrics = [
  AvailableMetrics.AVG_TALK_RATIO,
  AvailableMetrics.AVG_LONGEST_USER_MONOLOGUE,
  AvailableMetrics.AVG_LONGEST_CONTACT_MONOLOGUE,
  AvailableMetrics.AVG_INTERACTION_SPEED,
] as const;

type InteractionsMetric = (typeof InteractionsMetrics)[number];

export const isInteractionsMetric = (value: unknown): value is InteractionsMetric => {
  return InteractionsMetrics.includes(value as InteractionsMetric);
};

const ActivitiesMetrics = [
  AvailableMetrics.CALLS_LISTENED,
  AvailableMetrics.COMMENTS_POSTED,
  AvailableMetrics.COMMENTS_RECEIVED,
  AvailableMetrics.LIVE_NOTES_POSTED,
] as const;

type ActivitiesMetric = (typeof ActivitiesMetrics)[number];

export const isActivitiesMetric = (value: unknown): value is ActivitiesMetric => {
  return ActivitiesMetrics.includes(value as ActivitiesMetric);
};

const ReviewsMetrics = [AvailableMetrics.REVIEWS_RECEIVED, AvailableMetrics.REVIEWS_POSTED] as const;

type ReviewsMetric = (typeof ReviewsMetrics)[number];

export const isReviewsMetric = (value: unknown): value is ReviewsMetric => {
  return ReviewsMetrics.includes(value as ReviewsMetric);
};

export enum EvolutionChartScale {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

type ComplexEvolutionValue = { volume: number; average: number };
export type ComplexEvolutionValueKeys = keyof ComplexEvolutionValue;

export type EvolutionChart = {
  startDate: string;
  scale?: EvolutionChartScale;
  values: (ComplexEvolutionValue | number)[];
  reference?: number[];
};

type AverageMetric = {
  average: number;
  count: number;
  evolution: EvolutionChart;
};

export type TemplateMetric = {
  uuid: string;
  title: string;
  count: number;
  average: number;
  order: number;
  deletedOn: string | null;
};

type UserMetric = {
  average: number;
  volume: number;
};

export type AiScoringAnalytics = {
  global: AverageMetric;
  templates: Map<string, TemplateMetric>;
  questions: Map<string, TemplateMetric>;
  users: Map<string, UserMetric>;
};

type ReviewsMetricDictionary = {
  reviewsReceived: AverageMetric;
  reviewsPosted: AverageMetric;
};

type ReviewTemplateMetric = {
  title: string;
  count: number;
  amount: number;
};

type TemplateReviewsMetricDictionary = {
  reviewsReceived: ReviewTemplateMetric;
  reviewsPosted: ReviewTemplateMetric;
};

type AverageQuestionMetric = {
  title: string;
  average: number;
  weight: number;
  count: number;
};

type ReviewQuestionsMetricDictionary = {
  reviewsReceived: AverageQuestionMetric;
  reviewsPosted: AverageQuestionMetric;
};

export type ReviewsAnalytics = {
  global: ReviewsMetricDictionary;
  users: Record<number, ReviewsMetricDictionary>;
  templates: Record<number, TemplateReviewsMetricDictionary>;
  reviewQuestions: Record<number, ReviewQuestionsMetricDictionary>;
};

export type AnalyticsCallFilterDto = CallFilterDto & {
  metric: AvailableMetric | undefined;
};

export type Metric = {
  currentPeriod?: number;
  lastPeriod?: number;
  evolution?: EvolutionChart;
};

type ActivitiesMetricDictionary = {
  callsListened: Metric;
  commentsPosted: Metric;
  commentsReceived: Metric;
  liveNotesPosted: Metric;
};
type UserActivitiesDictionary = {
  callsListened: number;
  commentsPosted: number;
  commentsReceived: number;
  liveNotesPosted: number;
};

export type ActivitiesMetricsAnalytics = {
  global: ActivitiesMetricDictionary;
  users: Record<number, UserActivitiesDictionary>;
};

const NumberMetricDictionaryKeys = {
  TOTAL_COUNT: 'totalCount',
  TOTOL_DURATION: 'totalDuration',
  AVG_DURATION: 'avgDuration',
  AVG_TALK_RATIO: 'avgTalkRatio',
  AVG_LONGEST_USER_MONOLOGUE: 'avgLongestUserMonologue',
  AVG_LONGEST_CONTACT_MONOLOGUE: 'avgLongestContactMonologue',
  AVG_INTERACTION_SPEED: 'avgInteractionSpeed',
} as const;

type NumberMetricDictionaryKey = ObjectValues<typeof NumberMetricDictionaryKeys>;

export type NumberMetricDictionary = Record<NumberMetricDictionaryKey, number>;

export const isNumberMetricDictionaryKey = (key: unknown): key is NumberMetricDictionaryKey => {
  return Object.values(NumberMetricDictionaryKeys).includes(key as NumberMetricDictionaryKey);
};

type MetricDictionary = Record<NumberMetricDictionaryKey, Metric>;

export type MetricsAnalytics = {
  global: MetricDictionary;
  users: Record<number, NumberMetricDictionary>;
};

export type WordsCounter = {
  countMatching?: number;
  totalCount: number;
  topics: Record<number, number>;
};

export type TopicWordAnalytics = {
  global: WordsCounter;
  users: Record<number, WordsCounter>;
};

export type TagsAnalytics = {
  tags: Record<number, number>;
  tagsUniqueCallNumber: number;
};
