import { useInfiniteQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { DEFAULT_PAGINATION_PER_PAGE } from '@/utils/paginated.utils';
import { getNextPageParam } from '@/utils/utils';

import { Notification, PaginationNotificationRequestResult } from './notifications.types';
import { queryKeys } from '../queryKeys';

export const NOTIFICATIONS_API_BASE_URL = '/notifications';

export const useNotificationsQuery = () => {
  return useInfiniteQuery({
    queryKey: queryKeys.notifications.all,
    queryFn: async ({ pageParam }) => {
      return apiService.requestPagination<Notification, PaginationNotificationRequestResult<Notification>>(
        NOTIFICATIONS_API_BASE_URL,
        {
          page: pageParam,
          perPage: DEFAULT_PAGINATION_PER_PAGE,
        }
      );
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, DEFAULT_PAGINATION_PER_PAGE),
  });
};
