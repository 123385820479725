import React from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { useNotificationsQuery } from '@/entities/notifications/notifications.queries';
import { NavbarTab } from '@/enums';
import { DIALOGS_IDS } from '@/enums/constants';
import BellIcon from '@/static/icons/alerte/bell-Line.svg?react';
import { Event } from '@/tracking/events';

import { Badge } from '../../common/ui-components/data-display/Badge';
import { useDialog } from '../../common/ui-components/dialogs';
import { Position } from '../../common/ui-components/types';
import { MobileNavbarButton } from '../MobileNavbarButton';

export const MobileNotificationButton = () => {
  const { t } = useTranslation();
  const { openDialog, isDialogOpen } = useDialog(DIALOGS_IDS.MOBILE_NOTIFICATIONS);
  const notificationsQuery = useNotificationsQuery();

  const lastPageIndex = notificationsQuery.isSuccess ? notificationsQuery.data.pages.length - 1 : 0;
  const unreadNotificationsCount = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages[lastPageIndex].unreadNotifications
    : 0;

  const badgeLabel = unreadNotificationsCount < 100 ? unreadNotificationsCount : '99+';

  return (
    <MobileNavbarButton
      variant="button"
      onClick={() => {
        openDialog();
        mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.NOTIFICATIONS });
      }}
      isActive={isDialogOpen(DIALOGS_IDS.MOBILE_NOTIFICATIONS)}
    >
      <Badge
        label={badgeLabel}
        anchorOrigin={{ vertical: Position.BOTTOM }}
        className={clsx(
          'bottom-2 right-1 aspect-square h-3.5 rounded bg-red text-2xs text-grey-white',
          unreadNotificationsCount > 100 && 'w-5.5'
        )}
      >
        <MobileNavbarButton.Icon lineIcon={BellIcon} />
      </Badge>

      <MobileNavbarButton.Label>{t('navbar.notifications.button')}</MobileNavbarButton.Label>
    </MobileNavbarButton>
  );
};
