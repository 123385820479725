import React from 'react';

import clsx from 'clsx';

import { Avatar } from '@/components/common/ui-components/data-display/avatar';
import { ProviderIcon } from '@/components/providers/icons/ProvidersIcon';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import FileDownloadIcon from '@/static/icons/files/file-download-Filled.svg?react';

import { UserNotificationData } from './notification.helper';
import { NotificationType, NotificationTypes } from '../notifications-enums';

type NotificationIconProps = {
  notificationType: NotificationType;
  isNotificationRead: boolean;
  serializedUserNotification: UserNotificationData | null;
};

export const NotificationIcon = ({
  notificationType,
  isNotificationRead,
  serializedUserNotification,
}: NotificationIconProps) => {
  const tenantQuery = useTenantQuery();
  switch (notificationType) {
    case NotificationTypes.DOWNLOAD: {
      return (
        <div
          className={clsx(
            'flex items-center justify-center rounded-full bg-green-900 p-1',
            isNotificationRead && 'opacity-60'
          )}
        >
          <FileDownloadIcon className="icon-small fill-grey-white" />
        </div>
      );
    }
    case NotificationTypes.CRM_FILLING_ANSWERS_ANSWERED: {
      if (tenantQuery.data?.crm) {
        return (
          <div
            className={clsx('flex items-center justify-center rounded-full p-1', isNotificationRead && 'opacity-60')}
          >
            {ProviderIcon(tenantQuery.data.crm, 20)}
          </div>
        );
      }
      return null;
    }
    default: {
      if (serializedUserNotification) {
        return (
          <Avatar className={clsx(isNotificationRead && 'opacity-60')} {...serializedUserNotification} border={false} />
        );
      }
      return null;
    }
  }
};
