import React from 'react';

import { RouteObject, redirect } from 'react-router-dom';

import { AuthenticationMode, RoutePaths } from '@/enums';
import { i18n } from '@/translation/i18n';

import { RoutesErrorBoundary } from '../RoutesErrorBoundary';

export const publicRoutes: RouteObject[] = [
  {
    path: RoutePaths.DEFAULT,
    errorElement: <RoutesErrorBoundary />,
    children: [
      {
        index: true,
        async lazy() {
          const { RootPage } = await import('@/layout/RootPage');
          return { Component: RootPage };
        },
      },
      {
        lazy: async () => {
          const { AuthenticationWrapper: Authentication } = await import('@/components/auth/AuthenticationWrapper');
          return {
            Component: Authentication,
            loader: async () => {
              // make sure the namespace is loaded before rendering the related routes
              await i18n.loadNamespaces(['auth']);
              return null;
            },
          };
        },
        children: [
          {
            path: RoutePaths.LOGIN,
            async lazy() {
              const { LoginPage } = await import('@/components/auth');
              return { Component: () => <LoginPage mode={AuthenticationMode.LOGIN} /> };
            },
          },
          {
            path: RoutePaths.SETUP_PASSWORD,
            async lazy() {
              const { LoginPage } = await import('@/components/auth');
              return { Component: () => <LoginPage mode={AuthenticationMode.SETUP_PASSWORD} /> };
            },
          },
          {
            path: RoutePaths.RESET_PASSWORD,
            async lazy() {
              const { LoginPage } = await import('@/components/auth');
              return { Component: () => <LoginPage mode={AuthenticationMode.RESET_PASSWORD} /> };
            },
          },
        ],
      },
      {
        path: RoutePaths.SSO,
        async lazy() {
          const { OAuthSignInCallback } = await import('@/components/providers/sso/OAuthSignInCallback');
          return { Component: OAuthSignInCallback };
        },
      },
      {
        path: RoutePaths.RESET_PASSWORD_CALLBACK,
        loader: () => {
          const token = new URLSearchParams(window.location.search).get('token');
          return redirect(`${RoutePaths.SETUP_PASSWORD}?token=${token}`);
        },
      },
      {
        path: RoutePaths.DIGEST_DELETE,
        async lazy() {
          const { DeleteDigest } = await import('@/components/digests');
          return { Component: DeleteDigest };
        },
      },
      {
        path: RoutePaths.FARMER_AUTH,
        async lazy() {
          const { FarmerAuth } = await import('@/components/farmer-auth');
          return { Component: FarmerAuth };
        },
      },
      {
        path: RoutePaths.PUBLIC_CALL,
        async lazy() {
          const { PublicPageWrapper } = await import('@/components/common/PublicPageWrapper');
          return {
            Component: PublicPageWrapper,
            loader: async () => {
              await i18n.loadNamespaces('callDetails');
              return null;
            },
          };
        },
      },
      {
        path: RoutePaths.EMBED,
        async lazy() {
          const { EmbedPage } = await import('@/components/embed');
          return {
            Component: EmbedPage,
            loader: async () => {
              await i18n.loadNamespaces('embed');
              return null;
            },
          };
        },
      },
      {
        path: RoutePaths.NOT_FOUND,
        async lazy() {
          const { ErrorPage } = await import('@/components/common/errors/ErrorPage');
          return { Component: ErrorPage };
        },
      },
      {
        path: RoutePaths.UNAUTHORIZED,
        async lazy() {
          const { UnauthorizedPage } = await import('@/components/common/errors/UnauthorizedPage');
          return { Component: UnauthorizedPage };
        },
      },
    ],
  },
  // Should be always last
  {
    path: RoutePaths.WILD_CARD,
    async lazy() {
      const { NoRouteFoundPage } = await import('@/layout/NoRouteFoundPage');
      return { Component: NoRouteFoundPage };
    },
  },
];
