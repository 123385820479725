import React, { forwardRef } from 'react';

import { ButtonBase } from './ButtonBase';
import { IconButtonBase } from './IconButtonBase';
import { ButtonBaseProps, ButtonColorVariants, IconButtonBaseProps } from './types';
import { cn } from '../../../../utils/utils';

type UIButtonColorProps = {
  color?: ButtonColorVariants;
  fullWidth?: boolean;
};

export type ButtonFilledProps = ButtonBaseProps & UIButtonColorProps;
type IconButtonFilledProps = IconButtonBaseProps & Omit<UIButtonColorProps, 'fullWidth'>;

/**
 * Button with a fully colored background.
 * We need border to be the same color than the BG to be able to
 */
const CLASSES = {
  ring: 'active:focus:ring-0 active:focus:ring-offset-0 hover:focus:ring-0 hover:focus:ring-offset-0 focus:ring-1 focus:ring-offset-1',
  base: 'font-semibold text-grey-white fill-grey-white',
  disabled: 'bg-states-white-disabled cursor-not-allowed',
  color: {
    primary:
      'bg-green ring-green hover:ring-green-600 hover:bg-green-600 focus:ring-green active:bg-green-700 active:ring-green-700',
    secondary:
      'bg-grey-white ring-grey hover:ring-grey-600 hover:bg-grey-600 focus:ring-grey active:ring-grey-700 active:bg-grey-700',
    third:
      'bg-blue ring-blue hover:bg-blue-600 hover:ring-blue-600 focus:ring-blue active:bg-blue-700 active:ring-blue-700',
    error: 'bg-red ring-red hover:bg-red-600 hover:ring-red-600 focus:ring-red active:bg-red-700 active:ring-red-700',
    info: 'hover:text-grey-900 active:text-grey-white bg-grey-900 border-grey-900 hover:bg-grey-white hover:border-grey-900 ring-grey-900 focus:ring-grey-white active:bg-grey-900 active:ring-grey-900',
    ghost: 'bg-grey-white border border-grey-white',
  },
  fullWidth: 'w-full',
};

const ButtonFilled = forwardRef<HTMLButtonElement, ButtonFilledProps>(
  ({ color = 'primary', disabled, fullWidth, className, ...otherProps }, reference) => (
    <ButtonBase
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(
        CLASSES.base,
        disabled ? CLASSES.disabled : `${CLASSES.color[color]} ${CLASSES.ring}`,
        fullWidth && CLASSES.fullWidth,
        className
      )}
    />
  )
);

const IconButtonFilled = forwardRef<HTMLButtonElement, IconButtonFilledProps>(
  ({ color = 'primary', disabled, className, ...otherProps }, reference) => (
    <IconButtonBase
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(
        'fill-grey-white',
        disabled ? CLASSES.disabled : `${CLASSES.color[color]} ${CLASSES.ring}`,
        className
      )}
    />
  )
);

export { ButtonFilled, IconButtonFilled };
