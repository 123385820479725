import React from 'react';

import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { UserRole } from '@/enums';
import { useIgnorePaymentOverdueBanner } from '@/hooks/useIgnorePaymentOverdueBanner';
import { hasRole } from '@/utils/user-utils';

import { VISIBLE_FOR_ADMINS_AFTER, VISIBLE_FOR_ALL_USERS_AFTER } from './constants';
import { PaymentOverdueContent } from './PaymentOverdueContent';
import { WarningBannerContainer } from '../WarningBannerContainer';

const PaymentOverdueBanner = () => {
  const tenantQuery = useTenantQuery();
  const currentUser = useCurrentUser();
  const shouldIgnoreBanner = useIgnorePaymentOverdueBanner();

  // Hide the banner for all users if the tenant has paid all his invoices or the total of due days is lower than 30 days
  // Or ALWAYS ignore some specific tenants
  if (
    !currentUser ||
    shouldIgnoreBanner ||
    !tenantQuery.isSuccess ||
    !tenantQuery.data.hasPaymentOverdue ||
    tenantQuery.data.daysSincePaymentOverdue < VISIBLE_FOR_ADMINS_AFTER
  ) {
    return null;
  }

  // If there's an overdue then hide it for users and managers first when the total of due days is lower than 90 days
  if (
    tenantQuery.data.daysSincePaymentOverdue < VISIBLE_FOR_ALL_USERS_AFTER &&
    !hasRole(currentUser.role, UserRole.ADMIN)
  ) {
    return null;
  }

  return (
    <WarningBannerContainer dismissable={false}>
      <PaymentOverdueContent daysSincePaymentOverdue={tenantQuery.data.daysSincePaymentOverdue} />
    </WarningBannerContainer>
  );
};

export { PaymentOverdueBanner };
