/*
These ids are used to locate UI components/elements
by the e2e tests. The attribute name is data-test-id.
 */
import { AuthenticationDataTestId } from '@/types/data-test-id/authentication';
import { CallListItemDataTestId } from '@/types/data-test-id/call-list-item';
import { CallNotesDataTestId } from '@/types/data-test-id/call-notes';
import { CallReviewsDataTestId } from '@/types/data-test-id/call-reviews';
import { CallsDataTestId } from '@/types/data-test-id/calls';
import { CommentsDataTestId } from '@/types/data-test-id/comments';
import { HomeDataTestId } from '@/types/data-test-id/home';
import { LivenotesDataTestId } from '@/types/data-test-id/livenotes';
import { OnboardingDataTestId } from '@/types/data-test-id/onboarding';
import { PublicCallDetailsDataTestId } from '@/types/data-test-id/public-call-details';
import { SettingsDataTestId } from '@/types/data-test-id/settings';
import { SnippetsDataTestId } from '@/types/data-test-id/snippets';
import { SpectrumDataTestId } from '@/types/data-test-id/spectrum';
import { TiptapDataTestId } from '@/types/data-test-id/tiptap';
import { TranscriptDataTestId } from '@/types/data-test-id/transcript';
import { UsersDataTestId } from '@/types/data-test-id/users';

import { ActivitiesDataTestId } from './activities';
import { CallChapterDataTestId } from './call-chapters';
import { DealsDataTestId } from './deals';
import { ErrorsDataTestId } from './errors';
import { MediaPlayerDataTestId } from './media-player';
import { SidebarDataTestId } from './sidebar';

export const DataTestId = {
  COPY_URL_SHORTCUT_BUTTON_ID: 'copyUrlShortcutBtn',
  DISTANCE_DATE_ID: 'distanceDate',
  DISTANCE_DATE_TOOLTIP_ID: 'distanceDateTooltip',
  OPEN_SHARE_MODAL_BUTTON_ID: 'openShareModalBtn',
  COPY_EMBED_CODE_BUTTON_ID: 'copyEmbedCodeBtn',
  COPY_LINK_BUTTON_ID: 'copyLinkBtn',
  SHARING_ACCESS_RIGHT_BUTTON_ID: 'sharingAccessRightBtn',
  TAG_PILL_ID: 'tagPillContainer',
  WATCH_LATER_CALL_LIST_CONTAINER_ID: 'watchLaterCallListContainer',
  WATCH_LATER_CALL_LIST_ITEM_ID: 'watchLaterCallListItem',
  DIALOG_CONFIRM_ID: 'dialogConfirmButn',
  SEARCH_BUTTON_ID: 'searchBtn',
  SIGN_OUT_BUTTON_ID: 'signOutBtn',
  BUTTON_TRACKING_CALL_VIEWED_ID: 'buttonTrackingCallViewed',
  INPUT_TRACKING_CALL_VIEWED_ID: 'inputTrackingCallViewed',
  ...AuthenticationDataTestId,
  ...HomeDataTestId,
  ...CallsDataTestId,
  ...CommentsDataTestId,
  ...SnippetsDataTestId,
  ...UsersDataTestId,
  ...CallListItemDataTestId,
  ...CallReviewsDataTestId,
  ...SettingsDataTestId,
  ...SpectrumDataTestId,
  ...TranscriptDataTestId,
  ...LivenotesDataTestId,
  ...CallChapterDataTestId,
  ...MediaPlayerDataTestId,
  ...OnboardingDataTestId,
  ...TiptapDataTestId,
  ...CallNotesDataTestId,
  ...PublicCallDetailsDataTestId,
  ...ErrorsDataTestId,
  ...ActivitiesDataTestId,
  ...DealsDataTestId,
  ...SidebarDataTestId,
} as const;
