import React, { useEffect, useState } from 'react';

import { autoUpdate, offset, useFloating, flip } from '@floating-ui/react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import CheckIcon from '@/static/icons/basic/check-Filled.svg?react';
import { cn, isEmpty } from '@/utils/utils';

import { SimpleSelectProps } from './types';
import { Loading } from '../animations/Loading';
import { Avatar } from '../data-display/avatar';
import { MaybeInAPortal } from '../layout';
import {
  DROPDOWN_MARGIN,
  DROPDOWN_ZINDEX,
  getButtonOrInputStyles,
  getLabelStyles,
  getListOptionItemIconStyles,
  getListOptionItemLabelStyles,
  getListOptionItemNoResultStyles,
  getListOptionItemStyles,
  getListOptionsStyles,
} from '../styles';

const SimpleSelect = ({
  id,
  label,
  placeholder,
  size = 'large',
  disabled = false,
  options,
  optionSelected,
  setOptionSelected,
  emptyLabel = 'No data 👀',
  isError,
  isGhost = false,
  withPortal = true,
  isListWidthDynamic = false,
  button,
  dataTestIds,
  isLoading,
  placement = 'bottom-start',
  onOpenChange,
  hasBackground,
  dropdownWidth,
}: SimpleSelectProps) => {
  const { t } = useTranslation();

  const { refs, floatingStyles } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(DROPDOWN_MARGIN), flip()],
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isSelectOpen = !!floatingStyles.transform;
    // prevent too many event triggered
    if (isSelectOpen !== isOpen) {
      setIsOpen(isSelectOpen);
      onOpenChange?.(isSelectOpen);
    }
  }, [isOpen, setIsOpen, onOpenChange, floatingStyles.transform]);

  const isLarge = size === 'large';
  const displayedPlaceholder = optionSelected ? optionSelected.label : (placeholder ?? t('select'));

  return (
    <div id={id} className="flex flex-col">
      <Listbox
        // defaultValue instead of value props to avoid the console error "A component is changing from uncontrolled to controlled" for undefined value
        defaultValue={optionSelected}
        onChange={(value) => value && setOptionSelected(value)}
        disabled={disabled}
      >
        {({ open }) => (
          <>
            {label && <Label className={getLabelStyles(isLarge, disabled)}>{label}</Label>}

            <ListboxButton
              ref={refs.setReference}
              as={button ? 'div' : 'button'}
              data-test-id={dataTestIds?.button}
              className={clsx(
                getButtonOrInputStyles({
                  open,
                  isLarge,
                  disabled: disabled || isLoading,
                  isError,
                  isButton: !!button,
                  isGhost: isGhost || !!button,
                  hasValues: !!optionSelected,
                  hasEndIcons: !button,
                }),
                hasBackground && 'bg-grey-white',
                'relative'
              )}
            >
              {button || (
                <span className="flex items-center gap-x-2">
                  {optionSelected?.avatarProps && (
                    <Avatar
                      name={optionSelected.avatarProps.name}
                      src={optionSelected.avatarProps.src}
                      bgColor={optionSelected.avatarProps.bgColor}
                      border={false}
                    />
                  )}
                  <span className="block min-w-[16px] truncate">{displayedPlaceholder}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
                    {isLoading ? (
                      <Loading className="mr-1" size="tiny" dotColor="grey" />
                    ) : (
                      <ArrowDownChevronIcon
                        className={clsx('icon-small mr-1 fill-grey transition-transform', open && '-rotate-180')}
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </span>
              )}
            </ListboxButton>

            <MaybeInAPortal withPortal={withPortal}>
              <Transition
                show={open}
                as="div"
                className="absolute"
                enter="transition duration-150 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-100 ease-out"
                leaveFrom="transform translate-y-0 opacity-100"
                leaveTo="transform -translate-y-4 opacity-0"
                style={{ zIndex: DROPDOWN_ZINDEX }}
              >
                <ListboxOptions modal={false} portal={false} data-test-id={dataTestIds?.list} static>
                  <ul
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, width: dropdownWidth }}
                    className={cn(
                      getListOptionsStyles(isLarge, false, isListWidthDynamic || !!button || isGhost),
                      'relative z-[9999] w-[var(--button-width)]'
                    )}
                  >
                    {isEmpty(options) ? (
                      <li key="no-option" className={getListOptionItemNoResultStyles()}>
                        <span>{emptyLabel}</span>
                      </li>
                    ) : (
                      options.map((option) => {
                        const selectedOption = optionSelected?.id === option.id;
                        return (
                          <ListboxOption
                            key={option.id}
                            as="li"
                            value={option}
                            className={getListOptionItemStyles(selectedOption, disabled)}
                          >
                            <span className="flex items-center gap-x-2">
                              {option.avatarProps && (
                                <Avatar
                                  name={option.avatarProps.name}
                                  src={option.avatarProps.src}
                                  bgColor={option.avatarProps.bgColor}
                                  border={false}
                                />
                              )}
                              <span className={getListOptionItemLabelStyles(selectedOption)}>{option.label}</span>
                              {selectedOption && (
                                <span className={getListOptionItemIconStyles()}>
                                  <CheckIcon className="icon-small" aria-hidden />
                                </span>
                              )}
                            </span>
                          </ListboxOption>
                        );
                      })
                    )}
                  </ul>
                </ListboxOptions>
              </Transition>
            </MaybeInAPortal>
          </>
        )}
      </Listbox>
    </div>
  );
};

export { SimpleSelect };
