import React, { useState, createContext, useContext, useMemo } from 'react';

import { FiltersCategories, getOptionType } from '@/components/common/search-components/Common';
import {
  SearchableObject,
  SearchableObjectOptionType,
} from '@/components/common/search-components/search-components.types';

type ContextProps = {
  selectedFilters: SearchableObject[];
  searchedFilters: SearchableObject[];
  setSelectedFilters: (values: SearchableObject[]) => void;
  updateContextFilters: (values: SearchableObject[], forSearchedFilters?: boolean) => void;
  deleteContextFilters: (
    category?: FiltersCategories,
    values?: SearchableObject[],
    forSearchedFilters?: boolean
  ) => void;
};

const SearchContext = createContext<ContextProps>({
  selectedFilters: [],
  searchedFilters: [],
  setSelectedFilters: () => null,
  updateContextFilters: () => null,
  deleteContextFilters: () => null,
});
SearchContext.displayName = 'SearchContext';

type ProviderProps = {
  children?: React.ReactNode;
};

export const SearchContextProvider = ({ children }: ProviderProps) => {
  const [selectedFilters, setSelectedFilters] = useState<SearchableObject[]>([]);
  const [searchedFilters, setSearchedFilters] = useState<SearchableObject[]>([]);

  const addFiltersAndRemoveDuplicateValues = (values: SearchableObject[], forSearchedFilters?: boolean) => {
    const updateFilters = forSearchedFilters ? setSearchedFilters : setSelectedFilters;
    const withoutDuplicateSingleSearchable = values.reduce<SearchableObject[]>((withoutDup, sO) => {
      if (
        getOptionType(sO) !== SearchableObjectOptionType.CALL_TITLE ||
        !withoutDup.some((processedSO) => getOptionType(processedSO) === SearchableObjectOptionType.CALL_TITLE)
      ) {
        withoutDup.push(sO);
      }
      return withoutDup;
    }, []);

    updateFilters((previousState) => [
      ...previousState.filter((f) =>
        values.some((v) => (getOptionType(f) === getOptionType(v) ? f.id && f.id === v.id : true))
      ),
      ...withoutDuplicateSingleSearchable.filter(
        (f) => !previousState.some((v) => getOptionType(f) === getOptionType(v) && f.id && f.id === v.id)
      ),
    ]);
  };

  const deleteContextFilters = (
    category?: FiltersCategories,
    values?: SearchableObject[],
    forSearchedFilters?: boolean
  ) => {
    if (!category && !values) {
      return;
    }
    const categoryToDelete = category ?? null;
    const updateFilters = forSearchedFilters ? setSearchedFilters : setSelectedFilters;
    if (categoryToDelete) {
      updateFilters((previousState) => previousState.filter((item) => getOptionType(item) !== categoryToDelete));
    } else if (values) {
      updateFilters((previousState) =>
        previousState.filter((item) => !values.some((value) => value.id === item.id && value.name === item.name))
      );
    }
  };

  const value: ContextProps = useMemo(
    () => ({
      selectedFilters,
      searchedFilters,
      setSelectedFilters,
      updateContextFilters: addFiltersAndRemoveDuplicateValues,
      deleteContextFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilters, searchedFilters]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchContext');
  }
  return context;
};
