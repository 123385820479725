import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { queryKeys } from '@/entities/queryKeys';
import { DEFAULT_CALL_HISTORY_PAGE_SIZE } from '@/enums/constants';
import { CustomInfiniteQueryOptions, CustomQueryOptions } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam } from '@/utils/utils';

import {
  CONTACTS_API_BASE_URL,
  CONTACTS_CALL_HISTORY_API_URL,
  CONTACTS_CREATE_CRM_LINK_API_URL,
  CONTACTS_SEARCH_JOB_TITLES_API_URL,
} from './contacts.constant';
import { CallHistory, JobTitle } from './contacts.types';

export const useCreateContactCrmLinkQuery = (contactId: number) => {
  return useQuery({
    queryKey: queryKeys.contacts.byContactId(contactId),
    queryFn: async () => {
      const { data } = await apiService.API.get<string>(
        combineRoutes([CONTACTS_API_BASE_URL, contactId.toString(), CONTACTS_CREATE_CRM_LINK_API_URL])
      );
      return data;
    },
  });
};

export const useSearchContactsJobTitlesQuery = <TSelectData = JobTitle[]>(
  query: string,
  minLengthForSearch = 3,
  options?: CustomQueryOptions<JobTitle[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.contactsJobTitles.byQuery(query),
    enabled: options?.queryOptions?.enabled !== false && query.length >= minLengthForSearch,
    queryFn: async () => {
      const { data } = await apiService.API.get<JobTitle[]>(
        combineRoutes([CONTACTS_API_BASE_URL, CONTACTS_SEARCH_JOB_TITLES_API_URL]),
        {
          params: { q: query },
        }
      );
      return data;
    },
  });
};

type ContactsCallHistoryQueryParams<TSelectData = InfiniteData<PaginationRequestResult<CallHistory>, number>> = {
  contactIds: number[];
  limit?: number;
} & CustomInfiniteQueryOptions<PaginationRequestResult<CallHistory>, TSelectData>;

export const useContactsCallHistoryQuery = <T = InfiniteData<PaginationRequestResult<CallHistory>, number>>({
  contactIds,
  limit = DEFAULT_CALL_HISTORY_PAGE_SIZE,
  queryOptions,
}: ContactsCallHistoryQueryParams<T>) => {
  return useInfiniteQuery({
    queryKey: queryKeys.callHistory.byContactIds(contactIds),
    queryFn: async ({ pageParam }) => {
      const { data } = await apiService.API.get<PaginationRequestResult<CallHistory>>(
        combineRoutes([CONTACTS_API_BASE_URL, CONTACTS_CALL_HISTORY_API_URL]),
        {
          params: { page: pageParam, perPage: limit, contactIds },
        }
      );
      const result: PaginationRequestResult<CallHistory> = {
        pagination: data.pagination,
        values: data.values.map((value) => ({
          ...value,
          date: new Date(value.date),
        })),
      };
      return result;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, limit),
    ...queryOptions,
  });
};
