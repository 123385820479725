export const UsersDataTestId = {
  CREATE_USER_SET_TEAMS_BUTTON_ID: 'createUserSetTeamBtn',
  CREATE_USER_SET_ROLE_BUTTON_ID: 'createUserSetRoleBtn',
  CREATE_USER_SET_ROLE_LIST_ID: 'createUserSetRoleList',
  CREATE_USER_SUBMIT_BUTTON_ID: 'createUserSubmitBtn',
  CREATE_USER_EMAIL_TXT_ID: 'createUserEmailTxt',
  USER_PROFILE_FIRST_NAME_TXT_ID: 'userProfileFirstNameTxt',
  USER_PROFILE_LAST_NAME_TXT_ID: 'userProfileLastNameTxt',
  USER_PROFILE_EMAIL_TXT_ID: 'userProfileEmailTxt',
  USER_PROFILE_SUBMIT_BUTTON_ID: 'userProfileSubmitBtn',
};
