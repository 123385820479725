import { useCallback, useEffect } from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AlertVariant } from '@/components/common/toast/alert';
import { createCustomToast, clearToast } from '@/components/common/toast/ToastHelpers';
import { getProviderDisplayName } from '@/components/providers/icons/ProvidersIcon';
import { useUserHealthCheckDownIntegrationsQuery } from '@/entities/providers/scopes/providersScopes.queries';
import { UserIntegration } from '@/entities/providers/scopes/providersScopes.types';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { HealthCheckReason, HealthCheckWarning, RoutePaths } from '@/enums';
import { useCurrentRoute } from '@/hooks/useCurrentRoute';
import { useTabActive } from '@/hooks/useTabActive';
import { Event } from '@/tracking/events';
import { isEmailProvider, Provider } from '@/types/providers';
import { listify } from '@/utils/array-utils';
import { isOlderThan24Hours } from '@/utils/date-utils';
import { isEmpty } from '@/utils/utils';

import { healthCheckStore } from './healthCheck.store';

export const USER_HEALTH_CHECK_TOAST_ID = 'user-health-check';
export const USER_HEALTH_CHECK_EMAIL_BANNER_TOAST_ID = 'user-health-check-email';

const getProviderNames = (providers: UserIntegration[]) => {
  if (isEmpty(providers)) {
    return [];
  }
  return providers.map(({ provider }) => getProviderDisplayName(provider));
};

export const useUserHealthCheckBanner = () => {
  const currentUserQuery = useCurrentUserQuery();
  const userHealthCheckDownIntegrationsQuery = useUserHealthCheckDownIntegrationsQuery();
  const isTabVisible = useTabActive(false);
  const pagesWithoutBanner = useCurrentRoute([RoutePaths.ONBOARDING, RoutePaths.SETTINGS_PROFILE]);
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const hideEmailIntegrationBannerStore = healthCheckStore.useHideEmailIntegrationBanner();

  const showEmailBanner = useCallback(
    (providerName: Provider) => {
      createCustomToast({
        message: t('banner.emailNotImported'),
        variant: AlertVariant.EmailDown,
        buttonProps: {
          label: t('banner.connectEmailIntegration', { providerName: getProviderDisplayName(providerName) }),
          onClickClose: () => {
            if (healthCheckStore) {
              mixpanel.track(Event.CLICK_POSTPONE_EMAIL_HEALTH_CHECK, {
                emailProviderName: providerName,
              });
              healthCheckStore.setHideEmailIntegrationBanner(new Date());
            }
          },
          providerName,
          onClick: () => {
            mixpanel.track(Event.CLICK_CTA_HEALTH_CHECK_SNACKBAR_REDIRECTION, {
              warningType: HealthCheckWarning.INTEGRATION,
              errorType: HealthCheckReason.USER_FLOW_MISSING_SCOPES,
              integrationName: 'email',
            });
            navigate(RoutePaths.SETTINGS_PROFILE);
          },
        },
        toastOptions: { toastId: USER_HEALTH_CHECK_EMAIL_BANNER_TOAST_ID },
      });
    },
    [navigate, t]
  );

  const showBanner = useCallback(
    (providerNames: string[]) => {
      if (isEmpty(providerNames)) {
        return;
      }
      const message = t('banner.callNotImported', { providerName: listify(providerNames) });

      createCustomToast({
        message,
        variant: AlertVariant.Down,
        buttonProps: {
          label: 'Connect integration',
          onClick: () => {
            mixpanel.track(Event.CLICK_CTA_HEALTH_CHECK_SNACKBAR_REDIRECTION, {
              warningType: HealthCheckWarning.INTEGRATION,
              errorType: HealthCheckReason.USER_FLOW_MISSING_SCOPES,
              integrationName: providerNames,
            });
            navigate(RoutePaths.SETTINGS_PROFILE);
          },
        },
        toastOptions: { toastId: USER_HEALTH_CHECK_TOAST_ID },
      });
    },
    [navigate, t]
  );

  useEffect(() => {
    /**
     * Fetch when:
     * - re-focusing on Modjo tab (isTabVisible equals to false) AND
     * - current location is not a blacklisted page (to avoid useless fetch)
     */
    if (!isTabVisible && !pagesWithoutBanner) {
      userHealthCheckDownIntegrationsQuery.refetch();
    }
  }, [userHealthCheckDownIntegrationsQuery, pagesWithoutBanner, isTabVisible]);

  useEffect(() => {
    // Skip banner for users without license for the moment
    if (!currentUserQuery.data?.hasLicense) {
      return;
    }

    if (hideEmailIntegrationBannerStore && !isOlderThan24Hours(hideEmailIntegrationBannerStore)) {
      clearToast(USER_HEALTH_CHECK_EMAIL_BANNER_TOAST_ID);
    }

    if (pagesWithoutBanner) {
      clearToast(USER_HEALTH_CHECK_TOAST_ID);
    } else if (
      userHealthCheckDownIntegrationsQuery.isSuccess &&
      userHealthCheckDownIntegrationsQuery.data.length > 0 &&
      !toast.isActive(USER_HEALTH_CHECK_TOAST_ID)
    ) {
      const emailProviderName = userHealthCheckDownIntegrationsQuery.data.find(({ provider }) =>
        isEmailProvider(provider)
      )?.provider;

      const shouldShowEmailBanner =
        hideEmailIntegrationBannerStore === null || isOlderThan24Hours(hideEmailIntegrationBannerStore);

      if (emailProviderName && shouldShowEmailBanner) {
        showEmailBanner(emailProviderName);
      } else {
        const providersName = userHealthCheckDownIntegrationsQuery.data.filter(
          (integrationDown) => !isEmailProvider(integrationDown.provider)
        );
        showBanner(getProviderNames(providersName));
      }
    }
  }, [
    userHealthCheckDownIntegrationsQuery.isSuccess,
    userHealthCheckDownIntegrationsQuery.data,
    pagesWithoutBanner,
    currentUserQuery.data,
    showBanner,
    showEmailBanner,
    hideEmailIntegrationBannerStore,
  ]);
};
