import { useTranslation } from 'react-i18next';

import { SelectOption } from '@/components/common/ui-components/types';
import { ALL_FILTERS_OPTION_ID, NO_FILTERS_OPTION_ID } from '@/components/settings/constants';
import { useTeamsQuery } from '@/entities/teams/teams.queries';
import { sortTeams, toTeamsOptions } from '@/entities/teams/teams.selects';
import { isEmpty } from '@/utils/utils';

export const useTeamsOptions = (options?: {
  withAllTeams?: boolean;
  withNoTeams?: boolean;
  excludedIds?: number[];
  disabledIds?: number[];
}) => {
  const { withAllTeams = true, withNoTeams = false, excludedIds, disabledIds } = options ?? {};
  const { t } = useTranslation();
  const allTeamsOption: SelectOption = {
    id: ALL_FILTERS_OPTION_ID,
    label: t('allTeams'),
  };
  const noTeamOption: SelectOption = {
    id: NO_FILTERS_OPTION_ID,
    label: t('withoutTeams'),
  };
  const teamsOptions = useTeamsQuery({
    queryOptions: {
      select: (data) => toTeamsOptions(data, disabledIds),
    },
  });
  if (teamsOptions.data) {
    return [
      ...(withAllTeams ? [allTeamsOption] : []),
      ...(withNoTeams ? [noTeamOption] : []),
      ...teamsOptions.data.filter(({ id }) => !excludedIds?.includes(Number.parseInt(id, 10))),
    ];
  }
  return [...(withAllTeams ? [allTeamsOption] : [])];
};

export const useSortedTeamsQuery = () => {
  return useTeamsQuery({
    queryOptions: {
      select: sortTeams,
    },
  });
};

export const useTeamsNames = (teamIds: number[]): string[] => {
  const teamsQuery = useTeamsQuery();

  if (!teamsQuery.isSuccess || isEmpty(teamIds)) {
    return [];
  }

  return teamIds.map((id) => teamsQuery.data.get(id)?.name).filter((name): name is string => !!name);
};
