import React, { forwardRef, Fragment, MutableRefObject, useRef } from 'react';

import clsx from 'clsx';
import { useToggle } from 'react-use';

import { cn } from '@/utils/utils';

import { InputWithAdornmentsProps } from './types';
import { getButtonOrInputBaseStyles, getLabelStyles } from '../../styles';
import { ElementPosition, Position } from '../../types';

const getAdornmentStyles = (position: ElementPosition, disabled?: boolean, canClick?: boolean) =>
  clsx('flex-center whitespace-nowrap', {
    'pointer-events-none opacity-50': disabled && !canClick,
    'pr-2': position === Position.START,
    'pl-2': position === Position.END,
  });

const handleClickAdornments = (
  toggleInputFocus: (nextValue?: boolean) => void,
  inputReference?: MutableRefObject<HTMLInputElement | null>
): Pick<React.HTMLAttributes<HTMLDivElement>, 'onBlur' | 'onClick'> => ({
  onClick: () => {
    inputReference?.current?.focus();
    toggleInputFocus(true);
  },
});

const InputWithAdornments = forwardRef<HTMLDivElement, InputWithAdornmentsProps>(
  (
    {
      dataTestId,
      label,
      name,
      size = 'large',
      isError,
      inputProps,
      startAdornment,
      endAdornment,
      helperText,
      className,
      pill = true,
      disableFocusRing = false,
      inputReference,
      canClickWhileDisabled,
      ...otherProps
    },
    reference
  ) => {
    const [isInputFocused, toggleInputFocus] = useToggle(false);
    const localInputReference = useRef<HTMLInputElement>(null);
    const currentInputReference = inputReference || localInputReference;

    const isDisabled = inputProps?.disabled;
    const isLarge = size === 'large';

    const ContainerWrapper = label || isDisabled ? 'div' : Fragment;

    return (
      <ContainerWrapper>
        {label && (
          <label className={getLabelStyles(isLarge, isDisabled)} htmlFor={name}>
            {label}
          </label>
        )}
        <div
          className={cn(
            'flex transition-all duration-150 focus:outline-none',
            {
              'rounded-full': pill,
              'pl-2.5': startAdornment,
              'pr-2.5': endAdornment,
              'ring-grey-100': !isInputFocused && !disableFocusRing,
              'ring-blue': isInputFocused && !disableFocusRing && !isError,
              'ring-red': isError,
              'ring-1 ring-inset': !disableFocusRing,
              'bg-grey-white': !isDisabled,
              'bg-grey-50': isDisabled,
            },
            className
          )}
          {...otherProps}
          ref={reference}
        >
          {startAdornment && (
            <div
              className={getAdornmentStyles(Position.START, inputProps?.disabled)}
              {...handleClickAdornments(toggleInputFocus, currentInputReference)}
            >
              {startAdornment}
            </div>
          )}
          <input
            data-test-id={dataTestId}
            {...(label && { id: name })}
            {...inputProps}
            ref={currentInputReference}
            onFocus={(event) => {
              toggleInputFocus(true);
              otherProps?.onFocus && otherProps.onFocus(event);
            }}
            onBlur={(event) => {
              toggleInputFocus(false);
              otherProps?.onBlur && otherProps.onBlur(event);
            }}
            disabled={isDisabled}
            className={cn(
              'w-full font-medium disabled:bg-transparent placeholder:text-grey-600',
              getButtonOrInputBaseStyles({ isLarge, disabled: isDisabled }),
              {
                'px-2.5': !startAdornment && !endAdornment,
                'pr-3': startAdornment && !endAdornment,
                'pl-3': endAdornment && !startAdornment,
                'rounded-full': isDisabled,
              },
              isDisabled ? 'text-grey-300' : 'bg-transparent text-grey-900',
              inputProps?.className
            )}
          />
          {endAdornment && (
            <div
              className={getAdornmentStyles(Position.END, isDisabled, canClickWhileDisabled)}
              {...handleClickAdornments(toggleInputFocus, currentInputReference)}
            >
              {endAdornment}
            </div>
          )}
        </div>
        {helperText}
      </ContainerWrapper>
    );
  }
);

export { InputWithAdornments };
