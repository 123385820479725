import React, { PropsWithChildren, useMemo } from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';

import { DataTestIdProps, ProductTourIdProps } from '@/types/common';
import { cn } from '@/utils/utils';

import { SidebarButtonContext } from './SidebarButtonContext';

type SidebarButtonContainerProps = PropsWithChildren<
  (
    | ({
        variant: 'link';
        path: string;
        state?: NavLinkProps['state'];
        isActivePage: boolean;
        isSidebarOpen: boolean;
        onClick: () => void;
      } & ProductTourIdProps)
    | {
        variant: 'menu';
        isMenuOpen?: boolean;
        isSidebarOpen: boolean;
        onClick?: () => void;
      }
  ) &
    DataTestIdProps
>;

const SidebarButtonContainer = ({ children, dataTestId, ...props }: SidebarButtonContainerProps) => {
  const Wrapper =
    props.variant === 'link'
      ? ({ children: _children, className }: PropsWithChildren<{ className: string }>) => (
          <NavLink
            to={props.path}
            state={props.state}
            className={cn(
              props.isActivePage
                ? 'bg-blue shadow-card outline-offset-2 focus:outline-blue'
                : 'outline-none hover:bg-grey-100 focus:bg-grey-100',
              'group',
              className
            )}
            data-test-id={dataTestId}
            onClick={props.onClick}
            data-product-tour-id={props.productTourId}
          >
            {_children}
          </NavLink>
        )
      : ({ children: _children, className }: PropsWithChildren<{ className: string }>) => (
          <button
            onClick={props.onClick}
            type="button"
            className={cn(
              'outline-none transition-all hover:bg-grey-100 focus:bg-grey-100',
              props.isMenuOpen && 'bg-grey-200',
              className
            )}
            data-test-id={dataTestId}
          >
            {_children}
          </button>
        );

  const providerValues = useMemo(
    () => ({ isSidebarOpen: props.isSidebarOpen, isActivePage: props.variant === 'link' ? props.isActivePage : false }),
    [props]
  );

  return (
    <SidebarButtonContext.Provider value={providerValues}>
      <Wrapper className="flex w-full items-center gap-x-2 rounded-lg p-2 transition-all">{children}</Wrapper>
    </SidebarButtonContext.Provider>
  );
};

SidebarButtonContainer.displayName = 'SidebarButtonContainer';

export { SidebarButtonContainer };
