import { useTranslation } from 'react-i18next';

import { SelectOption } from '@/components/common/ui-components/types';
import { ALL_FILTERS_OPTION_ID, NO_FILTERS_OPTION_ID } from '@/components/settings/constants';
import { useTagsQuery } from '@/entities/tags/tags.queries';
import { toTagsOptions, toTagsProviderOptions } from '@/entities/tags/tags.selects';

import { useEmptyTag } from './tags.types';

export const useTagsProviderOptions = () => {
  const { t } = useTranslation();
  const allProvidersOption = {
    id: ALL_FILTERS_OPTION_ID,
    label: t('allProviders'),
  };
  const providerOptions = useTagsQuery({
    queryOptions: {
      select: toTagsProviderOptions,
    },
  });
  if (providerOptions.data) {
    return [allProvidersOption, ...providerOptions.data];
  }
  return [allProvidersOption];
};

export const useTagsOptions = (options?: { withAllTags?: boolean; withEmptyTag?: boolean }) => {
  const { withAllTags = true, withEmptyTag = false } = options ?? {};
  const { t } = useTranslation();
  const tagOptions = useTagsQuery({ queryOptions: { select: toTagsOptions } });
  const emptyTag = useEmptyTag();

  const allTagsOption: SelectOption = {
    id: ALL_FILTERS_OPTION_ID,
    label: t('allTags'),
  };
  const emptyTagOption: SelectOption = {
    id: NO_FILTERS_OPTION_ID,
    label: emptyTag.name,
  };
  const allTagsItem = withAllTags ? [allTagsOption] : [];
  const emptyTagItem = withEmptyTag ? [emptyTagOption] : [];
  if (tagOptions.data) {
    return [...allTagsItem, ...emptyTagItem, ...tagOptions.data];
  }
  return [...allTagsItem, ...emptyTagItem];
};
