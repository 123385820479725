import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useCurrentUser } from '@/entities/users/users.hooks';
import { RoutePaths, UserRole } from '@/enums';
import { hasRole } from '@/utils/user-utils';

type RoleRouteProps = {
  minRoleRequired?: UserRole;
};

export const RoleRoute = ({ minRoleRequired }: RoleRouteProps) => {
  const currentUser = useCurrentUser();

  if (minRoleRequired && !(currentUser && hasRole(currentUser.role, minRoleRequired))) {
    return <Navigate to={RoutePaths.UNAUTHORIZED} />;
  }

  return <Outlet />;
};
