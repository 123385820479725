import React from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import MicrophoneNoAccessDesktop from '@/static/MicrophoneNoAccessDesktop.svg?react';
import MicrophoneNoAccessMobile from '@/static/MicrophoneNoAccessMobile.svg?react';

export const MeetingRecorderNoAccess = () => {
  const { t } = useTranslation();
  return (
    <div className="flex-center flex h-full flex-col gap-y-3 overflow-hidden px-8 py-10">
      {isMobile ? <MicrophoneNoAccessMobile /> : <MicrophoneNoAccessDesktop />}
      <span className="font-semibold">{t('meetingRecorder.noAccess')}</span>
      <span className="text-center">{t('meetingRecorder.noAccessDescription')}</span>
    </div>
  );
};
