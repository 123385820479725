import {
  IntegrationProvidersSettings,
  IntegrationType,
  isIntegrationProvider,
} from '@/components/settings/integrations-providers.type';
import { ApiProvider } from '@/entities/providers/providers.types';
import { ProviderDataStatus } from '@/enums';
import { Provider, Providers } from '@/types/providers';

export const getIntegrationProviders = (providers: ApiProvider[]) =>
  providers.filter((p) => isIntegrationProvider(p.provider));

export const hasGoogleMeet = (providers: ApiProvider[]) =>
  providers.some(({ provider }) => provider === Providers.GOOGLE_USER_FLOW || provider === Providers.GOOGLE);

export const hasMicrosoftTeams = (providers: ApiProvider[]) =>
  providers.some(
    ({ provider }) => provider === Providers.MICROSOFT_USER_FLOW || provider === Providers.MICROSOFT_TENANT_FLOW
  );

export const getProviderByName = (providers: ApiProvider[], providerName: Provider) =>
  providers.find(({ provider }) => provider === providerName);

export const providerHasSomeTypes = (name: Provider, providerTypes: IntegrationType[]) => {
  return providerTypes.some(
    (type) => isIntegrationProvider(name) && IntegrationProvidersSettings[name].types.includes(type)
  );
};
export const isIntegratedWithCRM = (providers: ApiProvider[]) => {
  return Boolean(
    providers.some(
      ({ provider, status }) =>
        (status === ProviderDataStatus.UP || status === ProviderDataStatus.DOWN) &&
        providerHasSomeTypes(provider, [IntegrationType.CRM])
    )
  );
};
