import { SelectOption } from '@/components/common/ui-components/types';

import { Team } from './teams.types';
import { isEmpty } from '../../utils/utils';

export const toTeamsOptions = (teams: Map<number, Team>, disabledIds?: number[]) =>
  [...teams.values()]
    .map(
      (team) => ({ id: team.id.toString(), label: team.name, disabled: disabledIds?.includes(team.id) }) as SelectOption
    )
    .sort((left, right) => left.label.localeCompare(right.label));

export const sortTeams = (teams: Map<number, Team>) =>
  [...teams.values()].sort((left, right) => left.name.localeCompare(right.name));

export const getTeamsWithRestriction = (teams: Map<number, Team>) =>
  [...teams.values()].filter((team) => !isEmpty(team.abacRules));
