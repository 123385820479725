/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from './utils';
import { ListFormatOptions } from '../types/intl-formatter';

/**
 * Split an array of elements T into multiple chunks of defined size.
 *
 * @param {T[]} array Array of elements T to chunk.
 * @param {number} chunkSize Size of chunk. By default 10.
 * @returns Array of chunks.
 */
export function chunk<T>(array: T[], chunkSize: number) {
  const defaultChunkSize = chunkSize <= 0 ? 10 : chunkSize;
  const result = [];
  const { length } = array;
  for (let index = 0; index < length; index += defaultChunkSize) {
    result.push(array.slice(index, index + defaultChunkSize));
  }
  return result;
}

/**
 * Take an array of ids and remove all duplicates.
 *
 * @param {number[]} ids The collection of ids.
 *
 * @returns An array with uniqueIds.
 */
export const uniqueIds = (ids: number[]) => [...new Set(ids.filter((id) => Number.isFinite(id)))];

/**
 * Take a collection and group items related to the same key. The key is extracted from each item.
 *
 * @param {T[]} list The collection to group by.
 * @param keyGetter The function apply to every item to extract the key.
 *
 * @returns A map of sub-collections of list grouped by key.
 */
export function groupBy<T, K extends number | string>(list: T[], keyGetter: (item: T) => K): Map<K, T[]> {
  return list.reduce((map, item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (collection) {
      collection.push(item);
    } else {
      map.set(key, [item]);
    }
    return map;
  }, new Map<K, T[]>());
}

/**
 * Extract all items from a collection based on a list of IDs
 *
 * @param {T[]} array The collection to extract from.
 * @param {number[]} ids The list of ids to extract.
 *
 * @returns A sub-collection of the original collection match an id.
 */
export function extractFromIds<T extends { id: number }>(array: T[], ids: number[]): T[] {
  return ids.reduce((accumulator: T[], id) => {
    const matchingItem = array.find((element) => element.id === id);
    if (matchingItem) {
      accumulator.push(matchingItem);
    }
    return accumulator;
  }, []);
}

/**
 * Take a collection and provide a sub collection with unique items depending on the key
 *
 * When there are duplicates, it will only keep the first ones.
 *
 * @param {T[]} array The collection to distinct.
 * @param getKey The function apply to every item to extract the value of the key.
 *
 * @returns A sub-collections where all element are unique from the key point of view.
 */
export function uniqueByKey<T>(array: T[], getKey: (item: T) => (typeof item)[keyof T]): T[] {
  return array.filter(
    (element, index, selfArray) => selfArray && selfArray.findIndex((t) => getKey(t) === getKey(element)) === index
  );
}

/**
 * Take a potential array and give it back if it is not empty. It returns undefined otherwise
 *
 * When there are duplicates, it will only keep the first ones.
 *
 * @param {T[]} array The potential array.
 *
 * @returns The array if not empty, undefined otherwise
 */
export const undefinedIfEmptyArray = <T>(array: T[]): T[] | undefined => (isEmpty(array) ? undefined : array);

/**
 * Remove duplicate values and empty ones
 *
 * @param list array of string to clean
 *
 * @returns an cleaned array
 */
export function cleanArray(list: string[]): string[] {
  return list.reduce((newList: string[], item: string) => {
    const cleanItem = item.trim();
    if (cleanItem !== '' && !newList.includes(cleanItem)) {
      newList.push(cleanItem);
    }
    return newList;
  }, []);
}

/**
 * Remove the element at index indexToRemove from an array and returns it. The original array will not be modified.
 *
 * @param array {T[]} The array to process
 * @param indexToRemove {number} The index of the element to remove. It must be within array's range.
 *
 * @returns {T[]} The original array without the element at indexToRemove.
 */
export function removeElementAtIndex<T>(array: T[], indexToRemove: number): T[] {
  if (indexToRemove < 0 || indexToRemove > array.length) {
    throw new Error(
      `Index to remove: ${indexToRemove} must be a valid index included in the array's range of length: ${array.length}`
    );
  }
  return array.filter((_, itemIndex) => itemIndex !== indexToRemove);
}

/**
 * Insert conditionally element in array if condition is fullfiled.
 *
 * @param condition Condition to fullfil
 * @param elements Elements to insert
 *
 * @returns {T[]}
 */
export function insertIf<T>(condition: boolean, ...elements: T[]): T[] {
  return condition ? elements : [];
}

type ListifyOptions<T extends { toString(): string }> = {
  type?: ListFormatOptions['type'];
  style?: ListFormatOptions['style'];
  stringify?: (item: T) => string;
};

/**
 * Listifies a collection taking advantage of internationalization standard so that we can use many locales.
 * For now, as Modjo is only in English, we'll use by default 'en' as the prefered locale.
 *
 * @param {T[]} array The collection of T type to listify.
 * @param options Options
 * @returns {string} A lisfified string of the collection.
 */
export function listify<T extends { toString(): string }>(
  array: Array<T>,
  { type = 'conjunction', style = 'long', stringify = (item: T) => item.toString() }: ListifyOptions<T> = {}
): string {
  const stringified = array.map((item) => stringify(item));
  const formatter = new Intl.ListFormat('en', { style, type });
  return formatter.format(stringified);
}

export function splitArray<T>(array: T[], size = 4): [T[], T[]] {
  const firstChunk = array.slice(0, size);
  const secondChunk = array.slice(size);
  return [firstChunk, secondChunk];
}
