import React, { PropsWithChildren } from 'react';

import AlertIconFilled from '@/static/icons/chat/comment-2-alert-Filled.svg?react';
import AlertIcon from '@/static/icons/chat/comment-2-alert-Red.svg?react';
import { cn } from '@/utils/utils';

type RootAlertProps = PropsWithChildren<{ className?: string }>;

type AlertProps = RootAlertProps & {
  severity: 'warning' | 'error';
};

const ErrorAlert = ({ children, className }: RootAlertProps) => (
  <div className={cn('flex items-start gap-x-3 rounded-2xl border border-grey-100 p-4 shadow', className)}>
    <AlertIcon className="w-5 shrink-0" />
    {children}
  </div>
);

const WarningAlert = ({ children, className }: RootAlertProps) => (
  <div className={cn('flex items-start gap-x-3 rounded-2xl border border-grey-100 p-4 shadow', className)}>
    <AlertIconFilled className="w-5 shrink-0 fill-orange" />
    {children}
  </div>
);

const DefaultAlert = ({ children, severity, className }: AlertProps) =>
  severity === 'warning' ? (
    <WarningAlert className={className}>{children}</WarningAlert>
  ) : (
    <ErrorAlert className={className}>{children}</ErrorAlert>
  );

export const Alert = Object.assign(DefaultAlert, {
  Warning: WarningAlert,
  Error: ErrorAlert,
});
