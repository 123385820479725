import React, { useContext } from 'react';

import clsx from 'clsx';

import ArrowLeftChevronIcon from '@/static/icons/arrows/chevron-right-Line.svg?react';
import { cn } from '@/utils/utils';

import { SidebarButtonContext } from './SidebarButtonContext';

type SidebarButtonIconProps = {
  lineIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  filledIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  hasViews?: boolean;
  active?: boolean;
  onClick?: () => void;
  className?: string;
};

const SidebarButtonIcon = ({
  lineIcon,
  filledIcon,
  active,
  onClick,
  hasViews = false,
  className,
}: SidebarButtonIconProps) => {
  const { isSidebarOpen, isActivePage } = useContext(SidebarButtonContext);

  const Icon = isActivePage && !!filledIcon ? filledIcon : lineIcon;

  return (
    <>
      <Icon
        className={cn(
          'icon-small shrink-0',
          {
            'fill-grey-white': isActivePage,
            'fill-grey-800': !isActivePage && isSidebarOpen,
            'fill-grey-900': !isActivePage && !isSidebarOpen,
            'group-hover:opacity-0': hasViews && isSidebarOpen,
          },
          className
        )}
      />
      {hasViews && (
        <button
          type="button"
          className="flex-center absolute"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onClick?.();
          }}
        >
          <ArrowLeftChevronIcon
            className={clsx('icon-small shrink-0 rounded opacity-0', {
              hidden: !isSidebarOpen,
              'fill-grey-white hover:bg-blue-600': isActivePage,
              'fill-grey-800 hover:bg-grey-100': !isActivePage,
              'group-hover:opacity-100': isSidebarOpen,
              'rotate-90': active,
            })}
          />
        </button>
      )}
    </>
  );
};

SidebarButtonIcon.displayName = 'SidebarButtonIcon';

export { SidebarButtonIcon };
