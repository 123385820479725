import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { queryKeys } from '@/entities/queryKeys';
import {
  toSearchableTagObjects,
  toSortedTagByTeamIds,
  toSortedTagsByLength,
  toTagColors,
  toTagsCount,
  toTagsMap,
} from '@/entities/tags/tags.selects';
import { SortByTagsFields, Tag, TagsTableQueryParams, useEmptyTag } from '@/entities/tags/tags.types';
import { CustomQueryOptions } from '@/types/common';
import { SortOrders } from '@/types/sort';
import { toNumber } from '@/utils/utils';

import { ALL_PROVIDERS_OPTION, MODJO_TAGS_OPTION } from '../../components/settings/constants';

export const useTagsQuery = <TSelectData = Tag[]>(options?: CustomQueryOptions<Tag[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.tags.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<Tag[]>(`/tags`);
      return data;
    },
    ...options?.queryOptions,
  });
};

export const useTagsMapQuery = () => {
  return useTagsQuery({
    queryOptions: { select: toTagsMap },
  });
};

export const useTableTagsQuery = (params: TagsTableQueryParams) => {
  return useTagsQuery({
    queryOptions: {
      select: useCallback(
        (tags: Tag[]) => {
          return tags
            .filter((tag) => {
              const isInTeam = params.teamIdFilter ? tag.teamIds?.includes(toNumber(params.teamIdFilter)) : true;

              const isPhoneProviderFiltered = Boolean(params.providerIdFilter);
              const isAllProviders = params.providerIdFilter === ALL_PROVIDERS_OPTION.id;
              const isModjo = !tag.phoneProvider && params.providerIdFilter === MODJO_TAGS_OPTION.id;
              const isSelectedProvider = tag.phoneProvider && params.providerIdFilter === tag.phoneProvider;
              const isPhoneProvider = !isPhoneProviderFiltered || isAllProviders || isModjo || isSelectedProvider;

              return isInTeam && isPhoneProvider;
            })
            .toSorted((a: Tag, b: Tag) => {
              const isOrderAsc = params.sortOrder.toLowerCase() === SortOrders.ASC;

              switch (params.sortBy) {
                case SortByTagsFields.NAME: {
                  return isOrderAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                }
                case SortByTagsFields.PROVIDER: {
                  return isOrderAsc
                    ? a.phoneProvider && b.phoneProvider
                      ? a.phoneProvider.localeCompare(b.phoneProvider)
                      : a.phoneProvider
                        ? -1
                        : 1
                    : b.phoneProvider && a.phoneProvider
                      ? b.phoneProvider.localeCompare(a.phoneProvider)
                      : b.phoneProvider
                        ? -1
                        : 1;
                }
                case SortByTagsFields.TEAMS: {
                  return isOrderAsc
                    ? a.teamIds && b.teamIds
                      ? a.teamIds.length - b.teamIds.length
                      : a.teamIds
                        ? -1
                        : 1
                    : b.teamIds && a.teamIds
                      ? b.teamIds.length - a.teamIds.length
                      : b.teamIds
                        ? -1
                        : 1;
                }
                default: {
                  const exhaustiveCheck: never = params.sortBy;
                  throw new Error(exhaustiveCheck);
                }
              }
            });
        },
        [params]
      ),
    },
  });
};

export const useTagsCountQuery = () => {
  return useTagsQuery({
    queryOptions: {
      select: toTagsCount,
    },
  });
};

export const useTagOptionsQuery = () => {
  return useTagsQuery({
    queryOptions: {
      select: toSearchableTagObjects,
    },
  });
};

export const useSortedTagByTeamIdsQuery = (
  teamIds: number[],
  options?: { addEmptyTag?: boolean; excludeExternalTags?: boolean }
) => {
  const emptyTag = useEmptyTag();
  return useTagsQuery({
    queryOptions: {
      select: useCallback(
        (tags: Tag[]) => {
          const tagsWithEmpty = options?.addEmptyTag ? [emptyTag, ...tags] : tags;
          return toSortedTagByTeamIds(tagsWithEmpty, teamIds, options);
        },
        [teamIds, options, emptyTag]
      ),
    },
  });
};

const sortedTagListing = (tags: Tag[]) => {
  return tags.filter((t) => !t.exclude).sort((a, b) => a.name.localeCompare(b.name));
};

export const useSortedTagListingQuery = () => {
  return useTagsQuery({
    queryOptions: {
      select: sortedTagListing,
    },
  });
};

export const useSortedTagByLengthQuery = (filter: (tags: Tag[]) => Tag[]) => {
  return useTagsQuery({
    queryOptions: {
      select: useCallback((tags: Tag[]) => toSortedTagsByLength(tags, filter), [filter]),
    },
  });
};

export const useTagColorsQuery = () => {
  return useTagsQuery({
    queryOptions: {
      select: toTagColors,
    },
  });
};
