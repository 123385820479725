import React from 'react';

import { FieldValues, NativeFieldValue, Path, useController } from 'react-hook-form';

import { SimpleAutoComplete, SimpleAutoCompleteProps } from '../ui-components/autocompletes';

function retrieveValue(value: NativeFieldValue) {
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString();
  }
  return undefined;
}

type FormAutocompleteProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  onChange?: (value: string) => void;
} & Omit<SimpleAutoCompleteProps, 'setSelectedOptionId' | 'selectedOptionId'>;

export const FormAutocomplete = <T extends Record<string, unknown>>({
  name,
  options,
  onChange,
  disabled,
  ...restProps
}: FormAutocompleteProps<T>) => {
  const { field } = useController({ name });

  return (
    <div className="pb-5">
      <SimpleAutoComplete
        name={field.name}
        {...restProps}
        disabled={disabled}
        options={options}
        {...(!disabled && { selectedOptionId: retrieveValue(field.value) })}
        setSelectedOptionId={(value) => {
          field.onChange(value);
          onChange?.(value);
        }}
      />
    </div>
  );
};
