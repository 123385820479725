export const SettingsDataTestId = {
  TAGS_ADD_NEW_BUTTON_ID: 'tagsAddNewBtn',
  TOPICS_ADD_NEW_BUTTON_ID: 'topicsAddNewBtn',
  SAVE_INTEGRATION_BUTTON_ID: 'saveIntegrationBtn',
  USER_INTEGRATION_SELECT_ID: 'userIntegrationSelect',
  USER_INTEGRATION_SELECT_CROSS_ID: 'userIntegrationSelectCross',
  REMINDER_EMAIL_EDIT_BUTTON_ID: 'reminderEmailEditBtn',
  REMINDER_EMAIL_SWITCH_BUTTON_ID: 'reminderEmailSwitchBtn',
  REMINDER_EMAIL_RECEIVE_TEST_SETTINGS_BUTTON_ID: 'reminderEmailReceiveTestEmailSettingsBtn',
  REMINDER_EMAIL_RECEIVE_TEST_BUTTON_ID: 'reminderEmailReceiveTestEmailBtn',
  REMINDER_EMAIL_SAVE_BUTTON_ID: 'reminderEmailSaveBtn',
  REMINDER_EMAIL_SUBJECT_SECTION_ID: 'reminderEmailSubjectSection',
  REMINDER_EMAIL_FIRST_PARAGRAPH_SECTION_ID: 'reminderEmailFirstParagraphSection',
  REMINDER_EMAIL_MEETING_INFORMATION_SECTION_ID: 'reminderEmailMeetingInformationSection',
  REMINDER_EMAIL_SECOND_PARAGRAPH_SECTION_ID: 'reminderEmailSecondParagraphSection',
  REMINDER_EMAIL_SUBJECT_INPUT_ID: 'reminderEmailSubjectInput',
  REMINDER_EMAIL_FIRST_PARAGRAPH_CONTENT_INPUT_ID: 'reminderEmailFirstParagraphContentInput',
  REMINDER_EMAIL_MEETING_INFORMATION_CONTENT_INPUT_ID: 'reminderEmailMeetingInformationContentInput',
  REMINDER_EMAIL_MEETING_INFORMATION_BUTTON_INPUT_ID: 'reminderEmailMeetingInformationButtonInput',
  REMINDER_EMAIL_SECOND_PARAGRAPH_CONTENT_INPUT_ID: 'reminderEmailSecondParagraphInput',
  REMINDER_EMAIL_SECOND_PARAGRAPH_LEGAL_FOOTER_INPUT_ID: 'reminderEmailSecondParagraphLegalFooterInput',
  REMINDER_EMAIL_SUBJECT_PREVIEW_ID: 'reminderEmailSubjectPreview',
  REMINDER_EMAIL_FIRST_PARAGRAPH_CONTENT_PREVIEW_ID: 'reminderEmailFirstParagraphContentPreview',
  REMINDER_EMAIL_MEETING_INFORMATION_CONTENT_PREVIEW_ID: 'reminderEmailMeetingInformationContentPreview',
  REMINDER_EMAIL_MEETING_INFORMATION_BUTTON_PREVIEW_ID: 'reminderEmailMeetingInformationButtonPreview',
  REMINDER_EMAIL_SECOND_PARAGRAPH_CONTENT_PREVIEW_ID: 'reminderEmailSecondParagraphPreview',
  REMINDER_EMAIL_SECOND_PARAGRAPH_LEGAL_FOOTER_PREVIEW_ID: 'reminderEmailSecondParagraphLegalFooterPreview',
  CALL_IMPORT_RESTRICTIONS_BUTTON_ID: 'domainNamesEditBtn',
  CUSTOM_VOCABULARY_ADD_NEW_BUTTON_ID: 'customVocabularyAddNewBtn',
  SAVE_CUSTOM_VOCABULARY_BUTTON_ID: 'saveCustomVocabularyBtn',
  CUSTOM_VOCABULARY_TABLE_ID: 'customVocabularyTable',
  CUSTOM_VOCABULARY_EDIT_ID: 'customVocabularyEditBtn',
  CUSTOM_VOCABULARY_DELETE_ID: 'customVocabularyDeleteBtn',
  RESTRICT_CALL_ACCESS_EDIT_BUTTON_ID: 'restrictCallAccessEditBtn',
  RESTRICT_CALL_ACCESS_SWITCH_BUTTON_ID: 'restrictCallAccessSwitchBtn',
  EXPIRATION_LINK_SWITCH_BUTTON_ID: 'expirationLinkSwitchBtn',
};
