import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomQueryOptions } from '@/types/common';

import { UserSessionMetadataDto } from './user-session.types';
import { queryKeys } from '../queryKeys';

export const USER_SESSIONS_API_BASE_URL = '/user-sessions';

const useUserSessionQuery = <TSelectData = UserSessionMetadataDto>(
  options?: CustomQueryOptions<UserSessionMetadataDto, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.userSession.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<UserSessionMetadataDto>(`${USER_SESSIONS_API_BASE_URL}/me`);
      return data;
    },
    ...options?.queryOptions,
  });
};

export { useUserSessionQuery };
