import { Account } from './account.types';

const sortAccounts = (accounts: Account[]) => {
  if (accounts) {
    return accounts.sort((accountA, accountB) => accountA.name.localeCompare(accountB.name));
  }
  return [];
};

export { sortAccounts };
