import React, { Fragment, useContext, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

import { DataTestIdProps } from '@/types/common';
import { DataTestId } from '@/types/data-test-id';
import { ExcludedTypes } from '@/utils/type-utils';

import { ActionsFooter, DialogBackdrop, DialogAnimatedPanel } from './Common';
import { DialogContext } from './DialogContext';
import { MobileDrawer } from './MobileDrawer';
import { CommonDialogProps, ActionsProps } from './types';

type ConfirmationDialogProps = CommonDialogProps & ActionsProps & DataTestIdProps;

const ConfirmationDialog = ({
  dataTestId,
  children,
  dialogId,
  title,
  titlePosition,
  dialogSize = 'small',
  onCancel,
  freezeClosing,
  nested,
  ...otherProps
}: ConfirmationDialogProps) => {
  // Little hack to disable initialFocus. Put initial focus on title.
  const fakeReference = useRef(null);

  const { dialogProps, setOpenDialog, dialogs } = useContext(DialogContext);
  const actionsFooterProps: Partial<ExcludedTypes<ConfirmationDialogProps, CommonDialogProps>> = {
    buttonSize: otherProps?.buttonSize ?? 'small',
    type: otherProps?.type ?? 'save',
    buttonsPosition: otherProps?.buttonsPosition ?? 'center',
    onCancel,
    ...otherProps,
  };

  const handleOnCloseDialog = (id: string) => {
    if (!freezeClosing) {
      onCancel && onCancel();
      setOpenDialog(false, id);
    }
  };

  if (isMobile) {
    return (
      <MobileDrawer
        dialogId={dialogId}
        handleClose={handleOnCloseDialog}
        nested={nested}
        isOpen={Boolean(dialogs.get(dialogId))}
        title={title}
        onReturn={onCancel}
        actionsFooterProps={actionsFooterProps}
      >
        {children}
      </MobileDrawer>
    );
  }

  return (
    <Transition appear show={Boolean(dialogs.get(dialogId))} as={Fragment}>
      <Dialog
        data-test-id={dataTestId}
        {...dialogProps}
        className="relative z-50"
        initialFocus={fakeReference}
        onClose={() => handleOnCloseDialog(dialogId)}
      >
        {/* Backdrop */}
        <DialogBackdrop />

        {/* Animated Dialog Panel */}
        <DialogAnimatedPanel dialogSize={dialogSize}>
          <Dialog.Title
            ref={fakeReference}
            className={clsx(
              'flex shrink-0 px-5 pb-3 pt-5 text-lg font-semibold',
              titlePosition === 'start' ? 'justify-start' : 'justify-center'
            )}
          >
            {title}
          </Dialog.Title>

          <Dialog.Description as="div" className="px-5 py-2 text-center text-sm">
            {children}
          </Dialog.Description>
          <div className="px-5 pb-7">
            <ActionsFooter
              {...actionsFooterProps}
              onCancel={() => handleOnCloseDialog(dialogId)}
              dataTestId={DataTestId.DIALOG_CONFIRM_ID}
            />
          </div>
        </DialogAnimatedPanel>
      </Dialog>
    </Transition>
  );
};

export { ConfirmationDialog };
export type { ConfirmationDialogProps };
