import { RouteObject, redirect } from 'react-router-dom';

import { DatePeriods } from '@/components/common/DatePeriodFilter/date-period-filter.constant';
import {
  AvailableMetrics,
  isActivitiesMetric,
  isCallMetric,
  isInteractionsMetric,
  isReviewsMetric,
} from '@/entities/analytics/analytics.types';
import { RoutePaths } from '@/enums';
import { i18n } from '@/translation/i18n';

import { AnalyticsPages } from '../routes.enum';

export const analyticsRoutes: RouteObject = {
  path: RoutePaths.ANALYTICS,
  async lazy() {
    const { AnalyticsLayout } = await import('@/components/analytics');
    return {
      Component: AnalyticsLayout,
      loader: async () => {
        // make sure the namespace is loaded before rendering the related routes
        await i18n.loadNamespaces(['analytics']);
        return null;
      },
    };
  },
  children: [
    {
      path: RoutePaths.WILD_CARD,
      index: true,
      loader: () => {
        /**
         * redirect to the default analytics page you need to define the defautl parameters
         * because the router is resolved before rendering the components if you do it after you may have some missing paramters
         * caused by the batch updates of searchParams that are done before the parameter actually changeg in the url
         *  and some update will override other.
         */
        const searchParams = new URLSearchParams({
          period: DatePeriods.ONE_MONTH,
          metric: AvailableMetrics.TOTAL_COUNT,
        });
        return redirect(`${AnalyticsPages.CALLS}?${searchParams.toString()}`);
      },
    },
    {
      path: AnalyticsPages.CALLS,
      async lazy() {
        const { CallsPage } = await import('@/components/analytics/calls-page');
        return {
          Component: CallsPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            const metric = url.searchParams.get('metric');
            if (!isCallMetric(metric)) {
              url.searchParams.set('metric', AvailableMetrics.TOTAL_COUNT);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
    {
      path: AnalyticsPages.INTERACTION,
      async lazy() {
        const { InteractionPage } = await import('@/components/analytics/interaction-page');
        return {
          Component: InteractionPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            const metric = url.searchParams.get('metric');
            if (!isInteractionsMetric(metric)) {
              url.searchParams.set('metric', AvailableMetrics.AVG_TALK_RATIO);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
    {
      path: AnalyticsPages.TOPICS,
      async lazy() {
        const { TopicsAndWordsPage } = await import('@/components/analytics/topics-and-words-page');
        return {
          Component: TopicsAndWordsPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            const metric = url.searchParams.get('metric');
            if (!isInteractionsMetric(metric)) {
              url.searchParams.set('metric', AvailableMetrics.AVG_TALK_RATIO);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
    {
      path: AnalyticsPages.ACTIVITIES,
      async lazy() {
        const { ActivitiesPage } = await import('@/components/analytics/ActivitiesPage');
        return {
          Component: ActivitiesPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            const metric = url.searchParams.get('metric');
            if (!isActivitiesMetric(metric)) {
              url.searchParams.set('metric', AvailableMetrics.CALLS_LISTENED);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
    {
      path: AnalyticsPages.REVIEWS,
      async lazy() {
        const { ReviewsPage } = await import('@/components/analytics/ReviewsPage');
        return {
          Component: ReviewsPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            const metric = url.searchParams.get('metric');
            if (!isReviewsMetric(metric)) {
              url.searchParams.set('metric', AvailableMetrics.REVIEWS_RECEIVED);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
    {
      path: AnalyticsPages.AI_SCORING,
      async lazy() {
        const { AiScoringPage } = await import('@/components/analytics/AiScoringPage');
        return {
          Component: AiScoringPage,
          loader: ({ request }) => {
            const url = new URL(request.url);
            if (url.searchParams.get('metric') !== AvailableMetrics.AI_SCORING) {
              url.searchParams.set('metric', AvailableMetrics.AI_SCORING);
              return redirect(url.toString());
            }
            return null;
          },
        };
      },
    },
  ],
};
