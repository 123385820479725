import { useMemo } from 'react';

import { compareAsc, compareDesc, secondsToMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { callDetailsSidebarStore } from '@/components/call/CallDetailsPage/CallDetailsPageSidebar/call-details-sidebar.store';
import { UNKNOWN_SPEAKER_LABEL } from '@/enums/constants';
import { colorSets } from '@/theme/colors';
import { CustomSuspenseQueryOptions } from '@/types/common';
import { toNumber } from '@/utils/utils';

import { useCallDetailsQuery } from './call-details.queries';
import {
  CallDetails,
  ThreadComment,
  isPublicCallDetails,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPrivateCallDetails,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPublicSnippetCallDetails,
  SpeakerWithDetails,
  ThreadNote,
} from './call-details.types';
import { adjustTimeStamp, getSpeakerDetails, getTeamNamesByUserId, sortListeners } from './call-details.utils';
import { useCommentFromNoteBlockQuery } from '../comments/comments.queries';
import { Comment } from '../comments/comments.types';
import { useCallDetailsNotesByIdQuery } from '../notes-calls/notes-calls.hooks';
import { useCurrentUserQuery, useUserByIdQuery } from '../users/users.queries';

/**
 * Hook for fetching call-details data. It uses `useCallDetailsQuery` under the hood which is a `useSuspenseQuery` hook
 * so it means the data is always defined and it needs to be used within both a `Suspense` and an `Error` boundary.
 *
 * This hook is used for:
 * - Private call-details
 * - Public call-details
 * - Public snippets
 * The return type `CallDetails` is an union of `PrivateCallDetails`, `PublicCallDetails` and `PublicSnippetCallDetails`.
 * In practice, this means you might have to check the type of the data before using it using the provided type guards {@link isPublicCallDetails},
 * {@link isPrivateCallDetails} and {@link isPublicSnippetCallDetails}. Example: {@link useCommentById} because public call-details doesn't have comments.
 *
 * @template TSelectData - The type of the selected data. Return value of `select` function. Is infered from the `queryOptions`.
 * @param {CustomSuspenseQueryOptions<CallDetails, TSelectData>} [queryOptions] - The query options from `react-query`
 * @returns {CallDetails} - The call details data.
 *
 * @example
 * ```typescript
 * const callDetails = useCallDetails();
 * ```
 */
export const useCallDetails = <TSelectData = CallDetails>(
  queryOptions?: CustomSuspenseQueryOptions<CallDetails, TSelectData>
) => {
  const callDetailsQuery = useCallDetailsQuery(queryOptions);

  return callDetailsQuery.data;
};

export const useCommentById = (commentId: number | string | undefined | null): Comment | undefined => {
  const callDetails = useCallDetails();

  if (isPublicCallDetails(callDetails)) {
    return undefined;
  }

  return callDetails.comments.find((comment) => comment.id === commentId);
};

export const useRootComments = () => {
  const callDetails = useCallDetails();

  if (isPublicCallDetails(callDetails)) {
    return [];
  }

  return callDetails.comments
    .filter((comment) => comment.replyToCommentId === null)
    .sort((a, b) => {
      const aSnippet = callDetails.snippets.find((snippet) => snippet.id === a.snippetId);
      const bSnippet = callDetails.snippets.find((snippet) => snippet.id === b.snippetId);

      const aStartTime = aSnippet?.startTime ? secondsToMilliseconds(aSnippet.startTime) : a.timestamp;
      const bStartTime = bSnippet?.startTime ? secondsToMilliseconds(bSnippet.startTime) : b.timestamp;

      if (aStartTime !== null && bStartTime !== null) {
        return aStartTime - bStartTime;
      }

      return compareAsc(new Date(a.createdOn), new Date(b.createdOn));
    });
};

export const useReplyComments = (commentId: number | string | null) => {
  const callDetails = useCallDetails();

  if (!commentId || isPublicCallDetails(callDetails)) {
    return [];
  }

  return callDetails.comments
    .filter((comment) => comment.replyToCommentId === commentId)
    .sort((a, b) => compareDesc(new Date(a.createdOn), new Date(b.createdOn)));
};

export const useNoteBlockComments = () => {
  const openedThreadNoteBlock = callDetailsSidebarStore.useOpenedThreadNoteBlock();
  const noteId = openedThreadNoteBlock?.noteId;
  const noteQuery = useCallDetailsNotesByIdQuery(noteId, !!noteId);

  const commentIds = openedThreadNoteBlock?.noteBlockUuid
    ? (noteQuery.data?.commentCount[openedThreadNoteBlock.noteBlockUuid] ?? [])
    : [];

  const commentsByIdsQuery = useCommentFromNoteBlockQuery({
    blockUuid: openedThreadNoteBlock?.noteBlockUuid ?? '',
    commentIds,
    queryOptions: {
      enabled: !!openedThreadNoteBlock?.noteBlockUuid && commentIds.length > 0,
    },
  });

  return commentsByIdsQuery.data ?? [];
};

export const useCommentBySnippetId = (snippetId: number | undefined) => {
  const callDetails = useCallDetails();

  if (!snippetId || isPublicCallDetails(callDetails)) {
    return undefined;
  }

  return callDetails.comments.find((comment) => comment.snippetId === snippetId);
};

const useThreadComment = (): ThreadComment | undefined => {
  const commentId = callDetailsSidebarStore.useOpenedThreadCommentId();
  const rootComments = useRootComments();
  const commentReplies = useReplyComments(commentId);

  const commentRepliedTo = rootComments.find((comment) => comment.id === commentId);

  if (!commentRepliedTo) {
    return undefined;
  }

  return {
    commentId: typeof commentRepliedTo.id === 'string' ? toNumber(commentRepliedTo.id) : commentRepliedTo.id,
    timestamp: commentRepliedTo.timestamp,
    content: commentRepliedTo.content,
    replies: commentReplies,
    isFocusOnReply: true,
  };
};

const useThreadNote = (): ThreadNote | undefined => {
  const openedThreadNoteBlock = callDetailsSidebarStore.useOpenedThreadNoteBlock();
  const noteBlockComments = useNoteBlockComments();

  if (!openedThreadNoteBlock) {
    return undefined;
  }

  const threadNote: ThreadNote = {
    noteId: openedThreadNoteBlock.noteId,
    blockUuid: openedThreadNoteBlock.noteBlockUuid,
    timestamp: adjustTimeStamp(openedThreadNoteBlock.nodeBlockComment.timestamp),
    content: openedThreadNoteBlock.nodeBlockComment.content,
    replies: noteBlockComments,
    isFocusOnReply: openedThreadNoteBlock.isFocusOnReply,
  };

  return threadNote;
};

export const useThread = (): ThreadComment | ThreadNote | undefined => {
  const threadComment = useThreadComment();
  const threadNote = useThreadNote();

  if (threadComment) {
    return threadComment;
  }
  if (threadNote) {
    return threadNote;
  }
  return undefined;
};

export const useCallDetailsSnippetById = (snippetId: number | null) => {
  const callDetails = useCallDetails();

  if (!snippetId || isPublicCallDetails(callDetails)) {
    return undefined;
  }

  return callDetails.snippets.find(({ id }) => id === snippetId);
};

export const useCallDetailsOwner = () => {
  const { ownerId } = useCallDetails();
  return useUserByIdQuery(ownerId ?? 0).data;
};

export const useCallDetailsAttendeeIds = (): number[] => {
  const callDetails = useCallDetails();
  return useMemo(() => (isPublicCallDetails(callDetails) ? [] : callDetails.attendeeIds), [callDetails]);
};

export const useIsCurrentUserAnAttendee = (): boolean => {
  const currentUserQuery = useCurrentUserQuery();
  const attendeeIds = useCallDetailsAttendeeIds();
  const { ownerId } = useCallDetails();

  if (!currentUserQuery.isSuccess) {
    return false;
  }

  const currentUserId = currentUserQuery.data.id;
  return ownerId === currentUserId || attendeeIds.includes(currentUserId);
};

export const useCurrentUserTypeForCallDetails = (): string => {
  const isCurrentUserAnAttendee = useIsCurrentUserAnAttendee();
  const { t } = useTranslation('callDetails');
  return isCurrentUserAnAttendee ? t('call.attendee') : t('call.notAttendee');
};

export const useSpeakerName = (speakerId?: number) => {
  const { speakers, contacts, users } = useCallDetails();
  if (!speakerId) {
    return null;
  }
  const speaker = speakers.find((s) => s.id === speakerId);
  if (!speaker) {
    return UNKNOWN_SPEAKER_LABEL;
  }
  return getSpeakerDetails(speaker, contacts, users).name;
};

export const useActiveSpeakers = () => {
  const { speakers } = useCallDetails();
  return speakers.filter((speaker) => speaker.talkRatio);
};

export const useActiveSpeakersWithDetails = (): SpeakerWithDetails[] => {
  const { speakers, contacts, users, teams } = useCallDetails();
  return speakers
    .filter((speaker) => speaker.talkRatio)
    .map((speaker) => {
      const speakerDetails = getSpeakerDetails(speaker, contacts, users);
      return {
        ...speaker,
        name: speakerDetails.name,
        jobTitle: speakerDetails?.jobTitle,
        teamNames: getTeamNamesByUserId(speaker.userId, teams),
      };
    });
};

export type PossibleSwapSpeaker = {
  id: number;
  name: string;
  type: 'user' | 'contact' | 'speaker';
};

export const usePossibleSwapSpeakers = (): PossibleSwapSpeaker[] => {
  const { contacts, users, ownerId } = useCallDetails();
  const attendeeIds = useCallDetailsAttendeeIds();
  const possibleSpeakersFromContacts = contacts.map((contact) => ({
    id: contact.id,
    name: contact.name,
    type: 'contact' as const,
  }));
  const possibleSpeakersFromUsers = users.reduce<PossibleSwapSpeaker[]>((acc, user) => {
    // To exclude users used for snippet, library item, listeners ...
    if ([...attendeeIds, ownerId].includes(user.id)) {
      acc.push({
        id: user.id,
        name: user.name,
        type: 'user' as const,
      });
    }
    return acc;
  }, []);
  return [...possibleSpeakersFromContacts, ...possibleSpeakersFromUsers];
};

export const useColorSpeakerMap = () => {
  const activeSpeakers = useActiveSpeakers();
  const colors = colorSets.colorBySpeaker;
  const colorBySpeaker = new Map<number, string>();

  for (const [index, speaker] of activeSpeakers.entries()) {
    colorBySpeaker.set(speaker.id, colors[index % colors.length]);
  }

  return colorBySpeaker;
};

export const useCallDetailsSortedListeners = () => {
  const { listeners } = useCallDetails();

  return sortListeners(listeners);
};
