import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { apiService } from '@/api.service';
import { createToast } from '@/components/common/toast/ToastHelpers';
import { combineRoutes } from '@/utils/url-utils';

import {
  AddRecordingBotsToMeetingMutationParams,
  AddRecordingBotsToMeetingMutationResponse,
  AddRecordingBotsToMeetingMutationErrors,
  AddRecordingBotsToMeetingMutationError,
} from './recording-bots.types';
import { AlertVariant } from '../../components/common/toast/alert';

const RECORDING_BOTS_API_BASE_URL = '/recording-bots';
export const RECORDING_BOTS_MEETING_API_URL = combineRoutes([RECORDING_BOTS_API_BASE_URL, 'meeting']);
const useAddRecordingBotsToMeetingMutation = () => {
  const { t } = useTranslation('home');
  return useMutation({
    mutationFn: async (body: AddRecordingBotsToMeetingMutationParams) => {
      const { data } = await apiService.API.post<AddRecordingBotsToMeetingMutationResponse>(
        RECORDING_BOTS_MEETING_API_URL,
        body
      );
      return data;
    },
    onSuccess: (data) => {
      const message = t('addRecorderBot.dialog.form.toasts.success', { name: data.name });
      createToast(message, AlertVariant.Success);
    },
    onError: (error: AddRecordingBotsToMeetingMutationError) => {
      if (error?.response) {
        const { code, reason } = error.response.data.error;
        switch (code) {
          case AddRecordingBotsToMeetingMutationErrors.NO_LICENSE:
          case AddRecordingBotsToMeetingMutationErrors.NOT_PART_OF_CALENDAR_EVENT:
          case AddRecordingBotsToMeetingMutationErrors.NOT_INTEGRATED_TO_RECORDING_BOT_PROVIDER: {
            const message = t(`addRecorderBot.dialog.form.toasts.${code}`);
            createToast(message, AlertVariant.Info);
            break;
          }
          case AddRecordingBotsToMeetingMutationErrors.NOT_FOUND_MEETING_URL:
          case AddRecordingBotsToMeetingMutationErrors.NOT_FOUND_USER:
          case AddRecordingBotsToMeetingMutationErrors.NOT_FOUND_TENANT:
          case AddRecordingBotsToMeetingMutationErrors.CALENDAR_EVENT_ALREADY_FINISHED: {
            const message = t(`addRecorderBot.dialog.form.toasts.${code}`);
            createToast(message, AlertVariant.Warning);
            break;
          }
          default: {
            reason && createToast(reason, AlertVariant.Error);
            break;
          }
        }
      } else {
        throw error;
      }
    },
  });
};

export { useAddRecordingBotsToMeetingMutation };
