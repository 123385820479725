import React from 'react';

import { flip, offset as floatingOffset, shift, useFloating } from '@floating-ui/react';
import { Menu as MenuHUI, Transition } from '@headlessui/react';

import { MenuItems } from './MenuItems';
import { MenuProps } from './types';
import { MaybeInAPortal } from '../layout';

const Menu = ({
  Button,
  items,
  Item,
  List,
  onButtonClick,
  onClickAway,
  offset = 4,
  placement = 'bottom',
  withPortal = true,
  dataTestId,
  floatingShiftOptions,
}: MenuProps) => {
  const { refs, floatingStyles } = useFloating({
    placement,
    middleware: [floatingOffset(offset), flip(), shift(floatingShiftOptions)],
  });

  return (
    <MenuHUI>
      {({ open, close }) => (
        <>
          <MenuHUI.Button
            as="div"
            className="flex-center"
            onClick={(event) => {
              event.stopPropagation();
              onButtonClick && onButtonClick(event);
              if (open) {
                event.preventDefault();
                close();
              }
            }}
            aria-haspopup={open ? 'true' : 'false'}
            ref={refs.setReference}
          >
            {Button && Button(open)}
          </MenuHUI.Button>
          <MaybeInAPortal withPortal={withPortal}>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="-translate-y-3 transform opacity-0"
              enterTo="translate-y-0 transform opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="translate-y-0 transform opacity-100"
              leaveTo="-translate-y-3 transform opacity-0"
              afterLeave={() => {
                onClickAway && onClickAway();
              }}
              as="div"
              className="relative"
            >
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="rounded-lg border border-grey-100 bg-grey-white shadow-card-hover"
                data-test-id={dataTestId}
              >
                <MenuItems items={items} Item={Item} List={List} />
              </div>
            </Transition>
          </MaybeInAPortal>
        </>
      )}
    </MenuHUI>
  );
};

export { Menu };
