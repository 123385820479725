import { PropsWithChildren, cloneElement } from 'react';

type MultiProviderProps = PropsWithChildren<{
  providers: JSX.Element[];
}>;

/**
 * Component that aggregates providers given into props.
 * Avoid to nest multiple providers, indentation becomes hard to read.
 */
export const MultiProvider = ({ children, providers }: MultiProviderProps) => {
  let content = children || null;

  const numberOfProviders = providers.length;
  if (!numberOfProviders) {
    return content;
  }

  for (const provider of providers) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    content = cloneElement(provider, provider.props, content);
  }

  return content;
};
