import { CrmEntityType, CrmPropertyTypeBase } from '@/enums/crm-fields';
import { CurrencyCode } from '@/enums/currency';
import { LanguageCode } from '@/enums/languages';
import { ObjectValues } from '@/types/common';
import { CRMProvider, Provider } from '@/types/providers';
import { SortOrder } from '@/types/sort';

import { ActivityType, CalendarEvent } from '../activities/activity.types';
import { AiTopicCategory } from '../ai-topics/ai-topics.types';
import { DealWarning } from '../deal-warnings/deal-warnings.types';

export type DealInteraction = {
  uuid: string;
  startDate: Date;
  type: ActivityType;
  restricted: boolean;
};

export type DealField = string | number | boolean | Array<string> | Array<number>;
export type CrmFieldColumn = { sourceKey: string; type: CrmPropertyTypeBase; label: string; createdOn: Date };

export type ApiDealSearchResult = {
  id: number;
  name: string;
  amount: number | null;
  currency: CurrencyCode | null;
  stage: string | null;
  lastStageChangeDate: Date | null;
  status: string | null;
  closeDate: string | null;
  startDate: string | null;
  type: string | null;
  ownerId: number | null;
  account?: { id: number; name: string };
  activityTimeline: DealInteraction[];
  warnings: DealWarning[];
  contactsCount: number;
  objectionsCount: number;
  nextMeetingDate: string | null;
  fields: Record<string, DealField> | null;
  modifiedOn: Date;
};

export type ApiDealSearchParams = {
  filter: {
    name?: string;
    userIds?: number[];
    teamIds?: number[];
    closeDateRange?: {
      start: string;
      end: string;
    };
    status?: string[];
    forecastCategory?: string[];
    warningUuids?: string[];
    aiTopicUuids?: string[];
    stage?: string[];
    types?: string[];
  };
  order: Record<string, SortOrder>;
};

export type Deal = {
  id: number;
  crmId: string;
  name: string;
  crmLink: string;
  crm: CRMProvider;
  status: string;
  stage: string | null;
  amount?: number;
  currency?: CurrencyCode;
  lossReason?: string;
  source?: string;
  closeDate?: string;
};

export const DEFAULT_DEAL_CURRENCY = 'EUR';

export type DealInfo = {
  account: {
    name: string;
    id: number;
  } | null;
  id: number;
  name: string;
  type: string | null;
  status: string | null;
  stage: string | null;
  lastStageChangeDate: Date | null;
  forecastCategoryName: string | null;
  fields: Record<string, number | string | boolean | Array<string> | Array<number>> | null;
  closeDate: Date | null;
  amount: number | null;
  startDate: Date | null;
  nextStep: string | null;
  crmLink: string;
  currency: CurrencyCode | null;
  ownerId: number | null;
  modifiedOn: Date;
};

export type DealNextMeeting = CalendarEvent | null;

export const CrmDealStatus = {
  OPEN: 'Open',
  CLOSED_WON: 'Closed won',
  CLOSED_LOST: 'Closed lost',
  /** Used when we are unable to determine if the deal is won or lost */
  DELETED: 'Deleted',
} as const;

export const DealOverviewTypes = {
  STATUS: 'status',
  FORECAST_CATEGORY: 'forecastCategory',
} as const;

type DealOverviewType = ObjectValues<typeof DealOverviewTypes>;

type CurrencySum = {
  currency: string | null;
  sum: number;
};

export type DealOverview = {
  label: string;
  currencySums: CurrencySum[];
  type: DealOverviewType;
  warningCount: number;
};

export type DealSummary = {
  uuid: string;
  content: {
    type: string;
    value: string;
  }[];
  dealId: number;
  lastUpdatedOn: string;
  language: LanguageCode;
  hasUserAlreadyDoneFeedback: boolean;
};

export type DealContactRole = {
  id: string;
  label: string;
  order: number;
};

export type DealSummaryFeedback = {
  isHelpful: boolean;
  hasInformationMissing?: boolean;
  hasIncorrectInformation?: boolean;
  isHelpfulDetails?: string;
  hasMissingInformationDetails?: string;
  hasIncorrectInformationDetails?: string;
};

export type ApiActivityProviderEmailParticipant = {
  userId: number;
  email: string;
  isUser: boolean;
  name: string | null;
};

export type ProviderEmail = {
  providerId: string;
  provider: Provider;
  date: Date;
  subject: string;
  rawBody: string;
  hashId: string;
  textBody: string;
  htmlBody: string;

  participants: {
    sender: ApiActivityProviderEmailParticipant;
    recipients: (ApiActivityProviderEmailParticipant & { type: 'to' | 'cc' | 'bcc' })[];
  };
};

export type DealObjectionsWithMentions = {
  name: string;
  uuid: string;
  lastMention: Date;
  totalMentions: number;
};

export type DealAiTopics = Record<AiTopicCategory, DealObjectionsWithMentions[]>;

export type ContactDealInfo = {
  activities: {
    uuid: string;
    type: ActivityType;
  }[];
  activityStats: {
    lastActivityDate: Date | null;
  };
  callStats: {
    totalCallsDurationSeconds: number;
    totalSpeakingTimeSeconds: number;
  };
  aiTopicsDetected: {
    categoryName: AiTopicCategory;
    aiTopics: DealObjectionsWithMentions[];
  }[];
};

export type DealContact = {
  id: number;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  role?: {
    id: string;
    label: string;
  } | null;
  jobTitle: string | null;
  crmId: string | null;
  crmLink: string | null;
  dealInfo: ContactDealInfo;
};

export type DealContactsWithObjections = {
  lastUpdatedOn: Date | null;
  contacts: DealContact[];
};

export type DealCrmInfo = {
  id: Deal['id'];
  crm: CRMProvider;
  crmId: Deal['crmId'];
};

export type DealCRMEntityType = CrmEntityType & CrmPropertyTypeBase;

export type DealCRMField = {
  entityType: CrmEntityType;
  label: string;
  crmPropertyId: string;
  crmPropertyKey: string;
  required: boolean;
  editable: boolean;
  supported: boolean;
  custom: boolean;
  validation: {
    type: DealCRMEntityType;
    defaultValue: (number | string | boolean) | null;
  };
};

export type DealCRMEntityField = {
  uuid: string;
  label: string;
  entityType: CrmEntityType;
  type: DealCRMEntityType;
  source: string;
  sourceKey: string;
  createdOn: Date;
};
