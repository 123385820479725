import React, { useMemo, useState } from 'react';

import { DialogContext } from './DialogContext';
import { ContextValue } from './types';

type ModalContextProps = {
  dialogIds: string[];
  children?: React.ReactElement;
};

const DialogContextProvider = ({ dialogIds, children }: ModalContextProps) => {
  const [dialogs, setDialogs] = useState<Map<string, boolean>>(new Map(dialogIds.map((id) => [id, false])));
  /**
   * Confirmation dialogs are not included int dialogs state map.
   * It's a way to force dev & product teams to use max 1 dialog at once of this type.
   * Of course, if needed, it can always be added into the dialogs state map.
   */
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [refreshDialog, setRefreshDialog] = useState<boolean>(false);

  const handleOpenDialog = (value: boolean, id: string) => {
    setDialogs(dialogs.set(id, value));
    setRefreshDialog(!refreshDialog);
  };

  const value: ContextValue = useMemo(
    () => ({
      dialogProps: {
        children,
        onClose: () => {},
      },
      confirmationDialogProps: {
        open: openConfirmationDialog,
        children,
        onClose: () => {
          setOpenConfirmationDialog(false);
        },
      },
      dialogs,
      setOpenDialog: handleOpenDialog,
      setOpenConfirmationDialog,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshDialog, openConfirmationDialog, dialogs]
  );

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
};

export { DialogContextProvider };
