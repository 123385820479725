import React from 'react';

import { useCurrentUser } from '@/entities/users/users.hooks';

import { MobileHomeButton } from './MobileHomeButton';
import { MobileNotification } from './MobileNotification';
import { MobilePhysicalRecorderButton } from './MobilePhysicalRecorderButton';

export const MobileNavbar = () => {
  const currentUser = useCurrentUser();

  return (
    <div className="flex w-full justify-between border-t border-t-grey-100 bg-grey-50 px-4 py-2">
      <MobileHomeButton />
      {currentUser?.hasLicense && <MobilePhysicalRecorderButton />}
      <MobileNotification />
    </div>
  );
};
