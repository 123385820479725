import { QueryClient } from '@tanstack/react-query';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';

import { Environment } from '@/enums/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not try to retry queries because it's really likely
      // that the same error will happen again and we just delay the error
      retry: 0,
      // do not refetch data straight when a query hook is mounted wait 60s before refetching
      staleTime: secondsToMilliseconds(60),
      // do not garbage collect query data after 60 minutes this is important and usefull for scroll restoration
      // because no data -> no scroll -> no scroll restoration
      // we should no stress too much about this time being long
      // because we will refetch data after 60s anyway to be sure to be up to date
      gcTime: minutesToMilliseconds(60),
      // on dev mode do not refresh when windows focus
      refetchOnWindowFocus: import.meta.env.VITE_ENV !== Environment.DEV,
    },
  },
});
