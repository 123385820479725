import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { AlertVariant } from '@/components/common/toast/alert';
import { createToast } from '@/components/common/toast/ToastHelpers';
import { BillingFrequency } from '@/enums';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import {
  ApiSubscriptionLicenses,
  BillingSettings,
  ChargebeeItem,
  SpecifiedPlan,
  Subscription,
  SubscriptionLicenses,
} from './billing.types';
import { queryKeys } from '../queryKeys';

export const BILLING_API_BASE_URL = '/billing';

export const useBillingSubscriptionQuery = () => {
  return useQuery({
    queryKey: queryKeys.billing.tenantSubscription(),
    queryFn: async () => {
      const { data } = await apiService.API.get<Subscription>(
        combineRoutes([BILLING_API_BASE_URL, 'tenant-subscription'])
      );
      return data;
    },
  });
};

export const useBillingPlansQuery = () => {
  return useQuery({
    queryKey: queryKeys.billing.plans(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ChargebeeItem[]>(combineRoutes([BILLING_API_BASE_URL, 'plan-list']));
      return data.sort((a, b) => a.name.localeCompare(b.name));
    },
  });
};

export const useBillingSettingsQuery = () => {
  return useQuery({
    queryKey: queryKeys.billing.settings(),
    queryFn: async () => {
      const { data } = await apiService.API.get<BillingSettings>(combineRoutes([BILLING_API_BASE_URL, 'settings']));
      return data;
    },
  });
};

export const useBillingLicensesCountQuery = () => {
  return useQuery({
    queryKey: queryKeys.billing.licensesCount(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiSubscriptionLicenses>(
        combineRoutes([BILLING_API_BASE_URL, 'licenses', 'count'])
      );
      return data;
    },
    select: useCallback((data: ApiSubscriptionLicenses): SubscriptionLicenses => {
      return {
        ...data,
        nbAllocated: data.total - data.nbAvailable,
      };
    }, []),
  });
};

export const useCreatePortalSessionUrlQuery = <TSelectData = string>(
  options?: CustomQueryOptions<string, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.billing.portalSessionUrl(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string>(combineRoutes([BILLING_API_BASE_URL, 'edit-url']));
      return data;
    },
    ...options?.queryOptions,
  });
};

export const getBillingSpecifiedPlan = async ({
  itemId,
  currency,
  billingFrequency,
  period = 1,
}: {
  itemId: string;
  currency: string;
  /**
   * Only 'month' or 'year'
   */
  billingFrequency: BillingFrequency.MONTH | BillingFrequency.YEAR;
  /**
   * Period number to distinguish the plans when the frequency has mulitple different periods.
   *
   * ex: 6 months and 1 month.
   */
  period?: number;
}) => {
  try {
    const { data } = await apiService.API.get<SpecifiedPlan>(
      combineRoutes([BILLING_API_BASE_URL, 'specified-plan', 'search']),
      {
        params: { planId: itemId, currencyCode: currency, billingFrequency, period },
      }
    );
    return data;
  } catch {
    createToast(`Error: couldn't find the subscription plan with id:${itemId}`, AlertVariant.Error);
    return undefined;
  }
};
