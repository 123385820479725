import React, { Fragment, Suspense, useContext, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { isMobile } from 'react-device-detect';

import { DataTestIdProps } from '@/types/common';

import { AnimatedPanelContent } from './AnimatedPanelContent';
import { DialogBackdrop, DialogAnimatedPanel } from './Common';
import { DialogContext } from './DialogContext';
import { MobileDrawer } from './MobileDrawer';
import { CommonDialogProps } from './types';
import { ProgressPlaceholder } from '../../ProgressPlaceholder';

type SimpleDialogProps = {
  subtitle?: string;
  onClose?: () => void;
  onReturn?: () => void;
  mainContentClassName?: string;
  hideCloseIcon?: boolean;
  preventClose?: boolean;
  dataTestId?: DataTestIdProps['dataTestId'];
  fullHeight?: boolean;
} & CommonDialogProps;

const SimpleDialog = ({
  children,
  dialogId,
  title,
  subtitle,
  dialogSize = 'medium',
  hideCloseIcon,
  mainContentClassName,
  preventClose = false,
  onClose,
  onReturn,
  dataTestId,
  fullHeight,
  ...otherProps
}: SimpleDialogProps) => {
  // Little hack to disable initialFocus. Put initial focus on content.
  const fakeReference = useRef<HTMLInputElement | null>(null);

  const { dialogProps, setOpenDialog, dialogs } = useContext(DialogContext);

  const handleOnCloseDialog = (id: string) => {
    if (preventClose) {
      return;
    }
    onClose && onClose();
    setOpenDialog(false, id);
  };

  if (isMobile) {
    return (
      <MobileDrawer
        dialogId={dialogId}
        handleClose={handleOnCloseDialog}
        isOpen={Boolean(dialogs.get(dialogId))}
        title={title}
        subtitle={subtitle}
        onReturn={onReturn}
        fullHeight={fullHeight}
        hideCloseIcon={hideCloseIcon}
        dismissible={!hideCloseIcon}
        mainContentClassName={mainContentClassName}
      >
        {children}
      </MobileDrawer>
    );
  }

  return (
    <Transition appear show={Boolean(dialogs.get(dialogId))} as={Fragment}>
      <Dialog
        data-test-id={dataTestId}
        {...dialogProps}
        {...otherProps}
        as="div"
        className="relative z-50"
        open={Boolean(dialogs.get(dialogId))}
        onClose={() => {
          handleOnCloseDialog(dialogId);
        }}
        initialFocus={otherProps?.initialFocus ?? fakeReference}
      >
        {/* Backdrop */}
        <DialogBackdrop />

        {/* Animated Dialog Panel */}
        <DialogAnimatedPanel dialogSize={dialogSize}>
          <AnimatedPanelContent
            title={title}
            subtitle={subtitle}
            handleOnCloseDialog={handleOnCloseDialog}
            hideCloseIcon={hideCloseIcon}
            dialogId={dialogId}
            fakeReference={fakeReference}
            mainContentClassName={mainContentClassName}
            onReturn={onReturn}
            dialogSize={dialogSize}
          >
            <Suspense fallback={<ProgressPlaceholder />}>{children}</Suspense>
          </AnimatedPanelContent>
        </DialogAnimatedPanel>
      </Dialog>
    </Transition>
  );
};

export { SimpleDialog };
