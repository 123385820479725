import React, { useState } from 'react';

import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { useHideModjoDeals } from '@/components/deals/useHideModjoDeals';
import { useDealsSearchQuery } from '@/entities/deals/deals.queries';
import { isEmpty } from '@/utils/utils';

import { DealOption, enterIcon, FiltersCategories, filtersCategoriesTranslationKeys, itemToJSX } from './Common';
import {
  ListMenuContainer,
  ListMenuItemContainer,
  SearchOptionsInput,
  SearchOptionsInputContainer,
  SearchOptionsInputDescription,
} from './CommonStyles';
import { NoResultSearch } from './NoResultSearch';
import { SearchableObject, SearchableObjectOptionType } from './search-components.types';
import { useShortcutsContext } from '../../MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import { MIN_LENTH_FOR_SEARCH } from '../../MainSidebar/Search/SearchDialog/SearchContent/search-hook';
import { ModjoProgress } from '../ModjoProgress';
import { EllipsedText } from '../ui-components/data-display/EllipsedText';
import { UIRadio } from '../ui-components/inputs/radio';

type SingleSelectWithApiDealSearchProps = {
  selectedFilterType: FiltersCategories;
  setSelectedItems: (values: SearchableObject[]) => void;
};

export const SingleSelectWithApiDealSearch = ({
  selectedFilterType,
  setSelectedItems,
}: SingleSelectWithApiDealSearchProps) => {
  const { t } = useTranslation('common');

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedDeal, setSelectedDeal] = useState<DealOption | null>(null);

  const hideModjoDeals = useHideModjoDeals();
  const dealSearchQuery = useDealsSearchQuery({
    filter: {
      name: inputValue,
    },
    order: {
      name: 'asc',
    },
  });

  const isSearching = dealSearchQuery?.isFetching;

  const dealOptions: DealOption[] =
    dealSearchQuery.data?.pages[0].values.map((deal) => {
      return {
        id: deal.id,
        name: deal.name,
        type: SearchableObjectOptionType.DEAL,
        searchableObjectType: SearchableObjectOptionType.DEAL,
      };
    }) ?? [];

  const { handleTriggers } = useShortcutsContext();

  if (hideModjoDeals) {
    return null;
  }

  return (
    <Combobox
      value={selectedDeal}
      onChange={(value) => {
        if (value) {
          setSelectedDeal(value);
          setSelectedItems([value]);
        }
      }}
    >
      <SearchOptionsInputContainer>
        <ComboboxInput
          as={SearchOptionsInput}
          displayValue={() => inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleTriggers}
          placeholder={`${t('filters.search.noInput')} ${t(filtersCategoriesTranslationKeys[selectedFilterType], { count: 1 })} ...`}
          autoFocus
        />
        {inputValue.length < MIN_LENTH_FOR_SEARCH && (
          <SearchOptionsInputDescription>{t('filters.search.3charMin')}</SearchOptionsInputDescription>
        )}
      </SearchOptionsInputContainer>
      <ComboboxOptions as={ListMenuContainer} className="flex flex-col gap-y-1 px-0 py-1" static>
        {/* Loader while searching */}
        {isSearching && (
          <div className="flex-center w-full">
            <ModjoProgress size={24} />
          </div>
        )}
        {/* No result found */}
        {isEmpty(dealOptions) && !isSearching && inputValue.length >= MIN_LENTH_FOR_SEARCH && (
          <NoResultSearch hasValues={false} inputValue={inputValue} />
        )}
        {/* Filtered results options */}
        {!isEmpty(dealOptions) &&
          dealOptions.map((field) => (
            <ComboboxOption
              key={field.id.toString()}
              as={ListMenuItemContainer}
              className="data-[active]:cursor-pointer data-[active]:bg-grey-100 data-[disabled]:opacity-50"
              value={field}
            >
              {({ focus }) => (
                <>
                  <UIRadio id={String(field.id)} checked={selectedDeal?.id === field.id} className="cursor-pointer" />
                  <EllipsedText as="div" className="flex w-full cursor-pointer items-center">
                    {itemToJSX(field, { inputValue, showDate: true })}
                    {enterIcon(focus)}
                  </EllipsedText>
                </>
              )}
            </ComboboxOption>
          ))}
      </ComboboxOptions>
    </Combobox>
  );
};
