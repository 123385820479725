import React, { useEffect, useMemo, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'vaul';

import { ProgressPlaceholder } from '@/components/common/ProgressPlaceholder';
import { EllipsedText } from '@/components/common/ui-components/data-display/EllipsedText';
import { scrollbarDefaultOptions } from '@/enums/constants';
import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import CheckThinIcon from '@/static/icons/basic/check-Filled.svg?react';
import SystemCloseIcon from '@/static/icons/basic/cross-Filled.svg?react';
import { cn, isEmpty } from '@/utils/utils';

import { SimpleAutoCompleteProps } from './types';
import { MaybeInAPortal } from '../../layout';
import { SelectMobileDrawerWrapper } from '../../select/SelectMobileDrawerWrapper';
import {
  DROPDOWN_MARGIN,
  DROPDOWN_ZINDEX,
  getButtonOrInputStyles,
  getLabelStyles,
  getListOptionItemIconStyles,
  getListOptionItemNoResultStyles,
  getListOptionItemStyles,
  getListOptionsStyles,
} from '../../styles';
import { getFilteredOptions } from '../common.helpers';

const OPTIONS_DROPDOWN_LIMIT = 100;

const SimpleAutoComplete = ({
  options,
  emptyOptionId,
  selectedOptionId = '',
  setSelectedOptionId,
  label,
  placeholder,
  noResultLabel,
  name,
  onInputChange,
  withPortal = true,
  loading = false,
  clearable = true,
  icon,
  fullWidth = false,
  dataTestIds,
  disabled = false,
}: SimpleAutoCompleteProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [inputQuery, setInputQuery] = useState<string>('');
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(DROPDOWN_MARGIN), flip(), shift()],
  });

  // clear the search result when we close the dropdown
  // headlsess ui does not provide a way to trach if the dropdown is open or not
  // but using the floating ui hook we can track it, if there is a transform it means the dropdown is open
  useEffect(() => {
    if (floatingStyles.transform) {
      setInputQuery('');
    }
  }, [floatingStyles.transform]);

  const selectedOption = useMemo(() => options.find(({ id }) => id === selectedOptionId), [selectedOptionId, options]);
  const allFilteredOptions = useMemo(() => getFilteredOptions(options, inputQuery), [inputQuery, options]);
  const filteredOptions = allFilteredOptions.slice(0, OPTIONS_DROPDOWN_LIMIT);
  // be sure that the selected option is always in the list if matching
  if (
    selectedOption &&
    getFilteredOptions([selectedOption], inputQuery).length > 0 &&
    !filteredOptions.some(({ id }) => id === selectedOptionId)
  ) {
    filteredOptions.unshift(selectedOption);
  }
  const hasOptionSelected = !isEmpty(selectedOptionId) && selectedOptionId !== emptyOptionId;

  const getInputDisplayValue = (option: string) => {
    const foundOption = options.find((o) => o.id === option);

    return foundOption?.label ?? '';
  };

  const showIcon = Boolean(hasOptionSelected && icon);

  return (
    <Combobox immediate value={selectedOptionId} onChange={setSelectedOptionId} disabled={disabled}>
      {({ open }) => {
        return (
          <>
            <div className={clsx('flex flex-col', fullWidth && 'w-full')}>
              {label && (
                <Label className={getLabelStyles(true)} htmlFor={name}>
                  {label}
                </Label>
              )}
              <div className="relative flex">
                {showIcon && <div className="absolute left-2 top-2">{icon}</div>}

                <ComboboxInput
                  {...(label && { id: name })}
                  ref={refs.setReference}
                  className={cn(
                    getButtonOrInputStyles({
                      open,
                      isLarge: true,
                      hasValues: hasOptionSelected,
                      hasStartIcon: showIcon,
                    }),
                    disabled && 'cursor-not-allowed'
                  )}
                  value={selectedOption?.label}
                  autoComplete="off"
                  placeholder={placeholder ?? t('combobox.typeAndSelect')}
                  displayValue={getInputDisplayValue}
                  data-test-id={dataTestIds?.inputTestId}
                  onChange={(event) => {
                    onInputChange && onInputChange(event.target.value);
                    setInputQuery(event.target.value);
                  }}
                  onClick={() => {
                    // The default behavior of the input is to only display the options when the user types
                    // something or when the user click on down arrow, not on focus. Here we want to see the
                    // list of options when the user click on the input, so we force the display of the options.
                    // The !hasOptionSelected clause avoids a loop (options close on click of an option, !open
                    // becomes true, so it opens again).
                    setIsOpen(!isOpen);
                    if (!open) {
                      buttonRef.current?.click();
                    }
                  }}
                />
                <ComboboxButton ref={buttonRef} className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ArrowDownChevronIcon className={clsx('icon-small fill-grey', open && 'rotate-180')} />
                </ComboboxButton>
                {hasOptionSelected && clearable && (
                  <button
                    type="button"
                    aria-label="Close"
                    className="absolute inset-y-0 right-7 flex h-full items-center"
                    onClick={() => setSelectedOptionId('')}
                    data-test-id={dataTestIds?.crossIconTestId}
                  >
                    <SystemCloseIcon className="icon-small rounded fill-grey hover:bg-transparent-basic-8" />
                  </button>
                )}
              </div>
            </div>
            {isMobile ? (
              <Drawer.Root
                open={isOpen}
                dismissible
                onOpenChange={(_isOpen) => {
                  setIsOpen(_isOpen);
                }}
              >
                <SelectMobileDrawerWrapper title={label}>
                  <div className="p-4">
                    <ComboboxInput
                      {...(label && { id: name })}
                      ref={refs.setReference}
                      className={cn(
                        getButtonOrInputStyles({
                          open,
                          isLarge: true,
                          hasValues: hasOptionSelected,
                          hasStartIcon: showIcon,
                        }),
                        disabled && 'cursor-not-allowed'
                      )}
                      autoComplete="off"
                      placeholder={placeholder ?? t('combobox.typeAndSelect')}
                      displayValue={getInputDisplayValue}
                      data-test-id={dataTestIds?.inputTestId}
                      onChange={(event) => {
                        onInputChange && onInputChange(event.target.value);
                        setInputQuery(event.target.value);
                      }}
                    />
                  </div>
                  <OverlayScrollbarsComponent
                    className="max-h-[500px] w-full border-t border-grey-100 p-4"
                    options={scrollbarDefaultOptions}
                  >
                    {!loading && (
                      <>
                        {!isEmpty(filteredOptions) &&
                          filteredOptions.map(
                            ({ id, label: optionLabel, disabled: disabledOption, secondaryLabel }) => {
                              const selected = selectedOptionId === id;
                              return (
                                <ComboboxOption
                                  key={id}
                                  value={id}
                                  onClick={() => setSelectedOptionId(id)}
                                  className={({ active }) => getListOptionItemStyles(active, disabledOption)}
                                  disabled={disabledOption}
                                >
                                  <EllipsedText
                                    className={clsx('z-[1500]', selected ? 'font-semibold' : 'font-normal')}
                                  >
                                    {optionLabel}
                                    {secondaryLabel && <em className="text-grey-700">{` ${secondaryLabel}`}</em>}
                                  </EllipsedText>
                                  {selected && (
                                    <span className={getListOptionItemIconStyles()}>
                                      <CheckThinIcon className="icon-small" aria-hidden />
                                    </span>
                                  )}
                                </ComboboxOption>
                              );
                            }
                          )}
                        {isEmpty(filteredOptions) && (
                          <div key="no-option" className={getListOptionItemNoResultStyles()}>
                            <span>{noResultLabel ?? t('combobox.noOptionFound')}</span>
                          </div>
                        )}
                        {allFilteredOptions.length > filteredOptions.length && (
                          <span className="self-center py-1">{t('combobox.continueTyping')}</span>
                        )}
                      </>
                    )}
                    {loading && <ProgressPlaceholder />}
                  </OverlayScrollbarsComponent>
                </SelectMobileDrawerWrapper>
              </Drawer.Root>
            ) : (
              <MaybeInAPortal withPortal={withPortal}>
                <Transition
                  show={open}
                  as="div"
                  className="absolute"
                  enter="transition duration-150 ease-out"
                  enterFrom="transform opacity-0"
                  enterTo="transform opacity-100"
                  leave="transition duration-100 ease-out"
                  leaveFrom="transform translate-y-0 opacity-100"
                  leaveTo="transform -translate-y-10 opacity-0"
                  style={{ zIndex: DROPDOWN_ZINDEX }}
                  afterLeave={() => setInputQuery('')}
                >
                  <ComboboxOptions data-test-id={dataTestIds?.listTestId} static as="ul">
                    <div
                      ref={refs.setFloating}
                      style={{ ...floatingStyles, width: refs.reference.current?.getBoundingClientRect().width }}
                      className={getListOptionsStyles(true)}
                    >
                      {!loading && (
                        <>
                          {!isEmpty(filteredOptions) &&
                            filteredOptions.map(
                              ({ id, label: optionLabel, disabled: disabledOption, secondaryLabel }) => (
                                <ComboboxOption
                                  as="li"
                                  key={id}
                                  value={id}
                                  className={({ active }) => getListOptionItemStyles(active, disabledOption)}
                                  disabled={disabledOption}
                                >
                                  {({ selected }) => (
                                    <>
                                      <EllipsedText
                                        className={clsx('z-[1500]', selected ? 'font-semibold' : 'font-normal')}
                                      >
                                        {optionLabel}
                                        {secondaryLabel && <em className="text-grey-700">{` ${secondaryLabel}`}</em>}
                                      </EllipsedText>
                                      {selected && (
                                        <span className={getListOptionItemIconStyles()}>
                                          <CheckThinIcon className="icon-small" aria-hidden />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </ComboboxOption>
                              )
                            )}
                          {isEmpty(filteredOptions) && (
                            <li key="no-option" className={getListOptionItemNoResultStyles()}>
                              <span>{noResultLabel ?? t('combobox.noOptionFound')}</span>
                            </li>
                          )}
                          {allFilteredOptions.length > filteredOptions.length && (
                            <span className="self-center py-1">{t('combobox.continueTyping')}</span>
                          )}
                        </>
                      )}
                      {loading && <ProgressPlaceholder />}
                    </div>
                  </ComboboxOptions>
                </Transition>
              </MaybeInAPortal>
            )}
          </>
        );
      }}
    </Combobox>
  );
};

export { SimpleAutoComplete };
