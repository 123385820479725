import React, { PropsWithChildren } from 'react';

import { RestrictedErrorModal } from '@/components/common/errors/fallback/RestrictedError/RestrictedErrorModal';
import ModjoLogo from '@/static/Modjo-full-logo-green.svg?react';

const RestrictedErrorPageBase = ({ children, ...props }: PropsWithChildren) => (
  <div className="flex-center relative h-screen w-screen flex-col place-content-center bg-lavender" {...props}>
    <div className="absolute left-0 top-0 p-6">
      <ModjoLogo className="h-7" />
    </div>
    {children}
  </div>
);

export const RestrictedError = { Page: RestrictedErrorPageBase, Modal: RestrictedErrorModal };
