import { createContext } from 'react';

import { ContextValue } from './types';

const DialogContext = createContext<ContextValue>({
  dialogProps: {
    children: null,
    onClose: () => {},
  },
  dialogs: {} as Map<string, boolean>,
  setOpenDialog: () => {},
});

export { DialogContext };
