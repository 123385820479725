import { useCallback } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

export const IS_CHROME_BASED_BROWSER = window.chrome && !/Opera|OPR\//.test(navigator.userAgent);

const MODJO_EXTENSION_ID: string = import.meta.env.VITE_MODJO_EXTENSION_ID || 'kjnbnafccdgppjfdlkgefndnkbildddo';
export const MODJO_EXTENSION_URL = `https://chrome.google.com/webstore/detail/${MODJO_EXTENSION_ID}`;

export const useModjoExtensionQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['modjoExtension'],
    queryFn: () => {
      if ('runtime' in chrome) {
        chrome.runtime.sendMessage(
          MODJO_EXTENSION_ID,
          {
            type: 'webapp-check-version',
          },
          (response) => {
            if (response.version) {
              queryClient.setQueryData(['modjoExtension'], response.version);
            } else {
              throw new Error('Modjo Extension is not installed.');
            }
          }
        );
        return true;
      }
      throw new Error('No installed extension allows Modjo to open it.');
    },
    enabled: !!window.chrome,
    retry: true,
    retryDelay: 1500,
  });
};

export const useOpenModjoExtension = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    const modjoExtension = await queryClient.fetchQuery({ queryKey: ['modjoExtension'] });
    if (modjoExtension) {
      await chrome.runtime.sendMessage(MODJO_EXTENSION_ID, {
        type: 'webapp-open-popup',
      });
    }
  }, [queryClient]);
};
