import React, { useMemo } from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { MenuItem } from '@/components/common/ui-components/menu/types';
import { NavbarTab } from '@/enums';
import { IntercomIds } from '@/enums/intercom-ids';
import BubbleQuestionIcon from '@/static/icons/chat/comment-2-question-Line.svg?react';
import { Event } from '@/tracking/events';
import { getHelpCenterUrl } from '@/utils/help-center-utils';

import { SidebarNavigationMenu } from './SidebarNavigationMenu/SidebarNavigationMenu';
import { SidebarNavigationMenuButton } from './SidebarNavigationMenu/SidebarNavigationMenuButton';

const HelpSupportMenu = () => {
  const { show } = useIntercom();
  const { t } = useTranslation();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        label: t('navbar.helpSupport.helpCenter'),
        link: getHelpCenterUrl(),
        target: '_blank',
        onClick: () => mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.INTERCOM_DOCUMENTATION }),
      },
      {
        label: t('navbar.helpSupport.chat'),
        onClick: () => {
          show();
          mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.INTERCOM_CHAT });
        },
      },
      {
        label: t('navbar.helpSupport.submitFeedback'),
        id: IntercomIds.GeneralFeedback,
        onClick: () => mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.INTERCOM_GENERAL_FEEDBACK }),
      },
    ];
  }, [show, t]);

  return (
    <SidebarNavigationMenu
      items={menuItems}
      Button={(open) => (
        <SidebarNavigationMenuButton
          label={t('navbar.helpSupport.button')}
          Icon={BubbleQuestionIcon}
          isMenuOpen={Boolean(open)}
        />
      )}
    />
  );
};

HelpSupportMenu.displayName = 'HelpSupportMenu';

export { HelpSupportMenu };
