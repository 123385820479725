import { useState, useRef } from 'react';

import { captureException } from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { useUnmount } from 'react-use';

import { twThemeColors } from '@/theme/colors';
import { Event } from '@/tracking/events';

import { MeetingRecorderStatuses, RECORDER_LIMIT_REACHED_IN_MS } from './meeting-recorder.constants';
import { meetingRecorderStore } from './meeting-recorder.store';
import { saveRecording } from './meeting-recorder.utils';

export const useMeetingRecorder = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorder = meetingRecorderStore.useMediaRecorderInstance();
  let startTime: number | null = null;
  let pauseStartTime: number | null = null;
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [hasReachedTimeout, setHasReachedTimeout] = useState<boolean>(false);
  const timerInterval = useRef<NodeJS.Timeout | null>(null);
  const status = meetingRecorderStore.useRecordingStatus();
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);
  const drawFrameCountRef = useRef<number>(0);
  const [twoHourTimeout, setTwoHourTimeout] = useState<NodeJS.Timeout | null>(null);

  const stopTimer = () => {
    if (timerInterval.current !== null) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
  };

  const stopLimitTimer = () => {
    if (twoHourTimeout !== null) {
      clearTimeout(twoHourTimeout);
      setTwoHourTimeout(null);
    }
  };

  const pauseRecording = () => {
    if (
      mediaRecorder !== null &&
      mediaRecorder !== undefined && // Add null check
      (status === MeetingRecorderStatuses.RECORDING || status !== MeetingRecorderStatuses.PAUSED)
    ) {
      mediaRecorder.pause();
    }
  };

  const startTimer = () => {
    stopTimer(); // Clear any existing timer

    // Start a new interval timer
    timerInterval.current = setInterval(() => {
      setElapsedTime(() => {
        if (startTime === null) {
          return 0;
        }
        const currentTime = Date.now();
        const currentElapsedTime = currentTime - startTime;
        return Math.floor(currentElapsedTime / 1000);
      });
    }, 1000); // Update every second
  };

  const startLimitTimer = () => {
    stopLimitTimer();
    const timeout = setTimeout(() => {
      setHasReachedTimeout(true);
    }, RECORDER_LIMIT_REACHED_IN_MS);

    setTwoHourTimeout(timeout);
    setHasReachedTimeout(false);
  };

  useUnmount(() => {
    stopTimer();
    stopLimitTimer();
  });

  const drawPausedWaveform = () => {
    const canvas = canvasRef.current;
    const { 200: grey200 } = twThemeColors.grey;
    const canvasContext = canvas?.getContext('2d');
    if (!canvas || !canvasContext) {
      return;
    }

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

    // Draw a straight line in the middle of the canvas
    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = grey200;
    canvasContext.beginPath();
    canvasContext.moveTo(0, HEIGHT / 2);
    canvasContext.lineTo(WIDTH, HEIGHT / 2);
    canvasContext.stroke();
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    const canvasContext = canvas?.getContext('2d');
    if (!canvas || !canvasContext || !analyserRef.current || !dataArrayRef.current) {
      return;
    }

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

    const draw = () => {
      drawFrameCountRef.current += 1;
      const { 200: grey200, 50: grey50 } = twThemeColors.grey;

      // Only draw every 3rd frame (or adjust to desired frame rate)
      if (drawFrameCountRef.current % 3 !== 0) {
        animationFrameIdRef.current = requestAnimationFrame(draw);
        return;
      }

      if (!analyserRef.current || !dataArrayRef.current) {
        return;
      }

      analyserRef.current?.getByteTimeDomainData(dataArrayRef.current);

      canvasContext.fillStyle = grey50;
      canvasContext.fillRect(0, 0, WIDTH, HEIGHT);

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = grey200;

      canvasContext.beginPath();

      const sliceWidth = (WIDTH * 1) / dataArrayRef.current.length;
      let x = 0;

      for (const [index, value] of dataArrayRef.current.entries()) {
        const v = value / 128;
        const y = (v * HEIGHT) / 2;

        if (index === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();
      animationFrameIdRef.current = requestAnimationFrame(draw);
    };

    draw();
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new AudioContext();
      analyserRef.current = audioContextRef.current.createAnalyser();

      sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);

      analyserRef.current.fftSize = 2048;
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);

      drawWaveform();
      const recorder = new MediaRecorder(stream);
      meetingRecorderStore.setMediaRecorderInstance(recorder);

      recorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.PROCESSING);
          saveRecording(event.data, `modjo-recording-${Date.now()}.wav`);
        }
      };

      recorder.onstart = () => {
        meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.RECORDING);
        mixpanel.track(Event.START_RECORDING);
        startTime = Date.now();
        setElapsedTime(0);
        startTimer();
        startLimitTimer();
      };

      recorder.addEventListener('pause', () => {
        meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.PAUSED);
        mixpanel.track(Event.PAUSE_RECORDING);
        stopTimer();
        cancelAnimationFrame(animationFrameIdRef.current!);
        drawPausedWaveform();

        if (startTime !== null) {
          pauseStartTime = Date.now();
        }
      });

      recorder.onresume = () => {
        if (pauseStartTime !== null && startTime !== null) {
          const resumeTime = Date.now();
          startTime += resumeTime - pauseStartTime;
        }
        meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.RECORDING);
        startTimer();
        drawWaveform();
        mixpanel.track(Event.RESUME_RECORDING);
      };

      recorder.onstop = () => {
        meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.PROCESSING);
        stopTimer();

        for (const track of recorder.stream.getTracks()) {
          track.stop();
        }
        mixpanel.track(Event.STOP_RECORDING);
      };

      recorder.start();
    } catch (error: unknown) {
      captureException(error, {
        tags: {
          component: 'MeetingRecorder',
        },
      });
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && status === MeetingRecorderStatuses.PAUSED) {
      mediaRecorder.resume();
      setHasReachedTimeout(false);
      startLimitTimer();
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorder !== null &&
      mediaRecorder !== undefined &&
      (status === MeetingRecorderStatuses.RECORDING || status === MeetingRecorderStatuses.PAUSED)
    ) {
      mediaRecorder.stop();
    }
  };

  const reset = () => {
    stopTimer();
    stopLimitTimer();
    setElapsedTime(0);
    startTime = null;
    pauseStartTime = null;
    meetingRecorderStore.reset();
  };

  return {
    canvasRef,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    elapsedTime,
    hasReachedTimeout,
    reset,
  };
};
