import React from 'react';

import mixpanel from 'mixpanel-browser';

import { useUrlFilters } from '@/components/explore/useUrlFilters';
import { useViewsByType } from '@/entities/views/views.hooks';
import { ViewType } from '@/entities/views/views.types';
import { Event } from '@/tracking/events';
import { ProductTourIdProps } from '@/types/common';
import { isEmpty } from '@/utils/utils';

import { NavigationViewButton } from './NavigationViewButton';
import { Collapsible } from '../../common/ui-components/layout/Collapsible';
import { NavigationLinkButton } from '../NavigationLinkButton';
import { sidebarStore } from '../sidebar.store';
import { NavigationLinkItem } from '../sidebar.utils';

type NavigationLinkButtonWithViewsProps = {
  item: NavigationLinkItem;
  viewType: ViewType;
} & ProductTourIdProps;

const NavigationLinkButtonWithViews = ({ item, viewType, productTourId }: NavigationLinkButtonWithViewsProps) => {
  const views = useViewsByType(viewType);
  const { viewId } = useUrlFilters();
  const openViews = sidebarStore.useOpenViews();

  const hasActiveView = views.some(({ id }) => id === viewId);
  const hasViewsOpen = openViews[viewType];
  const [open, setOpen] = React.useState(sidebarStore.getOpenViews()[viewType]);

  return (
    <>
      <NavigationLinkButton
        {...item}
        hasViews={!isEmpty(views)}
        hasActiveView={hasActiveView && hasViewsOpen}
        productTourId={productTourId}
        iconActive={open}
        iconOnClick={() => {
          setOpen(!open);
          mixpanel.track(Event.TOGGLE_FILTERS_VIEWS_SIDEBAR, {
            type: viewType,
            newState: open ? 'open' : 'close',
          });
          sidebarStore.setOpenViews((prev) => ({ ...prev, [viewType]: !open }));
        }}
      />
      <Collapsible open={open}>
        <ul className="flex flex-col gap-y-0">
          {views.map((view) => (
            <li key={view.id} className="ml-4 border-l border-l-grey-200">
              <NavigationViewButton {...view} viewType={viewType} />
            </li>
          ))}
        </ul>
      </Collapsible>
    </>
  );
};

export { NavigationLinkButtonWithViews };
