import React from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { SimplePopover } from '@/components/common/ui-components/popover';
import { useMarkAsReadMutation } from '@/entities/notifications/notifications.mutations';
import { useNotificationsQuery } from '@/entities/notifications/notifications.queries';
import TaskListIcon from '@/static/icons/basic/checkbox-rec-Line.svg?react';
import { isEmpty } from '@/utils/utils';

import { NotificationButton } from './NotificationButton';
import { NotificationPopoverContent } from './NotificationPopoverContent';
import { SIDEBAR_FLOATING_SHIFTING_OPTIONS, SIDEBAR_OFFSET_OPTIONS } from '../sidebar.constants';

const Notifications = () => {
  const { t } = useTranslation();
  const markAsReadMutation = useMarkAsReadMutation();
  const notificationsQuery = useNotificationsQuery();

  const lastPageIndex = notificationsQuery.isSuccess ? notificationsQuery.data.pages.length - 1 : 0;
  const unreadNotificationsCount = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages[lastPageIndex].unreadNotifications
    : 0;
  const currentNotifications = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages.flatMap(({ values }) => values)
    : [];

  const handleClickMarkAsRead = () => {
    if (unreadNotificationsCount === 0 || isEmpty(currentNotifications)) {
      return;
    }
    markAsReadMutation.mutate(undefined);
  };

  return (
    <SimplePopover
      togglerComponent={(open) => (
        <NotificationButton isMenuOpen={open} unreadNotificationsCount={unreadNotificationsCount} />
      )}
      placement="right"
      offsetOptions={SIDEBAR_OFFSET_OPTIONS}
      floatingShiftOptions={SIDEBAR_FLOATING_SHIFTING_OPTIONS}
    >
      <div className="flex items-center justify-between border-b border-grey-100 px-4 py-2">
        <h3 className="text-xl font-semibold capitalize">{t('navbar.notifications.button')}</h3>
        <ButtonGhost
          onClick={handleClickMarkAsRead}
          icon={<TaskListIcon className="icon-small" />}
          iconPosition="start"
          disabled={unreadNotificationsCount === 0 && !isEmpty(currentNotifications)}
          className="text-xs font-semibold"
        >
          {t('navbar.notifications.markAllAsRead')}
        </ButtonGhost>
      </div>

      <NotificationPopoverContent />
    </SimplePopover>
  );
};

Notifications.displayName = 'Notifications';

export { Notifications };
