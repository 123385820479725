import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import { Sizes } from '@/enums';
import ExternalLinkIcon from '@/static/icons/arrows/external-link-Line.svg?react';
import { cn } from '@/utils/utils';

type ExternalLinkProps = PropsWithChildren<{
  className?: string;
  iconClassName?: string;
  size?: Extract<Sizes, 'small' | 'medium'>;
}> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink = ({ className, iconClassName, children, size = 'medium', ...rest }: ExternalLinkProps) => (
  <a
    {...rest}
    target="_blank"
    rel="noopener noreferrer"
    className={cn(
      'underline underline-offset-2',
      {
        'text-xs [&>svg]:size-3': size === 'small',
        'text-sm [&>svg]:size-4': size === 'medium',
      },
      className
    )}
  >
    {children}
    <ExternalLinkIcon className={cn('ml-2 align-text-bottom', iconClassName)} />
  </a>
);

export { ExternalLink };
