import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { SelectOption } from '@/components/common/ui-components/types';
import { getProviderDisplayName } from '@/components/providers/icons/ProvidersIcon';
import { ApiProvider } from '@/entities/providers/providers.types';
import { CustomQueryOptions } from '@/types/common';
import {
  OAuthProvider,
  Provider,
  RecordingBotProvider,
  UserOAuthProvider,
  UserProvider,
  isCallRecordingProvider,
} from '@/types/providers';
import { ProviderCredentialsFormType } from '@/utils/providers-utils';
import { combineRoutes } from '@/utils/url-utils';

import {
  getProviderByName,
  hasGoogleMeet,
  hasMicrosoftTeams,
  getIntegrationProviders,
  isIntegratedWithCRM,
} from './providers.select';
import {
  ProviderAuthorizationUrl,
  ProviderMember,
  ProviderOAuthOptions,
  ProviderScheduleBotOptionDto,
} from './providers.types';
import { queryKeys } from '../queryKeys';

export const PROVIDERS_API_BASE_URL = '/providers';

export const useProvidersQuery = <TSelectData = ApiProvider[]>(
  options?: CustomQueryOptions<ApiProvider[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.providers.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiProvider[]>(PROVIDERS_API_BASE_URL);
      return data;
    },
    ...options?.queryOptions,
  });
};

type UseProviderCredentialsQueryParams<TSelectData = ProviderCredentialsFormType> = CustomQueryOptions<
  ProviderCredentialsFormType,
  TSelectData
> & {
  provider: Provider;
};

export const useProviderCredentialsQuery = <TSelectData = ProviderCredentialsFormType>({
  provider,
  queryOptions,
}: UseProviderCredentialsQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.providerCredentials.provider(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderCredentialsFormType>(
        `${PROVIDERS_API_BASE_URL}/${provider}/credentials`
      );
      return data;
    },
    ...queryOptions,
  });
};

type UseProviderBotScheduleOptionQueryParams<TSelectData = ProviderScheduleBotOptionDto> = CustomQueryOptions<
  ProviderScheduleBotOptionDto,
  TSelectData
> & {
  provider: RecordingBotProvider;
};

export const useProvidersBotScheduleOptionQuery = <TSelectData = ProviderScheduleBotOptionDto>({
  provider,
  queryOptions,
}: UseProviderBotScheduleOptionQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.providerBotScheduleOption.provider(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderScheduleBotOptionDto>(
        `${PROVIDERS_API_BASE_URL}/bots/${provider}/schedule-option`
      );
      return data;
    },
    ...queryOptions,
  });
};

export const useIntegrationProvidersQuery = () =>
  useProvidersQuery({
    queryOptions: {
      select: getIntegrationProviders,
    },
  });

export const useHasGoogleMeetQuery = () =>
  useProvidersQuery({
    queryOptions: {
      select: hasGoogleMeet,
    },
  });

export const useHasMicrosoftTeamsQuery = () =>
  useProvidersQuery({
    queryOptions: {
      select: hasMicrosoftTeams,
    },
  });

export const useProviderByNameQuery = (providerName: Provider) =>
  useProvidersQuery({
    queryOptions: {
      select: React.useCallback(
        (providers: ApiProvider[]) => getProviderByName(providers, providerName),
        [providerName]
      ),
    },
  });

export const useIsIntegratedWithCRMQuery = () =>
  useProvidersQuery({
    queryOptions: {
      select: isIntegratedWithCRM,
    },
  });

export const useCallRecordingProvidersOptionsQuery = () =>
  useProvidersQuery({
    queryOptions: {
      select: useCallback((data: ApiProvider[]) => {
        return data
          .reduce<SelectOption[]>((accumulator, provider) => {
            if (isCallRecordingProvider(provider.provider)) {
              accumulator.push({ id: provider.provider, label: getProviderDisplayName(provider.provider) });
            }
            return accumulator;
          }, [])
          .sort((left, right) => left.id.localeCompare(right.id));
      }, []),
    },
  });

type ProviderUsersQueryParams<TSelectData = ProviderMember[]> = CustomQueryOptions<ProviderMember[], TSelectData> & {
  provider: UserProvider;
};

export const providerUsersQueryBaseOptions = (provider: UserProvider) => ({
  queryKey: queryKeys.providers.usersByProvider(provider),
  queryFn: async () => {
    const { data } = await apiService.API.get<ProviderMember[]>(
      combineRoutes([PROVIDERS_API_BASE_URL, provider, 'users'])
    );
    return data;
  },
});

export const useProviderUsersQuery = <TSelectData = ProviderMember[]>({
  provider,
  queryOptions,
}: ProviderUsersQueryParams<TSelectData>) => {
  const options = useMemo(() => providerUsersQueryBaseOptions(provider), [provider]);
  return useQuery({
    ...options,
    ...queryOptions,
  });
};

type ProviderAuthorizationUrlQueryParams = {
  provider: OAuthProvider;
  otherOptions?: ProviderOAuthOptions;
};

export const providerAuthorizationUrlQueryObject = ({
  provider,
  otherOptions,
}: ProviderAuthorizationUrlQueryParams) => {
  return {
    queryKey: queryKeys.providers.authorizationUrlByProvider(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderAuthorizationUrl>(
        combineRoutes([PROVIDERS_API_BASE_URL, provider, 'oauth']),
        { params: { ...otherOptions } }
      );
      return data;
    },
  };
};

export const useProviderAuthorizationUrlQuery = ({ provider, otherOptions }: ProviderAuthorizationUrlQueryParams) => {
  return useQuery(providerAuthorizationUrlQueryObject({ provider, otherOptions }));
};

type ProviderUserSsoAuthorizationUrlQueryParams = {
  provider: OAuthProvider;
  otherOptions?: ProviderOAuthOptions;
};

export const providerUserSsoAuthorizationUrlQueryObject = ({
  provider,
  otherOptions,
}: ProviderUserSsoAuthorizationUrlQueryParams) => {
  return {
    queryKey: queryKeys.providers.userSsoAuthorizationUrlByProvider(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderAuthorizationUrl>(
        combineRoutes([PROVIDERS_API_BASE_URL, provider, 'oauth/sso']),
        { params: { ...otherOptions } }
      );
      return data;
    },
  };
};

export const useProviderUserSsoAuthorizationUrlQuery = ({
  provider,
  otherOptions,
}: ProviderUserSsoAuthorizationUrlQueryParams) => {
  return useQuery(providerUserSsoAuthorizationUrlQueryObject({ provider, otherOptions }));
};

type ProviderScopesAuthorizationUrlQueryParams = {
  provider: UserOAuthProvider;
  options?: { scopes: string[]; to?: string };
};

export const providerScopesAuthorizationUrlQueryObject = ({
  provider,
  options,
}: ProviderScopesAuthorizationUrlQueryParams) => {
  return {
    queryKey: queryKeys.providers.scopesAuthorizationUrlByProvider(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderAuthorizationUrl>(
        combineRoutes([PROVIDERS_API_BASE_URL, provider, 'oauth/user']),
        { params: { ...options } }
      );
      return data;
    },
  };
};

export const useProviderScopesAuthorizationUrlQuery = ({
  provider,
  options,
}: ProviderScopesAuthorizationUrlQueryParams) => {
  return useQuery(providerScopesAuthorizationUrlQueryObject({ provider, options }));
};

export const useNotesOAuthQuery = () => {
  return useQuery({
    queryKey: queryKeys.providers.notesOAuth(),
    queryFn: async () => {
      const { data } = await apiService.API.get<{ code: string }>('oauth');
      return data;
    },
  });
};
