import React from 'react';

import { useIsFarmer } from '@/entities/users/users.hooks';

import { FarmerBanner } from './FarmerBanner';
import { FreeTrialExpirationBanner } from './FreeTrialExpirationBanner';
import { PaymentOverdueBanner } from './PaymentOverdueBanner';

export const Header = () => {
  const isFarmer = useIsFarmer();

  return (
    <header>
      {/** The order is important as we can stack multiple banners */}
      {isFarmer && <FarmerBanner />}
      <PaymentOverdueBanner />
      <FreeTrialExpirationBanner />
    </header>
  );
};
