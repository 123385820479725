import { ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { CallTagState } from '@/enums';
import { ColorPalette } from '@/theme/colors';
import { ObjectValues } from '@/types/common';
import { PhoneProvider } from '@/types/providers';
import { SortOrder } from '@/types/sort';

export const WITHOUT_TAG_ID = -1;
export const DEFAULT_TAG_COLOR = ColorPalette.basic300;

export const SortByTagsFields = {
  NAME: 'name',
  PROVIDER: 'phoneProvider',
  TEAMS: 'teamIds',
} as const;

type SortByTagsField = ObjectValues<typeof SortByTagsFields>;

const isSortByTagsField = (value: string): value is SortByTagsField => {
  return Object.values(SortByTagsFields).includes(value as SortByTagsField);
};

export type TagTableSortingState = {
  id: SortByTagsField;
  desc: boolean;
};

const isTagTableSortingState = (value: unknown): value is TagTableSortingState => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const { id } = value as ColumnSort;
  return isSortByTagsField(id);
};

export const isTagTableSortingStateArray = (value: unknown): value is TagTableSortingState[] => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.every((item) => isTagTableSortingState(item));
};

export type Tag = {
  id: number;
  name: string;
  teamIds?: number[] | null;
  color: string;
  exclude: boolean;
  phoneProvider?: PhoneProvider | null;
  origin?: CallTagState;
};

export type TagsTableQueryParams = {
  teamIdFilter?: string;
  providerIdFilter?: string;
  sortBy: SortByTagsField;
  sortOrder: SortOrder;
};

export type PostTagDto = Partial<Pick<Tag, 'id' | 'color' | 'exclude' | 'teamIds'>>;

export type PutTagDto = Pick<Tag, 'id'> & Partial<Pick<Tag, 'name' | 'teamIds' | 'color' | 'exclude'>>;

export const useEmptyTag = (id = WITHOUT_TAG_ID) => {
  const { t } = useTranslation();
  return {
    name: t('tag.without'),
    id,
    color: DEFAULT_TAG_COLOR,
    exclude: false,
    teamIds: [],
  };
};

export const serializeColor = (color?: string) => (color ? color.replaceAll('#', '') : undefined);

export const deserializeColor = (color?: string) => (color ? `#${color}` : undefined);
