import React from 'react';

import { MobileNotificationButton } from './MobileNotificationButton';
import { MobileNotificationDialog } from './MobileNotificationDialog';

export const MobileNotification = () => (
  <>
    <MobileNotificationButton />
    <MobileNotificationDialog />
  </>
);
