import React, { useEffect } from 'react';

import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ErrorFallback } from '@/components/common/errors/fallback';
import { ModjoProgress } from '@/components/common/ModjoProgress';
import { useDialog } from '@/components/common/ui-components/dialogs';
import { SimpleDialog } from '@/components/common/ui-components/dialogs/SimpleDialog';
import { DIALOGS_IDS } from '@/enums/constants';
import ModjoOnlyGreenLogo from '@/static/logo.svg?react';
import { Event } from '@/tracking/events';

import { MeetingRecorderContentStates, MeetingRecorderStatuses } from './meeting-recorder.constants';
import { meetingRecorderStore } from './meeting-recorder.store';
import { MeetingRecorderContent } from './MeetingRecorderContent';
import { MeetingRecorderImport } from './MeetingRecorderImport';
import { MeetingRecorderNoAccess } from './MeetingRecorderNoAccess';

export const MeetingRecorderDialog = () => {
  const { t } = useTranslation();
  const { openDialog, closeDialog, isDialogOpen } = useDialog();
  const hasAccessMicrophone = meetingRecorderStore.useHasAccessMicrophone();
  const meetingRecorderContentState = meetingRecorderStore.useMeetingRecorderContentState();
  const status = meetingRecorderStore.useRecordingStatus();

  useEffect(() => {
    const fetchMicrophoneAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        meetingRecorderStore.setHasAccessMicrophone(true);
      } catch {
        meetingRecorderStore.setHasAccessMicrophone(false);
      }
    };

    if (hasAccessMicrophone === null && isDialogOpen(DIALOGS_IDS.RECORDER_DIALOG)) {
      fetchMicrophoneAccess();
    }
  }, [hasAccessMicrophone, isDialogOpen]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (
        meetingRecorderContentState === MeetingRecorderContentStates.RECORDER &&
        status !== MeetingRecorderStatuses.READY
      ) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [status, meetingRecorderContentState, openDialog]);

  const dialogTitle = isMobile ? (
    <div className="relative flex w-full items-center justify-center p-4 pr-8">
      <ModjoOnlyGreenLogo className="size-9 shrink-0" />
      <span className="text-ellipsis text-xl font-semibold first-letter:capitalize">{t('meetingRecorder.title')}</span>
    </div>
  ) : (
    t('meetingRecorder.title')
  );

  const hideAndPreventClose =
    status === MeetingRecorderStatuses.PAUSED ||
    status === MeetingRecorderStatuses.RECORDING ||
    status === MeetingRecorderStatuses.PROCESSING ||
    status === MeetingRecorderStatuses.COMPLETED ||
    meetingRecorderContentState === MeetingRecorderContentStates.IMPORT;

  return (
    <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
      <SimpleDialog
        className="p-0"
        dialogId={DIALOGS_IDS.RECORDER_DIALOG}
        title={dialogTitle}
        dialogSize="large"
        onClose={() => {
          meetingRecorderStore.reset();
          mixpanel.track(Event.CLOSE_PHYISICAL_RECORDER);
          closeDialog(DIALOGS_IDS.RECORDER_DIALOG);
        }}
        hideCloseIcon={hideAndPreventClose}
        preventClose={hideAndPreventClose}
        fullHeight
        // Override with !important to match the design without modifying the original SimpleDialog
        mainContentClassName="!px-0 border-t border-grey-100 flex flex-col items-center justify-between h-full"
      >
        {hasAccessMicrophone === null ? (
          <ModjoProgress />
        ) : hasAccessMicrophone ? (
          meetingRecorderContentState === MeetingRecorderContentStates.RECORDER ? (
            <MeetingRecorderContent />
          ) : (
            meetingRecorderContentState === MeetingRecorderContentStates.IMPORT && <MeetingRecorderImport />
          )
        ) : (
          <MeetingRecorderNoAccess />
        )}
      </SimpleDialog>
    </ErrorBoundary>
  );
};
