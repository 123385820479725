import React, { PropsWithChildren } from 'react';

import { ConditionalWrapper } from '@/components/common/ConditionalWrapper';

import { Tooltip } from '.';
import { TooltipProps } from './types';

type MaybeInATooltipProps = PropsWithChildren<{
  withTooltip: boolean;
}> &
  TooltipProps;

const MaybeInATooltip = ({ children, withTooltip, ...tooltipProps }: MaybeInATooltipProps) => (
  <ConditionalWrapper
    condition={withTooltip}
    truthyWrapper={(wrappedChildren) => <Tooltip {...tooltipProps}>{wrappedChildren}</Tooltip>}
  >
    {children}
  </ConditionalWrapper>
);

export { MaybeInATooltip };
