/* eslint-disable i18next/no-literal-string */
import clsx from 'clsx';

const DROPDOWN_MARGIN = 4;
const DROPDOWN_ZINDEX = 1400;

const getLabelStyles = (isLarge: boolean, disabled?: boolean) =>
  clsx(
    'select-none font-medium leading-5 first-letter:uppercase',
    isLarge ? 'mb-1.5 text-sm' : 'mb-1 text-xs',
    disabled && 'text-grey-400'
  );

const getButtonOrInputBaseStyles = ({
  isLarge,
  disabled,
  isButton,
}: {
  isLarge?: boolean;
  disabled?: boolean;
  isButton?: boolean;
}) =>
  clsx(
    'transition duration-150 ease-in-out placeholder:font-normal placeholder:text-grey-400 focus:outline-none',
    isLarge ? 'text-sm' : 'text-xs',
    disabled && 'hover:cursor-not-allowed',
    isButton ? '!p-0' : 'whitespace-pre-line py-2 focus:ring-blue'
  );
const getButtonOrInputStyles = ({
  open,
  isLarge,
  disabled,
  isError,
  hasValues,
  isButton,
  isGhost,
  hasStartIcon = false,
  hasEndIcons = true,
  fullWidth = true,
}: {
  open?: boolean;
  isLarge?: boolean;
  disabled?: boolean;
  isError?: boolean;
  hasValues?: boolean;
  isButton?: boolean;
  isGhost?: boolean;
  hasStartIcon?: boolean;
  hasEndIcons?: boolean;
  fullWidth?: boolean;
}) =>
  clsx(
    getButtonOrInputBaseStyles({ isLarge, disabled, isButton }),
    'truncate rounded-md text-left focus-visible:text-grey-900',
    {
      'shadow ring-blue': open && !isGhost,
      'ring-grey-100': !open && !isError && !disabled && !isGhost,
      'ring-grey-200': disabled && !isGhost,
      'ring-red': isError,
      'ring-1 ring-inset': !isGhost,
      'cursor-pointer': isButton,
    },
    disabled || !hasValues ? 'text-grey-300' : 'text-grey-900',
    hasEndIcons ? (isGhost ? 'pr-7' : 'pr-11') : 'pr-2',
    hasStartIcon ? 'pl-9' : 'pl-2',
    fullWidth && 'w-full'
  );

const getListOptionsStyles = (isLarge: boolean, forceHide?: boolean, isListWidthDynamic?: boolean) =>
  clsx(
    'invisible-scrollbar absolute flex max-h-60 flex-col overflow-auto rounded-md bg-grey-white p-2 shadow-md ring-1 ring-grey-900 ring-opacity-5 focus:outline-none',
    isLarge ? 'gap-y-1 text-sm' : 'gap-y-0.5 text-xs',
    forceHide && 'hidden',
    isListWidthDynamic && '!w-auto'
  );

const getListOptionItemStyles = (active: boolean, disabled?: boolean) =>
  clsx(
    'relative select-none rounded py-1 pl-3 pr-9 hover:cursor-pointer hover:bg-grey-100 focus:outline-none',
    active && !disabled && 'cursor-pointer bg-grey-100',
    disabled && 'text-grey-400'
  );
const getListOptionItemNoResultStyles = () => 'relative select-none py-1 pl-3 pr-10 text-grey-700';
const getListOptionItemLabelStyles = (selected: boolean) =>
  clsx('block truncate', selected ? 'font-semibold' : 'font-normal');
const getListOptionItemIconStyles = () => 'absolute inset-y-0 right-0 flex items-center pr-2';

export {
  DROPDOWN_MARGIN,
  DROPDOWN_ZINDEX,
  getLabelStyles,
  getButtonOrInputBaseStyles,
  getButtonOrInputStyles,
  getListOptionItemLabelStyles,
  getListOptionItemIconStyles,
  getListOptionItemNoResultStyles,
  getListOptionItemStyles,
  getListOptionsStyles,
};
