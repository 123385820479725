import React, { PropsWithChildren, useState } from 'react';

import { m } from 'framer-motion';

import { IconButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import CrossIcon from '@/static/icons/basic/cross-Line.svg?react';
import { cn } from '@/utils/utils';

type WarningBannerContainerProps = PropsWithChildren<{ className?: string; dismissable?: boolean }>;

const WarningBannerContainer = ({ children, className, dismissable = true }: WarningBannerContainerProps) => {
  const [show, toggle] = useState<boolean>(true);

  return (
    <m.div
      initial="collapsed"
      animate={show ? 'open' : 'collapsed'}
      variants={{
        open: { height: 'auto' },
        collapsed: { height: 0 },
      }}
      className={cn(
        'bg-header-banner grid w-full grid-cols-11 items-center gap-x-2.5 px-2 font-semibold shadow md:px-4',
        className
      )}
    >
      <div className="col-span-9 col-start-2 py-3">{children}</div>
      {dismissable && (
        <IconButtonGhost
          icon={<CrossIcon />}
          size="tiny"
          className="ml-auto"
          isTransparent
          onClick={() => toggle(false)}
        />
      )}
    </m.div>
  );
};

export { WarningBannerContainer };
