import { createContext, useContext } from 'react';

import { ApiService } from '../api.service';

export const ApiContext = createContext({} as ApiService);
ApiContext.displayName = 'ApiContext';

export function useApiService() {
  const cappedErrors = useContext(ApiContext);
  if (cappedErrors === null) {
    throw new Error('useApiService must be used within a ApiServiceProvider');
  }
  return cappedErrors;
}
