import React, { useEffect, useState } from 'react';

const useIsTextOverflowing = (textRef: React.RefObject<HTMLElement>) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    const { current } = textRef;
    const handleResize = () => {
      if (current) {
        const { scrollWidth, clientWidth } = current;
        setIsTextOverflowing(scrollWidth > clientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (current) {
      resizeObserver.observe(current);
    }

    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef.current]);

  return isTextOverflowing;
};

export { useIsTextOverflowing };
