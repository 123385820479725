import { OtherOption } from '@/components/common/search-components/Common';
import { OtherInfoOptionType } from '@/components/common/search-components/search-components.types';
import { LanguageCode } from '@/enums/languages';
import { getFlagEmoji, getLanguageDisplayName } from '@/utils/language';

export const sortedLanguageOptions = (languages: LanguageCode[]) => {
  const clonedLanguages = structuredClone(languages);
  return clonedLanguages
    .reduce<{ flag: string; languageDisplayName: string; languageCode: LanguageCode }[]>((options, languageCode) => {
      const flag = getFlagEmoji(languageCode);
      const languageDisplayName = getLanguageDisplayName(languageCode);
      if (flag && languageDisplayName) {
        options.push({
          flag,
          languageDisplayName,
          languageCode,
        });
      }
      return options;
    }, [])
    .sort((a, b) => a.languageDisplayName.localeCompare(b.languageDisplayName))
    .map(
      ({ flag, languageDisplayName, languageCode }) =>
        new OtherOption(languageCode, `${flag} ${languageDisplayName}`, OtherInfoOptionType.LANGUAGE)
    );
};
