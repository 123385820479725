import { CallImportStatus, CallMediaType, CallTagState } from '@/enums';
import { LanguageCode } from '@/enums/languages';
import { CallRecordingProvider } from '@/types/providers';

import { Account } from '../account/account.types';
import { ApiListener } from '../calls/calls.types';
import { Comment } from '../comments/comments.types';
import { Contact } from '../contact/contact.types';
import { Deal } from '../deals/deals.types';
import { ApiLibraryItem } from '../library/library.types';
import { PublicSnippet, Snippet } from '../snippets/snippets.types';
import { Tag } from '../tags/tags.types';
import { Team } from '../teams/teams.types';
import { ApiUser } from '../users/users.types';

type TagWithOrigin = {
  id: number;
  origin: CallTagState;
};

export type Speaker = {
  id: number;
  userId: number | null;
  contactId: number | null;
  talkRatio: number;
};

export type SpeakerWithDetails = Speaker & {
  name: string;
  jobTitle?: string;
  teamNames?: string;
};

export type CallDetailsTeams = Team & {
  userIds: number[];
};

type Activity = {
  uuid: string;
  account?: Account;
  accountId?: number;
  calendarEventId?: number;
  callId?: number;
  createdById: number | null;
  createdOn: string;
  deal?: Deal;
  dealId?: number;
  deletedById: number | null;
  deletedOn: number | null;
  modifiedById: number | null;
  modifiedOn: string | null;
};

export type CommonCallDetails = {
  activity: Pick<Activity, 'uuid'>;
  id: number;
  name: string;
  status: CallImportStatus;
  areAiSummariesPublic?: boolean;
  duration: number;
  users: ApiUser[];
  teams: CallDetailsTeams[];
  accounts: Account[];
  listeners: ApiListener[];
  contacts: Contact[];
  defaultContactId?: number;
  ownerId: number;
  deal?: Deal;
  mediaUrl: string;
  previewUrl?: string;
  thumbnailUrl?: string;
  mediaType: CallMediaType;
  translatedLanguages: LanguageCode[];
  libraryItems: ApiLibraryItem[];
  date: string;
  tags: Tag[];
  callTags: TagWithOrigin[];
  availableTagIds: number[];
  externalPhoneProvider?: CallRecordingProvider;
  phoneProvider?: CallRecordingProvider;
  uuid: string;
  isPublic: boolean;
  speakers: Speaker[];
  language?: LanguageCode;
  tenantName?: string;
  reviewsCount: number;
  reviewsAverage?: number;
  tenantDisplayName?: string | null;
};

export type PrivateCallDetails = CommonCallDetails & {
  callOwner?: Pick<ApiUser, 'firstName'>;
  comments: Comment[];
  snippets: Snippet[];
  attendeeIds: number[];
};

type CallOwner = {
  id: number;
  firstName: string;
};

export type PublicCallDetails = CommonCallDetails & {
  hasAiAssistant: boolean;
  logoURI: string | null;
  callOwner: CallOwner;
  tenantDisplayName: string;
};

export type PublicSnippetCallDetails = PublicSnippet & PublicCallDetails;

export type CallDetails = PrivateCallDetails | PublicCallDetails | PublicSnippetCallDetails;

export type ApiShareCall = {
  isPublic: boolean;
  areAiSummariesPublic?: boolean;
  shareLink: string;
};

export const isPublicCallDetails = (callDetails: CallDetails): callDetails is PublicCallDetails => {
  return 'logoURI' in callDetails;
};

export const isPrivateCallDetails = (callDetails: CallDetails): callDetails is PrivateCallDetails => {
  return !isPublicCallDetails(callDetails);
};

export const isPublicSnippetCallDetails = (callDetails: CallDetails): callDetails is PublicSnippetCallDetails => {
  return 'callName' in callDetails;
};

export type ThreadComment = {
  commentId: number;
  timestamp: number | null;
  content: string;
  replies: Comment[];
  // @todo check if can be safely removed
  isFocusOnReply: boolean;
};

export type ThreadNote = {
  noteId: number;
  blockUuid: string;
  timestamp: number | null;
  content: string;
  replies: Comment[];
  // @todo check if can be safely removed
  isFocusOnReply: boolean;
};

export const isThreadNote = (thread: ThreadComment | ThreadNote | undefined): thread is ThreadNote => {
  return !!thread && 'noteId' in thread;
};

export const isThreadComment = (thread: ThreadComment | ThreadNote | undefined): thread is ThreadComment => {
  return !!thread && 'commentId' in thread;
};
