import React, { PropsWithChildren } from 'react';

import { DataTestIdProps, ProductTourIdProps } from '@/types/common';
import { cn } from '@/utils/utils';

type SidebarBaseProps = PropsWithChildren<{
  className?: string;
}> &
  ProductTourIdProps &
  DataTestIdProps;

const SidebarBase = ({ children, className, productTourId, dataTestId }: SidebarBaseProps) => (
  <nav
    className={cn(
      'group/sidebar',
      'left-0 flex w-52 h-full shrink-0 flex-col justify-between overflow-hidden z-50 outline-1 outline outline-grey-100 bg-grey-50',
      className
    )}
    data-product-tour-id={productTourId}
    data-test-id={dataTestId}
  >
    {children}
  </nav>
);

SidebarBase.displayName = 'SidebarBase';

export { SidebarBase };
