export const AuthenticationDataTestId = {
  SIGN_IN_EMAIL_TXT_ID: 'signInEmailTxt',
  SIGN_IN_PASSWORD_TXT_ID: 'signInPasswordTxt',
  SIGN_IN_BUTTON_ID: 'signInBtn',
  SIGN_IN_WITH_GOOGLE_BUTTON_ID: 'signInWithGoogleBtn',
  SIGN_IN_WITH_MICROSOFT_BUTTON_ID: 'signInWithMicrosoftBtn',
  SIGN_UP_LINK_ID: 'signUpLnk',
  SIGN_IN_PAGE_TITLE_ID: 'signInPageTitle',
  SIGNUP_EMAIL_TXT_ID: 'signUpEmailTxt',
  SIGNUP_PASSWORD_TXT_ID: 'signUpPasswordTxt',
  SIGNUP_CONFIRM_PASSWORD_TXT_ID: 'signUpConfirmPasswordTxt',
  SIGNUP_SUBMIT_BUTTON_ID: 'signUpSubmitBtn',
  RESET_PASSWORD_ID: 'signInResetPasswordBtn',
};
