import { Account } from '@/entities/account/account.types';
import { Contact } from '@/entities/contact/contact.types';
import { Tag } from '@/entities/tags/tags.types';
import { Team } from '@/entities/teams/teams.types';
import { Topic } from '@/entities/topics/topics.types';
import { User } from '@/entities/users/users.types';

import { CallTitleOption, RangeNumberOption, DealCloseDateOption, OtherOption } from './Common';

/* Interface returned by `/calls/search` endpoint.
 * Used only in this component for element in the dropdown
 */
export interface OptionInfo {
  id: string | number;
  name: string;
}

export enum OptionTypeCategory {
  CRM = 'CRM',
}

export enum RangeCategory {
  AFTER = 'after',
  BEFORE = 'before',
  GREATER = 'greater',
  LOWER = 'lower',
  BETWEEN = 'between',
  EQUAL = 'equal',
}

export enum OtherInfoOptionType {
  CALL_TITLE = 'Call Title',
  TRANSCRIPT = 'Transcript',
  PHONE_PROVIDER = 'Provider',
  LANGUAGE = 'Language',
  JOB_TITLE = 'Contact job title',
  LEAD_SOURCE = 'Lead source',
  LEAD_STATUS = 'Lead status',
  DEAL_STAGE = 'Deal stage',
  DEAL_STATUS = 'Deal status',
  DEAL_LOSS_REASON = 'Deal loss reason',
}

export enum RangeOptionType {
  DEAL_AMOUNT = 'Deal amount',
  DEAL_CLOSE_DATE = 'Deal close date',
  REVIEW_RATING = 'Call Review',
  CALL_DURATION = 'Call Duration',
}

export enum ReviewsOptionType {
  AI_CALL_SCORING = 'AI Call Scoring',
}

export enum SearchableObjectOptionType {
  ACCOUNT = 'Account',
  CALL_TITLE = 'Call Title',
  COMMENTEND_ONLY = 'Commented Only',
  CONTACT = 'Contact',
  OTHER = 'Other',
  TAG = 'Tag',
  TEAM = 'Team',
  TOPIC = 'Topic',
  TRANSCRIPT = 'Word',
  USER = 'User',
  START_DATE = 'Start date',
  END_DATE = 'End date',
  EXTERNAL_PHONE_PROVIDER = 'Provider',
  LANGUAGE = 'Language',
  JOB_TITLE = 'Contact job title',
  LEAD_SOURCE = 'Lead source',
  LEAD_STATUS = 'Lead status',
  DEAL = 'Deal',
  DEAL_STAGE = 'Deal stage',
  DEAL_STATUS = 'Deal status',
  DEAL_LOSS_REASON = 'Deal loss reason',
}

export type SearchableObject = (
  | Account
  | Contact
  | Team
  | Topic
  | User
  | Tag
  | CallTitleOption
  | RangeNumberOption
  | DealCloseDateOption
  | OtherOption
) & {
  searchableObjectType?: SearchableObjectOptionType;
}; // objectType is used to know which type of object is the searchableObjectexport enum SearchableObjectOptionType {/**
