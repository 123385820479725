import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import { AI_SCORING_QUESTIONS_API_BASE_URL, AI_SCORING_TEMPLATES_API_BASE_URL } from './aiScoringTemplates.constants';
import { mapApiAiScoringTemplateResponseToAiScoringTemplate } from './aiScoringTemplates.mappers';
import { getTemplatesByFilters, getTemplatesCount } from './aiScoringTemplates.selects';
import {
  AiScoringQuestionAccuracy,
  AiScoringTemplate,
  AiScoringTemplatesTableQueryParams,
} from './aiScoringTemplates.types';
import { queryKeys } from '../queryKeys';

type UseAiScoringTemplatesQueryParams<TSelectData = AiScoringTemplate[]> = CustomQueryOptions<
  AiScoringTemplate[],
  TSelectData
>;

export const useAiScoringTemplatesQuery = <TSelectData = AiScoringTemplate[]>(
  options?: UseAiScoringTemplatesQueryParams<TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.aiScoringTemplates.list(),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiScoringTemplate[]>(AI_SCORING_TEMPLATES_API_BASE_URL);
      return data.map((template) => mapApiAiScoringTemplateResponseToAiScoringTemplate(template));
    },
    ...options?.queryOptions,
  });
};

type UseAiScoringTemplateQueryParams<TSelectData = AiScoringTemplate> = CustomQueryOptions<
  AiScoringTemplate,
  TSelectData
> & { templateUuid: AiScoringTemplate['uuid'] };

export const useAiScoringDetailsTemplateQuery = ({ templateUuid, queryOptions }: UseAiScoringTemplateQueryParams) => {
  return useQuery({
    queryKey: queryKeys.aiScoringTemplates.byUuid(templateUuid),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiScoringTemplate>(
        combineRoutes([AI_SCORING_TEMPLATES_API_BASE_URL, templateUuid])
      );
      return mapApiAiScoringTemplateResponseToAiScoringTemplate(data);
    },
    ...queryOptions,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useAiScoringTemplatesTableQuery = (params: AiScoringTemplatesTableQueryParams) => {
  return useAiScoringTemplatesQuery({
    queryOptions: {
      select: useCallback((templates: AiScoringTemplate[]) => getTemplatesByFilters(templates, params), [params]),
    },
  });
};

export const useAiScoringTemplatesCountQuery = () =>
  useAiScoringTemplatesQuery<number>({
    queryOptions: {
      select: getTemplatesCount,
    },
  });

type UseAiScoringQuestionHistoryQueryParams<TSelectData = AiScoringQuestionAccuracy[]> = CustomQueryOptions<
  AiScoringQuestionAccuracy[],
  TSelectData
> & { questionUuid: AiScoringQuestionAccuracy['uuid'] };

export const useAiScoringQuestionHistoryQuery = ({
  questionUuid,
  queryOptions,
}: UseAiScoringQuestionHistoryQueryParams) => {
  return useQuery({
    queryKey: queryKeys.aiScoringTemplates.questionHistory(questionUuid),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiScoringQuestionAccuracy[]>(
        combineRoutes([AI_SCORING_QUESTIONS_API_BASE_URL, questionUuid, 'history'])
      );
      return data;
    },
    ...queryOptions,
  });
};
