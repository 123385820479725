import React from 'react';

import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { DIALOGS_IDS } from '@/enums/constants';
import StartRecordingFilledIcon from '@/static/icons/audiovisuals/record-Filled.svg?react';
import { Event } from '@/tracking/events';

import { MobileNavbarButton } from './MobileNavbarButton';
import { useDialog } from '../common/ui-components/dialogs';
import {
  MeetingRecorderContentStates,
  MeetingRecorderStatuses,
} from '../MainSidebar/SidebarBottomSection/MeetingRecorder/meeting-recorder.constants';
import { meetingRecorderStore } from '../MainSidebar/SidebarBottomSection/MeetingRecorder/meeting-recorder.store';
import {
  getFirstFileFromStorage,
  calculateWavDuration,
  requestMicrophoneAccess,
} from '../MainSidebar/SidebarBottomSection/MeetingRecorder/meeting-recorder.utils';
import { MeetingRecorderDialog } from '../MainSidebar/SidebarBottomSection/MeetingRecorder/MeetingRecorderDialog';

export const MobilePhysicalRecorderButton = () => {
  const { t } = useTranslation();
  const { openDialog } = useDialog(DIALOGS_IDS.RECORDER_DIALOG);

  useEffectOnce(() => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const fetchFile = async () => {
      try {
        const foundFile = await getFirstFileFromStorage();
        if (foundFile) {
          meetingRecorderStore.setFileDuration(calculateWavDuration(foundFile.size));
          meetingRecorderStore.setCurrentFileName(foundFile?.name);
          meetingRecorderStore.setMeetingRecorderContentState(MeetingRecorderContentStates.RECORDER);
          meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.COMPLETED);
          mixpanel.track(Event.CLICK_OPEN_PHYISICAL_RECORDER, {
            source: 'navbar',
            device: 'mobile',
          });
          openDialog();
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    fetchFile();
  });

  const handleOpenRecordDialog = () => {
    requestMicrophoneAccess();
    mixpanel.track(Event.CLICK_OPEN_PHYISICAL_RECORDER, {
      source: 'navbar',
      device: 'mobile',
    });
    openDialog();
  };

  return (
    <>
      <MobileNavbarButton
        variant="button"
        onClick={() => {
          mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: 'modjo-physical-recorder' });
          handleOpenRecordDialog();
        }}
      >
        <MobileNavbarButton.Icon
          lineIcon={StartRecordingFilledIcon}
          filledIcon={StartRecordingFilledIcon}
          className="fill-red"
        />
        <MobileNavbarButton.Label>{t('record')}</MobileNavbarButton.Label>
      </MobileNavbarButton>
      <MeetingRecorderDialog />
    </>
  );
};
