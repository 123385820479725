export const SnippetsDataTestId = {
  SNIPPET_CREATE_BUTTON_ID: 'snippetCreateBtn',
  SNIPPET_CANCEL_BUTTON_ID: 'snippetCancelBtn',
  SNIPPET_CREATE_COMMENT_BUTTON_ID: 'snippetCreateCommentBtn',
  SNIPPET_MODAL_CONTAINER: 'snippetModalContainer',
  SNIPPET_ADD_TO_LIBRARY_BUTTON_ID: 'snippetAddToLibraryBtn',
  SNIPPET_DOWNLOAD_BUTTON_ID: 'snippetDownloadBtn',
  SNIPPET_SHARE_BUTTON_ID: 'snippetShareBtn',
  SNIPPET_COPY_LINK_BUTTON_ID: 'snippetCopyLinkBtn',
  SNIPPET_COMMENT_MODAL_TEXT_AREA_ID: 'snippetCommentTxtArea',
};
