import {
  AiScoringTemplate,
  AiScoringTemplatesTableQueryParams,
  AiScoringTemplatesSortByFields,
} from '@/entities/ai-scoring-templates/aiScoringTemplates.types';
import { TemplateStatus } from '@/types/common';
import { SortOrders } from '@/types/sort';
import { toNumber } from '@/utils/utils';

import { SelectOption } from '../../components/common/ui-components/types';

const TEMPLATE_WEIGHTS: Record<TemplateStatus, number> = {
  [TemplateStatus.PUBLISHED]: 0,
  [TemplateStatus.PENDING]: 1,
  [TemplateStatus.ARCHIVED]: 2,
};

export function isAiScoringTemplate(template: unknown): template is AiScoringTemplate {
  return template !== null && typeof template === 'object' && 'matchCriteria' in template;
}

export const getTemplatesCount = (templates: AiScoringTemplate[]) =>
  templates.filter((template) => template.status !== TemplateStatus.ARCHIVED).length;

export const getTemplatesByFilters = (templates: AiScoringTemplate[], params: AiScoringTemplatesTableQueryParams) => {
  return templates
    .filter((template) => {
      // An archived template corresponds to a deleted template
      if (template.status === TemplateStatus.ARCHIVED) {
        return false;
      }

      if (params.teamIdFilter) {
        return template.matchCriteria.teamIds.includes(toNumber(params.teamIdFilter)) && template;
      }

      if (params.tagIdFilter) {
        return template.matchCriteria.tagIds.includes(toNumber(params.tagIdFilter)) && template;
      }

      if (params.statusFilter) {
        return template.status === params.statusFilter && template;
      }

      return template;
    })
    .toSorted((left: AiScoringTemplate, right: AiScoringTemplate) => {
      const isOrderAsc = params.sortOrder === SortOrders.ASC;

      switch (params.sortBy) {
        case AiScoringTemplatesSortByFields.TITLE: {
          return isOrderAsc ? left.title.localeCompare(right.title) : right.title.localeCompare(left.title);
        }
        case AiScoringTemplatesSortByFields.STATUS: {
          return isOrderAsc
            ? TEMPLATE_WEIGHTS[left.status] - TEMPLATE_WEIGHTS[right.status]
            : TEMPLATE_WEIGHTS[right.status] - TEMPLATE_WEIGHTS[left.status];
        }

        case AiScoringTemplatesSortByFields.TEAM: {
          const leftTeamIds = left.matchCriteria.teamIds;
          const rightTeamIds = right.matchCriteria.teamIds;
          return isOrderAsc
            ? leftTeamIds && rightTeamIds
              ? leftTeamIds.length - rightTeamIds.length
              : leftTeamIds
                ? -1
                : 1
            : rightTeamIds && leftTeamIds
              ? rightTeamIds.length - leftTeamIds.length
              : rightTeamIds
                ? -1
                : 1;
        }
        case AiScoringTemplatesSortByFields.TAG: {
          const leftTagIds = left.matchCriteria.tagIds;
          const rightTagIds = right.matchCriteria.tagIds;
          return isOrderAsc
            ? leftTagIds && rightTagIds
              ? leftTagIds.length - rightTagIds.length
              : leftTagIds
                ? -1
                : 1
            : rightTagIds && leftTagIds
              ? rightTagIds.length - leftTagIds.length
              : rightTagIds
                ? -1
                : 1;
        }
        case AiScoringTemplatesSortByFields.LAST_EDITED: {
          const leftModifiedDate = new Date(left.modifiedOn).getTime();
          const rightModifiedDate = new Date(right.modifiedOn).getTime();
          return isOrderAsc ? leftModifiedDate - rightModifiedDate : rightModifiedDate - leftModifiedDate;
        }
        default: {
          const exhaustiveCheck: never = params.sortBy;
          throw new Error(exhaustiveCheck);
        }
      }
    });
};

export const getAiCallScoringTemplatesMap = (
  templates: AiScoringTemplate[]
): Map<AiScoringTemplate['uuid'], AiScoringTemplate> => new Map(templates.map((template) => [template.uuid, template]));

export const toAiCallScoringTemplateOptions = (templates: AiScoringTemplate[]): SelectOption[] =>
  templates
    .sort((a, b) => {
      if (a.deletedOn && !b.deletedOn) {
        return 1;
      }
      if (!a.deletedOn && b.deletedOn) {
        return -1;
      }
      return a.title.localeCompare(b.title);
    })
    .map(({ uuid, title, deletedOn }) => ({
      id: uuid,
      label: title,
      secondaryLabel: deletedOn ? '(Deleted)' : undefined,
    }));
