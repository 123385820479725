export const MediaPlayerDataTestId = {
  MEDIA_PLAYER_PLAY_BUTTON_ID: 'mediaPlayerPlayBtn',
  MEDIA_PLAYER_PAUSE_BUTTON_ID: 'mediaPlayerPauseBtn',
  MEDIA_PLAYER_HOVER_PLAY_BUTTON_ID: 'mediaPlayerHoverPlayBtn',
  MEDIA_PLAYER_HOVER_PAUSE_BUTTON_ID: 'mediaPlayerHoverPauseBtn',
  MEDIA_PLAYER_HOVER_BACKWARD_BUTTON_ID: 'mediaPlayerHoverBackwardBtn',
  MEDIA_PLAYER_HOVER_FORWARD_BUTTON_ID: 'mediaPlayerHoverForwardBtn',
  MEDIA_PLAYER_CONTROLS_OVERLAY_ID: 'mediaPlayerControlsOverlay',
  MEDIA_PLAYER_PAUSE_OVERLAY_ID: 'mediaPlayerPauseOverlay',
  MEDIA_PLAYER_PLAYBACK_RATE_BUTTON_ID: 'mediaPlayerPlaybackRateBtn',
  MEDIA_PLAYER_FULLSCREEN_BUTTON_ID: 'mediaPlayerFullscreenBtn',
  MEDIA_PLAYER_SUBTITLES_ID: 'mediaPlayerSubtitles',
  MEDIA_PLAYER_SUBTITLES_OPTIONS_ID: 'mediaPlayerSubtitlesOptions',
  MEDIA_PLAYER_SUBTITLES_BUTTON_ID: 'mediaPlayerSubtitlesBtn',
  MEDIA_PLAYER_PROGRESS_BAR_ID: 'mediaPlayerProgressBar',
  MEDIA_PLAYER_ADD_COMMENT_BTN_ID: 'mediaPlayerAddCommentBtn',
};
