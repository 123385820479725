import React from 'react';

import { Trans } from 'react-i18next';

type NoResultSearchProps = {
  hasValues: boolean;
  inputValue?: string;
};

export const NoResultSearch = ({ hasValues, inputValue }: NoResultSearchProps) => {
  if (hasValues || !inputValue) {
    return null;
  }
  return (
    <span className="text-sm">
      <Trans i18nKey="filters.search.noResultFor" values={{ inputValue }} />
    </span>
  );
};
