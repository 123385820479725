import type { Config } from 'tailwindcss/types/config';
import headlessuiPlugin from '@headlessui/tailwindcss';

const config = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      timeline: '#E5F3FB',
      warning: '#FFB946',
      pale: {
        grey: '#f2f0fe',
        lavender: '#FDF6FF',
      },
      lavender: {
        DEFAULT: '#f5ebff',
        transparent: {
          26: 'rgba(247, 220, 255, 0.26)',
          30: 'rgba(246, 239, 255, 0.7)',
        },
      },
      green: {
        50: '#F1FEF3',
        100: '#dcfce1',
        200: '#bafacb',
        300: '#95f2b6',
        400: '#77e5aa',
        DEFAULT: '#4dd599', // 500
        600: '#38b78b',
        700: '#26997d',
        800: '#187b6d',
        900: '#0E6662',
      },
      blue: {
        50: '#F0F5FF',
        100: '#d7e4ff',
        200: '#afc9ff',
        300: '#86afff',
        400: '#5e94ff',
        DEFAULT: '#3679FF', // 500
        600: '#2b61cc',
        700: '#204999',
        800: '#163066',
        900: '#0b1833',
      },
      red: {
        50: '#FFF0F0',
        100: '#ffdbdb',
        200: '#ffb6b6',
        300: '#ff9292',
        400: '#ff6d6d',
        DEFAULT: '#ff4949', // 500
        600: '#cc3a3a',
        700: '#992c2c',
        800: '#661d1d',
        900: '#330f0f',
      },
      orange: {
        50: '#FFF9EB',
        100: '#FFF1CC',
        200: '#FFE399',
        300: '#FFD466',
        400: '#FFC633',
        DEFAULT: '#FFB800',
        600: '#CC9300',
        700: '#996E00',
        800: '#664A00',
        900: '#332500',
      },
      grey: {
        white: '#FFFFFF',
        50: '#FCFCFE',
        100: '#EFF0F9',
        200: '#D8D9E8',
        300: '#B5B8D2',
        400: '#9C9FB8',
        DEFAULT: '#88889B', // 500
        600: '#6D6D7C',
        700: '#60606E',
        800: '#4D4F5E',
        900: '#111827',
      },
      transparent: {
        DEFAULT: 'transparent',
        4: 'rgba(0, 0, 0, 0.04)',
        80: 'rgba(0, 0, 0, 0.8)',
        basic: {
          4: 'rgba(158, 158, 158, 0.04)',
          8: 'rgba(158, 158, 158, 0.08)',
          16: 'rgba(158, 158, 158, 0.16)',
          24: 'rgba(158, 158, 158, 0.24)',
          38: 'rgba(158, 158, 158, 0.38)',
          48: 'rgba(158, 158, 158, 0.48)',
        },
      },
      states: {
        white: {
          disabled: 'rgba(158, 158, 158, 0.38)',
          'border-disabled': 'rgba(158, 158, 158, 0.0)',
        },
        secondary: {
          hover: 'rgba(25, 42, 61, 0.09)',
          'border-hover': 'rgba(25, 42, 61, 0.0)',
        },
      },
      providers: {
        'google-meet': {
          DEFAULT: '#3679FF',
          hover: '#2B61CC',
        },
        'microsoft-teams': {
          DEFAULT: '#4B53BC',
          hover: '#3C4296',
        },
        outlook: {
          DEFAULT: '#1784D9',
          hover: '#3E82D3',
        },
        zoom: {
          DEFAULT: '#0078D4',
          hover: '#0060AA',
        },
        apizee: {
          DEFAULT: '#ED5131',
          hover: '#D5492C',
        },
      },
    },
    extend: {
      screens: {
        '3xl': '1920px',
        'small-height': { raw: '(min-height: 530px)' },
      },
      gridTemplateColumns: {
        // other column grids for more precision
        13: 'repeat(13, minmax(0, 1fr))',
        16: 'repeat(16, minmax(0, 1fr))',
      },
      gridColumn: {
        'span-13': 'span 13 / span 13',
        'span-14': 'span 14 / span 14',
        'span-15': 'span 15 / span 15',
        'span-16': 'span 16 / span 16',
      },
      fontSize: {
        '2xs': [
          '0.625rem',
          {
            lineHeight: '0.875rem',
          },
        ],
      },
      spacing: {
        4.5: '1.125rem',
        5.5: '1.375rem',
      },
      keyframes: {
        grow: {
          '0%': { width: '0%' },
          '50%': { width: '50%' },
          '100%': { width: '100%' },
        },
      },
      animation: {
        grow: 'grow 1s ease-in-out',
      },
      flexGrow: {
        '2': '2',
        '3': '3',
        '4': '4',
      },
      boxShadow: {
        lg: '0px 4px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,0.1)',
        input: '0 0 0 2px rgba(39, 94, 254, 0.3)',
        'list-item': '0 4px 4px 0 rgba(199, 201, 255, 0.45)',
        'list-item-hovered': '0 5px 9px 0 rgba(199, 201, 255, 0.65)',
        card: '0px 2px 2px rgba(34, 25, 61, 0.1)',
        'card-hover': '0px 4px 12px rgba(34, 25, 61, 0.1)',
        'integration-card': '0px 4px 12px rgba(34, 25, 61, 0.1)',
        'calendar-button': '0px 2px 2px rgba(34, 25, 61, 0.1)',
        'button-hover': '0px 4px 4px 0px rgba(34, 25, 61, 0.08)',
        player: '0px 24px 32px -12px rgba(34, 25, 61, 0.1)',
        'public-player': '0px 14px 34px 0px rgba(39, 22, 77, 0.25)',
        'calendar-item-hover': '0 3px 4px 0 rgba(199, 201, 255, 0.5)',
        'inner-purple': 'inset 0px -1px 2px 0px #641BFD33',
        'comment-box': '0px 4px 12px 0px #22193D1A, 0px -2px 4px 0px #2B106412 inset',
      },
      borderRadius: {
        '2sm': '4px',
        '2lg': '10px',
      },
    },
  },
  variants: {
    extend: {
      display: ['group-hover'],
    },
  },
  plugins: [headlessuiPlugin({ prefix: 'ui' })],
} satisfies Config;

export default config;
