import { useCallback, useMemo } from 'react';

import { captureException } from '@sentry/react';
import { InfiniteData, useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { hasRefreshToken } from '@/authenticate.service';
import { UserRole } from '@/enums';
import { authenticationStore } from '@/stores/authentication.store';
import { CustomInfiniteQueryOptions, CustomQueryOptions } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { sortProviders } from '@/types/providers';
import { DEFAULT_PAGINATION_PER_PAGE } from '@/utils/paginated.utils';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam } from '@/utils/utils';

import { userByIdLoader } from './users.dataloader';
import { mapApiUserToUser } from './users.mappers';
import { ApiCurrentUser, ApiUser, User, UserPreferences, UsersQueryParams } from './users.types';
import { queryKeys } from '../queryKeys';

export const USERS_API_BASE_URL = '/users';

/**
 * Query object for current user
 * This object is exported in order to be used in the user store (for login method)
 */
export const currentUserQueryObject = {
  queryKey: queryKeys.users.me(),
  queryFn: async () => {
    const { data } = await apiService.API.get<ApiCurrentUser>(combineRoutes([USERS_API_BASE_URL, 'me']));
    authenticationStore.setIsAuthenticated(!!data);
    return data;
  },
};

const useCurrentUserQuery = <TSelectData = ApiCurrentUser>(options?: CustomQueryOptions<ApiCurrentUser, TSelectData>) =>
  useQuery({ enabled: hasRefreshToken(), ...currentUserQueryObject, ...options?.queryOptions });

const useUsersQuery = <TSelectData = User[]>(options?: CustomQueryOptions<User[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.users.list(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiUser[]>(`${USERS_API_BASE_URL}/list`);
      return data.map((user) => mapApiUserToUser(user));
    },
    ...options?.queryOptions,
  });
};

const useUserByIdQuery = <TSelectData = User>(
  userId: number,
  queryOptions?: CustomQueryOptions<User, TSelectData>['queryOptions']
) => {
  return useQuery({
    queryKey: queryKeys.users.id(userId),
    queryFn: () => userByIdLoader.load(userId),
    enabled: !!userId,
    ...queryOptions,
  });
};

const useUserByIdsQuery = (userIds: number[]) => {
  const queries = useQueries({
    queries: userIds.map((userId) => {
      return {
        queryKey: queryKeys.users.id(userId),
        queryFn: () => userByIdLoader.load(userId),
      };
    }),
    combine: (results) => {
      return {
        data: new Map(
          results.reduce<[number, User][]>((acc, result) => {
            if (result.data) {
              acc.push([result.data.id, result.data]);
            }
            return acc;
          }, [])
        ),
        isPending: results.some((result) => result.isPending),
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });
  // we have to memo result to avoid infinite loop due to combine result not memoized
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => queries, [userIds, queries.isPending, queries.isSuccess]);
};

const useInfiniteUsersQuery = <T = InfiniteData<PaginationRequestResult<ApiUser>>>(
  params?: UsersQueryParams,
  options?: CustomInfiniteQueryOptions<PaginationRequestResult<ApiUser>, T>
) => {
  return useInfiniteQuery({
    queryKey: queryKeys.users.filters(params),
    queryFn: async ({ pageParam }) => {
      const data = await apiService.requestPagination<ApiUser>(`${USERS_API_BASE_URL}/v2/list`, {
        ...params,
        page: pageParam,
        perPage: DEFAULT_PAGINATION_PER_PAGE,
      });
      return {
        ...data,
        values: data.values.map((user) => ({
          ...user,
          providersId: user.providersId.sort((a, b) => sortProviders(a.provider, b.provider)),
        })),
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, DEFAULT_PAGINATION_PER_PAGE),
    ...options?.queryOptions,
  });
};

export const useTotalUsersQuery = () => {
  return useInfiniteUsersQuery(undefined, {
    queryOptions: {
      select: useCallback((data: InfiniteData<PaginationRequestResult<ApiUser>, unknown>) => {
        return data.pages[0].pagination?.totalValues ?? 0;
      }, []),
    },
  });
};

const useUserPreferencesQuery = () => {
  return useQuery({
    queryKey: queryKeys.users.preferences(),
    queryFn: async () => {
      const { data, status } = await apiService.API.get<UserPreferences>('/preferences');
      if (!data) {
        captureException(
          new Error(`UserHomepagePreference not retrieved properly. Response returned status ${status}`)
        );
      }
      return data;
    },
  });
};

const useAdminUsersQuery = () => {
  return useUsersQuery({
    queryOptions: {
      select: useCallback((users: User[]) => {
        return users.filter((user) => !user.deletedOn && user.role === UserRole.ADMIN);
      }, []),
    },
  });
};

const useFarmerUserQuery = <TSelectData = User>(options?: CustomQueryOptions<User, TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.users.farmer(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiUser>(combineRoutes([USERS_API_BASE_URL, '/farmer']));
      return mapApiUserToUser(data);
    },
    ...options?.queryOptions,
    // Once we have the info, it's considered definitive.
    staleTime: Number.POSITIVE_INFINITY,
  });
};

const useUsersUploadTemplateQuery = <TSelectData = string>(options?: CustomQueryOptions<string, TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.users.uploadTemplate(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string>(combineRoutes([USERS_API_BASE_URL, '/upload-template']));
      return data;
    },
    ...options?.queryOptions,
  });
};

const useExportUsersQuery = () => {
  return useQuery({
    queryKey: queryKeys.users.export(),
  });
};

export {
  useCurrentUserQuery,
  useUsersQuery,
  useUserByIdQuery,
  useUserByIdsQuery,
  useInfiniteUsersQuery,
  useUserPreferencesQuery,
  useAdminUsersQuery,
  useFarmerUserQuery,
  useUsersUploadTemplateQuery,
  useExportUsersQuery,
};
