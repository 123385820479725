import React, { ReactNode } from 'react';

import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { useHideModjoDeals } from '@/components/deals/useHideModjoDeals';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { User } from '@/entities/users/users.types';
import AccountIcon from '@/static/Account.svg?react';
import AiStarsIcon from '@/static/ai_stars_line.svg?react';
import EnterKeyIcon from '@/static/icons/arrows/arrow-corner-cw-lb-Line.svg?react';
import ProviderIcon from '@/static/icons/audiovisuals/volume-high-Line.svg?react';
import LeadSourceIcon from '@/static/icons/basic/filter-2-Line.svg?react';
import DealIcon from '@/static/icons/basic/fire-Line.svg?react';
import TopicIcon from '@/static/icons/basic/hashtag-Line.svg?react';
import TagIcon from '@/static/icons/basic/tag-2-Line.svg?react';
import CallIcon from '@/static/icons/call/phone-Line.svg?react';
import JobTitleIcon from '@/static/icons/editor/first-line-1-rec-Line.svg?react';
import FileAgreement from '@/static/icons/files/file-agreement-Line.svg?react';
import DollarIcon from '@/static/icons/finances/dollar-circle-Line.svg?react';
import DealAmountIcon from '@/static/icons/finances/euro-circle-Line.svg?react';
import DealCloseDateIcon from '@/static/icons/time/calendar-cross-Line.svg?react';
import ContactIcon from '@/static/icons/time/calendar-person-Line.svg?react';
import StopWatchIcon from '@/static/icons/time/stopwatch-Line.svg?react';
import UserCircleLineIcon from '@/static/icons/users/user-circle-Line.svg?react';
import UserIcon from '@/static/icons/users/user-Line.svg?react';
import TeamIcon from '@/static/icons/users/users-Line.svg?react';
import CRMIcon from '@/static/icons/weather/cloud-Line.svg?react';
import PineappleIcon from '@/static/Pineapple.svg?react';
import LanguageIcon from '@/static/Title.svg?react';
import TranscriptIcon from '@/static/Transcript.svg?react';
import { ColorPalette, twThemeColors } from '@/theme/colors';
import { i18n } from '@/translation/i18n';
import { CRMProvider, Provider, Providers } from '@/types/providers';
import { BEGIN_BOLD_MARKER, boldMarkersToJSX, END_BOLD_MARKER } from '@/utils/BoldMarkersToJsx';
import { currentYear, formatDate, getDateFormatByLocale } from '@/utils/date-utils';
import { insertBoldMarkers } from '@/utils/search';

import { DEAL_CLOSE_DATE_ID } from './DateSelect';
import {
  SearchableObject,
  SearchableObjectOptionType,
  RangeOptionType,
  ReviewsOptionType,
  OptionInfo,
  OtherInfoOptionType,
  OptionTypeCategory,
  RangeCategory,
} from './search-components.types';

export const RANGE_NUMBER_CATEGORIES = new Set([
  RangeOptionType.DEAL_AMOUNT,
  RangeOptionType.REVIEW_RATING,
  RangeOptionType.CALL_DURATION,
]);

export const DEAL_AMOUNT_ID = 'deal-amount';
export const REVIEW_RATING_ID = 'review-rating';
const AI_CALL_SCORING_ID = 'ai-call-scoring';
export const AI_CALL_SCORING_TEMPLATE_ID = 'ai-call-scoring-template-id';
export const AI_CALL_SCORING_RANGE_ID = 'ai-call-scoring-range';
export const CALL_DURATION_ID = 'call-duration';

export const getRangeCategoryId = (category: RangeOptionType | ReviewsOptionType) => {
  switch (category) {
    case RangeOptionType.DEAL_AMOUNT: {
      return DEAL_AMOUNT_ID;
    }
    case RangeOptionType.REVIEW_RATING: {
      return REVIEW_RATING_ID;
    }
    case ReviewsOptionType.AI_CALL_SCORING: {
      return AI_CALL_SCORING_RANGE_ID;
    }
    default: {
      return CALL_DURATION_ID;
    }
  }
};

export const getRangeUnit = (category: RangeOptionType | ReviewsOptionType) => {
  switch (category) {
    case RangeOptionType.CALL_DURATION: {
      return i18n.t('filters.search.min', { value: 0 });
    }
    case ReviewsOptionType.AI_CALL_SCORING: {
      return `${i18n.t('filters.search.in')} %`;
    }
    case RangeOptionType.REVIEW_RATING: {
      return i18n.t('filters.search.grade');
    }
    case RangeOptionType.DEAL_AMOUNT: {
      return '€';
    }
    default: {
      return undefined;
    }
  }
};

export const CALL_REVIEW_MAX_RATING = 4;
export const AI_CALL_SCORING_MAX_RATING = 100;

export type DealCloseDateOption = OptionInfo & {
  startCloseDate?: Date;
  endCloseDate?: Date;
};

export type RangeNumberOption = OptionInfo & {
  min?: number;
  max?: number;
  unit?: string;
};

type AiCallScoringTemplateOption = OptionInfo & {
  templateUuid: string;
};

export type AiCallScoringOption = RangeNumberOption;

export const isAiCallScoringTemplateOption = (option: AiCallScoringOption): option is AiCallScoringTemplateOption =>
  option?.id.toString().includes(AI_CALL_SCORING_TEMPLATE_ID);
export const isAiCallScoringRangeOption = (option: AiCallScoringOption): option is RangeNumberOption =>
  option?.id.toString().includes(AI_CALL_SCORING_RANGE_ID);

export type CallTitleOption = OptionInfo & {
  type: SearchableObjectOptionType;
  date?: string;
  goToCallDetails?: boolean;
};

export type DealOption = OptionInfo & {
  type: SearchableObjectOptionType;
};

export class OtherOption implements OptionInfo {
  public readonly id: string | number = '';
  public readonly name: string = '';
  public readonly type: OtherInfoOptionType = OtherInfoOptionType.CALL_TITLE;
  public readonly otherLabelType: string = '';

  public constructor(id?: string | number, name?: string, type?: string, otherLabelType?: string) {
    if (id) {
      this.id = id;
    }
    if (name) {
      this.name = name;
    }
    if (type) {
      this.type = type as OtherInfoOptionType;
    }
    this.otherLabelType = (otherLabelType ?? this.type) as SearchableObjectOptionType;
  }
}

export type FiltersCategories =
  | OptionTypeCategory
  | RangeOptionType
  | RangeCategory
  | SearchableObjectOptionType
  | OtherInfoOptionType
  | ReviewsOptionType;

/**
 * This is the list of all the filters categories that can be used in the search bar with their translation key
 * we can't rename the enum values because they are used in mixpanel events
 * So we will need to go through this record to get the translation key
 */
export const filtersCategoriesTranslationKeys = {
  [OptionTypeCategory.CRM]: 'filters.categories.crm',
  [SearchableObjectOptionType.ACCOUNT]: 'filters.categories.account',
  [OtherInfoOptionType.CALL_TITLE]: 'filters.categories.callTitle',
  [SearchableObjectOptionType.COMMENTEND_ONLY]: 'filters.categories.commentEndOnly',
  [SearchableObjectOptionType.CONTACT]: 'contact',
  [SearchableObjectOptionType.OTHER]: 'filters.categories.other',
  [SearchableObjectOptionType.TAG]: 'filters.categories.tag',
  [SearchableObjectOptionType.TEAM]: 'filters.categories.team',
  [SearchableObjectOptionType.TOPIC]: 'filters.categories.topic',
  [SearchableObjectOptionType.TRANSCRIPT]: 'filters.categories.transcript',
  [SearchableObjectOptionType.USER]: 'user',
  [SearchableObjectOptionType.START_DATE]: 'filters.categories.startDate',
  [SearchableObjectOptionType.END_DATE]: 'filters.categories.endDate',
  [SearchableObjectOptionType.DEAL]: 'filters.categories.deal',
  [OtherInfoOptionType.PHONE_PROVIDER]: 'filters.categories.provider',
  [OtherInfoOptionType.LANGUAGE]: 'filters.categories.language',
  [OtherInfoOptionType.JOB_TITLE]: 'filters.categories.contactJobTitle',
  [OtherInfoOptionType.LEAD_SOURCE]: 'filters.categories.leadSource',
  [OtherInfoOptionType.LEAD_STATUS]: 'filters.categories.leadStatus',
  [OtherInfoOptionType.DEAL_STAGE]: 'filters.categories.dealStage',
  [OtherInfoOptionType.DEAL_STATUS]: 'filters.categories.dealStatus',
  [OtherInfoOptionType.DEAL_LOSS_REASON]: 'filters.categories.dealLossReason',
  [RangeOptionType.DEAL_AMOUNT]: 'filters.categories.dealAmount',
  [RangeOptionType.DEAL_CLOSE_DATE]: 'filters.categories.dealCloseDate',
  [RangeOptionType.REVIEW_RATING]: 'filters.categories.reviewRating',
  [RangeOptionType.CALL_DURATION]: 'filters.categories.callDuration',
  [RangeCategory.AFTER]: 'filters.categories.after',
  [RangeCategory.BEFORE]: 'filters.categories.before',
  [RangeCategory.GREATER]: 'filters.categories.greater',
  [RangeCategory.LOWER]: 'filters.categories.lower',
  [RangeCategory.BETWEEN]: 'filters.categories.between',
  [RangeCategory.EQUAL]: 'filters.categories.equal',
  [OtherInfoOptionType.TRANSCRIPT]: 'filters.categories.transcript',
  [ReviewsOptionType.AI_CALL_SCORING]: 'filters.categories.aiCallScoring',
} as const;

export function isRangeOptionType(category: FiltersCategories): category is RangeOptionType {
  return (category as RangeOptionType) !== undefined;
}

export const DATE_FILTERS = [RangeCategory.AFTER, RangeCategory.BEFORE, RangeCategory.BETWEEN, RangeCategory.EQUAL];
export const NUMBER_FILTERS = [RangeCategory.GREATER, RangeCategory.LOWER, RangeCategory.BETWEEN, RangeCategory.EQUAL];
export const DURATION_FILTERS = [RangeCategory.GREATER, RangeCategory.LOWER, RangeCategory.BETWEEN];
export const COMMON_CRM_FILTERS: FiltersCategories[] = [
  SearchableObjectOptionType.ACCOUNT,
  SearchableObjectOptionType.JOB_TITLE,
  RangeOptionType.DEAL_AMOUNT,
  RangeOptionType.DEAL_CLOSE_DATE,
  SearchableObjectOptionType.DEAL_LOSS_REASON,
  SearchableObjectOptionType.DEAL_STAGE,
  SearchableObjectOptionType.DEAL_STATUS,
  SearchableObjectOptionType.LEAD_SOURCE,
  SearchableObjectOptionType.LEAD_STATUS,
];
const CRM_CONTACT_FILTER: FiltersCategories[] = [SearchableObjectOptionType.CONTACT];
export const CRM_FILTERS: FiltersCategories[] = [...COMMON_CRM_FILTERS, ...CRM_CONTACT_FILTER].sort((a, b) =>
  a.localeCompare(b)
);

export const SINGLE_SELECT_CATEGORIES = new Set([
  SearchableObjectOptionType.CALL_TITLE,
  SearchableObjectOptionType.DEAL,
  SearchableObjectOptionType.DEAL_STATUS,
  SearchableObjectOptionType.DEAL_STAGE,
  SearchableObjectOptionType.LEAD_STATUS,
]);
const jobTitleProviders = new Set<Provider>([
  Providers.SALESFORCE,
  Providers.HUBSPOT,
  Providers.ZENDESK,
  Providers.ZOHO,
]);
const closeAndAmountAndSourceDateProviders = new Set<Provider>([Providers.SALESFORCE, Providers.HUBSPOT]);
const statusProviders = new Set<Provider>([
  Providers.SALESFORCE,
  Providers.HUBSPOT,
  Providers.PIPEDRIVE,
  Providers.SELLSY,
]);
const stageProviders = new Set<Provider>([
  Providers.SALESFORCE,
  Providers.HUBSPOT,
  Providers.PIPEDRIVE,
  Providers.SELLSY,
  Providers.ZOHO,
]);
const leadStatusProviders = new Set<Provider>([Providers.SALESFORCE]);

const makeCrmFiltersFromCrm = (crm: Provider): (FiltersCategories | undefined)[] => {
  const display = {
    jobTitle: jobTitleProviders.has(crm),
    amount: closeAndAmountAndSourceDateProviders.has(crm),
    closeDate: closeAndAmountAndSourceDateProviders.has(crm),
    lossReason: crm === Providers.HUBSPOT,
    status: statusProviders.has(crm),
    stage: stageProviders.has(crm),
    leadStatus: leadStatusProviders.has(crm),
    source: closeAndAmountAndSourceDateProviders.has(crm),
  };
  return [
    SearchableObjectOptionType.ACCOUNT,
    SearchableObjectOptionType.CONTACT,
    display.jobTitle ? SearchableObjectOptionType.JOB_TITLE : undefined,
    display.amount ? RangeOptionType.DEAL_AMOUNT : undefined,
    display.closeDate ? RangeOptionType.DEAL_CLOSE_DATE : undefined,
    display.lossReason ? SearchableObjectOptionType.DEAL_LOSS_REASON : undefined,
    display.stage ? SearchableObjectOptionType.DEAL_STAGE : undefined,
    display.status ? SearchableObjectOptionType.DEAL_STATUS : undefined,
    display.leadStatus ? SearchableObjectOptionType.LEAD_STATUS : undefined,
    display.source ? SearchableObjectOptionType.LEAD_SOURCE : undefined,
  ].filter(Boolean);
};

const useMakeDefaultFilters = (
  type: 'explore' | 'navbar',
  hasActiveCRM: boolean
): (FiltersCategories | undefined)[] => {
  // Avoid showing AI call scoring category to tenants that don't have AI assistant
  const tenantQuery = useTenantQuery();
  const hideModjoDeals = useHideModjoDeals();
  const shouldShowAiCallScoringCategory = tenantQuery.data?.features.aiAssistant.enabled ?? false;

  if (type === 'navbar') {
    return [
      shouldShowAiCallScoringCategory ? ReviewsOptionType.AI_CALL_SCORING : undefined,
      RangeOptionType.REVIEW_RATING,
      SearchableObjectOptionType.CALL_TITLE,
      hideModjoDeals ? undefined : SearchableObjectOptionType.DEAL,
      RangeOptionType.CALL_DURATION,
      hasActiveCRM ? OptionTypeCategory.CRM : SearchableObjectOptionType.CONTACT,
      SearchableObjectOptionType.LANGUAGE,
      SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER,
      SearchableObjectOptionType.TAG,
      SearchableObjectOptionType.TEAM,
      SearchableObjectOptionType.TOPIC,
      SearchableObjectOptionType.USER,
      SearchableObjectOptionType.TRANSCRIPT,
    ];
  }
  return [
    shouldShowAiCallScoringCategory ? ReviewsOptionType.AI_CALL_SCORING : undefined,
    RangeOptionType.REVIEW_RATING,
    RangeOptionType.CALL_DURATION,
    hasActiveCRM ? undefined : SearchableObjectOptionType.CONTACT,
    SearchableObjectOptionType.LANGUAGE,
    SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER,
    SearchableObjectOptionType.TOPIC,
    SearchableObjectOptionType.TRANSCRIPT,
  ];
};

export const useMapOptionsFromCategory = (
  type: 'explore' | 'navbar',
  category?: FiltersCategories,
  crm?: CRMProvider | null
): (FiltersCategories | undefined)[] => {
  const defaultFilters = useMakeDefaultFilters(type, !!crm).filter(Boolean);
  if (!category) {
    return defaultFilters;
  }
  switch (category) {
    case OptionTypeCategory.CRM: {
      return crm ? makeCrmFiltersFromCrm(crm) : [];
    }
    case RangeOptionType.DEAL_CLOSE_DATE: {
      return DATE_FILTERS;
    }
    case RangeOptionType.DEAL_AMOUNT:
    case ReviewsOptionType.AI_CALL_SCORING:
    case RangeOptionType.REVIEW_RATING: {
      return NUMBER_FILTERS;
    }
    case RangeOptionType.CALL_DURATION: {
      return DURATION_FILTERS;
    }
    default: {
      return defaultFilters;
    }
  }
};

export const defaultItemToString = () => '';

export type OptionType = SearchableObjectOptionType | RangeOptionType | OtherInfoOptionType | ReviewsOptionType;

export const getOptionType = (o: SearchableObject): OptionType => {
  if (o instanceof OtherOption) {
    return (o.otherLabelType as OtherInfoOptionType) ?? o.type;
  }
  if (o.searchableObjectType) {
    return o.searchableObjectType;
  }
  if (typeof o?.id === 'string' && o?.id?.includes(DEAL_AMOUNT_ID)) {
    return RangeOptionType.DEAL_AMOUNT;
  }
  if (typeof o?.id === 'string' && o?.id?.includes(DEAL_CLOSE_DATE_ID)) {
    return RangeOptionType.DEAL_CLOSE_DATE;
  }
  if (typeof o?.id === 'string' && o?.id?.includes(REVIEW_RATING_ID)) {
    return RangeOptionType.REVIEW_RATING;
  }
  if (typeof o?.id === 'string' && o?.id?.includes(CALL_DURATION_ID)) {
    return RangeOptionType.CALL_DURATION;
  }
  if (
    typeof o?.id === 'string' &&
    (o?.id?.includes(AI_CALL_SCORING_ID) ||
      o?.id?.includes(AI_CALL_SCORING_RANGE_ID) ||
      o?.id?.includes(AI_CALL_SCORING_TEMPLATE_ID))
  ) {
    return ReviewsOptionType.AI_CALL_SCORING;
  }
  return SearchableObjectOptionType.CALL_TITLE;
};

export interface OptionItem {
  option: SearchableObject;
  index?: number;
  type?: OptionType;
}

export interface OptionCategory {
  title: string | null;
  items: OptionItem[];
}

const Chip = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (
  <span
    className="whitespace-nowrap rounded bg-grey-100 p-px text-xs font-semibold uppercase text-grey-600"
    {...props}
  />
);

export const getViewMoreOption = () => i18n.t('filters.search.viewMore');
const ICON_CLASSES = 'icon-small fill-grey-800 cursor-pointer';
const MIN_CALL_TO_DISPLAY_CHIP = 1;

export const computeResultIcon = (
  type?:
    | OptionTypeCategory
    | RangeCategory
    | SearchableObjectOptionType
    | OtherInfoOptionType
    | RangeOptionType
    | ReviewsOptionType,
  option?: SearchableObject
) => {
  let iconComponent: JSX.Element | null = null;
  if (!type && !option) {
    return iconComponent;
  }

  const optionType = type ?? (option && getOptionType(option));
  if (Object.values(RangeCategory).includes(optionType as RangeCategory)) {
    return null;
  }
  switch (optionType) {
    case SearchableObjectOptionType.ACCOUNT: {
      iconComponent = <AccountIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.CONTACT: {
      iconComponent = <ContactIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.DEAL: {
      iconComponent = <DollarIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.LEAD_STATUS: {
      iconComponent = <UserCircleLineIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.DEAL_STATUS: {
      iconComponent = <DealIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.DEAL_STAGE: {
      iconComponent = <FileAgreement className={ICON_CLASSES} />;
      break;
    }
    case OtherInfoOptionType.TRANSCRIPT: {
      iconComponent = <TranscriptIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.TAG: {
      iconComponent = <TagIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.TEAM: {
      iconComponent = <TeamIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.TOPIC: {
      iconComponent = <TopicIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.TRANSCRIPT: {
      iconComponent = <TranscriptIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.USER: {
      iconComponent = <UserIcon className={ICON_CLASSES} />;
      break;
    }
    case OtherInfoOptionType.CALL_TITLE: {
      iconComponent = <CallIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER: {
      iconComponent = <ProviderIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.LANGUAGE: {
      iconComponent = <LanguageIcon className={ICON_CLASSES} />;
      break;
    }
    case OptionTypeCategory.CRM: {
      iconComponent = <CRMIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.JOB_TITLE: {
      iconComponent = <JobTitleIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.LEAD_SOURCE: {
      iconComponent = <LeadSourceIcon className={ICON_CLASSES} />;
      break;
    }
    case SearchableObjectOptionType.DEAL_LOSS_REASON: {
      iconComponent = <CallIcon className={ICON_CLASSES} />;
      break;
    }
    case RangeOptionType.DEAL_CLOSE_DATE: {
      iconComponent = <DealCloseDateIcon className={ICON_CLASSES} />;
      break;
    }
    case RangeOptionType.DEAL_AMOUNT: {
      iconComponent = <DealAmountIcon className={ICON_CLASSES} />;
      break;
    }
    case RangeOptionType.REVIEW_RATING: {
      iconComponent = <PineappleIcon className={ICON_CLASSES} />;
      break;
    }
    case ReviewsOptionType.AI_CALL_SCORING: {
      iconComponent = <AiStarsIcon className={ICON_CLASSES} />;
      break;
    }
    case RangeOptionType.CALL_DURATION: {
      iconComponent = <StopWatchIcon className={ICON_CLASSES} />;
      break;
    }
    default: {
      iconComponent = <UserIcon className={ICON_CLASSES} />;
      break;
    }
  }
  return iconComponent;
};

const OneLineTruncatedContainer = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => <div className="truncate" {...props} />;

const ItemAdormentContainer = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => <div className="flex-center mr-1.5 text-base" {...props} />;

export const itemToJSX = (
  option: SearchableObject,
  params?: { inputValue?: string | null; showDate?: boolean; nbItems?: number; itemAdorment?: ReactNode | null }
) => {
  if (!option) {
    return '';
  }
  const { inputValue, showDate, nbItems, itemAdorment } = params ?? {};
  const searchKeywords = inputValue ?? '';
  if (
    option instanceof OtherOption &&
    ![
      SearchableObjectOptionType.ACCOUNT,
      SearchableObjectOptionType.CONTACT,
      SearchableObjectOptionType.DEAL,
      OtherInfoOptionType.DEAL_STATUS,
      OtherInfoOptionType.LEAD_STATUS,
      OtherInfoOptionType.DEAL_STAGE,
      OtherInfoOptionType.PHONE_PROVIDER,
      OtherInfoOptionType.LANGUAGE,
      OtherInfoOptionType.JOB_TITLE,
      OtherInfoOptionType.LEAD_SOURCE,
      OtherInfoOptionType.DEAL_LOSS_REASON,
    ].includes(option.type)
  ) {
    return (
      <span style={{ color: twThemeColors.grey[300] }}>
        <Trans i18nKey="filters.search.searchForIn" values={{ value: option.name, type: option.type }} />
      </span>
    );
  }
  if (option.name.includes(getViewMoreOption())) {
    return <span style={{ color: ColorPalette.darkGrey }}>{getViewMoreOption()}</span>;
  }
  const currentContent = insertBoldMarkers(option.name, [searchKeywords], BEGIN_BOLD_MARKER, END_BOLD_MARKER);
  const deletedUser = getOptionType(option) === SearchableObjectOptionType.USER && Boolean((option as User).deletedOn);
  const optionDate = new Date((option as CallTitleOption).date ?? '');
  const dateFormat =
    currentYear === optionDate.getFullYear()
      ? getDateFormatByLocale().fullDateWithTimeWithoutYear
      : getDateFormatByLocale().fullDateWithTime;
  return (
    <>
      {itemAdorment && <ItemAdormentContainer>{itemAdorment}</ItemAdormentContainer>}

      <div className="flex flex-grow flex-col overflow-hidden">
        <div className="flex items-center overflow-hidden">
          <OneLineTruncatedContainer>
            {boldMarkersToJSX(currentContent, 0, { whiteSpace: 'inherit' })}
            {(nbItems || deletedUser) && <span style={{ paddingLeft: '0.6em' }} />}
          </OneLineTruncatedContainer>
          {nbItems && nbItems > MIN_CALL_TO_DISPLAY_CHIP && (
            <Chip>{i18n.t('filters.search.plusCall', { numberOfCalls: nbItems })}</Chip>
          )}
          {deletedUser && (
            <em style={{ color: ColorPalette.darkGrey }} className="capitalize">
              {' '}
              ({i18n.t('deleted')})
            </em>
          )}
        </div>

        {getOptionType(option) === SearchableObjectOptionType.CALL_TITLE && showDate && (
          <div>
            <p className="whitespace-nowrap text-xs text-grey-700">{formatDate(optionDate, dateFormat)}</p>
          </div>
        )}
      </div>
    </>
  );
};

export const enterIcon = (display: boolean, white?: boolean) => (
  <EnterKeyIcon
    className={clsx(
      'ml-1 h-3 w-4 text-xs',
      display ? 'visible' : 'invisible',
      white ? 'fill-grey-white' : 'fill-grey-700'
    )}
  />
);

export const filterOptionsByCategory = (options: SearchableObject[], category: FiltersCategories) => {
  return options.filter((option) => getOptionType(option) === category);
};
