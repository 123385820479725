import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import { DealWarning, DealWarningSetupRecomputationStatus } from './deal-warnings.types';
import { queryKeys } from '../queryKeys';

export const DEAL_WARNING_BASE_URL = '/deal-warnings';

export const useDealWarningsQuery = <TSelectData = DealWarning[]>(
  options?: CustomQueryOptions<DealWarning[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.dealWarning.list(),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealWarning[]>(combineRoutes([DEAL_WARNING_BASE_URL]));
      return data;
    },
    ...options?.queryOptions,
  });
};

export const useDealWarningSetupRecomputationStatusQuery = <TSelectData = DealWarningSetupRecomputationStatus>({
  queryOptions,
}: CustomQueryOptions<DealWarningSetupRecomputationStatus, TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.dealWarning.warningSetupRecomputationStatus(),
    queryFn: async () => {
      const { data } = await apiService.API.post<DealWarningSetupRecomputationStatus>(
        combineRoutes([DEAL_WARNING_BASE_URL, 'recomputation-status'])
      );
      return data;
    },
    ...queryOptions,
  });
};
