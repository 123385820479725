import React from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { RoutePaths } from '@/enums';
import { ProductTourIds } from '@/enums/product-tour-ids';
import ChevronLeftIcon from '@/static/icons/arrows/chevrons-right-Line.svg?react';
import ModjoOnlyGreenLogo from '@/static/logo.svg?react';
import { Event } from '@/tracking/events';

import { sidebarStore } from './sidebar.store';
import { IconButtonGhost } from '../common/ui-components/buttons/UIButtonGhost';
import { Tooltip } from '../common/ui-components/data-display/tooltip';

const SidebarTopSection = () => {
  const { t } = useTranslation();
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();

  const handleToggleSidebar = () => {
    mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: 'Expand / Collapse button', newState: !isSidebarOpen });
    sidebarStore.toggleSidebar();
  };

  return (
    <div
      className={clsx(
        'flex shrink-0 transform items-center justify-between gap-2 p-3 transition-all',
        isSidebarOpen ? 'flex-row' : 'flex-col'
      )}
    >
      <NavLink
        to={RoutePaths.DEFAULT}
        className="ml-0.5 flex shrink-0 items-center self-start overflow-hidden rounded-full outline-offset-1 outline-green"
      >
        <ModjoOnlyGreenLogo className="size-9" />
      </NavLink>

      <Tooltip
        variant="small"
        content={isSidebarOpen ? t('collapse') : t('expand')}
        contentClassName="capitalize"
        placement="right"
      >
        <IconButtonGhost
          onClick={handleToggleSidebar}
          className="invisible focus:bg-grey group-hover/sidebar:visible"
          isTransparent
          icon={<ChevronLeftIcon className={clsx('size-3 transition-transform', isSidebarOpen && 'rotate-180')} />}
          data-product-tour-id={ProductTourIds.SIDEBAR_TOGGLE_BUTTON}
        />
      </Tooltip>
    </div>
  );
};

SidebarTopSection.displayName = 'SidebarTopSection';

export { SidebarTopSection };
