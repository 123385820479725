import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { UserRole } from '@/enums';
import LivenoteIcon from '@/static/icons/brands/livenotes-Line.svg?react';
import { DataTestId } from '@/types/data-test-id';
import { useModjoExtensionQuery, IS_CHROME_BASED_BROWSER } from '@/utils/modjo-extension-utils';
import { hasRole } from '@/utils/user-utils';

import { useExtensionMenuItems } from './extension-menu.utils';
import { SidebarNavigationMenu } from '../SidebarNavigationMenu/SidebarNavigationMenu';
import { SidebarNavigationMenuButton } from '../SidebarNavigationMenu/SidebarNavigationMenuButton';

const ExtensionMenu = () => {
  const { t } = useTranslation();
  const currentUserQuery = useCurrentUserQuery();
  const modjoExtensionQuery = useModjoExtensionQuery();
  const extensionMenuItems = useExtensionMenuItems();

  const menuItems = useMemo(() => {
    if (!currentUserQuery.isSuccess) {
      return [];
    }

    const items = [extensionMenuItems.MY_NOTES];
    if (hasRole(currentUserQuery.data.role, UserRole.MANAGER)) {
      items.push(extensionMenuItems.LIVENOTES_TEMPLATES);
    }
    if (currentUserQuery.data.hasLicense || hasRole(currentUserQuery.data.role, UserRole.MANAGER)) {
      if (modjoExtensionQuery.isSuccess && IS_CHROME_BASED_BROWSER) {
        items.push(extensionMenuItems.OPEN_LIVENOTES);
      } else {
        items.push(extensionMenuItems.DOWNLOAD_LIVENOTES);
      }
    } else if (currentUserQuery.data.role === UserRole.USER) {
      items.push(extensionMenuItems.DISCOVER_LIVENOTES);
    }
    return items;
  }, [currentUserQuery.data, currentUserQuery.isSuccess, extensionMenuItems, modjoExtensionQuery.isSuccess]);

  if (!currentUserQuery.isSuccess) {
    return null;
  }

  return (
    <SidebarNavigationMenu
      items={menuItems}
      Button={(open) => (
        <SidebarNavigationMenuButton
          label={t('navbar.modjoExtension.button')}
          Icon={LivenoteIcon}
          isMenuOpen={Boolean(open)}
          dataTestId={DataTestId.LIVENOTES_MENU_BUTTON_ID}
        />
      )}
      dataTestId={DataTestId.LIVENOTES_MENU_CONTAINER_ID}
    />
  );
};

ExtensionMenu.displayName = 'ExtensionMenu';

export { ExtensionMenu };
