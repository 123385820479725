import React, { PropsWithChildren, useRef } from 'react';

import { useClickAway, useKey, useUnmount } from 'react-use';
import { Key } from 'ts-key-enum';

import { ProductTourIdProps } from '@/types/common';
import { cn } from '@/utils/utils';

import { MaybeInAPortal } from '../MaybeInAPortal';

type RightPanelProps = PropsWithChildren<{
  header: JSX.Element;
  open: boolean;
  onClickAway?: (event?: Event) => void;
  width?: number;
  disabledOnClickAwayHTMLTags?: (keyof HTMLElementTagNameMap)[];
  withPortal?: boolean;
  dataTestId?: string;
  closeWithEsc?: boolean;
  className?: string;
}> &
  ProductTourIdProps;

const RightPanel = ({
  header,
  children,
  open,
  onClickAway,
  width,
  disabledOnClickAwayHTMLTags = ['button', 'input'],
  withPortal = true,
  closeWithEsc = true,
  dataTestId,
  className,
  productTourId,
}: RightPanelProps) => {
  useUnmount(() => {
    if (onClickAway && open) {
      onClickAway();
    }
  });

  const panelRef = useRef<HTMLDivElement | null>(null);
  useClickAway(panelRef, (event) => {
    const target = event.target as HTMLElement;
    const disableOnClickAway = disabledOnClickAwayHTMLTags.some((tag) => !!target.closest(tag));
    const isInsidePanel =
      panelRef.current && panelRef.current.getBoundingClientRect().left <= target.getBoundingClientRect().left;

    if (onClickAway && open && !isInsidePanel && !disableOnClickAway) {
      onClickAway(event);
    }
  });

  useKey(
    Key.Escape,
    (event) => {
      const target = event.target as HTMLElement;
      const isText = ['input', 'textarea'].some((tag) => !!target.closest(tag));
      if (!isText && closeWithEsc && onClickAway && open) {
        onClickAway(event);
      }
    },
    undefined,
    [onClickAway, closeWithEsc, open]
  );

  return (
    <MaybeInAPortal withPortal={withPortal}>
      <div
        data-test-id={dataTestId}
        className={cn(
          open ? 'translate-x-0 shadow-card-hover' : 'translate-x-full',
          'fixed inset-y-0 right-0 z-50 transform bg-grey-white transition-transform duration-300 ease-in-out',
          className
        )}
        ref={panelRef}
        style={{ width: width ?? '33.333333%' }}
        data-product-tour-id={productTourId}
      >
        <div className="flex h-full max-h-full flex-col">
          <div className="z-50 flex w-full items-center gap-2.5 bg-grey-50 p-3 ring-1 ring-grey-100">{header}</div>
          {children}
        </div>
      </div>
    </MaybeInAPortal>
  );
};

export { RightPanel };
