import {
  SearchableObjectOptionType,
  SearchableObject,
} from '@/components/common/search-components/search-components.types';
import { SelectOption } from '@/components/common/ui-components/types';
import { getProviderDisplayName } from '@/components/providers/icons/ProvidersIcon';
import { DEFAULT_TAG_COLOR, deserializeColor, Tag } from '@/entities/tags/tags.types';

export const toTagsProviderOptions = (tags: Tag[]) => {
  return tags
    .reduce<SelectOption[]>((acc, tag) => {
      if (tag.phoneProvider && !acc.some((selectOption) => selectOption.id === tag.phoneProvider)) {
        acc.push({
          id: tag.phoneProvider,
          label: getProviderDisplayName(tag.phoneProvider),
        });
      }
      return acc;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const toTagsOptions = (tags: Tag[]) =>
  tags
    .map((tag) => ({ id: tag.id.toString(), label: tag.name, color: tag.color }) as SelectOption)
    .sort((left, right) => left.label.localeCompare(right.label));

export const toTagsCount = (tags: Tag[]) => tags.length;

export const toTagsMap = (tags: Tag[]) => new Map(tags.map((tag) => [tag.id, tag]));

export const toSearchableTagObjects = (tags: Tag[]) => {
  if (tags) {
    return tags
      .reduce((result: SearchableObject[], tag: Tag) => {
        if (!tag.exclude) {
          const updatedTag: SearchableObject = {
            ...tag,
            color: deserializeColor(tag.color) ?? DEFAULT_TAG_COLOR,
            searchableObjectType: SearchableObjectOptionType.TAG,
          };
          result.push(updatedTag);
        }
        return result;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  return [];
};

export const toSortedTagByTeamIds = (
  tags: Tag[],
  teamIds: number[],
  options?: { addEmptyTag?: boolean; excludeExternalTags?: boolean }
) => {
  const excludeExternalTags = options?.excludeExternalTags ?? false;
  return tags
    .filter(
      (t) =>
        (!excludeExternalTags || (excludeExternalTags && !t.phoneProvider)) &&
        !t.exclude &&
        (teamIds.length === 0 ||
          !t.teamIds?.filter((t1) => t1).length ||
          t.teamIds?.filter((t2) => t2).some((t3) => teamIds.includes(t3)))
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const toTagColors = (tags: Tag[]) => {
  return tags.map((t) => t.color);
};

export const toSortedTagsByLength = (tags: Tag[], filter: (tags: Tag[]) => Tag[]) => {
  return filter(tags).sort((a, b) => a.name.length - b.name.length);
};
