import React, { useState } from 'react';

import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { useTranslation } from 'react-i18next';

import { useShortcutsContext } from '@/components/MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import { isEmpty } from '@/utils/utils';

import { enterIcon, OtherOption } from './Common';
import {
  InlineFlexWrapBox,
  ListMenuContainer,
  ListMenuItemContainer,
  SearchOptionsInput,
  SearchOptionsInputContainer,
} from './CommonStyles';
import { OtherInfoOptionType, SearchableObjectOptionType, SearchableObject } from './search-components.types';
import { Chip } from '../ui-components/data-display/chips';

type MultiChipsSelectContentProps = {
  selectedItems: SearchableObject[];
  setSelectedItems: React.Dispatch<React.SetStateAction<SearchableObject[]>>;
};

const MultiChipsSelectContent = ({ selectedItems, setSelectedItems }: MultiChipsSelectContentProps) => {
  const { t } = useTranslation();
  const [inputItems, setInputItems] = useState<OtherOption[]>([]);

  const handleAddSelectedItems = (values: SearchableObject[]) =>
    setSelectedItems((previous) => [...previous, ...values]);

  const handleRemoveSelectedItem = (value: SearchableObject) =>
    setSelectedItems((previous) => previous.filter((item) => value.id !== item.id));

  const { handleTriggers } = useShortcutsContext();
  const { getDropdownProps, getSelectedItemProps } = useMultipleSelection<OtherOption>({
    selectedItems: inputItems,
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem: {
          return {
            ...changes,
            activeIndex: undefined,
          };
        }
        default: {
          return changes;
        }
      }
    },
  });

  const {
    isOpen,
    closeMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    inputValue,
    getItemProps,
    selectItem,
    setHighlightedIndex,
  } = useCombobox({
    selectedItem: null,
    items: inputItems,
    onInputValueChange: ({ inputValue: inputChangedValue }) => {
      if (!inputChangedValue || inputChangedValue.length === 0) {
        setInputItems([]);
        closeMenu();
      } else {
        setInputItems([
          new OtherOption(
            selectedItems.length.toString(),
            inputChangedValue,
            OtherInfoOptionType.TRANSCRIPT,
            SearchableObjectOptionType.TRANSCRIPT
          ),
        ]);
        setHighlightedIndex(0);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur: {
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: '',
          };
        }
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            inputValue: '',
            isOpen: false,
          };
        }
        default: {
          return changes;
        }
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          if (selectedItem) {
            if (!selectedItems.some((item) => item.name === selectedItem.name)) {
              handleAddSelectedItems([selectedItem]);
            }
            selectItem({} as OtherOption);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
  });

  return (
    <>
      <SearchOptionsInputContainer onKeyDown={handleTriggers} {...getComboboxProps()}>
        <SearchOptionsInput
          autoFocus
          placeholder={t('filters.search.searchWordsInTranscript')}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          {...getDropdownProps(getInputProps())}
        />
      </SearchOptionsInputContainer>
      <ListMenuContainer {...getMenuProps()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <ListMenuItemContainer
              key={item.id}
              className={clsx(highlightedIndex === index && 'bg-grey-100')}
              {...getItemProps({
                item,
                index,
              })}
            >
              <InlineFlexWrapBox className="gap-x-1 text-xs">
                <p>{t('filters.search.addWord')}</p>
                <p className="font-semibold">{item.name}</p>
              </InlineFlexWrapBox>
              {enterIcon(highlightedIndex === index)}
            </ListMenuItemContainer>
          ))}
      </ListMenuContainer>
      {isEmpty(selectedItems) && isEmpty(inputValue) && (
        <span className="ml-4 text-sm">{t('filters.search.noWordsSelected')}</span>
      )}
      {!isEmpty(selectedItems) && (
        <div className={clsx('ml-4 flex flex-wrap items-center gap-1 text-sm font-normal', isOpen && 'mt-1.5')}>
          {selectedItems.map((item, index) => (
            <div
              className="inline-flex items-center gap-x-1"
              key={item.id}
              {...getSelectedItemProps({
                selectedItem: new OtherOption(item.id.toString(), item.name, OtherInfoOptionType.TRANSCRIPT),
                index,
              })}
            >
              <Chip key={item.id} label={item.name} onDelete={() => handleRemoveSelectedItem(item)} />
              {selectedItems.length !== index + 1 && <span>and</span>}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export { MultiChipsSelectContent };
