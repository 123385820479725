import React from 'react';

import mixpanel from 'mixpanel-browser';

import { Event, EventOrigin } from '@/tracking/events';
import { DataTestIdProps } from '@/types/common';

export const trackBreadcrumbClickEvent = (from: EventOrigin, to: EventOrigin) =>
  mixpanel.track(Event.CLICK_ON_BREADCRUMBS, {
    pageOrigin: from,
    destination: to,
  });

type BaseBreadcrumbProps = {
  levels: React.ReactNode[];
} & DataTestIdProps;

const BaseBreadcrumb = ({ levels, dataTestId }: BaseBreadcrumbProps) => (
  <div className="flex min-w-0 items-center gap-x-2" data-test-id={dataTestId}>
    {levels.map((level, index) => {
      if (!level) {
        return null;
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index > 0 && <span className="text-grey-600">/</span>}
          {level}
        </React.Fragment>
      );
    })}
  </div>
);

export { BaseBreadcrumb };
