export const LOCALSTORAGE_CLOSED_DOWN_INTEGRATION_SNACKBAR = 'ClosedDownIntegrationSnackbar';
export const LOCALSTORAGE_CHUNK_ERROR_LAST_RELOAD_DATE_KEY = 'ModjoChunkErrorLastReloadDate';
export const LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING = 'ModjoChunkErrorIsReloading';
export const LOCALSTORAGE_MEDIA_PLAYER_STORE_KEY = 'ModjoMediaPlayerStore';
export const LOCALSTORAGE_SIDEBAR_STORE_KEY = 'ModjoSidebarStore';
export const LOCALSTORAGE_PASSWORD_TOKEN_KEY = 'ModjoPasswordToken';
export const LOCALSTORAGE_ALERTS_TOKEN_KEY = 'ModjoAlertsToken';
export const LOCALSTORAGE_ANONYMOUS_VIEWED_KEY = 'ModjoAnonymousViewed';
export const LOCALSTORAGE_DEAL_LIST_COLUMN_SIZES = 'ModjoDealListColumnSizes';
export const LOCALSTORAGE_DEAL_WARNINGS_SETUP_KEY = 'ModjoDealWarningsSetup';
