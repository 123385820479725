import React from 'react';

import { DialogTitle } from '@headlessui/react';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'vaul';

import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { ButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { Event } from '@/tracking/events';

import { MeetingRecorderContentStates } from './meeting-recorder.constants';
import { useMeetingRecorder } from './meeting-recorder.hook';
import { meetingRecorderStore } from './meeting-recorder.store';
import { removeRecording } from './meeting-recorder.utils';

const MeetingRecorderCompletedContent = () => {
  const { t } = useTranslation();
  const tenantQuery = useTenantQuery();
  const isDeleting = meetingRecorderStore.useIsDeleting();
  const fileName = meetingRecorderStore.useCurrentFileName();
  const { reset } = useMeetingRecorder();

  const handleRequestDelete = () => {
    meetingRecorderStore.setIsDeleting(true);
  };

  const handleConfirmDelete = () => {
    if (fileName) {
      removeRecording(fileName);
    }
    reset();
    mixpanel.track(Event.DELETE_RECORDING);
  };

  const handleCancelDelete = () => {
    meetingRecorderStore.setIsDeleting(false);
  };

  const handleImportRecording = () => {
    meetingRecorderStore.setMeetingRecorderContentState(MeetingRecorderContentStates.IMPORT);
  };

  if (!tenantQuery.data) {
    return null;
  }

  const title = isDeleting ? t('meetingRecorder.recordingDelete.title') : t('meetingRecorder.recordingComplete.title');

  const description = isDeleting
    ? t('meetingRecorder.recordingDelete.description')
    : t('meetingRecorder.recordingComplete.description');

  const TitleComponent = isMobile ? Drawer.Title : DialogTitle;

  return (
    <div className={clsx('flex flex-1 flex-col items-center gap-y-4 rounded-t-2lg px-4', isMobile && 'py-4')}>
      <TitleComponent className="text-lg font-semibold">{title}</TitleComponent>
      <div className="flex flex-col items-center gap-y-6">
        <span className="px-6 text-center text-grey-800">{description}</span>
        <ButtonFilled
          color={isDeleting ? 'error' : 'primary'}
          onClick={() => {
            if (isDeleting) {
              handleConfirmDelete();
            } else {
              handleImportRecording();
            }
          }}
          fullWidth={!isDeleting}
        >
          {isDeleting
            ? t('meetingRecorder.recordingDelete.deleteLabel')
            : t('meetingRecorder.recordingComplete.uploadLabel')}
        </ButtonFilled>
        <ButtonGhost
          onClick={() => {
            if (isDeleting) {
              handleCancelDelete();
            } else {
              handleRequestDelete();
            }
          }}
          fullWidth={!isDeleting}
          className={clsx(!isDeleting && 'text-red hover:text-red-700')}
        >
          {isDeleting
            ? t('meetingRecorder.recordingDelete.cancelLabel')
            : t('meetingRecorder.recordingComplete.cancelLabel')}
        </ButtonGhost>
      </div>
    </div>
  );
};

export const MeetingRecorderCompleted = () => {
  if (isMobile) {
    return (
      <Drawer.NestedRoot open>
        <Drawer.Portal>
          <Drawer.Content className="fixed inset-x-0 bottom-0 flex flex-col rounded-t-2lg bg-grey-white shadow-list-item-hovered">
            <MeetingRecorderCompletedContent />
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.NestedRoot>
    );
  }
  return <MeetingRecorderCompletedContent />;
};
