import React, { useEffect } from 'react';

import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { IconButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import IconPausedFilled from '@/static/icons/audiovisuals/pause-Filled.svg?react';
import IconPlayFilled from '@/static/icons/audiovisuals/play-Filled.svg?react';
import LongArrowDown from '@/static/LongArrowDown.svg?react';
import { Event } from '@/tracking/events';
import { formatDuration } from '@/utils/date-utils';
import { cn } from '@/utils/utils';

import { MeetingRecorderStatuses, MeetingRecorderTranslationKeys } from './meeting-recorder.constants';
import { useMeetingRecorder } from './meeting-recorder.hook';
import { meetingRecorderStore } from './meeting-recorder.store';
import { MeetingRecorderCompleted } from './MeetingRecorderCompleted';

export const MeetingRecorderContent = () => {
  const { t } = useTranslation();
  const status = meetingRecorderStore.useRecordingStatus();
  const statusLabel = MeetingRecorderTranslationKeys[status];
  const { startRecording, pauseRecording, resumeRecording, stopRecording, elapsedTime, canvasRef, hasReachedTimeout } =
    useMeetingRecorder();
  const fileDuration = meetingRecorderStore.useFileDuration();
  const formattedElapsedTime = formatDuration(fileDuration ?? elapsedTime);

  useEffect(() => {
    if (hasReachedTimeout && status === MeetingRecorderStatuses.RECORDING) {
      pauseRecording();
      mixpanel.track(Event.REACHED_MAX_RECORDING_DURATION);
    }

    if (hasReachedTimeout && status === MeetingRecorderStatuses.PAUSED) {
      // eslint-disable-next-line no-alert
      const userResponse = window.confirm(t('meetingRecorder.recordingLimitMessage'));

      if (userResponse) {
        stopRecording();
        mixpanel.track(Event.STOP_RECORDING_AFTER_MAX_DURATION);
      } else {
        resumeRecording();
        mixpanel.track(Event.CONTINUE_RECORDING_AFTER_MAX_DURATION);
      }
    }
  }, [hasReachedTimeout, pauseRecording, resumeRecording, status, stopRecording, t]);

  return (
    <div className="flex size-full min-h-96 flex-col items-center justify-between gap-y-4 pt-4">
      <span className="rounded-xl bg-grey-100 px-3 py-1 font-semibold">{t(statusLabel)}</span>
      {status !== MeetingRecorderStatuses.READY && <span className="text-grey-800">{formattedElapsedTime}</span>}
      {status !== MeetingRecorderStatuses.COMPLETED && <canvas ref={canvasRef} width={640} height={50} />}
      <div className="flex w-full flex-col items-center gap-y-6 px-4">
        {status === MeetingRecorderStatuses.COMPLETED ? (
          <MeetingRecorderCompleted />
        ) : (
          <>
            {status === MeetingRecorderStatuses.READY && (
              <>
                <span className="text-lg font-semibold">{t('meetingRecorder.startRecording')}</span>
                <span>
                  {isMobile ? t('meetingRecorder.pressCircleMobile') : t('meetingRecorder.pressCircleDesktop')}
                </span>
                <LongArrowDown />
              </>
            )}
            <div className="relative flex w-full items-center justify-center">
              <div className="absolute inset-y-0 left-24 flex items-center justify-center">
                {status === MeetingRecorderStatuses.RECORDING ? (
                  <IconButtonGhost onClick={pauseRecording} icon={<IconPausedFilled />} />
                ) : (
                  status === MeetingRecorderStatuses.PAUSED && (
                    <IconButtonGhost onClick={resumeRecording} icon={<IconPlayFilled />} />
                  )
                )}
              </div>
              {status === MeetingRecorderStatuses.READY || status === MeetingRecorderStatuses.PROCESSING ? (
                <button
                  type="button"
                  className={cn(
                    'flex size-12 items-center justify-center rounded-full border-2 border-grey-100 bg-red outline-2 outline-grey-white',
                    status === MeetingRecorderStatuses.PROCESSING && 'cursor-not-allowed bg-grey-400'
                  )}
                  onClick={startRecording}
                />
              ) : (
                <button
                  type="button"
                  className="relative flex size-12 items-center justify-center rounded-full border-2 border-grey-100 bg-transparent"
                  onClick={stopRecording}
                >
                  <span className="inset-0 m-auto size-7 rounded-lg bg-red" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
