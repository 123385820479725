import { Notification } from '@/entities/notifications/notifications.types';

import {
  isCallReviewNotification,
  isCommentNotification,
  isLibraryItemNotification,
  isNoteNotification,
  isReactionNotification,
  ReactionNotificationType,
} from '../notifications-enums';

export type UserNotificationData = {
  name: string;
  src: string | null;
};

export const serializeUserNotification = (notification: Notification): UserNotificationData | null => {
  if (isReactionNotification(notification)) {
    return notification.type === ReactionNotificationType.COMMENT_REACTION
      ? {
          name: notification.comment.reactingUserName,
          src: notification.comment.reactingUserProfilePictureURL,
        }
      : notification.type === ReactionNotificationType.NOTE_REACTION
        ? {
            name: notification.note.reactingUserName,
            src: null,
          }
        : null;
  }

  if (isCommentNotification(notification)) {
    return {
      name: notification.comment.writerName,
      src: notification.comment.writerProfilePictureURL,
    };
  }

  if (isNoteNotification(notification)) {
    return {
      name: notification.note.writerName,
      src: null,
    };
  }

  if (isCallReviewNotification(notification)) {
    return {
      name: notification.review.reviewerName,
      src: notification.review.reviewerProfilePictureURL,
    };
  }

  if (isLibraryItemNotification(notification)) {
    return {
      name: notification.libraryItem.writerName,
      src: notification.libraryItem.writerProfilePictureURL,
    };
  }

  return null;
};
