import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AI_CALL_SCORING_MAX_RATING,
  AI_CALL_SCORING_TEMPLATE_ID,
  AiCallScoringOption,
  FiltersCategories,
  getRangeCategoryId,
  isAiCallScoringRangeOption,
  isAiCallScoringTemplateOption,
  RangeNumberOption,
} from '@/components/common/search-components/Common';
import { RangeNumber } from '@/components/common/search-components/RangeNumber';
import {
  RangeCategory,
  RangeOptionType,
  ReviewsOptionType,
  SearchableObject,
} from '@/components/common/search-components/search-components.types';
import { MultipleSelect } from '@/components/common/ui-components/select/MultipleSelect';
import { SelectOption } from '@/components/common/ui-components/types';
import { getRangeType } from '@/components/explore/helpers';
import { useAiScoringTemplateOptions } from '@/entities/ai-scoring-templates/aiScoringTemplates.hooks';
import { i18n } from '@/translation/i18n';

export const getAllTemplatesOption: () => SelectOption = () => {
  return {
    id: '1',
    label: i18n.t('allOptionLabel'),
  };
};

type AiCallScoringSearchProps = {
  category: FiltersCategories;
  selectedItems: SearchableObject[];
  setSelectedItems: React.Dispatch<React.SetStateAction<SearchableObject[]>>;
};

const mapToAiCallScoringOption = (values: SelectOption[]) => {
  return values.map<AiCallScoringOption>(({ id, label }) => ({
    id: `${AI_CALL_SCORING_TEMPLATE_ID}-${id}`,
    templateUuid: id,
    name: label,
    type: ReviewsOptionType.AI_CALL_SCORING,
  }));
};

export const AiCallScoringSearch = ({ category, setSelectedItems, selectedItems }: AiCallScoringSearchProps) => {
  const { t } = useTranslation('explore');
  const allTemplatesOption = getAllTemplatesOption();
  const aiScoringTemplateOptions = useAiScoringTemplateOptions();
  const currentTemplateUuidsFilters = selectedItems.reduce<string[]>((acc, option) => {
    if (isAiCallScoringTemplateOption(option)) {
      acc.push(option.templateUuid);
    }
    return acc;
  }, []);
  const currentRangeOptionFilter = selectedItems.find((option) => isAiCallScoringRangeOption(option));

  const currentTemplateOptions =
    currentTemplateUuidsFilters.length > 0
      ? aiScoringTemplateOptions.filter(({ id }) => currentTemplateUuidsFilters.includes(id))
      : [allTemplatesOption];
  const [selectedTemplates, setSelectedTemplates] = useState<SelectOption[]>(currentTemplateOptions);
  const [selectedRangeOption, setSelectedRangeOption] = useState<RangeNumberOption | undefined>(
    currentRangeOptionFilter as RangeNumberOption
  );

  const aiScoringRangeFilter = useMemo(() => {
    const aiScoringRangeOption = selectedItems.find((item) => isAiCallScoringRangeOption(item)) as
      | RangeNumberOption
      | undefined;
    return aiScoringRangeOption
      ? getRangeType('number', aiScoringRangeOption?.min, aiScoringRangeOption?.max).rangeCategory
      : RangeCategory.GREATER;
  }, [selectedItems]);

  const handleTemplatesSelection = (selectedValues: SelectOption[]) => {
    const filteredTemplates: SelectOption[] = [];
    if (
      // When option allTemplatesOption is selected manually
      (selectedValues.some(({ id }) => id === allTemplatesOption.id) &&
        !selectedTemplates.some(({ id }) => allTemplatesOption.id === id)) ||
      // Or when all other options are selected
      (!selectedValues.some(({ id }) => id === allTemplatesOption.id) &&
        aiScoringTemplateOptions.length - 1 === selectedValues.length) ||
      // Or all options are unselected
      selectedValues.length === 0
    ) {
      // Then select all templates except allTemplatesOption
      setSelectedTemplates([allTemplatesOption]);
    } else {
      filteredTemplates.push(
        ...aiScoringTemplateOptions.filter(
          (option) => selectedValues.some(({ id }) => id === option.id) && option.id !== allTemplatesOption.id
        )
      );
      setSelectedTemplates(filteredTemplates);
    }

    return filteredTemplates;
  };

  const handleRangeSelection = (selectedValues: RangeNumberOption[]) => {
    const rangeSelectedValue = selectedValues.find((value) => isAiCallScoringRangeOption(value));
    if (
      rangeSelectedValue &&
      typeof rangeSelectedValue?.max !== 'string' &&
      typeof rangeSelectedValue?.min !== 'string'
    ) {
      setSelectedRangeOption(rangeSelectedValue);
      return rangeSelectedValue;
    }
    setSelectedRangeOption(undefined);
    return undefined;
  };

  const formatSelectedItemsToSearch = (templatesToSearch?: SelectOption[], rangeToSearch?: RangeNumberOption) => {
    const withoutAllTemplatesOption = (templatesToSearch ?? selectedTemplates).filter(
      (template) => template.id !== allTemplatesOption.id
    );
    const rangeOptions = [
      ...(rangeToSearch ? [rangeToSearch] : []),
      ...(templatesToSearch && currentRangeOptionFilter ? [currentRangeOptionFilter] : []),
    ];

    setSelectedItems([...rangeOptions, ...mapToAiCallScoringOption(withoutAllTemplatesOption)].filter(Boolean));
  };

  return (
    <>
      <div className="mx-4 mb-2 mt-0">
        <MultipleSelect
          withPortal={false}
          placeholder={t('filters.templates.placeholder')}
          options={aiScoringTemplateOptions}
          optionsSelected={selectedTemplates}
          setOptionsSelected={(values) => {
            const templatesToSearch = handleTemplatesSelection(values);
            formatSelectedItemsToSearch(templatesToSearch);
          }}
        />
      </div>

      <RangeNumber
        category={category}
        id={getRangeCategoryId(category as RangeOptionType)}
        selectedFilterType={aiScoringRangeFilter}
        selectedItems={selectedRangeOption?.id ? [selectedRangeOption] : []}
        setSelectedItems={(values) => {
          const rangeToSearch = handleRangeSelection(values as RangeNumberOption[]);
          formatSelectedItemsToSearch(undefined, rangeToSearch);
        }}
        step={10}
        maxValue={AI_CALL_SCORING_MAX_RATING}
        placeholder={t('filters.templates.range.placeholder')}
      />
    </>
  );
};
