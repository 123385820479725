import { ObjectValues } from './common';

export const Providers = {
  AIRCALL: 'aircall',
  ALCATEL: 'alcatel',
  DEMODESK: 'demodesk',
  ONOFF: 'onoff',
  ZOOM: 'zoom',
  ZOOM_PHONE: 'zoom_phone',
  KEYYO: 'keyyo',
  TWILIO: 'twilio',
  MICROSOFT_TENANT_FLOW: 'microsoft',
  OUTREACH: 'outreach',
  HUBSPOT: 'hubspot',
  SALESFORCE: 'salesforce',
  SLACK: 'slack',
  ZOHO: 'zoho',
  ZENDESK: 'zendesk',
  RING_CENTRAL: 'ring_central',
  SELLSY: 'sellsy',
  MICROSOFT_USER_FLOW: 'microsoft_user_flow',
  PIPEDRIVE: 'pipedrive',
  AMAZON_SERVICE_CLOUD_VOICE: 'amazon-service-cloud-voice',
  APIZEE: 'apizee',
  CLOUDTALK: 'cloudtalk',
  FRESHCALLER: 'freshcaller',
  RINGOVER: 'ringover',
  SALESLOFT: 'salesloft',
  DIABOLOCOM: 'diabolocom',
  DIABOLOCOM_V2: 'diabolocom-v2',
  MANUAL: 'manual',
  GOOGLE: 'google',
  GOOGLE_USER_FLOW: 'google_user_flow',
  TALKDESK: 'talkdesk',
  FUZE: 'fuze',
  VOCALCOM: 'vocalcom',
  GOOGLE_MEET: 'google_meet',
  OUTLOOK: 'outlook',
  GOOGLE_CALENDAR: 'google_calendar',
  GOOGLE_RECORDING_BOT: 'google_recording_bot',
  MICROSOFT_RECORDING_BOT: 'microsoft_recording_bot',
  DYNAMICS: 'microsoft_dynamics',
  DIXA: 'dixa',
  OUTLOOK_EMAIL: 'outlook_email',
  GMAIL: 'gmail',
  MODJO_PHYSICAL_RECORDER: 'modjo_physical_recorder',
} as const;

export type Provider = ObjectValues<typeof Providers>;
export const isProvider = (provider: unknown): provider is Provider =>
  Object.values(Providers).includes(provider as Provider);

/**
 * Providers using recall recording bot
 */
const RecordingBotProviders = [Providers.GOOGLE_RECORDING_BOT, Providers.MICROSOFT_RECORDING_BOT];
export type RecordingBotProvider = (typeof RecordingBotProviders)[number];
export const isRecordingBotProvider = (provider: Provider): provider is RecordingBotProvider =>
  RecordingBotProviders.includes(provider as RecordingBotProvider);

/**
 * CRM Providers
 */
export const CRMProviders = [
  Providers.HUBSPOT,
  Providers.PIPEDRIVE,
  Providers.SALESFORCE,
  Providers.ZOHO,
  Providers.ZENDESK,
  Providers.SELLSY,
  Providers.DYNAMICS,
];
export type CRMProvider = (typeof CRMProviders)[number];
export const isCRMProvider = (provider: Provider): provider is CRMProvider =>
  CRMProviders.includes(provider as CRMProvider);

/**
 * Phone Providers
 */
export const PhoneProviders = [
  Providers.KEYYO,
  Providers.TWILIO,
  Providers.DIABOLOCOM,
  Providers.DIABOLOCOM_V2,
  Providers.FUZE,
  Providers.RINGOVER,
  Providers.ZOOM_PHONE,
  Providers.SALESLOFT,
  Providers.TALKDESK,
  Providers.OUTREACH,
  Providers.FRESHCALLER,
  Providers.AIRCALL,
  Providers.RING_CENTRAL,
  Providers.CLOUDTALK,
  Providers.AMAZON_SERVICE_CLOUD_VOICE,
  Providers.VOCALCOM,
  Providers.HUBSPOT,
  Providers.DIXA,
  Providers.MANUAL,
];
export type PhoneProvider = (typeof PhoneProviders)[number];

/**
 * Video Providers
 */
export const VideoProviders = [
  Providers.GOOGLE,
  Providers.GOOGLE_USER_FLOW,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.MICROSOFT_USER_FLOW,
  Providers.ZOOM,
  Providers.APIZEE,
  Providers.MANUAL,
  ...RecordingBotProviders,
] as const;
export type VideoProvider = (typeof VideoProviders)[number];

/**
 * Manual or External Recording Providers
 */
export const ManualProviders = [
  Providers.MANUAL,
  Providers.DEMODESK,
  Providers.ALCATEL,
  Providers.ONOFF,
  Providers.MODJO_PHYSICAL_RECORDER,
] as const;
export type ManualPhoneProvider = (typeof ManualProviders)[number];

const NotExternalPhoneProviders = [
  Providers.GOOGLE_RECORDING_BOT,
  Providers.GOOGLE_USER_FLOW,
  Providers.MICROSOFT_RECORDING_BOT,
  Providers.MICROSOFT_USER_FLOW,
] as const;
export const notExternalPhoneProvidersSet = new Set<string>(NotExternalPhoneProviders);

export type ExternalPhoneProvider =
  | Exclude<PhoneProvider, (typeof NotExternalPhoneProviders)[keyof typeof NotExternalPhoneProviders]>
  | ManualPhoneProvider;

/**
 * Call Recording Providers
 */
const CallRecordingProviders = [...new Set([...PhoneProviders, ...VideoProviders, ...ManualProviders])];
export type CallRecordingProvider = (typeof CallRecordingProviders)[number];
export const isCallRecordingProvider = (provider: Provider): provider is CallRecordingProvider =>
  CallRecordingProviders.includes(provider as CallRecordingProvider);

/**
 * Collaboration Providers
 */
export const CollaborationProviders = [Providers.SLACK];
export type CollaborationProvider = (typeof CollaborationProviders)[number];
const isCollaborationProvider = (provider: Provider): provider is CollaborationProvider =>
  CollaborationProviders.includes(provider as CollaborationProvider);

/**
 * Email Providers
 */
export const EmailProviders = [Providers.OUTLOOK_EMAIL, Providers.GMAIL];
export type EmailProvider = (typeof EmailProviders)[number];
export const isEmailProvider = (provider: Provider): provider is EmailProvider =>
  EmailProviders.includes(provider as EmailProvider);

/**
 * User OAuth Providers
 */
export const UserOAuthProviders = [
  Providers.MICROSOFT_USER_FLOW,
  Providers.GOOGLE,
  Providers.GOOGLE_USER_FLOW,
  Providers.GMAIL,
  Providers.OUTLOOK_EMAIL,
];
export type UserOAuthProvider = (typeof UserOAuthProviders)[number];
export const isUserOAuthProvider = (provider: Provider): provider is UserOAuthProvider =>
  UserOAuthProviders.includes(provider as UserOAuthProvider);

/**
 * Tenant OAuth Providers
 */
const TenantOAuthProviders = [
  Providers.ZOOM,
  Providers.ZOOM_PHONE,
  Providers.KEYYO,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.OUTREACH,
  Providers.HUBSPOT,
  Providers.SALESFORCE,
  Providers.SLACK,
  Providers.ZOHO,
  Providers.ZENDESK,
  Providers.RING_CENTRAL,
  Providers.SELLSY,
  Providers.DYNAMICS,
];
/**
 * OAuth Providers: Merge of UserOAuthProviders and TenantOAuthProviders
 */
const OAuthProviders = [...new Set([...TenantOAuthProviders, ...UserOAuthProviders])];
export type OAuthProvider = (typeof OAuthProviders)[number];
export const isOAuthProvider = (provider: Provider): provider is OAuthProvider =>
  OAuthProviders.includes(provider as OAuthProvider);

/**
 * Credentials Providers
 */
export const CredentialsProviders = [
  Providers.AMAZON_SERVICE_CLOUD_VOICE,
  Providers.APIZEE,
  Providers.TWILIO,
  Providers.CLOUDTALK,
  Providers.FRESHCALLER,
  Providers.RINGOVER,
  Providers.SALESLOFT,
  Providers.DIABOLOCOM,
  Providers.DIABOLOCOM_V2,
  Providers.SALESFORCE,
  Providers.PIPEDRIVE,
  Providers.GOOGLE,
  Providers.TALKDESK,
  Providers.FUZE,
  Providers.VOCALCOM,
  Providers.ZENDESK,
  Providers.MICROSOFT_USER_FLOW,
  Providers.ZOHO,
  Providers.AIRCALL,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.DYNAMICS,
  Providers.DIXA,
];
type CredentialsProvider = (typeof CredentialsProviders)[number];
export const isCredentialsProvider = (provider: Provider): provider is CredentialsProvider =>
  CredentialsProviders.includes(provider as CredentialsProvider);

/**
 * User providers
 */
const UserProviders = [
  Providers.AIRCALL,
  Providers.APIZEE,
  Providers.CLOUDTALK,
  Providers.FRESHCALLER,
  Providers.SLACK,
  Providers.DIABOLOCOM_V2,
  Providers.KEYYO,
  Providers.RINGOVER,
  Providers.SALESLOFT,
  Providers.ZOOM,
  Providers.ZOOM_PHONE,
  Providers.SALESFORCE,
  Providers.PIPEDRIVE,
  Providers.HUBSPOT,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.ZOHO,
  Providers.TALKDESK,
  Providers.TWILIO,
  Providers.FUZE,
  Providers.OUTREACH,
  Providers.GOOGLE,
  Providers.GOOGLE_USER_FLOW,
  Providers.ZENDESK,
  Providers.RING_CENTRAL,
  Providers.SELLSY,
  Providers.MICROSOFT_USER_FLOW,
  Providers.AMAZON_SERVICE_CLOUD_VOICE,
  Providers.DIXA,
  Providers.DYNAMICS,
];
export type UserProvider = (typeof UserProviders)[number];
export const isUserProvider = (provider: Provider): provider is UserProvider =>
  UserProviders.includes(provider as UserProvider);

/**
 * Calendar Providers
 */
const CalendarProviders = [
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.MICROSOFT_USER_FLOW,
  Providers.GOOGLE,
  Providers.GOOGLE_USER_FLOW,
];
export type CalendarProvider = (typeof CalendarProviders)[number];
export const isCalendarProvider = (provider: Provider): provider is CalendarProvider =>
  CalendarProviders.includes(provider as CalendarProvider);

export const sortProviders = (leftProvider: Provider, rightProvider: Provider): number => {
  if (isCRMProvider(leftProvider)) {
    return -1;
  }
  if (isCRMProvider(rightProvider)) {
    return 1;
  }
  if (isCollaborationProvider(leftProvider)) {
    return 1;
  }
  if (isCollaborationProvider(rightProvider)) {
    return -1;
  }
  return leftProvider.localeCompare(rightProvider);
};
