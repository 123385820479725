import {
  SearchableObjectOptionType,
  SearchableObject,
} from '@/components/common/search-components/search-components.types';
import { SelectOption } from '@/components/common/ui-components/types';
import { Topic, TopicWithTeamNames } from '@/entities/topics/topics.types';
import { defaultTopicOrTagColor } from '@/theme/colors';
import { getShortLanguageLabel } from '@/utils/language';

import { Team } from '../teams/teams.types';

export const toSortedActiveSearchableTopicObjects = (topics: Topic[]) =>
  topics
    .reduce<SearchableObject[]>((acc, topic) => {
      if (topic.isActive) {
        acc.push({ ...topic, searchableObjectType: SearchableObjectOptionType.TOPIC });
      }
      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

export const toSortedActiveSearchableTopicsForGlobalSearch = (topics: Topic[], teamIds?: number[]) =>
  topics
    .reduce<SearchableObject[]>((acc, topic) => {
      if (
        topic.isActive &&
        (teamIds === undefined || topic.teamIds.length <= 0 || topic.teamIds.includes(teamIds[0]))
      ) {
        acc.push({ ...topic, searchableObjectType: SearchableObjectOptionType.TOPIC });
      }
      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

export const findTopicById = (topics: Topic[], topicId: number) => {
  return topics.find((topic) => topic.id === topicId);
};

export const toTopicsMap = (topics: Topic[]) => {
  return new Map(topics.map((topic) => [topic.id, topic]));
};

export const toTopicsCount = (topics: Topic[]) => {
  return topics.length;
};

export const toFormattedTopicsWithTeams = (topics: Topic[], teams: Team[]) =>
  topics
    .map<TopicWithTeamNames>((topic) => ({
      ...topic,
      color: topic.color ?? defaultTopicOrTagColor,
      teamNames: topic.teamIds.reduce<string[]>((teamAcc, teamId) => {
        const teamName = teams.find((team) => teamId === team.id)?.name;
        if (teamName) {
          teamAcc.push(teamName);
        }
        return teamAcc;
      }, []),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

export const toTopicsLanguageOptions = (topics: Topic[]): SelectOption[] => {
  const languages = [...new Set(topics.flatMap((t) => Object.keys(t.keywordsMap)))];
  return languages
    .map((language) => ({
      id: language,
      label: getShortLanguageLabel(language),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
