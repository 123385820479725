import React from 'react';

import { useCurrentUser } from '@/entities/users/users.hooks';
import { ColorPalette } from '@/theme/colors';
import { capitalizeFirstLetter } from '@/utils/utils';

const FarmerBanner = () => {
  const user = useCurrentUser();

  return (
    <div
      className="border-b-2 border-dashed border-b-grey-white px-3 py-1 text-xs font-semibold text-grey-white"
      style={{ backgroundColor: ColorPalette.warning }}
    >
      {capitalizeFirstLetter(user?.tenantName ?? '')}
    </div>
  );
};

export { FarmerBanner };
