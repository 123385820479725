import React, { useContext } from 'react';

import { cn } from '@/utils/utils';

import { MobileNavbarButtonContext } from './MobileNavbarButtonContext';

type MobileNavbarButtonIconProps = {
  lineIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  filledIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
};

export const MobileNavbarButtonIcon = ({ lineIcon, filledIcon, className }: MobileNavbarButtonIconProps) => {
  const { isActive: isActivePage } = useContext(MobileNavbarButtonContext);

  const Icon = isActivePage && !!filledIcon ? filledIcon : lineIcon;

  return <Icon className={cn('size-6 shrink-0 fill-grey-900', className)} />;
};
