/* eslint-disable i18next/no-literal-string */
import { Buffer } from 'buffer';

import { AxiosError, AxiosResponse } from 'axios';
import clsx, { ClassValue } from 'clsx';
import { intervalToDuration } from 'date-fns';
import isMap from 'lodash/isMap';
import { createTailwindMerge, getDefaultConfig, mergeConfigs } from 'tailwind-merge';

import { AlertVariant } from '@/components/common/toast/alert';
import { createAlert, createCustomToast } from '@/components/common/toast/ToastHelpers';
import { TranscriptionBlock } from '@/entities/transcription-blocks/transcription-blocks.types';
import { Environment, envOrder } from '@/enums/constants';
import { HttpStatusCode } from '@/enums/http-status-code';
import { NoteTakingAppOs } from '@/enums/note-taking-app-os';
import { AxiosErrorWithMessage } from '@/types/axios';
import { PaginationRequestResult } from '@/types/paginations';

import { MINUTES_PER_HOUR, SECONDS_PER_HOUR } from './date-utils';

const twMerge = createTailwindMerge(() =>
  mergeConfigs(getDefaultConfig(), {
    override: {
      classGroups: {
        shadow: [{ shadow: ['input', 'list-item', 'list-item-hovered', 'card'] }],
      },
    },
  })
);

/*
 * We are comparing the current environment with a threshold environment.
 * E.g. if the current environment is DEV and the threshold environment is STAGING, we'll return true for DEV and STAGING
 */
export const isEnvironmentBelowThreshold = (currentEnv: string, thresholdEnv: Environment) => {
  const currentEnvOrder = envOrder[currentEnv as Environment];
  const thresholdEnvOrder = envOrder[thresholdEnv];

  if (currentEnvOrder === undefined || thresholdEnvOrder === undefined) {
    return false;
  }

  return currentEnvOrder <= thresholdEnvOrder;
};

export const getOS = () => {
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  if (macosPlatforms.includes(platform)) {
    return NoteTakingAppOs.MAC;
  }
  if (windowsPlatforms.includes(platform)) {
    return NoteTakingAppOs.WINDOWS;
  }
  return '';
};

export const isAxiosError = (error: AxiosError | AxiosErrorWithMessage | Error): error is AxiosError =>
  (error as AxiosError).isAxiosError !== undefined;

const zeroPad = (num: number) => String(num).padStart(2, '0');

export const formatDuration = (n: number, unit: 'seconds' | 'ms') => {
  const duration = intervalToDuration({ start: 0, end: n * (unit === 'seconds' ? 1000 : 1) });
  if (!duration) {
    return '00:00';
  }
  if (duration.hours && duration.hours > 0) {
    return `${zeroPad(duration.hours)}h${zeroPad(duration.minutes ?? 0)}`;
  }
  return `${zeroPad(duration.minutes ?? 0)}:${zeroPad(duration.seconds ?? 0)}`;
};

export const formatSecondsToMinutes = (seconds: number): string => {
  // Calculate the number of whole hours
  const hours = Math.floor(seconds / SECONDS_PER_HOUR);
  // Calculate the number of minutes left after accounting for the hours
  const minutes = Math.floor((seconds % SECONDS_PER_HOUR) / MINUTES_PER_HOUR);

  // Structure the formatted string based on the hours and minutes calculated
  if (hours > 0 && minutes > 0) {
    return `${hours}h${zeroPad(minutes)}`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  if (minutes > 0) {
    return `${minutes} min`;
  }
  return '0 min';
};

export function parseDuration(time: string): number {
  const parts = time.split(':').map((part) => Number.parseInt(part, 10));

  if (parts.length === 2) {
    // minutes:seconds format
    const [minutes, seconds] = parts;
    return minutes * 60 + seconds;
  }
  if (parts.length === 3) {
    // hours:minutes:seconds format
    const [hours, minutes, seconds] = parts;
    return hours * 3600 + minutes * 60 + seconds;
  }

  return 0;
}

/**
 * Merges classes that can be conditional thanks to clsx and resolves class conflicts thanks to twMerge.
 *
 * @param {ClassValue[]} classes Class name values. Can be conditional.
 * @returns {string} Class names without conflict.
 */
export function cn(...classes: ClassValue[]): string {
  return twMerge(clsx(classes));
}

/**
 * Find next transcription block depending on Media player time within speaker transcription blocks.
 *
 * @param blocks ITranscript[]. Array of Transcripts of one speaker.
 * @param time Number. Current time playing.
 * @returns Object of nextBlock if defined and isLastBlock.
 */
export function nextTranscriptionBlockBySpeaker(
  blocks: TranscriptionBlock[],
  time: number
): {
  isLastBlock?: boolean;
  nextBlock?: number[];
} {
  if (!blocks || !time) {
    return {};
  }

  const blocksTimestamps = blocks.map((block) => [block.startTime, block.endTime]);
  const currentBlock = blocksTimestamps.find((block) => time >= block[0] && time <= block[1]);

  if (!currentBlock) {
    const nextBlock = blocksTimestamps.find((block) => block[0] >= time);
    if (nextBlock) {
      return { isLastBlock: false, nextBlock };
    }
    return { isLastBlock: true };
  }
  return {};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (string_: any): string_ is string => {
  if (!string_) {
    return false;
  }
  return Object.prototype.toString.call(string_) === '[object String]';
};

export const encodeSoundUrl = (soundUrl: string) => {
  const parts = soundUrl.split('/');
  const lastPart = parts.at(-1) || false;
  const encodedLastPart = encodeURIComponent(lastPart);
  return [...parts.slice(0, -1).join('/'), '/', ...encodedLastPart].join('');
};

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (object: any) => {
  if (object === undefined || object === null) {
    return true;
  }
  if (Array.isArray(object)) {
    return object.length === 0;
  }
  if (typeof object === 'string') {
    return object.length === 0;
  }
  if (isMap(object)) {
    return object.size === 0;
  }
  if (typeof object === 'object' && !(object instanceof Date)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.keys(object).length === 0;
  }
  return false;
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmpty = (object: any) =>
  JSON.parse(JSON.stringify(object), (_, value) => {
    if (isEmpty(value)) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return value;
  });
/* eslint-enable @typescript-eslint/no-unsafe-return */

export const cutStringWithSuffix = (string_: string, maxLength: number, suffix = ' ...') => {
  if (!string_) {
    return '';
  }
  return string_.length > maxLength ? string_.slice(0, Math.max(0, maxLength - suffix.length)) + suffix : string_;
};

/**
 * This function ask the navigator to start download a file named, from a URL or from data given to it.
 * https://stackoverflow.com/a/53230807/10440469
 *
 * @param data optional parameter. Should be used if you want to pass Blob data that should be put into a file
 * @param fileName optional parameter. Should be used if you want to name the file that you will download.
 * Has to be set when parameter data is used.
 * @param fileUrl optional parameter. Should be used when the file to download is from a url. In this case, the fileName parameter
 * will not be used as the fileName will be overwritten by the actual file downloaded
 */
export const startDownload = ({ data, fileUrl, fileName }: { data?: Blob; fileUrl?: string; fileName?: string }) => {
  const url = fileUrl || window.URL.createObjectURL(data!);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'file-to-download');
  document.body.append(link);
  link.click();
};

export const getSingularOrPlural = (amount: number, singular: string, plural: string) =>
  Math.abs(amount) > 1 ? plural : singular;

/**
 * Remove the hashtag in an hex color string.
 *
 * @param {string} color Hex color.
 * @returns Hex color without hashtag
 */
export function removeHashtagInHexColor(color: string): string {
  return color.replace('#', '');
}

/**
 * Convert a number to a hex keepinmg only 2 letter: 12 -> 0C
 *
 * @param {number} n number to convert in hexa
 * @returns {string} return a hexa representation of the number
 */
export const numberToHex = (n: number) => n.toString(16).padStart(2, '0').toUpperCase();

/**
 * Adds alpha to hex color.
 *
 * @param {string} hexColor 6 characters color in hex format.
 * @param {number} alpha Percentage of alpha wanted.
 * @returns {string} color in hex representation with alpha
 */
export function hexColorWithAlpha(hexColor: string, alpha: number): string {
  if (!hexColor || !alpha) {
    return '';
  }
  const percent = Math.max(0, Math.min(100, alpha)); // bound percent from 0 to 100
  const hexValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255) in hex representation
  return `${hexColor}${numberToHex(hexValue)}`;
}

/**
 * darken a color represented as a number from 0 to 255
 *
 * @param {number} n number representing the color from 0 to 255
 * @param @param {number} percent Percentage to increase the black in the color
 * @returns {number} number representing the darken color
 */
export const darkenColorValue = (n: number, pct: number) =>
  Math.round(Math.max(0, Math.min(255, n - 256 * (pct / 100))));

/**
 * Take a hex color and darken it of a certain percent
 *
 * @param {string} hex 6 characters color in hex format.
 * @param {number} percent Percentage to darken the color
 * @returns {string} darken color in hex
 */
export function darkenHexColor(hex: string, percent: number): string {
  const hexWithoutHash = removeHashtagInHexColor(hex);
  // Parse the hexadecimal color into its red, green, and blue components
  const red = Number.parseInt(hexWithoutHash.slice(0, 2), 16);
  const green = Number.parseInt(hexWithoutHash.slice(2, 4), 16);
  const blue = Number.parseInt(hexWithoutHash.slice(4, 6), 16);

  // Decrease the value of each component by x% of its maximum value (256)
  const newRed = darkenColorValue(red, percent);
  const newGreen = darkenColorValue(green, percent);
  const newBlue = darkenColorValue(blue, percent);

  // Convert the new red, green, and blue values back into a hexadecimal color
  const newHexColor = `#${numberToHex(newRed)}${numberToHex(newGreen)}${numberToHex(newBlue)}`;
  return newHexColor;
}

export const formatTalkRatio = (rawRatio: number | null): string => rawRatio?.toFixed(0) ?? '0';

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

/**
 * Converts a value to a number.
 *
 * @param value - The value to convert.
 * @returns The converted number.
 */
export const toNumber = (value: string | number | boolean) => {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  return typeof value === 'string' ? +value : value;
};

/**
 * Extract all numbers from a string.
 *
 * @param text {string} the string to extract number from.
 * @returns {number | undefined} The number extracted. If there is no number in the string, the result will be undefined.
 */
export const extractNumber = (text: string): number | undefined => {
  const extractedNumbers = text.match(/\d+/);
  return extractedNumbers ? toNumber(extractedNumbers[0]) : undefined;
};

export const toFloat = (value: string | number): number =>
  typeof value === 'string' ? Number.parseFloat(value) : value;

export const toBoolean = (value?: string | boolean | null) =>
  typeof value === 'string' ? value === 'true' : Boolean(value);

/**
 * Decode a Base64 string and parse the object.
 *
 * @param {string} encodedInput String in Base64
 * @returns {T} Target object
 */
export function decodeBase64String<T>(encodedInput: string): T | null {
  let decoded: T | null;
  try {
    decoded = JSON.parse(Buffer.from(encodedInput, 'base64').toString('utf8')) as T;
  } catch {
    decoded = null;
  }
  return decoded;
}

/**
 * Returns an object without all nullish values.
 *
 * @param {Record<string, unknown>} object Object to process
 * @returns {Record<string, NonNullable<unknown>>} similar object without nullish value
 */
export const removeNullishValues = (object: Record<string, unknown>) =>
  Object.entries(object).reduce(
    (objectWithDefinedValues, [key, value]) => {
      if (value) {
        return { ...objectWithDefinedValues, [key]: value };
      }
      return objectWithDefinedValues;
    },
    {} as Record<string, NonNullable<unknown>>
  );

export const WHITESPACE = ' ';

function hashCode(text: string) {
  let hash = 0;
  for (let i = 0; i < text.length; i += 1) {
    // eslint-disable-next-line no-bitwise, unicorn/prefer-code-point
    hash = text.charCodeAt(i) ?? 0 + ((hash << 5) - hash);
  }
  return hash;
}

/**
 * Generate a random Hexadecimal color.
 *
 * @example
 * const color = getRandom();
 * console.log(color);
 * // #AE3F3A
 *
 * @returns {string}
 */
const colors = [
  '#e51c23',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#5677fc',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#259b24',
  '#8bc34a',
  '#afb42b',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const getRandomColor = (text?: string): string => {
  let color = '#';
  if (text) {
    let hash = hashCode(text);
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
  }
  const letters = '0123456789ABCDEF';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/** Returns the result of a percentage computation
 * @param {number} value The value to be divided
 * @param {number} percentage The percentage to apply on the value
 */
export const getPercentage = (value: number, percentage: number) => {
  if (percentage <= 0) {
    throw new Error('You should use a positive percentage!');
  }
  return value * (percentage / 100);
};

/**
 * Returns a random number.
 * @param {number} max The range from the value to be choosed
 * @returns {number}
 */
export const getRandomNumber = (max = 1000): number => {
  return Math.floor(Math.random() * max);
};

export function isAlphanumeric(text: string) {
  return /^[\dA-Za-z]+$/.test(text);
}

export function isNumeric(text: string) {
  return text !== '' && !Number.isNaN(toNumber(text));
}

export function isStringOrNumber(value: unknown): value is string | number {
  return typeof value === 'string' || typeof value === 'number';
}

export function createSubsetObject<T, K extends keyof T>(originalObject: T, keys: K[]): Pick<T, K> {
  const subsetObject = {} as Pick<T, K>;

  for (const key of keys) {
    subsetObject[key] = originalObject[key];
  }

  return subsetObject;
}

export const showResponseAlert = (response?: AxiosResponse, message?: string | string[]) => {
  if (response) {
    let alertVariant: AlertVariant | undefined;
    const { status } = response;
    switch (true) {
      case status >= HttpStatusCode.OK && status < HttpStatusCode.MULTIPLE_CHOICES: {
        alertVariant = AlertVariant.Success;
        break;
      }
      case status >= HttpStatusCode.MULTIPLE_CHOICES && status < HttpStatusCode.BAD_REQUEST: {
        alertVariant = AlertVariant.Info;
        break;
      }
      case status >= HttpStatusCode.BAD_REQUEST && status < HttpStatusCode.INTERNAL_SERVER_ERROR: {
        alertVariant = AlertVariant.Warning;
        break;
      }
      case status >= HttpStatusCode.INTERNAL_SERVER_ERROR && status < 600: {
        alertVariant = AlertVariant.Error;
        break;
      }
      default: {
        break;
      }
    }
    if (alertVariant) {
      const alert = createAlert(response, message, alertVariant);
      alert.message &&
        createCustomToast({
          message: alert.message,
          variant: alert.variant,
        });
    }
  }
};

const MODJO_NAME = 'Modjo';

export const isModjoTenant = (tenantName: string) => tenantName === MODJO_NAME;

/**
 * Compare properties of two objects to determine if they are equal.
 *
 * @template T - The type of the objects being compared.
 * @param {T} objectA - The first object to compare.
 * @param {Partial<T>} objectB - The second object to compare (partial object).
 * @returns {boolean} True if the properties in objectB are equal to their counterparts in objectA, or false if any differences are found.
 */
export function arePropertiesEqual<T>(objectA: T, objectB: Partial<T>): boolean {
  // If first object is undefined then we consider B as being part of A.
  if (!objectA) {
    return true;
  }
  for (const key in objectB) {
    if (objectA[key] !== objectB[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Checks if a numeric or string value is within a specified range.
 *
 * @param {number|string} value - The value to check.
 * @param {{ min: number, max: number }} options - An object with minimum and maximum values.
 * @param {number} options.min - The minimum value of the range.
 * @param {number} options.max - The maximum value of the range.
 * @returns {boolean} Returns `true` if the value is within the specified range, otherwise `false`.
 */
export function isInRange(value: number | string, { min, max }: { min?: number; max?: number }): boolean {
  const numericValue = Number.isNaN(toNumber(value)) ? value : toNumber(value);
  return (
    typeof numericValue === 'number' &&
    (min === undefined || numericValue >= min) &&
    (max === undefined || numericValue <= max)
  );
}

/**
 * Removes trailing zeros from a number or a string representation of a number.
 *
 * @param {number} value - The value to remove trailing zeros from.
 * @returns {number} - The value after removing trailing zeros. If the input is not a valid number, returns NaN.
 * @example
 * // Example 1:
 * const result1 = removeTrailingZeros(12.34000); // Result: 12.34
 *
 * // Example 2:
 * const result2 = removeTrailingZeros("5.000"); // Result: 5
 *
 * // Example 3:
 * const result3 = removeTrailingZeros("abc"); // Result: NaN
 */
export const removeTrailingZeros = (value: number): number => {
  if (typeof value !== 'number') {
    return Number.NaN;
  }
  const result = value.toFixed(2).replace(/(\.\d*?[1-9])0+$/, '$1');
  return Number.parseFloat(result);
};

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const random = Math.random().toString(36).slice(2, 7); // Generate a random base36 string

  return `${timestamp}-${random}`;
};

/**
 * Gets the next page parameter based on the last page and the limit.
 * @param lastPage The last page of the pagination request result.
 * @param limit The limit of items per page.
 * @returns The next page parameter.
 */
export function getNextPageParam<T>(lastPage: PaginationRequestResult<T> | undefined, limit: number) {
  return !!lastPage?.pagination?.nextPage && lastPage.values.length === limit
    ? lastPage.pagination.nextPage
    : undefined;
}

/**
 * Checks if a string value is a valid UUID.
 *
 * @param value - The string value to check.
 * @returns `true` if the value is a valid UUID, `false` otherwise.
 */
export function isUuid(value: string) {
  const uuidRegex = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
  return uuidRegex.test(value);
}

/**
 * Adjusts the input number to fit within specified min and max constraints,
 * ensuring it does not fall below zero.
 * @param constraints Object containing optional min and max values.
 * @param numberInput The input number to adjust.
 * @returns Adjusted number constrained within the specified range.
 */
export const constrainNumberInRange = (constraints: { min?: number; max?: number }, numberInput: number): number => {
  let adjustedValue = numberInput;

  // Adjust value to fit within max constraint
  if (constraints.max !== undefined) {
    adjustedValue = Math.min(adjustedValue, constraints.max);
  }

  // Adjust value to fit within min constraint
  if (constraints.min !== undefined) {
    adjustedValue = Math.max(adjustedValue, constraints.min);
  }

  // Ensure adjusted value does not fall below 0
  adjustedValue = Math.max(adjustedValue, 0);

  return adjustedValue;
};

/**
 * Splits an array of values into a main value and an array of other values.
 *
 * @template T - The type of the values in the array.
 * @param {T[]} values - The array of values to split.
 * @returns {{ mainValue: T | undefined; others: T[] }} - An object containing the main value and the array of other values.
 */
export const splitValuesForButtonLabel = <T>(values: T[] = []): { mainValue: T | undefined; others: T[] } =>
  values.reduce<{ mainValue: T | undefined; others: T[] }>(
    (acc, value) => {
      if (acc.mainValue === undefined) {
        // eslint-disable-next-line no-param-reassign
        acc.mainValue = value;
      } else {
        acc.others.push(value);
      }
      return acc;
    },
    { mainValue: undefined, others: [] }
  );
