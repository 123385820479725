import { generatePath } from 'react-router-dom';

import { CallDetails } from '@/entities/call-details/call-details.types';
import { Comment } from '@/entities/comments/comments.types';
import { NoteType, NoteTypes } from '@/entities/notes-calls/notes-calls.types';
import { Snippet } from '@/entities/snippets/snippets.types';
import { User } from '@/entities/users/users.types';
import { RoutePaths } from '@/enums';
import { encodePublicToken } from '@/stores/embed/decoded-public-token';
import { UTMMedium } from '@/tracking/utms';
import { PlayStatus } from '@/types/play-status';

export enum QUERY_PARAMETER {
  COMMENT = 'comment',
  NOTE_BLOCK = 'noteBlockUuid',
  TIME = 't',
  CALL_REVIEW = 'reviewId',
  TRANSCRIPT_BLOCK = 'transcriptBlockId',
  AI_CALL_SCORING_TEMPLATE = 'aiCallScoringTemplateUuid',
  NOTE_ID = 'noteId',
  TOPIC_IDS = 'topicIds',

  LIBRARY_ITEM = 'libraryItemId',
  SNIPPET_ID = 'snippetId',
  SNIPPET_DEPRECATED = 'snippet',

  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',

  CODE = 'code',
  TO = 'to',
  PROVIDER = 'provider',
  ERROR = 'error',

  PLAY_STATUS = 'playStatus',
  SEARCH_PHRASES = 'searchPhrases',

  VIEW_ID = 'viewId',

  CRM_FILLING_PANEL_OPEN = 'crmFillingPanelOpen',
  EMAIL_FOLLOW_UP_MODAL_OPEN = 'emailFollowUpModalOpen',
}

const getPrivateCallURL = (callId: number) =>
  new URL(generatePath(RoutePaths.CALL_DETAILS, { id: callId.toString() }), new URL(window.location.origin));

export const getCommentLink = (callId: number, commentId: number | string, snippetId?: number) => {
  const commentURL = getPrivateCallURL(callId);
  commentURL.searchParams.set(QUERY_PARAMETER.COMMENT, commentId.toString());
  if (snippetId) {
    commentURL.searchParams.set(QUERY_PARAMETER.SNIPPET_ID, snippetId.toString());
  }
  commentURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, UTMMedium.SHARE_LINK_COMMENT);
  return commentURL.href;
};

export const getTranscriptBlockLink = (callId: number, transcriptBlockId: number) => {
  const callURL = getPrivateCallURL(callId);
  callURL.searchParams.set(QUERY_PARAMETER.TRANSCRIPT_BLOCK, transcriptBlockId.toString());
  callURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, UTMMedium.SHARE_LINK_TRANSCRIPT);
  return callURL.href;
};

export const getNoteBlockLink = (
  callId: number,
  noteBlockUuid: string,
  noteType: NoteType,
  playStatus?: PlayStatus
) => {
  const utmMedium = noteType === NoteTypes.AI ? UTMMedium.SHARE_LINK_AI_NOTE : UTMMedium.SHARE_LINK_LIVE_NOTE;
  const callURL = getPrivateCallURL(callId);
  callURL.searchParams.set(QUERY_PARAMETER.NOTE_BLOCK, noteBlockUuid);
  callURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, utmMedium);
  playStatus && callURL.searchParams.set(QUERY_PARAMETER.PLAY_STATUS, PlayStatus.Playing);
  return callURL.href;
};

export const getPrivateLink = (callId: number, snippetId?: number) => {
  const snippetURL = getPrivateCallURL(callId);
  snippetId && snippetURL.searchParams.set(QUERY_PARAMETER.SNIPPET_ID, snippetId.toString());
  snippetURL.searchParams.set(
    QUERY_PARAMETER.UTM_MEDIUM,
    snippetId ? UTMMedium.SHARE_LINK_SNIPPET : UTMMedium.SHARE_LINK_CALL
  );
  return snippetURL.href;
};

const getSnippetEmbedPath = (publicToken: string) => generatePath(RoutePaths.EMBED, { publicToken });

export const getSnippetIframeSource = (publicToken: string, snippetId?: number) => {
  const iframeURL = new URL(getSnippetEmbedPath(publicToken), new URL(window.location.origin));
  if (snippetId) {
    iframeURL.searchParams.set(QUERY_PARAMETER.SNIPPET_ID, snippetId.toString());
  }
  return iframeURL.href;
};

export const getShareLink = (
  call: Partial<Pick<CallDetails, 'uuid' | 'id' | 'isPublic'>>,
  baseUrl: URL,
  userTracking: Pick<User, 'id' | 'fullName' | 'tenantName'>,
  options?: {
    comment?: Pick<Comment, 'id' | 'callId'>;
    snippet?: Pick<Snippet, 'id' | 'startTime' | 'endTime' | 'isPublic'>;
  }
): string => {
  // To avoid mutating the original URL object
  const url = new URL(baseUrl);
  if (call.id && options?.comment && !options?.snippet?.isPublic) {
    return getCommentLink(call.id, options?.comment.id);
  }
  if (call.uuid && (call.isPublic || options?.snippet?.isPublic)) {
    const publicToken = encodePublicToken({
      uuid: call.uuid,
      tenantName: userTracking.tenantName,
      sharerId: userTracking.id,
      sharerName: userTracking.fullName,
    });

    // eslint-disable-next-line no-param-reassign
    url.pathname = generatePath(RoutePaths.PUBLIC_CALL, { publicToken });
    url.searchParams.set(
      QUERY_PARAMETER.UTM_MEDIUM,
      options?.snippet ? UTMMedium.SHARE_LINK_SNIPPET : UTMMedium.SHARE_LINK_CALL
    );

    options?.snippet && url.searchParams.set(QUERY_PARAMETER.SNIPPET_ID, options.snippet.id.toString());
    return url.href;
  }
  if (!call.id) {
    throw new Error('getShareLink() requires a valid snippet and call.id if no comment is provided');
  }
  return getPrivateLink(call.id, options?.snippet?.id);
};

export const getCallReviewLink = (callId: number, reviewId: number) => {
  const callURL = getPrivateCallURL(callId);
  callURL.searchParams.set(QUERY_PARAMETER.CALL_REVIEW, reviewId.toString());
  callURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, UTMMedium.SHARE_LINK_REVIEW);
  return callURL.href;
};

export const combineRoutes = (segments: string[]): string =>
  `/${segments.map((segment) => segment.replaceAll(/^\/|\/$/g, '')).join('/')}`;

export const getAiCallScoringLink = (callId: number, scoringTemplateUuid: string) => {
  const callURL = getPrivateCallURL(callId);
  callURL.searchParams.set(QUERY_PARAMETER.AI_CALL_SCORING_TEMPLATE, scoringTemplateUuid.toString());
  callURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, UTMMedium.SHARE_LINK_AI_SCORING);
  return callURL.href;
};

export const getHighlightsLink = (callId: number, noteId: string) => {
  const callURL = getPrivateCallURL(callId);
  callURL.searchParams.set(QUERY_PARAMETER.NOTE_ID, noteId);
  callURL.searchParams.set(QUERY_PARAMETER.UTM_MEDIUM, UTMMedium.SHARE_LINK_AI_SUMMARY);
  return callURL.href;
};
