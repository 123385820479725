import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { secondsToMilliseconds } from 'date-fns';

import { apiService } from '@/api.service';
import { OtherInfoOptionType } from '@/components/common/search-components/search-components.types';
import { LanguageCode } from '@/enums/languages';
import { CustomQueryOptions } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { CRMProvider } from '@/types/providers';
import { DEFAULT_PAGINATION_PER_PAGE } from '@/utils/paginated.utils';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam } from '@/utils/utils';

import { toSearchableOptions } from './deals.selects';
import {
  ApiDealSearchParams,
  ApiDealSearchResult,
  DealAiTopics,
  DealContactRole,
  DealContactsWithObjections,
  DealCRMEntityField,
  DealCRMField,
  DealCrmInfo,
  DealInfo,
  DealNextMeeting,
  DealOverview,
  DealOverviewTypes,
  DealSummary,
} from './deals.types';
import { DealWarning } from '../deal-warnings/deal-warnings.types';
import { queryKeys } from '../queryKeys';

const DEAL_BASE_URL = '/deals';

const useDealsSearchQuery = (params: ApiDealSearchParams) => {
  return useInfiniteQuery({
    queryKey: queryKeys.deals.list(params),
    queryFn: async ({ pageParam }) => {
      const { data } = await apiService.API.post<PaginationRequestResult<ApiDealSearchResult>>(
        combineRoutes([DEAL_BASE_URL, 'search']),
        {
          ...params,
          pagination: {
            page: pageParam,
            perPage: DEFAULT_PAGINATION_PER_PAGE,
          },
        }
      );
      return {
        ...data,
        values: data.values.map((deal) => ({
          ...deal,
          activityTimeline: deal.activityTimeline?.map((activity) => ({
            ...activity,
            startDate: new Date(activity.startDate),
          })),
        })),
      };
    },
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, DEFAULT_PAGINATION_PER_PAGE),
    initialPageParam: 1,
  });
};

const useDealLossReasonQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.lossReason(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(combineRoutes([DEAL_BASE_URL, 'loss-reasons']));

      return data;
    },
    ...options?.queryOptions,
  });
};

const useDealLossReasonAsSearchableOptionsQuery = () => {
  return useDealLossReasonQuery({
    queryOptions: { select: (data) => toSearchableOptions(data, OtherInfoOptionType.DEAL_LOSS_REASON) },
  });
};

export const getDealStages = async () => {
  const { data } = await apiService.API.get<string[]>(combineRoutes([DEAL_BASE_URL, 'stages']));
  return data;
};

const useDealStagesQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.stages(),
    queryFn: getDealStages,
    ...options?.queryOptions,
  });
};
export const useDealTypesQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.types(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(combineRoutes([DEAL_BASE_URL, 'types']));
      return data;
    },
    ...options?.queryOptions,
  });
};

const useDealStagesAsSearchableOptionsQuery = () => {
  return useDealStagesQuery({
    queryOptions: { select: (data) => toSearchableOptions(data, OtherInfoOptionType.DEAL_STAGE) },
  });
};

const useDealStatusesQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.statuses(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(combineRoutes([DEAL_BASE_URL, 'statuses']));

      return data;
    },
    ...options?.queryOptions,
  });
};

const useDealStatusesAsSearchableOptionsQuery = () => {
  return useDealStatusesQuery({
    queryOptions: { select: (data) => toSearchableOptions(data, OtherInfoOptionType.DEAL_STATUS) },
  });
};

const useLeadSourcesQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.lead.sources(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(combineRoutes([DEAL_BASE_URL, 'sources']));

      return data;
    },
    ...options?.queryOptions,
  });
};

const useLeadSourcesAsSearchableOptionsQuery = () => {
  return useLeadSourcesQuery({
    queryOptions: { select: (data) => toSearchableOptions(data, OtherInfoOptionType.LEAD_SOURCE) },
  });
};

const useDealInfoQuery = <TSelectData = DealInfo>(
  dealId?: number,
  options?: CustomQueryOptions<DealInfo, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deal.info(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealInfo>(combineRoutes([DEAL_BASE_URL, dealId!.toString()]));
      return data;
    },
    ...options?.queryOptions,
    enabled: !!dealId && options?.queryOptions?.enabled !== false,
  });
};

const useCrmToDealInfoQuery = (dealCrm?: { crm: CRMProvider; crmId: string }) => {
  return useQuery({
    queryKey: queryKeys.deal.crmInfo(dealCrm),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealCrmInfo | null>(
        combineRoutes(['deals', dealCrm!.crm, dealCrm!.crmId])
      );
      if (!data) {
        throw new Error('Deal not found, waiting synchro to be done ...');
      }
      return data;
    },
    retry: 3,
    retryDelay: secondsToMilliseconds(30),
    enabled: !!dealCrm,
  });
};

const useDealNextMeetingQuery = <TSelectData = DealNextMeeting>(
  dealId?: number,
  options?: CustomQueryOptions<DealNextMeeting, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deal.nextMeeting(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealNextMeeting>(
        combineRoutes([DEAL_BASE_URL, dealId!.toString(), 'next-meeting'])
      );
      return data;
    },
    enabled: !!dealId && options?.queryOptions?.enabled !== false,
    ...options?.queryOptions,
  });
};

const useDealContactsQuery = <TSelectData = DealContactsWithObjections>(
  dealId: number,
  options?: CustomQueryOptions<DealContactsWithObjections, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deal.contacts(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealContactsWithObjections>(
        combineRoutes([DEAL_BASE_URL, dealId.toString(), 'contacts'])
      );
      return data;
    },
    enabled: !!dealId,
    ...options?.queryOptions,
  });
};

const useDealsOverviewStatusQuery = (params: Pick<ApiDealSearchParams, 'filter'>) => {
  return useQuery({
    queryKey: queryKeys.deals.overviewStatus(params),
    queryFn: async () => {
      const { data } = await apiService.API.post<Omit<DealOverview, 'type'>[]>(
        combineRoutes([DEAL_BASE_URL, 'overview/status']),
        params
      );

      return data.map((deal) => ({
        ...deal,
        type: DealOverviewTypes.STATUS,
      }));
    },
  });
};

const useDealsOverviewForecastCategoriesQuery = (params: Pick<ApiDealSearchParams, 'filter'>) => {
  return useQuery({
    queryKey: queryKeys.deals.overviewForecastCategories(params),
    queryFn: async () => {
      const { data } = await apiService.API.post<Omit<DealOverview, 'type'>[]>(
        combineRoutes([DEAL_BASE_URL, 'overview/forecast-categories']),
        params
      );

      return data.map((deal) => ({
        ...deal,
        type: DealOverviewTypes.FORECAST_CATEGORY,
      }));
    },
  });
};

type DealSummaryQueryProps<TSelectData = DealSummary> = {
  dealId: number;
  language?: LanguageCode;
} & CustomQueryOptions<DealSummary | null, TSelectData>;

const useDealSummaryQuery = <TSelectData = DealSummary | null>({
  dealId,
  language,
  queryOptions,
}: DealSummaryQueryProps<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.summary(dealId, language),
    queryFn: async () => {
      if (language) {
        const { data } = await apiService.API.get<DealSummary | null>(
          combineRoutes([DEAL_BASE_URL, dealId.toString(), 'summary']),
          {
            params: {
              language,
            },
          }
        );
        return data;
      }
      const { data } = await apiService.API.get<DealSummary | null>(
        combineRoutes([DEAL_BASE_URL, dealId.toString(), 'summary'])
      );
      return data;
    },
    ...queryOptions,
  });
};

export const getDealContactRoles = async () => {
  const { data } = await apiService.API.get<DealContactRole[]>(combineRoutes([DEAL_BASE_URL, 'roles']));
  return data;
};

const useDealContactRolesQuery = <TSelectData = DealContactRole[]>(
  options?: CustomQueryOptions<DealContactRole[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deals.contactRoles(),
    queryFn: getDealContactRoles,
    ...options?.queryOptions,
  });
};

type DealAiTopicsQueryProps<TSelectData = DealAiTopics> = {
  dealId: number;
} & CustomQueryOptions<DealAiTopics, TSelectData>;

const useDealAiTopicsByDealIdQuery = ({ dealId, queryOptions }: DealAiTopicsQueryProps) => {
  return useQuery({
    queryKey: queryKeys.deal.aiTopics(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealAiTopics>(
        combineRoutes([DEAL_BASE_URL, dealId.toString(), 'ai-topics'])
      );
      return data;
    },
    ...queryOptions,
  });
};

type DealWarningsByDealIdQueryProps<TSelectData = DealWarning[]> = {
  dealId: number;
} & CustomQueryOptions<DealWarning[], TSelectData>;

const useDealWarningsByDealIdQuery = <TSelectData = DealWarning[]>({
  dealId,
  queryOptions,
}: DealWarningsByDealIdQueryProps<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deal.warnings(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealWarning[]>(
        combineRoutes([DEAL_BASE_URL, dealId.toString(), 'deal-warnings'])
      );
      return data;
    },
    ...queryOptions,
  });
};

type DealAskAnythingStatProps<
  TSelectData = {
    callCount: number;
  },
> = {
  dealId?: number;
} & CustomQueryOptions<
  {
    callCount: number;
  },
  TSelectData
>;

const useDealAskAnythingStatQuery = <
  TSelectData = {
    callCount: number;
    calls: {
      id: number;
      name: string;
    }[];
  },
>({
  dealId,
  queryOptions,
}: DealAskAnythingStatProps<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.deals.askAnythingStats(Number(dealId)),
    queryFn: async () => {
      const { data } = await apiService.API.get<{
        callCount: number;
        calls: {
          id: number;
          name: string;
        }[];
      }>(combineRoutes(['ai-chat/deals/', dealId!.toString(), 'stats']));
      return data;
    },
    ...queryOptions,
    enabled: !!dealId && queryOptions?.enabled !== false,
  });
};

type DealSettingsCrmFieldsAvailable = {
  deal: DealCRMField[];
};

const useDealSettingsCRMFieldsAvailableQuery = <TSelectData = DealSettingsCrmFieldsAvailable>(
  options?: CustomQueryOptions<DealSettingsCrmFieldsAvailable, TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deals.dealCrmfieldsAvailable(),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealSettingsCrmFieldsAvailable>(
        '/crm/properties/all?entityTypes[]=deal'
      );
      return {
        // Filter out fields that have not type
        // this is a hack waiting for the backend to fix the issue in https://linear.app/modjo/issue/TBD-602/type-can-be-undefined
        deal: data.deal.filter((field) => field.validation.type),
      };
    },
    ...options?.queryOptions,
  });
};

const useDealCrmEntityFieldsQuery = <TSelectData = DealCRMEntityField[]>(
  options?: CustomQueryOptions<DealCRMEntityField[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deals.crmEntityFields(),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealCRMEntityField[]>('/entity-fields/deal');
      return data;
    },
    ...options?.queryOptions,
  });
};

const useDealCrmSpecificEntityFieldsQuery = <TSelectData = DealCRMEntityField[]>(
  entityType: string,
  options?: CustomQueryOptions<DealCRMEntityField[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.deals.crmEntityFields(entityType),
    queryFn: async () => {
      const { data } = await apiService.API.get<DealCRMEntityField[]>(
        combineRoutes([DEAL_BASE_URL, '/entity-fields', entityType])
      );
      return data;
    },
    ...options?.queryOptions,
  });
};

export {
  useDealSettingsCRMFieldsAvailableQuery,
  useDealCrmEntityFieldsQuery,
  useDealCrmSpecificEntityFieldsQuery,
  useDealLossReasonAsSearchableOptionsQuery,
  useDealStagesQuery,
  useDealStagesAsSearchableOptionsQuery,
  useDealStatusesAsSearchableOptionsQuery,
  useDealStatusesQuery,
  useDealAskAnythingStatQuery,
  useLeadSourcesAsSearchableOptionsQuery,
  useDealsSearchQuery,
  useDealInfoQuery,
  useDealNextMeetingQuery,
  useDealContactRolesQuery,
  useDealSummaryQuery,
  useDealsOverviewStatusQuery,
  useDealsOverviewForecastCategoriesQuery,
  useDealContactsQuery,
  useDealAiTopicsByDealIdQuery,
  useDealWarningsByDealIdQuery,
  useCrmToDealInfoQuery,
};
