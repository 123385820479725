import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Path } from 'react-router-dom';

import { DatePeriods } from '@/components/common/DatePeriodFilter/date-period-filter.constant';
import { DatePeriod } from '@/components/common/DatePeriodFilter/date-period-filter.types';
import { SelectOption } from '@/components/common/ui-components/types';
import { PaginationRequestResult } from '@/types/paginations';

export type DataTestIdProps = {
  /*
  This prop is used for test automation purposes. It defines the identifier for a rendered component or HTML element
   */
  dataTestId?: string;
};
export type ProductTourIdProps = {
  /*
  This prop is used by Intercom product tours to target DOM elements.
   */
  productTourId?: string;
};
export function assertUnreachable(_: never): never {
  throw new Error('Reached unexpected case.');
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type ObjectValues<T> = T[keyof T];

export type Category = SelectOption;

export type NavigationDirection = 'left' | 'right' | 'down';

export type CustomQueryOptions<TQueryFnData, TSelectData = TQueryFnData> = {
  queryOptions?: Omit<UseQueryOptions<TQueryFnData, unknown, TSelectData>, 'queryKey' | 'queryFn'>;
};

export type CustomSuspenseQueryOptions<TQueryFnData, TSelectData = TQueryFnData> = {
  queryOptions?: Omit<UseSuspenseQueryOptions<TQueryFnData, unknown, TSelectData>, 'queryKey' | 'queryFn'>;
};

export type CustomInfiniteQueryOptions<TQueryFnData, TSelectData = TQueryFnData> = {
  queryOptions?: Pick<
    UseInfiniteQueryOptions<TQueryFnData, unknown, TSelectData, TQueryFnData, QueryKey, number>,
    'select' | 'enabled'
  >;
};

export type CustomMutationOptions<TMutationFnData, TVariables> = Omit<
  UseMutationOptions<AxiosResponse<TMutationFnData>, unknown, TVariables>,
  'mutationFn'
>;

export enum TemplateStatus {
  PENDING = 'pending',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export const flattenPaginatedQueryData = <T>(data: InfiniteData<PaginationRequestResult<T>>) => {
  return data.pages.flatMap((page) => page.values);
};

export const DEFAULT_PERIOD: DatePeriod = DatePeriods.ALL_TIME;

export type PreviousPageState = {
  previousLocationPath: Pick<Path, 'pathname' | 'search'>;
};
export const isPreviousPageState = (obj: unknown): obj is PreviousPageState => {
  return typeof obj === 'object' && obj !== null && 'previousLocationPath' in obj;
};
