export const CallsDataTestId = {
  CALL_DETAILS_DEALS_BREADCRUMB_ROOT_ID: 'callDetailsDealsBreadcrumbRoot',
  CALL_DETAILS_DEALS_BREADCRUMB_DEAL_ID: 'callDetailsDealsBreadcrumbDeal',
  CALL_DETAILS_MORE_INFO_LINK_ID: 'callDetailsMoreInfoLnk',
  CALL_TITLE_HEADER_ID: 'callTitleHeader',
  CALL_TITLE_TXT_ID: 'callTitleTxt',
  CALL_PROVIDER_NAME_LABEL_ID: 'callProviderNameLbl',
  CALL_ACCOUNT_NAME_CRM_LINK_ID: 'callAccountNameCrmLnk',
  CALL_LANGUAGE_LABEL_ID: 'callLanguageLbl',
  CALL_OPPORTUNITY_LINK_ID: 'callOpportunityLnk',
  CALL_HOST_NAME_ID: 'callHostNameLbl',
  CALL_ATTENDEE_LIST_ITEM_ID: 'callAttendeeLi',
  CALL_SECTION_INTERVAL_HEADING_ID: 'callSectionIntervalHeading',
  CALL_TIME_STAMP_LABEL_ID: 'callTimeStampLbl',
  CALL_LIST_CONTAINER_ID: 'callListContainer',
};
