import React, { forwardRef } from 'react';

import { ColorPalette } from '@/theme/colors';
import { cn } from '@/utils/utils';

import { ButtonBaseProps } from './types';
import { ModjoProgress } from '../../ModjoProgress';
import { Tooltip } from '../data-display/tooltip';

const CLASSES = {
  pill: 'rounded-full',
};

export const RawButton = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (
    {
      children,
      type,
      disabled = false,
      target,
      rel,
      className,
      pill,
      onClick,
      tooltip,
      tooltipProps = { variant: 'small' },
      isLoading = false,
      ...otherProps
    },
    reference
  ) => {
    const tagName = otherProps.as ?? (otherProps.href ? 'a' : 'button');
    const componentProps =
      tagName === 'button'
        ? {
            type: type === 'submit' ? 'submit' : 'button',
            disabled,
          }
        : {
            target: target ?? '_blank',
            rel: rel ?? 'noreferrer',
          };

    Object.assign(componentProps, {
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        onClick && onClick(event);
        event.currentTarget.blur();
      },
    });

    const ComponentTag = tagName as React.ElementType;
    const ComponentButton = (
      <ComponentTag
        ref={reference}
        {...componentProps}
        {...otherProps}
        disabled={componentProps.disabled || isLoading}
        className={cn(pill ? CLASSES.pill : 'rounded-md', className)}
      >
        {isLoading ? (
          <>
            <ModjoProgress color={ColorPalette.basicWhite} size={36} className="absolute" />
            {/* we are making the children transparent so that the button as still the same size */}
            <div className="opacity-0">{children}</div>
          </>
        ) : (
          children
        )}
      </ComponentTag>
    );

    return tooltip ? (
      <Tooltip
        content={tooltip}
        {...tooltipProps}
        contentClassName={cn('first-letter:capitalize', tooltipProps?.contentClassName)}
      >
        {ComponentButton}
      </Tooltip>
    ) : (
      ComponentButton
    );
  }
);
