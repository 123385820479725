import { AvatarProps } from '@/components/common/ui-components/data-display/avatar/types';

export interface SelectOption {
  id: string;
  label: string;
  disabled?: boolean;
  secondaryLabel?: string;
  avatarProps?: AvatarProps;
  color?: string;
}

export interface CheckboxListOption {
  id: number;
  label: string;
  secondaryLabel?: string;
  disabled?: boolean;
  avatarProps?: AvatarProps;
}

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
type ValueOfPosition = `${Position}`;

export type InputVariantSize = 'small' | 'large';
export type ElementPosition = ValueOfPosition;
