import React from 'react';

import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';

import { scrollbarDefaultOptions } from '@/enums/constants';
import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import CheckThinIcon from '@/static/icons/basic/check-Filled.svg?react';
import SystemCloseIcon from '@/static/icons/basic/cross-Filled.svg?react';
import { isEmpty } from '@/utils/utils';

import { MultipleSelectProps } from './types';
import { MaybeInAPortal } from '../layout';
import {
  DROPDOWN_MARGIN,
  DROPDOWN_ZINDEX,
  getButtonOrInputStyles,
  getLabelStyles,
  getListOptionItemIconStyles,
  getListOptionItemLabelStyles,
  getListOptionItemNoResultStyles,
  getListOptionItemStyles,
  getListOptionsStyles,
} from '../styles';

const MultipleSelect = ({
  label,
  placeholder,
  size = 'large',
  disabled = false,
  options,
  optionsSelected,
  setOptionsSelected,
  emptyLabel,
  isError,
  withPortal = true,
  dataTestId,
  clearable = false,
}: MultipleSelectProps) => {
  const { t } = useTranslation();

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [offset(DROPDOWN_MARGIN)],
  });

  const isLarge = size === 'large';
  const hasSomeOptionsSelected = optionsSelected && optionsSelected?.length > 0;
  const displayedPlaceholder = hasSomeOptionsSelected
    ? optionsSelected.map(({ id }) => options.find((option) => id === option.id)?.label).join(', ')
    : (placeholder ?? t('multipleSelect.placeholder'));

  return (
    <div className="flex flex-col">
      <Listbox value={optionsSelected} onChange={setOptionsSelected} disabled={disabled} multiple>
        {({ open }) => (
          <>
            {label && <Label className={getLabelStyles(isLarge, disabled)}>{label}</Label>}

            <ListboxButton
              ref={refs.setReference}
              as="div"
              data-test-id={dataTestId}
              className={clsx(
                getButtonOrInputStyles({
                  open,
                  isLarge,
                  disabled,
                  isError,
                  hasValues: hasSomeOptionsSelected,
                }),
                'relative'
              )}
            >
              <span className="block truncate">{displayedPlaceholder}</span>
              {optionsSelected.length > 0 && clearable && (
                <button
                  aria-label="Close"
                  type="button"
                  className="absolute inset-y-0 right-7 flex h-full items-center"
                  onClick={(event) => {
                    event.preventDefault();
                    setOptionsSelected([]);
                  }}
                >
                  <SystemCloseIcon className="icon-small fill-grey hover:bg-grey-100" />
                </button>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
                <ArrowDownChevronIcon className={clsx('icon-small mr-1 fill-grey', open && 'rotate-180')} />
              </span>
            </ListboxButton>

            <MaybeInAPortal withPortal={withPortal}>
              <Transition
                show={open}
                as="div"
                className="absolute"
                enter="transition duration-150 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-100 ease-out"
                leaveFrom="transform translate-y-0 opacity-100"
                leaveTo="transform -translate-y-10 opacity-0"
                style={{ zIndex: DROPDOWN_ZINDEX }}
              >
                <ListboxOptions modal={false} portal={withPortal} static>
                  <div
                    ref={refs.setFloating}
                    style={{ ...floatingStyles }}
                    className={clsx(getListOptionsStyles(isLarge), 'relative z-[9999] w-[var(--button-width)]')}
                  >
                    <OverlayScrollbarsComponent options={scrollbarDefaultOptions}>
                      <ul className="flex max-h-[40vh] flex-col gap-y-1">
                        {isEmpty(options) ? (
                          <li key="no-option" className={getListOptionItemNoResultStyles()}>
                            <span>{emptyLabel ?? t('multipleSelect.emptyLabel')}</span>
                          </li>
                        ) : (
                          options.map((option) => {
                            const selectedOption = !!optionsSelected.some((selected) => selected.id === option.id);
                            return (
                              <ListboxOption
                                key={option.id}
                                value={option}
                                className={getListOptionItemStyles(selectedOption)}
                              >
                                <>
                                  <span className={getListOptionItemLabelStyles(selectedOption)}>
                                    {option.label}
                                    {option.secondaryLabel && (
                                      <em className="text-grey-700">{` ${option.secondaryLabel}`}</em>
                                    )}
                                  </span>
                                  {selectedOption && (
                                    <span className={getListOptionItemIconStyles()}>
                                      <CheckThinIcon className="icon-small" aria-hidden />
                                    </span>
                                  )}
                                </>
                              </ListboxOption>
                            );
                          })
                        )}
                      </ul>
                    </OverlayScrollbarsComponent>
                  </div>
                </ListboxOptions>
              </Transition>
            </MaybeInAPortal>
          </>
        )}
      </Listbox>
    </div>
  );
};

export { MultipleSelect };
