import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { UserRole } from '@/enums';
import { Features } from '@/enums/features';
import { CRMProvider, Providers } from '@/types/providers';

const COMPATIBLE_CRM_DEALS = new Set<CRMProvider>([Providers.HUBSPOT, Providers.SALESFORCE]);

export const useHideModjoDeals = () => {
  const currentUser = useCurrentUser();
  const tenantQuery = useTenantQuery();

  // By default we hide the Modjo deals to avoid glitch while loading
  if (!currentUser || !tenantQuery.data?.crm) {
    return true;
  }

  if (currentUser.hasRole(UserRole.SUPERADMIN)) {
    return false;
  }

  return !currentUser?.hasFeature(Features.MODJO_DEALS) || !COMPATIBLE_CRM_DEALS.has(tenantQuery.data.crm);
};
