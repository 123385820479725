import { i18n } from '@/translation/i18n';
import { isEmpty, toNumber } from '@/utils/utils';

import { UrlFilters } from './useUrlFilters';
import { FiltersCategories, getOptionType, OtherOption } from '../common/search-components/Common';
import {
  OtherInfoOptionType,
  RangeCategory,
  RangeOptionType,
  ReviewsOptionType,
  SearchableObjectOptionType,
  SearchableObject,
} from '../common/search-components/search-components.types';

export const getRangeType = (
  mode: 'number' | 'date',
  min?: number | string | Date,
  max?: number | string | Date
): { rangeCategory: RangeCategory; label: string } => {
  if (min !== undefined && !max) {
    return mode === 'number'
      ? { rangeCategory: RangeCategory.GREATER, label: i18n.t('filters.categories.greaterThan') }
      : { rangeCategory: RangeCategory.AFTER, label: i18n.t('filters.categories.after') };
  }
  if (max !== undefined && !min) {
    return mode === 'number'
      ? { rangeCategory: RangeCategory.LOWER, label: i18n.t('filters.categories.lowerThan') }
      : { rangeCategory: RangeCategory.BEFORE, label: i18n.t('filters.categories.before') };
  }
  if (max === min) {
    return { rangeCategory: RangeCategory.EQUAL, label: i18n.t('filters.categories.equalTo') };
  }
  return { rangeCategory: RangeCategory.BETWEEN, label: i18n.t('filters.categories.between') };
};

export const filtersOptionsByCategories = (
  options: SearchableObject[],
  filters: UrlFilters,
  categories: FiltersCategories[]
) => {
  const result: SearchableObject[] = [];
  if (categories.includes(SearchableObjectOptionType.ACCOUNT)) {
    result.push(
      ...options.filter(
        (o) => filters.accountIds?.includes(toNumber(o.id)) && getOptionType(o) === SearchableObjectOptionType.ACCOUNT
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.CONTACT)) {
    result.push(
      ...options.filter(
        (o) => filters.contactIds?.includes(o.id as number) && getOptionType(o) === SearchableObjectOptionType.CONTACT
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.DEAL_STATUS)) {
    result.push(
      ...options.filter(
        (o) => filters.dealStatus === o.name && getOptionType(o) === SearchableObjectOptionType.DEAL_STATUS
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.LEAD_STATUS)) {
    result.push(
      ...options.filter(
        (o) => filters.leadStatus === o.name && getOptionType(o) === SearchableObjectOptionType.LEAD_STATUS
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.DEAL_STAGE)) {
    result.push(
      ...options.filter(
        (o) => filters.dealStage === o.name && getOptionType(o) === SearchableObjectOptionType.DEAL_STAGE
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.USER)) {
    result.push(
      ...options.filter(
        (o) => filters.userIds?.includes(o.id as number) && getOptionType(o) === SearchableObjectOptionType.USER
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.TAG)) {
    result.push(
      ...options.filter(
        (o) => filters.tagIds?.includes(o.id as number) && getOptionType(o) === SearchableObjectOptionType.TAG
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.TEAM)) {
    result.push(
      ...options.filter(
        (o) => filters.teamIds?.includes(o.id as number) && getOptionType(o) === SearchableObjectOptionType.TEAM
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.TOPIC)) {
    result.push(
      ...options.filter(
        (o) => filters.topicIds?.includes(o.id as number) && getOptionType(o) === SearchableObjectOptionType.TOPIC
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.TRANSCRIPT)) {
    result.push(
      ...filters.searchPhrases.map(
        (word) =>
          new OtherOption(undefined, word, OtherInfoOptionType.TRANSCRIPT, SearchableObjectOptionType.TRANSCRIPT)
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER)) {
    result.push(
      ...options.filter(
        (o) =>
          filters.externalPhoneProviders?.includes(o.id as string) &&
          getOptionType(o) === SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.LANGUAGE)) {
    result.push(
      ...options.filter(
        (o) => filters.languages?.includes(o.id as string) && getOptionType(o) === SearchableObjectOptionType.LANGUAGE
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.JOB_TITLE)) {
    result.push(
      ...options.filter(
        (o) => filters.jobTitles?.includes(o.name) && getOptionType(o) === OtherInfoOptionType.JOB_TITLE
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.LEAD_SOURCE)) {
    result.push(
      ...options.filter(
        (o) => filters.leadSources?.includes(o.name) && getOptionType(o) === OtherInfoOptionType.LEAD_SOURCE
      )
    );
  }
  if (categories.includes(SearchableObjectOptionType.DEAL_LOSS_REASON)) {
    result.push(
      ...options.filter(
        (o) => filters.dealLossReasons?.includes(o.name) && getOptionType(o) === OtherInfoOptionType.DEAL_LOSS_REASON
      )
    );
  }
  if (categories.includes(RangeOptionType.DEAL_CLOSE_DATE)) {
    result.push(...options.filter((o) => getOptionType(o) === RangeOptionType.DEAL_CLOSE_DATE));
  }
  if (categories.includes(RangeOptionType.DEAL_AMOUNT)) {
    result.push(...options.filter((o) => getOptionType(o) === RangeOptionType.DEAL_AMOUNT));
  }
  if (categories.includes(RangeOptionType.REVIEW_RATING)) {
    result.push(...options.filter((o) => getOptionType(o) === RangeOptionType.REVIEW_RATING));
  }
  if (categories.includes(ReviewsOptionType.AI_CALL_SCORING)) {
    result.push(...options.filter((o) => getOptionType(o) === ReviewsOptionType.AI_CALL_SCORING));
  }
  if (categories.includes(RangeOptionType.CALL_DURATION)) {
    result.push(...options.filter((o) => getOptionType(o) === RangeOptionType.CALL_DURATION));
  }

  return result;
};

/**
 * Transform filters into known searchable categories.
 * Used after URL params are parsed and stored in callFilters store.
 *
 * @param filters Explore filters
 * @returns {SearchableObjectOptionType[]} Array of filter category enum
 */
export const makeCategoriesFromFilters = (filters: Partial<UrlFilters>): FiltersCategories[] => {
  const categories: FiltersCategories[] = [];
  if (!isEmpty(filters.accountIds)) {
    categories.push(SearchableObjectOptionType.ACCOUNT);
  }
  if (!isEmpty(filters.contactIds)) {
    categories.push(SearchableObjectOptionType.CONTACT);
  }
  if (filters.dealStage) {
    categories.push(SearchableObjectOptionType.DEAL_STAGE);
  }
  if (filters.dealStatus) {
    categories.push(SearchableObjectOptionType.DEAL_STATUS);
  }
  if (filters.leadStatus) {
    categories.push(SearchableObjectOptionType.LEAD_STATUS);
  }
  if (!isEmpty(filters.userIds)) {
    categories.push(SearchableObjectOptionType.USER);
  }
  if (!isEmpty(filters.tagIds)) {
    categories.push(SearchableObjectOptionType.TAG);
  }
  if (!isEmpty(filters.teamIds)) {
    categories.push(SearchableObjectOptionType.TEAM);
  }
  if (!isEmpty(filters.topicIds)) {
    categories.push(SearchableObjectOptionType.TOPIC);
  }
  if (!isEmpty(filters.searchPhrases)) {
    categories.push(SearchableObjectOptionType.TRANSCRIPT);
  }
  if (!isEmpty(filters.languages)) {
    categories.push(SearchableObjectOptionType.LANGUAGE);
  }
  if (!isEmpty(filters.externalPhoneProviders)) {
    categories.push(SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER);
  }
  if (!isEmpty(filters.jobTitles)) {
    categories.push(SearchableObjectOptionType.JOB_TITLE);
  }
  if (!isEmpty(filters.leadSources)) {
    categories.push(SearchableObjectOptionType.LEAD_SOURCE);
  }
  if (!isEmpty(filters.dealLossReasons)) {
    categories.push(SearchableObjectOptionType.DEAL_LOSS_REASON);
  }
  if (filters.startCloseDate || filters.endCloseDate) {
    categories.push(RangeOptionType.DEAL_CLOSE_DATE);
  }
  if (filters.minAmount !== undefined || filters.maxAmount !== undefined) {
    categories.push(RangeOptionType.DEAL_AMOUNT);
  }
  if (filters.minReviewRating !== undefined || filters.maxReviewRating !== undefined) {
    categories.push(RangeOptionType.REVIEW_RATING);
  }
  if (
    filters.minAiScoring !== undefined ||
    filters.maxAiScoring !== undefined ||
    (filters.aiScoringTemplateUuids && filters.aiScoringTemplateUuids.length > 0)
  ) {
    categories.push(ReviewsOptionType.AI_CALL_SCORING);
  }
  if (filters.minDuration !== undefined || filters.maxDuration !== undefined) {
    categories.push(RangeOptionType.CALL_DURATION);
  }
  return categories;
};
