import { useCallback } from 'react';

import { useQueryParamsBuilder } from '@/hooks/useQueryParamsBuilder';
import { CustomQueryOptions } from '@/types/common';

import { NotesCallsQueryParams, useNotesCallsQuery } from './notes-calls.queries';
import { ApiNote, NoteTypes } from './notes-calls.types';
import { useCallDetails } from '../call-details/call-details.hooks';
import { isPublicSnippetCallDetails } from '../call-details/call-details.types';

export const useCallDetailsNotesQuery = <TSelectData = ApiNote[]>(
  options?: CustomQueryOptions<ApiNote[], TSelectData>
) => {
  const callDetails = useCallDetails();
  const { id: callId } = callDetails;
  const notesCallsParams = useQueryParamsBuilder({
    callId,
  });
  return useNotesCallsQuery<TSelectData>({
    ...notesCallsParams,
    queryOptions: { ...options?.queryOptions, enabled: !isPublicSnippetCallDetails(callDetails) },
  });
};

export const useCallDetailsNotesByIdQuery = (id: number | undefined, enabled = true) => {
  const callDetails = useCallDetails();
  const { id: callId } = callDetails;
  const notesCallsParams = useQueryParamsBuilder({
    callId,
  }) as NotesCallsQueryParams<ApiNote[]>;
  return useNotesCallsQuery({
    ...notesCallsParams,
    queryOptions: {
      select: useCallback((notes: ApiNote[]) => notes.find((note) => note.id === id), [id]),
      enabled: !isPublicSnippetCallDetails(callDetails) && enabled,
    },
  });
};

export const useCallDetailsNotes = () => {
  const notesCallQuery = useCallDetailsNotesQuery();

  if (!notesCallQuery.data) {
    return [];
  }

  return notesCallQuery.data;
};

export const useCallDetailsSummaries = () => {
  const notesCallQuery = useCallDetailsNotesQuery({
    queryOptions: {
      select: useCallback((notes: ApiNote[]) => notes.filter((note) => note.type === NoteTypes.AI), []),
    },
  });
  if (!notesCallQuery.data) {
    return [];
  }
  return notesCallQuery.data;
};

export const useCallDetailsModjoNotes = () => {
  const notesCallQuery = useCallDetailsNotesQuery<ApiNote[]>({
    queryOptions: {
      select: useCallback((notes: ApiNote[]) => notes.filter((note) => note.type === NoteTypes.USER), []),
    },
  });
  if (!notesCallQuery.data) {
    return [];
  }
  return notesCallQuery.data;
};

export const useIsHighlights = (noteId?: number | null) => {
  const aiSummaries = useCallDetailsSummaries();
  if (!noteId) {
    return false;
  }
  return aiSummaries.some((summary) => summary.id === noteId);
};
