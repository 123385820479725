export const DealsDataTestId = {
  DEALS_LIST_ID: 'dealsList',
  DEALS_LIST_ROW_ID: 'dealsListRow',
  DEALS_LIST_ROW_DEAL_NAME_ID: 'dealsListRowDealName',
  DEALS_LIST_ROW_OWNER_NAME_ID: 'dealsListRowOwnerName',
  DEALS_LIST_HEADER_AMOUNT_ID: 'dealsListHeaderAmount',
  DEALS_LIST_ROW_AMOUNT_ID: 'dealsListRowAmount',
  DEALS_LIST_FILTER_TEAM_ID: 'dealsListFilterTeam',
  DEALS_LIST_FILTER_USER_ID: 'dealsListFilterUser',
  DEALS_LIST_FILTER_CLOSE_DATE_ID: 'dealsListFilterCloseDate',
  DEALS_LIST_FILTER_STAGE_ID: 'dealsListFilterStage',
  DEALS_LIST_CLEAR_FILTERS_ID: 'dealsListClearFilters',
  DEALS_LIST_CREATE_SAVED_VIEW_ID: 'dealsListCreateSavedView',
  DEALS_LIST_CREATE_SAVED_VIEW_DIALOG_ID: 'dealsListCreateSavedViewDialog',
  DEALS_LIST_UPDATE_SAVED_VIEW_BUTTON_MENU_ID: 'dealsListUpdateSavedViewButtonMenu',
  DEALS_LIST_UPDATE_SAVED_VIEW_BUTTON_MENU_COPY_ID: 'dealsListUpdateSavedViewButtonMenuCopy',
  DEALS_LIST_UPDATE_SAVED_VIEW_BUTTON_MENU_UPDATE_ID: 'dealsListUpdateSavedViewButtonMenuUpdate',
  DEALS_LIST_SEARCH_ID: 'dealsListSearch',
  DEALS_LIST_COPY_LINK_ID: 'dealsListCopyLink',
  DEAL_VIEW_INFO_TITLE_ID: 'dealViewInfoTitle',
  DEAL_VIEW_INFO_ACCOUNT_ID: 'dealViewInfoAccount',
  DEAL_VIEW_INFO_AMOUNT_ID: 'dealViewInfoAmount',
  DEAL_VIEW_INFO_CLOSE_DATE_ID: 'dealViewInfoCloseDate',
  DEAL_VIEW_INF0_STAGE_ID: 'dealViewInfoStage',
  DEAL_VIEW_INFO_OWNER_ID: 'dealViewInfoOwner',
  DEAL_BREADCRUMB_ID: 'dealBreadcrumb',
};
