import { matchPath, Location, useLocation } from 'react-router-dom';

import { RoutePaths } from '@/enums';
import { extractPublicToken } from '@/stores/embed/decoded-public-token';
import { QUERY_PARAMETER } from '@/utils/url-utils';
import { toNumber } from '@/utils/utils';

export type CallURLInfos =
  | {
      isPublic: true;
      tenantName: string;
      sharerName: string;
      sharerId: number;
      uuid: string;
      isSnippet: true;
      snippetId: number;
      isPublicSnippet: true;
    }
  | {
      isPublic: true;
      tenantName: string;
      sharerName: string;
      sharerId: number;
      uuid: string;
      isSnippet: false;
      snippetId?: never;
      isPublicSnippet: false;
    }
  | {
      isPublic: false;
      tenantName?: never;
      uuid?: never;
      isSnippet: true;
      snippetId: number;
      isPublicSnippet: false;
      callId: number;
      sharerName?: string;
      sharerId?: number;
    }
  | {
      isPublic: false;
      tenantName?: never;
      uuid?: never;
      isSnippet: false;
      snippetId?: never;
      isPublicSnippet: false;
      callId: number;
      sharerName?: string;
      sharerId?: number;
    };

// Checking paths from all the routes where it's possible to extract some call URL infos
const supportedCallDetailsRoutes = [RoutePaths.CALL_DETAILS, RoutePaths.DEAL_CALL_VIEW];

const extractSnippetIdFromSearchParams = (search: string): number | undefined => {
  const searchParams = new URLSearchParams(search);
  const snippetIdParameter = searchParams.get(QUERY_PARAMETER.SNIPPET_ID);
  return snippetIdParameter ? toNumber(snippetIdParameter) : undefined;
};

const findMatchedRoute = (location: Location) => {
  for (const route of supportedCallDetailsRoutes) {
    const match = matchPath({ path: route }, location.pathname);
    if (match) {
      return match;
    }
  }
  return null;
};

const extractPrivateCallInfos = (location: Location): { callId: number; snippetId?: number } => {
  const matchedCallDetailsRoute = findMatchedRoute(location);
  // If there's no matched route, it means that we can't have a call id from the URL in call details context
  if (!matchedCallDetailsRoute) {
    return { callId: 0 };
  }

  const callIdString = matchedCallDetailsRoute.params.id ?? matchedCallDetailsRoute.params.callId;
  if (!callIdString) {
    // We are in a page where there's a call but cannot extract the id
    throw new Error(`Failed to extract call id from location ${location.pathname}`);
  }

  const callId = toNumber(callIdString);
  if (Number.isNaN(callId)) {
    throw new TypeError(`The param ${callIdString} is not a valid number.`);
  }

  const snippetId = extractSnippetIdFromSearchParams(location.search);

  return { callId, snippetId };
};

const useCallURLInfos = (): CallURLInfos => {
  const location = useLocation();
  const publicCallDetailsPageMatch =
    matchPath(RoutePaths.PUBLIC_CALL, location.pathname) || matchPath(RoutePaths.EMBED, location.pathname);

  // Public call
  const publicTokenInfos = publicCallDetailsPageMatch && extractPublicToken(location);
  if (publicCallDetailsPageMatch && publicTokenInfos?.uuid) {
    if (publicTokenInfos?.snippetId) {
      return {
        isPublic: true,
        tenantName: publicTokenInfos.tenantName,
        uuid: publicTokenInfos.uuid,
        sharerName: publicTokenInfos?.sharerName,
        sharerId: publicTokenInfos?.sharerId,
        isSnippet: true,
        snippetId: publicTokenInfos.snippetId,
        isPublicSnippet: true,
      };
    }
    return {
      isPublic: true,
      tenantName: publicTokenInfos.tenantName,
      sharerName: publicTokenInfos.sharerName,
      sharerId: publicTokenInfos.sharerId,
      uuid: publicTokenInfos.uuid,
      isSnippet: false,
      isPublicSnippet: false,
    };
  }

  // Private call
  const privateCallInfos = extractPrivateCallInfos(location);
  if (privateCallInfos?.snippetId) {
    return {
      isPublic: false,
      isSnippet: true,
      snippetId: privateCallInfos.snippetId,
      isPublicSnippet: false,
      callId: privateCallInfos.callId,
    };
  }
  return {
    isPublic: false,
    isSnippet: false,
    isPublicSnippet: false,
    callId: privateCallInfos.callId,
  };
};

export { useCallURLInfos };
