const AskAnythingSuggestionCategories = {
  DEFAULT: 'DEFAULT',
  DISCOVERY: 'DISCOVERY',
  DEMO: 'DEMO',
  NEGOTIATIONS: 'NEGOTIATIONS',
} as const;

export type AskAnythingSuggestionCategory =
  (typeof AskAnythingSuggestionCategories)[keyof typeof AskAnythingSuggestionCategories];

export const AskAnythingSuggestionsQuestionsTranslationKeys = {
  [AskAnythingSuggestionCategories.DEFAULT]: 'dealView.askMeAnything.suggestedQuestions.DEFAULT.title',
  [AskAnythingSuggestionCategories.DISCOVERY]: 'dealView.askMeAnything.suggestedQuestions.DISCOVERY.title',
  [AskAnythingSuggestionCategories.DEMO]: 'dealView.askMeAnything.suggestedQuestions.DEMO.title',
  [AskAnythingSuggestionCategories.NEGOTIATIONS]: 'dealView.askMeAnything.suggestedQuestions.NEGOTIATIONS.title',
} as const satisfies Record<AskAnythingSuggestionCategory, string>;
