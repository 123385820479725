import React, { forwardRef, Fragment, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';

import { ModjoProgress } from '@/components/common/ModjoProgress';
import { ButtonColorVariants } from '@/components/common/ui-components/buttons/types';
import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { ButtonOutlined } from '@/components/common/ui-components/buttons/UIButtonOutlined';
import { ColorPalette } from '@/theme/colors';
import { cn } from '@/utils/utils';

import { ActionsProps, ActionsType, DialogSize } from './types';

const CLASSES = {
  size: {
    tiny: 'max-w-xs',
    small: 'max-w-sm',
    medium: 'max-w-md',
    large: 'max-w-xl',
    full: 'max-w-full h-full',
  },
};

type DialogBackdropProps = {
  color?: string;
};

const DialogBackdrop = ({ color }: DialogBackdropProps) => (
  <div className={cn('fixed inset-0 bg-[#9284BF] bg-opacity-40 backdrop-blur-[2px]', color)} />
);

const DialogAnimatedPanel = forwardRef<HTMLDivElement, { dialogSize: DialogSize; children?: ReactNode }>(
  ({ children, dialogSize, ...otherProps }, reference) => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      {...otherProps}
    >
      <div className="flex-center fixed inset-0" ref={reference}>
        <div
          className={clsx(
            'flex-center mx-auto min-h-full w-full',
            dialogSize === 'full' ? 'px-44 py-10' : 'p-4',
            CLASSES.size[dialogSize]
          )}
        >
          <Dialog.Panel
            className={clsx(
              'w-full overflow-hidden rounded-2xl bg-grey-white text-left align-middle shadow-xl transition-all',
              dialogSize === 'full' && 'h-full'
            )}
          >
            <OverlayScrollbarsComponent className="h-full" options={{ scrollbars: { autoHide: 'scroll' } }}>
              {children}
            </OverlayScrollbarsComponent>
          </Dialog.Panel>
        </div>
      </div>
    </Transition.Child>
  )
);

const getConfirmationButtonColor = (type: ActionsType): ButtonColorVariants => {
  switch (type) {
    case 'save':
    case 'validate': {
      // eslint-disable-next-line i18next/no-literal-string
      return 'primary';
    }
    case 'delete': {
      // eslint-disable-next-line i18next/no-literal-string
      return 'error';
    }
    default: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'primary';
    }
  }
};

const ActionsFooter = ({
  variant = 'dialog',
  type = 'save',
  isConfirmLoading,
  buttonsPosition,
  buttonSize = 'small',
  onCancelLabel,
  onConfirmLabel,
  onCancel,
  onConfirm,
  confirmationButtonProps,
  dataTestId,
  isValid = true,
}: ActionsProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex gap-x-2.5',
        variant === 'dialog' ? 'pt-5' : 'pt-4',
        buttonsPosition === 'center' ? 'justify-center' : 'justify-end'
      )}
    >
      {!isConfirmLoading && (
        <ButtonOutlined size={buttonSize} onClick={() => onCancel?.()}>
          <span className="first-letter:capitalize">{onCancelLabel ?? t('cancel')}</span>
        </ButtonOutlined>
      )}
      <ButtonFilled
        dataTestId={dataTestId}
        size={buttonSize}
        onClick={() => onConfirm?.()}
        color={getConfirmationButtonColor(type)}
        disabled={!isValid}
        {...confirmationButtonProps}
      >
        {isConfirmLoading && <ModjoProgress color={ColorPalette.basicWhite} size={36} className="absolute" />}
        <span className={clsx('first-letter:capitalize', isConfirmLoading && 'opacity-0')}>
          {onConfirmLabel ?? (type === 'delete' ? t('delete') : t('save'))}
        </span>
      </ButtonFilled>
    </div>
  );
};

export { DialogBackdrop, DialogAnimatedPanel, ActionsFooter };
