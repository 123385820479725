import React, { useRef, useState, RefObject, useCallback, useLayoutEffect, useMemo } from 'react';

import { Action } from '@remix-run/router';
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
  OverlayScrollbarsComponentRef,
} from 'overlayscrollbars-react';
import { useLocation } from 'react-router-dom';

import { historyStore } from '@/stores/history.store';
import { cn } from '@/utils/utils';

const positionRestorations: Record<string, number> = {};

/**
 * Custom hook for scroll restoration.
 * @param ref - Reference to the OverlayScrollbars component.
 * @param enabled - Flag indicating whether scroll restoration is enabled. Default is false.
 */
function useScrollRestoration(ref: RefObject<OverlayScrollbarsComponentRef>, enabled = false) {
  const location = useLocation(); // memoize the key to prevent unnecessary rerenders
  const key = useMemo(() => location.pathname + location.search, [location.pathname, location.search]);
  const restore = useCallback(() => {
    // don't restore if the last action wasn't a pop ( back button of browser for exemple )
    // don't restore if the scroll element is not ready to be scrolled to
    // don't restore if we have no value to restore to
    if (historyStore.getLastAction() === Action.Pop && enabled && ref.current && positionRestorations[key]) {
      const el = ref.current.osInstance()?.elements().viewport;
      // if element is not present it's because it's not mounted
      if (el) {
        el.scrollTop = positionRestorations[key];
        delete positionRestorations[key];
      }
    }
  }, [enabled, ref, key]);
  useLayoutEffect(() => {
    restore();
    const scrollElement = ref.current?.osInstance()?.elements().viewport;
    // on route changes, the scroll position will be saved during the clean up function
    return () => {
      if (scrollElement && !positionRestorations[key]) {
        positionRestorations[key] = scrollElement.scrollTop ?? 0;
      }
    };
  }, [key, ref, enabled, restore]);
}

export const ScrollArea = (props: Omit<OverlayScrollbarsComponentProps, 'ref'>) => {
  const ref = useRef<OverlayScrollbarsComponentRef>(null);
  const [scrollBarInitialized, setScrollBarInitialized] = useState(false);
  useScrollRestoration(ref, scrollBarInitialized);
  return (
    <OverlayScrollbarsComponent
      ref={ref}
      {...props}
      className={cn(props?.className, !scrollBarInitialized && 'opacity-0')}
      events={{
        // eslint-disable-next-line react/destructuring-assignment
        ...props.events,
        initialized: () => {
          setScrollBarInitialized(true);
        },
      }}
    />
  );
};
