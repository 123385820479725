import {
  addMilliseconds,
  addMinutes,
  compareAsc,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  Duration,
  format as _format,
  formatDistanceToNow as _formatDistanceToNow,
  FormatDistanceToNowOptions,
  intervalToDuration,
  isEqual,
  isFuture,
  isPast,
  isSameYear,
  isToday,
  secondsToMilliseconds,
  differenceInDays,
  parseISO,
  startOfDay,
  setDefaultOptions,
  FormatDistanceToken,
} from 'date-fns';
import { enUS, fr, zhCN } from 'date-fns/locale';

import { LanguageCode } from '@/enums/languages';
import { i18n } from '@/translation/i18n';

import { capitalizeFirstLetter } from './utils';

interface DateFormats {
  time: string;
  fullDateShort: string;
  fullDateShortWithHour: string;
  fullDateMedium: string;
  fullDateMediumWithoutYear: string;
  fullDateWithTime: string;
  fullDateWithTimeWithoutYear: string;
  fullDateLong: string;
}

const dateFormatByLocale: Record<string, DateFormats> = {
  // US formats
  en: {
    time: 'h:mm aaa',
    fullDateShort: 'MM/dd/yyyy',
    fullDateShortWithHour: 'MM/dd/yyyy - HH:MM',
    fullDateMedium: 'MMM d, yyyy',
    fullDateMediumWithoutYear: 'MMM d',
    fullDateWithTime: 'MMM d, yyyy, h:mm aa',
    fullDateWithTimeWithoutYear: 'MMM d, h:mm aa',
    fullDateLong: 'eeee, MMM d, yyyy',
  },
  fr: {
    time: 'HH:mm',
    fullDateShort: 'dd/MM/yyyy',
    fullDateShortWithHour: 'dd/MM/yyyy - HH:MM',
    fullDateMedium: 'dd MMM yyyy',
    fullDateMediumWithoutYear: 'dd MMM',
    fullDateWithTime: 'd MMM yyyy, HH:mm',
    fullDateWithTimeWithoutYear: 'd MMM, HH:mm',
    fullDateLong: 'eeee d MMM, yyyy',
  },
  zh: {
    time: 'HH:mm',
    fullDateShort: 'dd/MM/yyyy',
    fullDateShortWithHour: 'dd/MM/yyyy - HH:MM',
    fullDateMedium: 'd MMM yyyy',
    fullDateMediumWithoutYear: 'd MMM',
    fullDateWithTime: 'd MMM yyyy, HH:mm',
    fullDateWithTimeWithoutYear: 'd MMM, HH:mm',
    fullDateLong: 'eeee d MMM, yyyy',
  },
};

export const getDateFormatByLocale = () => {
  return dateFormatByLocale[i18n.language];
};

const HOURS_PER_DAY = 24;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE;
export const MILLISECONDS_PER_DAY = MILLISECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY;

export const currentYear = new Date().getFullYear();

export const changeDateFnsLocale = (language: LanguageCode) => {
  switch (language) {
    case LanguageCode.frFR: {
      setDefaultOptions({ locale: fr });
      break;
    }
    case LanguageCode.zhCN: {
      setDefaultOptions({ locale: zhCN });
      break;
    }
    default: {
      setDefaultOptions({ locale: enUS });
      break;
    }
  }
};

const getLocale = () => {
  const lang = i18n.language;
  switch (lang) {
    case 'en': {
      return enUS;
    }
    case 'fr': {
      return fr;
    }
    case 'zh': {
      return zhCN;
    }
    default: {
      return enUS;
    }
  }
};

/**
 * By default, date-fns uses the full word (minutes, hours, etc.).
 * So to overwrite the distance wording, we created this simple object to use the words we want.
 *
 * - The key matches the token returned by the function formatDistance.
 * - __{{count}}__ is the custom text replaced.
 */
const getformatDistanceLocale = (count: number): Record<FormatDistanceToken, string> => ({
  lessThanXSeconds: i18n.t('date.format.lessThanXSeconds', { count }),
  xSeconds: i18n.t('date.format.xSeconds', { count }),
  halfAMinute: i18n.t('date.format.halfAMinute', { count }),
  lessThanXMinutes: i18n.t('date.format.lessThanXMinutes', { count }),
  xMinutes: i18n.t('date.format.xMinutes', { count }),
  aboutXHours: i18n.t('date.format.aboutXHours', { count }),
  xHours: i18n.t('date.format.xHours', { count }),
  xDays: i18n.t('date.format.xDays', { count }),
  aboutXWeeks: i18n.t('date.format.aboutXWeeks', { count }),
  xWeeks: i18n.t('date.format.xWeeks', { count }),
  aboutXMonths: i18n.t('date.format.aboutXMonths', { count }),
  xMonths: i18n.t('date.format.xMonths', { count }),
  aboutXYears: i18n.t('date.format.aboutXYears', { count }),
  xYears: i18n.t('date.format.xYears', { count }),
  overXYears: i18n.t('date.format.overXYears', { count }),
  almostXYears: i18n.t('date.format.almostXYears', { count }),
});

export function formatDate(date: Date | string | number, format?: string): string {
  const locale = getLocale();
  return _format(new Date(date), format ?? getDateFormatByLocale().fullDateWithTime, { locale });
}

export const formatFromNowMini = (date: Date | string, { extension }: { extension?: string } = {}): string => {
  const now = new Date();
  const dateObj = new Date(date);
  const diffInSeconds = differenceInSeconds(now, dateObj);
  const formatWithDefault = getDateFormatByLocale().fullDateShort;
  const extensionWithDefault = extension || i18n.t('date.ago', { ns: 'common' });

  if (diffInSeconds < 60) {
    return `${Math.max(1, diffInSeconds)} sec ${extensionWithDefault}`;
  }

  const diffInMinutes = differenceInMinutes(now, dateObj);

  if (diffInMinutes < 60) {
    return `${Math.trunc(diffInMinutes)} min ${extensionWithDefault}`;
  }

  const diffInHours = differenceInHours(now, dateObj);

  if (diffInHours < 24) {
    return `${diffInHours} ${i18n.t('date.hour', { count: diffInHours })} ${extensionWithDefault}`;
  }

  return _format(dateObj, formatWithDefault);
};

export const formatTimestamp = (timestamp: number) => {
  const baseDate = new Date(0);
  const dateWithoutTimezoneDiff = addMinutes(baseDate, baseDate.getTimezoneOffset());
  const dateWithMilliseconds = addMilliseconds(dateWithoutTimezoneDiff, timestamp);
  if (timestamp < 3_600_000) {
    return _format(dateWithMilliseconds, 'm:ss');
  }
  return _format(dateWithMilliseconds, 'H:mm:ss');
};

export const formatLastUpdate = (date: Date) => {
  const dateFormat = isSameYear(date, Date.now())
    ? getDateFormatByLocale().fullDateMediumWithoutYear
    : getDateFormatByLocale().fullDateMedium;
  return _format(date, dateFormat, { locale: getLocale() });
};

const formatHour = (duration: Duration): string => {
  const min = duration.minutes ?? 0;
  if (min === 0) {
    return String(duration.hours);
  }
  return `${String(duration.hours)}:${min > 9 ? min.toString() : `0${min}`}`;
};

export const formatBoundary = (interval: number) => {
  const duration = intervalToDuration({ start: 0, end: secondsToMilliseconds(interval) });
  const startUnit: string =
    duration.hours! > 0
      ? i18n.t('date.hour', { count: duration.hours ?? 0 })
      : i18n.t('date.minute', { count: duration.minutes ?? 0 });
  const startString = startUnit.includes('minute') ? (duration.minutes ?? '') : formatHour(duration);
  const showedStartUnit = interval > 0 ? ` ${capitalizeFirstLetter(startUnit)}` : '';
  return `${startString.toString() + showedStartUnit}`;
};

/**
 * Returns a human-readable representation of the distance between the given date and the current date.
 * By default, the options are:
 * - addSuffix: true
 *
 * @param {Date | number | string} date The date to calculate the distance from.
 * @param {object} [options] An optional object containing formatting options.
 * @param {boolean} [options.addSuffix=true] Whether to add "ago" or "left" suffix to the result.
 * @returns {string} A human-readable representation of the distance between the given date and now.
 */
export const formatDistanceToNow = (
  date: Date | number | string,
  { addSuffix = true }: Pick<FormatDistanceToNowOptions, 'addSuffix'> = {}
): string => {
  return _formatDistanceToNow(date, {
    addSuffix,
    locale: {
      formatDistance: (token: FormatDistanceToken, count: number): string => {
        const value = getformatDistanceLocale(count)[token];
        if (addSuffix) {
          if (compareAsc(date, Date.now()) < 0) {
            return i18n.t('date.agoWithValue', {
              value,
            });
          }
          return i18n.t('date.leftWithValue', {
            value,
          });
        }
        return value;
      },
    },
  });
};

/**
 * Converts the duration in seconds into a string representation of hh:mm:ss.
 *
 * @param duration - The duration in seconds.
 * @returns The formatted duration string in the format "hh:mm:ss" or "mm:ss".
 *
 * @examples
 * formatDuration(3723) => "1:02:03"
 *
 * formatDuration(123) => "2:03"
 *
 * formatDuration(3) => "00:03"
 */
export const formatDuration = (duration: number, forcePad = false): string => {
  const hours = Math.floor(duration / SECONDS_PER_HOUR);
  const minutes = Math.floor((duration % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
  const seconds = Math.floor(duration % SECONDS_PER_MINUTE);
  const formattedSeconds = forcePad ? String(seconds).padStart(2, '0') : seconds <= 9 ? `0${seconds}` : seconds;
  const formattedMinutes = forcePad ? String(minutes).padStart(2, '0') : minutes <= 9 ? `0${minutes}` : minutes;
  if (hours > 0) {
    const formattedHours = forcePad ? String(hours).padStart(2, '0') : String(hours);
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  if (minutes > 0) {
    return forcePad
      ? `00:${String(minutes).padStart(2, '0')}:${formattedSeconds}`
      : `${String(minutes)}:${formattedSeconds}`;
  }
  return `00:${formattedSeconds}`;
};

/**
 * Parse the date if it's not already a Date object
 * @param {Date | number | string} date Date that can be in different types
 * @returns {Date | number}
 */
const parseDate = (date: Date | number | string | null | undefined): Date | number => {
  if (!date) {
    return 0;
  }
  return typeof date === 'string' ? parseISO(date) : date;
};

/**
 * Number of full days between two dates. Returns a positive number if endDate > startDate.
 * @param {Date | number | string} startDate Left date
 * @param {Date | number | string} endDate Right date
 * @returns {number} Difference in full days
 */
export function daysBetweenDates(startDate: Date | number | string, endDate: Date | number | string): number {
  if (!startDate || !endDate) {
    return Number.NaN;
  }
  return differenceInDays(parseDate(endDate), parseDate(startDate));
}

export const daysLeftUntilTargetDate = (
  targetDate: Date | number | string,
  rounding: 'up' | 'down' = 'down'
): number => {
  if (rounding === 'up') {
    return daysBetweenDates(Date.now(), targetDate) + 1;
  }
  return daysBetweenDates(Date.now(), targetDate);
};

export const daysPastSinceTargetDate = (targetDate: Date | number | string): number => {
  // Since daysLeftUntilTargetDate return a negative number when target date is past, we only need to return the opposite
  return -daysLeftUntilTargetDate(targetDate);
};

export const shortformatDuration = (milliseconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const { hours, minutes, seconds } = duration;
  if (hours && hours > 0) {
    return _format(new Date(0, 0, 0, hours, minutes ?? 0, seconds ?? 0), "k'h'mm", { locale: getLocale() });
  }
  return _format(new Date(0, 0, 0, 0, minutes ?? 0, seconds ?? 0), 'mm:ss', { locale: getLocale() });
};

export function areDaysEqual(dateLeft: Date | number, dateRight: Date | number): boolean {
  const midnightLeft = startOfDay(dateLeft);
  const midnightRight = startOfDay(dateRight);
  return isEqual(midnightLeft, midnightRight);
}

export function formatDistanceInDays(targetDate: Date | number | string, rounding: 'up' | 'down' = 'down'): string {
  const difference = daysLeftUntilTargetDate(targetDate, rounding);
  if (Number.isNaN(difference)) {
    return i18n.t('date.incorrect');
  }
  if (difference > 0) {
    return i18n.t('date.dayLeft', { count: difference });
  }
  return i18n.t('date.dayAgo', { count: Math.abs(difference) });
}

export function getDateStatus(date: Date | number): string {
  if (isPast(date)) {
    return i18n.t('date.past');
  }
  if (isToday(date)) {
    return i18n.t('today');
  }
  if (isFuture(date)) {
    return i18n.t('date.future');
  }
  return i18n.t('unknown');
}

export function roundUpMaxDuration(maxDuration: number): number {
  return maxDuration % 60 === 0 ? maxDuration : maxDuration + (60 - (maxDuration % 60));
}

export const isOlderThan24Hours = (date: Date) => {
  const currentDate = new Date();
  const hoursDifference = differenceInHours(currentDate, date);
  return hoursDifference > 24;
};

export const formatRange = (startDate: Date, endDate: Date) => {
  const { fullDateMediumWithoutYear, fullDateMedium } = getDateFormatByLocale();
  if (isSameYear(startDate, endDate)) {
    return `${_format(startDate, fullDateMediumWithoutYear, { locale: getLocale() })} - ${_format(endDate, fullDateMedium, { locale: getLocale() })}`;
  }
  return `${_format(startDate, fullDateMedium, { locale: getLocale() })} - ${_format(endDate, fullDateMedium)}`;
};
