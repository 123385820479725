export const AnalyticsPages = {
  CALLS: 'calls',
  INTERACTION: 'interaction',
  TOPICS: 'topics',
  ACTIVITIES: 'activities',
  REVIEWS: 'reviews',
  AI_SCORING: 'ai-scoring',
} as const;

export type AnalyticsPage = (typeof AnalyticsPages)[keyof typeof AnalyticsPages];

export function isAnalyticsPage(page: string): page is AnalyticsPage {
  return Object.values(AnalyticsPages).includes(page as AnalyticsPage);
}
