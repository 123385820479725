import React, { PropsWithChildren } from 'react';

import { Drawer } from 'vaul';

type SelectMobileDrawerWrapperProps = PropsWithChildren<{
  title?: string | React.ReactNode;
}>;

export const SelectMobileDrawerWrapper = ({ title, children }: SelectMobileDrawerWrapperProps) => {
  return (
    <Drawer.Portal>
      <Drawer.Overlay className="fixed inset-0 bg-lavender bg-opacity-40 backdrop-blur-[1px]" />
      <Drawer.Content className="fixed inset-x-0 bottom-0 top-auto flex max-h-80 flex-col rounded-t-2lg bg-grey-50 p-4">
        <>
          {title ? <Drawer.Title className="px-2 py-4 font-semibold">{title}</Drawer.Title> : <Drawer.Title />}
          {children}
        </>
      </Drawer.Content>
    </Drawer.Portal>
  );
};
