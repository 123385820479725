import React from 'react';

import { useChat } from 'ai/react';
import mixpanel from 'mixpanel-browser';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { apiService, apiURL } from '@/api.service';
import { RightPanel } from '@/components/common/ui-components/layout/right-panel';
import { RightPanelHeader } from '@/components/common/ui-components/layout/right-panel/RightPanelHeader';
import { dealViewStore } from '@/components/deals/DealView/deal-view.store';
import { useDealActivityCountsByTypeQuery } from '@/entities/activities/activities.selects';
import { useDealAskAnythingStatQuery, useDealInfoQuery } from '@/entities/deals/deals.queries';
import QuestionIcon from '@/static/icons/alerte/question-Line.svg?react';
import { Event } from '@/tracking/events';
import { getHelpCenterUrl } from '@/utils/help-center-utils';

import { AskAnythingContent } from './AskAnythingContent';
import { askAnythingStore } from './askAnythingStore';

type AskAnythingPanelProps = {
  showDealNameInTitle?: boolean;
};

export const AskAnythingPanel = ({ showDealNameInTitle }: AskAnythingPanelProps) => {
  const { t } = useTranslation('deals');
  const sidebarWidth = dealViewStore.useRightPanelWidth();
  const isPanelOpen = askAnythingStore.useIsRightPanelOpen();
  const dealId = askAnythingStore.useDealId();
  const dealQuery = useDealInfoQuery(dealId, {
    queryOptions: { enabled: showDealNameInTitle && !!dealId },
  });

  const dealActivityCountsByTypeQuery = useDealActivityCountsByTypeQuery({
    dealIds: [dealId],
  });
  const hasCallInteraction = (dealActivityCountsByTypeQuery.data?.call ?? 0) > 0;
  const askAnythingStatQuery = useDealAskAnythingStatQuery({
    dealId,
    queryOptions: { enabled: hasCallInteraction && isPanelOpen },
  });
  const { input, messages, handleInputChange, setInput, handleSubmit, isLoading, setMessages } = useChat({
    fetch: apiService.authService.customFetch,
    api: `${apiURL}/ai-chat/deals/${dealId}`,
  });

  const handleClosePanel = () => {
    setInput('');
    mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_SIDEPANEL, {
      isOpen: false,
    });
    askAnythingStore.setIsRightPanelOpen(false);
  };

  if (askAnythingStatQuery.isError) {
    return null;
  }

  const title = showDealNameInTitle ? (
    <Trans i18nKey="dealList.askMeAnything.sidePanelTitle" values={{ name: dealQuery.data?.name ?? '' }} ns="deals" />
  ) : (
    <strong>{t('dealView.askMeAnything.sidePanelTitle')}</strong>
  );

  return (
    <RightPanel
      header={
        <RightPanelHeader onClick={handleClosePanel} className="flex w-full items-center justify-between font-normal">
          <span className="line-clamp-1">{title}</span>
          <Link
            to={getHelpCenterUrl('/articles/9680149')}
            target="_blank"
            rel="noreferrer"
            className="flex-center gap-x-1 whitespace-nowrap text-sm text-blue"
          >
            <>
              <QuestionIcon className="icon-small fill-blue align-text-bottom" />
              {t('learnMore', {
                ns: 'common',
              })}
            </>
          </Link>
        </RightPanelHeader>
      }
      open={isPanelOpen}
      onClickAway={handleClosePanel}
      width={sidebarWidth}
    >
      <div className="flex h-full min-h-0 flex-col justify-between">
        {dealId && hasCallInteraction && isPanelOpen && (
          <AskAnythingContent
            dealId={dealId}
            messages={messages}
            onSubmit={handleSubmit}
            input={input}
            onInputChange={handleInputChange}
            isLoading={isLoading}
            setInput={setInput}
            setMessages={setMessages}
          />
        )}
      </div>
    </RightPanel>
  );
};
