import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { ApiNote, Note } from '@/entities/notes-calls/notes-calls.types';
import { queryKeys } from '@/entities/queryKeys';
import { useCallURLInfos } from '@/hooks/useCallURLInfos';
import { CustomQueryOptions } from '@/types/common';

import { CALLS_API_BASE_URL } from '../calls/calls.constants';

const PUBLIC_NOTES_API_BASE_URL = '/public/calls/notes';

export type NotesCallsParams = {
  callId: number;
};

type PublicNotesCallsParams = {
  tenantName: string;
  uuid: string;
};

export type NotesCallsQueryParams<T = ApiNote[]> = (
  | {
      isPublic: true;
      queryParams: PublicNotesCallsParams;
    }
  | {
      isPublic: false;
      queryParams: NotesCallsParams;
    }
) &
  CustomQueryOptions<ApiNote[], T>;

export const useCallNotesQueryKey = () => {
  const infos = useCallURLInfos();
  if (infos.isPublic) {
    return queryKeys.publicNotesCalls.tenantNameAndUuid(infos.tenantName, infos.uuid);
  }
  return queryKeys.notesCalls.callId(infos.callId);
};

export const useNotesCallsQuery = <TSelectData = ApiNote[]>({
  isPublic,
  queryParams,
  queryOptions,
}: NotesCallsQueryParams<TSelectData>) => {
  const queryKey = useCallNotesQueryKey();
  return useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiNote[]>(
        `${isPublic ? `${PUBLIC_NOTES_API_BASE_URL}` : `${CALLS_API_BASE_URL}/${queryParams.callId}/notes`}`,
        {
          params: isPublic ? queryParams : undefined,
        }
      );
      return data;
    },
    ...queryOptions,
  });
};

export const useNotesAssociationsCallsQuery = (searchTerm: string) => {
  return useQuery({
    queryKey: queryKeys.notesCalls.associationsSearch(searchTerm),
    queryFn: async () => {
      if (searchTerm && searchTerm.length > 2) {
        const { data } = await apiService.API.get<Note['call'][]>('/calls/note-associations/search', {
          params: { q: searchTerm },
        });
        return data;
      }
      const { data } = await apiService.API.get<Note['call'][]>('/calls/note-associations/last');
      return data;
    },
    placeholderData: (value) => value,
  });
};

export const useNotesAssociationCallsQuery = (callId: number) => {
  return useQuery({
    queryKey: queryKeys.notesCalls.associationCallId(callId),
    queryFn: async () => {
      const { data } = await apiService.API.get<Note['call']>(`calls/${callId}/note-association`);
      return data;
    },
  });
};
