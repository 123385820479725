import { useEffect } from 'react';

import { secondsToMilliseconds } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useDealWarningSetupRecomputationStatusQuery } from '@/entities/deal-warnings/deal-warnings.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { UserRole } from '@/enums';
import { LOCALSTORAGE_DEAL_WARNINGS_SETUP_KEY } from '@/enums/localstorage-keys';

import { useHideModjoDeals } from '../useHideModjoDeals';

export const useShowDealWarningSetupBanner = () => {
  const { id: dealId } = useParams<{ id: string }>();
  const currentUser = useCurrentUser();
  const hideModjoDeals = useHideModjoDeals();
  const [hasSetupDealWarnings, setValue] = useLocalStorage<string>(LOCALSTORAGE_DEAL_WARNINGS_SETUP_KEY, undefined);
  const isDealsList = !dealId;
  const dealWarningSetupRecomputationStatusQuery = useDealWarningSetupRecomputationStatusQuery({
    queryOptions: {
      enabled: isDealsList && currentUser?.role === UserRole.ADMIN && !hideModjoDeals && !!hasSetupDealWarnings,
      staleTime: secondsToMilliseconds(15),
    },
  });

  useEffect(() => {
    if (isDealsList && hasSetupDealWarnings && dealWarningSetupRecomputationStatusQuery.data?.total === 0) {
      setValue(undefined);
    }
  }, [dealWarningSetupRecomputationStatusQuery.data, hasSetupDealWarnings, isDealsList, setValue]);

  return isDealsList && (dealWarningSetupRecomputationStatusQuery.data?.total ?? 0) > 0;
};
