import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomQueryOptions } from '@/types/common';

import { mapApiViewToView } from './views.mappers';
import { ApiView, VIEWS_API_BASE_URL, View } from './views.types';
import { queryKeys } from '../queryKeys';

export const useViewsQuery = <TSelectData = View[]>(options?: CustomQueryOptions<View[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.views.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiView[]>(VIEWS_API_BASE_URL);
      return data?.map((view) => mapApiViewToView(view));
    },
    ...options?.queryOptions,
  });
};
