import React, { CSSProperties, ElementType, PropsWithChildren, useRef } from 'react';

import { useIsTextOverflowing } from '@/utils/useIsTextOverflowing';
import { cn } from '@/utils/utils';

import { MaybeInATooltip } from './tooltip/MaybeInATooltip';
import { TooltipProps } from './tooltip/types';

export type EllipsedTextProps = PropsWithChildren<{
  as?: ElementType;
  className?: string;
  style?: CSSProperties;
  dataTestId?: string;
  tooltipProps?: Omit<TooltipProps, 'content'>;
}>;

const EllipsedText = ({
  children,
  as: TextComponent = 'p',
  style,
  className,
  dataTestId,
  tooltipProps = { placement: 'bottom', variant: 'small' },
}: EllipsedTextProps) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const isTextOverFlowing = useIsTextOverflowing(ref);

  return (
    <MaybeInATooltip
      {...tooltipProps}
      className="truncate"
      content={ref.current?.textContent}
      withTooltip={isTextOverFlowing}
    >
      <TextComponent ref={ref} className={cn('truncate block', className)} data-test-id={dataTestId} style={style}>
        {children}
      </TextComponent>
    </MaybeInATooltip>
  );
};

export { EllipsedText };
