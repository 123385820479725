import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { EllipsedText } from '@/components/common/ui-components/data-display/EllipsedText';
import { updateParams, useUrlFilters } from '@/components/explore/useUrlFilters';
import { useViewById, useViewsByType } from '@/entities/views/views.hooks';
import { RoutePaths } from '@/enums';
import { EventOrigin } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';
import { isEmpty } from '@/utils/utils';

import { BaseBreadcrumb, trackBreadcrumbClickEvent } from './BaseBreadcrumb';
import { ViewsDropdown } from './ViewsDropdown';

type BreadcrumbProps = {
  deal?: {
    id: number;
    name: string;
  };
  callName?: string;
  pageOrigin: EventOrigin;
};

export const DealsBreadcrumb = ({ deal, callName, pageOrigin }: BreadcrumbProps) => {
  const { t } = useTranslation();
  const dealViews = useViewsByType('deals');
  const { viewId } = useUrlFilters();
  const currentView = useViewById(viewId);

  // If there is no deal, we are on the deals list page
  if (!deal) {
    const firstLevel = isEmpty(dealViews) ? (
      <span className="font-semibold first-letter:uppercase">{t('deals')}</span>
    ) : (
      <ViewsDropdown viewType="deals" />
    );

    return <BaseBreadcrumb levels={[firstLevel]} dataTestId={DataTestId.DEAL_BREADCRUMB_ID} />;
  }

  // If there is a deal, we are on the deal view page or in call-details
  const firstLevel = currentView ? (
    <Link
      to={{
        pathname: RoutePaths.DEALS,
        search: updateParams(new URLSearchParams(), {
          ...currentView.filters,
          ...currentView.sorting,
          ...currentView.viewConfig,
          viewId: currentView.id,
        }).toString(),
      }}
      className="font-semibold"
      onClick={() => trackBreadcrumbClickEvent(pageOrigin, EventOrigin.DEALS_LIST)}
    >
      <EllipsedText>{currentView.label}</EllipsedText>
    </Link>
  ) : (
    <Link
      to={RoutePaths.DEALS}
      className="font-semibold first-letter:uppercase"
      onClick={() => trackBreadcrumbClickEvent(pageOrigin, EventOrigin.DEALS_LIST)}
      data-test-id={DataTestId.CALL_DETAILS_DEALS_BREADCRUMB_ROOT_ID}
    >
      {t('deals')}
    </Link>
  );

  const secondLevel = callName ? (
    <EllipsedText>
      <Link
        to={generatePath(RoutePaths.DEAL_VIEW, { id: deal.id.toString() })}
        className="font-semibold"
        onClick={() => trackBreadcrumbClickEvent(pageOrigin, EventOrigin.DEAL_VIEW)}
        data-test-id={DataTestId.CALL_DETAILS_DEALS_BREADCRUMB_DEAL_ID}
      >
        {deal.name}
      </Link>
    </EllipsedText>
  ) : (
    <EllipsedText>{deal.name}</EllipsedText>
  );

  const thirdLevel = callName && <EllipsedText>{callName}</EllipsedText>;

  return <BaseBreadcrumb levels={[firstLevel, secondLevel, thirdLevel]} dataTestId={DataTestId.DEAL_BREADCRUMB_ID} />;
};
