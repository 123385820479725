import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import mixpanel from 'mixpanel-browser';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DateSelectInline } from '@/components/common/DateSelectInline';
import { FormAutocomplete } from '@/components/common/form/FormAutoComplete';
import { FormInput } from '@/components/common/form/FormInput';
import { FormMultiComboboxWithChips } from '@/components/common/form/FormMultiComboboxWithChips';
import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { useDialog } from '@/components/common/ui-components/dialogs';
import { ActionsFooter } from '@/components/common/ui-components/dialogs/Common';
import { SelectOption } from '@/components/common/ui-components/types';
import { useUploadCallMutation, useUploadCallFileToS3BucketMutation } from '@/entities/calls/calls.mutations';
import { ApiUploadCall } from '@/entities/calls/calls.types';
import { useSearchContactQuery } from '@/entities/contact/contact.queries';
import { useTagsOptions } from '@/entities/tags/tags.hooks';
import { useLicensedUsers, useCurrentUser, useActiveUsers } from '@/entities/users/users.hooks';
import { CurrentUser } from '@/entities/users/users.types';
import { DIALOGS_IDS, scrollbarDefaultOptions } from '@/enums/constants';
import ArrowLeftIcon from '@/static/icons/arrows/arrow-left-Line.svg?react';
import { Event } from '@/tracking/events';
import { Providers } from '@/types/providers';
import { isNumeric, toNumber } from '@/utils/utils';
import { numberOrStringEmailArray } from '@/utils/yup-utils';

import { SEARCH_DEBOUNCE_TIME_IN_MS } from './meeting-recorder.constants';
import { meetingRecorderStore } from './meeting-recorder.store';
import { UploadCallValues } from './meeting-recorder.types';
import { useMeetingRecorderFileRequest, useMeetingRecorderTimestampRequest } from './meeting-recorder.utils';
import { MeetingRecorderImportDeleteMobile } from './MeetingRecorderImportDeleteMobile';

const getInitialUserValue = (currentUser?: CurrentUser) => {
  if (!currentUser?.hasLicense) {
    return null;
  }
  return currentUser.id;
};

export const MeetingRecorderImport = () => {
  const { t } = useTranslation('home');
  const uploadCallMutation = useUploadCallMutation();
  const { closeDialog } = useDialog();
  const currentUser = useCurrentUser();
  const tagOptions = useTagsOptions({ withAllTags: false });
  const [contactQuery, setContactQuery] = React.useState<string>('');
  const searchContactQuery = useSearchContactQuery(contactQuery);
  const isDeleting = meetingRecorderStore.useIsDeleting();
  const fileName = meetingRecorderStore.useCurrentFileName();
  const contactIds = meetingRecorderStore.useContactIds();
  const clientOptions = meetingRecorderStore.useClientIds();
  const [stateClientsOptions, setStateClientsOptions] = React.useState<SelectOption[]>(clientOptions ?? []);
  const uploadCallFileToS3BucketMutation = useUploadCallFileToS3BucketMutation();
  const fileTimestamp = useMeetingRecorderTimestampRequest();
  const fileToSend = useMeetingRecorderFileRequest();
  const validationSchema = yup.object().shape({
    userId: yup.number().required().nullable(),
    date: yup.date().required(),
    contactIds: yup.array().of(yup.number().required()).required(),
    clientsIds: numberOrStringEmailArray(
      t('meetingRecorder.importCallPanel.form.clients.validation', {
        ns: 'common',
      }),
      t('required', { ns: 'common' })
    )
      .required()
      .min(1),
    tagIds: yup.array().of(yup.string().required()).required(),
    name: yup.string().required(),
  });
  const methods = useForm<UploadCallValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userId: getInitialUserValue(currentUser),
      date: fileTimestamp.data ? new Date(fileTimestamp.data) : new Date(),
      contactIds: contactIds ?? [],
      clientsIds: clientOptions?.map((client) => Number(client.id)) ?? [],
      name: fileName ?? '',
      tagIds: [],
    },
  });
  const licensedUsers = useLicensedUsers();
  const activeUsers = useActiveUsers();

  const clientsOptions = useMemo((): SelectOption[] => {
    const filteredStateClientsOptions = stateClientsOptions.filter(
      (client) => !searchContactQuery.data?.find((contact) => contact.id === toNumber(client.id))
    );
    const isNewContact = !filteredStateClientsOptions.some((client) => client.id === contactQuery);
    return [
      // This allows the user to input a new contact that is not in the list
      ...filteredStateClientsOptions,
      ...(isNewContact && contactQuery ? [{ id: contactQuery, label: contactQuery }] : []),
      ...(searchContactQuery.data?.map((contact) => ({
        id: String(contact.id),
        label: contact.name,
      })) ?? []),
    ];
  }, [contactQuery, searchContactQuery.data, stateClientsOptions]);

  if (licensedUsers.length === 0 || fileToSend.data === null || fileToSend.data === undefined) {
    return null;
  }

  const onSubmit = (values: UploadCallValues) => {
    const colleagues = values.contactIds.reduce((acc: string[], contactId) => {
      const userFound = activeUsers.find((activeUser) => activeUser.id === contactId);
      if (userFound) {
        acc.push(userFound.email);
      }
      return acc;
    }, []);

    const clients = stateClientsOptions.map((client) => {
      if (client.id === client.label) {
        return isNumeric(client.label) ? toNumber(client.label) : client.label;
      }

      return toNumber(client.id);
    });

    const mappedContacts = [...clients, ...colleagues];
    const metadata: ApiUploadCall = {
      users: values.userId ? [toNumber(values.userId)] : [],
      contacts: mappedContacts,
      date: values.date.toISOString(),
      fileExtension: fileName?.split('.').pop() ?? '.wav',
      name: values.name,
      provider: Providers.MODJO_PHYSICAL_RECORDER,
      tags: values.tagIds.map((tagId) => ({ modjoTagId: toNumber(tagId) })),
    };

    uploadCallMutation.mutate(metadata, {
      onSuccess: (signedUrl) => {
        if (!fileToSend.data) {
          return;
        }
        mixpanel.track(Event.IMPORT_RECORDING);
        uploadCallFileToS3BucketMutation.mutate({ signedUrl, file: fileToSend.data });
        methods.reset();
        meetingRecorderStore.reset();
        closeDialog(DIALOGS_IDS.RECORDER_DIALOG);
      },
    });
  };

  const handleTextFieldChange = debounce((value: string) => {
    setContactQuery(value);
  }, SEARCH_DEBOUNCE_TIME_IN_MS);

  const handleConfirmDelete = () => {
    meetingRecorderStore.reset();
    meetingRecorderStore.setIsDeleting(false);
    mixpanel.track(Event.DELETE_RECORDING);
  };

  return (
    <div className="flex h-full flex-col justify-between">
      {isDeleting && !isMobile ? (
        <div className="flex flex-col items-center gap-y-2 p-4">
          <span className="text-lg font-semibold">
            {t('meetingRecorder.recordingDelete.title', {
              ns: 'common',
            })}
          </span>
          <p className="text-center text-sm">
            {t('meetingRecorder.recordingDelete.description', {
              ns: 'common',
            })}
          </p>
          <ActionsFooter
            type="delete"
            buttonsPosition="center"
            onCancel={() => {
              meetingRecorderStore.setIsDeleting(false);
            }}
            onConfirm={handleConfirmDelete}
          />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OverlayScrollbarsComponent
              className="flex max-h-[70vh] min-h-[70vh] w-full p-4"
              options={scrollbarDefaultOptions}
            >
              {!isDeleting && (
                <div className="mb-4">
                  <div className="mb-4 flex items-center justify-start gap-x-4">
                    <ArrowLeftIcon
                      className={clsx('size-4 cursor-pointer', isMobile && 'size-6')}
                      onClick={() => {
                        meetingRecorderStore.setIsDeleting(true);
                      }}
                    />
                    <span className="text-xl font-semibold">
                      {t('meetingRecorder.importCallPanel.title', {
                        ns: 'common',
                      })}
                    </span>
                  </div>
                  <span className="text-grey-800">
                    {t('meetingRecorder.importCallPanel.description', {
                      ns: 'common',
                    })}
                  </span>
                </div>
              )}

              <FormInput name="name" label={t('uploadCall.dialog.form.fields.name.label')} fullWidth />
              <FormMultiComboboxWithChips
                name="tagIds"
                label={t('meetingRecorder.importCallPanel.form.meetingTags.label', {
                  ns: 'common',
                })}
                options={tagOptions}
              />
              <FormAutocomplete
                name="userId"
                label={t('uploadCall.dialog.form.fields.userId.label')}
                placeholder={t('uploadCall.dialog.form.fields.userId.placeholder')}
                options={licensedUsers.map(({ id, fullName }) => ({ id: id.toString(), label: fullName }))}
              />
              <FormMultiComboboxWithChips
                name="contactIds"
                label={t('meetingRecorder.importCallPanel.form.colleagues.label', {
                  ns: 'common',
                })}
                options={activeUsers.map(({ id, fullName }) => ({ id: id.toString(), label: fullName }))}
              />
              <FormMultiComboboxWithChips
                name="clientsIds"
                label={t('meetingRecorder.importCallPanel.form.clients.label', {
                  ns: 'common',
                })}
                onInputChange={(query) => {
                  handleTextFieldChange(query);
                }}
                onChange={(items) => {
                  setStateClientsOptions(items);
                  if (items.length === 0) {
                    methods.setValue('clientsIds', [], { shouldValidate: true, shouldTouch: true });
                    return;
                  }
                  const clientsIdsTmp = methods.getValues('clientsIds');
                  const tmpIds: (number | string)[] =
                    items.length > 0
                      ? clientsIdsTmp.filter((clientId) => items.map((item) => item.id).includes(clientId.toString()))
                      : [];

                  for (const item of items) {
                    const contact = clientsOptions.find(({ id }) => id === item.id);
                    if (contact) {
                      const formatNumericString = isNumeric(item.id) ? toNumber(item.id) : item.id;
                      tmpIds.push(formatNumericString);
                    } else {
                      tmpIds.push(item.id);
                    }
                    methods.setValue('clientsIds', tmpIds, { shouldValidate: true, shouldTouch: true });
                  }
                }}
                options={clientsOptions}
              />
              <DateSelectInline
                id="date"
                value={fileTimestamp.data ?? new Date()}
                label={t('uploadCall.dialog.form.fields.date.label')}
                max={Date.now()}
                onChange={() => {}}
                disabled
              />
            </OverlayScrollbarsComponent>
            <div>
              {isMobile ? (
                <ButtonFilled fullWidth type="submit" isLoading={uploadCallMutation.isPending}>
                  {t('meetingRecorder.importCallPanel.title', {
                    ns: 'common',
                  })}
                </ButtonFilled>
              ) : (
                <div className="flex w-full justify-end px-4">
                  <ButtonFilled type="submit" isLoading={uploadCallMutation.isPending}>
                    {t('meetingRecorder.importCallPanel.title', {
                      ns: 'common',
                    })}
                  </ButtonFilled>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      )}
      {isMobile && <MeetingRecorderImportDeleteMobile onConfirmDelete={handleConfirmDelete} />}
    </div>
  );
};
