import { useCallback } from 'react';

import { groupBy } from '@/utils/array-utils';

import { useViewsQuery } from './views.queries';
import { View, ViewType } from './views.types';

const useViewsByType = (type: ViewType) => {
  const viewsQuery = useViewsQuery({
    queryOptions: {
      select: useCallback(
        (data: View[]) =>
          groupBy(
            // We need to filter out views without filters because they are
            // the general views we created for the purpose of deal view digests
            data.filter(({ filters }) => filters),
            (view) => view.type
          ),
        []
      ),
    },
  });
  return viewsQuery.data?.get(type) ?? [];
};

const useViewById = (viewId?: View['id']) => {
  const viewsQuery = useViewsQuery({
    queryOptions: {
      select: useCallback((views: View[]) => views.find((view) => view.id === viewId), [viewId]),
    },
  });
  return viewsQuery.data;
};

export { useViewsByType, useViewById };
