import React, { SyntheticEvent, useMemo } from 'react';

import { captureException } from '@sentry/core';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { Tooltip } from '@/components/common/ui-components/data-display/tooltip';
import { useToggleNotificationStatusMutation } from '@/entities/notifications/notifications.mutations';
import { Notification } from '@/entities/notifications/notifications.types';
import NotificationCheck from '@/static/icons/alerte/notification-indicator-Line.svg?react';
import NotificationUncheck from '@/static/icons/basic/checkbox-rec-Line.svg?react';
import { twThemeColors } from '@/theme/colors';
import { Event, EventOrigin } from '@/tracking/events';
import { formatDistanceToNow } from '@/utils/date-utils';
import { cutStringWithSuffix, toNumber } from '@/utils/utils';

import { serializeUserNotification } from './notification.helper';
import { NotificationIcon } from './NotificationIcon';
import { createNotificationDescription } from './NotificationTransformer';
import { ConditionalWrapper } from '../../../common/ConditionalWrapper';
import { NotificationStatus, NotificationType, NotificationTypes } from '../notifications-enums';

type NotificationItemProps = {
  notification: Notification<NotificationType>;
  onClick: () => void;
};

const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
  const { t } = useTranslation();
  const { id, title, action, date } = notification;

  const toggleNotificationMutation = useToggleNotificationStatusMutation();

  const [callId, path] = useMemo(() => {
    if (!action) {
      return [];
    }
    const actionUrl = new URL(action);
    const callDetailsPathname = actionUrl?.pathname;
    const _callId = callDetailsPathname.split('/')[2] ?? null;
    if (!_callId) {
      return [];
    }
    return [toNumber(_callId), callDetailsPathname + actionUrl.search];
  }, [action]);

  const onNotificationClick = (event: React.MouseEvent) => {
    if (callId) {
      mixpanel.track(Event.GO_TO_CALL_DETAILS, {
        callId,
        clickOrigin: EventOrigin.NOTIFICATIONS,
      });
    }
    // we don't want to close the notication panel if the user is opening the notification in a new tab
    if (!event.metaKey && !event.ctrlKey) {
      onClick();
    }
  };

  const isNotificationRead = notification.status === NotificationStatus.READ;

  const description = createNotificationDescription(notification);
  const serializedUserNotification = serializeUserNotification(notification);

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    toggleNotificationMutation.mutate({
      notificationId: id,
      status: isNotificationRead ? NotificationStatus.SENT : NotificationStatus.READ,
    });
  };

  return (
    <ConditionalWrapper
      condition={notification.type === NotificationTypes.DOWNLOAD}
      truthyWrapper={(children) => (
        <a
          onClick={() => {
            action &&
              toggleNotificationMutation.mutate(
                {
                  notificationId: id,
                  status: NotificationStatus.READ,
                },
                {
                  onSuccess: onClick,
                }
              );
          }}
          href={action}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: twThemeColors.grey[300] }}
        >
          {children}
        </a>
      )}
      falsyWrapper={(children) => {
        if (!path) {
          captureException(new Error('broken notification'), { extra: { notification } });
          return <div />;
        }
        return (
          <Link to={path} onClick={onNotificationClick}>
            {children}
          </Link>
        );
      }}
    >
      <div
        className={clsx(
          'group relative flex cursor-pointer items-start justify-start gap-x-2 rounded-lg border border-grey-100 p-2.5',
          !isNotificationRead && 'shadow-card hover:shadow-card-hover'
        )}
      >
        <NotificationIcon
          notificationType={notification.type}
          isNotificationRead={isNotificationRead}
          serializedUserNotification={serializedUserNotification}
        />
        <div className="flex w-4/5 flex-col">
          {description && (
            <span className={clsx('my-1 w-full text-xs', isNotificationRead ? 'text-grey-700' : 'text-grey-900')}>
              {description}
            </span>
          )}
          <span
            className={clsx(
              'w-full min-w-0 max-w-fit break-words font-semibold',
              isNotificationRead ? 'text-grey-700' : 'text-grey-900'
            )}
          >
            {cutStringWithSuffix(title, 70)}
          </span>
          {date && (
            <span className={clsx('text-xs', isNotificationRead ? 'text-grey-700' : 'text-blue')}>
              {formatDistanceToNow(date)}
            </span>
          )}
        </div>
        <Tooltip
          content={isNotificationRead ? t('navbar.notifications.markAsUnread') : t('navbar.notifications.markAsRead')}
          placement="left"
          variant="small"
          offsetX={-10}
          className={clsx(
            !isMobile && 'invisible group-hover:visible',
            isMobile && isNotificationRead && 'fill-grey-700'
          )}
        >
          <IconButtonGhost
            onClick={handleClick}
            icon={
              isNotificationRead ? (
                <NotificationCheck className="icon-small" />
              ) : (
                <NotificationUncheck className="icon-small" />
              )
            }
            className="absolute -right-1 -top-1 z-50 flex items-center justify-center rounded border border-grey-100 bg-grey-white p-1 hover:bg-grey-200 hover:shadow-card"
          />
        </Tooltip>
        <div
          className={clsx(
            'absolute right-2.5 top-2.5 z-50 size-2 rounded-full border-2 border-blue bg-blue',
            isNotificationRead ? 'invisible' : 'visible group-hover:invisible',
            isMobile && 'invisible'
          )}
          onClick={handleClick}
          aria-hidden="true"
        />
      </div>
    </ConditionalWrapper>
  );
};

NotificationItem.displayName = 'NotificationItem';

export { NotificationItem };
