export enum CallMediaType {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum CallImportStatus {
  TO_DOWNLOAD = 'to_download',
  DOWNLOAD_IN_PROGRESS = 'download_in_progress',
  DOWNLOADED = 'downloaded',
  TRANSCRIPTION_IN_PROGRESS = 'transcription_in_progress',
  COMPLETED = 'completed',
  FAILED_ONE_SPEAKER = 'failed_one_speaker',
  FAILED_EMPTY = 'failed_empty',
  FAILED_NO_SPEAKERS_NO_CHANNELS = 'failed_no_speakers_no_channels',
}

export const isCompletedOrFailedOneSpeaker = (status: CallImportStatus) =>
  status === CallImportStatus.COMPLETED || status === CallImportStatus.FAILED_ONE_SPEAKER;

export const isCallNotDownloaded = (status: CallImportStatus) =>
  [CallImportStatus.TO_DOWNLOAD, CallImportStatus.DOWNLOAD_IN_PROGRESS].includes(status);

export enum DateInterval {
  WEEK = 'week',
  DAY = 'day',
}

export enum CallInfos {
  USER = 'User',
  CONTACT = 'Contact',
  ACCOUNT = 'Account',
  DEAL = 'Deal',
  JOB_TITLE = 'Job title',
}

export enum DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const ResponseCode: { [key: number]: string } = {
  200: 'Success',
  201: 'Created',
  204: 'No content',
  304: 'Not modified',
  400: 'Oops something went wrong! Bad Request',
  401: 'Oops something went wrong! Unauthenticated',
  403: 'Oops something went wrong! Forbidden',
  404: 'Oops something went wrong! Not found',
  500: 'Oops something went wrong! Server Error',
};

export enum RoutePaths {
  DEFAULT = '/',
  WILD_CARD = '*',
  UNAUTHORIZED = '/unauthorized',
  EXPIRED_FREE_TRIAL = '/expired-free-trial',
  HOME = '/home',
  HOME_PAGES = '/home/:page',
  LOGIN = '/login',
  SETUP_PASSWORD = '/password',
  RESET_PASSWORD = '/password/reset',
  RESET_PASSWORD_CALLBACK = '/password/reset/callback',
  POST_SIGN_ON_DISPATCHER = '/auth/post-sign-on',
  ONBOARDING = '/auth/onboarding',
  PUBLIC_CALL = '/call/:publicToken',
  LAST_TRANSCRIBE_CALL = '/last-transcribe',
  CALL_DETAILS = '/call-details/:id',
  EXPLORE = '/explore',
  DEALS = '/deals',
  DEAL_VIEW = '/deals/:id',
  REDIRECT_CRM_DEAL_TO_CRM_VIEW = '/:crm/deal/:crmId',
  DEAL_CALL_VIEW = '/deals/:dealId/call/:callId',
  SETTINGS = '/settings',
  SETTINGS_PROFILE = '/settings/profile',
  MODJO_EXTENSION_SETTINGS = '/settings/modjo-extension',
  // Route used for the nta layout
  LIVENOTES_SETTINGS_LAYOUT = '/settings/nta/*',
  LIVENOTES_NOTES_LIST = '/notes',
  LIVENOTES = '/settings/livenotes',
  LIVENOTES_TEMPLATE_LIST = '/settings/livenotes/templates',
  LIVENOTES_TEMPLATE_CREATION = '/settings/livenotes/templates/create',
  LIVENOTES_TEMPLATE_VIEW = '/settings/livenotes/templates/:templateId',
  /**
   * @deprecated use LIVENOTES_TEMPLATE_VIEW instead
   */
  LIVENOTES_TEMPLATE_VIEW_DEPRECATED = '/settings/nta/templates/:templateId',
  // Do not rename because we need for the Intercom  bot to work
  SETTINGS_INTEGRATIONS = '/settings/integrations',
  SETTINGS_INTEGRATION = '/settings/integrations/:provider',
  SETTINGS_SUBSCRIPTION = '/settings/subscription',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_TEAMS = '/settings/teams',
  // Do not rename because we need for the Intercom  bot to work
  SETTINGS_AI_ASSISTANT = '/settings/ai-assistant',
  SETTINGS_AI_SCORING = '/settings/ai-scoring',
  SETTINGS_AI_SCORING_TEMPLATE = '/settings/ai-scoring/template/:templateUuid',
  SETTINGS_ADVANCED = '/settings/advanced',
  SETTINGS_REMINDER_EMAIL = '/settings/advanced/reminder-email',
  SETTINGS_RESTRICT_CALL_ACCESS = '/settings/advanced/restrict-call-access',
  /** @deprecated */
  SETTINGS_DOMAIN_NAMES = '/settings/advanced/domain-names',
  CALL_IMPORT_RESTRICTIONS = '/settings/advanced/call-import-restrictions',
  /** @deprecated */
  SETTINGS_AI_NOTES = '/settings/ai-notes',
  SETTINGS_CALL_REVIEWS = '/settings/call-reviews',
  SETTINGS_CALL_REVIEW = '/settings/call-reviews/:id',
  SETTINGS_TOPICS = '/settings/topics',
  SETTINGS_CUSTOM_VOCABULARY = '/settings/custom-vocabulary',
  SETTINGS_NOTIFICATIONS = '/settings/notifications',
  SETTINGS_TAGS = 'settings/tags',
  SETTINGS_SUMMARY_TEMPLATES = '/settings/summary',
  SETTINGS_SUMMARY_TEMPLATE = '/settings/summary/template/:templateUuid',
  SETTINGS_HIGHLIGHTS_TEMPLATES = '/settings/highlights',
  SETTINGS_HIGHLIGHTS_TEMPLATE = '/settings/highlights/template/:templateUuid',
  SETTINGS_CRM_FILLING_TEMPLATES = '/settings/crm-filling/templates',
  SETTINGS_CRM_FILLING_TEMPLATE = '/settings/crm-filling/templates/:templateUuid',
  SETTINGS_EMAIL_FOLLOW_UP_TEMPLATES = '/settings/email-follow-up/templates',
  SETTINGS_EMAIL_FOLLOW_UP_TEMPLATE = '/settings/email-follow-up/templates/:templateUuid',
  SETTINGS_EMAIL_FOLLOW_UP_AI_ASSISTANT = '/settings/email-follow-up/ai-assistant',
  SETTINGS_DEALS = '/settings/deals',
  SETTINGS_DEALS_WARNINGS = '/settings/deals/warnings',
  SETTINGS_CRM_FILLING_PLAYGROUND = '/settings/crm-filling/playground',
  LIBRARY = '/library',
  LIBRARY_FOLDER = '/library/:id',
  ANALYTICS = '/analytics',
  ANALYTICS_PAGE = '/analytics/:page',
  ANALYTICS_LAYOUT = '/analytics/*',
  ANALYTICS_REVIEWS = '/analytics/reviews',
  ANALYTICS_TOPICS = '/analytics/topics',
  ANALYTICS_CALLS = '/analytics/calls',
  ANALYTICS_INTERACTION = '/analytics/interaction',
  ANALYTICS_ACTIVITIES = '/analytics/activities',
  ANALYTICS_AI_SCORING = '/analytics/ai-scoring',
  DIGEST_DELETE = '/delete-digest/:tenantName/:token',
  FARMER_AUTH = '/farmer-auth/:token',
  GOOGLE_CHROME_EXTENSION = 'https://chrome.google.com/webstore/detail/modjo-auto-recording-exte/ndaofhhipkdefljcjcpgbjhlieeplnpj',
  EMBED = '/embed/:publicToken',
  SSO = '/sso/:provider',
  OAUTH_USERS = '/oauth/users/:provider',
  OAUTH_CALLBACK = '/integrations/:provider/callback',
  /** @deprecated */
  NTA_DOWNLOAD_APP = '/download-app',
  NOT_FOUND = '/not-found',
}

export enum AuthenticationMode {
  LOGIN = 'login',
  SETUP_PASSWORD = 'password',
  RESET_PASSWORD = 'reset',
}

export enum SaidByFilter {
  USER = 'user',
  CONTACT = 'contact',
}

export enum TagOrigin {
  TRUE_PREDICTION = 'truePrediction',
  FALSE_PREDICTION = 'falsePrediction',
  USER = 'user',
  SYNC = 'sync',
}

export enum CallTagState {
  /** When a tag was predicted to be associated to the call, but not validated yet. In database, this means that there is a PredictedTag */
  PREDICTED = 'predicted',
  /** The tag is just associated with the call, and no prediction was made. In database, a CallTag exists */
  USER = 'user',
  /** The tag is associated with the call after a correct prediction was validated. In database, the PredictedTag was turned into a CallTag */
  PREDICTION_VALIDATED = 'prediction_validated',
}

export enum UserRole {
  USER = 'user',
  MANAGER = 'manager',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export enum NotificationConsumer {
  EMAIL = 'email',
  SLACK = 'slack',
}

export enum CropState {
  /** When a Call is being crop from the Spectrum. */
  ONGOING = 'ongoing',
  /** When a sub call's part slot is fixed. */
  READY = 'ready',
  /** When cropping the call has been canceled. */
  CANCELED = 'canceled',
  /** When the call's part slot has been validated by the user. */
  FINISHED = 'finished',
}

export const isCroppingState = (state?: CropState) =>
  state !== undefined && [CropState.READY, CropState.ONGOING].includes(state);

export enum ActivityType {
  COMMENT = 'comment',
  SNIPPET = 'snippet',
  LIVENOTES = 'Livenotes',
  HIGHLIGHTS = 'Highlights',
  COMMENT_REPLY = 'comment reply',
  LIVENOTES_REPLY = 'Livenotes reply',
  HIGHLIGHTS_REPLY = 'Highlights reply',
  LIBRARY = 'library',
  TRANSCRIPT = 'transcript',
  AI_CALL_SCORING = 'Ai Call Scoring',
  CALL_REVIEW = 'Call review',
}

export const clickOriginActivityType: Record<ActivityType, string> = {
  [ActivityType.COMMENT]: 'Comment',
  [ActivityType.SNIPPET]: 'Snippet',
  [ActivityType.LIVENOTES]: 'Livenotes',
  [ActivityType.HIGHLIGHTS]: 'Highlights',
  [ActivityType.COMMENT_REPLY]: 'comment reply',
  [ActivityType.LIVENOTES_REPLY]: 'Livenotes',
  [ActivityType.HIGHLIGHTS_REPLY]: 'Highlights',
  [ActivityType.LIBRARY]: 'Library',
  [ActivityType.TRANSCRIPT]: 'Transcript',
  [ActivityType.AI_CALL_SCORING]: 'Ai Call Scoring',
  [ActivityType.CALL_REVIEW]: 'Call review',
};

export enum ActivityActionType {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum NavbarTab {
  NTA = 'NTA',
  SETTINGS = 'settings',
  PERSONAL_SETTINGS = 'personal settings',
  WORKSPACE_SETTINGS = 'workspace settings',
  MODJO_APPLICATIONS = 'modjo apps',
  MY_NOTES = 'my notes',
  LIVENOTES_TEMPLATES = 'LN templates',
  DISCOVER_LIVENOTES = 'discover LN',
  OPEN_LIVENOTES = 'open LN',
  DOWNLOAD_LIVENOTES = 'download LN',
  LOGOUT = 'logout',
  HOME = 'home',
  ANALYTICS = 'analytics',
  LIBRARY = 'library',
  EXPLORE = 'explore',
  NOTIFICATIONS = 'notifications',
  INTERCOM_DOCUMENTATION = 'help',
  INTERCOM_CHAT = 'chat',
  INTERCOM_GENERAL_FEEDBACK = 'submit feedback',
  SEARCH = 'search',
  DEALS_LIST = 'deals list',
  DEAL_VIEW = 'deal view',
}

export enum HomePageTab {
  MYCALLS = 'me',
  COMPANY = 'company',
  TEAM = 'team',
}

export enum SnippetCreationMode {
  DRAG_AND_DROP = 'drag-n-drop',
  PLAYER_BUTTON = 'playbar_button',
}

export enum HighlightSource {
  PANEL = 'panel',
  TIMESTAMP = 'timestamp',
  URL = 'url',
  THREAD = 'thread',
}

export enum SubscriptionCurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  USD = 'USD',
}

export enum BillingFrequency {
  MONTH = 'month',
  BIANNUAL = 'biannual',
  YEAR = 'year',
}

export enum PaymentMethod {
  CHARGEBEE = 'chargebee',
  BANK_TRANSFER = 'bank_transfer',
}

export enum ModalType {
  SHARE = 'share',
  SLACK_IT = 'slack_it',
  SNIPPET = 'snippet',
  COMMENT = 'comment',
  LIBRARY = 'library',
  GENERATE_EMAIL = 'generate_email',
}

export type Sizes = 'tiny' | 'small' | 'medium' | 'large';

export enum ExportField {
  CALL = 'call',
  CALL_MADE = 'call.made',
  CALL_DURATION_AVERAGE = 'call.duration.average',
  CALL_DURATION_TOTAL = 'call.duration.total',
  INTERACTION = 'interaction',
  TALK_RATIO = 'talk.ratio',
  LONGEST_MONOLOGUE_USER_AVERAGE = 'longest.monologue.user.average',
  LONGEST_MONOLOGUE_CUSTOMER_AVERAGE = 'longest.monologue.customer.average',
  INTERACTION_AVERAGE = 'interaction_average',
  ACTIVITIES = 'activities',
  CALL_LISTENED_TOTAL = 'call.listened.total',
  COMMENT_POSTED = 'comment.posted',
  COMMENT_RECEIVED = 'comment.received',
  LIVENOTES_POSTED = 'livenotes.posted',
  REVIEW = 'review',
  REVIEW_POSTED = 'review.posted',
  REVIEW_POSTED_NOTE_AVERAGE = 'review.posted.note.average',
  REVIEW_RECEIVED = 'review.received',
  REVIEW_RECEIVED_NOTE_AVERAGE = 'review.received.note.average',
}

export enum TranscriptionSegmentFormat {
  SRT = 'text/srt',
  JSON = 'application/json',
}

export enum HealthCheckWarning {
  INTEGRATION = 'integration down',
}

export enum HealthCheckReason {
  CREDENTIALS = 'credentials',
  USER_FLOW_MISSING_SCOPES = 'user flow - missing scopes',
}

export enum AvatarGroupMode {
  WATCH = 'watch',
  REPLY = 'reply',
}

export enum Direction {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum ProviderDataStatus {
  UP = 'up',
  DOWN = 'down',
  AVAILABLE = 'available',
  DISABLED = 'disabled',
  UNDEFINED = 'undefined',
}

export enum ProviderFeatureType {
  IMPORT_CALLS = 'importCalls',
  SYNC_CALENDAR = 'calendar',
  SEND_CALENDAR_EVENT_EMAIL_REMINDERS = 'sendCalendarEventEmailReminders',
  CRM = 'crm',
  AUTO_RECORDING = 'autoRecording',
  CHANNEL = 'channel',
  SYNC_EMAILS = 'syncEmails',
}

export enum ProviderScope {
  GOOGLE_DRIVE = 'https://www.googleapis.com/auth/drive.readonly',
  GOOGLE_CALENDAR = 'https://www.googleapis.com/auth/calendar.events.readonly',
  MICROSOFT_CALENDAR = 'https://graph.microsoft.com/Calendars.Read',
  MICROSOFT_DRIVE_SITES = 'https://graph.microsoft.com/Sites.Read.All',
  MICROSOFT_DRIVE_FILES = 'https://graph.microsoft.com/Files.Read.All',
  MICROSOFT_USER = 'https://graph.microsoft.com/User.Read',
  MICROSOFT_AUTORECORDING_SITES = 'https://graph.microsoft.com/OnlineMeetings.ReadWrite',
}

export enum FeatureStatus {
  UP = 'up',
  DOWN = 'down',
  MISSING = 'missing',
}

export enum DataState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
