import * as yup from 'yup';

export const YupUtils = {
  required<T extends yup.Schema<unknown>>(schema: T, errorMesage: string): T {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return schema.required(errorMesage).nullable(false).typeError(errorMesage);
  },
};

// Custom validation for arrays that can contain numbers or strings
export const numberOrStringArray = (message: string) =>
  yup
    .array()
    .of(
      yup
        .mixed<number | string>()
        .transform((value) => {
          if (typeof value === 'number') {
            return value;
          }
          if (typeof value === 'string') {
            return value;
          }
          return undefined;
        })
        .required()
    )
    .required(message);

// Custom validation for arrays that can contain numbers or strings (emails)
export const numberOrStringEmailArray = (messageArray: string, messageRequired: string) =>
  yup
    .array()
    .of(
      yup
        .mixed<number | string>()
        .transform((value) => {
          if (typeof value === 'number') {
            return value;
          }
          if (
            typeof value === 'string' &&
            (/^\+?\d+$/.test(value) || /^\w+(['+.-]\w+)*@\w+([.-]\w+)*\.\w+([.-]\w+)*$/.test(value))
          ) {
            return value;
          }
          return undefined;
        })
        .required()
    )
    .test('required', messageRequired, (value) => !!value && value.length > 0)
    .test('array-value-type', messageArray, (value) => !value?.some((v) => !v));
