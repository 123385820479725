import { FeatureStatus } from '@/enums';
import { Providers, UserOAuthProvider } from '@/types/providers';

import {
  isAutoRecordingFeature,
  isCalendarFeature,
  isChannelFeature,
  isCrmFeature,
  isEmailFeature,
  isImportCallsFeature,
  MissingScopes,
  ProviderFeature,
  UserIntegration,
} from './providersScopes.types';

const getMissingScopes = (provider: ProviderFeature, otherFeatures: ProviderFeature[]): MissingScopes[] => {
  const missingScopes: MissingScopes[] = [];

  // Add missing scopes for the feature import calls
  if (provider.missingScopes.length > 0) {
    missingScopes.push({
      providerName: provider.provider as UserOAuthProvider,
      scopes: provider.missingScopes,
      type: provider.feature,
    });
  }

  // Add missing scopes from the mandatory features
  for (const feature of provider.mandatoryFeatures) {
    const filteredFeatures = otherFeatures.filter((p) => p.feature === feature);

    // If one of the mandatory features is already up, we don't need to add the missing scopes
    // If none of the mandatory features is up, we add the missing scopes so that the user can choose
    // among the UserOAuthProvider to connect
    if (!filteredFeatures.some((p) => p.status === FeatureStatus.UP)) {
      for (const feat of filteredFeatures) {
        missingScopes.push({
          providerName: feat.provider as UserOAuthProvider,
          scopes: feat.missingScopes,
          type: feat.feature,
        });
      }
    }
  }

  return missingScopes;
};

export const getUserIntegrations = (
  providerFeatures: ProviderFeature[],
  userHasLicense: boolean
): UserIntegration[] => {
  /**
   * As we receive each provider infos with its feature details, we need to group them by features:
   * - importCalls mainly (it includes also the autoRecording ones)
   * - crm
   * - channel
   * - otherFeatures is mainly used to facilitate the array iterations
   */
  const importCallsFeatures = providerFeatures.reduce((accumulator, providerFeature) => {
    const existingProvider = accumulator.find(({ provider }) => provider === providerFeature.provider);
    // Is import Call Feature type and provider feature not added yet
    if (isImportCallsFeature(providerFeature) && !existingProvider) {
      accumulator.push(providerFeature);
      // If there's an existing autoRecording provider, and the current importCall providerFeature has DOWN status,
      // then replace the existing provider with the new one to make it appear as down otherwise ignore it.
    } else if (
      existingProvider &&
      providerFeature.status === FeatureStatus.DOWN &&
      isAutoRecordingFeature(providerFeature)
    ) {
      const index = accumulator.indexOf(existingProvider);
      return [...accumulator.slice(0, index), providerFeature, ...accumulator.slice(index + 1)];
    }

    return accumulator;
  }, [] as ProviderFeature[]);

  // Everything that it's not importCall features without duplicates.
  // Used to get the mandatory features for the importCalls feature providers such as calendar for instance.
  const otherFeatures = providerFeatures.reduce((accumulator: ProviderFeature[], providerFeature) => {
    const isImportCalls = isImportCallsFeature(providerFeature);
    const hasDuplicateProviderFeature = accumulator.some(
      ({ provider, feature }) => provider === providerFeature.provider && feature === providerFeature.feature
    );
    if (!isImportCalls && !hasDuplicateProviderFeature) {
      accumulator.push(providerFeature);
    }
    return accumulator;
  }, []);

  const crmFeatures = otherFeatures.filter((providerFeature) => isCrmFeature(providerFeature));
  const channelFeatures = otherFeatures.filter((providerFeature) => isChannelFeature(providerFeature));
  const emailFeatures = otherFeatures.filter((providerFeature) => isEmailFeature(providerFeature));

  // Add all features that need to be displayed
  return [...importCallsFeatures, ...crmFeatures, ...channelFeatures, ...emailFeatures]
    .reduce<UserIntegration[]>((accumulator, providerFeature) => {
      if (!userHasLicense && (isAutoRecordingFeature(providerFeature) || isImportCallsFeature(providerFeature))) {
        return accumulator;
      }

      if (providerFeature.missingScopes.length > 0 || providerFeature.status === FeatureStatus.UP) {
        const missingScopes = getMissingScopes(providerFeature, otherFeatures);

        accumulator.push({
          provider: providerFeature.provider,
          isConnected: missingScopes.length === 0,
          missingScopes,
        });
      }
      return accumulator;
    }, [])
    .sort((a, b) => {
      return +a.isConnected - +b.isConnected || a.provider.localeCompare(b.provider);
    });
};

export function getUserCalendarIntegration(providerFeatures: ProviderFeature[]) {
  const calendarFeatures = providerFeatures.filter((providerFeature) => isCalendarFeature(providerFeature));
  return calendarFeatures.find((calendar) => calendar.status === FeatureStatus.UP);
}

export function getUserHealthCheckDownIntegrations(providerFeatures: ProviderFeature[]) {
  const userIntegrations = getUserIntegrations(providerFeatures, true);
  return userIntegrations.reduce<UserIntegration[]>((accumulator, userIntegration) => {
    const isRecordingBotProvider = (
      [Providers.GOOGLE_RECORDING_BOT, Providers.MICROSOFT_RECORDING_BOT] as string[]
    ).includes(userIntegration.provider);

    if (!userIntegration.isConnected && !isRecordingBotProvider) {
      accumulator.push({
        ...userIntegration,
      });
    }
    return accumulator;
  }, []);
}
