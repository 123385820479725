import React, { useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { i18n } from '@/translation/i18n';
import { isInRange, toFloat, toNumber } from '@/utils/utils';

import {
  NUMBER_FILTERS,
  RangeNumberOption,
  FiltersCategories,
  getRangeCategoryId,
  CALL_DURATION_ID,
  DURATION_FILTERS,
  getRangeUnit,
  filtersCategoriesTranslationKeys,
} from './Common';
import { RangeCategory, RangeOptionType, ReviewsOptionType, SearchableObject } from './search-components.types';
import { getRangeType } from '../../explore/helpers';
import { SimpleInput } from '../ui-components/inputs/text/SimpleInput';
import { SimpleSelect } from '../ui-components/select/SimpleSelect';
import { SelectOption } from '../ui-components/types';

type RangeNumberProps = {
  category: FiltersCategories;
  id: string;
  selectedFilterType: RangeCategory;
  selectedItems: SearchableObject[];
  setSelectedItems: (values: SearchableObject[]) => void;
  maxValue?: number;
  step?: number;
  placeholder?: string;
};

export const createRangeNumberOption = ({
  type,
  id,
  min,
  max,
}: {
  type: FiltersCategories;
  id: string;
  min?: string | number;
  max?: string | number;
}) => {
  const numberMethod = type === RangeOptionType.DEAL_AMOUNT ? toNumber : toFloat;
  const idSuffix = `${(min && `-min-${min}`) ?? ''}${(max && `-max-${max}`) ?? ''}`;

  return {
    type,
    id: `${id}${idSuffix}`,
    name: `${getRangeType('number', min, max).label}${min === undefined ? '' : ` ${min.toString()}`}${
      (max !== undefined && min !== max && `${min ? ` ${i18n.t('and')}` : ''} ${max.toString()}`) || ''
    }`,
    min: min && numberMethod(min),
    max: max && numberMethod(max),
  } as RangeNumberOption;
};

export const RangeNumber = ({
  category,
  id,
  selectedFilterType,
  selectedItems,
  setSelectedItems,
  maxValue,
  step = 1,
  placeholder,
}: RangeNumberProps) => {
  const { t } = useTranslation();
  const [selectedItem] = selectedItems as RangeNumberOption[];
  const [min, setMin] = useState<string>(selectedItem?.min ? selectedItem?.min.toString() : '');
  const [max, setMax] = useState<string>(selectedItem?.max ? selectedItem?.max.toString() : '');
  const [option, setOption] = useState<SelectOption>({
    id: selectedFilterType,
    label: t(filtersCategoriesTranslationKeys[selectedFilterType]),
  });

  const selectedId = getRangeCategoryId(category as RangeOptionType);
  const optionAsRangeCategory = option.id as RangeCategory;
  const isLower = optionAsRangeCategory === RangeCategory.LOWER;
  const isBetween = optionAsRangeCategory === RangeCategory.BETWEEN;

  const handleChange = (value: { min?: string; max?: string }) => {
    const minFloat = Number.parseFloat(value.min ?? '');
    const maxFloat = Number.parseFloat(value.max ?? '');
    const minLimit = Number.isNaN(toNumber(min)) ? toNumber(min) : 0;
    const maxLimit = Number.isNaN(toNumber(max)) ? toNumber(max) : maxValue;

    const isMinValid = !minFloat || (minFloat >= 0 && (!maxLimit || minFloat <= maxLimit));
    const isMaxValid = !maxFloat || (maxFloat >= minLimit && (!maxValue || maxFloat <= maxValue));
    const isBetweenValid =
      !isBetween || (minFloat !== undefined && maxFloat !== undefined && minFloat < maxFloat && maxFloat > minFloat);

    if ((minFloat !== undefined || maxFloat !== undefined) && isMinValid && isMaxValid && isBetweenValid) {
      const filteredItems = selectedItems.filter((item) => !item.id.toString().includes(selectedId));
      const newOption = createRangeNumberOption({
        type: category,
        id,
        ...value,
      });
      const updatedItems = [...filteredItems, newOption];
      setSelectedItems(updatedItems);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <div className="mx-4 mb-2 mt-0">
        <SimpleSelect
          withPortal={false}
          options={(selectedId === CALL_DURATION_ID ? DURATION_FILTERS : NUMBER_FILTERS).map(
            (amountFilter) =>
              ({ id: amountFilter, label: t(filtersCategoriesTranslationKeys[amountFilter]) }) as SelectOption
          )}
          optionSelected={option}
          setOptionSelected={(value) => {
            if (selectedFilterType === value.label) {
              setSelectedItems([...(selectedItems || [])]);
            } else {
              setSelectedItems(selectedItems.filter((item) => !item?.id.toString().includes(selectedId)));
              setMin('');
              setMax('');
            }
            setOption(value);
          }}
        />
      </div>
      <div className={clsx('mx-4', isBetween && 'grid grid-cols-2 gap-x-4')}>
        <SimpleInput
          fullWidth={!isBetween}
          autoFocus
          step={step}
          onChange={(event) => {
            const { value } = event.currentTarget;
            const parsedValue = Number.parseFloat(value);
            switch (optionAsRangeCategory) {
              case RangeCategory.LOWER: {
                if (isInRange(value, { min: 0, max: maxValue })) {
                  handleChange({ max: value });
                  setMax(value.toString());
                }

                break;
              }
              case RangeCategory.BETWEEN: {
                if (isInRange(value, { min: 0, max: maxValue })) {
                  handleChange({ min: value, max });
                  setMin(value.toString());
                  if (maxValue && max && parsedValue >= toNumber(max)) {
                    setMax((parsedValue <= maxValue ? parsedValue : maxValue).toString());
                  }
                }
                break;
              }
              case RangeCategory.EQUAL: {
                if (isInRange(value, { min: 0, max: maxValue })) {
                  handleChange({ min: value, max: value });
                  setMin(value.toString());
                }
                break;
              }
              default: {
                if (isInRange(value, { min: 0, max: maxValue })) {
                  handleChange({ min: value });
                  setMin(value.toString());
                }
                break;
              }
            }
          }}
          value={isLower ? max : min}
          type="number"
          min={0}
          max={isLower ? maxValue : (max ?? maxValue)}
          placeholder={
            !isBetween && placeholder
              ? placeholder
              : `${isBetween ? t('filters.search.min', { value: 0 }) : t(filtersCategoriesTranslationKeys[category], { count: 1 })} (${getRangeUnit(category as RangeOptionType | ReviewsOptionType)})`
          }
        />
        {isBetween && (
          <SimpleInput
            onChange={(event) => {
              const { value } = event.currentTarget;
              const parsedValue = Number.parseFloat(value);
              if (isInRange(value, { min: 0, max: maxValue })) {
                handleChange({ min, max: value });
                setMax(parsedValue.toString());
              }
            }}
            value={max}
            type="number"
            min={min}
            max={maxValue}
            step={step}
            placeholder={
              !isBetween && placeholder
                ? placeholder
                : `${t('filters.search.max', { value: maxValue ?? '∞' })} (${getRangeUnit(category as RangeOptionType | ReviewsOptionType)})`
            }
          />
        )}
      </div>
    </>
  );
};
