import { useEffect } from 'react';

import mixpanel from 'mixpanel-browser';
import { useIntercom } from 'react-use-intercom';

import { useUpdateUserSessionMetadataMutation } from '@/entities/user-session/user-session.mutations';
import { useUserSessionQuery } from '@/entities/user-session/user-session.queries';
import { changeDateFnsLocale } from '@/utils/date-utils';
import { getDefaultUserLanguage } from '@/utils/language';

import { i18n } from './i18n';

const UserLanguageSetter = () => {
  const userSession = useUserSessionQuery({ queryOptions: { staleTime: Number.POSITIVE_INFINITY } });
  const updateUserSession = useUpdateUserSessionMetadataMutation();
  const { update } = useIntercom();

  /**
   * Change language for user
   */
  useEffect(() => {
    const language = userSession.isSuccess && getDefaultUserLanguage(userSession.data.language);
    // auto set language for user if it's not set
    if (language && !userSession.data?.language) {
      updateUserSession.mutate({ language });
    }
    if (language) {
      i18n.changeLanguage(language.split('-')[0]);
      changeDateFnsLocale(language);
      // update intercom language
      update({ languageOverride: language });
      // add info to the user for mixpanel events
      mixpanel.people.set({ language });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession.data, userSession.isSuccess]);

  return null;
};

export { UserLanguageSetter };
