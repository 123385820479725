import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';

import { Event } from './events';
import { QUERY_PARAMETER } from '../utils/url-utils';

/**
 * Identify the source referring the webapp.
 *
 * This enum provides possible value defined from query parameter named {@see QUERY_PARAMETER.UTM_SOURCE}
 */
export enum UTMSource {
  //  URL was referenced from crm link we add when logging it
  CRM = 'crm',
  //  URL was referenced from an email notification
  EMAIL = 'email',
  //  URL was referenced from Slack notifications or Slack-it links
  SLACK = 'slack',
  //  URL was referenced from Login button on the website
  WEBSITE = 'website',
  //  URL was referenced from the livenotes application
  NTA = 'nta',
  // undefined : source that we don’t track, mainly direct or sharing links
}

/**
 * Identify the medium which generates the URL.
 *
 * e.g. notification, sharing modal, slack-it
 *
 * This enum provides possible value defined from query parameter named {@see QUERY_PARAMETER.UTM_MEDIUM}
 */
export enum UTMMedium {
  // Link generated by the notifications
  NOTIF_IMPORT_CALL = 'notif_import_call',
  NOTIF_IMPORT_CALL_AI_EMAIL = 'notif_import_call_AI_email',
  NOTIF_COMMENT = 'notif_comment',
  NOTIF_MENTION = 'notif_mention',
  NOTIF_REACTION = 'notif_reaction',
  NOTIF_RECO_CALL = 'notif_reco_call',
  NOTIF_ONBOARDING_LINK = 'notif_onboarding_link',
  NOTIF_REVIEW = 'notif_review',
  NOTIF_CRM_FILLING = 'notif_crm_filling_answered',

  // Link generated by the share modal
  SHARE_LINK_SNIPPET = 'share_link_snippet',
  SHARE_LINK_CALL = 'share_link_call',
  SHARE_LINK_COMMENT = 'share_link_comment',
  SHARE_LINK_LIVE_NOTE = 'share_link_live_note',
  SHARE_LINK_AI_NOTE = 'share_link_ai_note',
  SHARE_LINK_TRANSCRIPT = 'share_link_transcript',
  SHARE_LINK_REVIEW = 'share-link-review',
  SHARE_LINK_AI_SCORING = 'share_link_ai_scoring',
  SHARE_LINK_AI_SUMMARY = 'share_link_ai_summary',

  // Link generated when downloading a snippet
  DOWNLOAD_SNIPPET = 'download_snippet',

  // Link provided from the Digest
  ANALYTICS_REPORT = 'analytics_report',

  // Link provided from the CRMs
  LOG_NOTES = 'log_notes',
  LOG_ACTIVITY = 'log_activity',

  // Link provided from the livenote application
  APP_MENU = 'app_menu',
  TEMPORARY_SIGNIN = 'temporary_signin',
  // Link provided from the AI insight
  AI_INSIGHT = 'ai_insight',
  // Link build in CRM
  CRM_DEAL_BUTTON = 'crm_deal_button',
}

export function setUtms(source?: UTMSource, medium?: UTMMedium) {
  const parameters = {
    [`${QUERY_PARAMETER.UTM_SOURCE} [last touch]`]: source ?? null,
    [`${QUERY_PARAMETER.UTM_MEDIUM} [last touch]`]: medium ?? null,
  };
  const firstParameters = {
    [`${QUERY_PARAMETER.UTM_SOURCE} [first touch]`]: source ?? null,
    [`${QUERY_PARAMETER.UTM_MEDIUM} [first touch]`]: medium ?? null,
  };
  mixpanel.people.set(parameters);
  mixpanel.register(parameters);
  mixpanel.people.set_once(firstParameters);
}

// This code is based on snippet found at https://help.mixpanel.com/hc/en-us/articles/360001337103
export const initUtms = () => {
  const searchParameters = new URLSearchParams(window.location.search);
  const utmSource = searchParameters.get(QUERY_PARAMETER.UTM_SOURCE)?.toLowerCase();
  // always lowercase and split to get the first word
  // in some case like https://modjo-ai.sentry.io/issues/4665112269/events/fa9c6e43552e44f199f015f8f17a7ce2/ th utm_medium
  // can be altered by third party. our utm source don't have spaces so we are safe to split on space
  const utmMedium = searchParameters.get(QUERY_PARAMETER.UTM_MEDIUM)?.trim().toLowerCase().split(' ')[0];

  // eslint-disable-next-line unicorn/prefer-includes
  if (utmSource && !Object.values(UTMSource).some((referencedUtmSource) => referencedUtmSource === utmSource)) {
    Sentry.captureException(new Error(`The UTM source: ${utmSource} is not implemented yet`));
  }

  // eslint-disable-next-line unicorn/prefer-includes
  if (utmMedium && !Object.values(UTMMedium).some((referencedUtmMedium) => referencedUtmMedium === utmMedium)) {
    Sentry.captureException(new Error(`The UTM medium: ${utmMedium} is not implemented yet`));
  }
  setUtms(utmSource as UTMSource, utmMedium as UTMMedium);

  /**
   * Let's wait for a few seconds to wait for user identification.
   * If after a few seconds the mixpanel user is identified, then let's trigger the Landed event.
   * Else, let's ignore it, because otherwise it triggers events for too many unidentified users
   * See https://gitlab.com/modjo-ai/modjo-webapp/-/issues/1465 for more details
   */
  setTimeout(() => {
    mixpanel.get_distinct_id() && mixpanel.track(Event.LANDED);
  }, 30_000);
};
