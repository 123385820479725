import React from 'react';

import { useTranslation } from 'react-i18next';

import { ProgressPlaceholder, ProgressPlaceholderProps } from '../common/ProgressPlaceholder';

const DataLoading = (props: ProgressPlaceholderProps) => {
  const { t } = useTranslation('common');
  return <ProgressPlaceholder {...props}>{t('loadingData')}</ProgressPlaceholder>;
};

export { DataLoading };
