import React from 'react';

import { Menu } from '@/components/common/ui-components/menu';
import { MenuProps } from '@/components/common/ui-components/menu/types';
import { DataTestIdProps } from '@/types/common';

import { SIDEBAR_FLOATING_SHIFTING_OPTIONS, SIDEBAR_OFFSET_OPTIONS } from '../../sidebar.constants';

type NavigationMenuProps = {
  items: MenuProps['items'];
  Button: MenuProps['Button'];
} & DataTestIdProps;

const SidebarNavigationMenu = ({ items, Button, dataTestId }: NavigationMenuProps) => (
  <Menu
    items={items}
    Button={Button}
    placement="right"
    floatingShiftOptions={SIDEBAR_FLOATING_SHIFTING_OPTIONS}
    offset={SIDEBAR_OFFSET_OPTIONS}
    dataTestId={dataTestId}
  />
);

SidebarNavigationMenu.displayName = 'SidebarNavigationMenu';

export { SidebarNavigationMenu };
