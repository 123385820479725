import React from 'react';

import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

import { DataTestId } from '@/types/data-test-id';

import { CommonDialogProps } from './types';
import ReturnIcon from '../../../../static/icons/arrows/chevron-left-Line.svg?react';
import CloseIcon from '../../../../static/icons/basic/cross-Filled.svg?react';
import { IconButtonGhost } from '../buttons/UIButtonGhost';

type AnimatedPanelContentProps = {
  subtitle?: string;
  onReturn?: () => void;
  mainContentClassName?: string;
  hideCloseIcon?: boolean;
  handleOnCloseDialog: (id: string) => void;
  fakeReference: React.MutableRefObject<HTMLInputElement | null>;
} & CommonDialogProps;

export const AnimatedPanelContent = ({
  title,
  hideCloseIcon,
  subtitle,
  dialogId,
  fakeReference,
  mainContentClassName,
  children,
  handleOnCloseDialog,
  onReturn,
  dialogSize,
}: AnimatedPanelContentProps) => {
  return (
    <>
      {(title || onReturn || !hideCloseIcon) && (
        <Dialog.Title
          as="div"
          className={clsx(
            'relative flex shrink-0 items-center',
            onReturn ? 'pl-7' : 'pl-5',
            dialogSize !== 'full' && 'pr-5 pt-5',
            dialogSize !== 'full' && !subtitle && 'pb-3'
          )}
        >
          <div className={clsx(!hideCloseIcon && 'max-w-[90%]')}>
            {onReturn && (
              <div className="absolute left-1.5 top-6 z-10">
                <IconButtonGhost
                  icon={<ReturnIcon />}
                  onClick={() => onReturn()}
                  size="tiny"
                  className="!p-0.5"
                  isTransparent
                />
              </div>
            )}
            {title && <h2 className="text-xl font-semibold">{title}</h2>}
          </div>
          {!hideCloseIcon && (
            <IconButtonGhost
              size="small"
              icon={<CloseIcon className="fill-grey" />}
              className="absolute right-5 top-5"
              aria-label="Close dialog"
              onClick={() => handleOnCloseDialog(dialogId ?? '')}
            />
          )}
        </Dialog.Title>
      )}

      <Dialog.Description as="div" className={clsx(dialogSize === 'full' && 'h-full')}>
        {subtitle && (
          <h3
            data-test-id={DataTestId.CALL_SECTION_INTERVAL_HEADING_ID}
            className={clsx('pb-3 pr-7', onReturn ? 'pl-7' : 'pl-5')}
          >
            {subtitle}
          </h3>
        )}
        {/* Fake element to set focus on */}
        <input className="absolute h-0 opacity-0" ref={fakeReference} />
        <div
          className={clsx(
            dialogSize !== 'full' && 'pb-7',
            dialogSize === 'full' ? 'h-full px-0' : onReturn ? 'px-7' : 'px-5',
            mainContentClassName
          )}
        >
          {children}
        </div>
      </Dialog.Description>
    </>
  );
};
