import React from 'react';

import { FieldValues, Path, useController } from 'react-hook-form';

import { isNumeric, toNumber } from '@/utils/utils';

import { MultiComboboxWithChipsProps } from '../ui-components/autocompletes';
import { DefaultMultiComboboxWithChips } from '../ui-components/autocompletes/multiple-autocomplete/MultiComboboxWithChips';
import { SelectOption } from '../ui-components/types';

type FormMultiComboboxWithChipsProps<TValue, TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  onChange?: MultiComboboxWithChipsProps<TValue>['onChange'];
} & Omit<MultiComboboxWithChipsProps<TValue>, 'onChange' | 'values' | 'setValues'>;

/**
 * Custom mutliple combobox with chips component used with Formik.
 *
 */
export const FormMultiComboboxWithChips = <TValue extends SelectOption, TFields extends Record<string, unknown>>({
  options,
  name,
  onChange: customOnChange,
  ...props
}: FormMultiComboboxWithChipsProps<TValue, TFields>) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<Record<string, (string | number)[]>>({ name });

  return (
    <div className="relative flex flex-col pb-5">
      <DefaultMultiComboboxWithChips
        {...props}
        options={options}
        onInputChange={props.onInputChange}
        values={options.filter((option) => {
          if (isNumeric(option.id)) {
            return value?.includes(toNumber(option.id));
          }
          return value?.includes(option.id);
        })}
        onChange={(values) =>
          customOnChange
            ? customOnChange(values)
            : onChange(values?.map((v) => (isNumeric(v.id) ? toNumber(v.id) : v.id)))
        }
        setValues={(values) => {
          customOnChange
            ? customOnChange(values)
            : onChange(values?.map((v) => (isNumeric(v.id) ? toNumber(v.id) : v.id)));
        }}
        error={!!error}
      />
      {!!error && <span className="absolute bottom-0 text-sm text-red">{error.message}</span>}
    </div>
  );
};
