import { FeatureStatus, ProviderFeatureType } from '@/enums';
import { Provider, UserOAuthProvider } from '@/types/providers';

export type MissingScopes = {
  providerName: UserOAuthProvider;
  scopes: string[];
  type: ProviderFeatureType;
};

export type UserIntegration = {
  provider: Provider;
  isConnected: boolean;
  missingScopes: MissingScopes[];
};

export type ProviderFeature = {
  provider: Provider;
  feature: ProviderFeatureType;
  missingScopes: string[];
  mandatoryFeatures: ProviderFeatureType[];
  status: FeatureStatus;
};

type UserFeature = {
  feature: ProviderFeatureType;
  provider: Provider;
  status: FeatureStatus;
};

export type UserFeatureDto = Record<string, UserFeature[]>;

/**
 * Private helper functions
 */

export const isImportCallsFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.IMPORT_CALLS;
};

export const isAutoRecordingFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.AUTO_RECORDING;
};

export const isChannelFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.CHANNEL;
};

export const isEmailFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.SYNC_EMAILS;
};

export const isCalendarFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.SYNC_CALENDAR;
};

export const isCrmFeature = (provider: ProviderFeature) => {
  return provider.feature === ProviderFeatureType.CRM;
};
