import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { ButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { useDialog } from '@/components/common/ui-components/dialogs';
import { MobileDrawerTitleWithBottomBorder } from '@/components/common/ui-components/dialogs/MobileDrawer';
import { SimpleDialog } from '@/components/common/ui-components/dialogs/SimpleDialog';
import { InfiniteScrollContainer } from '@/components/common/ui-components/InfiniteScrollContainer';
import { NotificationItem } from '@/components/MainSidebar/Notifications/NotificationPopoverContent/NotificationItem';
import { useMarkAsReadMutation } from '@/entities/notifications/notifications.mutations';
import { useNotificationsQuery } from '@/entities/notifications/notifications.queries';
import { DIALOGS_IDS, scrollbarDefaultOptions } from '@/enums/constants';
import NotificationsIcon from '@/static/icons/alerte/bell-Filled.svg?react';
import TaskListIcon from '@/static/icons/basic/checkbox-rec-Line.svg?react';

export const MobileNotificationDialog = () => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog(DIALOGS_IDS.MOBILE_NOTIFICATIONS);
  const notificationsQuery = useNotificationsQuery();
  const markAsReadMutation = useMarkAsReadMutation();

  const notifications = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages.flatMap(({ values }) => values)
    : [];

  const lastPageIndex = notificationsQuery.isSuccess ? notificationsQuery.data.pages.length - 1 : 0;
  const unreadNotificationsCount = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages[lastPageIndex].unreadNotifications
    : 0;

  const handleClickMarkAsRead = () => {
    if (unreadNotificationsCount === 0 || isEmpty(notifications)) {
      return;
    }
    markAsReadMutation.mutate(undefined);
  };

  return (
    <SimpleDialog
      dialogId={DIALOGS_IDS.MOBILE_NOTIFICATIONS}
      onClose={closeDialog}
      mainContentClassName="p-0"
      title={
        <MobileDrawerTitleWithBottomBorder>
          <span className="text-base first-letter:capitalize">
            {t('navbar.notifications.button')} <span className="font-normal">({unreadNotificationsCount})</span>
          </span>

          <ButtonGhost
            onClick={handleClickMarkAsRead}
            icon={<TaskListIcon className="icon-small" />}
            iconPosition="start"
            disabled={unreadNotificationsCount === 0 && !isEmpty(notifications)}
            className="text-xs font-semibold"
          >
            {t('navbar.notifications.markAllAsRead')}
          </ButtonGhost>
        </MobileDrawerTitleWithBottomBorder>
      }
    >
      {notificationsQuery.isPending && (
        <div className="grid gap-y-2.5 p-4">
          {Array.from({ length: 5 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} className="h-20" />
          ))}
        </div>
      )}

      {notificationsQuery.isSuccess && isEmpty(notifications) && (
        <div className="flex-center flex-col text-center text-base">
          <NotificationsIcon className="size-5" />
          <div>{t('navbar.notifications.noNotificationState.firstSentence')}</div>
          <div>{t('navbar.notifications.noNotificationState.secondSentence')}</div>
        </div>
      )}

      {notificationsQuery.isSuccess && (
        <InfiniteScrollContainer
          canLoadMore={notificationsQuery.hasNextPage}
          loadMore={notificationsQuery.fetchNextPage}
          scrollbarOptions={scrollbarDefaultOptions}
          className="p-4"
        >
          <div className="flex flex-col gap-y-2.5">
            {notifications.map(
              (notification) =>
                notification && (
                  <NotificationItem key={notification.id} notification={notification} onClick={closeDialog} />
                )
            )}
          </div>
        </InfiniteScrollContainer>
      )}
    </SimpleDialog>
  );
};
