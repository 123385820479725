import { NodeOfANote } from '@/entities/notes-calls/notes-calls.types';
import { i18n } from '@/translation/i18n';
import { createStoreAndMethods } from '@/utils/zustand-utils';

export enum SidebarTab {
  INFO,
  HIGHLIGHTS,
  ACTIVITIES,
  CALL_REVIEW,
  TRANSCRIPT,
}

const sidebarTabLabelKeys = new Map<SidebarTab, 'info' | 'highlights' | 'activities' | 'reviews' | 'transcripts'>([
  [SidebarTab.INFO, 'info'],
  [SidebarTab.HIGHLIGHTS, 'highlights'],
  [SidebarTab.ACTIVITIES, 'activities'],
  [SidebarTab.CALL_REVIEW, 'reviews'],
  [SidebarTab.TRANSCRIPT, 'transcripts'],
]);

// Function to get translated label for enum value
export const getTranslatedSidebarTabLabel = (value: SidebarTab): string => {
  const tab = sidebarTabLabelKeys.get(value);
  if (!tab) {
    return i18n.t('callDetails:sidebar.info.tabLabel');
  }
  return i18n.t(`callDetails:sidebar.${tab}.tabLabel` as const);
};

export const DEFAULT_CALL_DETAILS_SIDEBAR_TAB = SidebarTab.INFO;

export enum RightPanelType {
  THREAD_COMMENT = 'Thread comment',
  THREAD_NOTE = 'Thread note',
  CHAPTERS = 'Chapters',
  TRANSCRIPT_CORRECTION = 'Transcript correction',
}

type NoteBlockThread = {
  noteId: number;
  noteBlockUuid: string;
  nodeBlockComment: Pick<NodeOfANote, 'timestamp' | 'content' | 'date'>;
  isFocusOnReply: boolean;
};

type CallDetailsSidebarStore = {
  currentSidebarTab: SidebarTab;
  openedThreadNoteBlock: NoteBlockThread | null;
  openedThreadCommentId: number | string | null;
  openedAiCallScoringTemplateUuid: string | null;
  currentRightPanel: RightPanelType | null;
  rightPanelWidth: number | null;
  transcriptCorrectionText: string | null;
  isWritingComment: boolean;
  showTextEditor: boolean;
};

const initialState: CallDetailsSidebarStore = {
  currentSidebarTab: DEFAULT_CALL_DETAILS_SIDEBAR_TAB,
  openedThreadNoteBlock: null,
  openedThreadCommentId: null,
  openedAiCallScoringTemplateUuid: null,
  currentRightPanel: null,
  rightPanelWidth: null,
  transcriptCorrectionText: null,
  isWritingComment: false,
  showTextEditor: false,
};

const [store, defaultMethods] = createStoreAndMethods(initialState);

const callDetailsSidebarStore = {
  ...defaultMethods,
  openNoteBlockThread(openedThreadNoteBlock: NoteBlockThread) {
    store.setState({ currentRightPanel: RightPanelType.THREAD_NOTE, openedThreadNoteBlock });
  },
  openCommentThread(openedThreadCommentId: number | string) {
    store.setState({ currentRightPanel: RightPanelType.THREAD_COMMENT, openedThreadCommentId });
  },
  openAiScoringBlock(templateUuid: string) {
    store.setState({
      currentSidebarTab: SidebarTab.ACTIVITIES,
      openedAiCallScoringTemplateUuid: templateUuid,
      currentRightPanel: null,
    });
  },
};

export { callDetailsSidebarStore };
