import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomInfiniteQueryOptions, CustomQueryOptions } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam } from '@/utils/utils';

import { ACTIVITIES_API_BASE_URL } from './activities.constants';
import { Activity, ActivityAttendee, ActivitySearchDTO } from './activity.types';
import { DealInteraction } from '../deals/deals.types';
import { queryKeys } from '../queryKeys';

type UseActivityByIdQueryParams<TSelectData = Activity> = CustomQueryOptions<Activity, TSelectData> & {
  activityUuid: string;
};

export const useActivityByIdQuery = <TSelectData = Activity>({
  activityUuid,
  queryOptions,
}: UseActivityByIdQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.activities.byId(activityUuid),
    queryFn: async () => {
      const { data } = await apiService.API.get<Activity>(combineRoutes([ACTIVITIES_API_BASE_URL, activityUuid]));

      return data;
    },
    ...queryOptions,
  });
};

type UseActivitiesSearchQueryParams<TSelectData = InfiniteData<PaginationRequestResult<Activity>, number>> = {
  filters?: ActivitySearchDTO;
  limit?: number;
} & CustomInfiniteQueryOptions<PaginationRequestResult<Activity>, TSelectData>;

export const useActivitiesSearchQuery = <T = InfiniteData<PaginationRequestResult<Activity>, number>>({
  filters,
  limit = 10,
  queryOptions,
}: UseActivitiesSearchQueryParams<T>) => {
  return useInfiniteQuery({
    queryKey: queryKeys.activities.search(limit, filters),
    queryFn: async ({ pageParam }) => {
      const { data } = await apiService.API.post<PaginationRequestResult<Activity>>(
        combineRoutes([ACTIVITIES_API_BASE_URL, 'search']),
        {
          filter: filters,
          pagination: { page: pageParam, perPage: limit },
        }
      );
      const result: PaginationRequestResult<Activity> = {
        pagination: data.pagination,
        values: data.values.map((activity) => ({ ...activity, startDate: new Date(activity.startDate) })),
      };
      return result;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, limit),
    ...queryOptions,
  });
};

type UseActivityTimelineQueryParams<TSelectData = DealInteraction[]> = {
  filters?: ActivitySearchDTO;
} & CustomQueryOptions<DealInteraction[], TSelectData>;

export const useActivityTimelineQuery = <TSelectData = DealInteraction[]>({
  filters,
  queryOptions,
}: UseActivityTimelineQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.activities.timeline(filters),
    queryFn: async () => {
      const { data } = await apiService.API.post<DealInteraction[]>(
        combineRoutes([ACTIVITIES_API_BASE_URL, 'timeline']),
        {
          filter: filters,
        }
      );

      return data.map((interaction) => ({ ...interaction, startDate: new Date(interaction.startDate) }));
    },
    placeholderData: (data) => data,
    ...queryOptions,
  });
};

type UseActivityAttendeesQueryParams<TSelectData = ActivityAttendee[]> = {
  dealId: number;
} & CustomQueryOptions<ActivityAttendee[], TSelectData>;

export const useActivityAttendeesQuery = <TSelectData = ActivityAttendee[]>({
  dealId,
  queryOptions,
}: UseActivityAttendeesQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.activities.attendees(dealId),
    queryFn: async () => {
      const { data } = await apiService.API.post<ActivityAttendee[]>(
        combineRoutes([ACTIVITIES_API_BASE_URL, 'attendees']),
        {
          filter: {
            dealIds: [dealId],
          },
        }
      );

      return data;
    },
    ...queryOptions,
  });
};
