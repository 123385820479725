import React from 'react';

import { getOS } from '@/utils/utils';

import { NoteTakingAppOs } from '../../enums/note-taking-app-os';

const OS = getOS();

export const AltKeySymbol = () => {
  // eslint-disable-next-line i18next/no-literal-string
  return <span>{OS === NoteTakingAppOs.MAC ? '⌥' : 'Alt'}</span>;
};

export const CtrlKeySymbol = () => {
  // eslint-disable-next-line i18next/no-literal-string
  return <span>{OS === NoteTakingAppOs.MAC ? '⌃' : 'Ctrl'}</span>;
};

export const getCtrlKeyText = () => {
  // eslint-disable-next-line i18next/no-literal-string
  return OS === NoteTakingAppOs.MAC ? 'Cmd' : 'Ctrl';
};

export const CmdKeySymbol = () => {
  // eslint-disable-next-line i18next/no-literal-string
  return <span>{OS === NoteTakingAppOs.MAC ? '⌘' : 'Ctrl'}</span>;
};
