import React, { FocusEvent, forwardRef, useState } from 'react';

import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';

import CloseIcon from '@/static/icons/basic/cross-Filled.svg?react';
import { cn } from '@/utils/utils';

import { SimpleTextareaProps } from './types';
import { getButtonOrInputStyles, getLabelStyles } from '../../styles';

const CLASSES = {
  base: 'resize-none leading-tight',
  fullWidth: 'w-full',
};

const SimpleTextarea = forwardRef<HTMLTextAreaElement, SimpleTextareaProps>(
  (
    {
      label,
      name,
      size = 'large',
      value,
      className,
      rows = 5,
      isError,
      autoSize = false,
      clearable = false,
      autoFocus = false,
      children,
      onClear,
      disabled,
      ...otherProps
    },
    reference
  ) => {
    const { onBlur, onFocus } = otherProps;
    const [isFocus, setIsFocus] = useState<boolean>(autoFocus);
    const TextArea = autoSize ? TextareaAutosize : 'textarea';
    const isLarge = size === 'large';

    return (
      <>
        {label && (
          <label className={getLabelStyles(isLarge, disabled)} htmlFor={name}>
            {label}
          </label>
        )}
        <div className="relative flex flex-col">
          <TextArea
            {...otherProps}
            // autoFocus isn't working if not set explicitely ¯\_(ツ)_/¯
            autoFocus={autoFocus}
            id={name}
            ref={reference}
            rows={rows}
            className={cn(
              clearable && 'pr-7',
              CLASSES.base,
              getButtonOrInputStyles({
                isLarge,
                isError,
                hasValues: Boolean(value),
                hasEndIcons: false,
                disabled,
              }),
              className
            )}
            value={value}
            onFocus={(event: FocusEvent<HTMLTextAreaElement>) => {
              setIsFocus(true);
              onFocus && onFocus(event);
            }}
            onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
              // onBlur is triggered before the closeIcon onClick event
              // we add a delay to be sure the click event is fired
              setTimeout(
                () => {
                  setIsFocus(false);
                  onBlur && onBlur(event);
                },
                clearable ? 100 : 0
              );
            }}
          />
          {clearable && (
            <div className="absolute inset-y-0 right-0 mr-1.5 mt-2 flex justify-end">
              <CloseIcon
                width={20}
                height={20}
                // because of the scrollbar we have to add a marginRight for non autosize textarea
                className={clsx(isFocus ? 'visible' : 'hidden', !autoSize && 'mr-3', 'cursor-pointer text-blue')}
                onClick={() => {
                  onClear && onClear();
                }}
              />
            </div>
          )}
          {children && <div>{children}</div>}
        </div>
      </>
    );
  }
);

export { SimpleTextarea };
