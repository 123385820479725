import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { useIsPublic } from '@/hooks/useIsPublic';
import { CustomQueryOptions } from '@/types/common';
import { DEFAULT_PAGINATION_PER_PAGE } from '@/utils/paginated.utils';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam, isEmpty } from '@/utils/utils';

import { API_TEAM_BASE_URL, API_TEAM_PAGINATED_LIST, API_TEAM_USERS } from './teams.constants';
import { GetTeamDto, Team, TeamsQueryParams, TeamsUsersInfiniteQueryParams } from './teams.types';
import { queryKeys } from '../queryKeys';
import { ApiUser } from '../users/users.types';

export const useTeamsQuery = <TSelectData = Map<number, Team>>(
  options?: CustomQueryOptions<Map<number, Team>, TSelectData>
) => {
  const isPublic = useIsPublic();
  return useQuery({
    queryKey: queryKeys.teams.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<Team[]>(API_TEAM_BASE_URL);
      return new Map(data.map((team) => [team.id, team]));
    },
    enabled: !isPublic,
    ...options?.queryOptions,
  });
};

export const useInfiniteTeamsQuery = (params?: TeamsQueryParams) => {
  return useInfiniteQuery({
    queryKey: queryKeys.teams.filters(params),
    queryFn: async ({ pageParam }) => {
      const data = await apiService.requestPagination<Team>(API_TEAM_PAGINATED_LIST, {
        ...params,
        page: pageParam,
        perPage: DEFAULT_PAGINATION_PER_PAGE,
      });
      return {
        ...data,
        values: data.values,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, DEFAULT_PAGINATION_PER_PAGE),
  });
};

type UseTeamQueryParams<TSelectData = GetTeamDto> = CustomQueryOptions<GetTeamDto, TSelectData> & {
  teamId: Team['id'];
};

export const useTeamQuery = ({ teamId, queryOptions }: UseTeamQueryParams) => {
  return useQuery({
    queryKey: queryKeys.teams.teamId(teamId),
    queryFn: async () => {
      const { data } = await apiService.API.get<GetTeamDto>(combineRoutes([API_TEAM_BASE_URL, teamId.toString()]));

      return data;
    },
    retry: false,
    ...queryOptions,
  });
};

export const useTeamsUsersInfiniteQuery = (params: TeamsUsersInfiniteQueryParams) => {
  return useInfiniteQuery({
    queryKey: queryKeys.teams.filters(params),
    queryFn: async ({ pageParam }) => {
      const data = await apiService.requestPagination<ApiUser>(API_TEAM_USERS, {
        page: pageParam,
        perPage: DEFAULT_PAGINATION_PER_PAGE,
        q: isEmpty(params.q) ? undefined : params.q,
        includeIds: params.includeIds && params.includeIds?.length > 0 ? params.includeIds?.join(',') : undefined,
      });

      return {
        ...data,
        values: data.values,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, DEFAULT_PAGINATION_PER_PAGE),
  });
};
