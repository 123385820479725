import React, { Fragment } from 'react';

import { Menu as MenuHUI } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { cn } from '@/utils/utils';

import { MenuProps } from './types';

const List = React.forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLUListElement>>((props, reference) => (
  <ul ref={reference} className="rounded shadow focus:outline-none" {...props} />
));

const MenuItems = ({ items, Item: ItemProp, List: ListProp }: Pick<MenuProps, 'items' | 'Item' | 'List'>) => (
  <MenuHUI.Items static as={ListProp ?? List}>
    {items.map(
      (
        {
          className,
          disabled,
          label,
          id,
          withActiveState = true,
          link,
          state,
          onClick,
          icon,
          tail,
          hidden,
          dataTestId,
          ...extraProps
        },
        index
      ) => {
        if (hidden) {
          return null;
        }
        const Component = ItemProp ? 'div' : link ? Link : 'button';

        return (
          <MenuHUI.Item as={ItemProp ?? Fragment} key={link ?? index} disabled={disabled}>
            {({ active }) => (
              <Component
                to={link ?? ''}
                state={state}
                id={id}
                key={label}
                role="menuitem"
                tabIndex={disabled ? -1 : 0}
                className={cn(
                  'flex w-full cursor-pointer items-center whitespace-nowrap px-3 py-1.5 text-sm focus:outline-none',
                  withActiveState && active && 'bg-grey-100',
                  disabled && 'pointer-events-none opacity-40',
                  className
                )}
                disabled={disabled}
                onClick={(event) => {
                  if (onClick) {
                    event.stopPropagation();
                    onClick(event);
                  }
                }}
                data-test-id={dataTestId}
                {...extraProps}
              >
                <div id={id} className="flex items-center gap-x-2">
                  {icon}
                  <span className="first-letter:capitalize">{label}</span>
                  {tail}
                </div>
              </Component>
            )}
          </MenuHUI.Item>
        );
      }
    )}
  </MenuHUI.Items>
);

export { MenuItems };
