import React from 'react';

import { Trans } from 'react-i18next';

import ClockIcon from '@/static/icons/time/time-Line.svg?react';

export const DealWarningSetupBanner = () => (
  <div className="flex w-full items-center justify-center gap-x-2.5 border-b border-b-grey-100 bg-gradient-to-r from-lavender to-pale-grey p-4 text-xs">
    <ClockIcon className="icon-small" />
    <span>
      <Trans i18nKey="deals:dealList.dealWarningSetupBanner" />
    </span>
  </div>
);
