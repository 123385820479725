import React from 'react';

import { Dialog } from '@headlessui/react';

import { searchStore } from './search.store';
import { SearchContent } from './SearchContent';

const SearchDialog = () => {
  const isSearchOpen = searchStore.useIsSearchOpen();

  return (
    <Dialog open={isSearchOpen} onClose={() => searchStore.setIsSearchOpen(false)}>
      <div className="flex-center fixed inset-0 bg-lavender bg-opacity-40 backdrop-blur-[1px]">
        <div className="h-[420px]">
          <SearchContent />
        </div>
      </div>
    </Dialog>
  );
};

SearchDialog.displayName = 'SearchDialog';

export { SearchDialog };
