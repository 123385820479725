import { Environment } from '@/enums/constants';

import { LoggerArgs } from './socket.types';

export const objectValuesToMessage = (obj: Record<string, string>): string => {
  const values = Object.values(obj);
  if (values.every((value) => value === '')) {
    return '';
  }
  return values.join('/');
};

export const logger = (...args: LoggerArgs) => {
  if (import.meta.env.VITE_ENV !== Environment.PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  return null;
};

export const defaultSocketOptions = {
  transports: ['websocket'],
  autoConnect: false,
};
