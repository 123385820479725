import { i18n } from '@/translation/i18n';
import { TranslationKeysWithoutNamespace } from '@/translation/types';
import {
  CollaborationProviders,
  CredentialsProviders,
  CRMProviders,
  EmailProviders,
  PhoneProviders,
  Provider,
  Providers,
  VideoProviders,
} from '@/types/providers';

import { getProviderDisplayName } from '../providers/icons/ProvidersIcon';

export type OnSubmitHandle = {
  onSubmit: () => void;
};

/**
 * Integration type that need to be updated
 */
export enum IntegrationType {
  PHONE = 'phone',
  VIDEO = 'video',
  CRM = 'crm',
  COLLABORATION = 'collaboration',
  EMAIL = 'email',
}

export const IntegrationTypesLabels: Record<IntegrationType, TranslationKeysWithoutNamespace<'settings'>> = {
  [IntegrationType.PHONE]: 'integrations.providers.types.phoneProvider',
  [IntegrationType.VIDEO]: 'integrations.providers.types.videoProvider',
  [IntegrationType.CRM]: 'integrations.providers.types.crm',
  [IntegrationType.COLLABORATION]: 'integrations.providers.types.collaboration',
  [IntegrationType.EMAIL]: 'integrations.providers.types.email',
};
export const IntegrationTypesDescriptions: Record<IntegrationType, TranslationKeysWithoutNamespace<'settings'>> = {
  [IntegrationType.PHONE]: 'integrations.providers.description.phoneProvider',
  [IntegrationType.VIDEO]: 'integrations.providers.description.videoProvider',
  [IntegrationType.CRM]: 'integrations.providers.description.crm',
  [IntegrationType.COLLABORATION]: 'integrations.providers.description.collaboration',
  [IntegrationType.EMAIL]: 'integrations.providers.description.email',
};

/**
 * This array will contain only the Provider supported by the integration workflows
 */
export const IntegrationProviders = [
  ...CRMProviders,
  ...PhoneProviders,
  ...VideoProviders,
  ...CollaborationProviders,
  ...EmailProviders,
];

export type IntegrationProvider = (typeof IntegrationProviders)[number];

export const isIntegrationProvider = (provider: Provider): provider is IntegrationProvider =>
  IntegrationProviders.includes(provider as IntegrationProvider);

export enum Recommendation {
  DEPRECATED = 'Deprecated',
  RECOMMENDED = 'Recommended',
  ALPHA = 'Alpha',
}

export const getTranslatedRecommendation = (recommendation: Recommendation): string => {
  switch (recommendation) {
    case Recommendation.DEPRECATED: {
      return i18n.t('integrations.providerInfoBlock.recommendation.deprecated', { ns: 'settings' });
    }
    case Recommendation.RECOMMENDED: {
      return i18n.t('integrations.providerInfoBlock.recommendation.recommended', { ns: 'settings' });
    }
    case Recommendation.ALPHA: {
      return i18n.t('integrations.providerInfoBlock.recommendation.alpha', { ns: 'settings' });
    }
    default: {
      const exhaustiveCheck: never = recommendation;
      return exhaustiveCheck;
    }
  }
};

/**
 * Type of settings required to display a provider in the integration workflow
 */
type IntegrationProviderSetting = {
  types: IntegrationType[];
  label: string;
  description?: TranslationKeysWithoutNamespace<'settings'>;
  recommendation?: Recommendation;
};

/**
 * This is a hard coded settings of Providers supported for the integration workflow
 */
export const IntegrationProvidersSettings: Record<IntegrationProvider, IntegrationProviderSetting> = {
  [Providers.GOOGLE_RECORDING_BOT]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.GOOGLE_RECORDING_BOT),
    recommendation: Recommendation.RECOMMENDED,
    description: 'integrations.providers.description.googleRecordingBot',
  },
  [Providers.MICROSOFT_RECORDING_BOT]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.MICROSOFT_RECORDING_BOT),
    recommendation: Recommendation.RECOMMENDED,
    description: 'integrations.providers.description.microsoftRecordingBot',
  },
  [Providers.GMAIL]: {
    types: [IntegrationType.EMAIL],
    label: getProviderDisplayName(Providers.GMAIL),
  },
  [Providers.OUTLOOK_EMAIL]: {
    types: [IntegrationType.EMAIL],
    label: getProviderDisplayName(Providers.OUTLOOK_EMAIL),
  },
  [Providers.ZOHO]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.ZOHO),
  },
  [Providers.PIPEDRIVE]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.PIPEDRIVE),
  },
  [Providers.SELLSY]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.SELLSY),
  },
  [Providers.ZENDESK]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.ZENDESK),
  },
  [Providers.SALESFORCE]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.SALESFORCE),
  },
  [Providers.HUBSPOT]: {
    types: [IntegrationType.CRM, IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.HUBSPOT),
    description: 'integrations.providers.description.hubspot',
  },
  [Providers.DYNAMICS]: {
    types: [IntegrationType.CRM],
    label: getProviderDisplayName(Providers.DYNAMICS),
  },
  [Providers.TWILIO]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.TWILIO),
    recommendation: Recommendation.ALPHA,
    description: 'integrations.providers.description.twilio',
  },
  [Providers.KEYYO]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.KEYYO),
  },
  [Providers.DIABOLOCOM]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.DIABOLOCOM),
    recommendation: Recommendation.DEPRECATED,
  },
  [Providers.DIABOLOCOM_V2]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.DIABOLOCOM_V2),
    recommendation: Recommendation.ALPHA,
  },
  [Providers.FUZE]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.FUZE),
  },
  [Providers.RINGOVER]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.RINGOVER),
  },
  [Providers.ZOOM_PHONE]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.ZOOM_PHONE),
  },
  [Providers.SALESLOFT]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.SALESLOFT),
  },
  [Providers.TALKDESK]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.TALKDESK),
  },
  [Providers.OUTREACH]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.OUTREACH),
  },
  [Providers.FRESHCALLER]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.FRESHCALLER),
  },
  [Providers.AIRCALL]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.AIRCALL),
  },
  [Providers.RING_CENTRAL]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.RING_CENTRAL),
  },
  [Providers.CLOUDTALK]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.CLOUDTALK),
  },
  [Providers.AMAZON_SERVICE_CLOUD_VOICE]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.AMAZON_SERVICE_CLOUD_VOICE),
  },
  [Providers.VOCALCOM]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.VOCALCOM),
  },
  [Providers.APIZEE]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.APIZEE),
  },
  [Providers.MICROSOFT_TENANT_FLOW]: {
    types: [IntegrationType.VIDEO],
    // Only place where we need to differenciate deprecated Microsoft Admin Integration from the other Microsoft flows
    label: 'Microsoft Admin Integration',
    recommendation: Recommendation.DEPRECATED,
  },
  [Providers.MICROSOFT_USER_FLOW]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.MICROSOFT_USER_FLOW),
    recommendation: Recommendation.DEPRECATED,
  },
  [Providers.ZOOM]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.ZOOM),
  },
  [Providers.GOOGLE]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.GOOGLE),
  },
  [Providers.GOOGLE_USER_FLOW]: {
    types: [IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.GOOGLE_USER_FLOW),
    recommendation: Recommendation.DEPRECATED,
  },
  [Providers.SLACK]: {
    types: [IntegrationType.COLLABORATION],
    label: getProviderDisplayName(Providers.SLACK),
  },
  [Providers.MANUAL]: {
    types: [IntegrationType.PHONE, IntegrationType.VIDEO],
    label: getProviderDisplayName(Providers.MANUAL),
  },
  [Providers.DIXA]: {
    types: [IntegrationType.PHONE],
    label: getProviderDisplayName(Providers.DIXA),
  },
};

// This OAuth providers required a configuration before doing the OAuth (tenant id)
const OAuthProvidersRequiredConfiguration = [
  Providers.KEYYO,
  Providers.ZOHO,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.MICROSOFT_USER_FLOW,
  Providers.ZENDESK,
  Providers.SALESFORCE,
  Providers.DYNAMICS,
];
type OAuthProviderRequiredConfiguration = (typeof OAuthProvidersRequiredConfiguration)[number];
export const isOAuthProvidersRequiredConfiguration = (
  provider: Provider
): provider is OAuthProviderRequiredConfiguration =>
  OAuthProvidersRequiredConfiguration.includes(provider as OAuthProviderRequiredConfiguration);

// Configurable OAuth providers after authentication
const ConfigurableOauthProviders = [
  Providers.HUBSPOT,
  Providers.SELLSY,
  Providers.MICROSOFT_TENANT_FLOW,
  Providers.KEYYO,
  Providers.DYNAMICS,
];
const ConfigurableProviders = [...new Set([...CredentialsProviders, ...ConfigurableOauthProviders])];
export type ConfigurableProvider = (typeof ConfigurableProviders)[number];
export const isConfigurableProvider = (provider: Provider): provider is ConfigurableProvider =>
  ConfigurableProviders.includes(provider as ConfigurableProvider);
