import React from 'react';

import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useDialog } from '@/components/common/ui-components/dialogs';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { NavbarTab, RoutePaths } from '@/enums';
import { DIALOGS_IDS } from '@/enums/constants';
import GearIcon from '@/static/icons/basic/gear-Line.svg?react';
import { Event } from '@/tracking/events';
import { PreviousPageState } from '@/types/common';

import { ExtensionMenu } from './ExtensionMenu';
import { HelpSupportMenu } from './HelpSupportMenu';
import { MeetingRecorder } from './MeetingRecorder';
import { NavigationLinkButton } from '../NavigationLinkButton';
import { MeetingRecorderContentStates, MeetingRecorderStatuses } from './MeetingRecorder/meeting-recorder.constants';
import { meetingRecorderStore } from './MeetingRecorder/meeting-recorder.store';
import { calculateWavDuration, getFirstFileFromStorage } from './MeetingRecorder/meeting-recorder.utils';

const SidebarBottomSection = () => {
  const location = useLocation();
  const { openDialog } = useDialog(DIALOGS_IDS.RECORDER_DIALOG);
  const currentUser = useCurrentUser();

  useEffectOnce(() => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const fetchFile = async () => {
      try {
        const foundFile = await getFirstFileFromStorage();
        if (foundFile) {
          meetingRecorderStore.setFileDuration(calculateWavDuration(foundFile.size));
          meetingRecorderStore.setCurrentFileName(foundFile?.name);
          meetingRecorderStore.setMeetingRecorderContentState(MeetingRecorderContentStates.RECORDER);
          meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.COMPLETED);
          openDialog();
          mixpanel.track(Event.CLICK_OPEN_PHYISICAL_RECORDER, {
            source: 'sidebar',
            device: 'desktop',
          });
        }
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            location: 'SidebarBottomSection/index.tsx',
          },
        });
      }
    };
    fetchFile();
  });

  const previousPageLocationState: PreviousPageState = {
    previousLocationPath: {
      pathname: location.pathname,
      search: location.search,
    },
  };

  return (
    <div className="flex flex-col gap-y-2 px-4 py-2">
      {currentUser?.hasLicense && <MeetingRecorder />}
      <HelpSupportMenu />
      <ExtensionMenu />
      <NavigationLinkButton
        path={RoutePaths.SETTINGS}
        navigationKey="settings"
        lineIcon={GearIcon}
        filledIcon={GearIcon}
        state={previousPageLocationState}
        trackedEvent={{ id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.SETTINGS } }}
      />
    </div>
  );
};

SidebarBottomSection.displayName = 'SidebarBottomSection';

export { SidebarBottomSection };
