/**
 * when adding a new product tour id, make sure to add it to the Notion https://www.notion.so/Product-tour-ids-410bf1836c004d84b0ed76dc6a94e0a3?pvs=4
 */
export const ProductTourIds = {
  HOME_PAGE_CALENDAR: 'home-page-calendar',
  LIVENOTES_DOWNLOAD: 'livenotes-download',
  EXPLORE_FILTER_LIST: 'filter-list',
  EXPLORE_CREATE_VIEW_BUTTION: 'explore-create-view-button',
  EXPLORE_CALL_LIST: 'explore-call-list',
  SIDEBAR: 'sidebar',
  SIDEBAR_TOGGLE_BUTTON: 'sidebar-toggle-button',
  AI_SCORING_SETTINGS_ACCURACY_TAB: 'ai-scoring-settings-accuracy-tab',
  AI_NOTES: 'ai-notes',
  CALL_DETAILS_PLAYER: 'call-details-player',
  CALL_DETAILS_ACTIVITIES_TAB: 'call-details-activities-tab',
  CALL_DETAILS_ADD_COMMENT_BUTTON: 'call-details-add-comment-button',
  CALL_DETAILS_CONFIRM_CRM_FILLING_FIELDS: 'call-details-confirm-crm-filling-fields',
  CALL_LIST_CONFIRM_CRM_FILLING_FIELDS: 'call-list-confirm-crm-filling-fields',
  CALL_LIST_PENDING_CRM_INFORMATION: 'call-list-pending-crm-information',
  CRM_FILLING_PANEL: 'crm-filling-panel',
  CRM_FILLING_SETTINGS_HEADER_PLAYGROUND_BUTTON: 'crm-filling-settings-header-playground-button',
  DEALS_NAVIGATION_SIDEBAR_BUTTON: 'deals-navigation-sidebar-button',
  DEAL_VIEW_ASK_ANYTHING_BUTTON: 'deal-view-ask-anything-button',
  DEAL_VIEW_WARNINGS: 'deal-view-warnings',
  DEAL_VIEW_TIMELINE_CAPTIONS: 'deal-view-timeline-captions',
  DEAL_VIEW_CONTACT_OBJECTIONS: 'deal-view-contact-objections',
  DEAL_VIEW_SUMMARY: 'deal-view-summary',
  GENERATE_EMAIL_FOLLOW_UP: 'generate-email-followup',
} as const;
/**
 * when adding a new product tour id, make sure to add it to the Notion https://www.notion.so/Product-tour-ids-410bf1836c004d84b0ed76dc6a94e0a3?pvs=4
 */
