import { UserRole } from '@/enums';
import { Feature } from '@/enums/features';
import { getRoleLevel, getUserFullName } from '@/utils/user-utils';

import { ApiCurrentUser, ApiUser, CurrentUser, UserUtilities } from './users.types';

export const mapApiUserToUser = <
  T extends Pick<ApiUser, 'teamIds' | 'role' | 'firstName' | 'lastName' | 'tenantName' | 'deletedOn'>,
>(
  user: T
): T & Omit<UserUtilities, 'hasFeature'> => ({
  ...user,
  isInTeam: (teamId: number) => user.teamIds.includes(teamId),
  hasRole: (role: UserRole) => getRoleLevel(user.role) >= getRoleLevel(role),
  fullName: getUserFullName(user),
});

export const mapApiCurrentUserToCurrentUser = (currentUser: ApiCurrentUser): CurrentUser => ({
  ...mapApiUserToUser({
    ...currentUser,
    firstName: currentUser.firstName ?? '',
    lastName: currentUser.lastName ?? '',
  }),
  hasFeature: (feature: Feature) => (currentUser.featureIds ?? []).includes(feature),
});
