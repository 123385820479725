import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { OtherInfoOptionType } from '@/components/common/search-components/search-components.types';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import { IDS_LENGHT_LIMIT } from './contact.constant';
import { statusesToSearchableOptions } from './contact.selects';
import { Contact } from './contact.types';
import { queryKeys } from '../queryKeys';

const CONTACT_BASE_URL = '/contacts';

const useContactsQuery = <TSelectData = Contact[]>(
  ids: number[],
  options?: CustomQueryOptions<Contact[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.contact.list(ids),
    queryFn: async () => {
      if (ids.length > IDS_LENGHT_LIMIT) {
        Sentry.captureMessage('useContactsQuery: ids array is longer than 200');
      }

      const slicedIds = ids.slice(0, IDS_LENGHT_LIMIT);

      const { data } = await apiService.API.get<Contact[]>(CONTACT_BASE_URL, { params: { ids: slicedIds } });

      return data;
    },
    ...options?.queryOptions,
    enabled: ids.length > 0,
  });
};

const useSearchContactQuery = <TSelectData = Contact[]>(
  query: string,
  minLengthForSearch = 3,
  options?: CustomQueryOptions<Contact[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.contact.search(query),
    enabled: options?.queryOptions?.enabled !== false && query.length >= minLengthForSearch,
    queryFn: async () => {
      const { data } = await apiService.API.get<Contact[]>(combineRoutes([CONTACT_BASE_URL, 'search']), {
        params: { q: query },
      });

      return data;
    },
  });
};

const useSortedSearchContactQuery = <TSelectData = Contact[]>(
  query: string,
  minLengthForSearch = 3,
  options?: CustomQueryOptions<Contact[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.contact.search(query),
    enabled: options?.queryOptions?.enabled !== false && query.length >= minLengthForSearch,
    queryFn: async () => {
      const { data } = await apiService.API.get<Contact[]>(combineRoutes([CONTACT_BASE_URL, 'search']), {
        params: { q: query },
      });

      return data;
    },
  });
};

const useLeadStatusesQuery = <TSelectData = string[]>(options?: CustomQueryOptions<string[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.lead.statuses(),
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(combineRoutes([CONTACT_BASE_URL, 'lead-status']));

      return data;
    },
    ...options?.queryOptions,
  });
};

const useLeadStatusesAsSearchableOptionsQuery = () => {
  return useLeadStatusesQuery({
    queryOptions: { select: (data) => statusesToSearchableOptions(data, OtherInfoOptionType.LEAD_STATUS) },
  });
};

export {
  useContactsQuery,
  useLeadStatusesAsSearchableOptionsQuery,
  useSearchContactQuery,
  useSortedSearchContactQuery,
};
