import React from 'react';

import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useIsFarmer } from '@/entities/users/users.hooks';
import { daysLeftUntilTargetDate } from '@/utils/date-utils';

import { FreeTrialExpirationContent } from './FreeTrialExpirationContent';
import { WarningBannerContainer } from '../WarningBannerContainer';

const FreeTrialExpirationBanner = () => {
  const tenantQuery = useTenantQuery();
  const isFarmer = useIsFarmer();

  if (isFarmer || !tenantQuery.isSuccess || !tenantQuery.data.trialEndDate) {
    return null;
  }

  return (
    <WarningBannerContainer>
      <FreeTrialExpirationContent
        numberOfDaysLeft={daysLeftUntilTargetDate(tenantQuery?.data?.trialEndDate ?? Date.now(), 'up')}
      />
    </WarningBannerContainer>
  );
};

export { FreeTrialExpirationBanner };
