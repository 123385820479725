/**
 * Must be identical as backend. It stands for languages supported by our transcript.
 */
export enum LanguageCode {
  afZA = 'af-ZA',
  arAE = 'ar-AE',
  zhCN = 'zh-CN',
  daDK = 'da-DK',
  nlNL = 'nl-NL',
  enUS = 'en-US',
  faIR = 'fa-IR',
  frFR = 'fr-FR',
  deDE = 'de-DE',
  heIL = 'he-IL',
  hiIN = 'hi-IN',
  idID = 'id-ID',
  itIT = 'it-IT',
  jaJP = 'ja-JP',
  koKR = 'ko-KR',
  msMY = 'ms-MY',
  ptPT = 'pt-PT',
  ruRU = 'ru-RU',
  esES = 'es-ES',
  svSE = 'sv-SE',
  taIN = 'ta-IN',
  teIN = 'te-IN',
  thTH = 'th-TH',
  tlTLG = 'tl-TLG',
  trTR = 'tr-TR',
  fiFI = 'fi-FI',
  caES = 'ca-ES',
  plPL = 'pl-PL',
  roRO = 'ro-RO',
  huHU = 'hu-HU',
  elGR = 'el-GR',
  bgBG = 'bg-BG',
  slSI = 'sl-SI',
  srRS = 'sr-RS',
  csCZ = 'cs-CZ',
  ltLT = 'lt-LT',
  hrHR = 'hr-HR',
  cyGB = 'cy-GB',
  nnNO = 'nn-NO',
  glES = 'gl-ES',
  mlIN = 'ml-IN',
  siLK = 'si-LK',
  kmKH = 'km-KH',
  snZW = 'sn-ZW',
  bsBA = 'bs-BA',
  urIN = 'ur-IN',
  viVN = 'vi-VN',
  bnBD = 'bn-BD',
  ukUA = 'uk-UA',
  boCN = 'bo-CN',
  miNZ = 'mi-NZ',
  skSK = 'sk-SK',
  lvLV = 'lv-LV',
  azAZ = 'az-AZ',
  knIN = 'kn-IN',
  etEE = 'et-EE',
  mkMK = 'mk-MK',
  brFR = 'br-FR',
  euES = 'eu-ES',
  isIS = 'is-IS',
  hyAM = 'hy-AM',
  neNP = 'ne-NP',
  mnMN = 'mn-MN',
  kkKZ = 'kk-KZ',
  sqAL = 'sq-AL',
  swKE = 'sw-KE',
  mrIN = 'mr-IN',
  paIN = 'pa-IN',
  yoBJ = 'yo-BJ',
  soSO = 'so-SO',
  ocFR = 'oc-FR',
  kaGE = 'ka-GE',
  beBY = 'be-BY',
  sdPK = 'sd-PK',
  guIN = 'gu-IN',
  amET = 'am-ET',
  loLA = 'lo-LA',
  uzUZ = 'uz-UZ',
  foFO = 'fo-FO',
  psPK = 'ps-PK',
  tkTM = 'tk-TM',
  mtMT = 'mt-MT',
  saIN = 'sa-IN',
  lbLU = 'lb-LU',
  myMM = 'my-MM',
  mgMG = 'mg-MG',
  asIN = 'as-IN',
  ttRU = 'tt-RU',
  lnAO = 'ln-AO',
  haGH = 'ha-GH',
  baRU = 'ba-RU',
}

export const isLanguageCode = (language: string): language is LanguageCode => {
  return Object.values(LanguageCode).includes(language as LanguageCode);
};

// see source lang of https://www.deepl.com/docs-api/translate-text/translate-text/
export const DeeplLangSupported = [
  'AR', // Arabic
  'BG', // Bulgarian
  'CS', // Czech
  'DA', // Danish
  'DE', // German
  'EL', // Greek
  'EN', // English
  'ES', // Spanish
  'ET', // Estonian
  'FI', // Finnish
  'FR', // French
  'HU', // Hungarian
  'ID', // Indonesian
  'IT', // Italian
  'JA', // Japanese
  'KO', // Korean
  'LT', // Lithuanian
  'LV', // Latvian
  'NB', // Norwegian Bokmål,
  'NN', // Norwegian Nynorsk
  'NL', // Dutch
  'PL', // Polish
  'PT', // Portuguese (all Portuguese varieties mixed)
  'RO', // Romanian
  'RU', // Russian
  'SK', // Slovak
  'SL', // Slovenian
  'SV', // Swedish
  'TR', // Turkish
  'UK', // Ukrainian
  'ZH', // Chinese
];

export const APP_LANGUAGES = [LanguageCode.frFR, LanguageCode.enUS] as const;

export type AppLanguage = (typeof APP_LANGUAGES)[number];
