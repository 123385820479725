import React, { forwardRef } from 'react';

import { ButtonBase } from './ButtonBase';
import { IconButtonBase } from './IconButtonBase';
import { ButtonBaseProps, IconButtonBaseProps } from './types';
import { Sizes } from '../../../../enums';
import { cn } from '../../../../utils/utils';

type UIButtonColorProps = {
  isTransparent?: boolean;
  isUnStyled?: boolean;
  size?: Sizes;
  fullWidth?: boolean;
};

type ButtonGhostProps = ButtonBaseProps & UIButtonColorProps;
type IconButtonGhostProps = IconButtonBaseProps & UIButtonColorProps;

/**
 * Button without borders and with white background.
 */
const CLASSES = {
  base: 'font-semibold',
  ring: 'ring-transparent-basic-24 hover:focus:ring-0 hover:focus:ring-offset-0 focus:ring-1 focus:ring-offset-1',
  white: 'bg-grey-white hover:bg-transparent-basic-8',
  transparent: 'bg-transparent hover:bg-transparent-basic-8',
  disabled: 'text-grey-400 fill-grey-400 cursor-not-allowed hover:bg-transparent',
  notDisabled: 'active:focus:ring-0 active:focus:ring-offset-0 active:bg-transparent-basic-16',
  fullWidth: 'w-full',
};

const ButtonGhost = forwardRef<HTMLButtonElement, ButtonGhostProps>(
  ({ disabled, isTransparent = false, isUnStyled = false, className, ...otherProps }, reference) => (
    <ButtonBase
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(
        CLASSES.base,
        disabled ? CLASSES.disabled : (CLASSES.ring, CLASSES.notDisabled),
        !isUnStyled && (isTransparent ? CLASSES.transparent : CLASSES.white),
        className
      )}
    />
  )
);

const IconButtonGhost = forwardRef<HTMLButtonElement, IconButtonGhostProps>(
  (
    { disabled, isTransparent, isUnStyled = false, className, size, fullWidth, dataTestId, ...otherProps },
    reference
  ) => (
    <IconButtonBase
      dataTestId={dataTestId}
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(
        disabled ? CLASSES.disabled : (CLASSES.ring, CLASSES.notDisabled),
        !isUnStyled && isTransparent ? CLASSES.transparent : CLASSES.white,
        fullWidth && CLASSES.fullWidth,
        className
      )}
      size={size}
    />
  )
);

export { ButtonGhost, IconButtonGhost };
