import { useCurrentUser, useIsBouygues } from '@/entities/users/users.hooks';

/**
 * A Hook to check that ignores the display of the banner for tenants in a particular case.
 *
 * Using the user to get the tenant name allows to display the banner for the farmer.
 *
 * @returns True if currentUser is not fetched yet to avoid UI glitches.
 */
export const useIgnorePaymentOverdueBanner = (): boolean => {
  const currentUser = useCurrentUser();
  const isBouygues = useIsBouygues();
  return (
    !currentUser ||
    // Always disable it for Bouygues
    isBouygues ||
    // Temporary tenants for whom the banner is disabled
    ['welii', 'explain', 'speexx', 'upcoop'].includes(currentUser.tenantName.toLowerCase())
  );
};
