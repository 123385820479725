import React from 'react';

import { AlertMessage } from '@/components/common/toast/alert';
import ErrorFallbackIcon from '@/static/ErrorFallback.svg?react';
import { DataTestId } from '@/types/data-test-id';
import { cn } from '@/utils/utils';

export type GenericErrorFallbackBodyProps = { ctaButton?: React.ReactNode; iconClassName?: string };

/**
 * Fallback component's body displayed when an error happens.
 * It should mostly be used along with the package: `react-error-boundary`
 *
 * This component is not tied to the React context CappedErrorsContext. If you want
 * to limit the number of errors handled, @see ErrorFallback component.
 */
export const GenericErrorFallbackBody = ({ ctaButton, iconClassName }: GenericErrorFallbackBodyProps) => (
  <div
    className="flex-center flex-1 flex-col gap-y-5 text-center"
    data-test-id={DataTestId.GENERIC_ERROR_FALLBACK_BODY_ID}
  >
    <div className="max-w-max">
      <ErrorFallbackIcon className={cn('h-min w-1/2 lg:w-3/4', iconClassName)} />
    </div>
    <div className="w-3/4">
      <p className="font-semibold">{AlertMessage.GENERIC_ERROR}</p>
      <p>{AlertMessage.ERROR_REFRESH}</p>
      {ctaButton && <div className="flex-center mt-2">{ctaButton}</div>}
    </div>
  </div>
);
