import { SnippetCreationMode } from '../enums';

export enum Event {
  // Used to determine how the end user reached the application
  LANDED = 'Landed on app',

  // Breadcrumbs navigation
  CLICK_ON_BREADCRUMBS = 'Click on breadcrumbs',

  // Deals - Pipeline
  DEALS_CLICK_ON_DEAL_VIEW = 'Deals - Click on deal view',
  DEALS_CLICK_ON_FORECAST_TABS = 'Deals - Click on forecast tabs',
  DEALS_USE_FILTERS = 'Deals - Use filters',
  DEALS_USE_SEARCH_BAR = 'Deals - Use search bar',
  DEALS_SHARE = 'Deals - Share',
  DEALS_RANK_DEALS_TABLE = 'Deals - Rank deals table',
  DEALS_HOVER_ACTIVITY_TIMELINE_CIRCLE = 'Deals - Hover activity timeline circle',
  DEALS_HOVER_OBJECTIONS_CELL = 'Deals - Hover objection number',
  DEALS_HOVER_CONTACTS_CELL = 'Deals - Hover contact number',
  DEALS_HOVER_WARNING_CELL = 'Deals - Hover warning number',

  // Deal - View
  DEAL_VIEW_CLICK_ON_CRM_LINK = 'Deals - Click on CRM link',
  DEAL_VIEW_CLICK_ON_INTERACTIONS_TABS = 'Deals - Click on interactions tabs',
  DEAL_VIEW_FILTER_INTERACTIONS = 'Deals - Filter interactions',
  DEAL_VIEW_CLICK_DELETE_EMAIL = 'Deals - Click delete email',
  DEAL_VIEW_CLICK_OPEN_EMAIL_SIDEPANEL = 'Deals - Click open email sidepanel',
  DEAL_VIEW_EDIT_CONTACT_ROLE = 'Deals - Edit contact role',

  // Deal - Summary
  DEAL_SUMMARY_CLICK_SHOW_MORE = 'AI Deal summary - Click on show more',
  DEAL_SUMMARY_CLICK_CLICK_TRANSLATE = 'AI Deal summary - Click on translate',
  DEAL_SUMMARY_CLICK_COPY_SUMMARY = 'AI Deal summary - Click on copy',
  DEAL_SUMMARY_CLICK_OPEN_FEEDBACK_PANEL = 'AI Deal summary - Click on open feedback panel',
  DEAL_SUMMARY_CLICK_SUBMIT_FEEDBACK = 'AI Deal summary - Click on submit feedback',
  DEAL_SUMMARY_CLICK_CLOSE_FEEDBACK_PANEL = 'AI Deal summary - Click on close feedback panel',
  DEAL_SUMMARY_CLICK_CANCEL_FEEDBACK = 'AI Deal summary - Click on cancel feedback',

  // Deal - Ask Anything
  DEAL_ASK_ANYTHING_CLICK_SIDEPANEL = 'Deals - ask anything - Click on sidepanel',
  DEAL_ASK_ANYTHING_CLICK_SUGGESTED_QUESTIONS = 'Deals - ask anything - Click on suggested questions',
  DEAL_ASK_ANYTHING_SUBMIT_USER_INPUT = 'Deals - ask anything - User submit custom question',
  DEAL_ASK_ANYTHING_CLICK_ASK_AGAIN = 'Deals - ask anything - Click on ask a new question',
  DEAL_ASK_ANYTHING_CLICK_CARROUSEL_ARROW = 'Deals - ask anything - Click on carrousel arrows',

  // Deal - Settings CRM fields
  DEAL_SETTINGS_ADD_CRM_FIELD = 'Deals - Add CRM field',
  DEAL_SETTINGS_DELETE_CRM_FIELD = 'Deals - Delete CRM field',
  DEAL_SETTINGS_OPEN_CRM_FIELD_PANEL = 'Deals - Open CRM field panel',
  DEAL_SETTINGS_CLOSE_CRM_FIELD_PANEL = 'Deals - Close CRM field panel',

  // Call activities (Comments + Notes)
  ADD_A_CALL_ACTIVITY = 'Add a call activity',
  EDIT_A_CALL_ACTIVITY = 'Edit a call activity',
  DELETE_A_CALL_ACTIVITY = 'Delete a call activity',
  REACT_CALL_ACTIVITY = 'React to call activity',
  CLICK_CALL_ACTIVITY = 'Click on call activity',

  // Library
  EDIT_A_LIB = 'Edit a library description',
  EDIT_DESC_FROM_LIBRARIES = 'Edit a library description from libraries',
  ADD_TO_LIBRARY = 'Add to library',
  ADD_NEW_FOLDER = 'Add new folder',
  EDIT_FOLDER = 'Edit folder',

  // Navbar
  CLICK_ON_NAVBAR = 'Click on navbar',

  ANALYTICS_CHANGE_PAGE = 'Change page in analytics',
  ANALYTICS_LANDED = 'Landed on Analytics',

  // CRM
  CLICK_TO_CRM = 'Click to CRM',

  TOGGLE_FULLSCREEN = 'Toggle full screen on video',

  CLICK_SELECT_SPEAKER = 'Click on select speaker control',

  DOWNLOAD_CALL = 'Download call',
  DOWNLOAD_TRANSCRIPT = 'Download transcript',

  CLICK_UNASSOCIATE_CONTACT = 'Click on unassociated contact dropdown',
  CLICK_REFRESH_CRM_CONTACT = 'Click on Refresh contact',
  CLICK_CREATE_CRM_CONTACT = 'Click on CRM link to create contact',
  CLICK_FILTER_UNASSOCIATED_CONTACT = 'Click on filter on unassociated contact with CRM',

  // Call details Sidebar
  OPEN_CALL_DETAILS_SIDEBAR_TAB = 'Open call details sidebar tab',

  // Info call-details sidebar tab
  TOGGLE_GENERAL_INFO_CARD = 'Collapse general info card',
  TOGGLE_CRM_INFO_CARD = 'Collapse crm info card',
  CLICK_CRM_ICON_CRM_INFO_CARD = 'Click on CRM icon in CRM info card',
  CLICK_CRM_HOVER_REDIRECT_CRM_INFO_CARD = 'Click on CRM hover redirect in CRM info card',

  // Transcript tab
  SEARCH_IN_TRANSCRIPT = 'Search in transcript',
  SELECT_TRANSCRIPT_LANGUAGE = 'Select transcript language',
  PLAY_TRANSCRIPTION_BLOCK = 'Play transcript block',
  COPY_TRANSCRIPTION_BLOCK = 'Copy transcript block',

  // Subtitles
  SELECT_CALL_SUBTITLES = 'Select call subtitles',

  // Auto-scroll transcript
  SCROLL_TO_CURRENT_TRANSCRIPTION_BLOCK = 'Scroll to current transcript block',
  DEACTIVATE_TRANSCRIPT_AUTOSCROLL = 'Deactivate transcript auto-scroll',

  CREATE_USER = 'Create user',
  UPLOAD_USERS = 'Upload users',
  EDIT_USER = 'Edit user',
  SWAP_SPEAKERS = 'Swap speakers',
  DELETE_USER = 'Delete user',

  CHANGE_SOUND_PLAY_SPEED = 'Change sound play speed',
  JUMP_BACKWARD = 'Jump backward in call',
  JUMP_FORWARD = 'Jump forward in call',
  PLAY_PAUSE_CALL = 'Play/Pause call',

  GO_TO_CALL_DETAILS = 'Go to call-details',

  EDIT_CALL_TITLE = 'Edit call title',
  CLEAR_SEARCH_BAR = 'Clear search in call details',
  SELECT_ANALYTICS_CARD = 'Select analytics card',
  SELECT_ANALYTICS_USER = 'Click on user breakdown',
  SELECT_TOPIC_CALL_DETAILS = 'Select topic in call details',

  TIMELINE_PREVIOUS = 'Timeline go to previous call',
  TIMELINE_NEXT = 'Timeline go to next call',

  USE_FILTER_ANALYTICS = 'Use filter in analytics',
  SAVE_DIGEST = 'Save Digest',
  DELETE_DIGEST = 'Delete Digest',

  ADD_A_TAG_TO_CALL = 'Add a tag to call',
  TOGGLE_CALL_SHARE = 'Toggle call share',

  CREATE_SNIPPET = 'Create Snippet',
  SNIPPET_DRAG_AND_DROP = "Snippet drag'n'drop",
  SNIPPET_INPUT = 'Snippet input',
  DOWNLOAD_SNIPPET = 'Download Snippet',

  CLICK_TAGS_GRAPH = 'Click on tags graph',

  CLICK_FILTER = 'Click on filter',
  APPLY_FILTER = 'Apply filter',
  ADD_MORE_FILTER = 'Add more filter',
  CLICK_SEARCHBAR = 'Click on searchbar',
  VALIDATE_SEARCH = 'Validate search',
  SEARCHBAR_VIEW_MORE = 'Searchbar view more',
  ERASE_FILTER = 'Erase filter',
  SELECT_DATE_PERIOD = 'Select date period',
  SELECT_HOME_TAB = 'Select home tab',
  CLICK_IMPORT_CALL = 'Click import call',
  UPLOAD_CALL = 'Upload call',
  EXPLORE_SEARCH_CALL = 'Search call in explore',
  SHARE_EXPLORE_FILTERS = 'Share explore filters',

  // Subscription
  REQUEST_PAYMENT_METHOD = 'Request payment method',
  CLICK_ADD_LICENCES = 'Click on add licences',
  CLICK_DELETE_LICENCES = 'Click on delete licences',

  // Sharing experience
  SHARE_CALL = 'Share call/snippet',

  // Nta
  ASSOCIATE_NOTE = 'Associate note',
  DOWNLOAD_LIVENOTES = 'Download nta',
  OPEN_LIVENOTES = 'Open LN',
  SHOW_MORE_ACTIVITY_CONTENT = 'show more Activity content',
  SHOW_LESS_ACTIVITY_CONTENT = 'show less Activity content',

  // Views filters
  CREATE_FILTERS_VIEW = 'Create filters view',
  DELETE_FILTERS_VIEW = 'Delete filters view',
  APPLY_FILTERS_VIEW = 'Apply filters view',
  RENAME_FILTERS_VIEW = 'Rename filters view',
  UPDATE_FILTERS_VIEW = 'Update filters view',
  DUPLICATE_FILTERS_VIEW = 'Duplicate filters view',
  TOGGLE_FILTERS_VIEWS_SIDEBAR = 'Toggle fitlers views sidebar',
  OPEN_DROPDOWN_FILTERS_VIEWS = 'Open dropdown filters views',

  // Embed
  CLICK_EMBED_CALL_TITLE = 'Embed - clicks on call title',

  // Call review setttings
  START_NEW_CALL_REVIEW = 'start new call review',
  DELETE_CALL_REVIEW = 'delete call review',
  CANCEL_CALL_REVIEW_VALIDATION = 'cancel call review validation',
  SAVE_AS_DRAFT_CALL_REVIEW_TEMPLATE = 'save as draft call review template',
  PUBLISH_CALL_REVIEW_TEMPLATE = 'publish call review template',
  UNPUBLISH_CALL_REVIEW_TEMPLATE = 'unpublish call review template',
  ARCHIVE_CALL_REVIEW_TEMPLATE = 'archive call review template',
  OPEN_NEW_CALL_REVIEW_TEMPALTE = 'open new call review template',

  // Livenotes template
  CREATE_NEW_LEVENOTES_TEMPLATE = 'create new live note template',
  SAVE_AS_DRAFT_LEVENOTES_TEMPLATE = 'save as draft live note template',
  PUBLISH_LIVENOTES_TEMPLATE = 'publish live note template',
  UNPUBLISH_LIVENOTES_TEMPLATE = 'unpublish live note template',
  ARCHIVE_LIVENOTES_TEMPLATE = 'archive live note template',
  ADD_CRM_FIELD_IN_TEMPLATE = 'Add CRM fields in template',
  ADD_CRM_FIELD_IN_TEMPLATE_ENTITY_TYPE = 'Add CRM fields in template : setup entity',
  CLICK_ON_LOG_NOTE_IN_CUSTOM_FIELD = 'Click on Log note in custom field',

  // export user data
  CLICK_ON_EXPORT_USER_DATA = 'click on export user data CTA',
  DOWNLOAD_USER_DATA_FILE = 'download user data file',

  // Integrations
  CLICK_ON_DELETE_INTEGRATION = 'click on delete integration',
  CONFIRM_INTEGRATION_DELETION = 'confirm integration deletion',
  SELECT_INTEGRATIONS_TAB = 'Select integrations tab',
  SEARCH_INTEGRATIONS = 'Search integrations marketplace',

  // Call review from call-details
  PUBLISH_NEW_CALL_REVIEW = 'publish new call review',
  ADD_TIMESTAMP_IN_CALL_REVIEW = 'add timestamp in call review',
  CLICK_TIMESTAMP_IN_COMMENT = 'click timestamp in comment',
  EXPORT_REVIEW = 'Export review',
  COPY_LINK_TO_REVIEW = 'Copy link to review',
  SHOW_MORE_REVIEW_DETAILS = 'show more review details',
  SHOW_LESS_REVIEW_DETAILS = 'show less review details',
  UPDATE_CALL_REVIEW_PRIVACY = 'update call review privacy',

  DELETE_CALL = 'delete call',

  // Watch Later
  ADD_CALL_TO_WATCH_LATER = 'Add call to Watch Later',
  REMOVE_CALL_FROM_WATCH_LATER = 'Remove call from Watch Later',
  UNDO_REMOVE_CALL_FROM_WATCH_LATER = 'Undo automatic Watch Later removal',

  // Health Check
  DISPLAY_HEALTH_CHECK_SNACKBAR = 'Display warning banner',
  CLICK_CTA_HEALTH_CHECK_SNACKBAR_REDIRECTION = 'Warning banner - click on redirection CTA',
  CLICK_POSTPONE_EMAIL_HEALTH_CHECK = 'Warning banner - postpone email health check',
  CLICK_CTA_HEALTH_CHECK_DOCUMENTATION = 'Redo integration - open help center',

  // User onboarding flow
  SIGN_UP = 'User onboarding funnel - Signs up',
  SELECT_CALENDAR = 'User onboarding funnel - Selects calendar',
  SELECT_EMAIL_PROVIDER = 'User onboarding funnel - Selects email provider',

  LANDED_ONBOARDING_CALENDAR_STEP = 'Landed on onboarding calendar step',
  LANDED_ONBOARDING_JOB_STEP = 'Landed on onboarding job step',
  LANDED_ONBOARDING_NAME_STEP = 'Landed on onboarding name step',
  LANDED_ONBOARDING_EMAIL_STEP = 'Landed on onboarding email step',

  // Login
  SIGN_IN = 'User signs-in',

  // Teams
  CREATE_TEAM = 'Create new team',
  UPDATE_TEAM = 'Update team',
  DELETE_TEAM = 'Delete team',
  CANCEL_TEAM_EDITION = 'Cancel team edition',
  SEARCH_TEAMS = 'Search teams list',

  // Right panel
  CLOSE_PANEL = 'Close sidebar',

  // Calendar in Home page
  CALENDAR_NO_INTEGRAION = 'calendar - Click on no calendar integration',

  CALENDAR_NAVIGATION = 'calendar - Navigation',
  CALENDAR_DETAILS_POPOVER = 'calendar - Open details popover',
  CALENDAR_JOIN_MEETING = 'calendar - Click on Join meeting',
  CALENDAR_EXTERNAL_CALENDAR = 'calendar - Open external calendar',

  CALENDAR_DETAILS_EXTERNAL_CALENDAR_EVENT = 'calendar details - Open external event',
  CALENDAR_DETAILS_CRM_CONTACT = 'calendar details - Click on CRM contact',
  CALENDAR_DETAILS_TOGGLE_ATTENDEES = 'calendar details - Toggle attendees',
  CALENDAR_DETAILS_LOAD_MORE_CALLS = 'calendar details - Load more calls',
  CALENDAR_DETAILS_TOGGLE_CALL_HISTORY = 'calendar details - Toggle call history',
  CALENDAR_DETAILS_OPEN_CALL_DETAILS = 'calendar details - Open call details',
  CALENDAR_DETAILS_PLAY_MOMENT = 'calendar details - Play moment',

  // Chapters in Call details page
  CHAPTERS_OPEN_CHAPTER_LIST = 'Chapters - Open chapters list',
  CHAPTERS_CLICK_CHAPTER_ITEM = 'Chapters - Click on chapter',

  // Settings
  SETTINGS_OPEN_SUBPAGE = 'Settings - Open settings sub-page',
  SETTINGS_CLICK_DOCUMENTATION = 'Settings - Click documentation',

  // Advanced Settings
  ADVANCED_SETTINGS_DOMAIN_NAMES_EDIT = 'Edit emails domains',
  ADVANCED_SETTINGS_DOMAIN_NAMES_SAVE = 'Save new emails domains',
  ADVANCED_SETTINGS_TOGGLE = 'Advanced settings - Toggle',
  ADVANCED_SETTINGS_EDIT = 'Advanced settings - Edit',
  ADVANCED_SETTINGS_SAVE = 'Advanced settings - Save',

  // Reminder Email
  RECEIVE_REMINDER_EMAIL_TEST = 'Receive email reminder test',
  REMINDER_EMAIL_BLOCK_SAVE = 'Apply reminder email content changes',

  // Tags
  CREATE_TAG = 'Create tag',
  EDIT_TAG = 'Edit tag',
  IMPORT_TAG = 'Import tag',
  DELETE_TAG = 'Delete tag',

  // Topics
  CREATE_TOPIC = 'Create topic',
  EDIT_TOPIC = 'Edit topic',
  DELETE_TOPIC = 'Delete topic',

  // Custom vocabulary
  ADD_TERM = 'Add new term',
  EDIT_TERM = 'Edit custom vocab',
  DELETE_TERM = 'Delete term',

  // Onboarding
  ONBOARDING_GO_TO_PREVIOUS_STEP = 'User onboarding funnel - Go to previous step',

  // Ai Assistant
  AI_ASSISTANT_CLOSE_BANNER = 'Ai Assistant banner - Close banner',
  AI_ASSISTANT_CLICK_DISCOVER_AI_ASSISTANT = 'Ai Assistant banner - Click on Discover Ai Assistant',

  // Livenotes
  OPEN_MY_NOTES_PAGE_IN_WEBAPP = 'open my notes page in webapp',
  OPEN_LIVENOTES_TEMPLATES_SETTINGS_IN_WEBAPP = 'open LN templates settings',

  // Integrations - Setup flow
  INTEGRATION_FUNNEL_START = 'Integration Funnel - START',
  INTEGRATION_FUNNEL_END = 'Integration Funnel - END',
  INTEGRATION_FUNNEL_CANCEL = 'Integration Funnel - CANCEL',
  INTEGRATION_FUNNEL_BACK = 'Integration Funnel - Back to integrations',
  INTEGRATION_FUNNEL_OPEN_DOCUMENTATION = 'Integration Funnel - Open documentation',
  INTEGRATION_FUNNEL_CREATE_USERS = 'Integration Funnel - Create users',
  INTEGRATION_FUNNEL_QUICK_LINKS = 'Integration Funnel - Quick links',

  // Integrations - Connected integration flow
  CONNECTED_INTEGRATION_SAVE_CHANGES = 'Connected Integration - Save changes',
  CONNECTED_INTEGRATION_AUTOMATCH_USERS = 'Connected Integration - Automatch users',
  CONNECTED_INTEGRATION_ASSOCIATE_MANUALLY_USER = 'Connected Integration - Associate manually user',
  CONNECTED_INTEGRATION_UNASSOCIATE_MANUALLY_USER = 'Connected Integration - Unassociate manually user',
  CONNECTED_INTEGRATION_SORT_USER_TABLE = 'Connected Integration - Sort user table',

  // Public page
  EXPAND_REDUCE_PUBLIC_BANNER = 'Public page - expand / reduce banner',
  CLICK_PUBLIC_DISCOVER_MODJO = 'Public page - click on "Discover Modjo"',
  CLICK_PUBLIC_SIGN_IN = 'Public page - Click on "Sign in"',

  // Ai Call Scoring
  // * Ai Call Scoring - Call details
  AI_SCORING_SHOW_DETAILS = 'Ai call scoring - Show details',
  AI_SCORING_HOVER_TOOLTIP = 'Ai call scoring - Hover tooltip',
  AI_SCORING_QUESTION_FEEDBACK = 'Ai call scoring - Question feedback',
  AI_SCORING_COPY_LINK = 'Ai call scoring - Copy link',
  AI_SCORING_GO_TO_TEMPLATES = 'Ai call scoring - Go to templates',
  AI_SCORING_CLICK_PLAYER_BUTTON = 'Ai call scoring - Click player button',
  // * Ai Call Scoring - Settings
  AI_SCORING_SETTINGS_CREATE_NEW_TEMPLATE = 'Ai call scoring - Create new template',
  AI_SCORING_SETTINGS_DUPLICATE_TEMPLATE = 'Ai call scoring - Duplicate template',
  AI_SCORING_SETTINGS_ARCHIVE_TEMPLATE = 'Ai call scoring - Archive template',
  AI_SCORING_SETTINGS_PUBLISH_TEMPLATE = 'Ai call scoring - Publish template',
  AI_SCORING_SETTINGS_UNPUBLISH_TEMPLATE = 'Ai call scoring - Unpublish template',
  AI_SCORING_SETTINGS_OPEN_TEMPLATE = 'Ai call scoring - Open template',
  AI_SCORING_SETTINGS_GO_BACK_TO_TEMPLATE_LIST = 'Ai call scoring - Go back to template list',
  AI_SCORING_SETTINGS_ADD_NEW_QUESTION = 'Ai call scoring - Add new question',
  AI_SCORING_SETTINGS_EDIT_QUESTION = 'Ai call scoring - Edit question',
  AI_SCORING_SETTINGS_DELETE_QUESTION = 'Ai call scoring - Delete question',
  AI_SCORING_SETTINGS_OPEN_TIPS_FOR_QUESTION = 'Ai call scoring - Open tips for question',

  // Highlights
  HIGHLIGHTS_SETTINGS_CREATE_NEW_TEMPLATE = 'Highlights - Create new template',
  HIGHLIGHTS_SETTINGS_SAVE_TEMPLATE = 'Highlights - Save template',
  HIGHLIGHTS_SETTINGS_PUBLISH_TEMPLATE = 'Highlights - Publish template',
  HIGHLIGHTS_SETTINGS_UNPUBLISH_TEMPLATE = 'Highlights - Unpublish template',
  HIGHLIGHTS_SETTINGS_DELETE_TEMPLATE = 'Highlights - Delete template',
  HIGHLIGHTS_SETTINGS_DUPLICATE_TEMPLATE = 'Highlights - Duplicate template',
  HIGHLIGHTS_SETTINGS_SELECT_SHELF_CATEGORY = 'Highlights - Select shelf category',
  HIGHLIGHTS_SETTINGS_SELECT_SCRATCH_CATEGORY = 'Highlights - Select scratch category',
  HIGHLIGHTS_SETTINGS_EDIT_CATEGORY = 'Highlights - Edit category',
  HIGHLIGHTS_SETTINGS_DELETE_CATEGORY = 'Highlights - Delete category',
  HIGHLIGHTS_COPY_CONTENT = 'Summary - Copy content',

  // Summary
  SUMMARY_SETTINGS_CREATE_NEW_TEMPLATE = 'Summary - Create new template',
  SUMMARY_SETTINGS_SAVE_TEMPLATE = 'Summary - Save template',
  SUMMARY_SETTINGS_PUBLISH_TEMPLATE = 'Summary - Publish template',
  SUMMARY_SETTINGS_UNPUBLISH_TEMPLATE = 'Summary - Unpublish template',
  SUMMARY_SETTINGS_DELETE_TEMPLATE = 'Summary - Delete template',
  SUMMARY_SETTINGS_DUPLICATE_TEMPLATE = 'Summary - Duplicate template',
  SUMMARY_SETTINGS_SELECT_SHELF_CATEGORY = 'Summary - Select shelf category',
  SUMMARY_SETTINGS_SELECT_SCRATCH_CATEGORY = 'Summary - Select scratch category',
  SUMMARY_SETTINGS_EDIT_CATEGORY = 'Summary - Edit category',
  SUMMARY_SETTINGS_DELETE_CATEGORY = 'Summary - Delete category',
  SUMMARY_COPY_CONTENT = 'Summary V2 - Copy content',

  // AI CRM Filling
  AI_CRM_FILLING_SETTINGS_OPEN_TEMPLATE = 'AI CRM Filling - Open template',
  AI_CRM_FILLING_SETTINGS_CREATE_NEW_TEMPLATE = 'AI CRM Filling - Create new template',
  AI_CRM_FILLING_SETTINGS_PUBLISH_TEMPLATE = 'AI CRM Filling - Publish template',
  AI_CRM_FILLING_SETTINGS_UNPUBLISH_TEMPLATE = 'AI CRM Filling - Unpublish template',
  AI_CRM_FILLING_SETTINGS_ARCHIVE_TEMPLATE = 'AI CRM Filling - Archive template',
  AI_CRM_FILLING_SETTINGS_DUPLICATE_TEMPLATE = 'AI CRM Filling - Duplicate template',
  AI_CRM_FILLING_SETTINGS_GO_BACK_TO_TEMPLATE_LIST = 'AI CRM Filling - Go back to template list',
  AI_CRM_FILLING_PUSH_FIELD = 'AI CRM Filling - Click on push',
  AI_CRM_FILLING_SIDEBAR_CANCEL = 'AI CRM Filling - Click on cancel in sidebar',
  AI_CRM_FILLING_SIDEBAR_OPEN = 'AI CRM Filling - Open sidebar',

  // Email Follow-up
  EMAIL_FOLLOW_UP_SETTINGS_OPEN_TEMPLATE = 'Email Follow-up - Open template',
  EMAIL_FOLLOW_UP_SETTINGS_PUBLISH_TEMPLATE = 'Email Follow-up - Publish template',
  EMAIL_FOLLOW_UP_SETTINGS_UNPUBLISH_TEMPLATE = 'Email Follow-up - Unpublish template',
  EMAIL_FOLLOW_UP_SETTINGS_DELETE_TEMPLATE = 'Email Follow-up - Delete template',
  EMAIL_FOLLOW_UP_SETTINGS_DUPLICATE_TEMPLATE = 'Email Follow-up - Duplicate template',
  EMAIL_FOLLOW_UP_SETTINGS_SET_DEFAULT_TEMPLATE = 'Email Follow-up - Set default template',
  EMAIL_FOLLOW_UP_SETTINGS_UNSET_DEFAULT_TEMPLATE = 'Email Follow-up - Unset default template',
  EMAIL_FOLLOW_UP_COPY_AI_EMAIL = 'Email Follow-up - Copy AI email',
  EMAIL_FOLLOW_UP_GENERATE_AI_EMAIL = 'Email Follow-up - Generate AI email',

  // Playground
  PLAYGROUND_OPEN = 'Prompt Playground - Open prompt Playground',
  PLAYGROUND_SUBMIT_TEST = 'Prompt Playground - Submit test',
  PLAYGROUND_CLEAR = 'Prompt Playground - Clear all results',
  PLAYGROUND_COPY_PROMPT = 'Prompt Playground - Copy prompt',
  PLAYGROUND_OPEN_PREVIOUS_TEST = 'Prompt Playground - Open previous test',

  // Error
  ERROR_PAGE_BACK_HOME = 'Error page - Go back home',

  // Recording bot
  CLICK_ADD_RECORDER_BOT_TO_LIVE_MEETING = 'Click add recorder bot to a live meeting',
  EDIT_RECORDING_BOT_OPTION = 'Edit recording bot option',

  // Variable
  ADD_REMINDER_EMAIL_VARIABLE = 'Add reminder email variable',
  ADD_SUMMARY_VARIABLE = 'Add summary variable',

  // Modjo Physical recorder
  CLICK_OPEN_PHYISICAL_RECORDER = 'Click open physical recorder',
  MICROPHONE_PERMISSION_ACCEPTED = 'Microphone permission accepted',
  MICROPHONE_PERMISSION_REFUSED = 'Microphone permission refused',
  CLOSE_PHYISICAL_RECORDER = 'close physical recorder',
  START_RECORDING = 'start recording',
  STOP_RECORDING = 'stop recording',
  PAUSE_RECORDING = 'pause recording',
  RESUME_RECORDING = 'resume recording',
  SAVE_RECORDING = 'save recording',
  DELETE_RECORDING = 'delete recording',
  IMPORT_RECORDING = 'import recording',
  REACHED_MAX_RECORDING_DURATION = 'Reached max recording duration',
  CONTINUE_RECORDING_AFTER_MAX_DURATION = 'Continue recording after max duration',
  STOP_RECORDING_AFTER_MAX_DURATION = 'Stop recording after max duration',
}

export const SNIPPET_CREATION_POPUP = 'snippet_creation_popup';
export type CropCallType = SnippetCreationMode.DRAG_AND_DROP | SnippetCreationMode.PLAYER_BUTTON;

export enum WatchLaterRemovingType {
  MANUAL = 'manual',
  AUTO = 'automatic',
}

export const enum PlayPauseAction {
  PLAY = 'play',
  PAUSE = 'pause',
}

export const enum PlayPauseBehavior {
  KEYBOARD = 'keyboard',
  CLICK = 'click',
  UNKNOWN = 'unknown - public',
}

export const enum PlayerPage {
  EMBED = 'embedded',
  PUBLIC_CALL_DETAILS = 'public call details',
  PRIVATE_CALL_DETAILS = 'private call details',
}

export enum SelectMode {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
}

export enum SharingMode {
  SLACK_CHANNEL = 'Slack Channel',
  COPY_LINK = 'Copy link',
  COPY_EMBED_CODE = 'Copy embed code',
}

/**
 * What is the ressource we are sharing.
 */
export enum SharingType {
  SNIPPET = 'snippet',
  CALL = 'call',
}

/**
 * What is the ressource we are sharing.
 */

export enum SharingAccessRight {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

/**
 * What is the button triggering the share.
 * ... Icon are referring to button without label i.e. IconButtonGhost
 */
export enum SharingClickOrigin {
  SLACK_ICON = 'slack Icon',
  SLACK_BUTTON = 'modal Slack Button',
  COPY_ICON = 'copy Icon',
  COPY_BUTTON = 'modal copy Button',
  COPY_EMBED_CODE_BUTTON = 'modal copy embed Button',
  COMMENT_ACTION_BAR = 'Conmment action bar',
}

export enum EventType {
  CLICK = 'click',
  SHORTCUT = 'shortcut',
}

export enum SnippetCreationOption {
  LIBRARY = 'library',
  DOWNLOAD = 'download',
  COMMENT = 'from_comment',
  SLACK = 'slack',
  COPY_LINK = 'copy link',
  COPY_EMBED_CODE = 'copy embed code',
}

export enum EventAnalyticsFilter {
  USER = 'user',
  TEAM = 'team',
  TAG = 'tag',
  DEAL_STATUS = 'dealStatus',
  DEAL_STAGE = 'dealStage',
  REVIEW_TEMPLATE = 'review template',
  AI_SCORING_TEMPLATE = 'call scoring template',
  AI_SCORING_QUESTION = 'call scoring question',
  DATE = 'date',
  DURATION = 'Duration',
  SAID_BY = 'saidBy',
  WORDS = 'searchPhrase',
}

export enum SubtitlesActionType {
  ACTIVATION = 'activation',
  DEACTIVATION = 'deactivation',
}

// Call review from call-details
export enum TimestampDetectionType {
  TEXT = 'text',
  CTA = 'cta',
}

export enum TimestampClickOrigin {
  REVIEWS = 'reviews',
  COMMENTS = 'comments',
}

export const NO_LANGUAGE = 'No language';

export enum AuthenticationType {
  EMAIL = 'email',
}

export enum LivenotesClickOrigin {
  HOME_PAGE_BANNER = 'Home page banner',
  LIVENOTES_NAVBAR = 'LN navbar',
  LIVENOTES_TEMPLATES_SETTINGS_PAGE = 'LN templates settings page',
  LIVENOTES_APP_SETTINGS_PAGE = 'LN app settings page',
  LIVENOTES_MY_NOTES_PAGE = 'LN my notes page',
}

export enum DealListFilter {
  TEAMS = 'teams',
  USERS = 'users',
  WARNING = 'warning',
  CLOSE_DATE = 'closeDate',
  OBJECTION = 'objection',
  STAGES = 'stage',
  TYPES = 'type',
}

export enum DealCRMLink {
  DEAL = 'deal',
  NEXT_FIELD = 'next-field',
}

export enum DealViewFilterInteractionSource {
  KEY_CONTACT = 'key-contact',
  CONTACT_OBJECTION = 'contact-objection',
  DROPDOWN_FILTER = 'dropdown-filter',
  DROPDOWN_FILTER_OBJECTION = 'dropdown-filter-objection',
}

export enum EditViewOrigin {
  SIDEBAR = 'sidebar',
  HEADER = 'header',
  SAVE_BUTTON = 'save-button',
}

export enum EventOrigin {
  ANALYTICS = 'analytics',
  HOME = 'home',
  SEARCHBAR = 'searchbar',
  WATCH_LATER = 'home watch later',
  EXPLORE = 'explore',
  CALL_DETAILS = 'call-details',
  DEALS_LIST = 'deals-list',
  DEAL_VIEW = 'deal-view',
  DEAL_VIEW_CONTACT_OBJECTION = 'deal view - contact objection',
  DEAL_VIEW_ANSWER_OBJECTION = 'deal view - sales answer to objection',
  DEAL_VIEW_ASK_ANYTHING = 'deal view - ask anything',
  SPECTRUM = 'spectrum',
  CALL_ACTIVITIES = 'Call activities',
  SETTINGS_TEMPLATE_TABLE_PAGE = 'table-page',
  SETTINGS_TEMPLATE_EDITION_PAGE = 'template-edition-page',
  LIBRARY = 'library',
  NOTIFICATIONS = 'notifications center',
  NOTES = 'livenotes page',
  CRM_FILLING = 'crm filling',
}

export enum GenerateEmailOrigin {
  SETTINGS_TEST = 'settings-test',
  CALL_DETAILS_BUTTON = 'call-details-button',
  CALL_DETAILS_MODAL_BUTTON = 'call-details-modal-button',
  CALL_DETAILS_NOTIFICATION = 'call-details-notification',
}
