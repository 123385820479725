import React, { useEffect, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import Downshift from 'downshift';
import mixpanel from 'mixpanel-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { Key } from 'ts-key-enum';

import {
  defaultItemToString,
  FiltersCategories,
  getOptionType,
  getViewMoreOption,
} from '@/components/common/search-components/Common';
import { Footer } from '@/components/common/search-components/Footer';
import {
  SearchableObject,
  SearchableObjectOptionType,
} from '@/components/common/search-components/search-components.types';
import { ClearAdornment, CrossAdormnemt } from '@/components/common/ui-components/inputs/adornments';
import { InputWithAdornments } from '@/components/common/ui-components/inputs/text/InputWithAdornments';
import { updateParams, useUrlFilters } from '@/components/explore/useUrlFilters';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { RoutePaths } from '@/enums';
import SearchIcon from '@/static/icons/basic/search-Line.svg?react';
import { Event } from '@/tracking/events';
import { isEmpty } from '@/utils/utils';

import { FiltersContentPopover } from './FiltersContentPopover';
import { computePlaceholder, makeSearchNavbar } from './helpers';
import { InputChips } from './InputChips';
import { useSearchFilters } from './search-hook';
import { useSearchContext } from './SearchProvider';
import { SimpleSearchDropdown } from './SimpleSearchDropdown';
import { searchStore } from '../search.store';

const MAIN_SEARCH_ID = 'main-search';
const PRINTABLE_CHARACTERS_REGEX = /^[ -~À-ÿ]$/;

const SearchContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantQuery = useTenantQuery();
  const { period } = useUrlFilters();

  const { selectedFilters, setSelectedFilters, deleteContextFilters, updateContextFilters } = useSearchContext();
  const [options, setInputValue] = useSearchFilters();

  const inputReference = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>;

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<FiltersCategories>();
  const [initialInputValue, setInitialInputValue] = useState<string>('');
  const [categoryHistory, setCategoryHistory] = useState<FiltersCategories[]>([]);

  const handleChangeCategory = (category: FiltersCategories | undefined) => {
    if (!category) {
      setCategoryHistory([]);
    } else if (selectedCategory) {
      setCategoryHistory((previousState) => [...previousState, selectedCategory]);
    }
    setSelectedCategory(category);
  };

  const handlePopoverClose = () => {
    handleChangeCategory(undefined);
    setCategoryHistory([]);
    setIsPopoverOpen(false);
  };

  const validateSearch = (values?: SearchableObject[]) => {
    handlePopoverClose();
    searchStore.setIsSearchOpen(false);
    updateContextFilters(values ?? []);
    const newFilters = makeSearchNavbar(navigate, selectedFilters, values);
    const isNavigatingToCallDetailsOrDealView = values?.some((v) =>
      [SearchableObjectOptionType.CALL_TITLE, SearchableObjectOptionType.DEAL].includes(
        getOptionType(v) as SearchableObjectOptionType
      )
    );
    inputReference.current.blur();
    if (!isNavigatingToCallDetailsOrDealView) {
      let searchParams = new URLSearchParams();
      searchParams = updateParams(searchParams, {
        ...newFilters,
        period,
      });
      navigate({
        pathname: RoutePaths.EXPLORE,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  const switchFromViewMore = (category: FiltersCategories, value: string) => {
    setIsPopoverOpen(true);
    setSelectedCategory(category);
    setInitialInputValue(value);
  };

  const popoverReference = useRef<HTMLDivElement>(null);

  useClickAway(
    popoverReference,
    (event: MouseEvent) => {
      const paths = event.composedPath();
      if (!paths.some((target) => (target as HTMLElement).id === MAIN_SEARCH_ID)) {
        searchStore.setIsSearchOpen(false);
        handlePopoverClose();
      }
    },
    ['mouseup']
  );

  const lastCategory = categoryHistory.at(-1) ?? undefined;

  const goToPreviewCategory = () => {
    setSelectedCategory(lastCategory);
    categoryHistory.pop();
  };

  const focusInputField = () => {
    if (inputReference.current) {
      (inputReference.current.children[1] as HTMLElement).focus();
    }
  };

  useEffect(() => {
    focusInputField();
    return () => setSelectedFilters([]);
  }, [setSelectedFilters]);

  return (
    <Downshift
      onInputValueChange={setInputValue}
      onChange={(value: SearchableObject | null) => {
        const [searchOrigin] = location.pathname.split('/').slice(1);
        if (value && !value.name.includes(getViewMoreOption())) {
          mixpanel.track(Event.VALIDATE_SEARCH, { searchOrigin, filterName: getOptionType(value) });
          setSelectedFilters([value]);
          validateSearch([value]);
        } else if (value?.name.includes(getViewMoreOption())) {
          mixpanel.track(Event.SEARCHBAR_VIEW_MORE, { clickOrigin: origin, filterName: getOptionType(value) });
          switchFromViewMore(
            value.name.split('-')[0] as SearchableObjectOptionType,
            value.name.split(getViewMoreOption())[1]
          );
        } else if (!value) {
          setSelectedFilters([]);
          validateSearch([]);
        }
      }}
      itemToString={defaultItemToString}
    >
      {({ getInputProps, getMenuProps, getItemProps, clearSelection, isOpen, inputValue, highlightedIndex }) => (
        <div className="relative z-50 w-full overflow-hidden rounded-2xl bg-grey-white pt-1 shadow-card-hover sm:w-[500px]">
          <InputWithAdornments
            id={MAIN_SEARCH_ID}
            ref={inputReference}
            pill={false}
            className="h-8 w-full"
            inputProps={{
              ...getInputProps({
                onKeyDown: (event) => {
                  event.stopPropagation();
                  if (PRINTABLE_CHARACTERS_REGEX.test(event.key)) {
                    handlePopoverClose();
                  }
                  if (event.key === Key.Escape) {
                    searchStore.setIsSearchOpen(false);
                  }

                  if (!inputValue || inputValue.length === 0) {
                    if (event.key === Key.Backspace) {
                      const lastSelectedFilter = selectedFilters.at(-1);
                      lastSelectedFilter && deleteContextFilters(undefined, [lastSelectedFilter]);
                    } else if (event.key === Key.Enter) {
                      validateSearch();
                    }
                  }

                  // We want to focus the first element of the list when the user press the down arrow.
                  // We need to do it manually because when the input has captured the event, the arrow keys
                  // are not propagated to the category list
                  if (isPopoverOpen && event.key === Key.ArrowDown) {
                    (inputReference.current.children[1] as HTMLElement).focus();
                    (popoverReference.current?.querySelector('ul') as HTMLElement).focus();
                  }
                },
              }),
              placeholder: computePlaceholder(selectedFilters, selectedCategory),
            }}
            size="large"
            onClick={() => {
              if (inputValue && inputValue.length > 0) {
                return;
              }
              if (!isPopoverOpen) {
                const [clickOrigin] = location.pathname.split('/').slice(1);
                setIsPopoverOpen(true);
                mixpanel.track(Event.CLICK_SEARCHBAR, { clickOrigin });
              }
            }}
            disableFocusRing
            startAdornment={
              <>
                <SearchIcon className="size-3.5 fill-grey-800" />
                <InputChips category={selectedCategory} previousCategory={lastCategory} />
              </>
            }
            endAdornment={
              inputValue || selectedFilters.length > 0 ? (
                <ClearAdornment
                  onClick={() => {
                    clearSelection();
                    inputReference.current.focus();
                    setIsPopoverOpen(true);
                  }}
                />
              ) : (
                <CrossAdormnemt
                  onClick={() => {
                    searchStore.setIsSearchOpen(false);
                  }}
                />
              )
            }
          />
          <SimpleSearchDropdown
            options={options}
            inputValue={inputValue}
            isOpen={isOpen && !isPopoverOpen}
            highlightedIndex={highlightedIndex}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            switchFromViewMore={switchFromViewMore}
          />
          <Transition show={isPopoverOpen || !inputValue} appear>
            <div
              ref={popoverReference}
              role="combobox"
              aria-expanded={isPopoverOpen}
              aria-controls="menu"
              aria-label="menu"
              tabIndex={0}
              className="w-full"
              onKeyDown={(event) => {
                if (isEmpty(selectedCategory) && PRINTABLE_CHARACTERS_REGEX.test(event.key)) {
                  focusInputField();
                  handlePopoverClose();
                }
              }}
            >
              <FiltersContentPopover
                options={options}
                selectedCategory={selectedCategory}
                onChangeSelectedCategory={handleChangeCategory}
                onValidateSearch={validateSearch}
                initialInputValue={initialInputValue}
                setInitialInputValue={setInitialInputValue}
                onReturn={goToPreviewCategory}
                previousCategory={lastCategory}
                crm={tenantQuery.data?.crm}
              />
            </div>
          </Transition>
          <Footer />
        </div>
      )}
    </Downshift>
  );
};

export { SearchContent };
