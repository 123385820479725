import React from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RestrictedError } from '@/components/common/errors/fallback/RestrictedError';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { RoutePaths } from '@/enums';
import HomeIcon from '@/static/icons/basic/home-3-Line.svg?react';
import LoginIcon from '@/static/icons/basic/log-in-2.svg?react';
import LockIcon from '@/static/Lock.svg?react';
import { Event } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';
import { AdvancedSettingsFeature } from '@/utils/settings';

import { ButtonFilled } from '../../ui-components/buttons/UIButtonFilled';

type ForbiddenErrorFallbackBodyProps = {
  feature: AdvancedSettingsFeature;
};

export const ForbiddenErrorFallbackBody = ({ feature }: ForbiddenErrorFallbackBodyProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUserQuery = useCurrentUserQuery();
  return (
    <RestrictedError.Page data-test-id={DataTestId.FORBIDDEN_ERROR_FALLBACK_BODY_ID}>
      <RestrictedError.Modal>
        <LockIcon className="size-14" />
        <div className="flex-center flex-col gap-y-2 text-center">
          <span className="text-base font-semibold">{t('errorPages.forbiddenPage.title')}</span>
          <span className="text-grey-800">{t('errorPages.forbiddenPage.text')}</span>
        </div>
        <div className="flex-center">
          {currentUserQuery.data ? (
            <ButtonFilled
              icon={<HomeIcon />}
              onClick={() => {
                mixpanel.track(Event.ERROR_PAGE_BACK_HOME, { feature });
                navigate(RoutePaths.HOME);
              }}
              color="third"
              size="small"
            >
              {t('errorPages.forbiddenPage.backToModjoButton')}
            </ButtonFilled>
          ) : (
            <ButtonFilled icon={<LoginIcon />} onClick={() => navigate(RoutePaths.LOGIN)} color="third" size="small">
              {t('errorPages.forbiddenPage.backToLoginButton')}
            </ButtonFilled>
          )}
        </div>
      </RestrictedError.Modal>
    </RestrictedError.Page>
  );
};
