import React from 'react';

import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useUrlFilters } from '@/components/explore/useUrlFilters';
import { useViewById, useViewsByType } from '@/entities/views/views.hooks';
import { ViewType } from '@/entities/views/views.types';
import { RoutePaths } from '@/enums';
import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import { Event } from '@/tracking/events';

import { ViewDropdownItem } from './ViewDropdownItem';
import { EllipsedText } from '../../ui-components/data-display/EllipsedText';
import { SimplePopover } from '../../ui-components/popover';

type ViewsDropdownProps = {
  viewType: ViewType;
};

const ViewsDropdown = ({ viewType }: ViewsDropdownProps) => {
  const { t } = useTranslation();
  const views = useViewsByType(viewType);
  const { viewId } = useUrlFilters();
  const currentView = useViewById(viewId);

  return (
    <SimplePopover
      placement="bottom-start"
      togglerFullWidth
      onOpenChange={(isOpen) => {
        mixpanel.track(Event.OPEN_DROPDOWN_FILTERS_VIEWS, {
          type: viewType,
          newState: isOpen ? 'open' : 'close',
        });
      }}
      togglerComponent={
        <button type="button" className="flex-center w-full gap-x-2.5 font-semibold">
          {currentView ? (
            <EllipsedText>{currentView.label}</EllipsedText>
          ) : (
            <span className="first-letter:capitalize">{t(viewType)}</span>
          )}
          <ArrowDownChevronIcon className="icon-small shrink-0" />
        </button>
      }
    >
      <Popover.Panel as="ul" className="flex w-48 flex-col gap-y-1 p-2.5">
        {({ close: closeDropdown }) => (
          <>
            <li>
              <NavLink
                to={viewType === 'deals' ? RoutePaths.DEALS : RoutePaths.EXPLORE}
                className={clsx(
                  'flex rounded-lg px-2 py-1 transition-colors hover:bg-grey-100',
                  !currentView && 'bg-grey-100'
                )}
                onClick={() => closeDropdown()}
              >
                <span className="first-letter:capitalize">{t(viewType)}</span>
              </NavLink>
            </li>
            {views.map((view) => (
              <li key={view.id}>
                <ViewDropdownItem {...view} viewType={viewType} closeDropdown={closeDropdown} />
              </li>
            ))}
          </>
        )}
      </Popover.Panel>
    </SimplePopover>
  );
};

export { ViewsDropdown };
