import { useEffect, useState } from 'react';

import { useIntercom } from 'react-use-intercom';

import { useCurrentUserQuery } from '@/entities/users/users.queries';

export const IntercomMessengerLauncher = () => {
  const { boot, update } = useIntercom();
  const [hasBooted, setHasBooted] = useState(false);
  const currentUserQuery = useCurrentUserQuery();
  const isPublic = window.location.pathname.startsWith('/call/') || window.location.pathname.startsWith('/embed/');

  useEffect(() => {
    if (!isPublic && !hasBooted && currentUserQuery.isSuccess && currentUserQuery.data) {
      boot({ hideDefaultLauncher: true });
      setHasBooted(true);
      update({
        name: currentUserQuery.data.name,
        email: currentUserQuery.data.email,
      });
    }
  }, [currentUserQuery.isSuccess, currentUserQuery.data, boot, update, hasBooted, isPublic]);

  return null;
};
