import React from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { NavbarTab } from '@/enums';
import { useShortcutKey } from '@/hooks/useShortcutKey';
import SearchIcon from '@/static/icons/basic/search-Line.svg?react';
import { Event } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';

import { searchStore } from './SearchDialog/search.store';
import { CmdKeySymbol } from '../../common/KeysSymbols';
import { ShortcutKey } from '../../common/ShortcutKey';
import { sidebarStore } from '../sidebar.store';

const triggerSearchModal = (source: 'shortcut' | 'button') => {
  searchStore.setIsSearchOpen(true);
  mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.SEARCH, source });
};

const SearchButton = () => {
  const { t } = useTranslation();
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();

  useShortcutKey({ key: 'k', metaKey: true, callback: () => triggerSearchModal('shortcut') });

  return (
    <button
      onClick={() => triggerSearchModal('button')}
      type="button"
      className="flex w-full items-center justify-between rounded-md bg-grey-100 p-2 outline-offset-2 outline-grey"
      data-test-id={DataTestId.SEARCH_BUTTON_ID}
    >
      <div className="flex items-center gap-x-2">
        <SearchIcon className="icon-small fill-grey-800" />
        <span className={clsx('capitalize text-grey-800', !isSidebarOpen && 'hidden')}>{t('navbar.search')}</span>
      </div>

      <ShortcutKey className={clsx('flex gap-x-0.5', !isSidebarOpen && 'hidden')}>
        <CmdKeySymbol />K
      </ShortcutKey>
    </button>
  );
};

SearchButton.displayName = 'SearchButton';

export { SearchButton };
