import { persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { ViewType } from '@/entities/views/views.types';
import { LOCALSTORAGE_SIDEBAR_STORE_KEY } from '@/enums/localstorage-keys';
import { createMethods } from '@/utils/zustand-utils';

type SidebarStore = {
  isSidebarOpen: boolean;
  openViews: Record<ViewType, boolean>;
};

const initialState: SidebarStore = {
  isSidebarOpen: true,
  openViews: {
    explore: true,
    deals: true,
  },
};

const store = createWithEqualityFn(
  persist(() => initialState, {
    name: LOCALSTORAGE_SIDEBAR_STORE_KEY,
    partialize: (state) =>
      ({
        isSidebarOpen: state.isSidebarOpen,
        openViews: state.openViews,
      }) as SidebarStore,
  })
);

const methods = createMethods(store);

const sidebarStore = {
  ...methods,
  toggleSidebar() {
    store.setState((state) => ({ isSidebarOpen: !state.isSidebarOpen }));
  },
  setOpenViews(value: Record<ViewType, boolean> | ((prev: Record<ViewType, boolean>) => Record<ViewType, boolean>)) {
    store.setState((state) => ({ openViews: typeof value === 'function' ? value(state.openViews) : value }));
  },
};

export { sidebarStore };
