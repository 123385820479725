import React from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { Badge } from '@/components/common/ui-components/data-display/Badge';
import { MaybeInATooltip } from '@/components/common/ui-components/data-display/tooltip/MaybeInATooltip';
import { SidebarButton } from '@/components/common/ui-components/layout/SidebarButton';
import { Position } from '@/components/common/ui-components/types';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { NavbarTab } from '@/enums';
import BellIcon from '@/static/icons/alerte/bell-Line.svg?react';
import { Event } from '@/tracking/events';

import { sidebarStore } from '../sidebar.store';

type NotificationButtonProps = {
  isMenuOpen?: boolean;
  unreadNotificationsCount: number;
};

const NotificationButton = ({ isMenuOpen, unreadNotificationsCount }: NotificationButtonProps) => {
  const { t } = useTranslation();
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();
  const currentUserQuery = useCurrentUserQuery();

  const handleOnClick = () => {
    mixpanel.track(Event.CLICK_ON_NAVBAR, {
      navbarTab: NavbarTab.NOTIFICATIONS,
      hasLicense: currentUserQuery.data?.hasLicense ?? false,
    });
  };

  const badgeLabel = unreadNotificationsCount < 100 ? unreadNotificationsCount : '99+';

  return (
    <MaybeInATooltip
      withTooltip={!isSidebarOpen}
      variant="small"
      contentClassName="capitalize"
      content={t('navbar.notifications.button')}
      placement="right"
    >
      <SidebarButton variant="menu" isMenuOpen={isMenuOpen} onClick={handleOnClick} isSidebarOpen={isSidebarOpen}>
        <div className="flex w-full items-center justify-between gap-x-2">
          <div className="flex items-center justify-start gap-x-2">
            <Badge
              label={badgeLabel}
              invisible={isSidebarOpen}
              anchorOrigin={{ vertical: Position.BOTTOM }}
              className={clsx(
                'aspect-square h-3.5 rounded bg-red text-2xs text-grey-white',
                unreadNotificationsCount > 100 && 'w-5.5'
              )}
            >
              <SidebarButton.Icon lineIcon={BellIcon} />
            </Badge>

            <SidebarButton.Label>{t('navbar.notifications.button')}</SidebarButton.Label>
          </div>

          <Badge
            label={badgeLabel}
            invisible={!isSidebarOpen}
            className="h-4 rounded bg-red p-1 text-2xs text-grey-white"
          />
        </div>
      </SidebarButton>
    </MaybeInATooltip>
  );
};

NotificationButton.displayName = 'NotificationButton';

export { NotificationButton };
