export enum AlertVariant {
  Error = 'error',
  Down = 'down',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Loading = 'loading',
  Processing = 'processing',
  EmailDown = 'email-down',
}

export enum AlertMessage {
  DOWNLOAD_SNIPPET = "Preparing file, you'll receive a notification once it is ready for download.",
  ADD_TO_LIBRARY_SUCCESS = 'Call added to library',
  ADD_TO_LIBRARY_ERROR = 'Oops, we were not able to add the library to the call',
  REMOVE_FROM_LIBRARY_SUCCESS = 'Removed from library successfully',
  REMOVE_FROM_LIBRARY_ERROR = 'Oops, we were not able to delete the library from the call',
  COPY_TEMP_PASSWORD = 'Temporary password copied to clipboard!',
  FORMAT_NOT_SUPPORTED = 'This file format is not supported for manual uploads',
  UPLOAD_CALL_PROCESSING = 'Uploading call...',
  UPLOAD_CALL_SUCCESS = 'Processing call...  Please wait a few minutes! You will receive a notification once it has been uploaded.',
  UPLOAD_CALL_ERROR = 'Upload failed, please retry.',
  GENERIC_ERROR = 'Oops, something went wrong.',
  TRANSCRIPT_ERROR = 'Oops, something went wrong with the transcript.',
  TIMELINE_ERROR = 'Oops, something went wrong with the timeline.',
  ERROR_REFRESH = 'Try to refresh the page or contact us if the problem persists.',
  CALL_SHARED_IN_CHANNEL_SUCCESS = 'Perfect 👌 Your call has been shared!',
  CALL_SHARED_IN_CHANNEL_ERROR = 'Oops, something went wrong while sharing your call.',
  SUCCESS_PUBLIC_INFO = 'Anyone with the link can access it',
  CREATE_COMMENT_ERROR = 'Oops, something went wrong while creating your comment.',
  SETTINGS_USER_ERROR = 'Oops, something went wrong while gettings settings Users.',
  SAVE_VIEW_SUCCESS = 'Perfect, your view was created successfully.',
  SAVE_VIEW_ERROR = 'Oops, something went wrong while creating your view.',
  TEST_SEND_EMAIL_EVENT_REMINDER_ERROR = 'Oops, something went wrong while sending your test email.',
  CALL_REVIEW_DONE_SUCCESS = 'Review posted!',
  CALL_REVIEW_DONE_ERROR = 'Oops, something went wrong while posting your review.',
  TRANSCRIPTION_BLOCK_COPIED = 'Transcription block copied!',
  CALL_ADDED_TO_WATCH_LATER = 'Call added to Watch Later playlist',
  CALL_REMOVE_FROM_WATCH_LATER = 'Call removed from Watch Later playlist',
  CALL_ADDED_TO_WATCH_LATER_DUPLICATED = 'This call is already in Watch later playlist',
  HIGHLIGHTS_CONTENT_COPIED = 'Highlights copied!',
  SUMMARY_CONTENT_COPIED = 'Summary copied!',
  CALL_REVIEW_COPIED_LINK = 'Review link copied!',
  PROVIDER_OPTIONS_SUCCESS = 'Options saved successfully!',
  INTEGRATION_ERROR = 'Oops, something went wrong setting up the integration. Please try again.',
  CREATE_TAG_SUCCESS = 'Tag created successfully!',
  EDIT_TAG_SUCCESS = 'Tag edited successfully!',
  DELETE_TAG_SUCCESS = 'Tag deleted successfully!',
  SYNC_TAG_SUCCESS = 'Tags synchronized successfully!',
  FREE_TRIAL_CREATION_SUCCESS = 'The trial has been successfully created!',
  FREE_TRIAL_UPDATE_SUCCESS = 'The trial has been successfully updated!',
  REMINDER_EMAIL_SUCCESS = 'Reminder Email template saved successfully!',
  DELETE_CUSTOM_VOCABULARY_VALUE_SUCCESS = 'Custom vocabulary term deleted successfully!',
  CREATE_DICTIONARY_TERMS_SUCCESS = 'Custom vocabulary created successfully!',
  UPDATE_DICTIONARY_TERMS_SUCCESS = 'Custom vocabulary updated successfully!',
  RECORDING_BOT_SCHEDULED_SUCCESS = 'You successfully asked for a recording bot to be scheduled!',
  RECORDING_BOT_DELETED_SUCCESS = 'You successfully asked the recording bot deletion!',
  EDIT_RECORDING_BOT_SCHEDULE_OPTION_SUCCESS = 'Recording bot schedule option updated successfully!',
}

export const SharingAlertMessage = {
  CALL_PUBLIC_LINK: (validityDuration: number | null) =>
    `Link Copied! Anyone with the link can access this call.${
      validityDuration ? ` Valid for ${validityDuration} days for external users.` : ''
    }`,
  SNIPPET_PUBLIC_LINK: (validityDuration: number | null) =>
    `Link Copied! Anyone with the link can access this snippet.${
      validityDuration ? ` Valid for ${validityDuration} days for external users.` : ''
    }`,
  CALL_PRIVATE_LINK: 'Link Copied! Only Modjo users can access this call.',
  SNIPPET_PRIVATE_LINK: 'Link Copied! Only Modjo users can access this snippet.',
  EMBED_LINK: 'Embedded code copied!',
  ERROR_PUBLIC_LINK: 'Failed to copy public link',
};

export interface Alert {
  message: string | string[];
  variant: AlertVariant;
}
