import { matchPath, useLocation } from 'react-router-dom';

import { RoutePaths } from '@/enums';
import { extractPublicToken } from '@/stores/embed/decoded-public-token';

export const useIsPublic = () => {
  const location = useLocation();
  const publicMatch = matchPath(RoutePaths.PUBLIC_CALL, location.pathname);
  const tokenInfos = publicMatch && extractPublicToken(location);
  return publicMatch && !!tokenInfos?.uuid;
};
