import React, { forwardRef, PropsWithChildren } from 'react';

import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { scrollbarDefaultOptions } from '../../../enums/constants';
import { SimpleInput } from '../ui-components/inputs/text/SimpleInput';

export const InlineFlexWrapBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
>(({ className, children, ...props }, reference) => (
  <div ref={reference} className={clsx('inline-flex flex-wrap items-center', className)} {...props}>
    {children}
  </div>
));

export const ListMenuItemContainer = forwardRef<HTMLLIElement, React.HTMLAttributes<HTMLLIElement>>(
  ({ className, ...others }, reference) => (
    <li
      ref={reference}
      className={clsx(
        'flex cursor-pointer items-center justify-between rounded-md p-1 text-sm font-normal hover:bg-grey-100',
        className
      )}
      {...others}
    />
  )
);

export const ListMenuContainer = forwardRef<
  HTMLUListElement,
  PropsWithChildren<React.HTMLAttributes<HTMLUListElement>>
>(({ className, children, ...props }, reference) => (
  <OverlayScrollbarsComponent
    className={clsx('max-h-[176px] px-4 py-1.5', className)}
    options={scrollbarDefaultOptions}
  >
    <ul ref={reference} {...props} className="flex flex-col gap-y-1">
      {children}
    </ul>
  </OverlayScrollbarsComponent>
));

export const SearchOptionsInput = forwardRef<HTMLInputElement, Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>>(
  ({ className, ...props }, reference) => (
    <SimpleInput ref={reference} size="large" {...props} className={clsx('mb-0', className)} />
  )
);

export const SearchOptionsInputContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>(({ className, children, ...props }, reference) => (
  <div ref={reference} className={clsx('mx-4', className)} {...props}>
    {children}
  </div>
));

export const SearchOptionsInputDescription = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>>
>(({ children, ...props }, reference) => (
  <span ref={reference} className="text-xs font-semibold italic text-grey-300" {...props}>
    {children}
  </span>
));
