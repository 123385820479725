import { useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

import { AlertVariant } from '@/components/common/toast/alert';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { HealthCheckReason, HealthCheckWarning, RoutePaths, UserRole } from '@/enums';
import { LOCALSTORAGE_CLOSED_DOWN_INTEGRATION_SNACKBAR } from '@/enums/localstorage-keys';
import { Event } from '@/tracking/events';
import { hasRole } from '@/utils/user-utils';
import { capitalizeFirstLetter } from '@/utils/utils';

import { healthCheckStore } from './healthCheck.store';
import { showBannerAlert } from './healthCheck.utils';

export const useTenantHealthCheckBanner = () => {
  const currentUserQuery = useCurrentUserQuery();
  const tenantQuery = useTenantQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (tenantQuery.data) {
      const closedDownIntegrationSnackbar = localStorage.getItem(LOCALSTORAGE_CLOSED_DOWN_INTEGRATION_SNACKBAR);
      const setLocalStorage = () => {
        if (healthCheckStore.getActiveDownAlerts() === 1) {
          try {
            localStorage.setItem(LOCALSTORAGE_CLOSED_DOWN_INTEGRATION_SNACKBAR, Date.now().toString());
          } catch (error) {
            // we do not want to break the whole app when an exception QuotaExceededError happens
            Sentry.captureException(error);
          }
        }
      };

      if (!healthCheckStore.getActiveDownAlerts() && !closedDownIntegrationSnackbar) {
        const { downProvidersWithLastUpStatus } = tenantQuery.data;
        downProvidersWithLastUpStatus?.map(({ provider }) => {
          const providerName = provider ? capitalizeFirstLetter(provider) : '';

          mixpanel.track(Event.DISPLAY_HEALTH_CHECK_SNACKBAR, {
            warningType: HealthCheckWarning.INTEGRATION,
            errorType: HealthCheckReason.CREDENTIALS,
            integrationName: providerName,
          });

          if (currentUserQuery.data && hasRole(currentUserQuery.data.role, UserRole.MANAGER)) {
            return showBannerAlert({
              message: `Sorry, your ${providerName} integration is disconnected due to credentials.`,
              variant: AlertVariant.Down,
              buttonProps: {
                label: 'Reconnect Integration',
                onClick: () => {
                  mixpanel.track(Event.CLICK_CTA_HEALTH_CHECK_SNACKBAR_REDIRECTION, {
                    warningType: HealthCheckWarning.INTEGRATION,
                    errorType: HealthCheckReason.CREDENTIALS,
                    integrationName: providerName,
                  });
                  setLocalStorage();
                  navigate(RoutePaths.SETTINGS_INTEGRATIONS);
                },
              },
              onClose: () => setLocalStorage(),
            });
          }

          return showBannerAlert({
            message: `Sorry, your ${providerName} integration is disconnected, please contact your admin to resolve it.`,
            variant: AlertVariant.Down,
            onClose: () => setLocalStorage(),
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantQuery.data]);
};
