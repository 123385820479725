import { createBrowserRouter } from 'react-router-dom';

import { historyStore } from '@/stores/history.store';

import { privateRoutes } from './private';
import { publicRoutes } from './public';

const router = createBrowserRouter([...publicRoutes, ...privateRoutes]);
router.subscribe((state) => {
  historyStore.setHistoryChanges(state.historyAction, state.location.pathname + state.location.search);
});

export { router };
