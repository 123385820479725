import {
  SearchableObjectOptionType,
  SearchableObject,
} from '@/components/common/search-components/search-components.types';
import { SelectOption } from '@/components/common/ui-components/types';
import { User } from '@/entities/users/users.types';
import { UserRole } from '@/enums';
import { getUserFullName } from '@/utils/user-utils';
import { toNumber } from '@/utils/utils';

const sortRecentOrActiveUsers = (a: User, b: User) => {
  const fullNameA = getUserFullName(a);
  const fullNameB = getUserFullName(b);
  if (!a.deletedOn && !b.deletedOn) {
    if (a.hasLicense !== b.hasLicense) {
      return toNumber(b.hasLicense) - toNumber(a.hasLicense);
    }
    return fullNameA.localeCompare(fullNameB);
  }
  if (a.deletedOn && !b.deletedOn) {
    return 1;
  }
  if (!a.deletedOn && b.deletedOn) {
    return -1;
  }
  return fullNameA.localeCompare(fullNameB);
};

export const toRecentOrActiveSearchableUsers = (users: User[]): SearchableObject[] =>
  users
    .reduce<(SearchableObject & User)[]>((acc, user) => {
      if (!user.hasRole(UserRole.SUPERADMIN)) {
        acc.push({ ...user, searchableObjectType: SearchableObjectOptionType.USER });
      }
      return acc;
    }, [])
    .sort(sortRecentOrActiveUsers);

export const toRecentOrActiveSelectOptionUsers = (users: User[]): SelectOption[] =>
  users.sort(sortRecentOrActiveUsers).reduce<SelectOption[]>((acc, user) => {
    if (!user.hasRole(UserRole.SUPERADMIN)) {
      acc.push({ id: String(user.id), label: user.name, secondaryLabel: user.deletedOn ? '(Deleted)' : undefined });
    }
    return acc;
  }, []);

export const toRecentOrActiveUsers = (users: User[]) =>
  users
    .filter((user) => {
      return !user.hasRole(UserRole.SUPERADMIN) && (user.hasLicense || user.deletedOn);
    })
    .sort(sortRecentOrActiveUsers);
