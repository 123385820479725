import { createStoreAndMethods } from '@/utils/zustand-utils';

type SearchStore = {
  isSearchOpen: boolean;
};

const initialState: SearchStore = {
  isSearchOpen: false,
};

const [, searchStore] = createStoreAndMethods(initialState);

export { searchStore };
