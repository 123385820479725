import { captureMessage } from '@sentry/browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { NotificationStatus, NotificationType } from '@/components/MainSidebar/Notifications/notifications-enums';
import { Environment } from '@/enums/constants';
import { assertUnreachable } from '@/types/common';
import { isEnvironmentBelowThreshold, sleep } from '@/utils/utils';

import { NOTIFICATIONS_API_BASE_URL } from './notifications.queries';
import { NotificationInfiniteData } from './notifications.types';
import { queryKeys } from '../queryKeys';

type notificationStatusToggler = {
  notificationId: number;
  status: NotificationStatus.READ | NotificationStatus.SENT;
};

export const useToggleNotificationStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ notificationId, status }: notificationStatusToggler) => {
      let endpoint = null;
      switch (status) {
        case NotificationStatus.READ: {
          endpoint = 'read';
          break;
        }
        case NotificationStatus.SENT: {
          endpoint = 'unread';
          break;
        }
        default: {
          assertUnreachable(status);
        }
      }
      return apiService.API.put(`${NOTIFICATIONS_API_BASE_URL}/${notificationId}/${endpoint}`, {
        propagate: false,
      });
    },
    onSuccess: (responseData, variables) => {
      queryClient.setQueryData<NotificationInfiniteData>(queryKeys.notifications.all, (cachedData) => {
        if (!cachedData) {
          return cachedData;
        }
        // be sure to not alter the existing cached data and trigger the right re-renders
        const { pages, pageParams } = structuredClone(cachedData);
        // used to early exit the loop to make it fast
        let valueUpdated = false;
        for (const page of pages) {
          for (let i = 0; i < page.values.length; i += 1) {
            if (page.values[i].id === variables.notificationId) {
              page.values[i].status = variables.status;
              valueUpdated = true;
              break;
            }
            if (valueUpdated) {
              break;
            }
          }
        }
        // update the unread notifications count
        const lastPage = pages.at(-1);
        if (lastPage) {
          lastPage.unreadNotifications =
            variables.status === NotificationStatus.READ
              ? lastPage.unreadNotifications - 1
              : lastPage.unreadNotifications + 1;
        } else {
          captureMessage(
            'No pages in notification list, this should not happend during a mutation of the notification list'
          );
        }
        return { pages, pageParams };
      });
    },
  });
};

type MarkAsReadMutationParams = {
  notificationToReadFilter?: {
    callId?: number;
    reviewId?: number;
    notificationType?: NotificationType;
  };
};

export const useMarkAsReadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params?: MarkAsReadMutationParams) => {
      return apiService.API.put(`${NOTIFICATIONS_API_BASE_URL}/read`, params?.notificationToReadFilter);
    },
    onSuccess: async (responseData, variables) => {
      // if we have to unread a specific notification, we need to invalidate the query to refetch the data
      // we do it for all the pages because we don't know how many notifications and in which pages are they
      if (variables?.notificationToReadFilter) {
        // We've put this sleep to avoid a bug on staging where the aurora database is a bit slower
        // mode details check on SUP-129
        if (!isEnvironmentBelowThreshold(import.meta.env.VITE_ENV, Environment.PRODUCTION)) {
          await sleep(100);
        }
        queryClient.invalidateQueries({
          queryKey: queryKeys.notifications.all,
        });
        // if we have to unread all the notifications, we can just update the cache
      } else {
        queryClient.setQueryData<NotificationInfiniteData>(queryKeys.notifications.all, (cachedData) => {
          if (!cachedData) {
            return cachedData;
          }
          // be sure to not alter the existing cached data and trigger the right re-renders
          const { pages, pageParams } = structuredClone(cachedData);
          // marke all notifications as read
          for (const page of pages) {
            for (let i = 0; i < page.values.length; i += 1) {
              page.values[i].status = NotificationStatus.READ;
            }
          }
          // only mark the last page ( if exist ) as no notifications
          const lastPage = pages.at(-1);
          if (lastPage) {
            lastPage.unreadNotifications = 0;
          }
          return { pages, pageParams };
        });
      }
    },
  });
};
