import { useAiCallScoringVisibilityForUserRoleQuery } from '@/entities/tenant/tenant.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { UserRole } from '@/enums';
import { hasRole } from '@/utils/user-utils';

import { useIsCurrentUserAnAttendee } from '../../../entities/call-details/call-details.hooks';

export const useIsAiCallScoringEnabled = () => {
  const tenantAiCallScoringVisibilityForUserRoleQuery = useAiCallScoringVisibilityForUserRoleQuery();
  const currentUser = useCurrentUser();
  const isAboveManager = currentUser && hasRole(currentUser.role, UserRole.MANAGER);
  return (
    (tenantAiCallScoringVisibilityForUserRoleQuery.isSuccess &&
      tenantAiCallScoringVisibilityForUserRoleQuery.data.isAICallScoringForUserRoleActivated) ||
    isAboveManager
  );
};

export const useIsAiCallScoringEnabledInCallDetails = () => {
  const isFeatureEnabled = useIsAiCallScoringEnabled();
  const isCurrentUserAnAttendee = useIsCurrentUserAnAttendee();
  return isFeatureEnabled || isCurrentUserAnAttendee;
};
