import { persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { LOCALSTORAGE_ALERTS_TOKEN_KEY } from '@/enums/localstorage-keys';
import { createMethods } from '@/utils/zustand-utils';

type AlertsStore = {
  activeDownAlerts: number;
  hideEmailIntegrationBanner: Date | null;
};

const initialState: AlertsStore = {
  activeDownAlerts: 0,
  hideEmailIntegrationBanner: null,
};

const store = createWithEqualityFn(
  persist(() => initialState, {
    name: LOCALSTORAGE_ALERTS_TOKEN_KEY,
    partialize: (state) =>
      ({
        activeDownAlerts: state.activeDownAlerts,
        hideEmailIntegrationBanner: state.hideEmailIntegrationBanner,
      }) as AlertsStore,
  })
);

const healthCheckStore = {
  ...createMethods(store),
};

export { healthCheckStore };
