import { endOfDay } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { SearchFiltersDTO } from '@/entities/calls/calls.types';
import { DealsFilters, DealsSorting, ViewConfig } from '@/entities/views/views.types';
import { SaidByFilter } from '@/enums';
import { CallFilterDto } from '@/types/call-filters';
import { DEFAULT_PERIOD, ObjectValues } from '@/types/common';

import { DatePeriods } from '../common/DatePeriodFilter/date-period-filter.constant';
import { DatePeriod } from '../common/DatePeriodFilter/date-period-filter.types';
import { getPeriodStart } from '../common/DatePeriodFilter/date-period-filter.utils';

export type UrlFilters = {
  period: DatePeriod;
  startDate?: string;
  endDate?: string;
  startCloseDate?: string;
  endCloseDate?: string;
  userIds: number[];
  teamIds: number[];
  accountIds: number[];
  contactIds: number[];
  externalPhoneProviders: string[];
  languages: string[];
  dealStatus?: string;
  leadStatus?: string;
  dealStage?: string;
  jobTitles: string[];
  leadSources: string[];
  dealLossReasons?: string[];
  topicIds: number[];
  searchPhrases: string[];
  saidBy?: SaidByFilter;
  excludeKeywords?: boolean;
  minDuration?: string | number;
  maxDuration?: string | number;
  minAmount?: string | number;
  maxAmount?: string | number;
  minReviewRating?: string | number;
  maxReviewRating?: string | number;
  minAiScoring?: string | number;
  maxAiScoring?: string | number;
  aiScoringTemplateUuids: string[];
  callTitle?: string;
  dealName?: string;
  minTalkRatio?: string | number;
  maxTalkRatio?: string | number;
  excludeAlreadyListened?: boolean;
  aiScoringQuestionUuids: string[];
  commentedOnly?: boolean;
  doesNotContain?: boolean;
  tagIds: number[];
  teamId?: number;
  userId?: number;
  tagId?: number;
  forReviewer?: boolean;
  aiScoringTemplateUuid?: string;
  reviewTemplateId?: number;
  withoutCrmAssociation?: boolean;
  metric?: string;
  chartType?: string;
  viewId?: number;
  columnOrder?: string[];
};

type UrlFiltersActions = {
  clear: () => void;
  update: (params: Partial<UrlFilters | DealsFilters | DealsSorting | ViewConfig>, clearParams?: boolean) => void;
};

export const getPeriodDates = (period: DatePeriod) => {
  const startDate =
    period === DatePeriods.ALL_TIME || period === DatePeriods.CUSTOM ? undefined : getPeriodStart(period);
  const endDate = period === DatePeriods.ALL_TIME || period === DatePeriods.CUSTOM ? undefined : endOfDay(new Date());
  return { startDate, endDate };
};

export const FilterCategories = {
  ACCOUNT: 'Account',
  CALL_TITLE: 'Call Title',
  COMMENTEND_ONLY: 'Commented Only',
  CONTACT: 'Contact',
  TAG: 'Tag',
  TEAM: 'Team',
  TOPIC: 'Topic',
  TRANSCRIPT: 'Word',
  USER: 'User',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  EXTERNAL_PHONE_PROVIDER: 'Provider',
  LANGUAGE: 'Language',
  JOB_TITLE: 'Contact job title',
  LEAD_SOURCE: 'Lead source',
  LEAD_STATUS: 'Lead status',
  DEAL_STAGE: 'Deal stage',
  DEAL_STATUS: 'Deal status',
  DEAL_LOSS_REASON: 'Deal loss reason',
  DEAL_AMOUNT: 'Deal amount',
  CALL_DURATION: 'Call Duration',
  DEAL_CLOSE_DATE: 'Deal close date',
  REVIEW_RATING: 'Call Review',
  AI_CALL_SCORING: 'AI Call Scoring',
  PHONE_PROVIDER: 'Provider',
} as const;

export type FilterCategory = ObjectValues<typeof FilterCategories>;

export function isFilterCategory(value: string): value is FilterCategory {
  return Object.values(FilterCategories).includes(value as FilterCategory);
}

export const categoryKeyMap: Record<FilterCategory, keyof UrlFilters | (keyof UrlFilters)[]> = {
  [FilterCategories.DEAL_STAGE]: 'dealStage',
  [FilterCategories.DEAL_STATUS]: 'dealStatus',
  [FilterCategories.LEAD_STATUS]: 'leadStatus',
  [FilterCategories.LEAD_SOURCE]: 'leadSources',
  [FilterCategories.JOB_TITLE]: 'jobTitles',
  [FilterCategories.ACCOUNT]: 'accountIds',
  [FilterCategories.CONTACT]: 'contactIds',
  [FilterCategories.EXTERNAL_PHONE_PROVIDER]: 'externalPhoneProviders',
  [FilterCategories.LANGUAGE]: 'languages',
  [FilterCategories.DEAL_AMOUNT]: ['minAmount', 'maxAmount'],
  [FilterCategories.CALL_DURATION]: ['minDuration', 'maxDuration'],
  [FilterCategories.DEAL_CLOSE_DATE]: ['startCloseDate', 'endCloseDate'],
  [FilterCategories.TOPIC]: 'topicIds',
  [FilterCategories.TAG]: 'tagIds',
  [FilterCategories.TEAM]: 'teamIds',
  [FilterCategories.USER]: 'userIds',
  [FilterCategories.CALL_TITLE]: 'callTitle',
  [FilterCategories.TRANSCRIPT]: 'searchPhrases',
  [FilterCategories.REVIEW_RATING]: ['minReviewRating', 'maxReviewRating'],
  [FilterCategories.AI_CALL_SCORING]: ['minAiScoring', 'maxAiScoring', 'aiScoringTemplateUuids'],
  [FilterCategories.DEAL_LOSS_REASON]: 'dealLossReasons',
  [FilterCategories.START_DATE]: 'startDate',
  [FilterCategories.END_DATE]: 'endDate',
  [FilterCategories.COMMENTEND_ONLY]: 'commentedOnly',
};

const mapCommonFilterProperties = (filter: Partial<UrlFilters>) => {
  const { startDate: periodStartDate, endDate: periodEndDate } = getPeriodDates(filter.period ?? DEFAULT_PERIOD);

  return {
    accountIds: filter.accountIds?.filter(Boolean),
    aiScoringTemplateUuids: filter.aiScoringTemplateUuids?.filter(Boolean),
    aiScoringQuestionUuids: filter.aiScoringQuestionUuids?.filter(Boolean),
    commentedOnly: filter.commentedOnly,
    withoutCrmAssociation: filter.withoutCrmAssociation,
    callTitle: filter.callTitle && filter.callTitle.length >= 3 ? filter.callTitle : undefined,
    contactIds: filter.contactIds?.filter(Boolean),
    endCloseDate: filter.endCloseDate ? new Date(filter.endCloseDate) : undefined,
    endDate: filter.endDate ? new Date(filter.endDate) : periodEndDate,
    externalPhoneProviders: filter.externalPhoneProviders?.filter(Boolean),
    jobTitles: filter.jobTitles?.filter(Boolean),
    languages: filter.languages?.filter(Boolean),
    leadSources: filter.leadSources?.filter(Boolean),
    maxAiScoring: filter.maxAiScoring ? Number(filter.maxAiScoring) : undefined,
    maxAmount: filter.maxAmount ? Number(filter.maxAmount) : undefined,
    maxDuration: filter.maxDuration ? Number(filter.maxDuration) : undefined,
    maxReviewRating: filter.maxReviewRating ? Number(filter.maxReviewRating) : undefined,
    minAiScoring: filter.minAiScoring ? Number(filter.minAiScoring) : undefined,
    minAmount: filter.minAmount ? Number(filter.minAmount) : undefined,
    minDuration: filter.minDuration ? Number(filter.minDuration) : undefined,
    minReviewRating: filter.minReviewRating ? Number(filter.minReviewRating) : undefined,
    searchPhrases: filter.searchPhrases,
    startCloseDate: filter.startCloseDate ? new Date(filter.startCloseDate) : undefined,
    startDate: filter.startDate ? new Date(filter.startDate) : periodStartDate,
    tagIds: filter.tagIds?.filter(Boolean),
    teamIds: filter.teamIds?.filter(Boolean),
    topicIds: filter.topicIds?.filter(Boolean),
    userIds: filter.userIds?.filter(Boolean),
  };
};

export const mapExploreFilterToCallFiltersDTO = (filter: Partial<UrlFilters>): CallFilterDto => {
  const commonProperties = mapCommonFilterProperties(filter);

  return {
    ...commonProperties,
    userId: filter.userId,
    teamId: filter.teamId,
    tagId: filter.tagId,
    forReviewer: filter.forReviewer,
    reviewTemplateId: filter.reviewTemplateId,
    saidBy: filter.saidBy,
    dealStage: filter.dealStage,
    dealStatus: filter.dealStatus,
  };
};

export const mapExploreFilterToSearchFiltersDTO = (filter: Partial<UrlFilters>): SearchFiltersDTO => {
  const commonProperties = mapCommonFilterProperties(filter);

  return {
    ...commonProperties,
    dealStages: filter.dealStage ? [filter.dealStage] : undefined,
    dealStatuses: filter.dealStatus ? [filter.dealStatus] : undefined,
    leadStatuses: filter.leadStatus ? [filter.leadStatus] : undefined,
  };
};

export const mapAiCallScoringFilterToCallSearchFiltersDTO = (filter: Partial<UrlFilters>): SearchFiltersDTO => {
  const { teamId, userId, tagId, aiScoringTemplateUuid } = filter;
  const commonProperties = mapCommonFilterProperties({
    ...filter,
    teamIds: teamId ? [teamId] : undefined,
    userIds: userId ? [userId] : undefined,
    tagIds: tagId ? [tagId] : undefined,
    aiScoringTemplateUuids: aiScoringTemplateUuid ? [aiScoringTemplateUuid] : undefined,
  });

  return {
    ...commonProperties,
    dealStages: filter.dealStage ? [filter.dealStage] : undefined,
    dealStatuses: filter.dealStatus ? [filter.dealStatus] : undefined,
    leadStatuses: filter.leadStatus ? [filter.leadStatus] : undefined,
    minAiScoring: 0,
  };
};

export const mapFilterToAiScoringAnalytics = (filter: UrlFilters) => ({
  ...mapExploreFilterToCallFiltersDTO(filter),
  aiScoringTemplateUuid: filter.aiScoringTemplateUuid,
  aiScoringQuestionUuids: undefined,
  aiScoringQuestionUuid: filter.aiScoringQuestionUuids?.length ? filter.aiScoringQuestionUuids[0] : undefined,
});

export const mapfilterToReviewAnalytics = (filter: UrlFilters) => ({
  ...mapExploreFilterToCallFiltersDTO(filter),
  reviewTemplateId: filter.reviewTemplateId,
  forReviewer: filter.forReviewer,
});

export function useUrlFilters(): UrlFilters {
  const [searchParams] = useSearchParams();
  const period = searchParams.get('period') as DatePeriod;
  const startCloseDate = searchParams.get('startCloseDate')
    ? decodeURIComponent(searchParams.get('startCloseDate') as string)
    : undefined;
  const endCloseDate = searchParams.get('endCloseDate')
    ? decodeURIComponent(searchParams.get('endCloseDate') as string)
    : undefined;
  const { startDate, endDate } = getPeriodDates(period);
  const decodedStartDate = searchParams.get('startDate')
    ? decodeURIComponent(searchParams.get('startDate') as string)
    : startDate?.toISOString();
  const decodedEndDate = searchParams.get('endDate')
    ? decodeURIComponent(searchParams.get('endDate') as string)
    : endDate?.toISOString();

  return {
    period: period ?? DEFAULT_PERIOD,
    startDate: decodedStartDate,
    endDate: decodedEndDate,
    startCloseDate: startCloseDate ?? undefined,
    endCloseDate: endCloseDate ?? undefined,
    userIds: searchParams.getAll('userIds[]').map(Number),
    teamIds: searchParams.getAll('teamIds[]').map(Number),
    accountIds: searchParams.getAll('accountIds[]').map(Number),
    contactIds: searchParams.getAll('contactIds[]').map(Number),
    externalPhoneProviders: searchParams.getAll('externalPhoneProviders[]'),
    languages: searchParams.getAll('languages[]'),
    dealStatus: searchParams.get('dealStatus')
      ? decodeURIComponent(searchParams.get('dealStatus') as string)
      : undefined,
    leadStatus: searchParams.get('leadStatus')
      ? decodeURIComponent(searchParams.get('leadStatus') as string)
      : undefined,
    dealStage: searchParams.get('dealStage') ? decodeURIComponent(searchParams.get('dealStage') as string) : undefined,
    jobTitles: searchParams.getAll('jobTitles[]'),
    columnOrder: searchParams.getAll('columnOrder[]').map((val) => decodeURIComponent(val)),
    leadSources: searchParams.getAll('leadSources[]').map((val) => decodeURIComponent(val)),
    dealLossReasons: searchParams.getAll('dealLossReasons[]') ?? undefined,
    topicIds: searchParams.getAll('topicIds[]').map(Number),
    searchPhrases: searchParams.getAll('searchPhrases[]'),
    saidBy: searchParams.get('saidBy')
      ? (decodeURIComponent(searchParams.get('saidBy') as string) as SaidByFilter)
      : undefined,
    excludeKeywords: searchParams.get('excludeKeywords') ? Boolean(searchParams.get('excludeKeywords')) : undefined,
    minDuration: searchParams.get('minDuration') ?? undefined,
    maxDuration: searchParams.get('maxDuration') ?? undefined,
    minAmount: searchParams.get('minAmount') ?? undefined,
    maxAmount: searchParams.get('maxAmount') ?? undefined,
    minReviewRating: searchParams.get('minReviewRating') ?? undefined,
    maxReviewRating: searchParams.get('maxReviewRating') ?? undefined,
    minAiScoring: searchParams.get('minAiScoring') ?? undefined,
    maxAiScoring: searchParams.get('maxAiScoring') ?? undefined,
    aiScoringTemplateUuids: searchParams.getAll('aiScoringTemplateUuids[]'),
    aiScoringQuestionUuids: searchParams.getAll('aiScoringQuestionUuids[]'),
    callTitle: searchParams.get('callTitle') ? decodeURIComponent(searchParams.get('callTitle') as string) : undefined,
    minTalkRatio: searchParams.get('minTalkRatio') ?? undefined,
    maxTalkRatio: searchParams.get('maxTalkRatio') ?? undefined,
    excludeAlreadyListened: searchParams.get('excludeAlreadyListened')
      ? Boolean(searchParams.get('excludeAlreadyListened'))
      : undefined,
    commentedOnly: searchParams.get('commentedOnly') ? Boolean(searchParams.get('commentedOnly')) : undefined,
    doesNotContain: searchParams.get('doesNotContain') ? Boolean(searchParams.get('doesNotContain')) : undefined,
    withoutCrmAssociation: searchParams.get('withoutCrmAssociation')
      ? Boolean(searchParams.get('withoutCrmAssociation'))
      : undefined,
    tagIds: searchParams.getAll('tagIds[]').map(Number),
    teamId: searchParams.get('teamId') ? Number(searchParams.get('teamId')) : undefined,
    userId: searchParams.get('userId') ? Number(searchParams.get('userId')) : undefined,
    tagId: searchParams.get('tagId') ? Number(searchParams.get('tagId')) : undefined,
    forReviewer: searchParams.get('forReviewer') ? Boolean(searchParams.get('forReviewer')) : undefined,
    aiScoringTemplateUuid: searchParams.get('aiScoringTemplateUuid')
      ? decodeURIComponent(searchParams.get('aiScoringTemplateUuid') as string)
      : undefined,
    reviewTemplateId: searchParams.get('reviewTemplateId') ? Number(searchParams.get('reviewTemplateId')) : undefined,
    metric: searchParams.get('metric') ?? undefined,
    chartType: searchParams.get('chartType') ?? undefined,
    viewId: searchParams.get('viewId') ? Number(searchParams.get('viewId')) : undefined,
  };
}

export const hasActiveFilters = (filters: Partial<UrlFilters>) => {
  const activeFilters = Object.entries(filters).filter(
    ([key, value]) =>
      key !== 'period' &&
      key !== 'metric' &&
      key !== 'viewId' &&
      key !== 'callTitle' &&
      key !== 'searchParams' &&
      key !== 'startDate' &&
      key !== 'endDate' &&
      value !== undefined &&
      !(Array.isArray(value) && value.length === 0)
  );
  return activeFilters.length > 0;
};

export const updateParams = (
  searchParams: URLSearchParams,
  filters: Partial<UrlFilters | DealsFilters | DealsSorting | ViewConfig>
) => {
  for (const [key, value] of Object.entries(filters)) {
    if (value === undefined) {
      // No matter what key we are deleting (we can't know at that time) we delete it
      // it helps typing categoryKeyMap
      searchParams.delete(key);
      searchParams.delete(`${key}[]`);
    } else {
      if (Array.isArray(value) && value.length > 0) {
        for (const item of value) {
          searchParams.append(`${key}[]`, String(item));
        }
      }

      if (key === 'minDuration' || key === 'maxDuration') {
        const newVal = value as number;
        searchParams.set(key, String(newVal));
      }

      if (
        key === 'minAmount' ||
        key === 'maxAmount' ||
        key === 'minTalkRatio' ||
        key === 'maxTalkRatio' ||
        key === 'minReviewRating' ||
        key === 'maxReviewRating' ||
        key === 'minAiScoring' ||
        key === 'maxAiScoring' ||
        key === 'reviewTemplateId' ||
        key === 'metric' ||
        key === 'chartType' ||
        key === 'viewId'
      ) {
        searchParams.set(key, String(value));
      }

      if (key === 'minAiScoring' || key === 'maxAiScoring') {
        searchParams.set(key, String(value));
      }

      if ((key === 'startDate' || key === 'endDate') && searchParams.get('period') !== DatePeriods.ALL_TIME) {
        searchParams.set(key, encodeURIComponent(value as string));
      }

      if (
        (key === 'startCloseDate' || key === 'endCloseDate') &&
        (typeof value === 'string' || typeof value === 'number')
      ) {
        searchParams.set(key, encodeURIComponent(value as string));
      }

      if (key === 'period' && value !== DatePeriods.CUSTOM) {
        searchParams.set('period', value as string);
        searchParams.delete('startDate');
        searchParams.delete('endDate');
      }

      if (key === 'period') {
        searchParams.set('period', value as string);
      }

      if (
        key === 'dealStatus' ||
        key === 'leadStatus' ||
        key === 'dealStage' ||
        key === 'saidBy' ||
        key === 'callTitle' ||
        key === 'excludeKeywords' ||
        key === 'excludeAlreadyListened' ||
        key === 'commentedOnly' ||
        key === 'doesNotContain' ||
        key === 'aiScoringTemplateUuid' ||
        key === 'tagId' ||
        key === 'userId' ||
        key === 'teamId' ||
        key === 'withoutCrmAssociation' ||
        key === 'closeDatePeriod' ||
        key === 'sortField' ||
        key === 'sortOrder' ||
        key === 'warning' ||
        key === 'objectionUuid'
      ) {
        searchParams.set(key, encodeURIComponent(value as string));
      }
    }
  }

  return searchParams;
};

export const useUrlFiltersActions = (): UrlFiltersActions => {
  const [, setSearchParams] = useSearchParams();

  const updateSearchParams = (
    params: Partial<UrlFilters | DealsFilters | DealsSorting | ViewConfig>,
    clearParams = false
  ) => {
    setSearchParams((prevSearchParams) => {
      if (clearParams) {
        return updateParams(new URLSearchParams(), params);
      }
      return updateParams(prevSearchParams, params);
    });
  };

  const clearParams = () => {
    setSearchParams(() => {
      const newSearchParams = new URLSearchParams();
      return updateParams(newSearchParams, {});
    });
  };

  return {
    clear: clearParams,
    update: updateSearchParams,
  };
};
