import React, { PropsWithChildren } from 'react';

import { cn } from '@/utils/utils';

type ShortcutKeyProps = PropsWithChildren<{ className?: string }>;

const ShortcutKey = ({ children, className }: ShortcutKeyProps) => {
  return (
    <span
      className={cn(
        'rounded border border-grey-100 bg-grey-white px-1 py-0.5 text-2xs font-semibold text-grey-700 shadow-card',
        className
      )}
    >
      {children}
    </span>
  );
};

ShortcutKey.displayName = 'ShortcutKey';

export { ShortcutKey };
