import { generatePath, NavigateFunction } from 'react-router-dom';

import {
  FiltersCategories,
  getOptionType,
  CallTitleOption,
  DealCloseDateOption,
  AiCallScoringOption,
  isAiCallScoringTemplateOption,
  isAiCallScoringRangeOption,
  RangeNumberOption,
  DealOption,
} from '@/components/common/search-components/Common';
import {
  SearchableObject,
  SearchableObjectOptionType,
  OtherInfoOptionType,
  RangeOptionType,
  ReviewsOptionType,
} from '@/components/common/search-components/search-components.types';
import { UrlFilters } from '@/components/explore/useUrlFilters';
import { RoutePaths } from '@/enums';
import { i18n } from '@/translation/i18n';
import { SECONDS_PER_MINUTE } from '@/utils/date-utils';

export const computePlaceholder = (selectedFilters: SearchableObject[], selectedCategory?: FiltersCategories) => {
  if (selectedFilters.length > 0 || selectedCategory) {
    return '';
  }
  return String(i18n.t('filters.search.placeholder'));
};

export const makeFiltersWithCategory = (items: SearchableObject[]) => {
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({ option: item, type: getOptionType(item) }));
};
/**
 * Handle append not confirmed selected filters and delete duplicated filters
 *
 * @param {SearchableObject[]} selectedFilters - Confirmed selected filters
 * @param {SearchableObject[]} [values] - Filters added when selection is not confirmed
 * @returns
 */
const computeSelectedFilters = (selectedFilters: SearchableObject[], values?: SearchableObject[]) => {
  const oldSelectedFilters = values?.length
    ? selectedFilters.filter((f) => values.some((v) => (getOptionType(f) === getOptionType(v) ? f.id === v.id : true)))
    : selectedFilters;
  const newSelectedFilters = values?.length
    ? values.filter((f) => !selectedFilters.some((v) => getOptionType(f) === getOptionType(v) && f.id === v.id))
    : [];
  return [...oldSelectedFilters, ...newSelectedFilters];
};

export const makeSearchParams = (selectedFilters: SearchableObject[], navigate?: NavigateFunction) => {
  const filtersToUpdate: Partial<UrlFilters> = {};
  const filtersWithCategory = makeFiltersWithCategory(selectedFilters);
  for (const filter of filtersWithCategory) {
    switch (filter.type) {
      case SearchableObjectOptionType.CALL_TITLE: {
        const callTitleOption = filter.option as CallTitleOption;
        if (callTitleOption?.goToCallDetails && navigate && Boolean(callTitleOption.id)) {
          navigate(generatePath(RoutePaths.CALL_DETAILS, { id: callTitleOption.id.toString() }));
        } else {
          filtersToUpdate.callTitle
            ? (filtersToUpdate.callTitle = callTitleOption.name)
            : Object.assign(filtersToUpdate, { callTitle: callTitleOption.name });
        }
        break;
      }

      case SearchableObjectOptionType.DEAL: {
        const dealOption = filter.option as DealOption;
        navigate && navigate(generatePath(RoutePaths.DEAL_VIEW, { id: dealOption.id.toString() }));
        break;
      }

      case OtherInfoOptionType.TRANSCRIPT:
      case SearchableObjectOptionType.TRANSCRIPT: {
        filtersToUpdate.searchPhrases
          ? filtersToUpdate.searchPhrases.push(filter.option.name)
          : Object.assign(filtersToUpdate, { searchPhrases: [filter.option.name] });
        break;
      }

      case SearchableObjectOptionType.DEAL_STATUS: {
        filtersToUpdate.dealStatus
          ? (filtersToUpdate.dealStatus = filter.option.name)
          : Object.assign(filtersToUpdate, { dealStatus: filter.option.name });
        break;
      }

      case SearchableObjectOptionType.DEAL_STAGE: {
        filtersToUpdate.dealStage
          ? (filtersToUpdate.dealStage = filter.option.name)
          : Object.assign(filtersToUpdate, { dealStage: filter.option.name });
        break;
      }

      case SearchableObjectOptionType.LEAD_STATUS: {
        filtersToUpdate.leadStatus
          ? (filtersToUpdate.leadStatus = filter.option.name)
          : Object.assign(filtersToUpdate, { leadStatus: filter.option.name });
        break;
      }

      case SearchableObjectOptionType.ACCOUNT: {
        filtersToUpdate.accountIds
          ? filtersToUpdate.accountIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { accountIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.CONTACT: {
        filtersToUpdate.contactIds
          ? filtersToUpdate.contactIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { contactIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.USER: {
        filtersToUpdate.userIds
          ? filtersToUpdate.userIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { userIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.TAG: {
        filtersToUpdate.tagIds
          ? filtersToUpdate.tagIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { tagIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.TEAM: {
        filtersToUpdate.teamIds
          ? filtersToUpdate.teamIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { teamIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.TOPIC: {
        filtersToUpdate.topicIds
          ? filtersToUpdate.topicIds.push(filter.option.id as number)
          : Object.assign(filtersToUpdate, { topicIds: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.EXTERNAL_PHONE_PROVIDER: {
        filtersToUpdate.externalPhoneProviders
          ? filtersToUpdate.externalPhoneProviders.push(filter.option.id as string)
          : Object.assign(filtersToUpdate, { externalPhoneProviders: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.LANGUAGE: {
        filtersToUpdate.languages
          ? filtersToUpdate.languages.push(filter.option.id as string)
          : Object.assign(filtersToUpdate, { languages: [filter.option.id] });
        break;
      }

      case SearchableObjectOptionType.JOB_TITLE: {
        filtersToUpdate.jobTitles
          ? filtersToUpdate.jobTitles.push(filter.option.name)
          : Object.assign(filtersToUpdate, { jobTitles: [filter.option.name] });
        break;
      }

      case SearchableObjectOptionType.LEAD_SOURCE: {
        filtersToUpdate.leadSources
          ? filtersToUpdate.leadSources.push(filter.option.name)
          : Object.assign(filtersToUpdate, { leadSources: [filter.option.name] });
        break;
      }

      case SearchableObjectOptionType.DEAL_LOSS_REASON: {
        filtersToUpdate.dealLossReasons
          ? filtersToUpdate.dealLossReasons.push(filter.option.name)
          : Object.assign(filtersToUpdate, { dealLossReasons: [filter.option.name] });
        break;
      }

      case RangeOptionType.DEAL_CLOSE_DATE: {
        const dealCloseDateOption = filter.option as DealCloseDateOption;
        const startCloseDate = dealCloseDateOption.startCloseDate
          ? new Date(dealCloseDateOption.startCloseDate)
          : undefined;
        const endCloseDate = dealCloseDateOption.endCloseDate ? new Date(dealCloseDateOption.endCloseDate) : undefined;
        filtersToUpdate.startCloseDate
          ? (filtersToUpdate.startCloseDate = startCloseDate?.toISOString())
          : Object.assign(filtersToUpdate, { startCloseDate: startCloseDate?.toISOString() });
        filtersToUpdate.endCloseDate
          ? (filtersToUpdate.endCloseDate = endCloseDate?.toISOString())
          : Object.assign(filtersToUpdate, { endCloseDate: endCloseDate?.toISOString() });
        break;
      }

      case ReviewsOptionType.AI_CALL_SCORING: {
        const aiScoringOption = filter.option as AiCallScoringOption;
        if (!filtersToUpdate.aiScoringTemplateUuids && !filtersToUpdate.minAiScoring && !filtersToUpdate.maxAiScoring) {
          filtersToUpdate.aiScoringTemplateUuids = [];
          filtersToUpdate.minAiScoring = undefined;
          filtersToUpdate.maxAiScoring = undefined;
        }

        if (filtersToUpdate.aiScoringTemplateUuids && isAiCallScoringTemplateOption(aiScoringOption)) {
          filtersToUpdate.aiScoringTemplateUuids.push(aiScoringOption.templateUuid);
        }
        if (isAiCallScoringRangeOption(aiScoringOption)) {
          filtersToUpdate.minAiScoring = aiScoringOption.min ?? filtersToUpdate.minAiScoring;
          filtersToUpdate.maxAiScoring = aiScoringOption.max ?? filtersToUpdate.maxAiScoring;
        }
        break;
      }

      case RangeOptionType.DEAL_AMOUNT: {
        const numberOption = filter.option as RangeNumberOption;
        if (filtersToUpdate.minAmount && filtersToUpdate.maxAmount) {
          filtersToUpdate.minAmount = numberOption.min;
          filtersToUpdate.maxAmount = numberOption.max;
        } else {
          Object.assign(filtersToUpdate, {
            minAmount: numberOption.min,
            maxAmount: numberOption.max,
          });
        }
        break;
      }

      case RangeOptionType.REVIEW_RATING: {
        const numberOption = filter.option as RangeNumberOption;
        if (filtersToUpdate.minReviewRating && filtersToUpdate.maxReviewRating) {
          filtersToUpdate.minReviewRating = numberOption.min;
          filtersToUpdate.maxReviewRating = numberOption.max;
        } else {
          Object.assign(filtersToUpdate, {
            minReviewRating: numberOption.min,
            maxReviewRating: numberOption.max,
          });
        }
        break;
      }

      case RangeOptionType.CALL_DURATION: {
        const numberOption = filter.option as RangeNumberOption;
        if (filtersToUpdate.minDuration && filtersToUpdate.maxDuration) {
          filtersToUpdate.minDuration = numberOption.min && numberOption.min * SECONDS_PER_MINUTE;
          filtersToUpdate.maxDuration = numberOption.max && numberOption.max * SECONDS_PER_MINUTE;
        } else {
          Object.assign(filtersToUpdate, {
            minDuration: numberOption.min && numberOption.min * SECONDS_PER_MINUTE,
            maxDuration: numberOption.max && numberOption.max * SECONDS_PER_MINUTE,
          });
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  return filtersToUpdate;
};

export const makeSearchNavbar = (
  navigate: NavigateFunction,
  selectedFilters: SearchableObject[],
  values?: SearchableObject[]
) => makeSearchParams(computeSelectedFilters(selectedFilters, values), navigate);
