import React, { KeyboardEvent, useState } from 'react';

import { Composite, CompositeItem } from '@floating-ui/react';
import { Key } from 'ts-key-enum';

import { cn, isEmpty } from '@/utils/utils';

import { itemToJSX } from './Common';
import { ListMenuContainer, ListMenuItemContainer, SearchOptionsInputContainer } from './CommonStyles';
import { NoResultSearch } from './NoResultSearch';
import { SearchableObject } from './search-components.types';
import { useShortcutsContext } from '../../MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import { UIRadio } from '../ui-components/inputs/radio';
import { SimpleInput } from '../ui-components/inputs/text/SimpleInput';

type SimpleSelectContentProps = {
  options: SearchableObject[];
  placeholder: string;
  selectedItems: SearchableObject[];
  setSelectedItems: (values: SearchableObject[]) => void;
  initialInputValue?: string;
};

const SimpleSelectContent = ({
  options,
  placeholder,
  selectedItems,
  setSelectedItems,
  initialInputValue = '',
}: SimpleSelectContentProps) => {
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [activeIndex, setActiveIndex] = useState<number>();
  const { handleTriggers } = useShortcutsContext();

  const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()));

  return (
    <Composite
      className="flex flex-col gap-2"
      activeIndex={activeIndex}
      onNavigate={(e) => {
        setActiveIndex(e);
      }}
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        handleTriggers(e);
      }}
    >
      <SearchOptionsInputContainer>
        <SimpleInput autoFocus placeholder={placeholder} onChange={(e) => setInputValue(e.currentTarget.value)} />
      </SearchOptionsInputContainer>
      <ListMenuContainer>
        <NoResultSearch hasValues={!isEmpty(filteredOptions)} inputValue={inputValue} />
        {filteredOptions.map((item, index) => {
          return (
            <CompositeItem
              key={item.id}
              className={cn('outline-none', {
                'rounded-md bg-grey/50': activeIndex === index,
              })}
              onMouseEnter={() => {
                setActiveIndex(index);
              }}
              onKeyDown={(e) => {
                if (e.key === Key.Enter && !e.ctrlKey && !e.shiftKey && !e.altKey) {
                  setSelectedItems([item]);
                }
              }}
            >
              <ListMenuItemContainer>
                <UIRadio
                  id={item.id.toString()}
                  checked={selectedItems.some((index_) => item.name === index_.name)}
                  onChange={() => setSelectedItems([item])}
                />
                <label htmlFor={item.id.toString()} className="flex flex-1 cursor-pointer items-center">
                  {itemToJSX(item, { inputValue })}
                </label>
              </ListMenuItemContainer>
            </CompositeItem>
          );
        })}
      </ListMenuContainer>
    </Composite>
  );
};

export { SimpleSelectContent };
