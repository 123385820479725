import React from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { MaybeInATooltip } from '@/components/common/ui-components/data-display/tooltip/MaybeInATooltip';
import { useDialog } from '@/components/common/ui-components/dialogs';
import { SidebarButton } from '@/components/common/ui-components/layout/SidebarButton';
import { DIALOGS_IDS } from '@/enums/constants';
import StartRecordingFilledIcon from '@/static/icons/audiovisuals/record-Filled.svg?react';
import { Event } from '@/tracking/events';

import { requestMicrophoneAccess } from './meeting-recorder.utils';
import { MeetingRecorderDialog } from './MeetingRecorderDialog';
import { sidebarStore } from '../../sidebar.store';

export const MeetingRecorder = () => {
  const { t } = useTranslation();
  const { openDialog, isDialogOpen } = useDialog(DIALOGS_IDS.RECORDER_DIALOG);
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();

  const handleOpenRecordDialog = () => {
    mixpanel.track(Event.CLICK_OPEN_PHYISICAL_RECORDER, {
      source: 'sidebar',
      device: 'desktop',
    });
    requestMicrophoneAccess();
    openDialog();
  };

  return (
    <>
      <MaybeInATooltip
        withTooltip={!isSidebarOpen}
        variant="small"
        placement="right"
        content={t('record')}
        forceHide={isDialogOpen(DIALOGS_IDS.RECORDER_DIALOG)}
      >
        <SidebarButton variant="menu" isSidebarOpen={isSidebarOpen} onClick={handleOpenRecordDialog}>
          <SidebarButton.Icon lineIcon={StartRecordingFilledIcon} className="fill-red" />
          <SidebarButton.Label>{t('record')}</SidebarButton.Label>
        </SidebarButton>
      </MaybeInATooltip>
      <MeetingRecorderDialog />
    </>
  );
};
