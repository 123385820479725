import React, { forwardRef, useRef } from 'react';

import { Key } from 'ts-key-enum';

import { useComposedRefs } from '@/hooks/useComposedRefs';
import ClearIcon from '@/static/icons/basic/cross-Filled.svg?react';
import { listify } from '@/utils/array-utils';
import { cn } from '@/utils/utils';

import { ChipProps } from './types';
import { Tooltip } from '../tooltip';

const SIZE = {
  tiny: 'h-4',
  small: 'h-6',
  large: 'h-7',
};

const isDeleteKeyboardEvent = (keyboardEvent: React.KeyboardEvent) => {
  return keyboardEvent.key === Key.Backspace || keyboardEvent.key === Key.Delete;
};

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      clickable: clickableProp,
      deleteElement: deleteElementProp,
      disabled = false,
      icon: iconProp,
      label,
      className,
      pill = false,
      onClick,
      onDelete,
      size = 'small',
      ...other
    },
    reference
  ) => {
    const chipRef = useRef<HTMLDivElement>(null);
    const handleRef = useComposedRefs(chipRef, reference);

    const handleDeleteIconClick = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement>) => {
      // Stop the event from bubbling up to the `Chip`
      event.stopPropagation();
      if (onDelete && !disabled) {
        onDelete();
      }
    };

    const handleDeleteIconKeyDown = (event: React.KeyboardEvent<HTMLDivElement | HTMLSpanElement>) => {
      // Stop the event from bubbling up to the `Chip`
      event.stopPropagation();
      if (onDelete && isDeleteKeyboardEvent(event) && !disabled) {
        onDelete();
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      // Ignore events from children of `Chip`.
      if (event.currentTarget === event.target && isDeleteKeyboardEvent(event)) {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
      // Ignore events from children of `Chip`.
      if (event.currentTarget === event.target) {
        if (onDelete && isDeleteKeyboardEvent(event) && !disabled) {
          onDelete();
        } else if (event.key === Key.Escape) {
          chipRef?.current?.blur();
        }
      }
    };

    const clickable = clickableProp !== false && onClick ? true : clickableProp;

    let deleteIcon = null;
    if (onDelete) {
      deleteIcon =
        deleteElementProp && React.isValidElement(deleteElementProp) ? (
          React.cloneElement(deleteElementProp as React.ReactElement, {
            className: 'icon-small flex-center',
            onClick: disabled ? undefined : handleDeleteIconClick,
            disabled,
          })
        ) : (
          <button
            type="button"
            className="icon-small flex-center"
            onClick={disabled ? undefined : handleDeleteIconClick}
            onKeyDown={handleDeleteIconKeyDown}
            tabIndex={0}
            aria-label="clear"
            disabled={disabled}
          >
            <ClearIcon />
          </button>
        );
    }

    let icon = null;
    if (iconProp && React.isValidElement(iconProp)) {
      icon = React.cloneElement(iconProp);
    }

    return (
      <div
        role="presentation"
        ref={handleRef}
        className={cn(
          'flex items-center gap-x-1 bg-grey-100',
          SIZE[size],
          size === 'tiny' ? 'p-1 text-xs' : 'py-1 pl-2 text-sm',
          deleteIcon || size === 'tiny' ? 'pr-1' : 'pr-2',
          { 'hover:cursor-pointer': clickable },
          disabled && 'cursor-not-allowed hover:cursor-not-allowed',
          pill ? 'rounded-full' : 'rounded',
          disabled && 'opacity-50',
          className
        )}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        {...other}
      >
        {icon}
        <div className="truncate">{label}</div>
        {deleteIcon}
      </div>
    );
  }
);

type MultipleChipItemsProps = {
  items: string[];
  maxDisplayedNumber?: number;
  size?: ChipProps['size'];
};

const MultipleChipItems = ({ items, size, maxDisplayedNumber = 1 }: MultipleChipItemsProps) => {
  const displayedItems = items.slice(0, maxDisplayedNumber);
  const remainingItems = items.length - maxDisplayedNumber;

  const formattedTooltip = listify(items.slice(maxDisplayedNumber));

  return (
    <>
      {displayedItems.map((sItem) => (
        <Chip key={sItem} size={size} label={sItem} />
      ))}
      {formattedTooltip && (
        <Tooltip placement="top" content={formattedTooltip}>
          <Chip size={size} label={`+${remainingItems}`} />
        </Tooltip>
      )}
    </>
  );
};

export { Chip, MultipleChipItems };
