import resolveConfig from 'tailwindcss/resolveConfig';

import twConfig from '../../tailwind.config';

// Resolve the Tailwind CSS configuration
const resolvedConfig = resolveConfig(twConfig);

// Extract only the colors from the resolved configuration
export const { colors: twThemeColors, spacing: twThemeSpacing, screens: twThemeScreens } = resolvedConfig.theme;

export enum ColorPalette {
  red = '#FF0000',
  blue = '#109CF1',
  accountBlue = '#03DAE6',
  grey = '#254C62B3',
  darkGrey = '#AEAEAE',
  iconGrey = '#AFB6BB',

  chartAverageColor = '#224358',

  callReviewNotSoGood = '#f4b9ac',
  callReviewPerfect = '#daefca',
  callReviewGood = '#fed9a2',

  warning = '#FB9E00',

  basicWhite = '#FFFFFF',
  basicBlack = '#000000',
  basic300 = '#E0E0E0',

  primary100 = '#D7E4FF',
  primary300 = '#86AFFF',
  primary500 = '#4DD599',
}

// eslint-disable-next-line i18next/no-literal-string
export const defaultTopicOrTagColor = '#DFD3C3';
export const colorSets = {
  colorBySpeaker: ['#BFDAC9', '#B6A2E0', '#FC9FD7', '#FC9473'],
  externalCommentColor: [ColorPalette.primary500, '#F8B400'],
};

type ColorObject = {
  /**
   * New colors
   */
  primary: string;
  /**
   * Old colors
   */
  secondary: string;
};
export type ColorObjectKeyType = keyof ColorObject;

const topicColorsMap: Record<string, ColorObject> = {
  '1': { primary: '#282247', secondary: '#382933' },
  '2': { primary: '#4744F0', secondary: '#3282B8' },
  '3': { primary: '#187B6D', secondary: '#127681' },
  '4': { primary: '#77E5AA', secondary: '#63B7AF' },
  '5': { primary: '#C7B198', secondary: '#C7B198' },
  '6': { primary: defaultTopicOrTagColor, secondary: defaultTopicOrTagColor },
  '7': { primary: '#6DD8FF', secondary: '#96DBF4' },
  '8': { primary: '#45BEBE', secondary: '#7FE7CC' },
  '9': { primary: '#F69E7B', secondary: '#F69E7B' },
  '10': { primary: '#F37121', secondary: '#F37121' },
  '11': { primary: '#F8B24F', secondary: '#F8B24F' },
  '12': { primary: '#F3C623', secondary: '#F3C623' },
  '13': { primary: '#FF9292', secondary: '#F96D80' },
  '14': { primary: '#EF2A2A', secondary: '#CF1B1B' },
  '15': { primary: '#A276FE', secondary: '#C68CF0' },
  '16': { primary: '#442270', secondary: '#5C2A9D' },
} as const;

const getTopicsColorPaletteMapByProperty = (prop: ColorObjectKeyType) => {
  const palette = new Map<string, string>();
  for (const key in topicColorsMap) {
    if (Object.prototype.hasOwnProperty.call(topicColorsMap, key)) {
      palette.set(key, topicColorsMap[key][prop]);
    }
  }
  return palette;
};

export const primaryColorsPaletteMap = getTopicsColorPaletteMapByProperty('primary');
export const secondaryColorsPaletteMap = getTopicsColorPaletteMapByProperty('secondary');

const gradeBoundaries = {
  low: 30,
  high: 60,
};

type AiCallScoringColors = {
  text: string;
  background: string;
  borderGradient: string;
  fill: string;
  color: string;
};

export const getColorForAiScoring = (score: number): AiCallScoringColors => {
  if (score >= gradeBoundaries.high) {
    return {
      text: 'text-green',
      background: 'bg-green',
      borderGradient: 'border-gradient-green',
      fill: 'fill-green',
      color: '#4dd599',
    };
  }
  if (score >= gradeBoundaries.low && score < gradeBoundaries.high) {
    return {
      text: 'text-orange',
      background: 'bg-orange',
      borderGradient: 'border-gradient-yellow',
      fill: 'fill-orange',
      color: '#ffb800',
    };
  }
  return {
    text: 'text-red',
    background: 'bg-red',
    borderGradient: 'border-gradient-red',
    fill: 'fill-red',
    color: '#ff4949',
  };
};
