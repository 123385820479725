import React, { Suspense } from 'react';

import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import { ProgressPlaceholder } from '@/components/common/ProgressPlaceholder';
import { Header } from '@/components/header';
import { HealthCheckHooks } from '@/components/health-check';
import { MainSidebar } from '@/components/MainSidebar';
import { MobileNavbar } from '@/components/MobileNavbar';
import { useNotificationsQuery } from '@/entities/notifications/notifications.queries';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { useViewsQuery } from '@/entities/views/views.queries';

export const MainLayout = () => {
  const currentUserQuery = useCurrentUserQuery();
  const viewsQuery = useViewsQuery();
  const notifcationsQuery = useNotificationsQuery();

  // We need to load queries that impact the sidebar to avoid flickering
  if (currentUserQuery.isPending || viewsQuery.isPending || notifcationsQuery.isPending) {
    return <ProgressPlaceholder />;
  }

  return (
    <>
      <HealthCheckHooks />
      <Header />
      <div className="flex min-h-0 flex-1">
        {!isMobile && <MainSidebar />}
        <main className="w-full min-w-0 bg-grey-white">
          <Suspense fallback={<ProgressPlaceholder />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
      {isMobile && <MobileNavbar />}
    </>
  );
};
