import { ApiView, View } from './views.types';

export const mapApiViewToView = ({ createdOn, filters, type, ...other }: ApiView): View => {
  return {
    ...other,
    type: type === 'calls' ? 'explore' : type,
    createdOn: new Date(createdOn),
    filters,
  };
};
