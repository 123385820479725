import { WHITESPACE } from './utils';
import { replaceDiacritics } from '../components/common/ui-components/autocompletes/common.helpers';

/**
 *  Normalize text to be able to compare it with topic keyword.
 * Topics are detected by the backend/data science but seems to not take care of hypens.
 * I'm replacing them with whitespaces to be able to do the comparison without them.
 *
 * @param t string to normalize
 * @returns string with lowercase and hyphens replaced by whitespaces
 */
export const normalizeText = (t: string): string => t.toLowerCase().replaceAll('-', WHITESPACE);

export const isBoldIndexBetweenMarkers = (
  boldIndex: number,
  currentContent: string,
  beginBoldedMarker: string,
  endBoldedMarker: string
) => {
  const beginMarkerBeforeBold = currentContent.lastIndexOf(beginBoldedMarker, boldIndex);
  const endMarkerAfterBold = currentContent.indexOf(endBoldedMarker, boldIndex);
  if (beginMarkerBeforeBold === -1 || endMarkerAfterBold === -1) {
    return { isBetween: false, nextIndex: boldIndex };
  }

  const endMarkerBeforeBold = currentContent.slice(beginMarkerBeforeBold, boldIndex).indexOf(endBoldedMarker);
  if (endMarkerBeforeBold !== -1) {
    return { isBetween: false, nextIndex: boldIndex };
  }

  const beginMarkerAfterBold = currentContent.slice(boldIndex, endMarkerAfterBold).indexOf(beginBoldedMarker);
  if (beginMarkerAfterBold !== -1) {
    return { isBetween: false, nextIndex: boldIndex };
  }

  return { isBetween: true, nextIndex: endMarkerAfterBold + endBoldedMarker.length };
};

const PUNCTUATIONS = new Set([',', '.', ';', ':', '!', "'", '-', '?']);

export const findKeywordThroughPunctuation = (
  string_: string,
  keyword: string,
  includeSubstrings: boolean,
  start = 0
) => {
  for (let index = start; index < string_.length; index += 1) {
    if (string_[index] === keyword[0]) {
      let size = 0;
      let match = true;

      for (let j = 0; j < keyword.length; size += 1) {
        // Check if space or punctuations at the beginning of keyword
        if (!includeSubstrings && j === 0 && index > 0 && ![' ', ...PUNCTUATIONS].includes(string_[index - 1])) {
          match = false;
          break;
        }
        if (!PUNCTUATIONS.has(string_[index + size]) || string_[index + size] === keyword[j]) {
          if (string_[index + size] !== keyword[j]) {
            match = false;
            break;
          }
          j += 1;
        }
        // Check if space or punctuation at the end of keyword
        if (
          !includeSubstrings &&
          j === keyword.length &&
          index + size < string_.length &&
          string_[index + size + 1] !== ' ' &&
          !PUNCTUATIONS.has(string_[index + size + 1])
        ) {
          match = false;
          break;
        }
      }

      if (match) {
        return [index, size];
      }
    }
  }
  return [-1, -1];
};

export const insertBoldMarkers = (
  content: string,
  keywords: string[],
  beginBoldedMarker: string,
  endBoldedMarker: string,
  includeSubstrings = true
) => {
  let currentContent = content;

  const processedKeywords = keywords.map((k) => replaceDiacritics(normalizeText(k)));
  processedKeywords.sort((a, b) => b.length - a.length);

  for (const keyword of processedKeywords) {
    const withoutDiacriticsCurrentContent = replaceDiacritics(normalizeText(currentContent));
    if (keyword !== '') {
      let nextContent = '';
      let index = 0;
      let [boldIndex, boldLength] = findKeywordThroughPunctuation(
        withoutDiacriticsCurrentContent,
        keyword,
        includeSubstrings,
        index
      );

      while (boldIndex >= 0 && boldLength >= 0) {
        const { isBetween, nextIndex } = isBoldIndexBetweenMarkers(
          boldIndex,
          currentContent,
          beginBoldedMarker,
          endBoldedMarker
        );

        if (isBetween) {
          nextContent += currentContent.slice(index, nextIndex);
          index = nextIndex;
          [boldIndex, boldLength] = findKeywordThroughPunctuation(
            withoutDiacriticsCurrentContent,
            keyword,
            includeSubstrings,
            index
          );
        } else {
          if (index !== boldIndex) {
            nextContent += currentContent.slice(index, boldIndex);
          }

          index = boldIndex + boldLength;
          nextContent += beginBoldedMarker + currentContent.slice(boldIndex, index) + endBoldedMarker;
          [boldIndex, boldLength] = findKeywordThroughPunctuation(
            withoutDiacriticsCurrentContent,
            keyword,
            includeSubstrings,
            index
          );
        }
      }
      nextContent += currentContent.slice(Math.max(0, index));
      currentContent = nextContent;
    }
  }

  return currentContent;
};
