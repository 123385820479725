import React, { forwardRef } from 'react';

import clsx from 'clsx';

const CLASSES = {
  base: 'search m-0 mr-2 focus-visible:outline-none focus-visible:shadow-input',
  labelBase: 'cursor-pointer',
};

const UIRadio = forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<'input'>>(
  ({ id, className, ...otherProps }, forwardedReference) => {
    return (
      <input id={id} ref={forwardedReference} type="radio" className={clsx(CLASSES.base, className)} {...otherProps} />
    );
  }
);

export { UIRadio };
