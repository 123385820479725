import React, { useEffect } from 'react';

import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { cn } from '@/utils/utils';

import { SimplePopoverProps } from './types';
import { MaybeInAPortal } from '../layout';
import { DROPDOWN_MARGIN } from '../styles';

export const SimplePopover = ({
  children,
  togglerComponent,
  togglerFullWidth,
  popoverWidth = 'w-auto',
  popoverIndex,
  placement = 'bottom',
  popperReferenceElement,
  offsetOptions = { mainAxis: DROPDOWN_MARGIN, crossAxis: 0 },
  className,
  withPortal = true,
  onOpenChange,
  floatingShiftOptions = { padding: DROPDOWN_MARGIN },
}: SimplePopoverProps) => {
  const { refs, floatingStyles } = useFloating({
    middleware: [offset(offsetOptions), flip({ crossAxis: false }), shift(floatingShiftOptions)],
    whileElementsMounted: autoUpdate,
    elements: { reference: popperReferenceElement },
    placement,
  });
  // we can't know if the popover is open using the headles UI API
  // to detect it we use the transform style to now if the popover is open or not becasue the transform key will be set or not
  useEffect(() => {
    onOpenChange?.(!!floatingStyles.transform);
  }, [onOpenChange, floatingStyles]);

  return (
    <Popover className={clsx(!withPortal && 'z-50', togglerFullWidth && 'w-full')}>
      {({ open }) => (
        <>
          <Popover.Button as="div" ref={refs.setReference}>
            {typeof togglerComponent === 'function' ? togglerComponent(open) : togglerComponent}
          </Popover.Button>
          <MaybeInAPortal withPortal={withPortal}>
            <Transition
              show={open}
              as="div"
              enter="transition duration-100 ease-out"
              enterFrom="-translate-y-3 transform opacity-0"
              enterTo="translate-y-0 transform opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="translate-y-0 transform opacity-100"
              leaveTo="-translate-y-3 transform opacity-0"
              className={clsx(popoverWidth, popoverIndex, 'relative')}
            >
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className={cn('overflow-hidden rounded-md bg-grey-white border border-grey-100 shadow-lg', className)}
              >
                {children}
              </div>
            </Transition>
          </MaybeInAPortal>
        </>
      )}
    </Popover>
  );
};
