import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { LanguageCode } from '@/enums/languages';
import { CustomQueryOptions } from '@/types/common';
import { SUPPORTED_LANGUAGE_CODES } from '@/utils/language';

import { sortedLanguageOptions } from './languages.select';
import { queryKeys } from '../queryKeys';

const LANGUAGES_API_BASE_URL = '/calls/languages';

const useLanguagesQuery = <TSelectData = LanguageCode[]>({
  queryOptions,
}: CustomQueryOptions<LanguageCode[], TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.languages.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<string[]>(LANGUAGES_API_BASE_URL);
      return data.reduce<LanguageCode[]>((supportedLanguageCodeForTenant, apiLanguageCode) => {
        if (
          SUPPORTED_LANGUAGE_CODES.some((supportedLanguageCode) => supportedLanguageCode.toString() === apiLanguageCode)
        ) {
          supportedLanguageCodeForTenant.push(apiLanguageCode as LanguageCode);
        }
        return supportedLanguageCodeForTenant;
      }, []);
    },
    ...queryOptions,
  });
};

export const useSortedLanguagesOptionsQuery = () =>
  useLanguagesQuery({
    queryOptions: {
      select: sortedLanguageOptions,
    },
  });
