import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { DEFAULT_CALL_HISTORY_PAGE_SIZE } from '@/enums/constants';
import { CustomInfiniteQueryOptions, CustomQueryOptions } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { combineRoutes } from '@/utils/url-utils';
import { getNextPageParam } from '@/utils/utils';

import { sortAccounts } from './account.selects';
import { Account } from './account.types';
import { CallHistory } from '../contacts/contacts.types';
import { queryKeys } from '../queryKeys';

const ACCOUNT_BASE_URL = '/accounts';
const ACCOUNT_CALL_HISTORY_API_URL = 'call-history';

const useAccountsQuery = <TSelectData = Account[]>(
  ids: number[],
  options?: CustomQueryOptions<Account[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.account.list(ids),
    enabled: ids.length > 0,
    queryFn: async () => {
      const { data } = await apiService.API.get<Account[]>(ACCOUNT_BASE_URL, { params: { ids } });

      return data;
    },
    ...options?.queryOptions,
  });
};

const useSearchAccountsQuery = <TSelectData = Account[]>(
  query: string,
  minLengthForSearch = 3,
  options?: CustomQueryOptions<Account[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.account.search(query),
    enabled: options?.queryOptions?.enabled !== false && query.length >= minLengthForSearch,
    queryFn: async () => {
      const { data } = await apiService.API.get<Account[]>(combineRoutes([ACCOUNT_BASE_URL, 'search']), {
        params: { q: query },
      });

      return data;
    },
  });
};

const useSortedSearchAccountsQuery = (query: string, minLengthForSearch = 3) => {
  return useSearchAccountsQuery(query, minLengthForSearch, { queryOptions: { select: sortAccounts } });
};

type AccountsCallHistoryQueryParams<TSelectData = InfiniteData<PaginationRequestResult<CallHistory>, number>> = {
  accountId: number;
  limit?: number;
} & CustomInfiniteQueryOptions<PaginationRequestResult<CallHistory>, TSelectData>;

export const useAccountsCallHistoryQuery = <T = InfiniteData<PaginationRequestResult<CallHistory>, number>>({
  accountId,
  limit = DEFAULT_CALL_HISTORY_PAGE_SIZE,
  queryOptions,
}: AccountsCallHistoryQueryParams<T>) => {
  return useInfiniteQuery({
    queryKey: queryKeys.callHistory.byAccountId(accountId),
    queryFn: async ({ pageParam }) => {
      const { data } = await apiService.API.get<PaginationRequestResult<CallHistory>>(
        combineRoutes([ACCOUNT_BASE_URL, ACCOUNT_CALL_HISTORY_API_URL, accountId.toString()]),
        {
          params: { page: pageParam, perPage: limit },
        }
      );
      const result: PaginationRequestResult<CallHistory> = {
        pagination: data.pagination,
        values: data.values.map((value) => ({
          ...value,
          date: new Date(value.date),
        })),
      };
      return result;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, limit),
    ...queryOptions,
  });
};

export { useAccountsQuery, useSearchAccountsQuery, useSortedSearchAccountsQuery };
