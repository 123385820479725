import React, { CSSProperties } from 'react';

import { ColorPalette } from '../../theme/colors';

const DEFAULT_SIZE = '48px';

type ModjoProgressProps = {
  size?: number | string;
  style?: React.SVGAttributes<string>['style'];
  color?: React.SVGAttributes<string>['color'];
  className?: string;
};

const ModjoProgress = ({ color, size, style, className }: ModjoProgressProps) => {
  const lineStyle: CSSProperties = {
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 7,
    stroke: color ?? ColorPalette.primary500,
  };

  let currentSize: string;
  if (size) {
    currentSize = typeof size === 'string' ? size : `${size}px`;
  } else {
    currentSize = DEFAULT_SIZE;
  }

  return (
    <svg
      className={className}
      width={currentSize}
      height={currentSize}
      style={style}
      id="modjo-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <line style={lineStyle} x1="30" y1="42.5" x2="30" y2="57.5">
        <animate attributeName="y1" from="42.5" to="57.5" dur="1s" repeatCount="indefinite" />
        <animate attributeName="y2" from="57.5" to="42.5" dur="1s" repeatCount="indefinite" />
      </line>
      <line style={lineStyle} x1="40" y1="27.5" x2="40" y2="72.5">
        <animate begin="-2.5s" attributeName="y1" from="27.5" to="72.5" dur="3s" repeatCount="indefinite" />
        <animate begin="-2.5s" attributeName="y2" from="72.5" to="27.5" dur="3s" repeatCount="indefinite" />
      </line>

      <line style={lineStyle} x1="50" y1="35" x2="50" y2="65">
        <animate attributeName="y1" from="35" to="65" dur="2s" repeatCount="indefinite" />
        <animate attributeName="y2" from="65" to="35" dur="2s" repeatCount="indefinite" />
      </line>

      <line style={lineStyle} x1="60" y1="27.5" x2="60" y2="72.5">
        <animate attributeName="y1" from="27.5" to="72.5" dur="3s" repeatCount="indefinite" />
        <animate attributeName="y2" from="72.5" to="27.5" dur="3s" repeatCount="indefinite" />
      </line>
      <line style={lineStyle} x1="70" y1="42.5" x2="70" y2="57.5">
        <animate begin="-1.5s" attributeName="y1" from="42.5" to="57.5" dur="1s" repeatCount="indefinite" />
        <animate begin="-1.5s" attributeName="y2" from="57.5" to="42.5" dur="1s" repeatCount="indefinite" />
      </line>
    </svg>
  );
};

export { ModjoProgress };
