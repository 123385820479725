import Dataloader from 'dataloader';

import { apiService } from '@/api.service';

import { mapApiUserToUser } from './users.mappers';
import { USERS_API_BASE_URL } from './users.queries';
import { ApiUser } from './users.types';

// fetch function that returns users in request order
//
const fetchUsers = async (ids: readonly number[]) => {
  const { data } = await apiService.API.get<ApiUser[]>(`${USERS_API_BASE_URL}/list`, {
    params: { ids },
  });
  // dataloader requires that we return users in the same order as requested
  // so we create a map to pick user in O(n) time
  const userMap = new Map(data.map((user) => [user.id, mapApiUserToUser(user)]));

  // map over ids array and pick the users in order
  // null indicates not found
  return ids.map((id) => userMap.get(id) ?? new Error(`User ${id} not found`));
};

// Singleton userLoader created at runtime
export const userByIdLoader = new Dataloader(fetchUsers, {
  cache: false, // cache is handle by react-query when used
});
