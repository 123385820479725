import { ChaptersParams, ChaptersQueryParams } from '@/entities/chapters/chapters.queries';
import { NotesCallsParams, NotesCallsQueryParams } from '@/entities/notes-calls/notes-calls.queries';
import {
  TranscriptionBlocksParams,
  TranscriptionBlocksQueryParams,
} from '@/entities/transcription-blocks/transcription-blocks.queries';
import {
  TranslateParams,
  TranslateQueryParams,
} from '@/entities/transcription-segments/transcription-segments.mutations';

import { useCallURLInfos } from './useCallURLInfos';

type PrivateParams = ChaptersParams | TranscriptionBlocksParams | TranslateParams | NotesCallsParams;
type QueryParams =
  | ChaptersQueryParams
  | TranslateQueryParams
  | TranscriptionBlocksQueryParams<never>
  | NotesCallsQueryParams<never>;

export const useQueryParamsBuilder = (
  privateParams?: PrivateParams,
  globalParams: Record<string, unknown> = {}
): QueryParams => {
  const { isPublic, tenantName, uuid } = useCallURLInfos();
  const queryParams: QueryParams = isPublic
    ? { isPublic: true, queryParams: { tenantName, uuid, ...globalParams } }
    : { isPublic: false, queryParams: { ...(privateParams as PrivateParams), ...globalParams } };
  return queryParams;
};
