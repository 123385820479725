import React, { forwardRef } from 'react';

import { RawButton } from './RawButton';
import { IconButtonBaseProps } from './types';
import { cn } from '../../../../utils/utils';

const CLASSES = {
  base: 'focus:outline-none transition ease-in-out duration-150 shrink-0',
  size: {
    tiny: 'p-1',
    small: 'p-2',
    medium: 'p-2',
    large: 'p-2.5',
  },
  iconSize: {
    tiny: 'h-4 w-4',
    small: 'h-4 w-4',
    medium: 'h-6 w-6',
    large: 'h-6 w-6',
  },
};

const IconButtonBase = forwardRef<HTMLButtonElement, IconButtonBaseProps>(
  ({ icon, className, size = 'medium', disabled, rotateIcon, dataTestId, ...otherProps }, reference) => (
    <RawButton
      data-test-id={dataTestId}
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(CLASSES.base, CLASSES.size[size], className)}
    >
      <span className="flex-center">
        {React.cloneElement(icon, {
          className: cn(
            CLASSES.iconSize[size],
            rotateIcon && 'rotate-180',
            disabled && 'pointer-events-none',
            icon.props.className as string
          ),
        })}
      </span>
    </RawButton>
  )
);

export { IconButtonBase };
