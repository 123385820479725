export const ModjoDefinedDealObjections = {
  INTEGRATION_AND_TECHNICAL_COMPATIBILITY: 'Integration and technical compatibility',
  PRODUCT_SERVICE_FIT_AND_LIMITATIONS: 'Product / Service Fit & Limitations',
  BUDGET_AND_PRICING: 'Budget & Pricing',
  LACK_OF_CLARITY_AND_TRANSPARENCY: 'Lack of clarity & transparency',
  COMMUNICATION_AND_CUSTOMER_SUPPORT: 'Communication and customer support',
  RESISTANCE_TO_CHANGE: 'Resistance to Change',
  LACK_OF_NEED_OR_INTEREST: 'Lack of Need or Interest',
  INFORMATION_MISSING: 'Information Missing',
  AVAILABILITY_AND_TIMING: 'Availability and timing',
  DECISION_MAKING_PROCESS: 'Decision-Making Process',
} as const;

export type ModjoDefinedDealObjection = (typeof ModjoDefinedDealObjections)[keyof typeof ModjoDefinedDealObjections];

export type AiTopic = {
  uuid: string;
  name: string;
};

export type AiTopicCategory = 'objection';

export type AiTopicsByCategory = Record<AiTopicCategory, AiTopic[]>;
