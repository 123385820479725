import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { queryKeys } from '@/entities/queryKeys';
import {
  findTopicById,
  toFormattedTopicsWithTeams,
  toSortedActiveSearchableTopicObjects,
  toSortedActiveSearchableTopicsForGlobalSearch,
  toTopicsCount,
  toTopicsMap,
} from '@/entities/topics/topics.selects';
import { Topic } from '@/entities/topics/topics.types';
import { useIsPublic } from '@/hooks/useIsPublic';
import { CustomQueryOptions } from '@/types/common';

import { useSortedTeamsQuery } from '../teams/teams.hooks';

export const TOPICS_API_BASE_URL = '/topics';

export const useTopicsQuery = <TSelectData = Topic[]>(options?: CustomQueryOptions<Topic[], TSelectData>) => {
  const isPublic = useIsPublic();
  const queryOptions = options?.queryOptions || {};
  return useQuery({
    queryKey: queryKeys.topics.all,
    queryFn: async () => {
      const { data } = await apiService.API.get<Topic[]>(TOPICS_API_BASE_URL);
      return data;
    },
    ...queryOptions,
    enabled: queryOptions?.enabled || !isPublic,
  });
};

export const useTopicsCountQuery = () =>
  useTopicsQuery({
    queryOptions: {
      select: toTopicsCount,
    },
  });

export const useSortedActiveSearchableTopicObjectsQuery = () =>
  useTopicsQuery({
    queryOptions: {
      select: toSortedActiveSearchableTopicObjects,
    },
  });

export const useFormattedTopicsWithTeamsQuery = () => {
  const teamsQuery = useSortedTeamsQuery();
  return useTopicsQuery({
    queryOptions: {
      select: useCallback(
        (topics: Topic[]) => toFormattedTopicsWithTeams(topics, teamsQuery.data ?? []),
        [teamsQuery.data]
      ),
      enabled: teamsQuery.isSuccess,
    },
  });
};

export const useSortedActiveSearchableTopicObjectsForGlobalSearchQuery = () =>
  useTopicsQuery({
    queryOptions: {
      select: toSortedActiveSearchableTopicsForGlobalSearch,
    },
  });

export const useTopicsByIdQuery = (topicId?: number) =>
  useTopicsQuery({
    queryOptions: {
      select: useCallback((topics: Topic[]) => findTopicById(topics, topicId!), [topicId]),
      enabled: !!topicId,
    },
  });

export const useTopicsMapQuery = () =>
  useTopicsQuery({
    queryOptions: {
      select: toTopicsMap,
    },
  });
