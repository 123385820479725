import React, { PropsWithChildren, useContext } from 'react';

import clsx from 'clsx';

import { SidebarButtonContext } from './SidebarButtonContext';

type SidebarButtonLabelProps = PropsWithChildren;

const SidebarButtonLabel = ({ children }: SidebarButtonLabelProps) => {
  const { isSidebarOpen, isActivePage } = useContext(SidebarButtonContext);

  return (
    <span
      className={clsx('whitespace-nowrap leading-4 first-letter:capitalize', {
        hidden: !isSidebarOpen,
        'text-grey-900': !isActivePage,
        'text-grey-white': isActivePage,
      })}
    >
      {children}
    </span>
  );
};

SidebarButtonLabel.displayName = 'SidebarButtonLabel';

export { SidebarButtonLabel };
