import React from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { InputWithAdornments } from '@/components/common/ui-components/inputs/text/InputWithAdornments';
import SearchIcon from '@/static/icons/basic/search-Line.svg?react';
import { Event } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';

import { DealFilterSearchParamKeys } from '../../RootPage/DealsFilters/utils';

export const DealSearchBar = () => {
  const { t } = useTranslation('deals');
  const [searchParams, setSearchParams] = useSearchParams();
  const dealSearch = searchParams.get(DealFilterSearchParamKeys.SEARCH);
  return (
    <InputWithAdornments
      className="ml-auto h-8 w-48 rounded-md outline-none lg:w-80"
      defaultValue={dealSearch ?? ''}
      startAdornment={<SearchIcon className="icon-small" />}
      onClick={() => mixpanel.track(Event.DEALS_USE_SEARCH_BAR)}
      inputProps={{
        placeholder: t('dealList.filters.placeholder.name'),
        value: dealSearch ?? '',
        onChange: (event) => {
          setSearchParams((oldValues) => {
            if (event.currentTarget.value === '') {
              oldValues.delete(DealFilterSearchParamKeys.SEARCH);
            } else {
              oldValues.set(DealFilterSearchParamKeys.SEARCH, event.currentTarget.value);
            }
            return oldValues;
          });
        },
      }}
      dataTestId={DataTestId.DEALS_LIST_SEARCH_ID}
    />
  );
};
