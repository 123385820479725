import React, { PropsWithChildren, useEffect } from 'react';

import { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react';
import { useInView } from 'react-intersection-observer';

import { DataLoading } from '@/components/settings/DataLoading';
import { scrollbarDefaultOptions } from '@/enums/constants';
import { ProductTourIdProps } from '@/types/common';
import { cn } from '@/utils/utils';

import { ScrollArea } from './ScrollArea';

type InfiniteScrollContainerProps = PropsWithChildren<{
  canLoadMore: boolean;
  loadMore: () => void;
  scrollbarOptions?: OverlayScrollbarsComponentProps['options'];
  className?: string;
}> &
  ProductTourIdProps;

type InfiniteScrollContainerDetectorProps = Pick<InfiniteScrollContainerProps, 'canLoadMore' | 'loadMore'>;

export const InfiniteScrollDetector = ({ canLoadMore, loadMore }: InfiniteScrollContainerDetectorProps) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && canLoadMore) {
      loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, canLoadMore]);
  if (!canLoadMore) {
    return null;
  }
  return (
    <div className="h-24 w-full" ref={ref}>
      <DataLoading />
    </div>
  );
};

export const InfiniteScrollContainer = ({
  canLoadMore,
  children,
  loadMore,
  scrollbarOptions = scrollbarDefaultOptions,
  className,
  productTourId,
}: InfiniteScrollContainerProps) => {
  return (
    <ScrollArea className={cn(className, 'h-full')} options={scrollbarOptions} data-product-tour-id={productTourId}>
      {children}
      <InfiniteScrollDetector canLoadMore={canLoadMore} loadMore={loadMore} />
    </ScrollArea>
  );
};
