import mixpanel, { Config } from 'mixpanel-browser';

import { initUtms } from './utms';
import { NetlifyContext } from '../enums/constants';

const MIXPANEL_TOKEN_ID = 'a507e0f77feb7f2d3595df9c91c8a299';
const isPreview = import.meta.env.VITE_CONTEXT === NetlifyContext.PREVIEW;
const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
const initConfig: Partial<Config> = {
  ip: false,
  api_host: '/proxy/mixpanel',
  ...((isPreview || isDevelopment) && { debug: true, batch_size: 1 }),
  // see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views for details
  track_pageview: 'url-with-path',
};

export const mixpanelInit = () => {
  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, initConfig);
  } else {
    mixpanel.init(MIXPANEL_TOKEN_ID, initConfig);
  }

  mixpanel.register({ $viewport_height: window.innerHeight, $viewport_width: window.innerWidth });

  initUtms();
};
