import { useEffect } from 'react';

type ShortcutKeyOptions = {
  key: string;
  metaKey?: boolean;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  callback: () => void | Promise<void>;
};

export const useShortcutKey = ({ key, metaKey, ctrlKey, altKey, shiftKey, callback }: ShortcutKeyOptions) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === key &&
        event.metaKey === !!metaKey &&
        event.ctrlKey === !!ctrlKey &&
        event.altKey === !!altKey &&
        event.shiftKey === !!shiftKey
      ) {
        event.preventDefault();
        event.stopImmediatePropagation();
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [altKey, callback, ctrlKey, key, metaKey, shiftKey]);
};
