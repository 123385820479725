import { createStoreAndMethods } from '@/utils/zustand-utils';

type DealViewStore = {
  rightPanelWidth: number | undefined;
};

const initialState: DealViewStore = {
  rightPanelWidth: undefined,
};

const [, dealViewStore] = createStoreAndMethods(initialState);

export { dealViewStore };
