/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseBoundStore, StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';
import { UseBoundStoreWithEqualityFn, createWithEqualityFn } from 'zustand/traditional';

import { capitalizeFirstLetter, isAlphanumeric } from './utils';

type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
};

type StoreMethods<T> = {
  [K in keyof T as `use${Capitalize<string & K>}`]: () => T[K];
} & {
  [K in keyof T as `set${Capitalize<string & K>}`]: (value: T[K]) => void;
} & {
  [K in keyof T as `get${Capitalize<string & K>}`]: () => T[K];
} & {
  reset: () => void;
};

const isEqual = (a: any, b: any) => {
  return a === b;
};

export function createMethods<S extends object, K extends keyof S>(store: UseBoundStoreWithEqualityFn<StoreApi<S>>) {
  const storeState = store.getState();
  const functions = {};
  for (const k of Object.keys(storeState)) {
    if (typeof storeState[k as K] === 'function') {
      throw new TypeError(
        "Actions need to be defined in a separate object, this function doesn't support actions in the store "
      );
    }
    if (!isAlphanumeric(k)) {
      throw new Error(`The key ${k} is not alphanumeric, please use only alphanumeric keys`);
    }
    const useShallow = typeof storeState[k as K] === 'object';
    (functions as any)[`use${capitalizeFirstLetter(k)}`] = () =>
      store((s) => s[k as K], useShallow ? shallow : isEqual);
    (functions as any)[`set${capitalizeFirstLetter(k)}`] = (value: S[K]) =>
      store.setState(() => ({ [k]: value }) as unknown as Partial<S>);
    (functions as any)[`get${capitalizeFirstLetter(k)}`] = () => store.getState()[k as K];
  }
  (functions as any).reset = () => store.setState(() => storeState, true);
  return functions as Prettify<StoreMethods<S>>;
}

export const createStoreAndMethods = <S extends object>(initialState: S) => {
  const store = createWithEqualityFn<S>()(() => initialState, Object.is);
  const functions = createMethods(store);
  return [store, functions] as [UseBoundStore<StoreApi<S>>, Prettify<StoreMethods<S>>];
};
