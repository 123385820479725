import React, { PropsWithChildren } from 'react';

import { i18n } from '@/translation/i18n';
import { Provider, Providers } from '@/types/providers';

import { PartialRecord } from './type-utils';
import { cn } from './utils';

const HELP_CENTER_URL = 'https://help.modjo.ai';

export const getHelpCenterUrl = (path = '') => `${HELP_CENTER_URL}/${i18n.language}${path}`;

export const PROVIDER_HELPCENTER_PATH_MAPPING: PartialRecord<Provider, `/articles/${number}`> = {
  [Providers.AIRCALL]: '/articles/5862846',
  [Providers.AMAZON_SERVICE_CLOUD_VOICE]: '/articles/6486193',
  [Providers.APIZEE]: '/articles/6510738',
  [Providers.CLOUDTALK]: '/articles/5903330',
  [Providers.DIABOLOCOM_V2]: '/articles/9610174',
  [Providers.DIABOLOCOM]: '/articles/5862860',
  [Providers.FRESHCALLER]: '/articles/6584273',
  [Providers.HUBSPOT]: '/articles/5862878',
  [Providers.GOOGLE]: '/articles/5914309',
  [Providers.GOOGLE_USER_FLOW]: '/articles/5914309',
  [Providers.MICROSOFT_USER_FLOW]: '/articles/6005062',
  [Providers.GOOGLE_RECORDING_BOT]: '/articles/9772769',
  [Providers.MICROSOFT_RECORDING_BOT]: '/articles/9772389',
  [Providers.MICROSOFT_TENANT_FLOW]: '/articles/5862883',
  [Providers.SALESFORCE]: '/articles/5863065',
  [Providers.PIPEDRIVE]: '/articles/5862894',
  [Providers.OUTREACH]: '/articles/5862885',
  [Providers.ZOOM]: '/articles/5863148',
  [Providers.ZOOM_PHONE]: '/articles/5863161',
  [Providers.FUZE]: '/articles/5862870',
  [Providers.RING_CENTRAL]: '/articles/5903342',
  [Providers.RINGOVER]: '/articles/5862903',
  [Providers.SALESLOFT]: '/articles/5863121',
  [Providers.SELLSY]: '/articles/5903356',
  [Providers.TALKDESK]: '/articles/5863126',
  [Providers.ZENDESK]: '/articles/5863131',
  [Providers.ZOHO]: '/articles/5863147',
  [Providers.GMAIL]: '/articles/9291682',
  [Providers.OUTLOOK_EMAIL]: '/articles/9291682',
  [Providers.DYNAMICS]: '/articles/9105000',
};

export const RESTRICT_CALL_ACCESS_INTERCOM_ARTICLE_PATH = getHelpCenterUrl(
  '/articles/8736674-how-to-restrict-call-visibility-and-access'
);

export const AI_CALL_SCORING_VISIBILITY_INTERCOM_ARTICLE_PATH = getHelpCenterUrl(
  '/articles/9065127-display-ai-call-scoring-to-all-users'
);

export const getHelpCenterProviderUrl = (provider?: Provider | null): string => {
  if (!provider || !Object.keys(PROVIDER_HELPCENTER_PATH_MAPPING).includes(provider)) {
    return getHelpCenterUrl();
  }
  return getHelpCenterUrl(PROVIDER_HELPCENTER_PATH_MAPPING[provider]);
};

export const HelpCenterLinkComponent = ({
  children,
  provider,
  path,
  onClick,
  underline,
  className,
}: PropsWithChildren<{
  provider?: Provider;
  path?: string;
  onClick?: () => void;
  underline?: 'hover' | 'always';
  className?: string;
}>) => (
  <a
    className={cn(underline === 'always' ? 'underline' : 'flex items-center justify-center hover:underline', className)}
    href={(provider !== undefined && getHelpCenterProviderUrl(provider)) || getHelpCenterUrl(path)}
    onClick={onClick}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
