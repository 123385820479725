import { JSONContent } from '@tiptap/core';

import { BlockReplier, Comment, Reaction } from '../comments/comments.types';

export interface NodeOfANote {
  id: string;
  content: string;
  date: string;
  timestamp?: number;
}

export const NoteTypes = {
  USER: 'USER',
  AI: 'AI',
} as const;
export type NoteType = keyof typeof NoteTypes;

export type ApiNote = {
  id: number;
  date: string;
  rawContent: JSONContent | null | undefined;
  call: {
    id: number;
    name: string | null;
  } | null;
  user: {
    id: number;
    name: string;
  };
  /**
   * @deprecated
   * - to keep for backwards compability ( not yet we miss the commentIds to be fully backwards compability )
   * - complete data about node replies is now needed and provided by {@link blockRepliers}
   */
  commentCount: Record<string, number[]>;
  reactions: Record<string, Reaction[]>;
  blockRepliers: Record<string, BlockReplier[]>;
  blockRepliesNumber: Record<string, number>;
  type: NoteType;
  summaryTemplateUuid: string | null;
  title: string;
};

export type CreateNoteComment = {
  content: string;
  blockUuid: string;
};

export type CommentFromNoteBlock = {
  id: number | string;
  content: string;
  callId: number;
  noteId?: number;
  timestamp: number | null;
  createdById: number;
  createdOn: string;
  nodeUuid: string;
  commentReactions: Reaction[];
};

export const isCommentFromNoteNode = (comment: CommentFromNoteBlock | Comment): comment is CommentFromNoteBlock => {
  return comment.nodeUuid !== undefined;
};

type NoteComment = {
  id: number;
  content: string;
};

type NoteCall = {
  date?: string;
  name: string;
  id: number;
  isAssociated: boolean | null;
};

export type Note = {
  id: number;
  date: string;
  comments: NoteComment[];
  call: NoteCall | null;
  rawContent: string | null;
};

export type NoteReaction = {
  unicode: string;
  blockUuid: string;
  createdById: number;
  id: number;
};

export type CreateReaction = {
  blockUuid: string;
  unicode: string;
};
