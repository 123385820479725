import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { Key } from 'ts-key-enum';

import { AlertVariant } from '@/components/common/toast/alert';
import { createToast } from '@/components/common/toast/ToastHelpers';
import { Menu } from '@/components/common/ui-components/menu';
import { MenuItem } from '@/components/common/ui-components/menu/types';
import { updateParams, useUrlFilters } from '@/components/explore/useUrlFilters';
import { useCreateViewMutation, useDeleteViewMutation, useUpdateViewMutation } from '@/entities/views/views.mutation';
import { View, ViewType } from '@/entities/views/views.types';
import { DataState, RoutePaths } from '@/enums';
import MoreIcon from '@/static/icons/basic/more-small-hori-Filled.svg?react';
import BinIcon from '@/static/icons/basic/trash-Line.svg?react';
import CopyIcon from '@/static/icons/editor/copy_past-Line.svg?react';
import EditIcon from '@/static/icons/editor/edit-Line.svg?react';
import { EditViewOrigin, Event } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';

import { SIDEBAR_FLOATING_SHIFTING_OPTIONS, SIDEBAR_OFFSET_OPTIONS } from '../sidebar.constants';

type NavigationViewButtonProps = View & { viewType: ViewType };

const NavigationViewButton = ({ id, label, filters, sorting, viewType, viewConfig }: NavigationViewButtonProps) => {
  const { t } = useTranslation();
  const { viewId } = useUrlFilters();
  const [viewName, setViewName] = useState(label);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const createViewMutation = useCreateViewMutation();
  const updateViewMutation = useUpdateViewMutation();
  const deleteViewMutation = useDeleteViewMutation();
  const navigate = useNavigate();

  const isViewActive = viewId === id;
  const redirectPathName = viewType === 'deals' ? RoutePaths.DEALS : RoutePaths.EXPLORE;

  const updatedParams = updateParams(new URLSearchParams(), {
    ...filters,
    ...sorting,
    ...viewConfig,
    viewId: id,
  });

  const menuItems: MenuItem[] = [
    {
      label: t('rename'),
      className: 'first-letter:capitalize',
      icon: <EditIcon className="icon-small" />,
      dataTestId: DataTestId.SIDEBAR_VIEW_BUTTON_MENU_RENAME_ID,
      onClick: () => setIsEditing(true),
    },
    {
      label: t('duplicate'),
      className: 'first-letter:capitalize',
      icon: <CopyIcon className="icon-small" />,
      dataTestId: DataTestId.SIDEBAR_VIEW_BUTTON_MENU_DUPLICATE_ID,
      onClick: () =>
        createViewMutation.mutate(
          {
            label: t('copyName', { name: viewName }),
            type: viewType,
            filters,
            sorting: sorting ?? undefined,
          },
          {
            onSuccess: ({ id: newViewId }) => {
              mixpanel.track(Event.DUPLICATE_FILTERS_VIEW, {
                type: viewType,
                origin: EditViewOrigin.SIDEBAR,
              });
              createToast(t('views.buttons.duplicate.toast.success'));
              const updatedParamsWithNewView = updateParams(new URLSearchParams(), {
                ...filters,
                ...sorting,
                viewId: newViewId,
              });
              navigate({ pathname: redirectPathName, search: updatedParamsWithNewView.toString() });
            },
          }
        ),
    },
    {
      label: t('delete'),
      className: 'text-red first-letter:capitalize',
      icon: <BinIcon className="icon-small fill-red" />,
      dataTestId: DataTestId.SIDEBAR_VIEW_BUTTON_MENU_DELETE_ID,
      onClick: () =>
        deleteViewMutation.mutate(id, {
          onSuccess: () => {
            mixpanel.track(Event.DELETE_FILTERS_VIEW, {
              type: viewType,
              origin: EditViewOrigin.SIDEBAR,
            });
            createToast(t('views.buttons.delete.toast.success'));
            if (isViewActive) {
              navigate({ pathname: redirectPathName });
            }
          },
        }),
    },
  ];

  const submitNewViewName = () =>
    updateViewMutation.mutate(
      {
        id,
        label: viewName,
        type: viewType,
      },
      {
        onSuccess: () => {
          createToast(t('views.buttons.rename.toast.success'));
          mixpanel.track(Event.RENAME_FILTERS_VIEW, {
            type: viewType,
            source: EditViewOrigin.SIDEBAR,
          });
        },
      }
    );

  useClickAway(inputRef, (e: MouseEvent) => {
    if (viewName === '') {
      createToast(t('views.buttons.rename.toast.errorLength'), AlertVariant.Error);
      e.preventDefault();
      return;
    }
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsEditing(false);
      submitNewViewName();
    }
  });

  return (
    <NavLink
      to={{
        pathname: redirectPathName,
        search: updatedParams.toString(),
      }}
      className={clsx(
        'group my-0.5 ml-2 flex justify-between gap-x-1 rounded-lg px-2 py-1 transition-colors',
        isViewActive ? 'bg-blue text-grey-white' : 'hover:bg-grey-100'
      )}
      data-state={isViewActive ? DataState.ACTIVE : DataState.INACTIVE}
      onClick={() => mixpanel.track(Event.APPLY_FILTERS_VIEW, { type: viewType, source: EditViewOrigin.SIDEBAR })}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={viewName}
          data-test-id={DataTestId.SIDEBAR_VIEW_INPUT_NAME_ID}
          onChange={(e) => setViewName(e.target.value)}
          onKeyDown={(e) => {
            if ([Key.Enter, Key.Escape].includes(e.key as Key)) {
              if (viewName === '') {
                createToast(t('views.buttons.rename.toast.errorLength'), AlertVariant.Error);
                return;
              }
              setIsEditing(false);
              submitNewViewName();
            }
          }}
          className={clsx('w-full outline-none', isViewActive ? 'bg-blue text-grey-white' : 'bg-transparent')}
        />
      ) : (
        <span className="truncate">{viewName}</span>
      )}

      <Menu
        items={menuItems}
        Button={() => (
          <MoreIcon
            className={clsx(
              'icon-small hidden rounded transition-colors group-hover:block',
              isViewActive ? 'fill-grey-white hover:bg-blue-600' : 'hover:bg-grey'
            )}
          />
        )}
        placement="right"
        floatingShiftOptions={SIDEBAR_FLOATING_SHIFTING_OPTIONS}
        offset={SIDEBAR_OFFSET_OPTIONS}
      />
    </NavLink>
  );
};

export { NavigationViewButton };
