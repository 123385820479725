import React, { useCallback, useContext } from 'react';

import { ConfirmationDialog } from './ConfirmationDialog';
import { DialogContext } from './DialogContext';
import { SimpleDialog } from './SimpleDialog';

const getMissingDialogIdError = (hookDialogId?: string, functionDialogId?: string) => {
  if (!hookDialogId && !functionDialogId) {
    throw new Error('You must use a dialog ID. See documention.');
  }
};

/**
 * To facilitate usage of this hook, you can pass in args a dialog ID that you want to use in a component.
 * By doing so, all the exported methods will automatically use this ID to avoid setting it each time.
 *
 * In case you want to handle multiple dialogs in a single component, you can either :
 * - override the ID in the function you want. Eg: closeDialog('dialog-1').
 * - let @param dialogId empty and manage ID in functions.
 *
 * However, you must at least pass a dialog ID to know which dialog you to handle.
 */
const useDialog = (dialogId?: string) => {
  const { dialogs, setOpenDialog } = useContext(DialogContext);

  const openDialog = useCallback(
    (event?: React.MouseEvent<HTMLElement>, id?: string) => {
      event?.stopPropagation();
      getMissingDialogIdError(dialogId, id);
      setOpenDialog(true, id ?? dialogId!);
    },
    [setOpenDialog, dialogId]
  );

  const closeDialog = useCallback(
    (id?: string) => {
      getMissingDialogIdError(dialogId, id);
      setOpenDialog(false, id ?? dialogId!);
    },
    [setOpenDialog, dialogId]
  );

  const isDialogOpen = (id?: string) => Boolean(dialogs.get(id ?? dialogId!));

  return {
    SimpleDialog,
    ConfirmationDialog,
    dialogs,
    isDialogOpen,
    openDialog,
    closeDialog,
  };
};

export { useDialog };
