import React, { CSSProperties } from 'react';

export const BEGIN_BOLD_MARKER = '<<<<';
export const END_BOLD_MARKER = '>>>>';

export const boldMarkersToJSX = (
  contentWithMarkers: string,
  blockId: number,
  parameters?: { whiteSpace?: CSSProperties['whiteSpace'] }
) => {
  const res: JSX.Element[] = [];
  const whiteSpace = parameters?.whiteSpace;
  const spanStyle: CSSProperties | undefined = whiteSpace && { whiteSpace };

  let index = 0;
  let beginMarkerIndex = contentWithMarkers.indexOf(BEGIN_BOLD_MARKER, index);
  let endMarkerIndex = contentWithMarkers.indexOf(END_BOLD_MARKER, index);

  while (beginMarkerIndex !== -1) {
    if (index !== beginMarkerIndex) {
      res.push(
        <span data-test-id="regularMessageText" style={spanStyle} key={`${blockId}_${index}`}>
          {contentWithMarkers.slice(index, beginMarkerIndex)}
        </span>
      );
    }

    index = endMarkerIndex + END_BOLD_MARKER.length;
    const boldedContent = contentWithMarkers.slice(beginMarkerIndex + BEGIN_BOLD_MARKER.length, endMarkerIndex);
    res.push(
      <span
        data-test-id="boldMessageText"
        key={`${blockId}_${beginMarkerIndex}`}
        className="font-semibold"
        style={{ ...spanStyle }}
      >
        {boldedContent}
      </span>
    );
    beginMarkerIndex = contentWithMarkers.indexOf(BEGIN_BOLD_MARKER, index);
    endMarkerIndex = contentWithMarkers.indexOf(END_BOLD_MARKER, index);
  }
  res.push(
    <span data-test-id="regularMessageText" key={blockId} style={spanStyle}>
      {contentWithMarkers.slice(Math.max(0, index))}
    </span>
  );

  return res;
};
