import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { queryKeys } from '@/entities/queryKeys';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import {
  getUserCalendarIntegration,
  getUserHealthCheckDownIntegrations,
  getUserIntegrations,
} from './providersScopes.select';
import { ProviderFeature, UserFeatureDto } from './providersScopes.types';
import { PROVIDERS_API_BASE_URL } from '../providers.queries';

export const useSelfProvidersFeaturesQuery = <TSelectData = ProviderFeature[]>(
  options?: CustomQueryOptions<ProviderFeature[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.providers.featuresForMe(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ProviderFeature[]>(
        combineRoutes([PROVIDERS_API_BASE_URL, 'users', 'me'])
      );
      return data;
    },
    ...options?.queryOptions,
  });
};

type UseProvidersFeaturesAllUsersQueryParams<TSelectData = UserFeatureDto> = CustomQueryOptions<
  UserFeatureDto,
  TSelectData
> & {
  provider: string;
};

export const useProvidersFeaturesForAllUsersQuery = <TSelectData = UserFeatureDto>({
  provider,
  queryOptions,
}: UseProvidersFeaturesAllUsersQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.providers.featuresForUsers(provider),
    queryFn: async () => {
      const { data } = await apiService.API.get<UserFeatureDto>(
        combineRoutes([PROVIDERS_API_BASE_URL, provider, 'features', 'users'])
      );
      return data;
    },
    ...queryOptions,
  });
};

export const useUserIntegrationsQuery = (hasLicense: boolean, enabled = false) => {
  return useSelfProvidersFeaturesQuery({
    queryOptions: {
      select: useCallback((features: ProviderFeature[]) => getUserIntegrations(features, hasLicense), [hasLicense]),
      enabled,
    },
  });
};

export const useUserCalendarIntegrationQuery = () => {
  return useSelfProvidersFeaturesQuery({
    queryOptions: {
      select: getUserCalendarIntegration,
    },
  });
};

export const useUserHealthCheckDownIntegrationsQuery = () => {
  return useSelfProvidersFeaturesQuery({
    queryOptions: {
      select: getUserHealthCheckDownIntegrations,
    },
  });
};
