import { ObjectValues } from '@/types/common';

export const AdvancedSettingsFeatures = {
  CALL_IMPORT_RESTRICTIONS: 'Call import restrictions',
  REMINDER_EMAIL: 'Reminder email',
  RESTRICT_CALL_ACCESS: 'Restrict call access',
  EXPIRATION_LINK: 'Expiration link',
  AI_CALL_SCORING_VISIBILITY: 'AI call scoring visibility',
} as const;
export type AdvancedSettingsFeature = ObjectValues<typeof AdvancedSettingsFeatures>;
