import { InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { MIN_LENTH_FOR_SEARCH } from '@/components/MainSidebar/Search/SearchDialog/SearchContent/search-hook';
import { queryKeys } from '@/entities/queryKeys';
import { CustomInfiniteQueryOptions, CustomQueryOptions, flattenPaginatedQueryData } from '@/types/common';
import { PaginationRequestResult } from '@/types/paginations';
import { CallRecordingProvider, ExternalPhoneProvider } from '@/types/providers';
import { roundUpMaxDuration } from '@/utils/date-utils';
import { queryClient } from '@/utils/queryClient';
import { getNextPageParam } from '@/utils/utils';

import { CALLS_API_BASE_URL } from './calls.constants';
import { getCallCrmEntityTypes, sortProvidersListingAsSearchableOptions } from './calls.selects';
import {
  AiCallScoring,
  AiCallScoringDetails,
  ApiCallCrmInfos,
  ApiCallDurationRange,
  ApiCallInfo,
  ApiListedCall,
  SearchFiltersDTO,
} from './calls.types';
import { combineRoutes } from '../../utils/url-utils';

type BaseCallQueryParams = { callId: number };

type UseCallListQueryParams<TSelectData = InfiniteData<PaginationRequestResult<ApiListedCall>>> = {
  filters?: SearchFiltersDTO;
  limit?: number;
} & CustomInfiniteQueryOptions<PaginationRequestResult<ApiListedCall>, TSelectData>;

const useCallListQuery = <T = InfiniteData<PaginationRequestResult<ApiListedCall>>>({
  filters,
  limit = 10,
  queryOptions,
}: UseCallListQueryParams<T>) => {
  return useInfiniteQuery({
    queryKey: queryKeys.calls.list(filters, limit),
    queryFn: async ({ pageParam }) => {
      const { data } = await apiService.API.post<PaginationRequestResult<ApiListedCall>>(
        combineRoutes([CALLS_API_BASE_URL, 'search']),
        {
          filters,
          pagination: { page: pageParam, perPage: limit },
        }
      );
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => getNextPageParam(lastPage, limit),
    ...queryOptions,
  });
};

export const useFlatCallListQuery = (params: Omit<UseCallListQueryParams, 'queryOptions'>) => {
  return useCallListQuery({
    ...params,
    queryOptions: {
      select: flattenPaginatedQueryData,
    },
  });
};

const callSearchQueryObject = (query: string) => {
  return {
    queryKey: queryKeys.calls.search(query),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiCallInfo[] | undefined>(`${CALLS_API_BASE_URL}/search`, {
        params: { q: query },
      });
      return data ?? [];
    },
  };
};

type UseCallSearchQueryParams<TSelectData = ApiCallInfo[]> = CustomQueryOptions<ApiCallInfo[], TSelectData> & {
  query: string;
};

const useCallSearchQuery = <TSelectData = ApiCallInfo[]>({
  query,
  queryOptions,
}: UseCallSearchQueryParams<TSelectData>) => {
  return useQuery({
    ...callSearchQueryObject(query),
    enabled: query.length >= MIN_LENTH_FOR_SEARCH,
    placeholderData: (values) => values,
    ...queryOptions,
  });
};

const useCallsDurationRangeQuery = () => {
  return useQuery({
    queryKey: queryKeys.calls.durationRange(),
    queryFn: async () => {
      const {
        data: { maxDuration },
      } = await apiService.API.get<ApiCallDurationRange>(`${CALLS_API_BASE_URL}/duration-range`);
      return {
        min: 0,
        max: roundUpMaxDuration(maxDuration),
      };
    },
  });
};

type UseAiCallScoringQueryParams<TSelectData = AiCallScoring[]> = CustomQueryOptions<AiCallScoring[], TSelectData> &
  BaseCallQueryParams;

const useAiCallScoringListQuery = <TSelectData = AiCallScoring[]>({
  callId,
  queryOptions,
}: UseAiCallScoringQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.calls.aiCallScoring(callId),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiCallScoring[]>(`${CALLS_API_BASE_URL}/${callId}/ai-scoring`);
      return data.sort((a, b) => a.templateTitle.localeCompare(b.templateTitle));
    },
    ...queryOptions,
  });
};

const fetchAiCallScoringDetails = async (templateUuid: AiCallScoring['templateUuid'], callId: number) => {
  const { data } = await apiService.API.get<AiCallScoringDetails[]>(
    combineRoutes([CALLS_API_BASE_URL, callId.toString(), '/ai-scoring', templateUuid])
  );
  return data.sort((a, b) => a.question.order - b.question.order);
};

export const prefetchAiCallScoringDetails = async (templateUuid: AiCallScoring['templateUuid'], callId: number) => {
  await queryClient.prefetchQuery({
    queryKey: queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId),
    queryFn: () => fetchAiCallScoringDetails(templateUuid, callId),
  });
};

type UseAiCallScoringTemplateQueryParams<TSelectData = AiCallScoringDetails[]> = CustomQueryOptions<
  AiCallScoringDetails[],
  TSelectData
> & {
  callId: number;
  templateUuid: AiCallScoring['templateUuid'];
};

const useAiCallScoringDetailsQuery = <TSelectData = AiCallScoringDetails[]>({
  callId,
  templateUuid,
  ...otherQueryOptions
}: UseAiCallScoringTemplateQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId),
    queryFn: async () => fetchAiCallScoringDetails(templateUuid, callId),
    ...otherQueryOptions,
  });
};

const useTenantCallProvidersQuery = <TSelectData = CallRecordingProvider[]>(
  options?: CustomQueryOptions<ExternalPhoneProvider[], TSelectData>
) => {
  return useQuery({
    queryKey: queryKeys.calls.tenantCallProviders(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ExternalPhoneProvider[]>(
        combineRoutes([CALLS_API_BASE_URL, 'external-providers'])
      );
      return data;
    },
    ...options?.queryOptions,
  });
};

type UseCallCrmInfosQueryParams<TSelectData = ApiCallCrmInfos> = CustomQueryOptions<ApiCallCrmInfos, TSelectData> &
  BaseCallQueryParams;

const useCallCrmInfoQuery = <TSelectData = ApiCallCrmInfos>({
  callId,
  queryOptions,
}: UseCallCrmInfosQueryParams<TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.calls.crmInfo(callId),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiCallCrmInfos>(
        combineRoutes([CALLS_API_BASE_URL, callId.toString(), 'crm-infos'])
      );
      return data;
    },
    ...queryOptions,
    enabled: callId > 0,
  });
};

type UseCallCrmEntityTypesQuery<TSelectData = ApiCallCrmInfos> = CustomQueryOptions<ApiCallCrmInfos, TSelectData> &
  BaseCallQueryParams;

const useCallCrmEntityTypesQuery = <TSelectData = keyof Omit<ApiCallCrmInfos, 'crm'>[]>({
  callId,
  queryOptions,
}: UseCallCrmEntityTypesQuery<TSelectData>) => {
  return useCallCrmInfoQuery({ callId, queryOptions: { ...queryOptions, select: getCallCrmEntityTypes } });
};

const useSortedTenantCallProvidersAsSearchableOptionsQuery = () => {
  return useTenantCallProvidersQuery({ queryOptions: { select: sortProvidersListingAsSearchableOptions } });
};

export {
  useCallListQuery,
  useCallSearchQuery,
  callSearchQueryObject,
  useCallsDurationRangeQuery,
  useAiCallScoringListQuery,
  useAiCallScoringDetailsQuery,
  useSortedTenantCallProvidersAsSearchableOptionsQuery,
  useCallCrmInfoQuery,
  useCallCrmEntityTypesQuery,
  useTenantCallProvidersQuery,
};
