import React from 'react';

import { useTranslation } from 'react-i18next';

type ClearAdornmentProps = { label?: string } & React.HTMLAttributes<HTMLDivElement>;

const ClearAdornment = ({ label, ...otherProps }: ClearAdornmentProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="cursor-pointer text-sm font-semibold text-blue first-letter:capitalize hover:opacity-50"
      role="button"
      tabIndex={0}
      {...otherProps}
    >
      {label ?? t('clear')}
    </div>
  );
};

ClearAdornment.displayName = 'ClearAdornment';

export { ClearAdornment };
