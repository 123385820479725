import { isEmpty } from '@/utils/utils';

import { useActivityTimelineQuery } from './activities.queries';
import { ActivitySearchDTO, ActivityType } from './activity.types';
import { DealInteraction } from '../deals/deals.types';

export const useDealActivityCountsByTypeQuery = ({ activityType: _, ...filters }: ActivitySearchDTO) => {
  return useActivityTimelineQuery({
    filters,
    queryOptions: {
      select: (activities: DealInteraction[]) => {
        const activityCountsByType = activities.reduce<Partial<Record<ActivityType, number>>>(
          (acc, activity) => ({ ...acc, [activity.type]: (acc[activity.type] ?? 0) + 1 }),
          {}
        );
        return {
          all: activities.length,
          call: activityCountsByType?.call ?? 0,
          providerEmail: activityCountsByType?.providerEmail ?? 0,
        };
      },
      enabled: !isEmpty(filters) && !!filters?.dealIds?.length,
    },
  });
};
