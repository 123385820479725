import { ObjectValues } from './common';

export const SortingValues = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
} as const;

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export type SortOrder = ObjectValues<typeof SortOrders>;

export const isSortOrder = (value: string): value is SortOrder =>
  Object.values(SortOrders).includes(value as SortOrder);
