import { OtherOption } from '@/components/common/search-components/Common';
import { OtherInfoOptionType } from '@/components/common/search-components/search-components.types';
import { CrmEntityType } from '@/enums/crm-fields';

import { useDealCrmEntityFieldsQuery } from './deals.queries';
import { CrmFieldColumn, DealCRMField } from './deals.types';

const toSearchableOptions = (data: string[], type: OtherInfoOptionType) => {
  if (data) {
    return data.map((stage, index) => new OtherOption(index.toString(), stage, type));
  }
  return [];
};

const sortedDealCrmFields = (dealCrmFields: DealCRMField[], search: string) => {
  const crmEntityTypesArray = Object.values(CrmEntityType);
  return dealCrmFields.filter(
    (field) =>
      field.label.toLowerCase().includes(search.toLowerCase()) &&
      !crmEntityTypesArray.includes(field.validation.type as CrmEntityType)
  );
};

const useDealCrmEntityKeys = () => {
  return useDealCrmEntityFieldsQuery({
    queryOptions: {
      select: (fields) => fields.map((field) => field.sourceKey),
    },
  });
};

const mappedFields = (fields: CrmFieldColumn[]): CrmFieldColumn[] => {
  return fields.map((field) => {
    return {
      sourceKey: field.sourceKey,
      type: field.type,
      label: field.label,
      createdOn: field.createdOn,
    };
  });
};

const useDealListCRMFieldColumn = () => {
  return useDealCrmEntityFieldsQuery<CrmFieldColumn[]>({
    queryOptions: {
      select: mappedFields,
    },
  });
};

export { toSearchableOptions, sortedDealCrmFields, useDealCrmEntityKeys, useDealListCRMFieldColumn };
