import { compareDesc } from 'date-fns';

import { UNKNOWN_SPEAKER_LABEL } from '@/enums/constants';
import { CallURLInfos } from '@/hooks/useCallURLInfos';
import { queryClient } from '@/utils/queryClient';

import { CallDetailsTeams, PrivateCallDetails, Speaker } from './call-details.types';
import { ApiListener } from '../calls/calls.types';
import { Contact } from '../contact/contact.types';
import { queryKeys } from '../queryKeys';
import { ApiUser } from '../users/users.types';

/**
 * Returns the query key to retrieve the call details based on the provided call URL information.
 * This is pretty useful to be able to update the store in a mutation.
 * @param callURLInfos - The call URL information.
 * @returns The query key for retrieving call details.
 */
export const getCallDetailsQueryKeyFromCallUrlInfos = (
  callURLInfos: CallURLInfos
): readonly [...string[], 'callDetails', string, string] | readonly [...string[], 'callDetails', number] => {
  return callURLInfos.isPublic
    ? queryKeys.callDetails.publicCall(callURLInfos.uuid, callURLInfos.tenantName)
    : queryKeys.callDetails.privateCall(callURLInfos.callId);
};

// Hack to handle the case where the timestamp is a string or a number
// It happens for node comment, the timestamp is a string when the comment is saved but a number during comment creation
export const adjustTimeStamp = (timestamp: string | number | undefined) => {
  switch (typeof timestamp) {
    case 'string': {
      return Date.parse(timestamp);
    }
    case 'number': {
      return timestamp;
    }
    default: {
      return null;
    }
  }
};

export const updateCallDetailsCache = (
  callId: number,
  updatedData: (data: PrivateCallDetails) => Partial<PrivateCallDetails>
): void => {
  queryClient.setQueryData<PrivateCallDetails>(queryKeys.callDetails.privateCall(callId), (currentData) => {
    if (!currentData) {
      return undefined;
    }

    const newCallDetails: PrivateCallDetails = {
      ...currentData,
      ...updatedData(currentData),
    };

    return newCallDetails;
  });
};

export const sortListeners = (listeners: ApiListener[]) => {
  return listeners.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
};

export const getTeamNamesByUserId = (userId: number | null, teams?: CallDetailsTeams[]) => {
  if (!userId || !teams) {
    return undefined;
  }
  return teams
    .reduce<string[]>((acc, team) => {
      if (team.userIds.includes(userId)) {
        acc.push(team.name);
      }
      return acc;
    }, [])
    .join(', ');
};

type SpeakerDetails = {
  name: string;
  jobTitle?: string;
};

export const getSpeakerDetails = (speaker: Speaker, contacts: Contact[], users: ApiUser[]): SpeakerDetails => {
  if (speaker.userId) {
    const user = users.find((u) => u.id === speaker.userId);
    if (!user?.name) {
      return { name: UNKNOWN_SPEAKER_LABEL };
    }
    return { name: user.name };
  }
  const contact = contacts.find((c) => c.id === speaker.contactId);
  if (!contact?.name) {
    return { name: UNKNOWN_SPEAKER_LABEL };
  }
  return { name: contact.name, jobTitle: contact?.jobTitle ?? undefined };
};
