import { matchPath, useLocation } from 'react-router-dom';

import { RoutePaths } from '@/enums';

/**
 * Returns true if the current location matches any of the given paths.
 * Insipired by useMatch from react-router-dom (https://reactrouter.com/en/main/hooks/use-match)
 */
export const useCurrentRoute = (pathsToCheck: RoutePaths | RoutePaths[], exact: boolean = false): boolean => {
  const location = useLocation();

  if (Array.isArray(pathsToCheck)) {
    return pathsToCheck.some((pathToCheck) => matchPath({ path: pathToCheck, end: exact }, location.pathname));
  }

  return !!matchPath({ path: pathsToCheck, end: exact }, location.pathname);
};
