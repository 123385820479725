import React, { ReactNode } from 'react';

import { AxiosResponse } from 'axios';
import { toast, ToastOptions, TypeOptions, Id } from 'react-toastify';

import { AlertVariant, Alert } from '@/components/common/toast/alert';
import { ToastMessage } from '@/components/common/toast/ToastMessage';
import { ResponseCode } from '@/enums';
import { HttpStatusCode } from '@/enums/http-status-code';
import AlertTriangleIcon from '@/static/icons/alerte/alert-triangle-Filled.svg?react';
import CrossLineIcon from '@/static/icons/basic/cross-Line.svg?react';
import { CustomToastOptions } from '@/types/toast';

import { SnackbarButtonProps } from './types';
import { ModjoProgress } from '../ModjoProgress';

const createAlert = (
  alertResponse: AxiosResponse,
  alertMessage: string | string[] | undefined,
  alertVariant: AlertVariant
): Alert => ({
  message:
    alertMessage ||
    (!ResponseCode[alertResponse.status] || alertResponse.status === HttpStatusCode.FORBIDDEN
      ? alertResponse.data.message
      : ResponseCode[alertResponse.status]) ||
    'Unknown error',
  variant: alertVariant,
});

const getToastTypeOption = (type?: AlertVariant): TypeOptions => {
  switch (type) {
    case AlertVariant.Success: {
      return AlertVariant.Success;
    }
    case AlertVariant.Info:
    case AlertVariant.Loading:
    case AlertVariant.Processing: {
      return AlertVariant.Info;
    }
    case AlertVariant.Warning: {
      return AlertVariant.Warning;
    }
    case AlertVariant.Down:
    case AlertVariant.Error: {
      return AlertVariant.Error;
    }
    default: {
      return AlertVariant.Info;
    }
  }
};

/** Returns snackbar options
 *
 * @param {AlertVariant} variant Type of toast (loading, info, warning...)
 * @param {boolean=} hasCustomButton Enables specific undo button type
 */
const getToastOptions = (variant: AlertVariant, customButtonProps?: SnackbarButtonProps): ToastOptions => {
  let customOptions: ToastOptions = { type: getToastTypeOption(variant) };
  switch (variant) {
    case AlertVariant.Down: {
      customOptions = {
        ...customOptions,
        className: '!bg-red-400 text-base',
        icon: <AlertTriangleIcon className="fill-orange-100" />,
        autoClose: false,
        closeOnClick: false,
      };
      break;
    }
    case AlertVariant.EmailDown: {
      customOptions = {
        ...customOptions,
        className: '!bg-grey-white text-base !text-grey-900 rounded-2xl mr-4 mt-4 overflow-visible',
        icon: <AlertTriangleIcon className="fill-orange-400" />,
        autoClose: false,
        closeButton: customButtonProps?.onClickClose && (
          <button
            onClick={customButtonProps.onClickClose}
            type="button"
            className="absolute -right-2 -top-2 z-10 flex size-7 cursor-pointer items-center justify-center rounded-full bg-grey-white p-1.5 shadow-card"
          >
            <CrossLineIcon />
          </button>
        ),
      };
      break;
    }
    case AlertVariant.Processing:
    case AlertVariant.Loading: {
      customOptions = {
        ...customOptions,
        icon: <ModjoProgress className="h-full" />,
        autoClose: variant === AlertVariant.Loading ? false : undefined,
        closeButton: false,
        closeOnClick: false,
      };
      break;
    }
    default: {
      break;
    }
  }
  if (customButtonProps) {
    customOptions = {
      ...customOptions,
      closeOnClick: !customButtonProps.onClickClose,
    };
  }
  return customOptions;
};

const clearToast = (id?: Id) => {
  toast.dismiss(id);
};

const createToast = (
  message: ReactNode,
  variant: AlertVariant | undefined = AlertVariant.Success,
  options?: Omit<ToastOptions, 'type'>
) => {
  return toast(message, { type: getToastTypeOption(variant), ...options });
};

const createCustomToast = ({ message, variant, buttonProps, toastOptions }: CustomToastOptions) => {
  return toast(
    ToastMessage({
      alert: { message, variant },
      buttonProps,
    }),
    {
      ...getToastOptions(variant, buttonProps),
      ...toastOptions,
    }
  );
};

export { createAlert, clearToast, createToast, createCustomToast };
