import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/common/ui-components/data-display/avatar';
import { MaybeInATooltip } from '@/components/common/ui-components/data-display/tooltip/MaybeInATooltip';
import { useCurrentUser, useIsFarmer } from '@/entities/users/users.hooks';
import MoreIcon from '@/static/icons/basic/more-small-hori-Filled.svg?react';
import { twThemeColors } from '@/theme/colors';
import { DataTestId } from '@/types/data-test-id';
import { getUserName } from '@/utils/user-utils';

import { sidebarStore } from '../sidebar.store';

const ProfileMenuButton = (isMenuOpen?: boolean) => {
  const currentUser = useCurrentUser();
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();
  const isFarmer = useIsFarmer();
  const { t } = useTranslation();

  if (!currentUser) {
    return null;
  }

  return (
    <MaybeInATooltip
      withTooltip={!isSidebarOpen}
      variant="small"
      contentClassName="capitalize"
      content={t('navbar.profile.button')}
      placement="right"
    >
      <button
        type="button"
        className="flex w-full items-center justify-between border-t border-t-grey-100 p-5 focus:bg-grey-200 focus:outline-none"
        data-test-id={DataTestId.SIDEBAR_PROFILE_MENU_BUTTON_ID}
      >
        <div className="flex items-center gap-x-2">
          <Avatar
            name={currentUser.name}
            src={currentUser.profilePictureURL}
            border={false}
            bgColor={isFarmer ? twThemeColors.orange.DEFAULT : undefined}
          />
          <p className={clsx('font-semibold', !isSidebarOpen && 'hidden')}>{getUserName(currentUser)}</p>
        </div>

        <div
          className={clsx(
            'flex items-center rounded p-1 hover:bg-grey-200',
            !isSidebarOpen && 'hidden',
            isMenuOpen && 'bg-grey-100'
          )}
        >
          <MoreIcon className="icon-small" />
        </div>
      </button>
    </MaybeInATooltip>
  );
};

ProfileMenuButton.displayName = 'ProfileMenuButton';

export { ProfileMenuButton };
