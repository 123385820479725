import React from 'react';

import { TranslationKeysWithoutNamespace } from '@/translation/types';
import { Provider, Providers, UserOAuthProvider } from '@/types/providers';
import { capitalizeFirstLetter } from '@/utils/utils';

type IconProvider = Provider;

const getProviderIconPath = (provider: IconProvider, useDarkVersion = false) => {
  switch (provider) {
    case Providers.MICROSOFT_USER_FLOW:
    case Providers.MICROSOFT_RECORDING_BOT: {
      return `/images/icons/providers/${
        useDarkVersion ? `${Providers.MICROSOFT_TENANT_FLOW}-dark` : Providers.MICROSOFT_TENANT_FLOW
      }.svg`;
    }
    case Providers.GOOGLE_MEET:
    case Providers.GOOGLE_USER_FLOW:
    case Providers.GOOGLE_RECORDING_BOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return `/images/icons/providers/${Providers.GOOGLE_MEET}${useDarkVersion ? '-dark' : ''}.svg`;
    }
    case Providers.GOOGLE: {
      return `/images/icons/providers/${Providers.GOOGLE}.svg`;
    }
    // eslint-disable-next-line i18next/no-literal-string
    case 'google-drive' as Provider: {
      return `/images/icons/providers/google-drive.svg`;
    }
    // eslint-disable-next-line i18next/no-literal-string
    case 'modjo_physical_recorder' as Provider: {
      return `/images/icons/providers/manual-dark.svg`;
    }
    default: {
      return `/images/icons/providers/${useDarkVersion ? `${provider}-dark` : provider}.svg`;
    }
  }
};

export const ProviderIcon = (
  provider: IconProvider | null,
  size = 32,
  useDarkVersion?: boolean,
  imgProps?: React.ComponentPropsWithoutRef<'img'>
) => {
  if (!provider) {
    return undefined;
  }
  return (
    <img
      src={getProviderIconPath(provider, useDarkVersion)}
      {...imgProps}
      alt={provider}
      key={provider}
      height={size}
      width={size}
    />
  );
};

export const ProviderSigninIcon = (provider: UserOAuthProvider) => {
  switch (provider) {
    case Providers.GOOGLE:
    case Providers.GOOGLE_USER_FLOW: {
      return <img src={getProviderIconPath(Providers.GOOGLE)} alt={`${Providers.GOOGLE_MEET} logo`} className="h-5" />;
    }
    case Providers.MICROSOFT_USER_FLOW: {
      return (
        <img
          src={getProviderIconPath(Providers.MICROSOFT_TENANT_FLOW)}
          alt={`${Providers.MICROSOFT_TENANT_FLOW} logo`}
          className="h-5"
        />
      );
    }
    default: {
      return ProviderIcon(provider, 32);
    }
  }
};

export const getProviderDisplayName = (provider: Provider) => {
  switch (provider) {
    case Providers.GOOGLE:
    case Providers.GOOGLE_USER_FLOW:
    case Providers.GOOGLE_RECORDING_BOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Google Meet';
    }
    case Providers.ZOOM_PHONE: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Zoom Phone';
    }
    case Providers.MICROSOFT_TENANT_FLOW:
    case Providers.MICROSOFT_USER_FLOW:
    case Providers.MICROSOFT_RECORDING_BOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Microsoft Teams';
    }
    case Providers.RING_CENTRAL: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Ring Central';
    }
    case Providers.AMAZON_SERVICE_CLOUD_VOICE: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Amazon Service Cloud Voice';
    }
    case Providers.DYNAMICS: {
      return `Dynamics`;
    }
    case Providers.HUBSPOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'HubSpot';
    }
    case Providers.OUTLOOK_EMAIL: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Outlook';
    }
    case Providers.TWILIO: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Twilio';
    }
    case Providers.DIABOLOCOM_V2: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'Diabolocom (v2)';
    }
    case Providers.MODJO_PHYSICAL_RECORDER: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'In-person meeting';
    }
    default: {
      return capitalizeFirstLetter(provider);
    }
  }
};

export const getCalendarProviderDisplayName = (provider: Provider): TranslationKeysWithoutNamespace<'settings'> => {
  switch (provider) {
    case Providers.GOOGLE:
    case Providers.GOOGLE_USER_FLOW:
    case Providers.GOOGLE_RECORDING_BOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'integrations.providerIntegrationPage.recordingBotIntegrationPanel.recordingBotUsersPermissionsTable.calendarTypeIcon.tooltip.google';
    }
    case Providers.MICROSOFT_TENANT_FLOW:
    case Providers.MICROSOFT_USER_FLOW:
    case Providers.MICROSOFT_RECORDING_BOT: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'integrations.providerIntegrationPage.recordingBotIntegrationPanel.recordingBotUsersPermissionsTable.calendarTypeIcon.tooltip.outlook';
    }
    default: {
      // eslint-disable-next-line i18next/no-literal-string
      return 'integrations.providerIntegrationPage.recordingBotIntegrationPanel.recordingBotUsersPermissionsTable.calendarTypeIcon.tooltip.other';
    }
  }
};
