import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ProgressPlaceholder } from '@/components/common/ProgressPlaceholder';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useUserSessionQuery } from '@/entities/user-session/user-session.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { RoutePaths, UserRole } from '@/enums';
import { authenticationStore } from '@/stores/authentication.store';
import { hasRole } from '@/utils/user-utils';

export const ProtectedRoute = () => {
  const isAuthenticated = authenticationStore.useIsAuthenticated();
  const userSession = useUserSessionQuery();
  const location = useLocation();

  const tenantQuery = useTenantQuery();
  const currentUser = useCurrentUser();

  if (isAuthenticated === null || userSession.isLoading) {
    return <ProgressPlaceholder />;
  }

  if (!isAuthenticated) {
    return <Navigate to={RoutePaths.LOGIN} state={{ from: location }} />;
  }

  /**
   * Redirect to Expired Free Trial page when conditions are met:
   * - Is not farmer (superadmin role).
   * - Trial end date exist and is higher then current date.
   */
  if (
    currentUser &&
    !hasRole(currentUser.role, UserRole.SUPERADMIN) &&
    tenantQuery.isSuccess &&
    tenantQuery.data.trialEndDate &&
    new Date(tenantQuery.data.trialEndDate).getTime() < Date.now() &&
    location.pathname !== RoutePaths.EXPIRED_FREE_TRIAL
  ) {
    return <Navigate to={RoutePaths.EXPIRED_FREE_TRIAL} replace />;
  }

  return <Outlet />;
};
