import React, { useCallback, useEffect, useRef } from 'react';

import {
  arrow as arrowMiddleware,
  flip,
  FloatingArrow,
  offset,
  shift,
  useClientPoint,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Portal } from '@headlessui/react';
import { useToggle } from 'react-use';

import { cn } from '@/utils/utils';

import { TooltipProps } from './types';
import './styles.css';

const ARROW_HEIGHT = 7;
const GAP = 2;

const CLASSES = {
  base: 'bg-blue-800 text-grey-white shadow-md',
  variant: {
    small: 'text-xs px-3 py-2',
    medium: 'text-sm px-4 py-2.5',
    large: 'text-base px-5 py-3',
    unstyled: '',
  },
};

const Tooltip = ({
  content,
  children,
  variant = 'small',
  placement = 'bottom',
  arrow,
  disabled,
  className,
  tooltipClasses,
  anchor,
  component,
  restoreFocus = true,
  delayHide = 0,
  offsetY = 0,
  offsetX = 4,
  disablePreventDefault,
  forceHide,
  followMouse = false,
  onShowTooltip,
  contentClassName,
}: TooltipProps) => {
  const [on, toggle] = useToggle(false);

  const timer = useRef<number>();
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    middleware: [
      offset({ mainAxis: offsetX + (arrow ? ARROW_HEIGHT + GAP : 0), crossAxis: offsetY }),
      flip(),
      shift({ padding: 5 }),
      arrowMiddleware({
        element: arrowRef,
      }),
    ],
    elements: {
      reference: anchor,
    },
  });
  const clientPoint = useClientPoint(context, {
    enabled: followMouse,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint]);

  const hideTooltip = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => toggle(false), delayHide);
  }, [delayHide, toggle]);

  const handleForceHide = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  useEffect(() => {
    if (forceHide) {
      handleForceHide();
    }
  }, [forceHide, handleForceHide]);

  const showTooltip = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => toggle(!disabled), delayHide);
    onShowTooltip?.();
  }, [delayHide, disabled, toggle, onShowTooltip]);

  // eslint-disable-next-line i18next/no-literal-string
  const ContentTooltipComponent = component ?? 'div';

  const portalContent = (
    <Portal>
      <div
        id="tooltip"
        role="tooltip"
        className={cn('relative whitespace-pre-wrap', tooltipClasses)}
        ref={refs.setFloating}
        style={{ ...floatingStyles, zIndex: 60 }}
        {...getFloatingProps()}
      >
        <ContentTooltipComponent
          {...(!component && {
            className: cn(
              'max-w-xs cursor-default rounded-md text-center xl:max-w-md',
              variant !== 'unstyled' && CLASSES.base,
              CLASSES.variant[variant],
              contentClassName
            ),
          })}
        >
          {content}
        </ContentTooltipComponent>
        {arrow && <FloatingArrow className="fill-grey-600" ref={arrowRef} context={context} />}
      </div>
    </Portal>
  );

  return (
    <>
      <div
        ref={refs.setReference}
        className={cn('focus:outline-none', className)}
        {...getReferenceProps({
          onPointerEnter: showTooltip,
          onPointerLeave: hideTooltip,
          onBlur: hideTooltip,
        })}
        {...(restoreFocus && { onFocus: showTooltip })}
        {...(disablePreventDefault && { onClick: (event) => event.preventDefault() })}
      >
        {children}
        {on && content && typeof content !== 'string' && portalContent}
      </div>
      {/** Render string outside of component to prevent to be able to keep it open when hovering it */}
      {on && content && typeof content === 'string' && portalContent}
    </>
  );
};

export { Tooltip };
