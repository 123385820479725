import { Notification } from '@/entities/notifications/notifications.types';

import {
  CallReviewNotification,
  CommentNotification,
  CrmFillingAnswersAnsweredNotification,
  LibraryItemNotification,
  NoteNotification,
  ReactionCommentNotification,
  ReactionNoteNotification,
  ReminderNotification,
} from './notifications-models';
import { UserRole } from '../../../enums';

export enum NotificationStatus {
  CREATED = 'created',
  // Could be set by the Lambda, but we have to be careful with connections.
  SENT = 'sent',
  SEEN = 'seen',
  READ = 'READ',
}

export enum ReactionNotificationType {
  /** Someone reacted to your comment */
  COMMENT_REACTION = 'comment.reaction',
  /** Someone reacted to your note */
  NOTE_REACTION = 'comment.note.reaction',
}

export enum DeprecatedCommentNotificationType {
  OLD = 'comment.old.notified',
  /** Someone liked your comment */
  LIKE = 'comment.like',
}

export enum CommentNotificationType {
  /** George comment your call */
  CALL = 'comment.call',
  /** Paul has replied to comment X where you have been mentioned */
  REPLY_MENTION = 'comment.reply.mention',
  /** Paul has replied to your comment X */
  REPLY_WRITER = 'comment.reply.writer',
  /** You have been mentioned by Y on call Z */
  MENTION = 'comment.mention',
  /** Someone replied to your note */
  NOTE_REPLY_WRITER = 'comment.note.reply',
  /** Someone has replied to note X where you have been mentioned */
  NOTE_BLOCK_REPLY_MENTION = 'comment.note.reply.mention',
  /** Someone has replied to note block, you also comment this note block  */
  NOTE_BLOCK_REPLY_THREAD = 'comment.note.block.reply',
}

export enum NoteNotificationType {
  NOTE_MENTION = 'note.mention',
}

export enum CallNotificationType {
  IMPORTED = 'call.imported',
  CRM_FILLING_ANSWERS_ANSWERED = 'call.crmFillingAnswers.answered',
}

enum SnippetNotificationType {
  /** When a snippet is ready for download */
  DOWNLOAD = 'snippet.download',
}

export enum CallReviewNotificationType {
  CALL_REVIEWED = 'call.reviewed',
}

export enum ReminderNotificationType {
  /** Reminder for watch later calls */
  WATCH_LATER = 'reminder.weekly.watchLater',
}

export enum LibraryItemNotificationType {
  /** You have been mentioned by Y on library item X */
  LIBRARY_ITEM_MENTION = 'library_item.mention',
}

enum AiInsightNotificationType {
  /** Your monthly ai insight generation */
  AI_INSIGHT_GENERATED = 'ai_insight.generated',
}

enum PublicCalltNotificationType {
  /** Your monthly ai insight generation */
  PUBLIC_CALL_VIEWED = 'public_call.viewed',
}

export const NotificationTypes = {
  ...DeprecatedCommentNotificationType,
  ...CommentNotificationType,
  ...ReactionNotificationType,
  ...CallNotificationType,
  ...SnippetNotificationType,
  ...NoteNotificationType,
  ...CallReviewNotificationType,
  ...ReminderNotificationType,
  ...LibraryItemNotificationType,
  ...AiInsightNotificationType,
  ...PublicCalltNotificationType,
} as const;
export type NotificationType = (typeof NotificationTypes)[keyof typeof NotificationTypes];

export const NotificationPreferencesTypes = {
  ...CommentNotificationType,
  ...ReactionNotificationType,
  ...CallNotificationType,
  ...SnippetNotificationType,
  ...NoteNotificationType,
  ...CallReviewNotificationType,
  ...ReminderNotificationType,
  ...AiInsightNotificationType,
  ...PublicCalltNotificationType,
} as const;
export type NotificationPreferencesType =
  (typeof NotificationPreferencesTypes)[keyof typeof NotificationPreferencesTypes];

export const isReactionNotification = (
  notification: Notification<NotificationType>
): notification is ReactionCommentNotification | ReactionNoteNotification =>
  Object.values(ReactionNotificationType).includes(notification.type as ReactionNotificationType);

export const isCommentNotification = (
  notification: Notification<NotificationType>
): notification is CommentNotification =>
  Object.values(CommentNotificationType).includes(notification.type as CommentNotificationType);

export const isNoteNotification = (
  notification: Notification<NotificationType>
): notification is NoteNotification<NoteNotificationType> =>
  Object.values(NoteNotificationType).includes(notification.type as NoteNotificationType);

export const isCallReviewNotification = (
  notification: Notification<NotificationType>
): notification is CallReviewNotification =>
  Object.values(CallReviewNotificationType).includes(notification.type as CallReviewNotificationType);

export const isReminderNotification = (
  notification: Notification<NotificationType>
): notification is ReminderNotification =>
  Object.values(ReminderNotificationType).includes(notification.type as ReminderNotificationType);

export const isLibraryItemNotification = (notification: Notification): notification is LibraryItemNotification =>
  Object.values(LibraryItemNotificationType).includes(notification.type as LibraryItemNotificationType);

export const isCrmFillingAnswersAnsweredNotification = (
  notification: Notification
): notification is CrmFillingAnswersAnsweredNotification =>
  Object.values(CallNotificationType).includes(notification.type as CallNotificationType);

export const getNotificationTypeMinimumRoleAccess = (notificationType: NotificationType): UserRole => {
  const managerTypes: NotificationType[] = Object.values(AiInsightNotificationType);
  return managerTypes.includes(notificationType) ? UserRole.MANAGER : UserRole.USER;
};

export const isAiAssistantNotification = (notificationType: NotificationType): boolean => {
  const aiAssistantNotifications: NotificationType[] = Object.values(AiInsightNotificationType);
  return aiAssistantNotifications.includes(notificationType);
};

export const isEmailNotificationOnly = (notificationType: NotificationType): boolean => {
  const emailOnlyTypes: NotificationType[] = Object.values(AiInsightNotificationType);
  emailOnlyTypes.push(PublicCalltNotificationType.PUBLIC_CALL_VIEWED);
  return emailOnlyTypes.includes(notificationType);
};
