import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { toTenantDisplayName } from '@/entities/tenant/tenant.selects';
import { useCallURLInfos } from '@/hooks/useCallURLInfos';
import { useIsPublic } from '@/hooks/useIsPublic';
import { CustomQueryOptions } from '@/types/common';
import { combineRoutes } from '@/utils/url-utils';

import {
  AiCallScoringVisibilityForUserRole,
  AiAssistant,
  CalendarEventReminder,
  ExpirationLink,
  RestrictCallAccess,
  Tenant,
} from './tenant.types';
import { queryKeys } from '../queryKeys';

export const TENANT_API_BASE_URL = '/tenants';
export const CALENDAR_EVENT_REMINDER_API_BASE_URL = `/calendar-event-reminders`;
export const EXPIRATION_LINK_API_BASE_URL = `/expiration-link`;
export const RESTRICT_CALL_ACCESS_API_BASE_URL = `/restrict-call-access`;
export const AI_CALL_SCORING_VISIBILITY_API_BASE_URL = `/ai-call-scoring-visibility`;

const useTenantQuery = <TSelectData = Tenant>(options?: CustomQueryOptions<Tenant, TSelectData>) => {
  return useQuery({
    queryKey: queryKeys.tenant.info(),
    queryFn: async () => {
      const { data } = await apiService.API.get<Tenant>(TENANT_API_BASE_URL, { params: { withBillingInfo: true } });
      return data;
    },
    ...options?.queryOptions,
  });
};

const useTenantDisplayNameQuery = () => useTenantQuery({ queryOptions: { select: toTenantDisplayName } });

const useTenantsAiAssistantQuery = () => {
  const isPublic = useIsPublic();
  return useQuery({
    queryKey: queryKeys.tenant.aiAssistant(),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiAssistant>(`${TENANT_API_BASE_URL}/ai-assistant`);
      return data;
    },
    enabled: !isPublic,
  });
};

const useTenantCalendarEventReminderQuery = () => {
  return useQuery({
    queryKey: queryKeys.tenant.calendarEventReminder(),
    queryFn: async () => {
      const { data } = await apiService.API.get<CalendarEventReminder>(
        combineRoutes([TENANT_API_BASE_URL, CALENDAR_EVENT_REMINDER_API_BASE_URL])
      );
      return data;
    },
  });
};

const useTenantRestrictCallAccessQuery = () => {
  return useQuery({
    queryKey: queryKeys.tenant.restrictCallAccess(),
    queryFn: async () => {
      const { data } = await apiService.API.get<RestrictCallAccess>(
        combineRoutes([TENANT_API_BASE_URL, RESTRICT_CALL_ACCESS_API_BASE_URL])
      );
      return data;
    },
  });
};

const useAiCallScoringVisibilityForUserRoleQuery = () => {
  return useQuery({
    queryKey: queryKeys.tenant.AiCallScoringVisibilityForUserRole(),
    queryFn: async () => {
      const { data } = await apiService.API.get<AiCallScoringVisibilityForUserRole>(
        combineRoutes([TENANT_API_BASE_URL, AI_CALL_SCORING_VISIBILITY_API_BASE_URL])
      );
      return data;
    },
  });
};

const useTenantDaysUntilLinkExpirationQuery = () => {
  const { isPublic } = useCallURLInfos();
  return useQuery({
    queryKey: queryKeys.tenant.daysUntilLinkExpiration(),
    queryFn: async () => {
      const { data } = await apiService.API.get<ExpirationLink>(
        combineRoutes([TENANT_API_BASE_URL, EXPIRATION_LINK_API_BASE_URL])
      );
      return data.daysUntilLinkExpiration;
    },
    enabled: !isPublic,
  });
};

export {
  useTenantQuery,
  useTenantsAiAssistantQuery,
  useTenantCalendarEventReminderQuery,
  useTenantDisplayNameQuery,
  useTenantRestrictCallAccessQuery,
  useTenantDaysUntilLinkExpirationQuery,
  useAiCallScoringVisibilityForUserRoleQuery,
};
