import { useMutation } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { combineRoutes } from '@/utils/url-utils';

import { USER_SESSIONS_API_BASE_URL } from './user-session.queries';
import { UserSessionMetadataDto } from './user-session.types';

// Do not put an onSuccess to invalidate query as it will break onboarding steps!
const useUpdateUserSessionMetadataMutation = () => {
  return useMutation({
    mutationFn: async (metadata: UserSessionMetadataDto) => {
      await apiService.API.put(combineRoutes([USER_SESSIONS_API_BASE_URL, 'me']), metadata);
    },
  });
};

export { useUpdateUserSessionMetadataMutation };
