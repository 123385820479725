import { captureException, captureMessage } from '@sentry/browser';
import { useTranslation } from 'react-i18next';

import { i18n } from '@/translation/i18n';

import { SelectOption } from '../components/common/ui-components/types';
import { DeeplLangSupported, LanguageCode, APP_LANGUAGES, AppLanguage } from '../enums/languages';

export const getMainLanguageCode = (languageCode: string): string => {
  if (!languageCode.includes('-')) {
    throw new Error(`languageCode:${languageCode} must be a valid BCP 47 language tag with a hyphen`);
  }
  return languageCode.split('-')[0].toUpperCase();
};

const getCountryCode = (languageCode: string): string => {
  const splittedLanguageCode = languageCode.split('-');
  return splittedLanguageCode.length > 1 ? splittedLanguageCode[1] : languageCode.toUpperCase();
};

/**
 * From a language's code, this function will return the related flag.
 */
export const getFlagEmoji = (languageCode: string) => {
  if (!languageCode) {
    return undefined;
  }

  // We want to display Standard Arabic instead of UAE
  if (languageCode === LanguageCode.arAE) {
    return '🇸🇦';
  }

  if (languageCode === LanguageCode.tlTLG) {
    return '🇵🇭';
  }

  const UTF_FLAG_REFERENCE = 127_397;
  const countryCode = getCountryCode(languageCode);
  const codePoints = [...countryCode].map((char) => UTF_FLAG_REFERENCE + (char.codePointAt(0) ?? 0));
  return String.fromCodePoint(...codePoints);
};

export const getShortLanguageLabel = (languageCode: string) => {
  if (languageCode === LanguageCode.arAE) {
    return `AR ${getFlagEmoji(LanguageCode.arAE) ?? ''}`;
  }
  return `${getCountryCode(languageCode)} ${getFlagEmoji(languageCode) ?? ''}`;
};

const getDefaultLangForPrefix = (prefix: string): LanguageCode => {
  switch (prefix.toUpperCase()) {
    case 'FR': {
      return LanguageCode.frFR;
    }
    case 'ES': {
      return LanguageCode.esES;
    }
    case 'DE': {
      return LanguageCode.deDE;
    }
    default: {
      const langNumberStartingWith = Object.values(LanguageCode).filter((lang) =>
        lang.startsWith(prefix.toLowerCase())
      );
      if (langNumberStartingWith.length > 1) {
        captureMessage(`Unhandled multiple languages suffixes found for ${prefix}.`);
      }
      return langNumberStartingWith[0];
    }
  }
};

/**
 * Get the native name of a language code.
 *
 * If the code is not supported by the Int API, we capture the exception.
 *
 * @param code {string} a BCP 47 language tag
 * @returns {string} The full language name in English
 *
 * @example getLanguageDisplayName('fr-FR') = French (France)
 */
export const getLanguageDisplayName = (code: string) => {
  // We want to display Standard Arabic instead of UAE
  if (code === LanguageCode.arAE) {
    return i18n.t('language.arae.title', {
      // hack for CI/CD to pass
      defaultValue: 'Standard Arabic',
    });
  }
  if (code === LanguageCode.tlTLG) {
    return i18n.t('language.tltlg.title', {
      // hack for CI/CD to pass
      defaultValue: 'Tagalog (Philippines)',
    });
  }

  try {
    const nativeMainLanguageDisplayName = new Intl.DisplayNames([LanguageCode.enUS], { type: 'language' }).of(
      getMainLanguageCode(code)
    );
    const nativeCountryDisplayName = new Intl.DisplayNames([LanguageCode.enUS], { type: 'region' }).of(
      getCountryCode(code)
    );

    if (!nativeMainLanguageDisplayName || !nativeCountryDisplayName) {
      return undefined;
    }

    return `${nativeMainLanguageDisplayName} (${nativeCountryDisplayName})`;
  } catch (error) {
    // We do not want to break the application but still track the invalid usa ge of to Intl
    captureException(error);
    return undefined;
  }
};

export const SUPPORTED_LANGUAGE_CODES = Object.values(LanguageCode);

const SUPPORTED_TRANSLATIONS = [
  LanguageCode.frFR,
  LanguageCode.enUS,
  LanguageCode.deDE,
  LanguageCode.esES,
  LanguageCode.itIT,
  LanguageCode.nlNL,
];

export const useSubtitleOffOption = () => {
  const { t } = useTranslation('common');
  return {
    id: 'off',
    label: t('off'),
  };
};

export enum LanguageOptionType {
  TRANSCRIPT = 'transcript',
  SUBTITLES = 'subtitles',
}

export function isSupportedByDeepl(lang: LanguageCode): boolean {
  return DeeplLangSupported.includes(lang.split('-')[0].toUpperCase());
}

export function getLanguageOptions(language: LanguageCode, optionType: LanguageOptionType, translatable: boolean) {
  if (!language) {
    return [{ id: undefined, label: i18n.t('language.none') } as unknown as SelectOption];
  }
  const originalTranslation = getDefaultLangForPrefix(getMainLanguageCode(language));
  const otherTranslations = translatable ? SUPPORTED_TRANSLATIONS.filter((lang) => lang !== originalTranslation) : [];
  const options = [originalTranslation, ...otherTranslations].reduce<SelectOption[]>(
    (languageOptions, languageCode) => {
      if (languageCode) {
        const displayName = getLanguageDisplayName(languageCode);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const languageDisplayName = displayName ? displayName.split('(')[0].trim() : '';
        const formattedDisplayName =
          languageCode === originalTranslation
            ? i18n.t('language.originalTitle', {
                displayName: languageDisplayName,
                ns: 'common',
              })
            : languageDisplayName;
        languageOptions.push({
          id: languageCode,
          label: optionType === LanguageOptionType.SUBTITLES ? formattedDisplayName : getMainLanguageCode(languageCode),
        });
      }
      return languageOptions;
    },
    []
  );
  return options;
}

const isSupportedLanguage = (language: LanguageCode): language is AppLanguage => {
  return APP_LANGUAGES.includes(language as AppLanguage);
};

export const getDefaultUserLanguage = (language?: LanguageCode): LanguageCode => {
  if (language && isSupportedLanguage(language)) {
    return language;
  }
  return isSupportedLanguage(navigator.language as LanguageCode)
    ? (navigator.language as LanguageCode)
    : LanguageCode.enUS;
};
