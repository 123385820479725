import React from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { NavbarTab, RoutePaths } from '@/enums';
import { useCurrentRoute } from '@/hooks/useCurrentRoute';
import HomeFilledIcon from '@/static/icons/basic/home-smile-Filled.svg?react';
import HomeLineIcon from '@/static/icons/basic/home-smile-Line.svg?react';
import { Event } from '@/tracking/events';

import { MobileNavbarButton } from './MobileNavbarButton';

export const MobileHomeButton = () => {
  const { t } = useTranslation();
  const isActivePage = useCurrentRoute(RoutePaths.HOME);

  return (
    <MobileNavbarButton
      variant="link"
      path={RoutePaths.HOME}
      onClick={() => mixpanel.track(Event.CLICK_ON_NAVBAR, { navbarTab: NavbarTab.HOME })}
      isActive={isActivePage}
    >
      <MobileNavbarButton.Icon lineIcon={HomeLineIcon} filledIcon={HomeFilledIcon} />
      <MobileNavbarButton.Label>{t('home')}</MobileNavbarButton.Label>
    </MobileNavbarButton>
  );
};
