import { i18n } from '@/translation/i18n';

import { SelectOption } from '../common/ui-components/types';

const MODJO_TAGS = 'Modjo';

export const MODJO_TAGS_OPTION: SelectOption = { id: MODJO_TAGS, label: MODJO_TAGS };

export const ALL_FILTERS_OPTION_ID = 'all-filters-option-id';
export const NO_FILTERS_OPTION_ID = 'no-filters-option-id';

export const ALL_TEAMS_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All teams' };
export const getTranslatedAllTeamsOption = (): SelectOption => ({
  id: ALL_FILTERS_OPTION_ID,
  label: i18n.t('allTeams'),
});
export const ALL_PROVIDERS_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All providers' };
export const getTranslatedAllProvidersOption = (): SelectOption => ({
  id: ALL_FILTERS_OPTION_ID,
  label: i18n.t('allProviders'),
});
export const ALL_TAGS_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All tags' };
export const getTranslatedAllTagsOption = (): SelectOption => ({
  id: ALL_FILTERS_OPTION_ID,
  label: i18n.t('allTags'),
});
export const ALL_LANGUAGES_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All languages' };
export const getTranslatedAllLanguagesOption = (): SelectOption => ({
  id: ALL_FILTERS_OPTION_ID,
  label: i18n.t('allLanguages'),
});
export const ALL_TEMPLATES_STATES_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All states' };
export const getTranslatedAllTemplatesOption = (): SelectOption => ({
  id: ALL_FILTERS_OPTION_ID,
  label: i18n.t('allStates'),
});
export const ALL_AUTHORS_OPTION: SelectOption = { id: ALL_FILTERS_OPTION_ID, label: 'All authors' };
