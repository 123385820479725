export const SettingsPages = {
  PROFILE: 'profile',
  NOTIFICATIONS: 'notifications',
  USERS: 'users',
  TEAMS: 'teams',
  DEALS: 'deals',
  DEAL_WARNINGS: 'deals/warnings',
  CALL_REVIEW: 'call-reviews/:id',
  CALL_REVIEWS: 'call-reviews',
  TAGS: 'tags',
  TOPICS: 'topics',
  CUSTOM_VOCABULARY: 'custom-vocabulary',
  PROVIDER_INTEGRATIONS: 'integrations/:provider',
  INTEGRATIONS: 'integrations',
  SUBSCRIPTION: 'subscription',
  ADVANCED: 'advanced',
  REMINDER_EMAIL: 'advanced/reminder-email',
  CALL_IMPORT_RESTRICTIONS: 'advanced/call-import-restrictions',
  AI_SCORING: 'ai-scoring',
  AI_SCORING_TEMPLATE: 'ai-scoring/template/:uuid',
  AI_ASSISTANT: 'ai-assistant',
  MODJO_EXTENSION: 'modjo-extension',
  LIVENOTE_TEMPLATES: 'livenotes/templates',
  SUMMARY_TEMPLATES: 'summary',
  SUMMARY_TEMPLATE: 'summary/template/:uuid',
  HIGHLIGHTS_TEMPLATES: 'highlights',
  HIGHLIGHTS_TEMPLATE: 'highlights/template/:uuid',
  CRM_FILLING_TEMPLATES: 'crm-filling/templates',
  CRM_FILLING_TEMPLATE: 'crm-filling/templates/:uuid',
  CRM_FILLING_PLAYGROUND: 'crm-filling/playground',
  EMAIL_FOLLOW_UP_TEMPLATES: 'email-follow-up/templates',
  EMAIL_FOLLOW_UP_TEMPLATE: 'email-follow-up/templates/:uuid',
  EMAIL_FOLLOW_UP_AI_ASSISTANT: 'email-follow-up/ai-assistant',
} as const;

export type SettingsPage = (typeof SettingsPages)[keyof typeof SettingsPages];
