import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { addDays, addMinutes } from 'date-fns';
import merge from 'lodash/merge';
import { matchPath } from 'react-router-dom';
import Cookies, { CookieSetOptions } from 'universal-cookie';

import { queryKeys } from '@/entities/queryKeys';
import { HttpStatusCode } from '@/enums/http-status-code';
import { AxiosErrorWithMessage } from '@/types/axios';
import { queryClient } from '@/utils/queryClient';
import { showResponseAlert } from '@/utils/utils';

import { apiURL } from './api.service';
import { JWTtokenResponse } from './entities/authentication/authentication.types';
import { RECORDING_BOTS_MEETING_API_URL } from './entities/recording-bots/recording-bots.mutations';
import { ApiUser } from './entities/users/users.types';
import { RoutePaths, UserRole } from './enums';
import { authenticationStore } from './stores/authentication.store';
import { connectWS, disconnectWS } from './utils/WebSocket/socket-singleton';

const ACCESS_TOKEN_EXPIRATION_MINUTES = 15;
const REFRESH_TOKEN_EXPIRATION_DAYS = 30;

const BROADCAST_CANAL_AUTH_NAME = 'auth';

export const BroadcastChannelMessageActions = {
  LOGOUT: 'logout',
  LOGIN: 'login',
} as const;

type BroadcastChannelMessageAction =
  (typeof BroadcastChannelMessageActions)[keyof typeof BroadcastChannelMessageActions];

type BroadcastChannelMessage = {
  action: BroadcastChannelMessageAction;
  payload?: unknown;
};

const ALLOWED_4XX_FAILING_ENDPOINTS = [
  // Call details can fail (404/403)
  RoutePaths.CALL_DETAILS,
  RECORDING_BOTS_MEETING_API_URL,
];

enum JwtTokens {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  EMPTY_TOKEN = '',
}

const cookies = new Cookies();

const getCookieRefreshToken = () => cookies.get<string>(JwtTokens.REFRESH_TOKEN) ?? JwtTokens.EMPTY_TOKEN;
export const hasRefreshToken = () => !!getCookieRefreshToken();

export class AuthenticateService {
  API: AxiosInstance;
  customFetch: typeof fetch;
  private _accessToken: string;
  private refreshToken: string;
  private cookies: Cookies;
  private refreshPromise?: Promise<boolean>;
  canal: BroadcastChannel;

  constructor() {
    this.cookies = cookies;
    this._accessToken = this.getCookieAccessToken();
    this.refreshToken = getCookieRefreshToken();
    this.API = this.customAxiosInstance();
    this.customFetch = this.customFetchInstance();
    connectWS(this._accessToken);
    this.canal = new BroadcastChannel(BROADCAST_CANAL_AUTH_NAME);
    this.canal.addEventListener('message', (event) => {
      const { action } = event.data as BroadcastChannelMessage;
      if (action === BroadcastChannelMessageActions.LOGOUT) {
        this.logout();
      }
      if (action === BroadcastChannelMessageActions.LOGIN) {
        this.setTokenPair(event.data.payload as JWTtokenResponse);
        queryClient.invalidateQueries({ queryKey: queryKeys.app });
      }
    });
  }

  get hasRefreshToken(): boolean {
    return Boolean(this.refreshToken);
  }

  get accessToken() {
    return this._accessToken;
  }

  static isTokenExpiredError = (errorResponse: AxiosResponse) => errorResponse.status === HttpStatusCode.UNAUTHORIZED;

  static isForbiddenError = (errorResponse: AxiosResponse) => errorResponse.status === HttpStatusCode.FORBIDDEN;

  static makeCookieOptions = () => {
    const opt: CookieSetOptions = {
      path: '/',
    };
    if (import.meta.env.VITE_NODE_ENV !== 'development') {
      opt.secure = true;
    }
    return opt;
  };

  static isValidationError = (errorResponse: AxiosResponse): boolean =>
    errorResponse.status === HttpStatusCode.BAD_REQUEST && errorResponse.data.message?.length > 0;

  static getValidationErrors = (errorResponse: AxiosResponse): string[] => errorResponse.data.message as string[];

  static makeValidationErrorMessage = (validationErrors: string[]) => {
    if (Array.isArray(validationErrors)) {
      return validationErrors;
    }
    return [validationErrors];
  };

  static requestPasswordReset(email: string) {
    return axios.post(`${apiURL}/auth/password/reset/request`, undefined, { params: { email } });
  }

  static validatePasswordReset(token: string) {
    return axios.post(`${apiURL}/auth/password/reset/validate`, undefined, { params: { token } });
  }

  setTokenPair({ accessToken, refreshToken }: JWTtokenResponse) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  fetchWithToken = async <T>(url: string, options: AxiosRequestConfig = {}): Promise<T> => {
    if (this.getCookieAccessToken() === JwtTokens.EMPTY_TOKEN) {
      await this.callRefreshToken();
    }

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${this.accessToken}`,
      accept: 'application/json',
    };

    return axios({
      ...options,
      url,
      headers,
    });
  };

  logout = () => {
    this._accessToken = JwtTokens.EMPTY_TOKEN;
    this.refreshToken = JwtTokens.EMPTY_TOKEN;
    this.setAuthorizationHeader(JwtTokens.EMPTY_TOKEN);
    this.cookies.remove(JwtTokens.ACCESS_TOKEN, { path: '/' });
    this.cookies.remove(JwtTokens.REFRESH_TOKEN, { path: '/' });
    authenticationStore.setIsAuthenticated(false);
    queryClient.removeQueries({ queryKey: queryKeys.app });
    disconnectWS();
    this.canal.postMessage({
      action: BroadcastChannelMessageActions.LOGOUT,
    });
  };

  private setTokenFromAxiosResponse(tokens: JWTtokenResponse) {
    if (!tokens?.accessToken || !tokens.refreshToken) {
      return;
    }

    this.setTokenPair(tokens);
  }

  private getCookieAccessToken = () => this.cookies.get<string>(JwtTokens.ACCESS_TOKEN) ?? JwtTokens.EMPTY_TOKEN;

  private setAccessToken = (accessToken?: string) => {
    if (!accessToken) {
      return;
    }
    this._accessToken = accessToken;
    this.setAuthorizationHeader(this._accessToken);
    this.cookies.set(JwtTokens.ACCESS_TOKEN, this._accessToken, {
      ...AuthenticateService.makeCookieOptions(),
      expires: addMinutes(new Date(), ACCESS_TOKEN_EXPIRATION_MINUTES),
    });
    connectWS(accessToken);
  };

  private setRefreshToken = (refreshToken: string) => {
    this.refreshToken = refreshToken;
    this.cookies.set(JwtTokens.REFRESH_TOKEN, this.refreshToken, {
      ...AuthenticateService.makeCookieOptions(),
      expires: addDays(new Date(), REFRESH_TOKEN_EXPIRATION_DAYS),
    });
  };

  private customAxiosInstance = () => {
    this.API = axios.create({
      baseURL: apiURL,
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
      },
    });
    // This interceptors ensures that the requests uses the proper access token in case when
    // there are multiple Modjo tabs opened in the browser, and one of them did refresh the token
    this.API.interceptors.request.use((request) => {
      const currentCookieToken = this.getCookieAccessToken();
      if (currentCookieToken !== this._accessToken) {
        this.setAccessToken(currentCookieToken);
        if (request.headers) {
          request.headers.Authorization = `Bearer ${this._accessToken}`;
        } else {
          request.headers = { Authorization: `Bearer ${this._accessToken}` } as AxiosRequestHeaders;
        }
      }
      return request;
    });
    this.API.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosErrorWithMessage) => {
        const errorResponse = error.response;
        let message: string | undefined | string[];
        if (errorResponse && AuthenticateService.isTokenExpiredError(errorResponse)) {
          return this.refreshTokenAndReattemptRequest(error);
        }
        if (errorResponse && AuthenticateService.isValidationError(errorResponse)) {
          const validationErrors = AuthenticateService.getValidationErrors(errorResponse);
          message = AuthenticateService.makeValidationErrorMessage(validationErrors);
        }
        const errorUrl = error.config?.url;
        if (errorUrl) {
          const isNotAmongAllowedFailingEndpoints = !ALLOWED_4XX_FAILING_ENDPOINTS.some((endpoint) =>
            matchPath(endpoint, errorUrl)
          );
          if (
            !(
              errorResponse?.status &&
              errorResponse.status >= HttpStatusCode.BAD_REQUEST &&
              errorResponse.status < HttpStatusCode.INTERNAL_SERVER_ERROR
            ) ||
            isNotAmongAllowedFailingEndpoints
          ) {
            showResponseAlert(errorResponse, message);
          }
        }
        return Promise.reject(error);
      }
    );
    return this.API;
  };

  private customFetchInstance() {
    return async (url: string | URL | globalThis.Request, options: RequestInit = {}) => {
      await this.refreshPromise;
      const optionsWithToken = merge({}, options, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
      const response = await fetch(url, optionsWithToken);
      if (response.status === HttpStatusCode.UNAUTHORIZED) {
        await this.callRefreshToken();
        optionsWithToken.headers.Authorization = `Bearer ${this.accessToken}`;
        return fetch(url, optionsWithToken);
      }
      return response;
    };
  }

  private setAuthorizationHeader = (accessToken: string) => {
    this.API.defaults.headers.Authorization = `Bearer ${accessToken}`;
  };

  private callRefreshToken = async () => {
    if (this.refreshToken) {
      this.refreshPromise = this.makeRefreshPromise();
      await this.refreshPromise;
      this.refreshPromise = undefined;
    }
  };

  private makeRefreshPromise = () =>
    axios
      .post<JWTtokenResponse>(`${apiURL}/auth/refresh`, {
        token: this.refreshToken,
      })
      .then(async ({ data, status }) => {
        this.setTokenFromAxiosResponse(data);
        // Temporary code to logout users that haven't set their job title.
        const { data: apiUser } = await this.API.get<ApiUser>(`${apiURL}/users/me`);
        if (apiUser.role !== UserRole.SUPERADMIN && (!apiUser.jobDepartment || !apiUser.jobTitle)) {
          this.logout();
          return false;
        }
        return status === HttpStatusCode.OK;
      })
      .catch((error: AxiosError) => {
        if (error.response && [HttpStatusCode.UNAUTHORIZED, HttpStatusCode.FORBIDDEN].includes(error.response.status)) {
          this.logout();
          return false;
        }
        throw error;
      });

  private refreshTokenAndReattemptRequest = async (error: AxiosError) => {
    try {
      const { response: errorResponse } = error;

      await (this.refreshPromise ?? this.callRefreshToken());

      if (!this._accessToken) {
        return await Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        /* Create new promise from the original request with updated authorization */
        if (errorResponse) {
          if (errorResponse.config.headers) {
            errorResponse.config.headers.Authorization = `Bearer ${this._accessToken}`;
          } else {
            errorResponse.config.headers = { Authorization: `Bearer ${this._accessToken}` } as AxiosRequestHeaders;
          }
          resolve(axios(errorResponse.config));
        }
      });

      return await retryOriginalRequest;
    } catch (error_) {
      // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
      return Promise.reject(error_);
    }
  };
}
