import React, { CSSProperties } from 'react';

import clsx from 'clsx';

import { cn, getRandomColor } from '@/utils/utils';

import { AvatarProps } from './types';

const variantSize = {
  small: 20,
  medium: 24,
  large: 36,
};

const Avatar = ({ name, bgColor, className, src, border = true, variant = 'medium', size }: AvatarProps) => {
  const firstLetter = name.charAt(0);
  const backgroundColor = bgColor || getRandomColor(name);
  const sizeToApply = size ?? variantSize[variant];
  const sizeStyle: CSSProperties = {
    height: sizeToApply,
    width: sizeToApply,
    minHeight: sizeToApply,
    minWidth: sizeToApply,
  };
  return (
    <div
      className={cn('flex-center rounded-full', border && 'ring-1 ring-grey-white', className)}
      style={{ ...sizeStyle, ...(src ? {} : { backgroundColor }) }}
    >
      {src && <img alt={name} src={src} className="rounded-full" style={sizeStyle} />}

      {!src && (
        <span
          className={clsx('flex-center font-extrabold uppercase text-grey-white', !size && 'text-sm')}
          style={{ ...sizeStyle, ...(size ? { fontSize: Math.ceil(size / 2) } : {}) }}
        >
          {firstLetter}
        </span>
      )}
    </div>
  );
};

export { Avatar };
