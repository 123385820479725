import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { useCallURLInfos } from '@/hooks/useCallURLInfos';
import { combineRoutes } from '@/utils/url-utils';

import { SNIPPET_API_BASE_URL, SNIPPET_PUBLIC_API_URL } from './snippets.constants';
import { ApiSnippet, PublicSnippet } from './snippets.types';
import { queryKeys } from '../queryKeys';

export const getPublicSnippetCallDetails =
  <T>(callUuid: string, snippetId: number, tenantName: string) =>
  async () => {
    const { data } = await apiService.API.get<T>(
      combineRoutes([SNIPPET_API_BASE_URL, SNIPPET_PUBLIC_API_URL, snippetId.toString()]),
      {
        params: { tenantName, callUuid },
      }
    );
    return data;
  };

export const usePublicSnippetQuery = (tenantName?: string, callUuid?: string, snippetId?: number) => {
  return useQuery<PublicSnippet>({
    queryKey: queryKeys.snippets.publicSnippet(tenantName!, callUuid!, snippetId!),
    queryFn: getPublicSnippetCallDetails<PublicSnippet>(callUuid!, snippetId!, tenantName!),
    enabled: !!callUuid && !!tenantName && !!snippetId,
  });
};

export const useGetSnippetQuery = () => {
  const callURLInfos = useCallURLInfos();
  return useQuery({
    queryKey: queryKeys.snippets.byId(callURLInfos.snippetId!),
    queryFn: async () => {
      const { data } = await apiService.API.get<ApiSnippet[]>(SNIPPET_API_BASE_URL, {
        params: { ids: [callURLInfos.snippetId] },
      });
      if (data.length > 0) {
        return data[0];
      }
      return null;
    },
    // Can't fetch snippet in public nor if no snippetId is given
    enabled: !callURLInfos.isPublicSnippet && !!callURLInfos.snippetId,
  });
};
