import { createStoreAndMethods } from '@/utils/zustand-utils';

type AuthenticationStore = {
  isAuthenticated: boolean | null;
};

const initialState: AuthenticationStore = {
  isAuthenticated: null,
};
const [, authenticationStore] = createStoreAndMethods<AuthenticationStore>(initialState);

export { authenticationStore };
