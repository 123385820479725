import React from 'react';

import { useTranslation } from 'react-i18next';

import ArrowDownChevronIcon from '@/static/icons/arrows/chevron-down-Line.svg?react';
import ArrowUpChevronIcon from '@/static/icons/arrows/chevron-up-Line.svg?react';

import { ShortcutKey } from '../ShortcutKey';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="flex select-none justify-between  border-t border-t-grey-100 fill-grey-600 px-3 py-2 text-sm text-grey-700">
      <span>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <ShortcutKey>Esc</ShortcutKey> {t('filters.search.espapeToCancel')}
      </span>

      <span className="flex-center gap-x-1">
        <ShortcutKey className="flex-center">
          <ArrowUpChevronIcon className="icon-small" />
        </ShortcutKey>
        {t('or')}
        <ShortcutKey className="flex-center">
          <ArrowDownChevronIcon className="icon-small" />
        </ShortcutKey>
      </span>
    </div>
  );
};

export { Footer };
