import React, { PropsWithChildren } from 'react';

import { Portal } from '@headlessui/react';

import { ConditionalWrapper } from '../../ConditionalWrapper';

type PortalOrFragmentProps = PropsWithChildren<{
  withPortal: boolean;
}>;

const MaybeInAPortal = ({ children, withPortal }: PortalOrFragmentProps) => (
  <ConditionalWrapper condition={withPortal} truthyWrapper={(wrappedChildren) => <Portal>{wrappedChildren}</Portal>}>
    {children}
  </ConditionalWrapper>
);

export { MaybeInAPortal };
