import React from 'react';

import { useTranslation } from 'react-i18next';
import { Drawer } from 'vaul';

import { ActionsFooter } from '@/components/common/ui-components/dialogs/Common';

import { meetingRecorderStore } from './meeting-recorder.store';

type MeetingRecorderImportDeleteMobileProps = {
  onConfirmDelete: () => void;
};

export const MeetingRecorderImportDeleteMobile = ({ onConfirmDelete }: MeetingRecorderImportDeleteMobileProps) => {
  const { t } = useTranslation();
  const isDeleting = meetingRecorderStore.useIsDeleting();

  return (
    <Drawer.Root
      nested
      onOpenChange={(open) => {
        if (!open) {
          meetingRecorderStore.setIsDeleting(false);
        }
      }}
      open={isDeleting}
      dismissible
      shouldScaleBackground
    >
      <Drawer.Portal>
        <Drawer.Content className="fixed inset-x-0 bottom-0 flex flex-col rounded-t-2lg bg-grey-white shadow-list-item-hovered">
          <div className="flex flex-col items-center gap-y-2 p-4">
            <Drawer.Title className="text-lg font-semibold">{t('meetingRecorder.recordingDelete.title')}</Drawer.Title>
            <p className="text-center text-sm">{t('meetingRecorder.recordingDelete.description')}</p>
            <ActionsFooter
              type="delete"
              buttonsPosition="center"
              onCancel={() => {
                meetingRecorderStore.setIsDeleting(false);
              }}
              onConfirm={onConfirmDelete}
            />
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
