import { AxiosError } from 'axios';

import { ObjectValues } from '@/types/common';

export type AddRecordingBotsToMeetingMutationParams = {
  url: string;
};

export const AddRecordingBotsToMeetingMutationErrors = {
  NOT_PART_OF_CALENDAR_EVENT: 'NOT_PART_OF_CALENDAR_EVENT',
  NOT_INTEGRATED_TO_RECORDING_BOT_PROVIDER: 'NOT_INTEGRATED_TO_RECORDING_BOT_PROVIDER',
  NO_LICENSE: 'NO_LICENSE',
  CALENDAR_EVENT_ALREADY_FINISHED: 'CALENDAR_EVENT_ALREADY_FINISHED',
  NOT_FOUND_USER: 'NOT_FOUND_USER',
  NOT_FOUND_TENANT: 'NOT_FOUND_TENANT',
  NOT_FOUND_MEETING_URL: 'NOT_FOUND_MEETING_URL',
} as const;

type AddRecordingBotsToMeetingMutationErrorCode = ObjectValues<typeof AddRecordingBotsToMeetingMutationErrors>;

export type AddRecordingBotsToMeetingMutationError = AxiosError<{
  error: {
    code: AddRecordingBotsToMeetingMutationErrorCode;
    reason: string;
  };
}>;

export type AddRecordingBotsToMeetingMutationResponse = {
  name?: string;
};
