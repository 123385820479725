import { ReactNode, PropsWithChildren } from 'react';

type ConditionalWrapperProps = PropsWithChildren<{
  condition: boolean;
  truthyWrapper: (children: ReactNode) => JSX.Element;
  falsyWrapper?: (children: ReactNode) => JSX.Element;
}>;

export const ConditionalWrapper = ({ children, condition, truthyWrapper, falsyWrapper }: ConditionalWrapperProps) =>
  condition ? truthyWrapper(children) : falsyWrapper ? falsyWrapper(children) : (children as JSX.Element);
