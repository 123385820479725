import React, { Fragment, PropsWithChildren } from 'react';

import { cn, isEmpty } from '@/utils/utils';

import { Position } from '../types';

type BadgeOrigin = {
  vertical?: Position.TOP | Position.BOTTOM;
  horizontal?: Position.START | Position.END;
};

type BadgeProps = PropsWithChildren<{
  label: string | number;
  className?: string;
  invisible?: boolean;
  anchorOrigin?: BadgeOrigin;
}>;

export const Badge = ({
  children,
  label,
  className,
  invisible = false,
  anchorOrigin = { horizontal: Position.END, vertical: Position.TOP },
}: BadgeProps) => {
  const isValidChildren = React.isValidElement(children);
  // eslint-disable-next-line i18next/no-literal-string
  const Container = isValidChildren ? 'div' : Fragment;
  const containerProps = isValidChildren ? { className: 'relative flex-center' } : {};
  const isXAnchorOriginStart = anchorOrigin.horizontal === Position.START;
  const isYAnchorOriginTop = anchorOrigin.vertical === Position.TOP;
  const showBadge = !invisible && (isEmpty(label) || label !== 0);
  return (
    <Container {...containerProps}>
      {children}
      {showBadge && (
        <div
          className={cn(
            'flex-center rounded-lg px-1 text-center font-semibold',
            isValidChildren ? 'absolute h-3.5 min-w-[12px]' : 'py-2',
            isValidChildren && isXAnchorOriginStart ? 'left-0' : 'right-0',
            isValidChildren && isYAnchorOriginTop ? 'top-0' : 'bottom-0',
            className
          )}
          style={{
            ...(isValidChildren && {
              transform: `translate(${isXAnchorOriginStart ? '-' : ''}50%, ${isYAnchorOriginTop ? '-' : ''}50%)`,
            }),
          }}
        >
          <span>{label}</span>
        </div>
      )}
    </Container>
  );
};
