import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { queryKeys } from '@/entities/queryKeys';
import { combineRoutes } from '@/utils/url-utils';

import { View, VIEWS_API_BASE_URL } from './views.types';

type CreateViewParams = Pick<View, 'label' | 'type' | 'filters' | 'sorting' | 'viewConfig'>;

const useCreateViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ type, ...view }: CreateViewParams) => {
      const viewType = type === 'explore' ? 'calls' : type;
      const { data } = await apiService.API.post<View>(combineRoutes([VIEWS_API_BASE_URL, viewType]), view);
      return data;
    },
    onSuccess: (data, { type }) => {
      queryClient.setQueryData<View[]>(queryKeys.views.all, (currentData) => {
        if (!currentData) {
          return undefined;
        }
        return [{ ...data, type }, ...currentData];
      });
    },
  });
};

type UpdateViewMutationParams = Pick<View, 'id' | 'type'> &
  Pick<Partial<View>, 'label' | 'filters' | 'sorting' | 'viewConfig'>;

const useUpdateViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id: viewId, type, ...rest }: UpdateViewMutationParams) => {
      const viewType = type === 'explore' ? 'calls' : type;
      const { data } = await apiService.API.put<View>(
        combineRoutes([VIEWS_API_BASE_URL, viewType, viewId.toString()]),
        rest
      );
      return data;
    },
    onSuccess: (data, { type }) => {
      queryClient.setQueryData<View[]>(queryKeys.views.all, (currentData) => {
        if (!currentData) {
          return undefined;
        }
        return currentData.map((view) => (view.id === data.id ? { ...view, ...data, type } : view));
      });
    },
  });
};

const useDeleteViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (viewId: View['id']) => {
      await apiService.API.delete(combineRoutes([VIEWS_API_BASE_URL, viewId.toString()]));
    },
    onSuccess: (_, viewId) => {
      queryClient.setQueryData<View[]>(queryKeys.views.all, (currentData) => {
        if (!currentData) {
          return undefined;
        }
        return currentData.filter(({ id }) => viewId !== id);
      });
    },
  });
};

export { useCreateViewMutation, useUpdateViewMutation, useDeleteViewMutation };
