import React, { PropsWithChildren } from 'react';

import { cn } from '@/utils/utils';

import { ModjoProgress } from './ModjoProgress';

export type ProgressPlaceholderProps = PropsWithChildren<{ className?: string }>;

export const ProgressPlaceholder = ({ className, children }: ProgressPlaceholderProps) => (
  <div className={cn('flex-center h-full w-full bg-grey-white', className)}>
    {children || null}
    <ModjoProgress />
  </div>
);
