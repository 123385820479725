import React from 'react';

import { MaybeInATooltip } from '@/components/common/ui-components/data-display/tooltip/MaybeInATooltip';
import { SidebarButton } from '@/components/common/ui-components/layout/SidebarButton';
import { DataTestIdProps } from '@/types/common';

import { sidebarStore } from '../../sidebar.store';

type SidebarNavigationMenuButtonProps = {
  label: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isMenuOpen: boolean;
} & DataTestIdProps;

const SidebarNavigationMenuButton = ({ label, Icon, isMenuOpen, dataTestId }: SidebarNavigationMenuButtonProps) => {
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();

  return (
    <MaybeInATooltip withTooltip={!isSidebarOpen} variant="small" content={label} placement="right">
      <SidebarButton variant="menu" isMenuOpen={isMenuOpen} isSidebarOpen={isSidebarOpen} dataTestId={dataTestId}>
        <SidebarButton.Icon lineIcon={Icon} />
        <SidebarButton.Label>{label}</SidebarButton.Label>
      </SidebarButton>
    </MaybeInATooltip>
  );
};

SidebarNavigationMenuButton.displayName = 'SidebarNavigationMenuButton';

export { SidebarNavigationMenuButton };
