import { PeriodAndRangeDateFilter } from './date-period-filter.types';

export const DatePeriods = {
  ONE_DAY: 'one_day',
  ONE_WEEK: 'one_week',
  ONE_MONTH: 'one_month',
  THREE_MONTHS: 'three_months',
  ONE_YEAR: 'one_year',
  ALL_TIME: 'all_time',
  NEXT_WEEK: 'next_week',
  NEXT_MONTH: 'next_month',
  NEXT_QUARTER: 'next_quarter',
  NEXT_YEAR: 'next_year',
  CUSTOM: 'custom',
} as const;

type DatePeriod = (typeof DatePeriods)[keyof typeof DatePeriods];

export const DatePeriodTranslationKeys = {
  [DatePeriods.ONE_DAY]: 'periods.oneDay',
  [DatePeriods.ONE_WEEK]: 'periods.oneWeek',
  [DatePeriods.ONE_MONTH]: 'periods.oneMonth',
  [DatePeriods.THREE_MONTHS]: 'periods.threeMonths',
  [DatePeriods.ONE_YEAR]: 'periods.oneYear',
  [DatePeriods.ALL_TIME]: 'periods.allTime',
  [DatePeriods.NEXT_WEEK]: 'periods.nextWeek',
  [DatePeriods.NEXT_MONTH]: 'periods.nextMonth',
  [DatePeriods.NEXT_QUARTER]: 'periods.nextQuarter',
  [DatePeriods.NEXT_YEAR]: 'periods.nextYear',
  [DatePeriods.CUSTOM]: 'periods.custom',
} as const satisfies Record<DatePeriod, string>;

export const datePeriodsWithoutCustom = [
  DatePeriods.ONE_DAY,
  DatePeriods.ONE_WEEK,
  DatePeriods.ONE_MONTH,
  DatePeriods.THREE_MONTHS,
  DatePeriods.ONE_YEAR,
  DatePeriods.ALL_TIME,
];

export const defaultPeriodAndRangeDateFilter: PeriodAndRangeDateFilter = {
  period: DatePeriods.ONE_MONTH,
  startDate: undefined,
  endDate: new Date(),
};
