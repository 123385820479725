/* More about this approach here:
   https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories * */

import { NotesStore } from '@/components/notes/notes.store';
import { TranscriptionSegmentFormat } from '@/enums';
import { CrmEntityType } from '@/enums/crm-fields';
import { LanguageCode } from '@/enums/languages';
import { RecordingBotProvider } from '@/types/providers';

import { ActivitySearchDTO } from './activities/activity.types';
import { AnalyticsCallFilterDto } from './analytics/analytics.types';
import { CallThumbnailDto, SearchFiltersDTO } from './calls/calls.types';
import { ApiDealSearchParams } from './deals/deals.types';
import { CallFilterDto } from '../types/call-filters';

// eslint-disable-next-line i18next/no-literal-string
const app = ['modjo-app'];

const chapters = {
  all: [...app, 'chapters'] as const,
  callId: (id: number) => [...chapters.all, id] as const,
};

const contacts = {
  all: [...app, 'contacts'] as const,
  byContactId: (contactId: number) => [...contacts.all, contactId] as const,
};

const contactsJobTitles = {
  all: [...app, 'contactsJobTitles'] as const,
  byQuery: (query: string) => [...contactsJobTitles.all, query] as const,
};

const callHistory = {
  all: [...app, 'callHistory'] as const,
  byContactIds: (contactIds: number[]) => [...callHistory.all, ...contactIds] as const,
  byAccountId: (accountId: number) => [...callHistory.all, accountId] as const,
};

const publicChapters = {
  all: [...app, 'publicChapters'] as const,
  tenantNameAndUuid: (tenantName: string, uuid: string) => [...publicChapters.all, tenantName, uuid] as const,
};

const summaryTemplate = {
  all: [...app, 'summaryTemplate'] as const,
  list: () => [...summaryTemplate.all, 'list'] as const,
  categories: [...app, 'categories'] as const,
  byUuid: (uuid?: string) => [...summaryTemplate.all, uuid] as const,
};

const highlightsTemplate = {
  all: [...app, 'highlightsTemplate'] as const,
  list: () => [...highlightsTemplate.all, 'list'] as const,
  categories: [...app, 'categories'] as const,
  byUuid: (uuid?: string) => [...highlightsTemplate.all, uuid] as const,
};

const aiTopics = {
  all: [...app, 'aiTopics'] as const,
};

const callDetails = {
  all: [...app, 'callDetails'] as const,
  privateCall: (id: number) => [...callDetails.all, id] as const,
  publicCall: (uuid: string, tenantName: string) => [...callDetails.all, tenantName, uuid] as const,
};

const calls = {
  all: [...app, 'calls'] as const,
  allList: () => [...calls.all, 'list'] as const,
  list: (dto?: SearchFiltersDTO, limit?: number) => [...calls.allList(), dto, limit] as const,
  allThumnail: () => [...calls.all, 'thumbnail'] as const,
  thumbnail: (dto?: CallThumbnailDto) => [...calls.allThumnail(), dto] as const,
  search: (query?: string) => [...calls.all, 'search', query] as const,
  durationRange: () => [...calls.all, 'durationRange'] as const,
  aiCallScoring: (callId: number) => [...calls.all, 'aiCallScoring', callId] as const,
  aiCallScoringByTemplate: (templateUuid: string, callId: number) => [...calls.all, callId, templateUuid] as const,
  tenantCallProviders: () => [...calls.all, 'tenantCallProviders'] as const,
  crmInfo: (callId: number) => [...calls.all, 'crmInfos', callId] as const,
};

const comments = {
  all: [...app, 'comments'] as const,
  block: (blockUuid: string) => [...comments.all, blockUuid] as const,
};

const crm = {
  all: [...app, 'crm'] as const,
  properties: (entityTypes: CrmEntityType[]) => [...crm.all, entityTypes] as const,
};

const livenotes = {
  latestVersion: [...app, 'livenotes', 'latestVersion'] as const,
};

const teams = {
  all: [...app, 'teams'] as const,
  teamId: (teamId: number) => [...teams.all, teamId] as const,
  list: () => [...teams.all, 'list'] as const,
  infinite: () => [...teams.all, 'infiniteTeams'] as const,
  filters: (filters?: object) => [teams.infinite, { filters }] as const,
};

const tenant = {
  all: [...app, 'tenant'] as const,
  aiAssistant: () => [...tenant.all, 'aiAssistant'] as const,
  daysUntilLinkExpiration: () => [...tenant.all, 'daysUntilLinkExpiration'] as const,
  calendarEventReminder: () => [...tenant.all, 'calendarEventReminder'] as const,
  restrictCallAccess: () => [...tenant.all, 'restrictCallAccess'] as const,
  AiCallScoringVisibilityForUserRole: () => [...tenant.all, 'AiCallScoringVisibilityForUserRole'] as const,
  info: () => [...tenant.all, 'info'] as const,
};

const users = {
  all: [...app, 'users'] as const,
  me: () => [...users.all, 'me'] as const,
  farmer: () => [...users.all, 'farmer'] as const,
  list: () => [...users.all, 'list'] as const,
  id: (id: number) => [...users.all, id] as const,
  infinite: () => [...users.all, 'infiniteUsers'] as const,
  filters: (filters?: object) => [users.infinite, { filters }] as const,
  preferences: () => [...users.all, 'preferences'] as const,
  uploadTemplate: () => [...users.all, 'uploadTemplate'] as const,
  export: () => [...users.all, 'export'] as const,
};

const userSession = {
  all: [...app, 'userSession'] as const,
};

const providers = {
  all: [...app, 'providers'] as const,
  featuresForMe: () => [...providers.all, 'features', 'me'] as const,
  featuresForUsers: (provider: string) => [...providers.all, 'features', 'users', provider] as const,
  users: () => [...providers.all, 'users'] as const,
  usersByProvider: (provider: string) => [...providers.users(), provider] as const,
  authorizationUrlByProvider: (provider: string) => [...providers.all, 'authorizationUrl', provider] as const,
  userSsoAuthorizationUrlByProvider: (provider: string) =>
    [...providers.all, 'userSsoAuthorizationUrl', provider] as const,
  scopesAuthorizationUrlByProvider: (provider: string) =>
    [...providers.all, 'scopesAuthorizationUrl', provider] as const,
  notesOAuth: () => [...providers.all, 'notesOAuth'] as const,
};

const providerCredentials = {
  all: [...app, 'providerCredentials'] as const,
  provider: (providerName: string) => [...providerCredentials.all, providerName] as const,
};

const providerBotScheduleOption = {
  all: [...app, 'providerBotScheduleOption'] as const,
  provider: (providerName: RecordingBotProvider) => [...providerBotScheduleOption.all, providerName] as const,
};

const notes = {
  all: [...app, 'notes'] as const,
  me: (filter: Pick<NotesStore, 'isAssociatedFilter' | 'startDate' | 'endDate'>) =>
    [...notes.all, 'me', { filter }] as const,
  presignUrlToDownloadApp: () => [...notes.all, 'presignedUrl'] as const,
  config: () => [...notes.all, 'config'] as const,
};

const notesTemplates = {
  all: [...app, 'notesTemplates'] as const,
  list: () => [...notesTemplates.all, 'list'] as const,
};

const notesCalls = {
  all: [...app, 'notesCalls'] as const,
  callId: (callId: number) => [...notesCalls.all, callId] as const,
  associations: () => [...notesCalls.all, 'associations'] as const,
  associationCallId: (callId: number) => [...notesCalls.associations(), callId] as const,
  associationsSearchAll: () => [...notesCalls.associations(), 'search'] as const,
  associationsSearch: (searchTerm: string) => [...notesCalls.associationsSearchAll(), searchTerm] as const,
};

const publicNotesCalls = {
  all: [...app, 'publicNotesCalls'] as const,
  tenantNameAndUuid: (tenantName: string, uuid: string) => [...publicNotesCalls.all, tenantName, uuid] as const,
};

const highlights = {
  all: [...app, 'highlights'] as const,
  byCallIdAndLanguage: (callId: number, language?: LanguageCode) => [...highlights.all, callId, language] as const,
};

const publicHighlights = {
  all: [...app, 'publicHighlights'] as const,
  byTenantNameAndUuidAndLanguage: (tenantName: string, uuid: string, language?: LanguageCode) =>
    [...publicHighlights.all, tenantName, uuid, language] as const,
};

const summary = {
  all: [...app, 'summary'] as const,
  byCallIdAndLanguage: (callId: number, language?: LanguageCode) => [...summary.all, callId, language] as const,
};

const publicSummary = {
  all: [...app, 'publicSummary'] as const,
  byTenantNameAndUuidAndLanguage: (tenantName: string, uuid: string, language?: LanguageCode) =>
    [...publicSummary.all, tenantName, uuid, language] as const,
};

const timeline = {
  all: [...app, 'timeline'] as const,
  callId: (id?: number, dealId?: number) => [...timeline.all, id, dealId] as const,
};

const tags = {
  all: [...app, 'tags'] as const,
};

const domainNames = {
  domainNames: [...app, 'domainNames'] as const,
  excluded: [...app, 'domainNamesExcluded'] as const,
};

const transcriptionSegments = {
  all: [...app, 'transcriptionSegments'] as const,
  callId: (callId: number) => [...transcriptionSegments.all, callId] as const,
  callIdAndLanguageAndFormat: (
    callId: number,
    language?: LanguageCode,
    format: TranscriptionSegmentFormat = TranscriptionSegmentFormat.JSON
  ) => [...transcriptionSegments.all, callId, language, format] as const,
};

const publicTranscriptionSegments = {
  all: [...app, 'publicTranscriptionSegments'] as const,
  tenantNameAndUuid: (tenantName: string, uuid: string) =>
    [...publicTranscriptionSegments.all, tenantName, uuid] as const,
  tenantNameAndUuidAndLanguageAndFormat: (
    tenantName: string,
    uuid: string,
    language?: LanguageCode,
    format: TranscriptionSegmentFormat = TranscriptionSegmentFormat.JSON
  ) => [...publicTranscriptionSegments.all, tenantName, uuid, language, format] as const,
};

const transcriptionBlocks = {
  all: [...app, 'transcriptionBlocks'] as const,
  callId: (callId: number) => [...transcriptionBlocks.all, callId] as const,
  callIdAndLanguage: (callId: number, language?: LanguageCode) =>
    [...transcriptionBlocks.all, callId, language] as const,
};

const publicTranscriptionBlocks = {
  all: [...app, 'publicTranscriptionBlocks'] as const,
  tenantNameAndUuidAndLanguage: (tenantName: string, uuid: string, language?: LanguageCode) =>
    [...publicTranscriptionBlocks.all, tenantName, uuid, language] as const,
};

const views = {
  all: [...app, 'views'] as const,
};

const notifications = {
  all: [...app, 'notifications'] as const,
};

const notificationsPreferences = {
  all: [...app, 'notificationsPreferences'] as const,
};

const callReviewTemplates = {
  all: [...app, 'callReviewTemplates'] as const,
  list: () => [...callReviewTemplates.all, 'list'] as const,
  byId: (id: number) => [...callReviewTemplates.all, id] as const,
};

const callReview = {
  all: [...app, 'callReview'] as const,
  byCallId: (callId?: number) => [...callReview.all, 'callId', callId] as const,
  callList: (dto: CallFilterDto) => [...callReview.all, 'callList', dto] as const,
};

const callReviewDraft = {
  all: [...app, 'callReviewDraft'] as const,
  byCallId: (callId?: number) => [...callReviewTemplates.all, callId] as const,
};

const topics = {
  all: [...app, 'topics'] as const,
};

const languages = {
  all: [...app, 'languages'] as const,
};

const billing = {
  all: [...app, 'billing'] as const,
  plans: () => [...billing.all, 'plans'] as const,
  settings: () => [...billing.all, 'settings'] as const,
  licensesCount: () => [...billing.all, 'licensesCount'] as const,
  tenantSubscription: () => [...billing.all, 'tenantSubscription'] as const,
  portalSessionUrl: () => [...billing.all, 'portalSessionUrl'] as const,
};

const emailBlocks = {
  all: [...app, 'emailBlocks'] as const,
};

const deal = {
  all: [...app, 'deal'] as const,
  lossReason: () => [...deal.all, 'lossReason'] as const,
  stages: () => [...deal.all, 'stages'] as const,
  types: () => [...deal.all, 'types'] as const,
  statuses: () => [...deal.all, 'statuses'] as const,
  contacts: (dealId: number) => [...deal.all, 'contacts', dealId] as const,
  info: (id?: number) => [...deal.all, 'info', id] as const,
  crmInfo: (options?: { crm: string; crmId: string }) =>
    [...deal.all, 'crmInfo', options?.crm, options?.crmId] as const,
  nextMeeting: (id?: number) => [...deal.all, 'nextMeeting', id] as const,
  summary: (dealId: number, language?: string) => [...deal.all, 'summary', dealId, language] as const,
  email: () => [...deal.all, 'email'] as const,
  aiTopics: (dealId: number) => [...deal.all, 'aiTopics', dealId] as const,
  warnings: (dealId: number) => [...deal.all, 'warnings', dealId] as const,
};

const dealWarning = {
  all: [...app, 'dealWarning'] as const,
  list: () => [...dealWarning.all, 'list'] as const,
  warningSetupRecomputationStatus: () => [...dealWarning.all, 'warningSetupRecomputationStatus'] as const,
};

const lead = {
  all: [...app, 'lead'] as const,
  statuses: () => [...lead.all, 'statuses'] as const,
  sources: () => [...lead.all, 'sources'] as const,
};

const dictionaryTerm = {
  all: [...app, 'dictionaryTerm'] as const,
};

const aiScoringTemplates = {
  all: [...app, 'aiScoringTemplates'] as const,
  list: () => [...aiScoringTemplates.all, 'list'] as const,
  byUuid: (uuid: string) => [...aiScoringTemplates.all, uuid] as const,
  questionHistory: (questionUuid: string) => [...aiScoringTemplates.all, 'questionHistory', questionUuid] as const,
};

const library = {
  all: [...app, 'library'] as const,
  itemsByLibraryId: (id: number) => [...library.all, 'libraryItems', id] as const,
};

const analytics = {
  all: [...app, 'analytics'] as const,
  aiScoring: () => [...analytics.all, 'aiScoring'] as const,
  aiScoringFiltered: (filters: CallFilterDto) => [...analytics.aiScoring(), filters] as const,
  reviews: () => [...analytics.all, 'reviews'] as const,
  reviewsFiltered: (filters: CallFilterDto) => [...analytics.reviews(), filters] as const,
  callList: (filters?: AnalyticsCallFilterDto) => [...analytics.all, 'call-list', filters] as const,
  activities: (filters?: CallFilterDto) => [...analytics.all, 'activities', filters] as const,
  topics: (filters?: CallFilterDto) => [...analytics.all, 'topics', filters] as const,
  topicsEvolution: (filters?: CallFilterDto) => [...analytics.all, 'topics-evolution', filters] as const,
  words: (filters?: CallFilterDto) => [...analytics.all, 'words', filters] as const,
  wordsEvolution: (filters?: CallFilterDto) => [...analytics.all, 'words-evolution', filters] as const,
  metrics: (filters?: CallFilterDto) => [...analytics.all, 'metrics', filters] as const,
  countMetrics: (filters?: CallFilterDto) => [...analytics.all, 'count-evolution', filters] as const,
  tags: (filters?: CallFilterDto) => [...analytics.all, 'tags', filters] as const,
};

const snippets = {
  all: [...app, 'snippets'] as const,
  byId: (id: number) => [...snippets.all, id] as const,
  publicSnippet: (tenantName: string, callUuid: string, snippetId: number) =>
    [...snippets.all, 'publicSnippet', tenantName, callUuid, snippetId] as const,
  download: (id: number) => [...snippets.all, 'download', id] as const,
};

const contact = {
  all: [...app, 'contact'] as const,
  list: (ids: number[]) => [...contact.all, 'list', ids] as const,
  search: (query: string) => [...contact.all, 'list', query] as const,
};

const account = {
  all: [...app, 'account'] as const,
  list: (ids: number[]) => [...account.all, 'list', ids] as const,
  search: (query: string) => [...account.all, 'search', query] as const,
};

const watchLater = {
  all: [...app, 'watchLater'] as const,
};

const activities = {
  all: [...app, 'activities'] as const,
  byId: (activityUuid: string) => [...activities.all, activityUuid] as const,
  allSearch: () => [...activities.all, 'search'] as const,
  search: (limit: number, filters?: ActivitySearchDTO) => [...activities.allSearch(), filters, limit] as const,
  timeline: (filters?: ActivitySearchDTO) => [...activities.all, 'timeline', filters] as const,
  attendees: (dealId: number) => [...activities.all, 'attendees', dealId] as const,
  potentialDeals: (activityUuid: string) => [...activities.byId(activityUuid), 'potential-deals'],
  newDealCrmLink: (activityUuid: string) => [...activities.byId(activityUuid), 'new-deal-crm-link'],
};

const deals = {
  all: [...app, 'deals'] as const,
  list: (params: ApiDealSearchParams) => [...deals.all, 'list', params] as const,
  overviewStatus: (params: Pick<ApiDealSearchParams, 'filter'>) => [...deal.all, 'overviewStatus', params] as const,
  contactRoles: () => [...deal.all, 'contactRoles'] as const,
  dealCrmfieldsAvailable: () => [...deal.all, 'dealCrmfieldsAvailable'] as const,
  crmEntityFields: (entityType?: string) => [...deal.all, 'crmEntityFields', entityType] as const,
  askAnythingStats: (dealId: number) => [...deals.all, 'askAnythingStats', dealId] as const,
  updateCRMFields: () => [...deals.all, 'updateCRMFields'] as const,
  overviewForecastCategories: (params: Pick<ApiDealSearchParams, 'filter'>) =>
    [...deal.all, 'overviewForecastCategories', params] as const,
};

const calendarEvents = {
  all: [...app, 'calendarEvents'] as const,
  byDate: (date: string) => [...calendarEvents.all, date] as const,
};

const calendarEventDetails = {
  all: [...app, 'calendarEventDetails'] as const,
  byId: (id: number) => [...calendarEventDetails.all, id] as const,
};

const callImportRestrictions = {
  all: [...app, 'callImportRestrictions'] as const,
};

const crmFilling = {
  all: [...app, 'crmFilling'] as const,
  entities: {
    allEntities: [...app, 'crmFilling', 'entities'] as const,
    types: () => [...crmFilling.entities.allEntities, 'types'] as const,
    allProperties: () => [...crmFilling.entities.allEntities, 'properties', 'all'] as const,
  },
  templates: {
    allTemplates: [...app, 'crmFilling', 'templates'] as const,
    list: () => [...crmFilling.templates.allTemplates, 'list'] as const,
    byUuid: (uuid: string) => [...crmFilling.templates.allTemplates, uuid] as const,
  },
  answers: {
    allAnswers: [...app, 'crmFilling', 'answers'] as const,
    byCallId: (callId: number) => [...crmFilling.answers.allAnswers, callId] as const,
  },
  playground: {
    all: [...app, 'crmFilling', 'playground'] as const,
    infinite: () => [...crmFilling.playground.all, 'infinite'] as const,
    byUuids: (uuids: string[]) => [...crmFilling.playground.all, uuids.join(',')] as const,
  },
};

const emailFollowUp = {
  all: [...app, 'emailFollowUp'] as const,
  templates: {
    allTemplates: [...app, 'emailFollowUp', 'templates'] as const,
    list: () => [...emailFollowUp.templates.allTemplates, 'list'] as const,
    byUuid: (uuid: string) => [...emailFollowUp.templates.allTemplates, uuid] as const,
  },
  callData: {
    allCallData: [...app, 'emailFollowUp', 'callData'] as const,
    byCallId: (callId: number) => [...emailFollowUp.callData.allCallData, callId] as const,
  },
};

const storage = {
  all: [...app, 'storage'] as const,
  recorderTimestamp: () => [...storage.all, 'recorderTimestamp'] as const,
  recorderFile: () => [...storage.all, 'recorderFile'] as const,
};

export const queryKeys = {
  app,
  summaryTemplate,
  highlightsTemplate,
  aiTopics,
  chapters,
  contacts,
  contactsJobTitles,
  callHistory,
  callDetails,
  domainNames,
  publicChapters,
  calls,
  comments,
  crm,
  livenotes,
  providers,
  providerCredentials,
  providerBotScheduleOption,
  teams,
  storage,
  tenant,
  users,
  userSession,
  notes,
  notesCalls,
  highlights,
  publicHighlights,
  summary,
  publicSummary,
  publicNotesCalls,
  notesTemplates,
  timeline,
  tags,
  views,
  notifications,
  notificationsPreferences,
  transcriptionSegments,
  publicTranscriptionSegments,
  transcriptionBlocks,
  publicTranscriptionBlocks,
  callReview,
  callReviewTemplates,
  callReviewDraft,
  topics,
  languages,
  billing,
  emailBlocks,
  deal,
  dealWarning,
  deals,
  dictionaryTerm,
  aiScoringTemplates,
  library,
  analytics,
  snippets,
  contact,
  account,
  watchLater,
  lead,
  activities,
  calendarEvents,
  calendarEventDetails,
  callImportRestrictions,
  crmFilling,
  emailFollowUp,
};
