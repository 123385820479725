import React, { useState } from 'react';

import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from '@/utils/utils';

import { CallTitleOption, enterIcon, FiltersCategories, filtersCategoriesTranslationKeys, itemToJSX } from './Common';
import {
  ListMenuContainer,
  ListMenuItemContainer,
  SearchOptionsInput,
  SearchOptionsInputContainer,
  SearchOptionsInputDescription,
} from './CommonStyles';
import { NoResultSearch } from './NoResultSearch';
import { SearchableObject } from './search-components.types';
import { useShortcutsContext } from '../../MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import {
  MIN_LENTH_FOR_SEARCH,
  useCallTitlesApiSearcher,
} from '../../MainSidebar/Search/SearchDialog/SearchContent/search-hook';
import { ModjoProgress } from '../ModjoProgress';
import { EllipsedText } from '../ui-components/data-display/EllipsedText';
import { UIRadio } from '../ui-components/inputs/radio';

type SingleSelectWithApiCallTitleSearchProps = {
  selectedFilterType: FiltersCategories;
  setSelectedItems: (values: SearchableObject[]) => void;
};

export const SingleSelectWithApiCallTitleSearch = ({
  selectedFilterType,
  setSelectedItems,
}: SingleSelectWithApiCallTitleSearchProps) => {
  const { t } = useTranslation('common');

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedCall, setSelectedCall] = useState<CallTitleOption | null>(null);

  const callTitleOptions = useCallTitlesApiSearcher(inputValue);

  const isSearching = callTitleOptions?.isFetching;

  const { handleTriggers } = useShortcutsContext();

  return (
    <Combobox
      value={selectedCall}
      onChange={(value) => {
        if (value) {
          setSelectedCall(value);
          setSelectedItems([value]);
        }
      }}
      immediate
    >
      <SearchOptionsInputContainer>
        <ComboboxInput
          as={SearchOptionsInput}
          displayValue={() => inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleTriggers}
          placeholder={`${t('filters.search.noInput')} ${t(filtersCategoriesTranslationKeys[selectedFilterType], { count: 1 })} ...`}
          autoFocus
        />
        {inputValue.length < MIN_LENTH_FOR_SEARCH && (
          <SearchOptionsInputDescription>{t('filters.search.3charMin')}</SearchOptionsInputDescription>
        )}
      </SearchOptionsInputContainer>
      <ComboboxOptions as={ListMenuContainer} static>
        {/* Loader while searching */}
        {isSearching && (
          <div className="flex-center w-full">
            <ModjoProgress size={24} />
          </div>
        )}
        {/* No result found */}
        {isEmpty(callTitleOptions?.data) && !isSearching && inputValue.length > MIN_LENTH_FOR_SEARCH && (
          <NoResultSearch hasValues={false} inputValue={inputValue} />
        )}
        {/* Filtered results options */}
        {!isEmpty(callTitleOptions.data) &&
          callTitleOptions.data!.map((field) => (
            <ComboboxOption
              key={field.id.toString()}
              as={ListMenuItemContainer}
              className="data-[active]:cursor-pointer data-[active]:bg-grey-100 data-[disabled]:opacity-50"
              value={field}
            >
              {({ focus }) => (
                <>
                  <UIRadio id={String(field.id)} checked={selectedCall?.id === field.id} className="cursor-pointer" />
                  <EllipsedText as="div" className="flex w-full cursor-pointer items-center">
                    {itemToJSX(field, { inputValue, showDate: true })}
                    {enterIcon(focus)}
                  </EllipsedText>
                </>
              )}
            </ComboboxOption>
          ))}
      </ComboboxOptions>
    </Combobox>
  );
};
