import { DatePeriod } from '@/components/common/DatePeriodFilter/date-period-filter.types';
import { UrlFilters } from '@/components/explore/useUrlFilters';
import { CallFilterDto } from '@/types/call-filters';
import { TransformOptionalType } from '@/utils/type-utils';

export const VIEWS_API_BASE_URL = '/views';

type ApiCallFilters = TransformOptionalType<CallFilterDto, Date, string>;

export type ViewType = 'explore' | 'deals';

export type DealsFilters = {
  teamIds?: number[];
  userIds?: number[];
  warningUuids?: string[];
  objectionUuids?: string[];
  closeDatePeriod?: DatePeriod;
  stages?: string[];
  types?: string[];
};

export type DealsSorting = {
  sortField: string;
  sortOrder: 'asc' | 'desc';
};

export type ViewConfig = {
  columnOrder?: string[];
};

export type View = {
  id: number;
  createdOn: Date;
  label: string;
  filters?: Partial<UrlFilters | DealsFilters>;
  sorting?: DealsSorting;
  viewConfig?: ViewConfig;
  userId?: number;
  type: ViewType;
};

export type ApiView = Omit<View, 'createdOn' | 'filters' | 'type'> & {
  createdOn: string;
  filters: ApiCallFilters;
  type: 'calls' | 'deals';
};
