import React, { forwardRef, Fragment } from 'react';

import clsx from 'clsx';

import { cn } from '@/utils/utils';

import { SimpleInputProps } from './types';
import { getButtonOrInputStyles, getLabelStyles } from '../../styles';

const SimpleInput = forwardRef<HTMLInputElement, SimpleInputProps>(
  (
    {
      label,
      size = 'large',
      name,
      isError,
      disabled,
      fullWidth = true,
      autoComplete = 'off',
      className,
      dataTestId,
      ...otherProps
    },
    reference
  ) => {
    const ContainerWrapper = label ? 'div' : Fragment;

    const isLarge = size === 'large';

    return (
      <ContainerWrapper {...(label && { className: clsx('flex flex-col', fullWidth && 'w-full') })}>
        {label && (
          <label className={getLabelStyles(isLarge, disabled)} htmlFor={name}>
            {label}
          </label>
        )}
        <input
          data-test-id={dataTestId}
          {...otherProps}
          id={name}
          ref={reference}
          disabled={disabled}
          autoComplete={autoComplete}
          className={cn(
            getButtonOrInputStyles({
              isLarge,
              isError,
              disabled,
              hasValues: Boolean(otherProps?.value),
              hasEndIcons: false,
              fullWidth,
            }),
            className
          )}
        />
      </ContainerWrapper>
    );
  }
);

export { SimpleInput };
