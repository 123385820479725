import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';

import { queryKeys } from '@/entities/queryKeys';
import { Event } from '@/tracking/events';

import { MeetingRecorderStatuses } from './meeting-recorder.constants';
import { meetingRecorderStore } from './meeting-recorder.store';

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('fileStorage', 1);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('files')) {
        db.createObjectStore('files', { keyPath: 'fileName' });
      }
    };

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.addEventListener('error', (event) => {
      reject((event.target as IDBOpenDBRequest).error);
    });
  });
}

async function saveFileToStorage(fileName: string, file: File): Promise<void> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');
    const request = store.put({ fileName, file, timestamp: Date.now() });

    request.onsuccess = () => {
      resolve();
    };

    request.addEventListener('error', (event) => {
      reject((event.target as IDBRequest).error);
    });
  });
}

function writeString(view: DataView, offset: number, string: string) {
  let i = 0;
  while (i < string.length) {
    const codePoint = string.codePointAt(i);
    if (codePoint !== undefined) {
      view.setUint8(offset + i, codePoint);
    }
    i += 1;
  }
}

function encodeWAV(audioBuffer: AudioBuffer): Blob {
  const numChannels = audioBuffer.numberOfChannels;
  const { sampleRate } = audioBuffer;
  const format = 1; // PCM
  const bitDepth = 16; // 16-bit PCM

  const numSamples = audioBuffer.length * numChannels;
  const dataSize = numSamples * (bitDepth / 8);
  const buffer = new ArrayBuffer(44 + dataSize);
  const view = new DataView(buffer);

  // Write WAV header
  writeString(view, 0, 'RIFF'); // ChunkID
  view.setUint32(4, 36 + dataSize, true); // ChunkSize
  writeString(view, 8, 'WAVE'); // Format
  writeString(view, 12, 'fmt '); // Subchunk1ID
  view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
  view.setUint16(20, format, true); // AudioFormat (PCM = 1)
  view.setUint16(22, numChannels, true); // NumChannels
  view.setUint32(24, sampleRate, true); // SampleRate
  view.setUint32(28, sampleRate * numChannels * (bitDepth / 8), true); // ByteRate
  view.setUint16(32, numChannels * (bitDepth / 8), true); // BlockAlign
  view.setUint16(34, bitDepth, true); // BitsPerSample
  writeString(view, 36, 'data'); // Subchunk2ID
  view.setUint32(40, dataSize, true); // Subchunk2Size

  // Write PCM data
  const offset = 44;
  let channel = 0;
  while (channel < numChannels) {
    const channelData = audioBuffer.getChannelData(channel);
    let i = 0;
    while (i < audioBuffer.length) {
      const sample = channelData[i] * (32_767.5 - 0.5); // Convert from [-1,1] to [0,65535] range
      view.setInt16(
        offset + i * 2 + channel * audioBuffer.length * 2,
        Math.max(-32_768, Math.min(32_767, sample)),
        true
      );
      i += 1;
    }
    channel += 1;
  }

  return new Blob([view], { type: 'audio/wav' });
}

async function webmToWav(webmBlob: Blob): Promise<Blob> {
  // Decode the WebM audio data
  const audioContext = new AudioContext();
  const arrayBuffer = await webmBlob.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Encode the decoded audio data into WAV format
  const wavBlob = encodeWAV(audioBuffer);
  return wavBlob;
}

/**
 * Calculates the duration of a WAV file based on its size.
 *
 * @param fileSize - Size of the WAV file in bytes.
 * @param numberOfChannels - Number of audio channels (1 for mono, 2 for stereo).
 * @param sampleRate - Sample rate of the WAV file in Hz (e.g., 44100 Hz).
 * @returns Duration of the WAV file in seconds.
 */
export function calculateWavDuration(fileSize: number): number {
  // WAV header size (commonly 44 bytes for PCM WAV files)
  const headerSize = 44;

  // Adjust file size by removing header size
  const dataSize = fileSize - headerSize;

  // Convert bit depth from bits to bytes
  const bytesPerSample = 16 / 8;

  // Calculate the size of the WAV file per second
  const sizePerSecond = bytesPerSample * 2 * 16_000;

  // Calculate and return the duration in seconds
  return dataSize / sizePerSecond;
}

const getAudioFile = (blob: Blob, filename: string): File => {
  return new File([blob], filename, { type: 'audio/wav' });
};

const updateMeetingRecorderStore = (fileName: string, audioUrl: string) => {
  meetingRecorderStore.setRecordingAudioUrl(audioUrl);
  meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.COMPLETED);
  meetingRecorderStore.setCurrentFileName(fileName);
};

const saveAudioFileFallback = (fileName: string, audioBlob: Blob) => {
  const audioUrl = URL.createObjectURL(audioBlob);
  updateMeetingRecorderStore(fileName, audioUrl);
  saveFileToStorage(fileName, getAudioFile(audioBlob, fileName));
};

export const saveRecording = async (blob: Blob, filename: string) => {
  const blobWav = await webmToWav(blob);
  mixpanel.track(Event.SAVE_RECORDING);

  try {
    if (navigator.storage?.getDirectory) {
      // Use the File System Access API for Chromium-based browsers
      const directoryHandle = await navigator.storage.getDirectory();
      const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(blobWav);
      await writableStream.close();

      // Create an object URL for the saved file
      const audioUrl = URL.createObjectURL(blobWav);
      updateMeetingRecorderStore(filename, audioUrl);
      saveFileToStorage(filename, getAudioFile(blobWav, filename));
    } else {
      Sentry.captureException('File System Access API is not supported in this browser.');
      // Fallback for browsers that do not support the File System Access API (like Safari)
      saveAudioFileFallback(filename, blobWav);
    }
  } catch {
    saveAudioFileFallback(filename, blob);
    saveFileToStorage(filename, getAudioFile(blobWav, filename));
  }
};

export const removeRecording = async (filename: string) => {
  const opfsRoot = await navigator.storage.getDirectory();
  await opfsRoot.removeEntry(filename);
};

export async function getFirstFileFromStorage(): Promise<File | null> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction('files', 'readonly');
    const store = transaction.objectStore('files');
    const request = store.openCursor();

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue | null>).result;
      if (cursor) {
        // If cursor is found, return the file
        resolve(cursor.value.file as File);
      } else {
        // No files found
        resolve(null);
      }
    };

    request.addEventListener('error', (event) => {
      reject((event.target as IDBRequest).error);
    });
  });
}

async function getFirstFileTimestampFromStorage(): Promise<number | null> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction('files', 'readonly');
    const store = transaction.objectStore('files');
    const request = store.openCursor();

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue | null>).result;
      if (cursor) {
        // If cursor is found, return the timestamp
        resolve(cursor.value.timestamp as number);
      } else {
        // No files found
        resolve(null);
      }
    };

    request.addEventListener('error', (event) => {
      reject((event.target as IDBRequest).error);
    });
  });
}

export function requestMicrophoneAccess() {
  if (navigator.mediaDevices?.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        mixpanel.track(Event.MICROPHONE_PERMISSION_ACCEPTED);
        meetingRecorderStore.setHasAccessMicrophone(true);
      })
      .catch(() => {
        mixpanel.track(Event.MICROPHONE_PERMISSION_REFUSED);
        meetingRecorderStore.setHasAccessMicrophone(false);
      });
  } else {
    meetingRecorderStore.setHasAccessMicrophone(false);
  }
}

export async function clearAudiosFromStorage(): Promise<void> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');
    const request = store.clear();

    request.onsuccess = () => {
      resolve();
    };

    request.addEventListener('error', (event) => {
      reject((event.target as IDBRequest).error);
    });
  });
}

export const useMeetingRecorderTimestampRequest = () => {
  return useQuery({
    queryKey: queryKeys.storage.recorderTimestamp(),
    queryFn: async () => {
      const data = await getFirstFileTimestampFromStorage();

      return data;
    },
  });
};

export const useMeetingRecorderFileRequest = () => {
  return useQuery({
    queryKey: queryKeys.storage.recorderFile(),
    queryFn: async () => {
      const data = await getFirstFileFromStorage();

      return data;
    },
  });
};
