import React from 'react';

import clsx from 'clsx';

import { ProviderIcon } from '@/components/providers/icons/ProvidersIcon';

import { Alert, AlertVariant } from './alert';
import { SnackbarButtonProps } from './types';
import { ButtonFilled } from '../ui-components/buttons/UIButtonFilled';
import { ButtonOutlined } from '../ui-components/buttons/UIButtonOutlined';

type SnackbarProps = {
  alert?: Alert;
  buttonProps?: SnackbarButtonProps;
};

const ToastMessage = ({ alert, buttonProps }: SnackbarProps) => {
  if (!alert) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-5">
      {!Array.isArray(alert.message) && alert.message}
      {Array.isArray(alert.message) && (
        <ul>
          {alert.message.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      )}
      {alert?.variant === AlertVariant.EmailDown && buttonProps?.providerName ? (
        <ButtonOutlined
          color="info"
          size="small"
          icon={ProviderIcon(buttonProps.providerName, 24, false, { className: clsx('aspect-square') })}
          onClick={buttonProps.onClick}
          className={clsx('group', buttonProps && 'mr-2')}
          id={buttonProps.id}
        >
          {buttonProps.label}
        </ButtonOutlined>
      ) : (
        buttonProps && (
          <ButtonFilled
            color="info"
            size="small"
            onClick={buttonProps.onClick}
            className={clsx('group', buttonProps && 'mr-2')}
            id={buttonProps.id}
          >
            {buttonProps.label}
          </ButtonFilled>
        )
      )}
    </div>
  );
};

export { ToastMessage };
