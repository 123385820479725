import { InfiniteData, QueryClient } from '@tanstack/react-query';

import { ApiListedCall } from '@/entities/calls/calls.types';
import { PaginationRequestResult } from '@/types/paginations';

import { queryKeys } from '../queryKeys';

type UpdateCallItemInCallSearchCacheParams = {
  queryClient: QueryClient;
  callMapper?: (call: ApiListedCall) => ApiListedCall;
  callFilter?: (call: ApiListedCall) => boolean;
};

/**
 * Updates the call item in the call search cache without potentially refetching several pages of calls.
 */
export const updateCallItemInCallSearchCache = ({
  queryClient,
  callMapper,
  callFilter,
}: UpdateCallItemInCallSearchCacheParams) => {
  // getQueriesData returns all the call search queries made with queryKeys.calls.allList() as root.
  // The return type is an array of [QueryKey, QueryData].
  const lastCallQueries = queryClient.getQueriesData<InfiniteData<PaginationRequestResult<ApiListedCall>>>({
    queryKey: queryKeys.calls.allList(),
  });
  if (lastCallQueries.length > 0) {
    // The last call query is the last query made

    for (const lastCallQuery of lastCallQueries) {
      if (lastCallQuery) {
        const lastCallQueryKey = lastCallQuery[0];
        const oldData = structuredClone(
          queryClient.getQueryData<InfiniteData<PaginationRequestResult<ApiListedCall>>>(lastCallQueryKey)
        );
        if (oldData) {
          queryClient.setQueryData<InfiniteData<PaginationRequestResult<ApiListedCall>>>(lastCallQueryKey, {
            pageParams: oldData.pageParams,
            pages: oldData.pages.map((page) => ({
              ...page,
              values: page.values.reduce<ApiListedCall[]>((acc, element) => {
                // If we don't have a callFilter function then we just map the element
                // If we have a callFilter function then we only map the element if the callFilter returns true
                if (!callFilter || callFilter(element)) {
                  const newElement = callMapper ? callMapper(element) : element;
                  acc.push(newElement);
                }
                return acc;
              }, []),
            })),
          });
        }
      }
    }
  }
};
