import { SelectOption } from '@/components/common/ui-components/types';
import { createStoreAndMethods } from '@/utils/zustand-utils';

import { MeetingRecorderContentStates, MeetingRecorderStatuses } from './meeting-recorder.constants';
import { MeetingRecorderContentState, MeetingRecorderStatus } from './meeting-recorder.types';
import { clearAudiosFromStorage } from './meeting-recorder.utils';

type MeetingRecorderStore = {
  hasAccessMicrophone: boolean | null;
  recordingStatus: MeetingRecorderStatus;
  mediaRecorderInstance: MediaRecorder | null;
  isDeleting: boolean;
  currentFileName: string | null;
  meetingRecorderContentState: MeetingRecorderContentState;
  recordingAudioUrl: string | null;
  fileDuration: number | null;
  recordingTimer: number | null;
  contactIds: number[] | null;
  clientIds: SelectOption[] | null;
};

const initialState: MeetingRecorderStore = {
  hasAccessMicrophone: null,
  mediaRecorderInstance: null,
  recordingStatus: MeetingRecorderStatuses.READY,
  isDeleting: false,
  currentFileName: null,
  meetingRecorderContentState: MeetingRecorderContentStates.RECORDER,
  recordingAudioUrl: null,
  fileDuration: null,
  recordingTimer: null,
  contactIds: null,
  clientIds: null,
};

const [, store] = createStoreAndMethods(initialState);

const meetingRecorderStore = {
  ...store,
  reset: () => {
    meetingRecorderStore.setIsDeleting(false);
    meetingRecorderStore.setCurrentFileName(null);
    meetingRecorderStore.setRecordingStatus(MeetingRecorderStatuses.READY);
    meetingRecorderStore.setMediaRecorderInstance(null);
    meetingRecorderStore.setFileDuration(null);
    meetingRecorderStore.setMeetingRecorderContentState(MeetingRecorderContentStates.RECORDER);
    clearAudiosFromStorage();
  },
};

export { meetingRecorderStore };
