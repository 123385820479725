import React from 'react';

import { Popover } from '@headlessui/react';
import { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { InfiniteScrollContainer } from '@/components/common/ui-components/InfiniteScrollContainer';
import { useNotificationsQuery } from '@/entities/notifications/notifications.queries';
import NotificationsIcon from '@/static/icons/alerte/bell-Filled.svg?react';
import { isEmpty } from '@/utils/utils';

import { NotificationItem } from './NotificationItem';

const HEIGHT = `calc(100vh - 150px)`;
const FIXED_HEIGHT = {
  height: HEIGHT,
  maxHeight: HEIGHT,
};

const scrollbarOptions: OverlayScrollbarsComponentProps['options'] = {
  scrollbars: { visibility: 'hidden' },
};

const NotificationPopoverContent = () => {
  const { t } = useTranslation();
  const notificationsQuery = useNotificationsQuery();

  const notifications = notificationsQuery.isSuccess
    ? notificationsQuery.data.pages.flatMap(({ values }) => values)
    : [];

  return (
    <Popover.Panel>
      {({ close }) => (
        <div className="w-96" style={FIXED_HEIGHT}>
          {notificationsQuery.isPending && (
            <div className="grid gap-y-2.5 p-4">
              {Array.from({ length: 5 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton key={index} className="h-20" />
              ))}
            </div>
          )}

          {notificationsQuery.isSuccess && (
            <>
              {isEmpty(notifications) && (
                <div className="flex-center flex-col text-center text-base" style={FIXED_HEIGHT}>
                  <NotificationsIcon className="size-5" />
                  <div>{t('navbar.notifications.noNotificationState.firstSentence')}</div>
                  <div>{t('navbar.notifications.noNotificationState.secondSentence')}</div>
                </div>
              )}

              <InfiniteScrollContainer
                canLoadMore={notificationsQuery.hasNextPage}
                loadMore={notificationsQuery.fetchNextPage}
                scrollbarOptions={scrollbarOptions}
              >
                <div className="flex flex-col gap-y-2.5 p-4">
                  {notifications.map(
                    (notification) =>
                      notification && (
                        <NotificationItem key={notification.id} notification={notification} onClick={close} />
                      )
                  )}
                </div>
              </InfiniteScrollContainer>
            </>
          )}
        </div>
      )}
    </Popover.Panel>
  );
};

export { NotificationPopoverContent };
