import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { apiService } from '@/api.service';
import { clearToast } from '@/components/common/toast/ToastHelpers';
import { Menu } from '@/components/common/ui-components/menu';
import { MenuItem } from '@/components/common/ui-components/menu/types';
import { useUrlFiltersActions } from '@/components/explore/useUrlFilters';
import { useCurrentUserQuery } from '@/entities/users/users.queries';
import { RoutePaths } from '@/enums';
import LogoutIcon from '@/static/icons/arrows/log-out-Filled.svg?react';
import { PreviousPageState } from '@/types/common';
import { DataTestId } from '@/types/data-test-id';

import { ProfileMenuButton } from './ProfileMenuButton';
import { SIDEBAR_FLOATING_SHIFTING_OPTIONS } from '../sidebar.constants';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { hardShutdown } = useIntercom();
  const currentUserQuery = useCurrentUserQuery();
  const { clear: clearUrlFilters } = useUrlFiltersActions();
  const location = useLocation();

  const navigate = useNavigate();

  const logoutAndClear = () => {
    clearToast();
    clearUrlFilters();
    apiService.authService.logout();
  };

  const menuItems = useMemo<MenuItem[]>(() => {
    const logout = () => {
      hardShutdown();
      logoutAndClear();
      navigate(RoutePaths.LOGIN);
    };

    const previousPageLocationState: PreviousPageState = {
      previousLocationPath: {
        pathname: location.pathname,
        search: location.search,
      },
    };

    if (!currentUserQuery.isSuccess) {
      return [];
    }

    return [
      {
        label: t('navbar.profile.personalSettings'),
        link: RoutePaths.SETTINGS_PROFILE,
        state: previousPageLocationState,
      },
      {
        icon: <LogoutIcon className="icon-small" />,
        label: t('navbar.profile.signOut'),
        onClick: logout,
        className: 'border-t border-grey-100',
        dataTestId: DataTestId.SIGN_OUT_BUTTON_ID,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserQuery.isSuccess, location.pathname, location.search]);

  if (!currentUserQuery.isSuccess) {
    return null;
  }

  return (
    <Menu
      items={menuItems}
      Button={ProfileMenuButton}
      placement="right"
      floatingShiftOptions={SIDEBAR_FLOATING_SHIFTING_OPTIONS}
      offset={-20}
    />
  );
};

export { ProfileMenu };
