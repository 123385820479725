import React from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { useTitle } from 'react-use';

import { ProgressPlaceholder } from '@/components/common/ProgressPlaceholder';
import { useTenantQuery } from '@/entities/tenant/tenant.queries';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { RoutePaths } from '@/enums';
import { capitalizeFirstLetter } from '@/utils/utils';

import { DealsHeader } from './DealsHeader';
import { DealWarningSetupBanner } from './DealWarningSetupBanner';
import { useShowDealWarningSetupBanner } from './useShowDealWarningSetupBanner';
import { useHideModjoDeals } from '../useHideModjoDeals';

const DealsLayout = () => {
  const hideModjoDeals = useHideModjoDeals();
  const currentUser = useCurrentUser();
  const tenantQuery = useTenantQuery();
  const { t } = useTranslation();
  useTitle(`Modjo - ${capitalizeFirstLetter(t('deals'))}`);
  const showDealWarningSetupBanner = useShowDealWarningSetupBanner();

  // We need to wait for the current user to be loaded before we can determine if we should hide the Modjo deals
  if (!currentUser || !tenantQuery.isSuccess) {
    return <ProgressPlaceholder />;
  }

  if (hideModjoDeals) {
    return <Navigate to={RoutePaths.UNAUTHORIZED} replace />;
  }

  return (
    <div className="flex h-full flex-col bg-grey-white">
      {showDealWarningSetupBanner && <DealWarningSetupBanner />}
      <DealsHeader />
      <Outlet />
    </div>
  );
};

export { DealsLayout };
