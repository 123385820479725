import React, { useEffect } from 'react';

import { AxiosResponse } from 'axios';
import { matchPath } from 'react-router-dom';

import { useCappedErrorsContext } from '@/contexts/CappedErrorsContext';
import { RoutePaths } from '@/enums';
import { HttpStatusCode } from '@/enums/http-status-code';
import { MISSING_TRANSLATION_ERROR_MESSAGE } from '@/translation/i18n';
import { AdvancedSettingsFeatures } from '@/utils/settings';

import { ForbiddenErrorFallbackBody } from './ForbiddenErrorFallbackBody';
import { GenericErrorFallbackBody, GenericErrorFallbackBodyProps } from './GenericErrorFallbackBody';

type ErrorFallbackProps = {
  response?: AxiosResponse;
  children?: JSX.Element | JSX.Element[];
} & GenericErrorFallbackBodyProps;

/**
 * Fallback component displayed when an error happens
 * Mounting this component will increase the number of handled errors to make sure we do
 * not displayed it more than NB_CAPPED_ERRORS errors @const CappedErrorsContext
 * It should mostly be used along with the package: `react-error-boundary`
 */
export const ErrorFallback = ({ response, children, ...errorFallbackBodyProps }: ErrorFallbackProps) => {
  const { increaseNbErrors, decreaseNbErrors } = useCappedErrorsContext();
  useEffect(() => {
    increaseNbErrors();
    return decreaseNbErrors;
  }, [increaseNbErrors, decreaseNbErrors]);
  // eslint-disable-next-line no-console
  console.error(response);

  const errorFallbackBody =
    response &&
    response.status === HttpStatusCode.FORBIDDEN &&
    matchPath(RoutePaths.CALL_DETAILS, response.data.path as string) ? (
      <ForbiddenErrorFallbackBody feature={AdvancedSettingsFeatures.RESTRICT_CALL_ACCESS} />
    ) : (
      <GenericErrorFallbackBody {...errorFallbackBodyProps} />
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children ? { children } : errorFallbackBody}</>;
};

export const isChunkLoadError = (error: Error): boolean => {
  return (
    error.message.toLowerCase().includes('failed to fetch dynamically imported module') ||
    error.message.toLowerCase().includes('unable to preload CSS') ||
    error.message.toLowerCase().includes(MISSING_TRANSLATION_ERROR_MESSAGE)
  );
};
