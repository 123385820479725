import React, { forwardRef, Ref } from 'react';

import clsx from 'clsx';
import { FieldValues, Path, useController } from 'react-hook-form';

import { SimpleInput } from '@/components/common/ui-components/inputs/text/SimpleInput';
import { SimpleInputProps } from '@/components/common/ui-components/inputs/text/types';
import { cn } from '@/utils/utils';

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  containerClassName?: string;
} & SimpleInputProps;

export const FormInput = forwardRef(
  <T extends Record<string, unknown>>(
    { name, containerClassName, dataTestId, size, label, ...otherProps }: FormInputProps<T>,
    ref: Ref<HTMLInputElement>
  ) => {
    const {
      field,
      fieldState: { isTouched, error },
    } = useController({ name });
    const isSmall = size === 'small';
    const isError = Boolean(error && isTouched);

    return (
      <div className={cn('relative flex flex-col', isSmall ? 'pb-4' : 'pb-5', containerClassName)}>
        <SimpleInput
          data-test-id={dataTestId}
          size={size}
          label={label}
          isError={isError}
          {...otherProps}
          {...field}
          onChange={(e) => {
            if (otherProps.type === 'number') {
              field.onChange(Number(e.target.value || otherProps.min));
            } else {
              field.onChange(e.target.value);
            }
          }}
          ref={ref}
        />

        {isError && <p className={clsx('text-red', isSmall ? 'text-xs' : 'text-sm')}>{error?.message}</p>}
      </div>
    );
  }
);
