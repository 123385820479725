import React from 'react';

type SidebarButtonContextProps = {
  isSidebarOpen?: boolean;
  isActivePage?: boolean;
};

export const SidebarButtonContext = React.createContext<SidebarButtonContextProps>({
  isSidebarOpen: true,
  isActivePage: false,
});
