import { SECONDS_PER_MINUTE } from '@/utils/date-utils';

import { AiScoringTemplate } from './aiScoringTemplates.types';

export const mapApiAiScoringTemplateResponseToAiScoringTemplate = (template: AiScoringTemplate): AiScoringTemplate => {
  return {
    ...template,
    createdOn: new Date(template.createdOn),
    modifiedOn: new Date(template.modifiedOn),
    deletedOn: template.deletedOn ? new Date(template.deletedOn) : undefined,
    matchCriteria: {
      ...template.matchCriteria,
      callDuration: template.matchCriteria.callDuration && {
        min: template.matchCriteria.callDuration.min && template.matchCriteria.callDuration.min / SECONDS_PER_MINUTE,
        max: template.matchCriteria.callDuration.max && template.matchCriteria.callDuration.max / SECONDS_PER_MINUTE,
      },
    },
  };
};
