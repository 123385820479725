import { useCallback } from 'react';

import { useCurrentUserQuery, useUserByIdQuery, useUsersQuery } from '@/entities/users/users.queries';
import {
  toRecentOrActiveSearchableUsers,
  toRecentOrActiveSelectOptionUsers,
  toRecentOrActiveUsers,
} from '@/entities/users/users.selects';
import { UserRole } from '@/enums';
import { useCallURLInfos } from '@/hooks/useCallURLInfos';

import { mapApiCurrentUserToCurrentUser } from './users.mappers';
import { User } from './users.types';

export const useCurrentUser = () => {
  const currentUserQuery = useCurrentUserQuery({ queryOptions: { select: mapApiCurrentUserToCurrentUser } });
  if (!currentUserQuery.isSuccess) {
    return undefined;
  }
  return currentUserQuery.data;
};

export const useIsFarmer = () => {
  const currentUser = useCurrentUser();
  return currentUser?.role === UserRole.SUPERADMIN;
};

const bouyguesTenantNames = new Set(['bouygues', 'bouyguesdevcli', 'bouyguesrcp', 'comdatabouygues']);
const otherTenantNames = new Set(['allegromusique', 'saadben']);
export const allTenantNames: Set<string> = new Set([...bouyguesTenantNames, ...otherTenantNames]);
export const useIsBouygues = () => {
  const { isPublic } = useCallURLInfos();
  const currentUser = useCurrentUser();
  if (isPublic) {
    return false;
  }
  // Using allTenantNames instead of bouyguesTenantNames to avoid regression
  return currentUser && allTenantNames.has(currentUser.tenantName.toLowerCase());
};

export const useUserById = (userId: User['id']) => {
  const userQuery = useUserByIdQuery(userId);
  return userQuery.data;
};

export const useActiveUsers = () => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: useCallback(
        (data: User[]) =>
          data.filter((u) => !u.deletedOn && !u.isExternal).sort((a, b) => a.fullName.localeCompare(b.fullName)),
        []
      ),
    },
  });

  return usersQuery.data ?? [];
};

export const useLicensedUsers = () => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: useCallback(
        (data: User[]) =>
          data.filter((u) => !u.deletedOn && u.hasLicense).sort((a, b) => a.fullName.localeCompare(b.fullName)),
        []
      ),
    },
  });

  return usersQuery.data ?? [];
};

export const useRecentOrActiveUsers = () => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: toRecentOrActiveUsers,
    },
  });

  return usersQuery.data ?? [];
};

export const useRecentOrActiveSearchableUsers = () => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: useCallback((data: User[]) => toRecentOrActiveSearchableUsers(data), []),
    },
  });

  return usersQuery.data ?? [];
};

export const useRecentOrActiveSelectOptionUsers = () => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: useCallback((data: User[]) => toRecentOrActiveSelectOptionUsers(data), []),
    },
  });

  return usersQuery.data ?? [];
};

export const useActiveUsersWithoutMe = (currentUserId?: User['id']) => {
  const usersQuery = useUsersQuery({
    queryOptions: {
      select: useCallback(
        (data: User[]) =>
          data
            .filter((u) => !u.deletedOn && !u.isExternal && u.id !== currentUserId)
            .sort((a, b) => a.fullName.localeCompare(b.fullName)),
        [currentUserId]
      ),
    },
  });

  return usersQuery.data ?? [];
};
