import React, { useEffect, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react';
import { Portal } from '@headlessui/react';
import { isValid, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { useResizeWindow } from '@/hooks/useResizeWindow';
import { formatDate, getDateFormatByLocale } from '@/utils/date-utils';

import { RangeCategory } from './search-components/search-components.types';
import { IconButtonGhost } from './ui-components/buttons/UIButtonGhost';
import { DatePicker } from './ui-components/date-pickers/DatePicker';
import { InputWithAdornments } from './ui-components/inputs/text/InputWithAdornments';
import { getLabelStyles } from './ui-components/styles';
import { InputVariantSize } from './ui-components/types';
import CalendarIcon from '../../static/icons/time/calendar-dates-Line.svg?react';

export type DateSelectInlineProps = {
  id: string;
  size?: InputVariantSize;
  value?: Date | number;
  label?: string;
  min?: Date | number;
  max?: Date | number;
  format?: string;
  filter?: boolean;
  onChange: (date: Date) => void;
  disabled?: boolean;
};

export const DateSelectInline = ({
  id,
  size,
  value,
  label,
  min,
  filter,
  max = filter ? Date.now() : undefined,
  format: formatProps,
  disabled,
  onChange,
}: DateSelectInlineProps) => {
  const { t } = useTranslation('common');

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [input, setInput] = useState<string>();
  const [error, setError] = useState<string | null>(null);

  const format = formatProps ?? getDateFormatByLocale().fullDateShort;

  const { refs, floatingStyles, update } = useFloating({
    middleware: [offset(10), flip(), shift()],
  });

  const openDatePicker = () => setIsDatePickerOpen(true);
  const closeDatePicker = () => setIsDatePickerOpen(false);

  useClickAway(refs.floating, () => closeDatePicker());

  useResizeWindow(update);

  useEffect(() => {
    if (input) {
      const dateParsed = parse(input, format, new Date());
      if (format.length !== input.length || !isValid(dateParsed)) {
        setError(t('inputDatePicker.errors.invalidFormat'));
        return;
      }
      if ((min && new Date(input) < min) || (max && new Date(input) > max)) {
        setError(t('inputDatePicker.errors.outOfRange'));
        return;
      }
      onChange(new Date(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, t]);

  return (
    <div className="flex flex-col items-start">
      {label && (
        <label className={getLabelStyles(true, false)} htmlFor={id}>
          {label}
        </label>
      )}
      <InputWithAdornments
        ref={refs.setReference}
        size={size}
        pill={false}
        className="rounded-md"
        onClick={disabled ? () => {} : openDatePicker}
        inputProps={{
          value: input || (value && formatDate(value, format)),
          placeholder: input ? undefined : label,
          onChange: (event) => {
            setError(null);
            setInput(event.target.value);
          },
          maxLength: 10,
        }}
        endAdornment={
          disabled ? null : (
            <IconButtonGhost
              size="small"
              icon={<CalendarIcon />}
              onClick={openDatePicker}
              isTransparent
              className="hidden p-1 lg:block"
            />
          )
        }
      />
      {isDatePickerOpen && (
        <Portal>
          <div
            className="z-50 rounded shadow-md"
            ref={refs.setFloating}
            style={{ ...floatingStyles, position: 'fixed' }}
          >
            <DatePicker
              id="datepicker"
              mode={RangeCategory.EQUAL}
              value={value}
              min={min}
              max={max}
              filter={filter}
              onSelect={(startDate) => {
                if (!startDate) {
                  return;
                }
                const date = typeof startDate === 'number' ? new Date(startDate) : startDate;
                if (date) {
                  onChange(date);
                }
              }}
              onClose={closeDatePicker}
            />
          </div>
        </Portal>
      )}

      {error && <span className="py-2 text-xs text-red">{error}</span>}
    </div>
  );
};
