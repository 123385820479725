import React, { useState } from 'react';

import { formatDate, getDateFormatByLocale } from '@/utils/date-utils';

import { DealCloseDateOption, DATE_FILTERS } from './Common';
import { RangeCategory, RangeOptionType, SearchableObject } from './search-components.types';
import { getRangeType } from '../../explore/helpers';
import { DatePicker } from '../ui-components/date-pickers/DatePicker';
import { SimpleSelect } from '../ui-components/select/SimpleSelect';
import { SelectOption } from '../ui-components/types';

export const DEAL_CLOSE_DATE_ID = 'deal-close-date';

export const createDateOption = (startDate?: Date, endDate?: Date) => {
  const dateFormat = getDateFormatByLocale().fullDateMedium;
  const formattedStartDate = startDate && formatDate(startDate, dateFormat);
  const formattedEndDate = endDate && formatDate(endDate, dateFormat);
  const isBetween = getRangeType('date', formattedStartDate, formattedEndDate).label === RangeCategory.BETWEEN;
  return {
    id: `${DEAL_CLOSE_DATE_ID}${(formattedStartDate && `-${formattedStartDate}`) ?? ''}${
      (formattedEndDate && `-${formattedEndDate}`) ?? ''
    }`,
    name: `${isBetween ? '' : getRangeType('date', formattedStartDate, formattedEndDate).label} ${
      (startDate && formattedStartDate) ?? ''
    } ${isBetween ? '-' : ''} ${
      (endDate && formattedStartDate !== formattedEndDate && ` ${formattedEndDate ?? ''}`) || ''
    }`,
    startCloseDate: startDate,
    endCloseDate: endDate,
    type: RangeOptionType.DEAL_CLOSE_DATE,
  } as DealCloseDateOption;
};

type DateSelectProps = {
  selectedFilterType: RangeCategory;
  selectedItems: SearchableObject[];
  setSelectedItems: (values: SearchableObject[]) => void;
  startCloseDate?: Date;
  endCloseDate?: Date;
};

export const DateSelect = ({
  selectedFilterType,
  selectedItems,
  setSelectedItems,
  startCloseDate,
  endCloseDate,
}: DateSelectProps) => {
  const [option, setOption] = useState<SelectOption>({
    id: selectedFilterType as string,
    label: selectedFilterType as string,
  });

  const optionAsRangeCategory = option.label as RangeCategory;

  const handleSelect = (startDate?: Date, endDate?: Date) => {
    setSelectedItems([
      ...selectedItems.filter((item) => !item.id.toString().includes(DEAL_CLOSE_DATE_ID)),
      createDateOption(startDate, endDate),
    ]);
  };

  return (
    <>
      <div className="m-4 mt-0">
        <SimpleSelect
          options={DATE_FILTERS.map(
            (dateFilter) => ({ id: dateFilter as string, label: dateFilter as string }) as SelectOption
          )}
          optionSelected={option}
          setOptionSelected={(value) => {
            if (selectedFilterType === value.label) {
              setSelectedItems([...(selectedItems || [])]);
            } else {
              setSelectedItems(selectedItems.filter((item) => !item.id.toString().includes(DEAL_CLOSE_DATE_ID)));
            }
            setOption(value);
          }}
        />
      </div>
      <DatePicker
        id="filter-datepicker"
        mode={optionAsRangeCategory}
        filters={{
          start: startCloseDate,
          end: endCloseDate,
        }}
        onSelect={(startDate, endDate) =>
          handleSelect(startDate ? new Date(startDate) : undefined, endDate ? new Date(endDate) : undefined)
        }
        filter
      />
    </>
  );
};
