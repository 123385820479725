import { ApiUser } from '@/entities/users/users.types';
import { UserRole } from '@/enums';
import { i18n } from '@/translation/i18n';
import { Provider, Providers } from '@/types/providers';

import { capitalizeFirstLetter } from './utils';

export const getRoleLevel = (role: UserRole) => {
  const levels = [UserRole.USER, UserRole.MANAGER, UserRole.ADMIN, UserRole.SUPERADMIN];
  return levels.indexOf(role);
};

export const hasRole = (userRole: UserRole, targetRole: UserRole) => {
  return getRoleLevel(userRole) >= getRoleLevel(targetRole);
};

export const getLabelByRole = (role: UserRole): string => {
  const upperCaseRole = role.toUpperCase();
  switch (role) {
    case UserRole.ADMIN: {
      return i18n.t('settings:users.rightPanel.form.role.options.admin');
    }
    case UserRole.MANAGER: {
      return i18n.t('settings:users.rightPanel.form.role.options.manager');
    }
    case UserRole.USER: {
      return i18n.t('settings:users.rightPanel.form.role.options.user');
    }
    default: {
      return upperCaseRole;
    }
  }
};

export const getUserName = (user: Pick<ApiUser, 'role' | 'firstName' | 'tenantName'>) => {
  if (hasRole(user.role, UserRole.SUPERADMIN)) {
    return `${capitalizeFirstLetter(user.tenantName)}`;
  }
  return user.firstName;
};

export const getUserFullName = ({
  firstName,
  lastName,
  role,
  tenantName,
  deletedOn,
}: Pick<ApiUser, 'role' | 'firstName' | 'lastName' | 'tenantName' | 'deletedOn'>) => {
  if (hasRole(role, UserRole.SUPERADMIN)) {
    return `${firstName} - ${capitalizeFirstLetter(tenantName)}`;
  }

  return `${firstName} ${lastName} ${deletedOn ? '(Deleted)' : ''}`.trim();
};

export const getUserProviders = (user: Pick<ApiUser, 'providersId'>) =>
  user.providersId.reduce<Provider[]>((acc, p) => {
    if (p.providerId) {
      acc.push(p.provider);
    }
    return acc;
  }, []);

export const hasSlackProvider = (user: Pick<ApiUser, 'providersId'>) =>
  getUserProviders(user).includes(Providers.SLACK);

export const hasTeams = (user: Pick<ApiUser, 'teamIds'>) => [...(user.teamIds || [])].some(Boolean);

export const getDomainFromEmail = (email: string) => {
  return `@${email.split('@')[1]}`;
};
