import React, { useState, createContext, useContext, useMemo } from 'react';

import { useDebounce } from 'react-use';

const DEBOUNCED_STATE = 200;

type ContextProps = {
  addFilter: boolean;
  search: boolean;
  handleTriggers: (event: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>, stopPropagation?: boolean) => void;
};

const ShortcutsContext = createContext<ContextProps>({
  addFilter: false,
  search: false,
  handleTriggers: () => null,
});
ShortcutsContext.displayName = 'ShortcutsContext';

type ProviderProps = {
  children?: React.ReactNode;
};

export const ShortcutsContextProvider = ({ children }: ProviderProps) => {
  const [addFilterState, setAddFilterState] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<boolean>(false);

  useDebounce(
    () => {
      setAddFilterState(false);
      setSearchState(false);
    },
    DEBOUNCED_STATE,
    [searchState, addFilterState]
  );

  const triggerAddFilter = () => {
    setAddFilterState(true);
  };

  const triggerSearch = () => {
    setSearchState(true);
  };

  const handleTriggers = (event: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>, stopPropagation = true) => {
    stopPropagation && event.stopPropagation();
    if (event.altKey && event.key === 'Enter') {
      triggerSearch();
    } else if (event.ctrlKey && event.key.toLowerCase() === 'i') {
      triggerAddFilter();
    }
  };

  const value: ContextProps = useMemo(
    () => ({
      addFilter: addFilterState,
      search: searchState,
      handleTriggers,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addFilterState, searchState]
  );

  return <ShortcutsContext.Provider value={value}>{children}</ShortcutsContext.Provider>;
};

export const useShortcutsContext = () => {
  const context = useContext(ShortcutsContext);
  if (!context) {
    throw new Error('useShortcutsContext must be used within a ShortcutsContext');
  }
  return context;
};
