import { useQuery } from '@tanstack/react-query';

import { apiService } from '@/api.service';
import { CustomQueryOptions } from '@/types/common';

import { COMMENTS_BASE_URL } from './comments.constants';
import { Comment } from './comments.types';
import { queryKeys } from '../queryKeys';

type UseCommentByIdsQueryParams = {
  blockUuid: string;
  commentIds: number[];
} & CustomQueryOptions<Comment[]>;

export const useCommentFromNoteBlockQuery = ({ blockUuid, commentIds, queryOptions }: UseCommentByIdsQueryParams) => {
  return useQuery({
    queryKey: queryKeys.comments.block(blockUuid),
    queryFn: async () => {
      const { data } = await apiService.API.get<Comment[]>(COMMENTS_BASE_URL, {
        params: { ids: commentIds },
      });
      return data;
    },
    ...queryOptions,
  });
};
