import { startOfDay } from 'date-fns';

import { DatePeriods } from './date-period-filter.constant';
import { DatePeriod } from './date-period-filter.types';

export const getPeriodStart = (period: DatePeriod, periodEnd = new Date()) => {
  const periodStart = new Date(periodEnd.setUTCHours(0, 0, 0, 0));
  switch (period) {
    case DatePeriods.ONE_DAY: {
      break;
    }
    case DatePeriods.ONE_WEEK: {
      periodStart.setUTCDate(periodEnd.getUTCDate() - 7);
      break;
    }
    case DatePeriods.ONE_MONTH: {
      periodStart.setUTCDate(periodEnd.getUTCDate() - 30);
      break;
    }
    case DatePeriods.THREE_MONTHS: {
      periodStart.setUTCDate(periodEnd.getUTCDate() - 3 * 30);
      break;
    }
    case DatePeriods.ONE_YEAR: {
      periodStart.setUTCDate(periodEnd.getUTCDate() - 12 * 30);
      break;
    }
    default: {
      periodStart.setUTCDate(periodEnd.getUTCDate() - 30);
      break;
    }
  }
  return startOfDay(periodStart);
};

export const isDatePeriod = (value: string): value is DatePeriod => {
  return Object.values(DatePeriods).includes(value as DatePeriod);
};
