export const CallListItemDataTestId = {
  CALL_LIST_ITEM_ID: 'callListItem',
  CALL_LIST_ITEM_TITLE_ID: 'callListItemTitle',
  CALL_LIST_ITEM_REVIEW_ICON_ID: 'callListItemReviewIcon',
  CALL_LIST_ITEM_REVIEW_AVERAGE_ID: 'callListItemReviewAverage',
  CALL_LIST_ITEM_USER_NAME_ID: 'callListItemUserName',
  CALL_LIST_ITEM_CONTACT_NAME_ID: 'callListItemContactName',
  CALL_LIST_ITEM_TEAM_NAME_ID: 'callListItemTeamName',
  CALL_LIST_ITEM_DURATION_ID: 'callListItemDuration',
};
