import { ObjectValues, TemplateStatus } from '@/types/common';
import { CallRecordingProvider } from '@/types/providers';
import { SortOrder } from '@/types/sort';

import { Tag } from '../tags/tags.types';
import { Team } from '../teams/teams.types';

export type AiScoringTemplateMatchingCriteriaCallDuration = {
  // Duration in seconds
  min?: number;
  // Duration in seconds
  max?: number;
};

type AiScoringTemplateMatchingCriteria = {
  tagIds: Tag['id'][];
  teamIds: Team['id'][];
  providers: CallRecordingProvider[];
  callDuration?: AiScoringTemplateMatchingCriteriaCallDuration;
};

export type AiScoringTemplateQuestion = {
  uuid: string;
  title: string;
  prompt: string;
  order: number;
  accuracy: number | null;
};

export type AiScoringTemplate = {
  uuid: string;
  createdOn: Date;
  createdById: number;
  modifiedOn: Date;
  deletedOn?: Date;
  title: string;
  status: TemplateStatus;
  matchCriteria: AiScoringTemplateMatchingCriteria;
  questions: AiScoringTemplateQuestion[];
};

export type AiScoringTemplateDto = Pick<AiScoringTemplate, 'title' | 'status' | 'matchCriteria'> & {
  questions: Omit<AiScoringTemplateQuestion, 'accuracy'>[];
};

export type AiScoringDetailsTemplate = Pick<AiScoringTemplate, 'title' | 'status' | 'matchCriteria' | 'questions'>;

export type AiScoringTemplatesFiltersParams = {
  teamIdFilter?: string;
  tagIdFilter?: string;
  statusFilter?: string;
};

export const AiScoringTemplatesSortByFields = {
  TITLE: 'title',
  STATUS: 'status',
  TAG: 'tagId',
  TEAM: 'teamId',
  LAST_EDITED: 'lastEdited',
} as const;

export type AiScoringTemplatesSortByField = ObjectValues<typeof AiScoringTemplatesSortByFields>;

export type AiScoringTemplatesTableQueryParams = AiScoringTemplatesFiltersParams & {
  sortBy: AiScoringTemplatesSortByField;
  sortOrder: SortOrder;
};

export type AiScoringQuestionAccuracy = {
  totalReactions: number;
  accuracy: number | null;
  uuid: string;
  title: string;
  prompt: string;
  modifiedById: number | null;
  createdOn: Date;
  deletedOn: Date | null;
};
