import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { Key } from 'ts-key-enum';

import { useUrlFilters, updateParams } from '@/components/explore/useUrlFilters';
import { useCreateViewMutation, useUpdateViewMutation, useDeleteViewMutation } from '@/entities/views/views.mutation';
import { View, ViewType } from '@/entities/views/views.types';
import { RoutePaths } from '@/enums';
import MoreIcon from '@/static/icons/basic/more-small-hori-Filled.svg?react';
import BinIcon from '@/static/icons/basic/trash-Line.svg?react';
import CopyIcon from '@/static/icons/editor/copy_past-Line.svg?react';
import EditIcon from '@/static/icons/editor/edit-Line.svg?react';
import { EditViewOrigin, Event } from '@/tracking/events';

import { AlertVariant } from '../../toast/alert';
import { createToast } from '../../toast/ToastHelpers';
import { Menu } from '../../ui-components/menu';
import { MenuItem } from '../../ui-components/menu/types';

type ViewDropdownItemProps = View & { viewType: ViewType; closeDropdown: () => void };

const ViewDropdownItem = ({
  id,
  label,
  filters,
  sorting,
  viewType,
  closeDropdown,
  viewConfig,
}: ViewDropdownItemProps) => {
  const { t } = useTranslation();
  const { viewId } = useUrlFilters();
  const [viewName, setViewName] = useState(label);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const createViewMutation = useCreateViewMutation();
  const updateViewMutation = useUpdateViewMutation();
  const deleteViewMutation = useDeleteViewMutation();
  const navigate = useNavigate();

  const isViewActive = viewId === id;
  const redirectPathName = viewType === 'deals' ? RoutePaths.DEALS : RoutePaths.EXPLORE;

  const updatedParams = updateParams(new URLSearchParams(), {
    ...filters,
    ...sorting,
    ...viewConfig,
    viewId: id,
  });

  const menuItems: MenuItem[] = [
    {
      label: t('rename'),
      className: 'first-letter:capitalize',
      icon: <EditIcon className="icon-small" />,
      onClick: () => setIsEditing(true),
    },
    {
      label: t('duplicate'),
      className: 'first-letter:capitalize',
      icon: <CopyIcon className="icon-small" />,
      onClick: () =>
        createViewMutation.mutate(
          {
            label: t('copyName', { name: viewName }),
            type: viewType,
            filters,
            sorting,
          },
          {
            onSuccess: ({ id: newViewId }) => {
              mixpanel.track(Event.DUPLICATE_FILTERS_VIEW, {
                type: viewType,
                origin: EditViewOrigin.HEADER,
              });
              createToast(t('views.buttons.duplicate.toast.success'));
              const updatedParamsWithNewView = updateParams(new URLSearchParams(), {
                ...filters,
                ...sorting,
                viewId: newViewId,
              });
              closeDropdown();
              navigate({ pathname: redirectPathName, search: updatedParamsWithNewView.toString() });
            },
          }
        ),
    },
    {
      label: t('delete'),
      className: 'text-red first-letter:capitalize',
      icon: <BinIcon className="icon-small fill-red" />,
      onClick: () =>
        deleteViewMutation.mutate(id, {
          onSuccess: () => {
            mixpanel.track(Event.DELETE_FILTERS_VIEW, {
              type: viewType,
              origin: EditViewOrigin.HEADER,
            });
            createToast(t('views.buttons.delete.toast.success'));
            navigate({ pathname: redirectPathName });
          },
        }),
    },
  ];

  const submitNewViewName = () =>
    updateViewMutation.mutate(
      {
        id,
        label: viewName,
        type: viewType,
      },
      {
        onSuccess: () => {
          createToast(t('views.buttons.rename.toast.success'));
          mixpanel.track(Event.RENAME_FILTERS_VIEW, {
            type: viewType,
            origin: EditViewOrigin.HEADER,
          });
        },
      }
    );

  useClickAway(inputRef, (e: MouseEvent) => {
    if (viewName === '') {
      createToast(t('views.buttons.rename.toast.errorLength'), AlertVariant.Error);
      return;
    }
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsEditing(false);
      submitNewViewName();
    }
  });

  const handleOnClick = () => {
    closeDropdown();
    mixpanel.track(Event.APPLY_FILTERS_VIEW, { type: viewType, origin: EditViewOrigin.HEADER });
  };

  return (
    <NavLink
      to={{
        pathname: redirectPathName,
        search: updatedParams.toString(),
      }}
      onClick={handleOnClick}
      className={clsx(
        'group flex justify-between gap-x-1 rounded-lg px-2 py-1 transition-colors',
        isViewActive ? 'bg-grey-100' : 'hover:bg-grey-100'
      )}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={viewName}
          onChange={(e) => setViewName(e.target.value)}
          onKeyDown={(e) => {
            if (viewName === '') {
              createToast(t('views.buttons.rename.toast.errorLength'), AlertVariant.Error);
              return;
            }
            if ([Key.Enter, Key.Escape].includes(e.key as Key)) {
              setIsEditing(false);
            }
            if (e.key === Key.Enter) {
              submitNewViewName();
            }
          }}
          className={clsx('w-full outline-none', isViewActive ? 'bg-grey-100' : 'bg-transparent')}
        />
      ) : (
        <span className="truncate">{viewName}</span>
      )}

      <Menu
        items={menuItems}
        Button={() => (
          <MoreIcon className="icon-small hidden rounded transition-colors hover:bg-grey-100 group-hover:block" />
        )}
        placement="right"
      />
    </NavLink>
  );
};

export { ViewDropdownItem };
