import React from 'react';

import clsx from 'clsx';
import { GetItemPropsOptions } from 'downshift';

import {
  OptionItem,
  computeResultIcon,
  itemToJSX,
  enterIcon,
  FiltersCategories,
  getViewMoreOption,
} from '@/components/common/search-components/Common';
import { ListMenuItemContainer } from '@/components/common/search-components/CommonStyles';
import { SearchableObject } from '@/components/common/search-components/search-components.types';

type ResultListMenuItemProps = {
  item: OptionItem;
  highlightedIndex: number | null;
  inputValue: string | null;
  getItemProps: (options: GetItemPropsOptions<SearchableObject>) => Record<string, unknown>;
  switchFromViewMore: (category: FiltersCategories, value: string) => void;
};

const ResultListMenuItem = ({
  item,
  getItemProps,
  inputValue,
  highlightedIndex,
  switchFromViewMore,
}: ResultListMenuItemProps) => {
  const handleClickViewMore = (itemOption: OptionItem) => {
    if (itemOption.option.name.includes(getViewMoreOption())) {
      switchFromViewMore(item.type!, item.option.name.split(getViewMoreOption())[1]);
    }
  };

  return (
    <ListMenuItemContainer
      key={item.option.id}
      className={clsx(highlightedIndex === item.index && 'bg-grey-100')}
      {...getItemProps({
        item: item.option,
        index: item.index,
        onClick: () => handleClickViewMore(item),
      })}
    >
      <div className="flex items-center gap-x-2">
        {computeResultIcon(item.type, item.option)}
        {itemToJSX(item.option, { inputValue })}
      </div>
      {enterIcon(highlightedIndex === item.index)}
    </ListMenuItemContainer>
  );
};

export { ResultListMenuItem };
