import React, { PropsWithChildren, useMemo } from 'react';

import { NavLink } from 'react-router-dom';

import { DataTestIdProps, ProductTourIdProps } from '@/types/common';
import { cn } from '@/utils/utils';

import { MobileNavbarButtonContext } from './MobileNavbarButtonContext';

type MobileNavbarButtonContainerProps = PropsWithChildren<
  (
    | ({
        variant: 'link';
        path: string;
        isActive: boolean;
        onClick: () => void;
      } & ProductTourIdProps)
    | {
        variant: 'button';
        isActive?: boolean;
        onClick?: () => void;
      }
  ) &
    DataTestIdProps
>;

const MobileNavbarButtonContainer = ({ children, dataTestId, ...props }: MobileNavbarButtonContainerProps) => {
  const Wrapper =
    props.variant === 'link'
      ? ({ children: _children, className }: PropsWithChildren<{ className: string }>) => (
          <NavLink
            to={props.path}
            className={cn(props.isActive && 'bg-grey-100', className)}
            data-test-id={dataTestId}
            onClick={props.onClick}
            data-product-tour-id={props.productTourId}
          >
            {_children}
          </NavLink>
        )
      : ({ children: _children, className }: PropsWithChildren<{ className: string }>) => (
          <button
            onClick={props.onClick}
            type="button"
            className={cn(props.isActive && 'bg-grey-100', className)}
            data-test-id={dataTestId}
          >
            {_children}
          </button>
        );

  const providerValues = useMemo(() => ({ isActive: props.isActive }), [props]);

  return (
    <MobileNavbarButtonContext.Provider value={providerValues}>
      <Wrapper className="flex flex-1 flex-col items-center gap-y-0.5 rounded-md py-2">{children}</Wrapper>
    </MobileNavbarButtonContext.Provider>
  );
};

MobileNavbarButtonContainer.displayName = 'MobileNavbarButtonContainer';

export { MobileNavbarButtonContainer };
