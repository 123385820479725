import React from 'react';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { NavLinkProps } from 'react-router-dom';

import { useCurrentRoute } from '@/hooks/useCurrentRoute';
import { ProductTourIdProps } from '@/types/common';

import { sidebarStore } from './sidebar.store';
import { NavigationLinkItem } from './sidebar.utils';
import { Chip } from '../common/ui-components/data-display/chips';
import { MaybeInATooltip } from '../common/ui-components/data-display/tooltip/MaybeInATooltip';
import { SidebarButton } from '../common/ui-components/layout/SidebarButton';

type NavigationLinkButtonProps = NavigationLinkItem & {
  state?: NavLinkProps['state'];
  hasViews?: boolean;
  hasActiveView?: boolean;
  iconActive?: boolean;
  iconOnClick?: () => void;
} & ProductTourIdProps;

const NavigationLinkButton = ({
  lineIcon,
  filledIcon,
  navigationKey,
  path,
  alternatePaths,
  state,
  trackedEvent,
  beta,
  hasViews = false,
  hasActiveView = false,
  productTourId,
  iconActive = false,
  iconOnClick = () => {},
}: NavigationLinkButtonProps) => {
  const { t } = useTranslation();
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();
  const isActivePage = useCurrentRoute([path, ...(alternatePaths ?? [])]);

  const handleNavLink = () => mixpanel.track(trackedEvent.id, trackedEvent.data);

  return (
    <MaybeInATooltip
      withTooltip={!isSidebarOpen}
      variant="small"
      content={
        <div className="flex items-center justify-between gap-x-2">
          <span className="first-letter:capitalize">{t(navigationKey)}</span>
          {beta && (
            <Chip
              label={t('common:beta')}
              size="small"
              className="rounded-lg bg-lavender px-2 py-3 text-2xs uppercase text-grey-800 shadow-inner-purple"
            />
          )}
        </div>
      }
      placement="right"
    >
      <SidebarButton
        variant="link"
        path={path}
        state={state}
        isActivePage={isActivePage && !hasActiveView}
        isSidebarOpen={isSidebarOpen}
        onClick={handleNavLink}
        productTourId={productTourId}
      >
        <SidebarButton.Icon
          lineIcon={lineIcon}
          filledIcon={filledIcon}
          hasViews={hasViews}
          active={iconActive}
          onClick={iconOnClick}
        />
        <SidebarButton.Label>{t(navigationKey)}</SidebarButton.Label>
        {beta && isSidebarOpen && (
          <Chip
            label={t('common:beta')}
            size="small"
            className="ml-auto h-4 rounded bg-lavender p-1 text-2xs uppercase shadow-inner-purple"
          />
        )}
      </SidebarButton>
    </MaybeInATooltip>
  );
};

NavigationLinkButton.displayName = 'NavigationLinkButton';

export { NavigationLinkButton };
