export const CommentsDataTestId = {
  COMMENT_TEXT_AREA_SUBMIT_BUTTON_ID: 'commentMessageSubmitBtn',
  COMMENT_TEXT_AREA_ID: 'commentTxtArea',
  THREAD_CONTAINER_ID: 'threadContainerId',
  COMMENT_CONTENT_CONTAINER_ID: 'commentContentContainer',
  COMMENT_CONTENT_AUTHOR_LABEL_ID: 'commentContentAuthorLbl',
  COMMENT_CONTENT_START_TIME_LABEL_ID: 'commentStartTimeLbl',
  COMMENT_CONTENT_END_TIME_LABEL_ID: 'commentEndTimeLbl',
  COMMENT_MESSAGE_TEXT_VALUE_ID: 'commentMessageTextValue',
  COMMENT_MORE_ACTION_BUTTON_ID: 'commentMoreActionBtn',
  COMMENT_SHARE_BUTTON_ID: 'commentShareBtn',
  COMMENT_DELETE_CONFIRMATION_DIALOG_ID: 'commentDeleteConfirmationDialog',
  COMMENT_OPEN_EMOJI_BUTTON_ID: 'commentOpenEmojiListBtn',
  COMMENT_SHARE_SNIPPET_BUTTON_ID: 'commentShareSnippetButton',
  COMMENT_REACTION_LIST_ID: 'commentReactionList',
  COMMENT_REACTION_BUTTON_ID: 'commentReactionBtn',
  COMMENT_LIKE_BUTTON_ID: 'commentLikeBtn',
  ADD_COMMENT_BUTTON_ID: 'addCommentBtn',
  COMMENT_MESSAGE_REGULAR_VALUE_ID: 'regularMessageText',
  COMMENT_MESSAGE_BOLD_VALUE_ID: 'boldMessageText',
  COMMENT_NUMBER_OF_REPLIES_LINK_ID: 'commentNumberOfRepliesLnk',
  COMMENT_START_THREAD_BUTTON_ID: 'commentStartThreadBtn',
  ACTIVITIES_COMMENT_CONTAINER: 'activitiesCommentContainer',
  EDIT_COMMENT_BUTTON_ID: 'editCommentBtn',
  DELETE_COMMENT_BUTTON_ID: 'deleteCommentBtn',
};
