/* eslint-disable i18next/no-literal-string */
import { AxiosInstance } from 'axios';

import { AuthenticateService } from './authenticate.service';
import { Environment } from './enums/constants';
import { PaginationRequestResult } from './types/paginations';

const getApiUrl = (): string => {
  if (import.meta.env.VITE_ENV === Environment.STAGING) {
    return 'https://api.staging.modjo.ai';
  }
  if (import.meta.env.VITE_ENV === Environment.DEV) {
    const devApiUrl: string = import.meta.env.VITE_MODJO_API_URL ?? 'http://localhost:4000';
    return devApiUrl;
  }
  return 'https://api.modjo.ai';
};

export const apiURL = getApiUrl();

export type PaginatedResponse<T> = {
  values: T[];
  pagination: {
    page: number;
    lastPage: number;
    totalValues: number;
  };
};

export class ApiService {
  authService: AuthenticateService;
  API: AxiosInstance;

  constructor() {
    this.authService = new AuthenticateService();
    this.API = this.authService.API;
  }

  async requestPagination<T, P extends PaginationRequestResult<T> = PaginationRequestResult<T>>(
    url: string,
    params: { [key: string]: unknown; page: number; perPage: number }
  ) {
    const { data } = await this.API.get<P>(url, { params });
    return data;
  }
}

export const apiService = new ApiService();
