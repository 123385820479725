import React from 'react';

import { GetItemPropsOptions, GetMenuPropsOptions, GetPropsCommonOptions } from 'downshift';
import { m } from 'framer-motion';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import {
  FiltersCategories,
  getOptionType,
  getViewMoreOption,
  OptionCategory,
  OtherOption,
} from '@/components/common/search-components/Common';
import {
  SearchableObjectOptionType,
  SearchableObject,
  OtherInfoOptionType,
} from '@/components/common/search-components/search-components.types';
import {
  makeSearchWordsRegex,
  replaceDiacritics,
} from '@/components/common/ui-components/autocompletes/common.helpers';
import { AUTO_HIDE_SCROLLBARS_OPTIONS } from '@/enums/constants';

import { ResultListMenuItem } from './ResultListMenuItem';

const MAX_RESULT_PER_CATEGORY = 2;

const Divider = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>) => (
  <hr className="mx-2 my-0 h-px w-full border-none bg-grey-400" {...props} />
);

const subMenuVariants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.1,
      delay: 0.02,
    },
    display: 'block',
  },
  exit: {
    opacity: 0.3,
    transition: {
      duration: 0.1,
      delay: 0.02,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const buildCategories = (options: SearchableObject[], value?: string | null) => {
  const regex = makeSearchWordsRegex(value ?? '');

  const categories: OptionCategory[] = [];

  for (const [index, option] of options.entries()) {
    const sectionExist = categories.find((s) => s.title === getOptionType(option));
    const section = sectionExist ?? { title: getOptionType(option), items: [] };

    if (value === '' || regex.test(replaceDiacritics(option.name))) {
      section.items.push({ option, index });
    }

    if (!sectionExist && section.items.length > 0) {
      categories.push(section);
    }
  }

  for (const category of categories) {
    if (category.items && category.items.length > MAX_RESULT_PER_CATEGORY) {
      category.items = category.items.slice(0, MAX_RESULT_PER_CATEGORY);
      const type = getOptionType(category.items[0].option);
      category.items.push({
        option: { name: `${type}-${getViewMoreOption()}${value ?? ''}` } as SearchableObject,
        type,
      });
    }
  }

  value &&
    value.length > 2 &&
    categories.push({
      title: SearchableObjectOptionType.OTHER,
      items: [
        {
          option: new OtherOption('', value, OtherInfoOptionType.TRANSCRIPT, SearchableObjectOptionType.TRANSCRIPT),
        },
      ],
    });

  let index = 0;
  for (const category of categories) {
    for (const item of category.items) {
      item.index = index;
      index += 1;
    }
  }

  return categories;
};

type SimpleSearchDropdownProps = {
  options: SearchableObject[];
  isOpen: boolean;
  highlightedIndex: number | null;
  inputValue: string | null;
  getMenuProps: (options?: GetMenuPropsOptions, otherOptions?: GetPropsCommonOptions) => Record<string, unknown>;
  getItemProps: (options: GetItemPropsOptions<SearchableObject>) => Record<string, unknown>;
  switchFromViewMore: (category: FiltersCategories, value: string) => void;
};

const SimpleSearchDropdown = ({
  isOpen,
  options,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  inputValue,
  switchFromViewMore,
}: SimpleSearchDropdownProps) => {
  const categories = buildCategories(options, inputValue);
  return (
    <div>
      {isOpen && (
        <m.ul
          className="w-full bg-grey-white text-grey-900"
          {...getMenuProps()}
          initial="exit"
          animate={inputValue && inputValue.length > 0 ? 'enter' : 'exit'}
          variants={subMenuVariants}
        >
          <OverlayScrollbarsComponent className="max-h-[320px] p-2" options={AUTO_HIDE_SCROLLBARS_OPTIONS}>
            {categories.map((category) => (
              <div key={category.title}>
                {categories.length === 1 && (
                  <div className="min-w-full pl-3 pr-5 pt-1.5 text-sm font-medium italic">
                    No result for <b>{inputValue}</b>
                  </div>
                )}
                {category.title && category.items.length > 0 && (
                  <>
                    <div className="flex items-center p-0.5 pr-3 pt-2.5">
                      <p className="whitespace-nowrap text-xs font-normal">{category.title}</p>
                      <Divider className="bg-grey-100" />
                    </div>
                    {category.items.map((item) => (
                      <ResultListMenuItem
                        key={item.index}
                        item={item}
                        inputValue={inputValue}
                        highlightedIndex={highlightedIndex}
                        getItemProps={getItemProps}
                        switchFromViewMore={switchFromViewMore}
                      />
                    ))}
                  </>
                )}
              </div>
            ))}
          </OverlayScrollbarsComponent>
        </m.ul>
      )}
    </div>
  );
};

export { SimpleSearchDropdown };
