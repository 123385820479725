import React, { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { useMeasure, useTimeoutFn } from 'react-use';

import { cn } from '@/utils/utils';

const TRANSITION_SPEED_ANIMATION_IN_MILLISECONDS = 300;

export const Collapsible = ({
  children,
  className,
  open = false,
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  open: boolean;
}) => {
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [, cancel, reset] = useTimeoutFn(() => {
    // make sure to not have transition when the component is not transitioning
    mainContainerRef.current && mainContainerRef.current.style.setProperty('transition-duration', '0ms');
    setIsTransitioning(false);
  }, TRANSITION_SPEED_ANIMATION_IN_MILLISECONDS);

  // reset the transition when the component state is changed
  useEffect(() => {
    cancel();
    reset();
    // set the transition duration to the default value before the transition start
    mainContainerRef.current &&
      mainContainerRef.current.style.setProperty(
        'transition-duration',
        `${TRANSITION_SPEED_ANIMATION_IN_MILLISECONDS}ms`
      );
    setIsTransitioning(true);
  }, [open, cancel, reset]);

  return (
    <div
      ref={mainContainerRef}
      {...props}
      className={cn(
        'transition-all',
        (isTransitioning || (!isTransitioning && !open)) && 'overflow-hidden',
        open && 'opacity-100',
        !open && 'opacity-0',
        className
      )}
      style={{
        height: open ? height : 0,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};
