import { RouterState } from '@remix-run/router';

import { createStoreAndMethods } from '@/utils/zustand-utils';

type HistoryStore = {
  lastAction: RouterState['historyAction'] | null;
  latestLocation: string | null;
};

const historyStoreDefaultValue: HistoryStore = {
  lastAction: null,
  latestLocation: null,
};

const [store, _historyStore] = createStoreAndMethods(historyStoreDefaultValue);

const historyStore = {
  ..._historyStore,
  setHistoryChanges: (action: RouterState['historyAction'], location: string) => {
    if (location === store.getState().latestLocation) {
      return;
    }
    store.setState({
      lastAction: action,
      latestLocation: location,
    });
  },
};

export { historyStore };
