import { clearToast, createCustomToast } from '@/components/common/toast/ToastHelpers';
import { CustomToastOptions } from '@/types/toast';

import { healthCheckStore } from './healthCheck.store';

const incrementActiveDownAlerts = () => {
  healthCheckStore.setActiveDownAlerts(healthCheckStore.getActiveDownAlerts() + 1);
};

const decrementActiveDownAlerts = () => {
  healthCheckStore.setActiveDownAlerts(healthCheckStore.getActiveDownAlerts() - 1);
};

type ShowBannerAlertOptions = {
  onClose?: () => void;
} & CustomToastOptions;

export const showBannerAlert = (alertOptions: ShowBannerAlertOptions) => {
  const { buttonProps, onClose } = alertOptions;
  let hasClickedOnButton = false;
  createCustomToast({
    ...alertOptions,
    buttonProps: buttonProps && {
      ...buttonProps,
      onClick: () => {
        hasClickedOnButton = true;
        clearToast();
        buttonProps?.onClick && buttonProps?.onClick();
      },
    },
    toastOptions: {
      onOpen: () => {
        incrementActiveDownAlerts();
      },
      onClose: () => {
        const shouldTriggerOnCloseCallBack = buttonProps ? !hasClickedOnButton : hasClickedOnButton;
        if (onClose && shouldTriggerOnCloseCallBack) {
          onClose();
        }
        decrementActiveDownAlerts();
      },
    },
  });
};
