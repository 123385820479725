// eslint-disable-next-line unicorn/prefer-string-raw
const escapeRegexCharacters = (input: string) => input.replaceAll(/[$()*+.?[\\\]{|}]/g, '\\$&');
const replaceDiacritics = (input: string) => input.normalize('NFD').replaceAll(/\p{Diacritic}/gu, '');
const replaceSpacesWithWildcards = (input: string) => input.replaceAll(' ', '.');

const makeSearchWordsRegex = (inputValue?: string, includeSubstrings = true) => {
  const escapedValue = escapeRegexCharacters(inputValue ? inputValue.trim() : '');
  const withoutDiacriticValue = replaceDiacritics(escapedValue);

  // Replace spaces with wildcards to match any character to match the search query from the nav bar
  // e.g. "saint denis" and "saint-denis" should both match a call with "Saint-Denis" in the transcript
  const withoutSpacesValue = replaceSpacesWithWildcards(withoutDiacriticValue);

  // If we include substrings, we'll match the input value "mai" in "mais" for example
  if (includeSubstrings) {
    return new RegExp(withoutSpacesValue, 'i');
  }

  const regexValue = `\\b${withoutSpacesValue}(?:[\\s?.;,!:]|$)`;

  return new RegExp(regexValue, 'i');
};

/**
 * Basic filter generic options. Can be string or object.
 * If object then specify on which property the filter needs to be applied.
 *
 * @param {SelectOption[]} options Array of options to filter
 * @param {string[]} inputValues Input query values
 * @param {Function} getOptionField Function that returns the field to be filtered
 * @returns
 */
const getFilteredOptionsArray = <T>(
  options: T[],
  inputValues?: string[],
  getOptionField?: (option: T) => string,
  includeSubstrings = true
) => {
  const result: T[] = [];
  if (Array.isArray(inputValues)) {
    for (const inputValue of inputValues) {
      if (inputValue) {
        const regex = makeSearchWordsRegex(inputValue, includeSubstrings);
        result.push(
          ...options.filter((option) =>
            regex.test(
              replaceDiacritics(
                getOptionField && typeof option === 'object' ? getOptionField(option) : (option as unknown as string)
              )
            )
          )
        );
      }
    }
  }
  return result;
};

/**
 * Basic filter options
 *
 * @param {TValue[]} options Options to filter
 * @param {string} inputValue Input query value
 * @returns
 */
const getFilteredOptions = <TValue extends { label: string }>(options: TValue[], inputValue?: string) => {
  const regex = makeSearchWordsRegex(inputValue);
  return options.filter((option) => regex.test(replaceDiacritics(option.label ?? '')));
};

export { makeSearchWordsRegex, replaceDiacritics, getFilteredOptionsArray, getFilteredOptions };
