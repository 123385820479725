export enum CrmEntityType {
  Account = 'account',
  Contact = 'contact',
  Deal = 'deal',
  Lead = 'lead',
  User = 'user',
}

export enum CrmPropertyTypeBase {
  STRING = 'string',
  EMAIL = 'email',
  ENUM = 'enum',
  URL = 'url',
  BOOLEAN = 'boolean',
  DATE = 'date',
  PHONE = 'phone',
  DATETIME = 'datetime',
  TIME = 'time',
  NUMBER = 'number',
  TEXTAREA = 'textarea',
}
