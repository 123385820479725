import { OtherOption } from '@/components/common/search-components/Common';
import { OtherInfoOptionType } from '@/components/common/search-components/search-components.types';
import { getProviderDisplayName } from '@/components/providers/icons/ProvidersIcon';
import { CallRecordingProvider } from '@/types/providers';

import { ApiCallCrmInfos, ApiCallCrmInfosKeysWithoutCrm } from './calls.types';

const sortProvidersListingAsSearchableOptions = (data: CallRecordingProvider[]) => {
  if (data) {
    return data
      .toSorted((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
      .map(
        (provider) => new OtherOption(provider, getProviderDisplayName(provider), OtherInfoOptionType.PHONE_PROVIDER)
      );
  }
  return [];
};

const getCallCrmEntityTypes = (crmInfo: ApiCallCrmInfos): ApiCallCrmInfosKeysWithoutCrm[] => {
  return Object.keys(crmInfo).filter((key): key is ApiCallCrmInfosKeysWithoutCrm => key !== 'crm');
};

export { getCallCrmEntityTypes, sortProvidersListingAsSearchableOptions };
