import React, { ReactNode } from 'react';

import ChevronRightIcon from '@/static/icons/arrows/chevrons-right-Line.svg?react';
import { cn } from '@/utils/utils';

import { IconButtonGhost } from '../../buttons/UIButtonGhost';

type RightPanelHeaderProps = {
  children: ReactNode;
  onClick: () => void;
  className?: string;
};

const RightPanelHeader = ({ children, onClick, className }: RightPanelHeaderProps) => (
  <>
    <IconButtonGhost
      className="size-8 p-3"
      isTransparent
      icon={<ChevronRightIcon className="size-2.5" />}
      onClick={onClick}
    />
    <div className={cn('w-full text-base font-semibold', className)}>{children}</div>
  </>
);

export { RightPanelHeader };
