import React from 'react';

import clsx from 'clsx';
import { useSelect } from 'downshift';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { Event, EventType, EventOrigin } from '@/tracking/events';

import { computeResultIcon, enterIcon, FiltersCategories, filtersCategoriesTranslationKeys } from './Common';
import { ListMenuItemContainer } from './CommonStyles';
import { RangeCategory, ReviewsOptionType } from './search-components.types';
import { useIsAiCallScoringEnabled } from '../hooks/useIsAiCallScoringEnabled';

type FilterCategoryListProps = {
  categories: (FiltersCategories | undefined)[];
  onChangeCategory: (value?: FiltersCategories) => void;
  setInitialInputValue?: (value: string) => void;
  isExplorePage?: boolean;
};

const FilterCategoryList = ({
  categories,
  isExplorePage,
  onChangeCategory,
  setInitialInputValue,
}: FilterCategoryListProps) => {
  const { t } = useTranslation();
  const { getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } = useSelect({
    items: categories,
    isOpen: true,
    onSelectedItemChange: ({ selectedItem }) => {
      onChangeCategory(selectedItem ?? undefined);
      setInitialInputValue && setInitialInputValue('');
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      if (state.selectedItem !== changes.selectedItem) {
        mixpanel.track(Event.CLICK_FILTER, {
          filterOrigin: isExplorePage ? EventOrigin.EXPLORE : EventOrigin.SEARCHBAR,
          filterName: changes.selectedItem,
          actionType: type === useSelect.stateChangeTypes.MenuKeyDownEnter ? EventType.SHORTCUT : EventType.CLICK,
        });
      }

      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownCharacter:
        case useSelect.stateChangeTypes.FunctionSetInputValue: {
          return {
            ...state,
            highlightedIndex: state.highlightedIndex,
          };
        }
        default: {
          return changes;
        }
      }
    },
  });
  const enableCallScoring = useIsAiCallScoringEnabled();

  return (
    <>
      <div {...getToggleButtonProps()} />
      <ul className="flex flex-col overflow-hidden text-grey-900 outline-none" {...getMenuProps()}>
        {categories.map((item, index) => {
          if (!item || (item === ReviewsOptionType.AI_CALL_SCORING && !enableCallScoring)) {
            return null;
          }
          const isRangeCategory = Object.values(RangeCategory).includes(item as RangeCategory);
          return (
            <ListMenuItemContainer
              key={item}
              className={clsx(highlightedIndex === index && 'bg-grey-100')}
              {...getItemProps({
                item,
                index,
              })}
            >
              <div className="flex items-center gap-x-1">
                {computeResultIcon(item, undefined)}
                {isRangeCategory && t('filters.categories.is', { count: 1 })}
                <span className={clsx('text-blue-900', isRangeCategory ? 'font-semibold' : 'first-letter:capitalize')}>
                  {t(filtersCategoriesTranslationKeys[item], { count: 1 })}
                </span>
              </div>
              {enterIcon(highlightedIndex === index)}
            </ListMenuItemContainer>
          );
        })}
      </ul>
    </>
  );
};

export { FilterCategoryList };
