import React, { forwardRef } from 'react';

import { RawButton } from './RawButton';
import { ButtonBaseProps } from './types';
import { cn, isEmpty } from '../../../../utils/utils';

const CLASSES = {
  base: `transition ease-in-out select-none duration-150 flex items-center justify-center shrink-0 whitespace-nowrap gap-2 focus:outline-none`,
  size: {
    tiny: 'py-1.5 text-xs leading-4',
    small: 'py-2 text-sm leading-4',
    medium: 'py-2 text-base leading-6',
    large: 'py-2.5 text-base leading-6',
  },
  iconPadding: {
    none: {
      tiny: 'px-3',
      small: 'px-4',
      medium: 'px-[1.125rem]',
      large: 'px-5',
    },
    start: {
      tiny: 'pl-2 pr-3.5',
      small: 'pl-3 pr-4.5',
      medium: 'pl-4 pr-5.5',
      large: 'pl-4.5 pr-6',
    },
    end: {
      tiny: 'pl-3.5 pr-2',
      small: 'pl-4.5 pr-3',
      medium: 'pl-5.5 pr-4',
      large: 'pl-6 pr-4.5',
    },
  },
  iconSize: {
    tiny: 'w-4 h-4',
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
    large: 'w-5 h-5',
  },
};

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (
    {
      children,
      className,
      size = 'medium',
      icon,
      iconPosition = 'start',
      rotateIcon,
      disabled,
      dataTestId,
      productTourId,
      ...otherProps
    },
    reference
  ) => (
    <RawButton
      ref={reference}
      {...otherProps}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      className={cn(
        CLASSES.base,
        CLASSES.size[size],
        isEmpty(icon) ? CLASSES.iconPadding.none[size] : CLASSES.iconPadding[iconPosition][size],
        iconPosition === 'start' ? 'flex-row' : 'flex-row-reverse',
        className
      )}
      data-test-id={dataTestId}
      data-product-tour-id={productTourId}
    >
      {icon && (
        <span className="flex-center shrink-0 flex-col">
          {React.cloneElement(icon, {
            className: cn(
              CLASSES.iconSize[size],
              rotateIcon && 'rotate-180',
              disabled && 'pointer-events-none',
              icon.props.className as string
            ),
          })}
        </span>
      )}
      {children}
    </RawButton>
  )
);
