import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import TimerIcon from '@/static/icons/time/timer-2-Line.svg?react';

type FreeTrialExpirationContentProps = {
  numberOfDaysLeft: number;
};

const FreeTrialExpirationContent = ({ numberOfDaysLeft }: FreeTrialExpirationContentProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex-center gap-x-2.5">
      <TimerIcon className="icon-small" />
      {numberOfDaysLeft === 1 ? (
        t('freetrial.lastday')
      ) : (
        <div className="flex-center gap-x-1">
          <Trans
            i18nKey="freetrial.daysLeft"
            values={{ numberOfDaysLeft }}
            components={{
              p: <p />,
              span: <span className="rounded border border-grey-100 bg-grey-white px-2" />,
            }}
          />
        </div>
      )}
    </div>
  );
};

export { FreeTrialExpirationContent };
