export const CallReviewsDataTestId = {
  CALL_REVIEW_REVIEWEE_SELECTOR_ID: 'callReviewRevieweeSelector',
  CALL_REVIEW_REVIEWEE_LIST_ID: 'callReviewRevieweeSelectorList',
  CALL_REVIEW_TEMPLATE_SELECTOR_ID: 'callReviewTemplateSelector',
  CALL_REVIEW_TEMPLATE_LIST_ID: 'callReviewTemplateSelectorList',
  CALL_REVIEW_QUESTION_ID: 'callReviewQuestion',
  CALL_REVIEW_QUESTION_RATING_ID: 'callReviewQuestionRating',
  CALL_REVIEW_SUBMIT_BUTTON_ID: 'callReviewSubmitButton',
  CALL_REVIEW_CANCEL_BUTTON_ID: 'callReviewCancelButton',
  CALL_REVIEW_TAB_ID: 'callReviewTab',
  CALL_REVIEW_ITEM_ID: 'callReviewItem',
  CALL_REVIEW_FEEDBACK_INPUT_ID: 'callReviewFeedbackInput',
  CALL_REVIEW_COLLAPSE_BUTTON_ID: 'callReviewCollapseButton',
  CALL_REVIEW_FEEDBACK_VIEW_ID: 'callReviewFeedbackView',
  CALL_REVIEW_MENU_BUTTON_ID: 'callReviewMenuButton',
  CALL_REVIEW_DELETE_DIALOG_ID: 'callReviewDeleteDialog',
  CALL_REVIEW_DELETE_BUTTON_ID: 'callReviewDeleteButton',
  CALL_REVIEW_TEMPLATE_NAME_ID: 'callReviewTemplateName',
};
