import { MeetingRecorderStatus } from './meeting-recorder.types';

export const SEARCH_DEBOUNCE_TIME_IN_MS = 300;
export const RECORDER_LIMIT_REACHED_IN_MS = 7_200_000;

export const MeetingRecorderStatuses = {
  READY: 'READY',
  PAUSED: 'PAUSED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  RECORDING: 'RECORDING',
} as const;

export const MeetingRecorderContentStates = {
  RECORDER: 'RECORDER',
  IMPORT: 'IMPORT',
} as const;

export const MeetingRecorderTranslationKeys = {
  [MeetingRecorderStatuses.READY]: 'meetingRecorder.status.ready',
  [MeetingRecorderStatuses.PAUSED]: 'meetingRecorder.status.paused',
  [MeetingRecorderStatuses.PROCESSING]: 'meetingRecorder.status.processing',
  [MeetingRecorderStatuses.COMPLETED]: 'meetingRecorder.status.completed',
  [MeetingRecorderStatuses.RECORDING]: 'meetingRecorder.status.recording',
} as const satisfies Record<MeetingRecorderStatus, string>;
