import React from 'react';

import { useTranslation } from 'react-i18next';

import { MaybeInATooltip } from '@/components/common/ui-components/data-display/tooltip/MaybeInATooltip';

import { SearchButton } from './SearchButton';
import { SearchDialog } from './SearchDialog';
import { sidebarStore } from '../sidebar.store';

const Search = () => {
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();
  const { t } = useTranslation();
  return (
    <>
      <MaybeInATooltip
        withTooltip={!isSidebarOpen}
        variant="small"
        contentClassName="capitalize"
        content={t('navbar.search')}
        placement="right"
      >
        <SearchButton />
      </MaybeInATooltip>
      <SearchDialog />
    </>
  );
};

Search.displayName = 'Search';

export { Search };
