import React, { PropsWithChildren, ReactElement } from 'react';

import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import { Drawer } from 'vaul';

import CloseIcon from '@/static/icons/basic/cross-Filled.svg?react';
import { DataTestId } from '@/types/data-test-id';
import { ExcludedTypes } from '@/utils/type-utils';
import { cn } from '@/utils/utils';

import { ActionsFooter } from './Common';
import { ConfirmationDialogProps } from './ConfirmationDialog';
import { CommonDialogProps } from './types';
import { ErrorFallback } from '../../errors/fallback';
import { IconButtonGhost } from '../buttons/UIButtonGhost';

export const MobileDrawerTitleWithBottomBorder = ({ children }: PropsWithChildren) => (
  <Drawer.Title className="relative flex w-full items-center justify-between border-b border-b-grey-100 p-4 pr-8 text-xl font-semibold first-letter:capitalize">
    {children}
  </Drawer.Title>
);

type MobileDrawerProps = {
  dialogId: string;
  handleClose: (dialogId: string) => void;
  isOpen: boolean;
  title?: string | ReactElement;
  hideCloseIcon?: boolean;
  subtitle?: string;
  onReturn?: () => void;
  nested?: boolean;
  dismissible?: boolean;
  children: React.ReactNode;
  fullHeight?: boolean;
  actionsFooterProps?: Partial<ExcludedTypes<ConfirmationDialogProps, CommonDialogProps>>;
  mainContentClassName?: string;
};

export const MobileDrawer = ({
  dialogId,
  handleClose,
  isOpen,
  title,
  fullHeight,
  hideCloseIcon,
  subtitle,
  onReturn,
  nested,
  dismissible,
  children,
  actionsFooterProps,
  mainContentClassName,
}: MobileDrawerProps) => {
  if (!isMobile) {
    return null;
  }
  return (
    <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
      <Drawer.Root
        nested={nested}
        onOpenChange={(open) => {
          if (!open) {
            handleClose(dialogId);
          }
        }}
        open={isOpen}
        dismissible={dismissible}
        shouldScaleBackground
      >
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 bg-lavender bg-opacity-40 backdrop-blur-[1px]" />
          <Drawer.Content
            className={cn('fixed inset-0 flex flex-col rounded-t-2xl bg-grey-50', fullHeight ? 'top-0' : 'mt-12')}
          >
            {/* Drawer.Description is required within Drawer.Content. Otherwise we get a warning */}
            <Drawer.Description />
            {!hideCloseIcon && (
              <IconButtonGhost
                size="small"
                icon={<CloseIcon className="fill-grey" />}
                className="absolute right-4 top-4 z-50"
                aria-label="Close dialog"
                onClick={() => handleClose(dialogId)}
              />
            )}
            {title ? (
              <Drawer.Title
                className={clsx(
                  typeof title === 'string' &&
                    'flex w-full shrink-0 items-start justify-between p-4 pr-8 text-xl font-semibold first-letter:capitalize'
                )}
              >
                {title}
              </Drawer.Title>
            ) : (
              // Drawer.Title is required within Drawer.Content. Otherwise we get an error
              <Drawer.Title className="hidden" />
            )}
            {subtitle && <h3 className={clsx('pb-3 pr-7', onReturn ? 'pl-7' : 'pl-5')}>{subtitle}</h3>}
            <div className={cn('h-full overflow-hidden p-4', mainContentClassName)}>{children}</div>
            {actionsFooterProps && (
              <div className="px-5 pb-4">
                <ActionsFooter
                  {...actionsFooterProps}
                  onCancel={() => handleClose(dialogId)}
                  dataTestId={DataTestId.DIALOG_CONFIRM_ID}
                />
              </div>
            )}
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </ErrorBoundary>
  );
};
