import { useCallback, useEffect, useState } from 'react';

/**
 * A Hook to check if the Modjo tab is being active or not. Returns a boolean.
 */
export const useTabActive = (defaultVisibilityState = true) => {
  const [visibilityState, setVisibilityState] = useState(defaultVisibilityState);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Calls handleVisibilityChange when the window first loads
    handleVisibilityChange();
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return visibilityState;
};
