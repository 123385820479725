import { Buffer } from 'buffer';

import { Location, matchPath } from 'react-router-dom';

import { RoutePaths } from '@/enums';
import { QUERY_PARAMETER } from '@/utils/url-utils';
import { decodeBase64String, toNumber } from '@/utils/utils';

export type PublicTokenDecoded = {
  uuid: string;
  tenantName: string;
  sharerId: number;
  sharerName: string;
};

export const extractPublicToken = (location: Location): (PublicTokenDecoded & { snippetId?: number }) | null => {
  const match = matchPath(RoutePaths.PUBLIC_CALL, location.pathname) ?? matchPath(RoutePaths.EMBED, location.pathname);

  if (match?.params.publicToken) {
    const decodedPublicToken = decodeBase64String<PublicTokenDecoded>(match.params.publicToken);
    const searchParams = new URLSearchParams(location.search);
    const snippetIdParameter = searchParams.get(QUERY_PARAMETER.SNIPPET_ID);
    if (snippetIdParameter && decodedPublicToken) {
      return { ...decodedPublicToken, snippetId: toNumber(snippetIdParameter) };
    }
    return decodedPublicToken;
  }
  throw new Error(
    `It was not possible to extract the public call details publicToken token from location ${location.pathname}`
  );
};

export const encodePublicToken = (publicTokenDecoded: PublicTokenDecoded): string =>
  Buffer.from(
    JSON.stringify({
      tenantName: publicTokenDecoded.tenantName,
      uuid: publicTokenDecoded.uuid,
      sharerId: publicTokenDecoded.sharerId,
      sharerName: publicTokenDecoded.sharerName,
    })
  ).toString('base64');
