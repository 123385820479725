import { useCallback, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { CallTitleOption, getOptionType, OptionType, OtherOption } from '@/components/common/search-components/Common';
import {
  OtherInfoOptionType,
  SearchableObject,
  SearchableObjectOptionType,
} from '@/components/common/search-components/search-components.types';
import { useHideModjoDeals } from '@/components/deals/useHideModjoDeals';
import { UrlFilters } from '@/components/explore/useUrlFilters';
import { useSortedSearchAccountsQuery } from '@/entities/account/account.queries';
import {
  callSearchQueryObject,
  useSortedTenantCallProvidersAsSearchableOptionsQuery,
  useCallSearchQuery,
} from '@/entities/calls/calls.queries';
import { ApiCallInfo } from '@/entities/calls/calls.types';
import {
  useLeadStatusesAsSearchableOptionsQuery,
  useSortedSearchContactQuery,
} from '@/entities/contact/contact.queries';
import { useSearchContactsJobTitlesQuery } from '@/entities/contacts/contacts.queries';
import { JobTitle } from '@/entities/contacts/contacts.types';
import {
  useDealLossReasonAsSearchableOptionsQuery,
  useDealStagesAsSearchableOptionsQuery,
  useDealStatusesAsSearchableOptionsQuery,
  useDealsSearchQuery,
  useLeadSourcesAsSearchableOptionsQuery,
} from '@/entities/deals/deals.queries';
import { useSortedLanguagesOptionsQuery } from '@/entities/languages/languages.queries';
import { useTagOptionsQuery } from '@/entities/tags/tags.queries';
import { useEmptyTag } from '@/entities/tags/tags.types';
import { useSortedTeamsQuery } from '@/entities/teams/teams.hooks';
import { useSortedActiveSearchableTopicObjectsForGlobalSearchQuery } from '@/entities/topics/topics.queries';
import { useRecentOrActiveSearchableUsers } from '@/entities/users/users.hooks';
import { useThrottle } from '@/hooks/useThrottle';

const SEARCH_CALL_DEBOUNCE_TIME_IN_MS = 300;

export const MIN_LENTH_FOR_SEARCH = 3;

const mapApiCallInfoToCallTitleOption = ({ id, name, date }: ApiCallInfo): CallTitleOption => ({
  id: id.toString(),
  name,
  type: SearchableObjectOptionType.CALL_TITLE,
  date,
  goToCallDetails: true,
});

const mapApiJobTitleToJobTitleOption = ({ jobTitle }: JobTitle, index: number): OtherOption =>
  new OtherOption(index.toString(), jobTitle, OtherInfoOptionType.JOB_TITLE);

export const useSearchFilters = (): [SearchableObject[], React.Dispatch<React.SetStateAction<string>>] => {
  const [callInfos, setCallInfos] = useState<CallTitleOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const hideModjoDeals = useHideModjoDeals();
  const tagOptionsQuery = useTagOptionsQuery();
  const sortedActiveTopicsForGlobalSearchQuery = useSortedActiveSearchableTopicObjectsForGlobalSearchQuery();
  const recentOrActiveUsers = useRecentOrActiveSearchableUsers();
  const sortedTeamsQuery = useSortedTeamsQuery();

  const throttledInputValue = useThrottle(inputValue, SEARCH_CALL_DEBOUNCE_TIME_IN_MS);
  const sortedSearchAccountsQuery = useSortedSearchAccountsQuery(throttledInputValue);
  const sortedSearchContactsQuery = useSortedSearchContactQuery(throttledInputValue);
  const sortedSearchDealQuery = useDealsSearchQuery({
    filter: {
      name: throttledInputValue,
    },
    order: {
      closeDate: 'desc',
    },
  });

  const dealStagesAsSearchableOptionsQuery = useDealStagesAsSearchableOptionsQuery();
  const dealStatusesAsSearchableOptionsQuery = useDealStatusesAsSearchableOptionsQuery();
  const leadStatusesAsSearchableOptionsQuery = useLeadStatusesAsSearchableOptionsQuery();
  const sortedTenantCallProvidersAsSearchableOptionsQuery = useSortedTenantCallProvidersAsSearchableOptionsQuery();
  const languagesOptionsQuery = useSortedLanguagesOptionsQuery();
  const optionLanguages = languagesOptionsQuery.data;
  const leadSourcesAsSearchableOptionsQuery = useLeadSourcesAsSearchableOptionsQuery();
  const dealLossReasonsAsSearchableOptionsQuery = useDealLossReasonAsSearchableOptionsQuery();
  const emptyTag = useEmptyTag();

  const optionTeams: SearchableObject[] = (sortedTeamsQuery.data ?? []).map((team) => {
    return {
      ...team,
      searchableObjectType: SearchableObjectOptionType.TEAM,
    };
  });
  const dealsOptions = useMemo(() => {
    return hideModjoDeals
      ? []
      : (sortedSearchDealQuery.data?.pages[0].values ?? []).map(
          (deal) => new OtherOption(deal.id, deal.name, SearchableObjectOptionType.DEAL)
        );
  }, [hideModjoDeals, sortedSearchDealQuery.data]);

  const options = useMemo(
    // Order is important here : dropdown categories are shown in this order
    (): SearchableObject[] => [
      ...dealsOptions,
      ...(recentOrActiveUsers || []),
      ...optionTeams,
      ...(dealStagesAsSearchableOptionsQuery.data || []),
      ...(dealStatusesAsSearchableOptionsQuery.data || []),
      ...(leadStatusesAsSearchableOptionsQuery.data || []),
      ...(sortedSearchAccountsQuery.data?.map(
        (account) => new OtherOption(account.id, account.name, SearchableObjectOptionType.ACCOUNT)
      ) || []),
      ...(sortedSearchContactsQuery.data?.map(
        (contact) => new OtherOption(contact.id, contact.name, SearchableObjectOptionType.CONTACT)
      ) || []),
      ...(leadSourcesAsSearchableOptionsQuery.data || []),
      ...(dealLossReasonsAsSearchableOptionsQuery.data || []),
      {
        ...emptyTag,
        searchableObjectType: SearchableObjectOptionType.TAG,
      },
      ...(tagOptionsQuery.data ?? []),
      ...(sortedActiveTopicsForGlobalSearchQuery.data || []),
      ...(callInfos || []),
      ...(sortedTenantCallProvidersAsSearchableOptionsQuery.data || []),
      ...(optionLanguages || []),
    ],
    [
      dealsOptions,
      recentOrActiveUsers,
      optionTeams,
      dealStagesAsSearchableOptionsQuery.data,
      dealStatusesAsSearchableOptionsQuery.data,
      leadStatusesAsSearchableOptionsQuery.data,
      sortedSearchAccountsQuery.data,
      sortedSearchContactsQuery.data,
      leadSourcesAsSearchableOptionsQuery.data,
      dealLossReasonsAsSearchableOptionsQuery.data,
      emptyTag,
      tagOptionsQuery.data,
      sortedActiveTopicsForGlobalSearchQuery.data,
      callInfos,
      sortedTenantCallProvidersAsSearchableOptionsQuery.data,
      optionLanguages,
    ]
  );

  const queryClient = useQueryClient();

  useDebounce(
    async () => {
      if (inputValue.length >= MIN_LENTH_FOR_SEARCH) {
        const calls = await queryClient.fetchQuery(callSearchQueryObject(inputValue));
        setCallInfos(calls.map((call) => mapApiCallInfoToCallTitleOption(call)));
      }
    },
    SEARCH_CALL_DEBOUNCE_TIME_IN_MS,
    [inputValue]
  );

  return [options, setInputValue];
};

export const useCallTitlesApiSearcher = (inputValue: string) => {
  const throttledInputValue = useThrottle(inputValue, SEARCH_CALL_DEBOUNCE_TIME_IN_MS);

  return useCallSearchQuery({
    query: throttledInputValue,
    queryOptions: {
      select: useCallback(
        (calls: ApiCallInfo[]) => calls.map((element) => mapApiCallInfoToCallTitleOption(element)),
        []
      ),
    },
  });
};

export const useJobTitlesApiSearcher = (inputValue: string) => {
  const throttledInputValue = useThrottle(inputValue, SEARCH_CALL_DEBOUNCE_TIME_IN_MS);

  return useSearchContactsJobTitlesQuery(throttledInputValue, MIN_LENTH_FOR_SEARCH, {
    queryOptions: {
      select: useCallback(
        (jobs: JobTitle[]) => jobs.map((element, index) => mapApiJobTitleToJobTitleOption(element, index)),
        []
      ),
    },
  });
};

type UrlFiltersKeys = keyof UrlFilters;

const searchParamsToOptionType: Record<OptionType, UrlFiltersKeys | UrlFiltersKeys[]> = {
  'AI Call Scoring': ['minAiScoring', 'maxAiScoring'],
  'Call Duration': ['minDuration', 'maxDuration'],
  'Deal amount': ['minAmount', 'maxAmount'],
  'Deal close date': ['startCloseDate', 'endCloseDate'],
  'Call Review': ['minReviewRating', 'maxReviewRating'],
  'Call Title': 'callTitle',
  'Contact job title': 'jobTitles',
  Deal: 'dealName',
  'Deal loss reason': 'dealLossReasons',
  'Deal stage': 'dealStage',
  'Deal status': 'dealStatus',
  Language: 'languages',
  'Lead source': 'leadSources',
  'Lead status': 'leadStatus',
  Provider: 'externalPhoneProviders',
  Tag: 'tagIds',
  Team: 'teamIds',
  Topic: 'topicIds',
  User: 'userIds',
  Word: 'searchPhrases',
  Account: 'accountIds',
  Contact: 'contactIds',
  'Commented Only': 'commentedOnly',
  'End date': 'endDate',
  'Start date': 'startDate',
  Other: 'withoutCrmAssociation',
  Transcript: 'searchPhrases',
} as const;

/**
 * Custom hook that retrieves the selected filters based on the provided options and search parameters in the URL.
 *
 * @param options - The array of searchable objects.
 * @returns An array of selected filters.
 */
export const useSelectedFilters = (options: SearchableObject[]) => {
  const [params] = useSearchParams();
  // use set to prevent duplicate keys and then convert it back to an array
  const paramsKeys = [...new Set(params.keys())];
  const filters = paramsKeys.reduce<SearchableObject[]>((acc, key) => {
    const isArray = key.includes('[]');
    // remove the '[]' from the key if it's an array
    const formattedKey = isArray ? key.slice(0, -2) : key;
    // find the option type based on the key
    const optionType = Object.entries(searchParamsToOptionType).find(([, v]) =>
      formattedKey && Array.isArray(v) ? v.includes(formattedKey as UrlFiltersKeys) : v === formattedKey
    )?.[0];
    // if the option type is not found, return the accumulator
    if (!optionType) {
      return acc;
    }
    // get the value from the search parameters
    const value = isArray ? params.getAll(key) : params.get(key);
    // find the options to add to the filters
    const optionToAdd = options.filter(
      (option) =>
        getOptionType(option) === optionType && (isArray ? value?.includes(option.id.toString()) : option.id === value)
    );
    if (optionToAdd) {
      acc.push(...optionToAdd);
    }
    return acc;
  }, []);
  return filters;
};
