import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

export const MISSING_TRANSLATION_ERROR_MESSAGE = 'missing translation';

// eslint-disable-next-line i18next/no-literal-string
const defaultNS = 'common';
const NS = [
  'common',
  'analytics',
  'embed',
  'library',
  'settings',
  'explore',
  'note',
  'callDetails',
  'auth',
  'deals',
  'publicCallDetails',
  'providers',
  'home',
] as const;

export type NSType = typeof NS;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // lazy load translations
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      return import(`./${language}/${namespace}.json`);
    })
  )
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: [defaultNS],
    fallbackNS: NS,
    defaultNS,
    debug: import.meta.env.DEV,
    lng: 'en',
    fallbackLng: 'en',
    react: { transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i', 'p', 'span'] },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    missingKeyHandler: (_lngs, ns, key) => {
      throw new Error(`${MISSING_TRANSLATION_ERROR_MESSAGE}: ${ns} / ${key}`);
    },
  });

// eslint-disable-next-line unicorn/prefer-export-from
export { i18n };
