import React from 'react';

import { captureMessage } from '@sentry/react';
import { AxiosError } from 'axios';
import { minutesToMilliseconds } from 'date-fns';
import { useRouteError } from 'react-router-dom';

import { ErrorFallback, isChunkLoadError } from '../components/common/errors/fallback';
import {
  LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING,
  LOCALSTORAGE_CHUNK_ERROR_LAST_RELOAD_DATE_KEY,
} from '../enums/localstorage-keys';
import { toNumber } from '../utils/utils';

const RoutesErrorBoundary = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const error = useRouteError() as AxiosError;
  if (localStorage.getItem(LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING)) {
    return null;
  }
  // If user doesn't use the latest version of the app, he may face a ChunkLoadError error
  // Here, we are reloading the page in order to be sure the user use the latest version
  if (error && isChunkLoadError(error)) {
    // eslint-disable-next-line no-console
    console.error(error);
    captureMessage(`RoutesErrorBoundary: ${error.message}`);
    const lastReload = localStorage.getItem(LOCALSTORAGE_CHUNK_ERROR_LAST_RELOAD_DATE_KEY);
    // 2 minutes cooldown between 2 reloads in order to avoid infinite loop due to network issue
    const cooldownTimeInMs = minutesToMilliseconds(2);
    if (!lastReload || Date.now() - toNumber(lastReload) > cooldownTimeInMs) {
      localStorage.setItem(LOCALSTORAGE_CHUNK_ERROR_LAST_RELOAD_DATE_KEY, Date.now().toString());
      localStorage.setItem(LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING, Date.now().toString());
      window.location.reload();
      return null;
    }
    return (
      <div className="flex size-full">
        <ErrorFallback response={error.response} />
      </div>
    );
  }
  // eslint-disable-next-line no-console
  console.error(error);
  captureMessage(`RoutesErrorBoundary: ${error.message}`);
  return (
    <div className="flex h-screen w-screen">
      <ErrorFallback response={error.response} />
    </div>
  );
};

RoutesErrorBoundary.displayName = 'RoutesErrorBoundary';

export { RoutesErrorBoundary };
