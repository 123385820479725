import React, { forwardRef, useEffect, useRef } from 'react';

import clsx from 'clsx';

import { useComposedRefs } from '@/hooks/useComposedRefs';
import { cn } from '@/utils/utils';

import { UICheckboxProps } from './types';

const UICheckbox = forwardRef<HTMLInputElement, UICheckboxProps>(
  (
    {
      labelClassName,
      labelSize = 'large',
      className,
      children,
      name,
      disabled,
      readOnly,
      indeterminate = false,
      ...otherProps
    },
    ref
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    const handleRef = useComposedRefs(checkboxRef, ref);

    const isLarge = labelSize === 'large';

    const Container = children ? 'label' : 'div';

    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    return (
      <Container
        className={clsx(
          'flex select-none items-center text-left leading-5 first-letter:uppercase',
          isLarge ? 'text-sm' : ' text-xs',
          disabled ? 'cursor-not-allowed text-grey-400' : 'cursor-pointer',
          !children && 'justify-center',
          labelClassName
        )}
      >
        <input
          id={name}
          name={name}
          ref={handleRef}
          type="checkbox"
          disabled={disabled}
          readOnly={readOnly}
          className={cn(
            'icon-small relative shrink-0 appearance-none rounded border border-grey-400 bg-grey-white outline-none',
            "after:absolute after:left-[5px] after:top-0.5 after:block after:h-2 after:w-1 after:border after:border-l-0 after:border-t-0 after:border-grey-white after:opacity-0 after:content-['']",
            'checked:text-grey-white checked:after:rotate-45 checked:after:opacity-100',
            'focus:shadow-input',
            children && 'mr-2',
            disabled
              ? 'cursor-not-allowed border-grey-400 checked:bg-grey-400'
              : 'cursor-pointer checked:border-blue checked:bg-blue hover:border-blue',
            readOnly &&
              'focus:outline-none focus:shadow-none focus:border-grey-400 cursor-default hover:border-grey-400 checked:bg-grey-400 checked:border-grey-400',
            className
          )}
          {...otherProps}
        />
        {children || null}
      </Container>
    );
  }
);

export { UICheckbox };
